import { Input, Radio, Segmented } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Breadcrumb } from "antd";
import { BirthdayWidget, CreateYourPageWidget, Quote } from '../../components/components';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import "./marketPlace.css"
import ProductCard from '../../components/market/ProductCard'
import { useAllProductListQuery } from '../../store/api/marketApi'
import { useGetCategoryListQuery } from '../../store/api/categoryMaster';
import { BsShopWindow } from "react-icons/bs";
import { BiShoppingBag } from "react-icons/bi";
import { LuTags } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import Dropdown from 'react-bootstrap/Dropdown';
import WidgetWrapper from '../../components/widgets/WidgetWrapper';
import { BiSolidShoppingBags } from "react-icons/bi";

const MarketPlace = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("search") || "");
    const [category, setCategory] = useState(searchParams.get("category") || "");
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1);
    const categoryList = useGetCategoryListQuery();

    const paramsMarket = {
        page: pageNo,
        searchVal: search,
        category: category,
    }
    const products = useAllProductListQuery(paramsMarket);

    useEffect(() => {
        let params = { pageNo };
        if (search) {
            params.search = search;
        }
        if (category) {
            params.category = category;
        }
        setSearchParams(params);
    }, [pageNo, search, category]);

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-3">
                        <div className='market_details'>
                            <h4 className='mb-0 fw-bold'>Marketplace</h4>
                            <div className='search mt-2'>
                                <Input placeholder="Search..." style={{ width: 200 }} onChange={(e) => setSearch(e.target.value)} value={searchParams.get("search") || search} />
                            </div>
                        </div>
                        <ul className='market_option'>
                            <li>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <div className='icon'><BsShopWindow /></div>Category:
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setCategory("")}>Show All</Dropdown.Item>
                                        {categoryList?.data?.data.map((el) => (
                                            <Dropdown.Item key={el._id} value={el._id} onClick={() => setCategory(el._id)}>{el.category_name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li onClick={() => navigate("/marketplace/myproduct")}>
                                <div className='icon'><BiSolidShoppingBags /></div>My Products</li>
                            <li onClick={() => navigate("/marketplace/myorders")}>
                                <div className='icon'><BiShoppingBag /></div>Buying</li>
                            <li onClick={() => navigate("/marketplace/myproduct")}>
                                <div className='icon'><LuTags /></div>Selling</li>
                        </ul>
                        <WidgetWrapper>
                            <div className='market_location'>
                                <div className='icon'><HiLocationMarker /></div>
                                <div>
                                    <h6>Location</h6>
                                    <Link to={`#`} className="address">
                                        East Grand Bahama, Bahamas
                                    </Link>
                                </div>
                            </div>
                        </WidgetWrapper>
                        <CreateYourPageWidget />
                    </div>
                    <div className='col-md-9'>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/marketplace">Marketplace</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center mb-2'>
                                <h5 className='mb-0 fw-bold'>Products</h5>
                                <div className="select-options">
                                    <div className='ceate_list' onClick={() => navigate("/marketplace/create")}>
                                        <div className='icon'><FiPlus /></div>
                                        <span>Create Product</span>
                                    </div>
                                </div>
                            </div>
                            <Row xs={1} md={3} className="g-4">
                                {products?.data?.data?.length > 0 ?
                                    (products?.data?.data?.map((el, i) => (
                                        <Col style={{ marginBottom: "10px" }} key={i}>
                                            <ProductCard data={el} />
                                        </Col>
                                    ))) :
                                    (
                                        <div className='col-md-12'>
                                            <h6 className="text-center py-5 mb-0 mt-5">
                                                No Product Found !!
                                            </h6>
                                        </div>
                                    )
                                }
                            </Row>
                            <table className='adminTable'>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketPlace
