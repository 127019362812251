import React, { Fragment, useReducer, useState } from 'react'
import { Button } from 'react-bootstrap'
import { LiaTimesSolid } from 'react-icons/lia'
import ReactQuill from 'react-quill'
import Compressor from "compressorjs";
import { BsImage } from 'react-icons/bs';
import AWS from "aws-sdk";
import generateRandomImageName from '../../../methods/generateRandomImageName';
import toast from 'react-hot-toast';
import { useBirthdayWishMutation } from '../../../store/api/birthdayApi';
import { useSelector } from 'react-redux';
import { socket } from '../../../methods/Socket';

const BirthdayWishWidget = ({ data, showHideModal }) => {

    const [birthdayImage, setBirthdayImage] = useState([]);
    const [birthdayWish] = useBirthdayWishMutation()

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });



    const initialState = {
        text: `Happy Birthday to you ${data.fname} 🎂😀😊`,
        images: [],
    };

    const wishReducer = (state, action) => {
        switch (action.type) {
            case "SET_TEXT":
                return { ...state, text: action.payload };
            case "SET_IMAGES":
                return { ...state, images: action.payload };
            case "RESET_REDUCER":
                return {
                    preview: false,
                    text: "",
                    images: [],
                };
            default:
                return state;
        }
    };

    const [wish, dispatch] = useReducer(wishReducer, initialState);

    // to manage text in wish modal (took only event because of react quill)
    const handleChange = (event) => {
        dispatch({ type: "SET_TEXT", payload: event });
    };

    let selectedFIles;
    let compressedFiles = []
    // to manage images from input
    function handleImageFileChange(event) {
        setBirthdayImage([...event.target.files]);
        selectedFIles = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles];
        targetFilesObject.map((file) => {
            selectedFIles.push({
                name: file.name,
                image_url: URL.createObjectURL(file),
                type: file.type,
                size: file.size
            })
            if (file.type.slice(0, 5) === "image") {
                new Compressor(file, {
                    quality: 0.2, // Default compression quality
                    success(result) {
                        const file = new File([result], result.name, { type: result.type });
                        compressedFiles.push({
                            name: file.name,
                            image_url: file,
                            type: file.type,
                            size: file.size
                        })
                        dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
                    },
                    error(err) {
                        console.error('Image compression error:', err);
                    },
                })
            } else {
                compressedFiles.push({
                    name: file.name,
                    image_url: file,
                    type: file.type,
                    size: file.size
                })
            }
            return dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
        });
        event.target.value = ''
    }

    const handleBirthdayWish = async () => {
        if (birthdayImage.length > 0) {
            // S3 bucket implement start
            const S3_BUCKET = "ubuntu-social-media-2";
            const REGION = "ap-south-1";
            AWS.config.update({
                accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                secretAccessKey: "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",
            });

            const s3 = new AWS.S3({
                params: { Bucket: S3_BUCKET },
                region: REGION,
            });

            let imgArr = [];
            for (let i = 0; i < wish.images.length; i++) {
                let randomName = generateRandomImageName(wish.images[i].name)
                let fileSize = wish.images[i].size;
                let fileType = wish.images[i].type;
                let fileName = "post_" + randomName.split(" ").join("_").split("-").join("_");
                const params = {
                    Bucket: S3_BUCKET,
                    Key: fileName,
                    Body: wish.images[i].image_url,
                };

                var upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
                    // File uploading progress
                    console.log(
                        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                    );
                }).promise();

                imgArr.push({
                    image_url: "https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + fileName,
                    size: fileSize,
                    mime_type: fileType,
                });
            }
            await upload;
            // S3 bucket implement end
            let obj = {
                post_text: wish.text,
                post_type: "birthday",
                scope: "public",
                post_category_id: "66adea96caa699923687b878",
                images: imgArr,
                timeline_user_id: data._id
            }

            birthdayWish(obj).then((res) => {
                console.log(res)
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                    socket.emit("sendNotification", {
                        sender_id: userSliceData._id,
                        receiver_id: data._id,
                        meta_data: res?.data?.data._id,
                        meta_type: "Post",
                        notification_for: "birthday_wish",
                        room_id: data.user_room_id,
                    })

                } else {
                    toast.error(res?.data?.message)
                }
            })

        } else {
            let obj = {
                post_text: wish.text,
                post_type: "birthday",
                scope: "public",
                post_category_id: "66adea96caa699923687b878",
                images: [],
                timeline_user_id: data._id
            }
            birthdayWish(obj).then((res) => {
                console.log(res)
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message);
                    socket.emit("sendNotification", {
                        sender_id: userSliceData._id,
                        receiver_id: data._id,
                        meta_data: res?.data?.data._id,
                        meta_type: "Post",
                        notification_for: "birthday_wish",
                        room_id: data.user_room_id,
                    })

                } else {
                    toast.error(res?.data?.message)
                }
            })
        }
        showHideModal.setShowBirthdayModal(false)
        compressedFiles = []
        selectedFIles = []
        dispatch({ type: "RESET_REDUCER" });
        setBirthdayImage([]);
    }

    const removeImage = () => {
        compressedFiles = []
        selectedFIles = []
        dispatch({ type: "SET_IMAGES", payload: [] });
        setBirthdayImage([]);
    };

    return (
        <Fragment>
            <h5 className='mb-3'>Birthday post</h5>
            <ReactQuill
                value={wish.text}
                onChange={handleChange}
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, 7] }]
                    ],
                }}
                formats={["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent"]}
                placeholder="Share some what are you thinking?"
            />
            {
                /* FOR SINGLE IMAGE */
                birthdayImage.length === 1 ? (
                    <div className="img-bunch mt-2 style_with_1">
                        <div className="row g-1">
                            <div className="col-md-12">
                                <div className="preview_post_img preview_post_img_1">
                                    {(birthdayImage[0]?.type?.slice(0, 5) === "image") ?
                                        <img src={birthdayImage.length === 1 && URL.createObjectURL(birthdayImage[0])} alt="..." /> : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <button className="remove-btn" onClick={() => removeImage(0)}>
                            <LiaTimesSolid />
                        </button>
                    </div>
                ) : ("")
            }
            <div className='attachments'>
                <ul className="editPostOptions">
                    <li
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="Upload Photos"
                    >
                        <label htmlFor="edit_post_image" className="fileContainer">
                            <BsImage />
                            <input
                                type="file"
                                id="edit_post_image"
                                onChange={handleImageFileChange}
                                accept="image/*"
                            />
                        </label>
                    </li>
                </ul>
            </div>

            <div className='text-center mt-3'>
                <Button className='mx-1' variant="secondary" onClick={() => showHideModal.setShowBirthdayModal(false)}>Cancel</Button>
                <Button className='mx-1' variant="primary" onClick={handleBirthdayWish}>Wish</Button>
            </div>
        </Fragment>
    )
}

export default BirthdayWishWidget
