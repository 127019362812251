import React from "react";
import { NavLink } from "react-router-dom";
import './dashboardMenu.css'
const DashboardMenu = ({ links }) => {
   return (
      <ul className="profile-menu">
         {links.map((el, i) =>
            <li key={i}>
               <NavLink to={el.url}>
                  {el.title}
               </NavLink>
            </li>
         )}
      </ul>
   );
};

export default DashboardMenu;