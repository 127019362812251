import React from 'react'
import { Link } from 'react-router-dom'
import icon from '../../../assets/images/book-icon.png'
import './CreateYourGroupWidget.css'
import WidgetWrapper from '../WidgetWrapper'

const CreateYourGroupWidget = () => {
  return (
    <WidgetWrapper>
      <div className="banner medium-opacity purple">
        <div className="bg-image" style={{ backgroundImage: "url(images/resources/baner-widgetbg.jpg)" }}
        ></div>
        <div className="baner-top">
          <span>
            <img src={icon} alt="..." />
          </span>
        </div>
        <div className="banermeta">
          <p>create your own favourite group.</p>
          <span>like them all</span>
          <Link to={'/profile/groups/create'}>Create Now!</Link>
        </div>
      </div>
    </WidgetWrapper>
  )
}

export default CreateYourGroupWidget