import React from "react";
import "./loginPage.css";
import bgImage from "../../assets/images/social-media-3846597_1280.webp";
import logo from "../../assets/images/logo.svg";
import Login from "../../components/login/login.jsx";
import { isMobile } from "../../methods/checkMobile.js";

const LoginPage = () => {
  return (
    <>
      <section
        className="login-back min-vh-100"
        style={{ background: `url(${bgImage})` }}
      >
        <div className="signin login min-vh-100">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4">
                <Login />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
