import React, { useState } from 'react'
import UserItem from './UserItem/UserItem'
import './UserList.css'
import { Button, Modal } from 'react-bootstrap'

const UserList = ({ data, modalController }) => {
    return (
        <div className='userList'>
            {data?.map((el, i) => {
                return (
                    <UserItem key={i} data={el} modalController={modalController} />
                )
            })}
            {data?.length === 0 && <div className='text-center fs_16 mt-4'>No connection found!</div>}
        </div>
    )
}

export default UserList;