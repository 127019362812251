import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const pageApi = createApi({
    reducerPath: "pageApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        // set access token to headers
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["page"],
    endpoints: (builder) => ({

        // ALL TYPE OF PAGE LISTING
        // GET MY ALL CREAETD PAGES
        myPages: builder.query({
            query: (data) => ({
                url: "api/pages/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 11,
                    role: "admin",
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                },
            }),
            providesTags: ["page"],
        }),
        // GET ALL JOINED PAGES
        joinedPages: builder.query({
            query: (data) => ({
                url: "api/pages/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 12,
                    role: "member",
                    status: "active",
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["page"],
        }),
        //pending invitation list from pages
        getPendingInvitationList: builder.query({
            query: (data) => ({
                url: "api/pages/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 12,
                    role: "member",
                    join_type: "invited",
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["page"],
        }),
        // GET ALL SUGGESTED PAGES
        suggestedPages: builder.query({
            query: (data) => ({
                url: "api/pages/suggested",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 12,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["page"],
        }),

        // DETAILS PAGE MEMBER LISTING
        // GET PAGE ACTIVE MEMBERS
        getPageActiveMembers: builder.query({
            query: (data) => ({
                url: "api/pages/details",
                method: "GET",
                params: {
                    page_id: data.page_id,
                    member_page: data.pageNo,
                    member_per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                    status: 'active',
                    role: 'member',
                },
            }),
            providesTags: ["page"],
        }),
        // GET PAGE PENDING MEMBERS
        getPagePendingMembers: builder.query({
            query: (data) => ({
                url: "api/pages/details",
                method: "GET",
                params: {
                    page_id: data.page_id,
                    member_page: data.pageNo,
                    member_per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                    status: 'pending',
                    role: 'member',
                    join_type: "invited"
                },
            }),
            providesTags: ["page"],
        }),
        // GET SUGGESTED PAGE FOLLOWERS
        suggestedPageFollower: builder.query({
            query: (data) => ({
                url: "api/pages/member/suggested",
                method: "GET",
                params: {
                    page_id: data.page_id,
                    page: data.pageNo,
                    per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                },
            }),
            providesTags: ["page"],
        }),

        // CREATE NEW PAGE
        createPage: builder.mutation({
            query: (pageData) => {
                return {
                    url: "api/pages/create",
                    method: "POST",
                    body: pageData,
                };
            },
            invalidatesTags: ["page"],
        }),
        // all pages for job listing
        myPagesListForJob: builder.query({
            query: () => ({
                url: "api/pages/list",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 100,
                    role: "admin",
                    sort_by: "see_all"
                },
            }),
            providesTags: ["page"],
        }),
        // DELETE MY PAGE
        deletePage: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/pages/delete`,
                    method: "DELETE",
                    body: {
                        "page_id": ID
                    }
                };
            },
            invalidatesTags: ["page"],
        }),
        // GET SINGLE PAGE DATA
        getSinglePage: builder.query({
            query: (id) => ({
                url: "api/pages/details",
                method: "GET",
                params: {
                    page_id: id
                },
            }),
            providesTags: ["page"],
        }),
        // UPDATE PAGE DETAILS
        updatePage: builder.mutation({
            query: (data) => ({
                url: "api/pages/update",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["page"],
        }),
        // Request to join page
        joinRequest: builder.mutation({
            query: (data) => ({
                url: "api/pages/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["page"],
        }),
        // remove page member
        removePageMember: builder.mutation({
            query: (data) => ({
                url: "api/pages/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["page"],
        }),
        // accept pending member request
        acceptJoinRequest: builder.mutation({
            query: (data) => ({
                url: "api/pages/update",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["page"],
        }),
        // invite as member
        inviteFollower: builder.mutation({
            query: (data) => ({
                url: "api/member/invite",
                method: "PUT",
                body: {
                    meta_type: "Page",
                    meta_data: data.pageId,
                    members: {
                        user_id: data.userId,
                        role: "member"
                    }
                }
            }),
            invalidatesTags: ["page"],
        }),
        // accept pending request to join a page
        acceptPendingRequest: builder.mutation({
            query: (data) => ({
                url: "api/member/accept-invitation",
                method: "PUT",
                body: {
                    meta_type: "Page",
                    meta_data: data.pageId,
                    members: {
                        user_id: data.userId,
                        role: "member"
                    }
                }
            }),
            invalidatesTags: ["page"],
        }),
        // decline pending request to join a page
        declinePendingRequest: builder.mutation({
            query: (data) => ({
                url: "api/member/remove-invitation",
                method: "DELETE",
                body: {
                    meta_type: "Page",
                    meta_data: data.pageId,
                    members: {
                        user_id: data.userId,
                        role: 'member'
                    }
                }
            }),
            invalidatesTags: ["page"],
        }),

        // other user page apis
        // GET PAGE MEMBERS
        getPostById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 100,
                    module: "Post",
                    user_id: data.id
                }
            }),
            providesTags: ["page"],
        }),
        // GET CREATED PAGE
        getCreatedPageById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {
                    // page: 1,
                    per_page: 12,
                    module: "Page",
                    user_id: data.id,
                    role: "admin",
                    page_id: data.page_id,
                    page: data.pageNo,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                }
            }),
            providesTags: ["page"],
        }),
        // GET JOINED PAGE
        getJoinedPageById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {
                    // page: 1,
                    per_page: 12,
                    module: "Page",
                    user_id: data.id,
                    role: "member",
                    page_id: data.page_id,
                    page: data.pageNo,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                }
            }),
            providesTags: ["page"],
        }),
        // remove page member or leave from page
        removePageMemberOthers: builder.mutation({
            query: (data) => ({
                url: "api/pages/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["page"],
        }),
        // Request to join page
        joinRequestPageOthers: builder.mutation({
            query: (data) => ({
                url: "api/pages/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["page"],
        }),

        // Album APIs
        getAlbumPage: builder.query({
            query: (id) => {
                return {
                    url: "api/album",
                    method: "GET",
                    params: {
                        page: 1,
                        meta_type: "Page",
                        meta_data: id
                    }
                };
            },
            providesTags: ["page"],
        }),

        getAlbumPageOthers: builder.query({
            query: (data) => {
                return {
                    url: "api/users/profile-details",
                    method: "GET",
                    params: data
                };
            },
            providesTags: ["page"],
        }),

        allCountPageLists: builder.query({
            query: () => {
                return {
                    url: `api/all/count`,
                    method: "GET",
                    params: {
                        module: "Page"
                    },
                }
            },
            providesTags: ["page"],
        }),

        allOtherPageCountLists: builder.query({
            query: (id) => {
                return {
                    url: `api/all/count`,
                    method: "GET",
                    params: {
                        module: "Page",
                        user_id: id
                    },
                }
            },
            providesTags: ["page"],

        })

    }),
});

export const { useAllOtherPageCountListsQuery, useCreatePageMutation, useMyPagesQuery, useJoinedPagesQuery, useDeletePageMutation, useSuggestedPagesQuery, useGetSinglePageQuery, useUpdatePageMutation, useSuggestedPageFollowerQuery, useJoinRequestMutation, useGetPageActiveMembersQuery, useGetPagePendingMembersQuery, useRemovePageMemberMutation, useAcceptJoinRequestMutation, useInviteFollowerMutation, useGetPendingInvitationListQuery, useAcceptPendingRequestMutation, useDeclinePendingRequestMutation, useMyPagesListForJobQuery, useGetPostByIdQuery, useGetCreatedPageByIdQuery, useGetJoinedPageByIdQuery, useRemovePageMemberOthersMutation, useJoinRequestPageOthersMutation, useGetAlbumPageQuery, useGetAlbumPageOthersQuery,
    useAllCountPageListsQuery } = pageApi;
