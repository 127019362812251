import React, { useState } from 'react'
import './tableRow.css'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useChangeStatusMutation } from '../../../../store/api/Admin/categoryPanelApi'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'

const CategoryTableRow = ({data}) => {
    const [statusModal, setStatusModal] = useState(false)
    const [updateStatus] = useChangeStatusMutation()

    const handleStatus = () => {
        updateStatus({
            status     : data.status === 1 ? 0 : 1,
            category_id: data._id
        }).then((res)=>{
            if(res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }           
            setStatusModal(false) 
        })
    }

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    
    const permissionList = useUserPermissionListQuery()

    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <>
            <tr>
                <td>
                    <div className='table_info'>{data.category_name}</div>
                </td>    
                <td>
                    <div className='table_info'>{data.meta_desc}</div>
                </td>    
                <td>
                    <div className='table_info'>{data.meta_keyword}</div>
                </td>    
                <td>
                    <div className='table_info'>{data.search_tag}</div>
                </td>  
                <td>
                    <div className='table_actions'>
                        {(userInfo.user_type === 3 && user_permission_arr.includes("category_edit")) && <Link to={`edit/${data._id}`}>Edit</Link>}
                        {userInfo.user_type === 1 && <Link to={`edit/${data._id}`}>Edit</Link>}

                        {(userInfo.user_type === 3 && user_permission_arr.includes("category_active")) && <button onClick={()=>setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.status === 1 ? 'Active' : 'Deactive'}
                        </button>}                             
                        {userInfo.user_type === 1 && <button onClick={()=>setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.status === 1 ? 'Active' : 'Deactive'}
                        </button>}                             
                    </div>
                    {/* BLOCK MODAL */}
                    <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                        <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to {data.status !== 1 ? 'Active' : 'Deactive'} this category?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={handleStatus} className="mx-2">
                                    {data.status !== 1 ? 'Active' : 'Deactive'}
                                </Button>
                            </div>
                        </div>
                        </Modal.Body>
                    </Modal>
                </td>        
            </tr>
            
        </>
    )
}

export default CategoryTableRow