import React from 'react'
import { useAppliedJobListsQuery } from '../../store/api/jobApi';
import JoblistItem from '../../components/employment/JoblistingItem';

const EmploymentApplied = () => {
  const appliedJob = useAppliedJobListsQuery()
  const appliedJobList = appliedJob?.data?.appliedJobs;

  return (
    <div className="gap2 gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-3">

          </div>
          <div className="col-md-12">
            {appliedJobList?.length > 0 ? (
              appliedJobList?.map((el) => {
                return (
                  <JoblistItem data={el.employment_id} key={el._id} />
                )
              })
            ) : (
              <h6 className='text-center py-5 mb-0 mt-5'>No Job Found !!</h6>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmploymentApplied;
