import React, { useState, useEffect } from 'react'
import { AdminMenu } from '../../../components/components';
import { useGetReportListQuery } from '../../../store/api/Admin/adminReportApi';
import ReportRow from '../../../components/adminComponent/table/tableRow/ReportRow';
import { useSelector } from 'react-redux';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

const Reports = () => {
    const reportList = useGetReportListQuery()

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    const [permissionArr, setPermissionArr] = useState("")
    const userPermissionList = useUserPermissionListQuery()

    useEffect(() => {
        if (userPermissionList?.isSuccess === true) {
            let user_permission_arr = userPermissionList?.data?.permissions;
            setPermissionArr(user_permission_arr)
        }
    }, [userPermissionList]);

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className="d-md-flex justify-content-between align-items-center p-2">
                                <h5 className="mb-0">Reports</h5>
                                {(userInfo.user_type === 3 && permissionArr.includes("report_create")) && <Link to="/admin/report/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                                {userInfo.user_type === 1 && <Link to="/admin/report/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                            </div>
                            <table className='adminTable'>
                                <thead>
                                    <tr>
                                        <th><div className='table_head_info'>Reason Name</div></th>
                                        <th><div className='table_head_info'>Report Count</div></th>
                                        {(userInfo.user_type === 3 && permissionArr.includes("report_edit" || "report_active" || "report_delete")) && <th><div className='table_head_info text-center'>Actions</div></th>}
                                        {userInfo.user_type === 1 && <th><div className='table_head_info text-center'>Actions</div></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportList.isError && (
                                            <tr className='noData'>
                                                <td colSpan={2}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        reportList.isLoading && (
                                            <tr className='noData'>
                                                <td colSpan={2}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        reportList.isSuccess && (
                                            reportList?.data?.data.length > 0 ? (
                                                reportList?.data?.data.map((el, i) => {
                                                    return (
                                                        <ReportRow data={el} key={i} />
                                                    )
                                                })
                                            ) : (
                                                <tr className='noData'>
                                                    <td colSpan={2}>
                                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports;
