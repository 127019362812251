import React from 'react'
import { Button } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { DashboardMenuLink, FilterWrapper, MediaCard } from '../../../../../components/components'
import { Dropdown } from 'antd';
import { useGetAlbumQuery, useGetMyEventAlbumDataQuery } from '../../../../../store/api/albumApi'
import EventProfileLayout from '../../../EventProfileLayout';
import { useSelector } from 'react-redux';
import { useGetAlbumEventOthersQuery, useGetAlbumEventQuery, useGetSingleEventQuery } from '../../../../../store/api/eventApi';
import { useParams } from 'react-router-dom';

const items = [
    {
        label: <div onClick={() => { }}>Show Friends Public</div>,
        key: "0",
    },
    {
        label: <div onClick={() => { }}>Show Friends Private</div>,
        key: "1",
    },
    {
        label: <div onClick={() => { }}>Mute Notifications</div>,
        key: "2",
    }
]

const AllPictures = () => {
    // get id from param
    const params = useParams();
    const EVENTID = params.eventId;
    // get single Event Data
    const singleEvent = useGetSingleEventQuery(EVENTID);
    let eventWholeData = singleEvent?.data?.data;
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: eventWholeData?.user?._id,
        meta_type: "Event",
        meta_data: EVENTID
    }
    const AlbumDataOthers = useGetAlbumEventOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetAlbumEventQuery(EVENTID)
    const AllImages = eventWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

    const AllImagesData = AllImages?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    if (AlbumData.isLoading) {
        return (
            <>
                <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                    <h4 className="mb-0">Loading...</h4>
                </div>
            </>
        );
    }

    if (AlbumData.isError) {
        return (
            <>
                <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                    <h4 className="mb-0">Someting Went Wrong!!</h4>
                </div>
            </>
        );
    }

    return (
        <EventProfileLayout data={eventWholeData}>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/event/${EVENTID}/media`} title="Images" noCount />
                            <DashboardMenuLink url={`/event/${EVENTID}/media/video`} title="Videos" noCount />
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <h3 className="fs_18 mb-3">All Picture</h3>
                <div className='row gx-4'>
                    {AllImagesData?.map((elem, index) => {
                        return (
                            <div className="col-lg-3 col-md-3 col-6" key={index}>
                                <MediaCard imageData={elem} type={"allImage"} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </EventProfileLayout>
    )
}

export default AllPictures
