import React, { useEffect, useState } from 'react'
import './UserItem.css'
import { useDispatch, useSelector } from 'react-redux'
import { setChatRoomForChat, setUserForChat } from '../../../../../store/slice/chatSlice'
import { socket } from '../../../../../methods/Socket'

const UserItem = ({ data, type,  modalControl }) => {
  const dispatch = useDispatch();

  const userSet = (data) => {
    dispatch(setUserForChat(data));
  }
  const { online } = useSelector((store) => {
    return store.chat;
  })
  const { offline } = useSelector((store) => {
    return store.chat;
  })

  const alreadyChattedUserSet = () => {
    userSet(data.opponents[0])
    dispatch(setChatRoomForChat(data?.chatRoom?._id))
    socket.emit("switchRoom", data?.chatRoom?._id);
  }

  const newChat = () => {
    dispatch(setChatRoomForChat(data?.common_room_id))
    userSet(data)
    modalControl(false)
    socket.emit("switchRoom", data?.common_room_id);
  }
  
  if (type === "already-chatted") {
    return (
      <div className='chatUserItem' onClick={alreadyChattedUserSet}>
        <div className='chatUserItem_img'>
          <img src={data?.opponents[0]?.profile_picture[0]?.image_url} alt='...' />
          {
            (data?.opponents[0]?._id === online || data?.opponents[0]?.is_online === "1") && (
              <span className={`chatUserItem_status active`}></span>
            )
          }
        </div>
        <div className='chatUserItem_info'>
          <div style={{display:'flex',justifyContent:"space-between"}}>
            <div className='chatUserItem_name'>{data?.opponents[0]?.fullName}</div>
            <div className='chatUserItem_name'>{data?.chatRoom?.totalUnreadMessages}</div>
          </div>
          <div className='chatUserItem_lastMsg' dangerouslySetInnerHTML={{ __html: data?.chatRoom?.lastMessage }}></div>
        </div>
      </div >
    )
  }

  if (type === "connection") {
    return (
      <div className='chatUserItem' onClick={newChat}>
        <div className='chatUserItem_img'>
          <img src={data?.profile_picture[0]?.image_url} alt='...' />
          {/* {
            (data?.opponents[0]?._id === online || data?.opponents[0]?.is_online === "1") && (
              <span className={`chatUserItem_status active`}></span>
            )
          } */}
        </div>
        <div className='chatUserItem_info'>
          <div className='chatUserItem_name'>{data?.fullName}</div>
          <div className='chatUserItem_lastMsg'>Start a conversassion !!!</div>
        </div>
      </div>
    )
  }
}

export default UserItem