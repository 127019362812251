import React, { useEffect, useState } from "react";
import { Dropdown } from "antd";
import { Button } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetSinglePageQuery, useSuggestedPageFollowerQuery, useGetPagePendingMembersQuery, useGetPageActiveMembersQuery } from "../../../../store/api/pageApi";
import PageUserCard from "../../../../components/userCard/PageUserCard";
import PageProfileLayout from "../../PageProfileLayout";
import { DashboardMenuLink, FilterWrapper } from "../../../../components/components";
import { useSelector } from "react-redux";

const Suggested = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
   const [search, setSearch] = useState(searchParams.get("search") || "")
   const [sort, setSort] = useState(searchParams.get("sort") || "see_all")
   // get details who loggedin
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   // get id from param
   const params = useParams();
   const PAGEID = params.pageId;

   // get single Page Data
   const singlePage = useGetSinglePageQuery(PAGEID);
   let pageWholeData = singlePage?.data?.data;

   //page suggested followers
   const paramsObjSuggested = {
      page_id: PAGEID,
      pageNo: pageNo,
      searchVal: search,
      sortVal: sort
   }
   const suggestedFollowers = useSuggestedPageFollowerQuery(paramsObjSuggested)
   const suggestedFollowersList = suggestedFollowers?.data?.data;

   //page active followers
   const paramsObjActive = {
      page_id: PAGEID,
      role: 'member',
      status: 'active',
      member_page: 1,
      member_per_page: 100
   }
   const pageActiveFollowers = useGetPageActiveMembersQuery(paramsObjActive)

   //page pending followers
   const paramsObjPending = {
      page_id: PAGEID,
      role: 'member',
      status: 'pending',
      member_page: 1,
      member_per_page: 100
   }
   const pagePendingMembers = useGetPagePendingMembersQuery(paramsObjPending);

   useEffect(() => {
      let params = { pageNo };
      if (search) {
         params.search = search;
      }
      if (sort) {
         params.sort = sort;
      }
      setSearchParams(params);
   }, [pageNo, search, sort]);

   // const items = [
   //    {
   //       label: <div onClick={() => { }}>Show Friends Public</div>,
   //       key: "0",
   //    },
   //    {
   //       label: <div onClick={() => { }}>Show Friends Private</div>,
   //       key: "1",
   //    },
   //    {
   //       label: <div onClick={() => { }}>Mute Notifications</div>,
   //       key: "2",
   //    }
   // ]

   return (
      <PageProfileLayout data={pageWholeData}>
         <FilterWrapper>
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="DashboardMenuLinks">
                     <DashboardMenuLink title="Followers" count={pageActiveFollowers?.data?.total_count||0} url={`/page/${PAGEID}/followers`} />
                     {pageWholeData?.user?._id === userSliceData?._id && <DashboardMenuLink
                        title="Pending Followers"
                        count={pagePendingMembers?.data?.total_count||0}
                        url={`/page/${PAGEID}/followers/pending`}
                     />}
                     {pageWholeData?.user?._id === userSliceData?._id && <DashboardMenuLink
                        title="Suggested Followers"
                        count={suggestedFollowers?.data?.total_count||0}
                        url={`/page/${PAGEID}/followers/suggation`}
                     />}
                  </div>
               </div>
               <div className="col-lg-5">
                  <div className="gx-2 justify-content-md-end row">
                     <div className="col-lg-7 col-md-7 col-sm-7">
                        <form method="post">
                           <input
                              type="text"
                              placeholder="Search.."
                              className="form-control"
                              onChange={(e) => setSearch(e.target.value)}
                              value={searchParams.get("search") || search}
                           />
                        </form>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="select-options">
                           <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                              <option value={"see_all"}>See All</option>
                              <option value={"a_to_z"}>A to Z</option>
                              <option value={"z_to_a"}>Z to A</option>
                              <option value={"newest"}>Newest</option>
                              <option value={"oldest"}>Oldest</option>
                           </select>
                        </div>
                     </div>
                     {/* <div className="col-lg-1 col-md-1 col-sm-1">
                        <Dropdown
                           menu={{ items }}
                           placement="bottomRight"
                           trigger={["click"]}
                        >
                           <Button className="filterWrapDropBtn">
                              <BsThreeDotsVertical />
                           </Button>
                        </Dropdown>
                     </div> */}
                  </div>
               </div>
            </div>
         </FilterWrapper>
         {suggestedFollowers?.isLoading === true ? (
            <div className="gap2 gray-bg d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Loading...</h4>
            </div>
         ) : (
            <div className="central-meta">
               <div className="row gx-4">
                  {suggestedFollowersList?.map((elem, index) => {
                     return (
                        <div className="col-lg-2 col-md-3 col-6" key={index}>
                           <PageUserCard type={"PageSuggestedFollower"} data={elem} />
                        </div>
                     );
                  })}
                  {suggestedFollowersList?.length === 0 && <div className="col-12">
                     <h5 className="text-center mb-0 py-5 fs_18">No Suggested Follower Found !!!</h5>
                  </div>}
               </div>
            </div>
         )}
      </PageProfileLayout>
   );
};

export default Suggested;
