import React, { useState } from 'react'
import './tableRow.css'
import { Button, Modal } from 'react-bootstrap'
import { useChangeStatusMutation } from '../../../../store/api/Admin/groupPanelApi'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'

const GroupTableRow = ({ data }) => {
    const [statusModal, setStatusModal] = useState(false)
    const [updateStatus] = useChangeStatusMutation()

    const handleStatus = () => {
        updateStatus({
            status: data.status === 1 ? 0 : 1,
            _id: data._id
        }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
            setStatusModal(false)
        })
    }

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <>
            <tr>
                <td>
                    <div className='table_info'>
                        <Link to={`/group/${data?._id}/timeline`} target='_blank'>{data?.title}</Link>
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        {data?.category.cacategory_name}
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        {data?.members_count}
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        {data?.description}
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        {data?.scope}
                    </div>
                </td>
                <td>
                    <div className='table_actions text-center'>
                        {(userInfo.user_type === 3 && user_permission_arr.includes("group_active")) && <button onClick={() => setStatusModal(!statusModal)} className={data?.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data?.status === 1 ? 'Active' : 'Deactive'}
                        </button>}
                        {userInfo.user_type === 1 && <button onClick={() => setStatusModal(!statusModal)} className={data?.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data?.status === 1 ? 'Active' : 'Deactive'}
                        </button>}
                    </div>
                </td>
            </tr>
            {/* BLOCK MODAL */}
            <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to {data.status !== 1 ? 'Active' : 'Deactive'} this group?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleStatus} className="mx-2">
                                {data.status !== 1 ? 'Active' : 'Deactive'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GroupTableRow