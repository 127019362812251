import React, { useState } from 'react'
import "./UserCard.css";
import { defaultUserCover, defaultUserDp } from '../components';
import { Link, useNavigate } from 'react-router-dom';
import { useAcceptRequestMutation, useInviteFollowerMutation, useRemoveFromEventMutation } from '../../store/api/eventApi';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const EventUserCard = ({ type, data, eventID }) => {
   const [showDeleteModal, setDeleteModal] = useState(false);
   const [acceptRequest] = useAcceptRequestMutation()
   const [removeFromEvent] = useRemoveFromEventMutation();
   const navigate = useNavigate()

   const acceptEvent = () => {
      acceptRequest({ event_id: eventID, user_id: data?.user?._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   const removeFromEventHandler = () => {
      setDeleteModal(true)
   }
   const triggerRemove = () => {
      setDeleteModal(false)
      removeFromEvent({ event_id: eventID, user_id: data?.user?._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // to invite a suggested member
   const [inviteMember] = useInviteFollowerMutation()
   const inviteMemberFunc = () => {
      inviteMember({ userId: data?._id, eventId: eventID }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
   }

   if (type === "EventGuest") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.user?.cover_picture?.length > 0 ? data?.user?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.user?.profile_picture?.length > 0 ? data?.user?.profile_picture[0]?.image_url : defaultUserDp} alt='...' />
                  </figure>
                  <Link to={`/profile/${data?.user._id}/timeline`} title="">
                     {data?.user?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        {data.role !== "admin" ? <button className="btn btn-light w-100" onClick={removeFromEventHandler}>
                           Remove
                        </button> : <button className="btn btn-light w-100 bg-red" onClick={() => navigate(`/profile/${data?.user._id}/timeline`)}>
                           Admin
                        </button>}
                     </li>
                  </ul>
               </div>
            </div>
            {/*CONFIRM DELETE MODAL*/}
            <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to delete the post?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={triggerRemove} className="mx-2">
                           Delete
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "EventPendingGuest") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.user?.cover_picture?.length > 0 ? data?.user.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.user?.profile_picture?.length > 0 ? data?.user?.profile_picture[0]?.image_url : defaultUserDp} alt='...' />
                  </figure>
                  <Link to={`/profile/${data?.user?._id}/timeline`} title="">
                     {data?.user?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist">
                        <Link href="#" title="Delete">
                           <i className="fa fa-trash"></i>
                        </Link>
                     </li>
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={acceptEvent}>
                           Accept
                        </button>
                     </li>
                  </ul>
               </div>
            </div>
         </>
      );
   }

   else if (type === "EventSuggestedGuest") {
      
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.cover_picture?.length > 0 ? data?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.profile_picture?.length > 0 ? data?.profile_picture[0]?.image_url : defaultUserDp} alt='...' />
                  </figure>
                  <Link to={`/profile/${data?._id}/timeline`} title="">
                     {data?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={inviteMemberFunc}>
                           Invite
                        </button>
                     </li>
                  </ul>
               </div>
            </div>
         </>
      );
   }

}

export default EventUserCard;
