import React, { useEffect, useState } from 'react'
import { AdminMenu } from '../../../components/components'
import { useGetPageListQuery } from '../../../store/api/Admin/pagesPanelApi';
import { useSearchParams } from 'react-router-dom';
import GroupTableRow from '../../../components/adminComponent/table/tableRow/GroupTableRow';
import { Input, Pagination } from 'antd';
import PagesRow from '../../../components/adminComponent/table/tableRow/pagesRow';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';
import { useSelector } from 'react-redux';

const Groups = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPageNo] = useState(searchParams.get("page") || 1)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [sort, setSort] = useState(searchParams.get("sort") || "all")

    const perPage = 15;
    const pageList = useGetPageListQuery({
        page: page,
        per_page: perPage,
        search_keyword: search
    })
    const handlePageChange = (page) =>{
        setPageNo(page)
    } 
    const onSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(()=>{
        let params = { page };
        if (search) {
            params.search = search;
        }
        setSearchParams(params);
    },[page, search, sort, setSearchParams])

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    const [permissionArr, setPermissionArr] = useState("")
    const userPermissionList = useUserPermissionListQuery()

    useEffect(() => {
        if (userPermissionList?.isSuccess === true) {
            let user_permission_arr = userPermissionList?.data?.permissions;
            setPermissionArr(user_permission_arr)
        }
    }, [userPermissionList]);

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open/>
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center p-2'>
                                <h5 className='mb-0'>Pages</h5>
                                <div className=''>
                                    <Input placeholder="Search..." value={search} onChange={onSearch} style={{ width: 200 }} />
                                </div>
                            </div>
                            <table className='adminTable'>
                                <thead>
                                    <tr>
                                        <th><div className='table_head_info'>Name</div></th>
                                        <th><div className='table_head_info'>Category</div></th>
                                        <th><div className='table_head_info'>Members</div></th>
                                        <th><div className='table_head_info'>Description</div></th>
                                        <th><div className='table_head_info'>Scope</div></th>
                                        {(userInfo.user_type === 3 && permissionArr.includes("pages_active")) && <th><div className='table_head_info text-center'>Action</div></th>}
                                        {userInfo.user_type === 1 && <th><div className='table_head_info text-center'>Action</div></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pageList.isError && (
                                            <tr className='noData'>
                                                <td colSpan={6}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                                </td>
                                            </tr>
                                        ) 
                                    }
                                    {
                                        pageList.isLoading && (
                                            <tr className='noData'>
                                                <td colSpan={6}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        pageList.isSuccess && (
                                            pageList.data?.data.length > 0 ? (
                                                pageList.data?.data?.map((elem, i)=>{
                                                    return(
                                                        <PagesRow data={elem} key={i}/>
                                                    )
                                                })
                                            ) : (
                                                <tr className='noData'>
                                                    <td colSpan={6}>
                                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                            {
                                pageList.data?.data.length > 0 && (
                                    <div className='mt-3 text-center'>
                                        <Pagination 
                                            defaultCurrent={1}
                                            current={Number(page)}
                                            size="small"
                                            total={pageList.data?.total_count}
                                            defaultPageSize={perPage}    
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Groups