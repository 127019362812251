import React from 'react'
import './pageWidget.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useSuggestedGroupsQuery } from '../../../store/api/groupApi';
import { defaultUserDp } from "../../../components/components";
import WidgetWrapper from '../WidgetWrapper';
import { useSuggestedPagesQuery } from '../../../store/api/pageApi';

const PageWidget = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const suggestedGroups = useSuggestedPagesQuery({ pageNo: 1 });
    const suggestedGroupsData = suggestedGroups?.data?.data;

    return (
        <WidgetWrapper>
            <div className="widget">
                <h4 className="widget-title">
                    Explore Pages <Link to={`/profile/${userSliceData._id}/pages/suggested`} className="see-all">See all</Link>
                </h4>
                <ul className="group_list" >
                    {suggestedGroupsData?.slice(0, 5).map((el, i) => (
                        <li key={i}>
                            <figure>
                                <img
                                    src={el.display_image.length > 0 ? el?.display_image[0]?.image_url : defaultUserDp}
                                    alt=""
                                />
                            </figure>
                            <div className="group_list_meta">
                                <div className='info'>
                                    <Link to={'/'}>{el.title}</Link>
                                    <span>{el?.members_count} members</span>
                                </div>
                                <button type='button' className="underline btn btn-link"><Link to={`/page/${el._id}/timeline`}>View</Link></button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </WidgetWrapper>
    )
}

export default PageWidget