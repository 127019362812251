import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
   DashboardMenuLink,
   FilterWrapper,
   MediaCard,
} from "../../../components/components";
import GroupProfileLayout from "../GroupProfileLayout";
import { useParams } from "react-router-dom";
import { useGetSingleGroupQuery } from "../../../store/api/groupApi";
import { useGetAlbumOthersQuery, useGetAlbumQuery } from "../../../store/api/userApi";

const Videos = () => {
   // get id from param
   const params = useParams();
   const id = params.groupId;

   // get single Event Data
   const singleGroup = useGetSingleGroupQuery(id);
   let groupWholeData = singleGroup?.data?.data;

   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   const { userId } = useParams()
   const AlbumParamsOthers = {
      page: 1,
      per_page: 100,
      module: "Album",
      user_id: userId,
      meta_type: "User",
      meta_data: userId
   }

   const AlbumDataOthers = useGetAlbumOthersQuery(AlbumParamsOthers)

   const AlbumData = useGetAlbumQuery(userSliceData._id)
   const AllImages = userId === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

   const AllImagesData = AllImages?.filter(function (el) {
      return el.name === "uploads";
   })[0]?.gallery;

   let [data, setData] = useState([])

   useEffect(() => {
      if (AllImagesData !== undefined) {
         let AllImegesList = AllImagesData?.filter((ele) => ele?.file[0]?.mime_type.slice(0, 5) === "video")
         setData(AllImegesList)
      }
   }, [])


   return (
      <GroupProfileLayout data={groupWholeData}>
         {/* <GroupProfileLayout > */}
         <FilterWrapper>
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="DashboardMenuLinks">
                     <DashboardMenuLink url={`/group/${id}/media`} title="Images" noCount end />
                     <DashboardMenuLink url={`/group/${id}/media/video`} title="Videos" noCount end />
                  </div>
               </div>
            </div>
         </FilterWrapper>
         {/* <div className="central-meta">
            <div className="row gx-4">
               {data.map((elem, index) => {
                  return (
                     <div className="col-lg-3 col-md-3 col-6" key={index}>
                        <MediaCard type={"video"} />
                     </div>
                  );
               })}
            </div>
         </div> */}
         {data.length === 0 ?
            <div className="d-flex justify-content-center">
               No videos found !! </div> :
            <div className="central-meta">
               <div className="row gx-4">
                  {data.map((elem, index) => {
                     return (
                        <div className="col-lg-3 col-md-3 col-6" key={index}>
                           <MediaCard type={"video"} />
                        </div>
                     );
                  })}
                  {/* ok */}
               </div>
            </div>
         }
      </GroupProfileLayout>
   );
};

export default Videos;
