import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const quoteApi = createApi({
    reducerPath: "quoteApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["Quotes"],
    endpoints: (builder) => ({
        // CREATE QUOTE BY USER
        createQuote: builder.mutation({
            query: (data) => {
                return {
                    url: "api/quotes",
                    method: "POST",
                    body: {
                        quote: data.quoteText,
                        author: data.authorName
                    }
                }
            },
            invalidatesTags: ["Quotes"],
        }),

        // GET PAGE MEMBERS
        getQuoteList: builder.query({
            query: () => ({
                url: "api/quotes",
                method: "GET",
            }),
            providesTags: ["Quotes"],
        }),
    })
})


export const { useCreateQuoteMutation, useGetQuoteListQuery } = quoteApi;