import React, { useEffect, useState } from 'react'
import { DashboardMenuLink, EventCard, FilterWrapper } from '../../../components/components'
import ProfileLayout from '../ProfileLayout'
import { CreateWidget } from '../../../components/dashboard/Dashboard'
import { useMyEventsQuery, useAllCountEventListsQuery, useAllOtherEventCountListsQuery } from '../../../store/api/eventApi'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetCreatedEventByIdQuery, useGetJoinedEventByIdQuery } from '../../../store/api/eventApi'
import { Pagination } from 'antd'

const Events = () => {
    const { userId } = useParams();
    let countData = useAllCountEventListsQuery()
    let otherCountData = useAllOtherEventCountListsQuery(userId)
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [sort, setSort] = useState(searchParams.get("sort") || "see_all")

    const paginationPageChange = (page) => {
        setPageNo(page);
    }

    const infoObj = {
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }

    useEffect(() => {
        let params = { pageNo };
        if (search) {
            params.search = search;
        }
        if (sort) {
            params.sort = sort;
        }

        setSearchParams(params);
    }, [pageNo, search, sort]);

    // created events
    const myEvents = useMyEventsQuery(infoObj);
    const myEventsData = myEvents?.data?.data;


    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    // other user
    const otherUserParam = {
        id: userId,
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }
    const otherUserEvent = useGetCreatedEventByIdQuery(otherUserParam)

    return (
        <ProfileLayout handleDisplay="none">
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/events`} title="Events" count={userId === userSliceData._id ? countData.isSuccess ? countData.data.data.total_events : 0 : otherCountData?.isSuccess ? otherCountData?.data?.data?.total_events : 0} />
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/events/joined`} title="Joined Events" count={userId === userSliceData._id ? countData.isSuccess ? countData.data.data.total_joined_events : 0 : otherCountData?.isSuccess ? otherCountData?.data?.data?.total_joined_events : 0} />
                            {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/events/pending`} title="Pending Events" count={countData.isSuccess ? countData.data.data.total_pending_events : 0} />}
                            {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/events/suggested`} title="Suggested Events" count={countData.isSuccess ? countData.data.data.total_suggested_events : 0} />}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="gx-2 justify-content-md-end row">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <form method="post">
                                    <input
                                        type="text"
                                        placeholder="Search.."
                                        className="form-control"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={searchParams.get("search") || search}
                                    />
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="select-options">
                                    <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                                        <option value={"see_all"}>See All</option>
                                        <option value={"a_to_z"}>A to Z</option>
                                        <option value={"z_to_a"}>Z to A</option>
                                        <option value={"newest"}>Newest</option>
                                        <option value={"oldest"}>Oldest</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className='row gx-4'>
                    <div className="d-flex justify-content-between">
                    </div>
                    {userId === userSliceData._id && <div className='col-lg-3 col-md-4 col-6'>
                        <CreateWidget title={'Create Event'} url="/profile/event/create" />
                    </div>}

                    {
                        myEvents.isLoading && (
                            <div className="col-9">
                                <h5 className="text-center mb-0 py-5 fs_18" style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>Loading...</h5>
                            </div>
                        )
                    }

                    {
                        myEvents.isError && (
                            <div className="col-9">
                                <h5 className="text-center mb-0 py-5 fs_18" style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>Someting Went Wrong!!</h5>
                            </div>
                        )
                    }

                    {userId === userSliceData._id ? myEventsData?.map((elem, index) => {
                        return (
                            <div className='col-lg-3 col-md-4 col-6' key={index}>
                                <EventCard data={elem} type={"myEvent"} eventId={index} />
                            </div>
                        )
                    }) : otherUserEvent?.data?.data?.map((elem, index) => {
                        return (
                            <div className='col-lg-3 col-md-4 col-6' key={index}>
                                <EventCard data={elem} type={"others"} eventId={index} />
                            </div>
                        )
                    })}
                    {userId === userSliceData._id ? myEventsData?.length === 0 && <div className="col-9">
                        <h5 className="text-center mb-0 fs_18" style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>No Created Event !!!</h5>
                    </div> : otherUserEvent?.data?.data?.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 fs_18" style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>No Created Event !!!</h5>
                    </div>}
                </div>
                {/* pagination component */}
                <div>
                    {userId === userSliceData._id ? (
                        myEvents?.data?.total_count > 7 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={pageNo} total={myEvents?.data?.total_count} onChange={paginationPageChange} defaultPageSize={7} />
                    ) : (
                        otherUserEvent?.data?.total_count > 8 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={otherUserEvent?.data?.total_count} onChange={paginationPageChange} defaultPageSize={8} />
                    )}
                </div>
            </div>
        </ProfileLayout>
    )
}

export default Events