import React, { useEffect, useReducer, useState } from 'react'
import { AdminMenu } from '../../../components/components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import TagsInput from 'react-tagsinput'
import { useGetCategoryQuery, useUpdateCategoryMutation } from '../../../store/api/Admin/categoryPanelApi';
import toast from 'react-hot-toast';
import './category.css'
import { useNavigate, useParams } from 'react-router-dom';

const initialState = {
    category_id: '',
    category_name: '',
    search_tag: [],
    meta_keyword: [],
    meta_desc: '',
    alias_url: ''
};
const Edit = () => {
    const navigate = useNavigate()
    const params = useParams();
    const id = params.id;
    const getCategoryData = useGetCategoryQuery(id)
    const [updateCategory] = useUpdateCategoryMutation()
    const [validated, setValidated] = useState(false);
    const createCategoryReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return payload;
            case "SET_VALUE":
              return { ...state, [payload.name]: payload.data };
            case "SET_TAG":
                return { ...state, [payload.name]: payload.data };
            case "CLEAR_STATE":
                return initialState
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(createCategoryReducer, initialState);
    const handleChange = (e) => {
        dispatch({
           type: "SET_VALUE",
            payload: {
              name: e.target.name,
              data: e.target.value,
            },
        });
    };
    const handleTag = (data) => {
        dispatch({
            type: "SET_TAG",
            payload: {
              name: data.name,
              data: data.value,
            },
        });
    };
    const submitForm = async (e) => {
        e.preventDefault();
        if (e.currentTarget.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            updateCategory({
                category_id  : state.category_id,
                category_name: state.category_name,
                search_tag   : state.search_tag.toString(),
                meta_keyword : state.meta_keyword.toString(),
                meta_desc    : state.meta_desc,
                alias_url    : state.alias_url
            }).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                    navigate(`/admin/category`)
                }
            })
        }
    };
    useEffect(()=>{
        if(getCategoryData.status === 'fulfilled' ) {
            dispatch({
                type: "SET_STATE",
                payload: {
                    category_id  : id,
                    category_name: getCategoryData?.data?.data?.category_name,
                    search_tag   : getCategoryData?.data?.data?.search_tag.split(","),
                    meta_keyword : getCategoryData?.data?.data?.meta_keyword.split(","),
                    meta_desc    : getCategoryData?.data?.data?.meta_desc,
                    alias_url    : getCategoryData?.data?.data?.alias_url
                }
            });
        }
    }, [getCategoryData.status])

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open/>
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3'>
                            <h5 className='mb-2'>Create Category</h5>
                            <Row>
                                <Form.Group as={Col} controlId="cat_nm" className={`col-md-6 mb-3`}>
                                    <Form.Label>Category Name</Form.Label>
                                    <Form.Control                                        
                                        type="text"
                                        placeholder="Enter category name"
                                        value={state.category_name}
                                        onChange={handleChange}
                                        name="category_name"
                                    />
                                    <Form.Control.Feedback type="invalid">Please provide category name</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="search_tag" className={`col-md-6 mb-3`}>
                                    <Form.Label>Search Keywords</Form.Label>
                                    <TagsInput name="search_tag" value={state.search_tag} onChange={(data)=>handleTag({name: 'search_tag', value: data})} className='form-control tagInput' placeholder="Enter to add tag" onlyUnique/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="meta_keyword" className={`col-md-6 mb-3`}>
                                    <Form.Label>Meta Keywords</Form.Label>
                                    <TagsInput name="meta_keyword" value={state.meta_keyword} onChange={(data)=>handleTag({name: 'meta_keyword', value: data})} className='form-control tagInput' placeholder="Enter to add tag" onlyUnique/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="cat_nm" className={`col-md-6 mb-3`}>
                                    <Form.Label>Alias URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter alias URL"
                                        value={state.alias_url}
                                        onChange={handleChange}
                                        name="alias_url"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="meta_desc" className={`col-md-12 mb-3`}>
                                    <Form.Label>Meta Description</Form.Label>
                                    <Form.Control
                                        placeholder="Enter a meta description"
                                        type="text"
                                        value={state.meta_desc}
                                        onChange={handleChange}
                                        name="meta_desc"
                                        as="textarea"
                                        style={{ height: '100px' }}
                                    />
                                </Form.Group>
                            </Row>
                            <div className="mt-2 text-center">
                                <Button className="btn btn-primary" onClick={submitForm}>Create Category</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Edit