import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAuthToken, addUserSlice } from "../../store/slice/userSlice";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useLoginUserMutation } from "../../store/api/authApi";
import { ErrorAlert, SuccessAlert } from "../components";
import GoBackWrapper from "../GoBackWrapper/GoBackWrapper";
import "./login.css";
import socketIO from 'socket.io-client';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // state to handle from validation
  const [validated, setValidated] = useState(false);
  // handle state for email and password
  const [emailorphone, setEmailorphone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  // api call for login
  const [loginUser, response] = useLoginUserMutation();
  // to handle message modal
  const [show, setShow] = useState(false);

  // function to login
  const submitLogin = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      let user = { emailorphone, password };
      loginUser(user).then((res) => {
        if (res?.data?.data?.user_room_id) {
          const socket = socketIO.connect('https://ubuntu-social-media-api.notebrains.com/', {
            auth: {
              user_id: res?.data?.data?._id,
            }
          });
          socket.emit("myRoom", res?.data?.data?.user_room_id);
        }
        setShow(true)
        if (res?.data?.token) {
          dispatch(addUserSlice(res?.data?.data));
          dispatch(addAuthToken(res?.data?.token));
          const hasInterest = res?.data?.data?.area_of_interests.length > 0 ? true : false
          setTimeout(() => {
            if (hasInterest) {
              navigate("/");
            } else {
              navigate("/profile/area-of-interest");
            }
          }, [2000]);
        } else {
          setTimeout(() => {
            setShow(false)
          }, [2000]);
        }
      });
    }
    setValidated(true)
  };

  return (
    <>
      <GoBackWrapper>
        <div className="we-login-register">
          <div className="form-title">
            <i className="fa fa-key" /> login
            <span>log in now and meet the awesome Friends around the world.</span>
          </div>
          <Form
            noValidate
            className="we-form"
            method="post"
            onSubmit={submitLogin}
            validated={validated}
          >
            <div>
              <Form.Group className="mb-2">
                <Form.Label>
                  Email <span className="text-lowercase">or</span> Phone
                  <small className="text-lowercase">(Enter with Country Code)</small>*
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email or phone"
                  name="username"
                  value={emailorphone}
                  onChange={(e) => setEmailorphone(e.target.value)}
                  required
                  pattern={
                    isNaN(Number(emailorphone))
                      ? "^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$"
                      : "^[+][1-9]{1}[0-9]{1,2}[1-9]{1}[0-9]{9}$"
                  }
                />
                {/* <Form.Control.Feedback type="invalid">
                  Please enter your email or phone no...
                </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Password*</Form.Label>
                <div className="password_with_view">
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  // pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$"
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  >
                    {passwordVisible ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </InputGroup.Text>
                  {/* <Form.Control.Feedback type="invalid">
                    Please enter valid password
                  </Form.Control.Feedback> */}
                </div>
              </Form.Group>
              <Link to={"/forgot"} className="we-account login-link">
                Forgotten Password?
              </Link>
            </div>
            <Button variant="primary" type="submit" className="register-btn">
              Login
            </Button>
          </Form>
          <span>
            Don't have an account?{" "}
            <Link to={"/register"} className="we-account login-link">
              Register Now
            </Link>
          </span>
        </div>

        {/* Modal for message */}
        <Modal
          className="modalSmall text-center"
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          {/* SUCCESS POPUP */}
          {response.isSuccess === true && (
            <Modal.Body>
              <SuccessAlert
                title={response.isSuccess === true ? "Login Success" : ""}
              />
            </Modal.Body>
          )}
          {/* ERROR POPUP */}
          {response.isSuccess === false && (
            <Modal.Body>
              <ErrorAlert
                title={response.isSuccess === false ? "Login Failed" : ""}
              />
            </Modal.Body>
          )}
        </Modal>
      </GoBackWrapper>
    </>
  );
};

export default Login;
