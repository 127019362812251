import React from "react";
import TopContainer from "./topContainer/TopContainer";
import FreePlan from "./planCard/PlanCard";
import "./Plans.css";
import { useGetMembershipListQuery } from "../../store/api/walletApi";
// import { useGetMembershipListQuery } from "../../store/api/membershipApi";

const Plans = () => {
  const membershipList = useGetMembershipListQuery();

  if (membershipList.isLoading) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Loading...</h4>
        </div>
      </>
    );
  }

  if (membershipList.isError) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Someting Went Wrong!!</h4>
        </div>
      </>
    );
  }

  return (
    <div className="full_height">
      <div className="gap planMain">
        <div className="container">
          <div className="row">
            <TopContainer />
            {membershipList.data?.data.map((el, i) => {
              return <FreePlan data={el} key={i} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
