import React, { useEffect, useState } from 'react';
import AdminUserLogsTable from '../../../components/adminComponent/table/tableRow/AdminUserLogsTable';
import { useParams, useSearchParams } from 'react-router-dom';
import { AdminMenu } from '../../../components/components';
import { useGetSingleUserLogsQuery } from '../../../store/api/Admin/userPanelApi';
import { Pagination } from 'antd';

const AdminUserLogs = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { userId } = useParams();
    // state for page no
    const [page, setPageNo] = useState(searchParams.get("page") || 1);
    // get user logs from API
    const userLogs = useGetSingleUserLogsQuery({ userId: userId, page: page });
    // handle the page no for pagination
    const handlePageChange = (page) => {
        setPageNo(page);
    }
    useEffect(() => {
        let params = { page };
        setSearchParams(params);
    }, [page, setSearchParams])

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center p-2'>
                                <h5 className='mb-0'>User Logs for <span style={{ color: "var(--bs-primary)" }}>
                                    {userLogs?.data?.user?.fullName}</span>
                                </h5>
                            </div>
                            <div className='table_head has_toggle'>
                                <div className='row'>
                                    <div className='col-md-8'><div className='table_head_info'>Activity</div></div>
                                    {/* <div className='col-md-7'><div className='table_head_info'>Browser Info</div></div> */}
                                    <div className='col-md-4'><div className='table_head_info'>Time</div></div>
                                </div>
                            </div>
                            {
                                userLogs.isError && (
                                    <div className='col-12'>
                                        <div className='noData'>
                                            <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                userLogs.isLoading && (
                                    <div className='col-12'>
                                        <div className='noData'>
                                            <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                userLogs.isSuccess && (
                                    userLogs?.data?.data?.length > 0 ? (
                                        userLogs?.data?.data?.map((elem, i) => {
                                            return (
                                                <AdminUserLogsTable key={i} data={elem} />
                                            )
                                        })
                                    ) : (
                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!!</h5>
                                    )
                                )
                            }
                            {
                                userLogs?.data?.data?.length > 0 && (
                                    <div className='mt-3 text-center'>
                                        <Pagination
                                            defaultCurrent={1}
                                            current={Number(page)}
                                            size="small"
                                            total={userLogs?.data?.total_count}
                                            defaultPageSize={10}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminUserLogs
