import React, { useEffect, useState } from 'react'
import { AdminMenu, DotLoading, Post } from '../../../components/components'
import { useGetAdminCommunityPostListQuery } from '../../../store/api/Admin/adminCommunityPanelApi';

const Community = () => {
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [lastPage, setLastPage] = useState(1); // storing last page number

  const getAdminPostList = useGetAdminCommunityPostListQuery({
    page: currPage,
    per_page: 5
  })

  const handleInfiniteScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if ((scrollTop + clientHeight) === scrollHeight) {
      if (currPage < lastPage) {
        setCurrPage(currPage + 1)
      }
    }
  }

  useEffect(() => {
    if (getAdminPostList.status === "fulfilled") {
      setLastPage(getAdminPostList.data.last_page)
      window.addEventListener('scroll', handleInfiniteScroll);
      return () => {
        window.removeEventListener('scroll', handleInfiniteScroll);
      };
    }
  }, [handleInfiniteScroll])


  return (
    <div className='gap2 gray-bg full_height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <AdminMenu open />
          </div>
          <div className='col-md-9'>
            <div className="d-md-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Community</h5>
            </div>
            {getAdminPostList.isError && <div className='noData'><h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5></div>}
            {getAdminPostList.isLoading && <div className='noData'><h5 className="text-center mb-0 py-5 fs_18">Loading...</h5></div>}
            {
              getAdminPostList.isSuccess && (
                getAdminPostList.data.data.length > 0 ? (
                  <>
                    {
                      getAdminPostList.data.data.map((elem, i) => {
                        return (
                          <Post data={elem} index={i} key={i} caption={elem.post_text} type={"admin"} />
                        )
                      })
                    }
                    <div className="d-flex justify-content-center">
                      {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                    </div>
                  </>
                ) : (
                  <div className='noData'>
                    <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Community