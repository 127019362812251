import React from 'react'
import './dorLoading.css'
const DotLoading = () => {
  return (
    <>
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </>
  )
}

export default DotLoading