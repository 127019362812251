import React, { useEffect, useState } from "react";
import PageProfileLayout from "../PageProfileLayout";
import {
   Advertisement,
   Post,
   SideBar,
   CreatePost,
   InfoWidget,
   DotLoading,
} from "../../../components/components";
import PhotoWidget from "../../../components/widgets/PhotoWidget/PhotoWidget";
import { useParams } from "react-router-dom";
import { useGetSinglePageQuery } from "../../../store/api/pageApi";
import { useGetPageTimelineQuery } from "../../../store/api/postApi";
import { useSelector } from "react-redux";

const Timeline = () => {
   const [currPage, setCurrPage] = useState(1); // storing current page number
   const [lastPage, setLastPage] = useState(1); // storing last page number

   // get details who loggedin
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   // get id from param
   const params = useParams();
   const PAGEID = params.pageId;

   // get single Page Data
   const singlePage = useGetSinglePageQuery(PAGEID);
   let pageWholeData = singlePage?.data?.data;

   const paramObj = {
      page: currPage,
      meta_data: PAGEID,
      meta_type: "Page"
   }
   const AllPostsInPage = useGetPageTimelineQuery(paramObj)
   const AllPostDataInPage = AllPostsInPage?.data?.data;

   const handleInfiniteScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if ((scrollTop + clientHeight) === scrollHeight) {
         // This will be triggered after hitting the last element.
         // API call here while implementing pagination.

         if (currPage < lastPage) {
            setCurrPage(currPage + 1)
         }
      }
   }

   useEffect(() => {
      if (AllPostsInPage.status === "fulfilled") {
         setLastPage(AllPostsInPage?.data?.last_page)
         window.addEventListener('scroll', handleInfiniteScroll);
         return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
         };
      }
   }, [handleInfiniteScroll])

   // THIS WILL SHOW WHEN LODING THE PAGE
   if (AllPostsInPage.isLoading) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Loading...</h4>
            </div>
         </>
      );
   }

   // IF GET ERROR FROM API WILL SHOW THIS
   if (AllPostsInPage.isError) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Someting Went Wrong!!</h4>
            </div>
         </>
      );
   }

   const hasDesiredId = pageWholeData?.members?.some((item) => item.user._id === userSliceData._id);

   return (
      <>
         <PageProfileLayout data={pageWholeData}>
            <div className="row">
               <div className="col-md-3">
                  <SideBar className={"left"}>
                     <InfoWidget postlength={AllPostDataInPage.length} admin={pageWholeData?.user?.fullName} title={"Page Info"} description={pageWholeData?.description} totalMember={pageWholeData?.members?.length} />
                     <PhotoWidget type={"page"} link={`/page/${PAGEID}/media/all_picture`} />
                     <Advertisement />
                  </SideBar>
               </div>
               <div className="col-md-9">
                  {hasDesiredId && <CreatePost ModuleType={"Page"} ModuleID={PAGEID} type={"page"} />}
                  <div className="feedSec">
                     {AllPostDataInPage?.map((el, i) => (
                        <Post data={el} index={i} key={i} caption={el.text} type={"page"} />
                     ))}
                     <div className="d-flex justify-content-center">
                        {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                     </div>
                  </div>
               </div>
            </div>
         </PageProfileLayout>
      </>
   );
};

export default Timeline;
