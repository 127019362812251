import React, { useEffect, useState } from "react";
import EventProfileLayout from "../EventProfileLayout";
import { Advertisement, SideBar, CreatePost, Post, DotLoading } from "../../../components/components";
import LocationWidget from "../../../components/widgets/locationWidget/LocationWidget";
import HostWidget from "../../../components/widgets/hostWidget/HostWidget";
import { useParams } from "react-router-dom";
import { useGetSingleEventQuery } from "../../../store/api/eventApi";
import { useGetEventTimelineQuery } from "../../../store/api/postApi";
import { useSelector } from "react-redux";

const Discussion = () => {
   const [currPage, setCurrPage] = useState(1); // storing current page number
   const [lastPage, setLastPage] = useState(1); // storing current page number

   // get details who loggedin
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   // get id from param
   const params = useParams();
   const EVENTID = params.eventId;
   // get single Event Data
   const singleEvent = useGetSingleEventQuery(EVENTID);
   let eventWholeData = singleEvent?.data?.data;

   let AdminData = eventWholeData?.members?.filter((el) => {
      return el.role === "admin";
   })
   
   const paramObj = {
      page: currPage,
      meta_data: EVENTID,
      meta_type: "Event"
   }
   const AllPostsInEvent = useGetEventTimelineQuery(paramObj)
   const AllPostDataInEvent = AllPostsInEvent?.data?.data;

   const handleInfiniteScroll = () => {
      const { scrollTop, offsetHeight } = document.documentElement;
      if (window.innerHeight + scrollTop !== offsetHeight) {
         // This will be triggered after hitting the last element.
         // API call here while implementing pagination.
         if (currPage < lastPage) {
         setCurrPage(currPage + 1)
         }
      }
   }

   useEffect(() => {
      if (AllPostsInEvent.status === "fulfilled") {
         setLastPage(AllPostsInEvent.data.last_page)
         window.addEventListener('scroll', handleInfiniteScroll);
         return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
         };
      }
   }, [handleInfiniteScroll])

   const hasDesiredId = eventWholeData?.members?.some((item) => item.user._id === userSliceData._id && item.status === "active");

   // THIS WILL SHOW WHEN LODING THE PAGE
   if (AllPostsInEvent.isLoading) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Loading...</h4>
            </div>
         </>
      );
   }

   // IF GET ERROR FROM API WILL SHOW THIS
   if (AllPostsInEvent.isError) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Someting Went Wrong!!</h4>
            </div>
         </>
      );
   }

   return (
      <>
         <EventProfileLayout data={eventWholeData}>
            <div className="row">
               <div className="col-md-3">
                  <SideBar className={"left"}>
                     <HostWidget data={AdminData && AdminData[0]?.user} />
                     <Advertisement />
                  </SideBar>
               </div>
               <div className="col-md-6">
                  {hasDesiredId && <CreatePost ModuleType={"Event"} ModuleID={EVENTID} type={"event"} />}
                  <div className="feedSec">
                     {AllPostDataInEvent?.map((el, i) => (
                        <Post data={el} index={i} key={i} caption={el.text} type={"event"}/>
                     ))}
                     <div className="d-flex justify-content-center">
                        {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                     </div>
                  </div>
               </div>
               <aside className="col-md-3">
                  <SideBar className="right">
                     <LocationWidget data={eventWholeData?.venue} />
                  </SideBar>
               </aside>
            </div>
         </EventProfileLayout>
      </>
   );
};

export default Discussion;
