import React, { useState } from 'react'
import './tableRow.css'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Modal, Form } from 'react-bootstrap'
import { useDeactiveUserMutation, useRoleUserMutation } from '../../../../store/api/Admin/userPanelApi'
import { useGetRoleListQuery, useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import EpochTimeToDateTime from '../../../../methods/EpochTimeToDateTime'

const UserTableRow = ({ data }) => {
    const navigate = useNavigate()
    const [expand, setExpand] = useState(false)
    const [blockModal, setBlockModal] = useState(false)
    const [roleModal, setRoleModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    const [deactiveUser] = useDeactiveUserMutation()
    const [roleUser] = useRoleUserMutation()
    const roleList = useGetRoleListQuery()

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleBlock = () => {
        deactiveUser(data._id).then((res) => {
            setBlockModal(false)
        })
    }
    const selectedEle = roleList?.data?.data.find(ele => ele.name === selectedOption);
    const handleSaveClick = () => {
        let arg = { role_id: selectedEle._id, user_id: data._id }

        roleUser(arg).then((res) => {
            toast.success("User role updated successfully")
            setRoleModal(false)

        })
    };

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <>
            <div className={expand !== true ? `table_row_wrapper` : `table_row_wrapper activeExpand`}>
                <div className={'table_row has_toggle'}>
                    <button className='toggle' onClick={() => setExpand(!expand)}>
                        {expand !== true ? <FaPlus /> : <FaMinus />}
                    </button>
                    <div className='row'>
                        {/* full name */}
                        <div className='col-4'>
                            <div className='table_info'>
                                <Link to={`/profile/${data._id}/timeline`} target='_blank'>{data.fullName}</Link>
                                <div>{EpochTimeToDateTime(data.createdAt).date}</div>
                                <div>{EpochTimeToDateTime(data.createdAt).time}</div>
                            </div>
                        </div>
                        {/* reg on */}
                        {/* <div className='col-2'>
                            <div className='table_info text-center'>
                                <div>{EpochTimeToDateTime(data.createdAt).date}</div>
                                <div>{EpochTimeToDateTime(data.createdAt).time}</div>
                            </div>
                        </div> */}
                        {/* membership */}
                        <div className='col-2'>
                            <div className='table_info'>
                                {"Free"}
                            </div>
                        </div>
                        {/* total post */}
                        <div className='col-2'>
                            <div className='table_actions' >
                                {data.postCount}
                            </div>
                        </div>
                        {/* Last Loggedin */}
                        <div className='col-2'>
                            <div className='table_info'>
                                {data.lastLoginAt ? <>
                                    <div>{EpochTimeToDateTime(data.lastLoginAt).date}</div>
                                    <div>{EpochTimeToDateTime(data.lastLoginAt).time}</div>
                                </> : "No Data"}
                            </div>
                        </div>
                        {/* Action */}
                        <div className='col-2'>
                            <div className='table_actions'>
                                <div>
                                    {(userInfo.user_type === 3 && user_permission_arr.includes("user_block")) && <button onClick={() => setBlockModal(!blockModal)} className={data.is_active !== 1 ? 'bg-danger-subtle text-danger' : ''}>
                                        {data.is_active === 1 ? 'Block' : 'Unblock'}
                                    </button>}
                                    {userInfo.user_type === 1 && <button onClick={() => setBlockModal(!blockModal)} className={data.is_active !== 1 ? 'bg-danger-subtle text-danger' : ''}>
                                        {data.is_active === 1 ? 'Block' : 'Unblock'}
                                    </button>}
                                    <button onClick={() => navigate(`/profile/${data._id}/timeline`)} className={data.is_active !== 1 ? 'bg-secondary-subtle text-secondary' : ''}>
                                        view
                                    </button>
                                    {userInfo.user_type === 1 && <button onClick={() => navigate(`/admin/users/${data._id}/logs`)} className={data.is_active !== 1 ? 'bg-secondary-subtle text-secondary' : ''}>
                                        view logs
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    expand && (
                        <div className='expandTable'>
                            <div className='row gy-1'>
                                <div className='col-md-4'>
                                    <div className='table_info'>
                                        <strong>Country</strong> : {data.countries.country}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='table_info'>
                                        <strong>Email</strong> : {data.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {/* {/ BLOCK MODAL /} */}
            <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to {data.is_active === 1 ? 'Block' : 'Unblock'} this user?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleBlock} className="mx-2">
                                {data.is_active === 1 ? 'Block' : 'Unblock'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* {/ assign MODAL /} */}
            <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to {data.is_active === 1 ? 'Block' : 'Unblock'} this user?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleBlock} className="mx-2">
                                {data.is_active === 1 ? 'Block' : 'Unblock'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* {/ assign MODAL /} */}
            <Modal centered size="sm" show={roleModal} onHide={() => setRoleModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">chosse role</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setRoleModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Form.Select size='sm' onChange={handleSelectChange}>
                                <option value="select">selecct</option>
                                {roleList?.data?.data.map((ele, i) =>
                                    <option key={i} value={ele.name}>{ele.name}</option>
                                )}
                            </Form.Select>
                            <button onClick={handleSaveClick}>save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserTableRow