import React, { useEffect, useState } from 'react'
import logo from "../../../assets/images/logo.svg";
import MenuIcon from "../../../assets/images/svg/menuicon.png";
import style from './headerMobile.module.css'
import { Link, NavLink, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOffCanvas } from '../../../store/slice/headerSlice';
import { FiSettings } from 'react-icons/fi';
import SettingSidePanel from '../../settingSidePanel/settingSidePanel';
import { AdminMenu, defaultUserDp } from '../../components';
import { Offcanvas } from 'react-bootstrap';
import PercentComplete from '../../widgets/ProfileWidget/PercentComplete';
import { logOut } from '../../../store/slice/userSlice';
import { useSignoutUserMutation } from '../../../store/api/authApi';
import { BsFileEarmarkMedical } from 'react-icons/bs';
import DrawerLinkItem from './LinkItem';




const HeaderMobile = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [scrolled, setScrolled] = useState(false);
    let headerHeight;
    const handleScroll = () => {
        const offset = window.scrollY;
        if (document.getElementsByClassName("headerMobile").length > 0) {
          headerHeight = document.getElementsByClassName("headerMobile")[0].offsetHeight;
          if (offset > headerHeight) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    const [drawer, setDrawer] = useState(false);
    const toogleDrawer = () => {
        if (drawer) {
            setDrawer(false);
        } else {
            setDrawer(true);
        }
    };

    // TOGGLE OFFCANVAS
    const handleDrawer = () => {
        dispatch(setOffCanvas(true));
    };

    const [signout, response] = useSignoutUserMutation();
    const logout = () => {
        dispatch(logOut());
        signout();
        Navigate("/login")
    };


    // PROFILE DRAWER
    const [profileDrawer, setProfileDrawer] = useState(false)

    useEffect(() => {
        setDrawer(false)
    }, [pathname]);



    const menuItems = [
        {
            title: 'Home',
            icon: <BsFileEarmarkMedical />,
            link: '/'
        },
        {
            title: 'Connections',
            icon: <BsFileEarmarkMedical />,
            link: `/profile/${userSliceData._id}/connections`,
            children: [
                {
                    title: 'New Requests',
                    link: `/profile/${userSliceData._id}/new`,
                },
                {
                    title: 'Suggested Connections',
                    link : `/profile/${userSliceData._id}/suggested`,
                },
            ]
        },
        {
            title: 'Groups',
            icon: <BsFileEarmarkMedical />,
            link: `/profile/${userSliceData._id}/groups`,
            children: [
                {
                    title: 'Joined Groups',
                    link: `/profile/${userSliceData._id}/groups/joined`,
                },
                {
                    title: 'Pending Groups',
                    link : `/profile/${userSliceData._id}/groups/pending`,
                },
                {
                    title: 'Suggested Groups',
                    link : `/profile/${userSliceData._id}/groups/suggested`,
                }
            ]
        },
        {
            title: 'Events',
            icon: <BsFileEarmarkMedical />,
            link: `/profile/${userSliceData._id}/events`,
            children: [
                {
                    title: 'Joined Events',
                    link: `/profile/${userSliceData._id}/events/joined`,
                },
                {
                    title: 'Pending Events',
                    link : `/profile/${userSliceData._id}/events/pending`,
                },
                {
                    title: 'Suggested Events',
                    link : `/profile/${userSliceData._id}/events/suggested`,
                }
            ]
        },
        {
            title: 'Community',
            icon: <BsFileEarmarkMedical />,
            link: '/community'
        },
        {
            title: 'Employment',
            icon: <BsFileEarmarkMedical />,
            link: `/profile/${userSliceData._id}/connections`,
            children: [
                {
                    title: 'New Requests',
                    link: `/profile/${userSliceData._id}/new`,
                },
                {
                    title: 'Suggested Connections',
                    link : `/profile/${userSliceData._id}/suggested`,
                },
            ]
        },
        {
            title: 'Marketplace',
            icon: <BsFileEarmarkMedical />,
            link: `/marketplace`,
        },
    ]


    return (
        <>
            <header className={scrolled ? `headerMobile ${style.headerMobile} ${style.sticky}` : `headerMobile ${style.headerMobile}`}>
                <div className={`${style.container} container`}>      
                    <div className={style.toggleMenu} onClick={toogleDrawer}>
                        <img src={MenuIcon} height={30} width={30} alt="..." />
                    </div>          
                    <Link to={"/"}>
                        <img src={logo} alt="logo/Ubuntu" className={style.logo} />
                    </Link>
                    <div className={style.headerRight}>
                        <button type='button' className={style.rightBtn} onClick={handleDrawer}>
                            <FiSettings />
                        </button> 
                        <button type='button' className={`${style.profile}`} onClick={toogleDrawer}>
                            <img
                                src={userSliceData?.profile_picture?.length > 0 ? userSliceData?.profile_picture[0]?.image_url : defaultUserDp}
                                width={'45px'}
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </header>
            
            <SettingSidePanel/>
            
            {/* DRAWER */}
            <Offcanvas show={drawer} onHide={toogleDrawer} placement="start" className={`${style.menuDrawer}`}>
                <Offcanvas.Body className='p-0'>
                    <div className='d-flex flex-column min-vh-100 p-3'>
                        <div className={`${style.profile_info}`}>
                            <PercentComplete className={`my-0 ms-0 me-3 ${style.PercentComplete}`}/>
                            <div className={`${style.otherInfo}`}>
                                <Link to={`/profile/${userSliceData._id}/timeline`} className={`${style.userName}`}>
                                    {userSliceData.fname} {userSliceData.mname} {userSliceData.lname}
                                </Link>
                                <span className={style.email}>
                                    {userSliceData.email? (userSliceData.email) : (userSliceData.phone)}
                                </span>       
                                <span className={`${style.gender}`}>
                                    {userSliceData.gender === "1" ? "Male" : (
                                        userSliceData.gender === "2" ? "Female" : "Others"
                                    )} | {" "}{userSliceData.countries.country}
                                </span>
                            </div>
                        </div>
                        {userSliceData.user_type === 1 && <AdminMenu className={`${style.adminMenu}`} />}   
                        {/* <h4 className={"panel_title mb-2"}>Menu</h4> */}
                        <ul className={`${style.drawerNav}`}>
                            {
                                menuItems.map((elem, i)=>{
                                    return(
                                        <li key={i}>
                                            <DrawerLinkItem data={elem}/>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <button className='btn btn-danger d-block w-100 mt-auto' onClick={logout}>Logout</button>
                    </div>
                    {/* <button className='closeDrawer'></button> */}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default HeaderMobile