import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ApplicantList = ({ data }) => {
    const [statusModal, setStatusModal] = useState(false)
    return (
        <div>
            <Link onClick={() => setStatusModal(!statusModal)} className='fw-bold'>Applicants ({data.length})</Link>
            <Modal centered size="lg" show={statusModal} onHide={() => setStatusModal(false)}>
                <Modal.Body>
                    <div>
                        <h5 className="mb-3">Applicants List</h5>
                        <table className='table table-borderless table-striped'>
                            <thead>
                                <tr>
                                    <th className='fw-bold text-dark'>Name</th>
                                    <th className='fw-bold text-dark'>Email</th>
                                    <th className='fw-bold text-dark'>Current CTC</th>
                                    <th className='fw-bold text-dark'>Expected CTC</th>
                                    <th className='fw-bold text-dark'>Resume</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((elem, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{elem.first_name} {elem.last_name}</td>
                                            <td>{elem.email}</td>
                                            <td>{elem.current_ctc}</td>
                                            <td>{elem.expected_ctc}</td>
                                            <td>
                                                <div className='table_actions'>
                                                    <Link
                                                        to={elem.cv_file[0].image_url}
                                                        className='bg-success-subtle text-success'
                                                    >
                                                        Download
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="text-center">
                            <Button variant="danger" onClick={() => setStatusModal(false)} className="mx-2">
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ApplicantList
