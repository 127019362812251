import React from "react";
import { Link } from "react-router-dom";
import "./helpCard.css";

const HelpCard = () => {
   return (
      <div className="help-list helpHelpList">
         <h3>Getting Started</h3>
         <ul>
            <li>
               <Link to="support-and-help-detail.html" title="">
                  <i className="ti-direction"></i>How to Create your account
               </Link>
            </li>
            <li>
               <Link to="support-and-help-detail.html" title="">
                  <i className="ti-direction"></i>post video on my account?
               </Link>
            </li>
            <li>
               <Link to="support-and-help-detail.html" title="">
                  <i className="ti-direction"></i>Inviting friends to join winku
               </Link>
            </li>
            <li>
               <Link to="support-and-help-detail.html" title="">
                  <i className="ti-direction"></i>How do I search for my
                  friends?
               </Link>
            </li>
            <li>
               <Link to="support-and-help-detail.html" title="">
                  <i className="ti-direction"></i>Create and manage groups
               </Link>
            </li>
         </ul>
         <Link to="#" title="" data-ripple="">
            more help
         </Link>
      </div>
   );
};

export default HelpCard;
