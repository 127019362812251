import React, { Fragment, useState } from 'react';
import brithDayCake from '../../../assets/images/resources/cake.png'
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import BirthdayWishWidget from './BirthdayWishWidget';

const BirthdaySingle = ({ data }) => {
    const [showBirthdayModal, setShowBirthdayModal] = useState(false)
    return (
        <Fragment>
            <div>
                {data.category === "today" ? (
                    <h4>Today's Birthday</h4>
                ) : data.category === "tomorrow" ? (
                    <h4>Tomorrow's Birthday</h4>
                ) : (
                    <h4>Birthday on {data.category}</h4>
                )}
                <figure className="birthdayImage">
                    <img src={brithDayCake} alt="" />
                </figure>
                <h6 className="">
                    <img style={{ width: "45px", height: "45px", borderRadius: '50%', marginBottom: '10px' }} src={data?.profile_picture[0].image_url} alt="" />
                    <Link to={`/profile/${data._id}/timeline`} className='mb-4 d-block'>{data.fname} {data.lname}</Link>
                </h6>
                <div>
                    <div className='btn btn-primary' onClick={() => setShowBirthdayModal(true)}>Send Wish</div>
                </div>
            </div>
            <Modal show={showBirthdayModal} onHide={() => setShowBirthdayModal(false)} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <BirthdayWishWidget data={data} showHideModal={{ showBirthdayModal, setShowBirthdayModal }} />
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default BirthdaySingle
