import { createSlice } from "@reduxjs/toolkit";

const initialState = ""
const VideoSlice = createSlice({
    name: "VideoSlice",
    initialState,
    reducers: {
        setActiveVideo: (state, action) => {
            console.log(action.payload)      
            state = action.payload;
        }
    },
});

// set Offcanvas
export const { setActiveVideo } = VideoSlice.actions;
// Select Offcanvas
export const selectActiveVideo = (state) => state;

export default VideoSlice.reducer;
