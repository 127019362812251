import React, { useState } from 'react'
import { AdminMenu, CheckButton } from '../../../../components/components';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCreateRoleMutation, useGetPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi';

const AddRole = () => {
    const navigate = useNavigate()

    const [title, setTitle] = useState("");
    const [permissionArr, setPermissionArr] = useState([]);

    const handleChangeInterest = (e, elem) => {
        if (e.target.checked) {
            setPermissionArr([...permissionArr, elem]);
        } else {
            setPermissionArr(permissionArr.filter((item) => item !== elem));
        }
    };

    const permissionList = useGetPermissionListQuery();

    const [createRole] = useCreateRoleMutation();

    const submitForm = () => {
        createRole({ title: title, permissions: permissionArr }).then((res) => {
            console.log(res)
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
                navigate("/admin/roles-permission/role")
            }
        })
    }

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3 h-100'>
                            <h5>Create Role</h5>
                            <Form noValidate method="post">
                                <Row>
                                    <Col className='col-md-6'>
                                        <Form.Group controlId="cat_nm">
                                            <Form.Label>Role Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter report name" value={title} onChange={(e) => setTitle(e.target.value)} name="category_name" />
                                            <Form.Control.Feedback type="invalid">Please provide report name</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className='mt-2'>
                                            <Form.Label>Permission Name</Form.Label>
                                            <br />
                                            {permissionList?.data?.data?.map((el, i) => {
                                                return (
                                                    <CheckButton key={i} id={i} name={el.name} handleChange={(e) => handleChangeInterest(e, el._id)} checked={permissionArr.includes(el._id)} title={el.name} />
                                                )
                                            })}
                                        </Form.Group>
                                        <div className="mt-4 d-flex gap-2">
                                            <button type="button" className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                                            <Button type="button" className="btn btn-primary" onClick={submitForm}>Create Role</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddRole;
