import React from "react";
import searchImg from "../../../assets/images/resources/baner-forum.png";
import "./search.css";

const SearchbarContainer = () => {
   return (
      <div className="page-header helpPageHeader">
         <div className="header-inner helpHeaderInner">
            <h2>Support &amp; Help</h2>
            <form method="post">
               <input type="text" placeholder="Search Help" />
               <button type="submit">
                  <i className="fa fa-search"></i>
               </button>
            </form>
            <p>
               Here you’ll find help and support all questions about template
               and ready made elemets as you want. you can use to show on your
               custom pages.
            </p>
         </div>
         <figure>
            <img src={searchImg} alt="" />
         </figure>
      </div>
   );
};

export default SearchbarContainer;
