import React, { useState } from 'react'
import { AdminMenu } from '../../../components/components'
import { useGetCategoryListQuery } from '../../../store/api/Admin/categoryPanelApi'
import { Link, useSearchParams } from 'react-router-dom';
import CategoryTableRow from '../../../components/adminComponent/table/tableRow/CategoryTableRow';
import { FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';

const Category = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPageNo] = useState(searchParams.get("page") || 1)
    const perPage = 15;
    const categoryList = useGetCategoryListQuery({
        page: page,
        per_page: perPage
    })

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center p-2'>
                                <h5 className='mb-0'>Category</h5>
                                {(userInfo.user_type === 3 && user_permission_arr.includes("category_create")) && <Link to="/admin/category/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                                {userInfo.user_type === 1 && <Link to="/admin/category/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                            </div>
                            <table className='adminTable'>
                                <thead>
                                    <tr>
                                        <th><div className='table_head_info'>Category Name</div></th>
                                        <th><div className='table_head_info'>Meta Desc</div></th>
                                        <th><div className='table_head_info'>Meta Keyword</div></th>
                                        <th><div className='table_head_info'>Search Tag</div></th>
                                        {(userInfo.user_type === 3 && user_permission_arr.includes("category_edit" || "category_active")) && <th><div className='table_head_info'>Action</div></th>}
                                        {userInfo.user_type === 1 && <th><div className='table_head_info'>Action</div></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {categoryList.isError && (
                                        <tr className='noData'>
                                            <td colSpan={5}>
                                                <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                            </td>
                                        </tr>
                                    )}
                                    {
                                        categoryList.isLoading && (
                                            <tr className='noData'>
                                                <td colSpan={5}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        categoryList.isSuccess && (
                                            categoryList.data?.data.length > 0 ? (
                                                categoryList.data?.data?.map((elem, i) => {
                                                    return (
                                                        <CategoryTableRow key={i} data={elem} />
                                                    )
                                                })
                                            ) : (
                                                <tr className='noData'>
                                                    <td colSpan={5}>
                                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category