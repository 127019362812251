import React from 'react'

const TermsAndConditions = () => {
  return (
    <div>
      Ubuntu Terms and conditions
    </div>
  )
}

export default TermsAndConditions
