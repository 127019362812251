import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    userSliceData: JSON.parse(localStorage.getItem("userData")) || {},
    userAuthToken: JSON.parse(localStorage.getItem("authToken")) || "",
  },
  reducers: {
    addUserSlice(state, action) {
      state.userSliceData = action.payload;
      localStorage.setItem("userData", JSON.stringify(action.payload));
    },
    addAuthToken(state, action) {
      state.userAuthToken = action.payload;
      localStorage.setItem("authToken", JSON.stringify(action.payload));
    },
    logOut(state) {
      state.userSliceData = {};
      state.userAuthToken = "";
      localStorage.removeItem("userData");
      localStorage.removeItem("authToken");
    },
  },
});
export const { addUserSlice, addAuthToken, deleteUser, setStatus, logOut } = userSlice.actions;
export default userSlice.reducer;

