import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const searchApi = createApi({
    reducerPath: "searchApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["search"],
    endpoints: (builder) => ({
        // search Something
        searchSomething: builder.query({
            query: (data) => ({
                url: "api/common/serach",
                method: "GET",
                params: {
                    search: data.search,
                    type: data.type
                },
            }),
            providesTags: ["search"],
        }),
    })
})

export const { useSearchSomethingQuery } = searchApi;