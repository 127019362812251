import React, { useState } from "react";

// import "./resetPassword.css"

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { addAuthToken,addUserSlice } from "../../../store/slice/userSlice";
import { useLoginUserMutation } from "../../../store/api/authApi";
import GoBackWrapper from "../../../components/GoBackWrapper/GoBackWrapper";

// import "../../../components/login/login.css";

const ResetPassword = () => {
  const [validated, setValidated] = useState(false);
  const [emailorphone, setEmailorphone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginUser, response] = useLoginUserMutation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitLogin = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === true) {
      let user = { emailorphone, password };
      loginUser(user).then((res) => {
        handleShow();
        if (res?.data?.token) {
          dispatch(addUserSlice(res?.data?.data));
          dispatch(addAuthToken(res?.data?.token));
          const hasInterest = res?.data?.data?.area_of_interests.length > 0 ? true : false
          setTimeout(() => {
            if(hasInterest) {
              navigate("/");
            } else {
              navigate("/profile/area-of-interest");
            }
          }, [2000]);
        } else {
          setTimeout(() => {
            handleClose();
          }, [2000]);
        }
      });
    }
    setValidated(true);
  };

  return (
    <>
          {/* <GoBackWrapper> */}
      <div className="we-login-register-reset">
        <div className="form-title">
          {/* <GiHouseKeys className="key-logo" /> */}
          <i className="fa fa-key" /> Reset Password
   
        </div>
        <Form
          noValidate
          validated={validated}
          className="we-form"
          method="post"
          onSubmit={submitLogin}
        >
          <div>
            <Form.Group className="mb-2">
              <Form.Label>
               Old Password
         
              </Form.Label>
              <div className="password_with_view">
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$"
                />
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                >
                  {passwordVisible ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </InputGroup.Text>
    
              </div>

            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label> New Password</Form.Label>
              <div className="password_with_view">
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$"
                />
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                >
                  {passwordVisible ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </InputGroup.Text>
                {/* <Form.Control.Feedback type="invalid">
                  Please enter valid password
                </Form.Control.Feedback> */}
              </div>
            </Form.Group>
           
          </div>
          <Button variant="primary" type="submit" className="register-btn">
            Login
          </Button>
        </Form>

      </div>

      {/* Modal for message */}
      <Modal
        className="modalSmall text-center"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        {/* SUCCESS POPUP */}
        {response.isSuccess === true && (
          <Modal.Body>
            {/* <SuccessAlert
              title={response.isSuccess === true ? "Login Success" : ""}
            /> */}
          </Modal.Body>
        )}
        {/* ERROR POPUP */}
        {response.isSuccess === false && (
          <Modal.Body>
            {/* <ErrorAlert
              title={response.isSuccess === false ? "Login Failed" : ""}
            /> */}
          </Modal.Body>
        )}
      </Modal>
      {/* </GoBackWrapper> */}
    </>
  );
};

export default ResetPassword;
