import React, { useReducer, useRef, useState } from "react";
import style from "./CreatePage.module.css";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useCreatePageMutation } from "../../../../store/api/pageApi";
import AWS from "aws-sdk";
import toast from "react-hot-toast";
import generateRandomImageName from "../../../../methods/generateRandomImageName";
import { AiOutlineDelete } from "react-icons/ai";
import { defaultUserCover, defaultUserDp } from "../../../../components/components";
import { useGetCategoryListQuery } from "../../../../store/api/categoryMaster";
import { useSelector } from "react-redux";
import { socket } from "../../../../methods/Socket";

const CreatePage = () => {
   const navigate = useNavigate()
   const [validated, setValidated] = useState(false);
   const [profile, setProfile] = useState("")
   const [cover, setCover] = useState("")
   const inputRefProfile = useRef(null);
   const inputRefCover = useRef(null);
   const [createPage] = useCreatePageMutation()
   const categoryList = useGetCategoryListQuery()

   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });



   //initial state for create page form
   const initialState = {
      title: "",
      description: "",
      cover_image: [],
      display_image: [],
      scope: "",
      category: ""
   };

   //reducer for create page form
   const createPageReducer = (state, { type, payload }) => {
      switch (type) {
         case "SET_EVENT":
            return { ...state, [payload.name]: payload.data };
         case "SET_COVER":
            return { ...state, [payload.name]: payload.data };
         case "SET_IMAGE":
            return { ...state, [payload.name]: payload.data };
         default:
            return state;
      }
   };

   const [state, dispatch] = useReducer(createPageReducer, initialState);
   //to change title & description
   const handleChange = (e) => {
      dispatch({
         type: "SET_EVENT",
         payload: {
            name: e.target.name,
            data: e.target.value,
         },
      });
   };

   //to set cover_image in state
   const handleCover = (e) => {
      setCover(e.target.files[0])
      const selectedFIles = [];
      const targetFiles = e.target.files;
      const targetFilesObject = [...targetFiles];

      targetFilesObject.map((file) => {
         return selectedFIles.push(URL.createObjectURL(file));
      });

      if (selectedFIles.length > 0) {
         dispatch({
            type: "SET_IMAGE",
            payload: {
               name: e.target.name,
               data: [...selectedFIles],
            },
         });
      }
   };

   //to set display_image in state
   const handleImage = (e) => {
      setProfile(e.target.files[0])
      const selectedFIles = [];
      const targetFiles = e.target.files;
      const targetFilesObject = [...targetFiles];

      targetFilesObject.map((file) => {
         return selectedFIles.push(URL.createObjectURL(file));
      });

      if (selectedFIles.length > 0) {
         dispatch({
            type: "SET_IMAGE",
            payload: {
               name: e.target.name,
               data: [...selectedFIles],
            },
         });
      }
   };

   // create page form submit
   const submitForm = async (e) => {
      e.preventDefault();
      if (e.currentTarget.checkValidity() === false) {
         e.stopPropagation();
         setValidated(true);
      } else {
         // when device online it will work
         if (navigator.onLine) {
            // when cover image and display image both present
            if (profile.name && cover.name) {
               // for profile picture start
               const displayImage_S3_BUCKET = "ubuntu-social-media-2";
               const displayImage_REGION = "ap-south-1";
               AWS.config.update({
                  accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                  secretAccessKey: "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",
               });
               const displayImage_s3 = new AWS.S3({
                  params: { Bucket: displayImage_S3_BUCKET },
                  region: displayImage_REGION,
               });
               let randomNameDisplayImage = generateRandomImageName(profile.name);
               let fileName = "page_" + randomNameDisplayImage.split(" ").join("_").split("-").join("_");
               let fileSize = profile.size;
               let fileType = profile.type;
               const displayImage_params = {
                  Bucket: displayImage_S3_BUCKET,
                  Key: fileName,
                  Body: profile,
               };

               var uploadDisplayImage = displayImage_s3
                  .putObject(displayImage_params)
                  .on("httpUploadProgress", (evt) => {
                     // File uploading progress
                     console.log(
                        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                     );
                  })
                  .promise();

               await uploadDisplayImage.then((data, err) => { });

               let displayImageArr = []

               displayImageArr.push({
                  image_url:
                     ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
                        fileName),
                  size: fileSize,
                  mime_type: fileType,
               });
               // for profile picture end

               // for cover picture start
               const coverImage_S3_BUCKET = "ubuntu-social-media-2";
               const coverImage_REGION = "ap-south-1";
               AWS.config.update({
                  accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                  secretAccessKey: "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",
               });
               const coverImage_s3 = new AWS.S3({
                  params: { Bucket: coverImage_S3_BUCKET },
                  region: coverImage_REGION,
               });
               let randomNameCoverImage = generateRandomImageName(cover.name);
               let coverName = "page_" + randomNameCoverImage.split(" ").join("_").split("-").join("_");
               let coverSize = cover.size;
               let coverType = cover.type;

               const coverImage_s3params = {
                  Bucket: coverImage_S3_BUCKET,
                  Key: coverName,
                  Body: cover,
               };

               var coverImage_upload = coverImage_s3.putObject(coverImage_s3params).on("httpUploadProgress", (evt) => {
                  // File uploading progress
                  console.log(
                     "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                  );
               }).promise();

               await coverImage_upload.then((data, err) => { });

               let coverImageArr = []

               coverImageArr.push({
                  image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
                  size: coverSize,
                  mime_type: coverType,
               });
               //for cover picture end

               let obj = {
                  scope: state.scope,
                  title: state.title,
                  description: state.description,
                  display_image: displayImageArr,
                  cover_image: coverImageArr,
                  category_id: state.category
               }

               createPage(obj).then((res) => {
                  if (res?.data?.error === 0) {
                     toast.success(res?.data?.message)
                     navigate(`/profile/${userSliceData._id}/pages`)
                     if (state.scope === "public") {
                        socket.emit("sendNotificationDataOthers", {
                           sender_id: userSliceData._id,
                           meta_data: res.data?.data?._id,
                           meta_type: "Page",
                           notification_for: "Create",
                        });
                     }
                  }
               })
            }

            //when cover image only present
            else if (cover.name) {
               // for cover picture start
               const coverImage_S3_BUCKET = "ubuntu-social-media-2";
               const coverImage_REGION = "ap-south-1";
               AWS.config.update({
                  accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                  secretAccessKey: "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",
               });
               const coverImage_s3 = new AWS.S3({
                  params: { Bucket: coverImage_S3_BUCKET },
                  region: coverImage_REGION,
               });
               let randomNameCoverImage = generateRandomImageName(cover.name);
               let coverName = "page_" + randomNameCoverImage.split(" ").join("_").split("-").join("_");
               let coverSize = cover.size;
               let coverType = cover.type;

               const coverImage_s3params = {
                  Bucket: coverImage_S3_BUCKET,
                  Key: coverName,
                  Body: cover,
               };

               var only_coverImage_upload = coverImage_s3.putObject(coverImage_s3params).on("httpUploadProgress", (evt) => {
                  // File uploading progress
                  console.log(
                     "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                  );
               }).promise();

               await only_coverImage_upload.then((data, err) => { });

               let coverImageArr = []

               coverImageArr.push({
                  image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
                  size: coverSize,
                  mime_type: coverType,
               });

               let obj = {
                  scope: state.scope,
                  title: state.title,
                  description: state.description,
                  display_image: [],
                  cover_image: coverImageArr,
                  category_id: state.category
               }

               createPage(obj).then((res) => {
                  if (res?.data?.error === 0) {
                     toast.success(res?.data?.message)
                     navigate(`/profile/${userSliceData._id}/pages`)
                     if (state.scope === "public") {
                        socket.emit("sendNotificationDataOthers", {
                           sender_id: userSliceData._id,
                           meta_data: res.data?.data?._id,
                           meta_type: "Page",
                           notification_for: "Create",
                        });
                     }
                  }
               })
               //for cover picture end
            }

            // when display image only present
            else if (profile.name) {
               // for profile picture start
               const displayImage_S3_BUCKET = "ubuntu-social-media-2";
               const displayImage_REGION = "ap-south-1";
               AWS.config.update({
                  accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                  secretAccessKey: "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",
               });
               const displayImage_s3 = new AWS.S3({
                  params: { Bucket: displayImage_S3_BUCKET },
                  region: displayImage_REGION,
               });
               let randomNameDisplayImage = generateRandomImageName(profile.name);
               let fileName = "page_" + randomNameDisplayImage.split(" ").join("_").split("-").join("_");
               let fileSize = profile.size;
               let fileType = profile.type;
               const displayImage_params = {
                  Bucket: displayImage_S3_BUCKET,
                  Key: fileName,
                  Body: profile,
               };

               var only_uploadDisplayImage = displayImage_s3
                  .putObject(displayImage_params)
                  .on("httpUploadProgress", (evt) => {
                     // File uploading progress
                     console.log(
                        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                     );
                  })
                  .promise();

               await only_uploadDisplayImage.then((data, err) => { });

               let displayImageArr = []

               displayImageArr.push({
                  image_url:
                     ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
                        fileName),
                  size: fileSize,
                  mime_type: fileType,
               });

               let obj = {
                  scope: state.scope,
                  title: state.title,
                  description: state.description,
                  display_image: displayImageArr,
                  cover_image: [],
                  category_id: state.category
               }

               createPage(obj).then((res) => {
                  if (res?.data?.error === 0) {
                     toast.success(res?.data?.message)
                     navigate(`/profile/${userSliceData._id}/pages`)
                     if (state.scope === "public") {
                        socket.emit("sendNotificationDataOthers", {
                           sender_id: userSliceData._id,
                           meta_data: res.data?.data?._id,
                           meta_type: "Page",
                           notification_for: "Create",
                        });
                     }
                  }
               })
               // for profile picture end
            }

            //when cover image and display image both not present 
            else {
               let obj = {
                  scope: state.scope,
                  title: state.title,
                  description: state.description,
                  display_image: [],
                  cover_image: [],
                  category_id: state.category
               }

               createPage(obj).then((res) => {
                  if (res?.data?.error === 0) {
                     toast.success(res?.data?.message)
                     navigate(`/profile/${userSliceData._id}/pages`)
                     if (state.scope === "public") {
                        socket.emit("sendNotificationDataOthers", {
                           sender_id: userSliceData._id,
                           meta_data: res.data?.data?._id,
                           meta_type: "Page",
                           notification_for: "Create",
                        });
                     }
                  }
               })
            }
         }
         // when device not online it will work
         else {
            toast.error("Please turn on internet!!!");
         }
      }
   };

   //to remove display image
   const removeDisplayImage = () => {
      setProfile("")
      dispatch({
         type: "SET_IMAGE",
         payload: {
            name: "display_image",
            data: [],
         },
      });
      inputRefProfile.current.value = null;
   }

   // to remove cover image
   const removeCoverImage = () => {
      setCover("")
      dispatch({
         type: "SET_COVER",
         payload: {
            name: "cover_image",
            data: [],
         },
      });
      inputRefCover.current.value = null;
   }

   return (
      <div className="gap2 gray-bg full_height">
         <div className="container">
            <div className="row">
               <div className={"col-md-7 col-lg-8"}>
                  <Card className={`${style.central_meta}`}>
                     <Card.Body className={`${style.card_body}`}>
                        <Card.Title className={`${style.card_title} mb-3 fs-4`} >
                           Create Your Page
                        </Card.Title>
                        <Form noValidate validated={validated} method="post" onSubmit={submitForm} >
                           <Row>
                              <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                 <Form.Label>Page name*</Form.Label>
                                 <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter your page name"
                                    value={state.title}
                                    onChange={handleChange}
                                    name="title"
                                 />
                                 {/* <Form.Control.Feedback type="invalid">Please provide page name</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                 <Form.Label>Page scope*</Form.Label>
                                 <Form.Select required aria-label="Default select example" value={state.scope} name="scope" onChange={handleChange}>
                                    <option value="">Select scope</option>
                                    <option value="public">Public</option>
                                    <option value="private">Private</option>
                                 </Form.Select>
                                 {/* <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom01" className={`col-md-12 mb-3`}>
                                 <Form.Label>Page category*</Form.Label>
                                 <Form.Select required aria-label="Default select example" value={state.category} name="category" onChange={handleChange}>
                                    <option value="">Select category</option>
                                    {categoryList?.data?.data.map((el) => (
                                       <option key={el._id} value={el._id}>{el.category_name}</option>
                                    ))}
                                 </Form.Select>
                                 {/* <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                 <Form.Label>Description*</Form.Label>
                                 <Form.Control
                                    placeholder="Enter a small description"
                                    required
                                    type="text"
                                    value={state.description}
                                    onChange={handleChange}
                                    name="description"
                                    as="textarea"
                                    style={{ height: '100px' }}
                                 />
                                 {/* <Form.Control.Feedback type="invalid">Please add a small description</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="formFile" className="mb-3 col-md-6">
                                 <Form.Label>Upload page image*</Form.Label>
                                 <div className="position-relative">
                                    <Form.Control type="file" onChange={handleImage} name="display_image" className="prevent-validation" ref={inputRefProfile} />
                                    {state.display_image.length > 0 && <button onClick={removeDisplayImage} className={style.delete_from_input}><AiOutlineDelete /></button>}
                                 </div>
                              </Form.Group>

                              <Form.Group as={Col} controlId="formFile" className="mb-3 col-md-6">
                                 <Form.Label>Upload Cover*</Form.Label>
                                 <div className="position-relative">
                                    <Form.Control type="file" onChange={handleCover} name="cover_image" className="prevent-validation" ref={inputRefCover} />
                                    {state.cover_image.length > 0 && <button onClick={removeCoverImage} className={style.delete_from_input}><AiOutlineDelete /></button>}
                                 </div>
                              </Form.Group>
                           </Row>
                           <div className="mt-2 text-center">
                              <Button className="btn btn-primary" type="submit">Create Page</Button>
                           </div>
                        </Form>
                     </Card.Body>
                  </Card>
               </div>
               <div className="col-md-5 col-lg-4">
                  <div className={"pageCard"}>
                     <div
                        className="pageCardcoverPhoto"
                        style={{
                           backgroundImage: `url(${state.cover_image.length > 0
                              ? state.cover_image
                              : defaultUserCover})`
                        }}
                     ></div>
                     <div className="page_content">
                        <figure>
                           <img
                              src={state.display_image.length > 0
                                 ? state.display_image
                                 : defaultUserDp}
                              alt=""
                           />
                        </figure>
                        <Link>
                           {state.title.length > 0
                              ? state.title
                              : "Page Title"}
                        </Link>
                        <span>Business • 125M Likes</span>
                        <ul className="add-remove-frnd following">
                           <li className="following">
                              <button className="btn btn-light w-100">Like</button>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CreatePage;
