import React, { Fragment } from 'react';
import './BirthdayWidget.css';
import brithDayCake from '../../../assets/images/resources/cake.png';
import WidgetWrapper from '../WidgetWrapper';
import { useBirthdayListQuery } from '../../../store/api/birthdayApi';
import Slider from "react-slick";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import BirthdaySingle from './BirthdaySingle';

const BirthdayWidget = () => {
  const birthdayUserList = useBirthdayListQuery()


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <button><MdOutlineKeyboardArrowRight /></button>,
    prevArrow: <button><MdOutlineKeyboardArrowLeft /></button>
  };

  return (
    <>
      <WidgetWrapper className={'birthday'}>
        {
          birthdayUserList?.data?.birthdays.length > 0 ? (
            <div className="dob-meta">
              <Slider {...settings}>
                {
                  birthdayUserList?.data?.birthdays.map((el, i) => (
                    <BirthdaySingle data={el} key={i} />
                  ))
                }
              </Slider>
            </div>
          ) : (
            <>
              <figure className="birthdayImage ">
                <img src={brithDayCake} alt="" style={{ display: "block", margin: "auto" }} />
              </figure>
              <div className='dob-meta'>No recent birthday</div>
            </>
          )
        }
      </WidgetWrapper>

    </>
  )
}

export default BirthdayWidget