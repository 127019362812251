import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/styles/style.css";
import "./assets/styles/weather-icons.min.css";
import "./assets/styles/page-tour.css";
import "./assets/styles/color.css";
import HeaderDesktop from "./components/desktop/header/header";
import AllRoutes from "./routes/AllRoutes";
import { ConfigProvider, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "./store/slice/themeSlice";
import { Toaster } from 'react-hot-toast'
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "./methods/checkMobile";
import HeaderMobile from "./components/Mobile/Header/Header";
import { useHealthCheckupMutation, useSignoutUserMutation } from "./store/api/authApi";
import { logOut } from "./store/slice/userSlice";
import { notification } from "antd";
import socketIO from 'socket.io-client';
import { socket } from "./methods/Socket";
// import { SocketProvider, useSocket } from "./provider/SocketContext";

function App() {
  // const socket = useSocket();
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const appTheme = useSelector(selectTheme);
  const { userAuthToken } = useSelector((store) => {
    return store.userSlice;
  });

  const dispatch = useDispatch();
  const [signout] = useSignoutUserMutation();
  const [healthCheckup] = useHealthCheckupMutation()

  useEffect(() => {
    if ((pathname !== '/login') || (pathname !== "/register")) {
      healthCheckup(userAuthToken).then((res) => {
        if (res?.error?.data?.error === 1) {
          dispatch(logOut());
          signout(userAuthToken);
        }
      })
    }
  }, [pathname])

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  })

  useEffect(() => {
    socket.emit("myRoom", userSliceData?.user_room_id);
    socket.on("receiveNotification", (data) => {
      let page_link;
      if (data?.data?.meta_type === "Post") {
        page_link = `/post/${data?.data?.meta_data?._id}`
      } else if (data?.data?.meta_type === "Group") {
        page_link = `/group/${data?.data?.meta_data?._id}/timeline`
      } else if (data?.data?.meta_type === "Page") {
        page_link = `/page/${data?.data?.meta_data?._id}/timeline`
      } else if (data?.data?.meta_type === "Event") {
        page_link = `/event/${data?.data?.meta_data?._id}/timeline`
      } else if (data?.data?.meta_type === "Event") {
        page_link = `/event/${data?.data?.meta_data?._id}/timeline`
      } else if (data?.data?.meta_type === "Chat") {
        page_link = `/chat`
      }

      notification.open({
        message: 'Notification',
        description: data?.data?.title,
        onClick: () => {
          navigate(page_link)
        },
        duration: 5
      });
    });

  }, [])

  // useEffect(() => {
  //   const socket = socketIO.connect('https://ubuntu-social-media-api.notebrains.com/', {
  //     auth: {
  //       user_id: userSliceData?._id,
  //     }
  //   });
  //   socket.emit("myRoom", userSliceData?.user_room_id);
  // }, [socket])

  return (
    <>
      {/* <SocketProvider> */}
      <ConfigProvider
        theme={{
          algorithm:
            appTheme.theme === "light-theme"
              ? theme.lightAlgorithm
              : theme.darkAlgorithm,
          token: {
            colorPrimary: "#d2252d",
            borderRadius: 5,
            fontSize: 12,
            sizeStep: 5,
            sizeUnit: 3,
          },
          components: {
            Input: {
              colorPrimary: "#d2252d",
              algorithm: true,
            },
          },
        }}
      >
        {
          pathname !== '/chat' &&
          (userAuthToken && (
            isMobile() ? <HeaderMobile /> : <HeaderDesktop />
          ))
        }
        <Toaster position="top-center" reverseOrder={false} />
        <AllRoutes />
      </ConfigProvider>
      {/* </SocketProvider> */}
    </>
  );
}

export default App;
