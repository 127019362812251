import React, { useEffect, useState } from 'react'
import { useGetPermissionListQuery, useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'
import { AdminMenu } from '../../../../components/components'
import PermissionRow from '../../../../components/adminComponent/table/tableRow/PermissionRow'
import { useSelector } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Permission = () => {
    const permissionList = useGetPermissionListQuery()
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    const [permissionArr, setPermissionArr] = useState("")
    const userPermissionList = useUserPermissionListQuery()

    if (userPermissionList?.isSuccess === true) {
        var user_permission_arr = userPermissionList?.data?.permissions;
    }

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className="d-md-flex justify-content-between align-items-center p-2">
                                <h5 className="mb-0">Permission</h5>
                                {(userInfo.user_type === 3 && user_permission_arr.includes("permission_create")) && <Link to="/admin/roles-permission/permission/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                                {userInfo.user_type === 1 && <Link to="/admin/roles-permission/permission/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                            </div>
                            <table className='adminTable'>
                                <thead>
                                    <tr>
                                        <th><div className='table_head_info'>Permission Name</div></th>
                                        {(userInfo.user_type === 3 && permissionArr.includes("permission_edit" || "permission_delete")) && <th><div className='table_head_info text-center'>Actions</div></th>}
                                        {userInfo.user_type === 1 && <th><div className='table_head_info text-center'>Actions</div></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {permissionList.isError && (
                                        <tr className='noData'>
                                            <td colSpan={2}>
                                                <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                            </td>
                                        </tr>
                                    )}
                                    {permissionList.isLoading && (
                                        <tr className='noData'>
                                            <td colSpan={2}>
                                                <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                            </td>
                                        </tr>
                                    )}
                                    {permissionList.isSuccess && (
                                        permissionList?.data?.data.length > 0 ? (
                                            permissionList?.data?.data.map((el, i) => {
                                                return (
                                                    <PermissionRow data={el} key={i} />
                                                )
                                            })
                                        ) : (
                                            <tr className='noData'>
                                                <td colSpan={2}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Permission
