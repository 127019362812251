import React from 'react'
import { AdminMenu, BirthdayWidget, CreateYourPageWidget, GroupWidget, LiveWidget, ProfileWidget, Quote, SideBar, UserCard } from '../../components/components';
import { useBlockedUserListQuery } from '../../store/api/userApi';
import { useSelector } from 'react-redux';
import { useUserPermissionListQuery } from '../../store/api/Admin/adminRolePermissionApi';
import PageWidget from '../../components/widgets/pageWidget/PageWidget';
import CreateYourGroupWidget from '../../components/widgets/createYourGroupWidget/CreateYourGroupWidget';
import CreateYourEventWidget from '../../components/widgets/createYourEventWidget/CreateYourEventWidget';
import EventWidget from '../../components/widgets/eventWidget/EventWidget';

const BlockedConnection = () => {
    const permissionList = useUserPermissionListQuery()

    const blockedConnection = useBlockedUserListQuery()
    const blockedConnectionData = blockedConnection?.data?.blockList;

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });
    return (
        <>
            <div className="gap2 gray-bg full_height">
                <div className="container">
                    <div className="row">

                        <aside className="col-md-3">
                            <SideBar className="left">
                                {(userSliceData.user_type === 1 || (userSliceData.user_type === 3 && permissionList?.data?.permissions.length > 0)) && <AdminMenu />}
                                <ProfileWidget />
                                <Quote />
                                <BirthdayWidget />
                                <CreateYourPageWidget />
                                <PageWidget />
                            </SideBar>
                        </aside>

                        <div className=" col-6">
                            <h5>Blocked Users List</h5>
                            <div className="row gx-4">
                                {blockedConnectionData?.map((elem, index) => {
                                    return (
                                        <div className="col-4" key={index}>
                                            <UserCard data={elem} type={'block'} />
                                        </div>
                                    );
                                })}
                                {blockedConnectionData?.length === 0 && (
                                    <div className="col-12">
                                        <h5 className="text-center mb-0 py-5 fs_18">No Blocked Users !!!</h5>
                                    </div>
                                )}
                            </div>
                        </div>

                        <aside className="col-md-3">
                            <SideBar className="right">
                                <LiveWidget />
                                <CreateYourGroupWidget />
                                <GroupWidget />
                                <CreateYourEventWidget />
                                <EventWidget />
                            </SideBar>
                        </aside>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BlockedConnection;