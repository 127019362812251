import React, { useState } from 'react';
import { useGetReactsQuery } from '../../../store/api/reactMaster.js';
import { useSelector } from 'react-redux';
import { IoIosArrowDown } from "react-icons/io";
import './post_reacts.css';
import { Image, defaultUserDp } from '../../components.jsx';
import { Link } from 'react-router-dom';
import { useDeleteReactMutation, useAddReactOnPostMutation, useUpdateReactMutation } from '../../../store/api/reactApi.js';
import toast from 'react-hot-toast';
import { socket } from '../../../methods/Socket.js';

const Reacts = ({ id, reactListOnPost, posted_by_id }) => {
    const { data: reactList } = useGetReactsQuery();
    const [deleteReact] = useDeleteReactMutation();
    const [addReactOnPost] = useAddReactOnPostMutation();
    const [updateReact] = useUpdateReactMutation();

    const USER = useSelector((store) => {
        return store.userSlice.userSliceData;
    });



    let OwnReact = reactListOnPost?.filter((elem) => {
        return elem?.user?._id === USER?._id;
    })

    const [loading, setLoading] = useState(false)

    const addReact = (elem) => {
        let obj = {
            reacts: {
                _id: elem._id,
                title: elem.title,
                image: elem.image,
                status: 1
            },
            post_id: id
        }
        setLoading(true)

        if (loading === false) {
            if (OwnReact.length > 0) {
                // UPDATE REACT
                updateReact(obj).then((res) => {
                    setLoading(false)
                })
            } else {
                // ADD REACT
                addReactOnPost(obj).then((res) => {
                    socket.emit("sendNotificationPostOtherDetailsUpdate", {
                        sender_id: USER._id,
                        meta_data: res?.data?.data?.post,
                        meta_type: "Post",
                        notification_for: "post_react",
                        update_meta_type_for: "Post",
                        posted_by: posted_by_id
                    });
                    setLoading(false)
                })
            }
        }
    }

    const toggleReact = (elem) => {
        // REMOVE REACT IF HAS OWN REACT IN THE LIST ELSE ADD DEFAULT REACT
        setLoading(true)
        let obj = {
            reacts: {
                _id: elem._id,
                title: elem.title,
                image: elem.image,
                status: 1
            },
            post_id: id
        }
        if (loading === false) {
            if (OwnReact.length > 0) {
                // REMOVEING REACT            
                deleteReact({ post_id: id }).then((res) => {
                    setLoading(false)
                })
            } else {
                // ADDING DEFAULT REACT
                addReactOnPost(obj).then((res) => {
                    socket.emit("sendNotificationPostOtherDetailsUpdate", {
                        sender_id: USER._id,
                        meta_data: res?.data?.data?.post,
                        meta_type: "Post",
                        notification_for: "post_react",
                        update_meta_type_for: "Post",
                        posted_by: posted_by_id
                    });
                    setLoading(false)
                })
            }
        }
    }

    if (!USER?._id) {
        return (
            <>
                <div className={reactListOnPost?.length > 0 ? 'post_react' : 'post_react me-0'}>
                    <button onClick={() => toast.error("Please login !!!")}>
                        <span>
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <g id="Layer">
                                    <path fill='none' stroke='currentColor' fillRule="evenodd" d="m11.7 9.5h-5.8q-0.2 0-0.3 0.1-0.1 0-0.3 0.1-0.1 0-0.2 0.1-0.1 0.1-0.2 0.2-0.3 0.3-0.4 0.7 0 0.4 0.1 0.8 0.2 0.4 0.6 0.7 0.4 0.2 0.8 0.2h0.3q-0.3 0-0.5 0-0.3 0.1-0.4 0.3-0.2 0.2-0.3 0.4-0.1 0.3-0.1 0.5 0 0.2 0.1 0.5 0.1 0.2 0.2 0.4 0.2 0.1 0.4 0.2 0.2 0.1 0.5 0.2-0.2 0.1-0.3 0.2-0.1 0.2-0.2 0.3 0 0.2-0.1 0.4 0 0.2 0.1 0.3 0 0.2 0.1 0.4 0.1 0.1 0.2 0.3 0.2 0.1 0.3 0.2 0.2 0 0.4 0.1-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.2 0 0.1 0 0.2 0 0.1 0.1 0.3 0 0.2 0.1 0.4 0.2 0.2 0.3 0.3 0.2 0.1 0.4 0.2 0.3 0.1 0.5 0.1h3.6q0.2 0 0.3-0.1 0.2 0 0.4 0 0.2 0 0.3 0 0.2-0.1 0.4-0.1l2.2-0.7h3c1.6 0 2-7.3 0-7.3h-0.8c-0.2 0-0.2-0.3-0.6-0.7-0.6-0.6-1.3-1.4-1.7-1.9q-0.4-0.3-0.8-0.7-0.4-0.4-0.7-0.9-0.3-0.4-0.6-0.9-0.3-0.4-0.6-0.9c-0.3-0.8-0.4-1.2-1.2-1.2q-0.2 0-0.4 0.2-0.2 0.1-0.3 0.2-0.2 0.2-0.2 0.4-0.1 0.3-0.1 0.5 0.1 0.6 0.2 1.2 0.1 0.5 0.2 0.9 0.2 0.4 0.4 0.9 0.2 0.4 0.4 0.8 0.2 0.4 0.5 0.8" />
                                </g>
                            </svg>
                        </span>
                        <span>{reactListOnPost?.length || 0}</span>
                    </button>
                    {/* <div className="react-box shadow-lg">
                        <ul>
                            {reactList?.data?.map((elem, i) => {
                                return (
                                    <li data-title={elem.title} key={i}>
                                        <button onClick={() => addReact(elem)} dangerouslySetInnerHTML={{ __html: elem.image }}></button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div> */}
                </div>
                {reactListOnPost?.length > 0 ? (
                    <div className='postReactList'>
                        <button type="button"><IoIosArrowDown /></button>
                        <div className='postReactListOverlay shadow-lg'>
                            {reactListOnPost?.map((elem, i) => {
                                return (
                                    <div className='reactListItem' key={i}>
                                        <div className='userImg'>
                                            <Image src={elem.user.profile_picture !== undefined ? elem.user.profile_picture[0].image_url : defaultUserDp} />
                                        </div>
                                        <h5 className='fs_12 fw-bold'><Link to={`/profile/${elem.user._id}/timeline`}>{elem.user.fullName}</Link></h5>
                                        <span className='reactSvgIcon' dangerouslySetInnerHTML={{ __html: elem.reacts.image }}></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : ('')}
            </>
        )
    }

    return (
        <>
            <div className={reactListOnPost?.length > 0 ? 'post_react' : 'post_react me-0'}>
                <button className={reactListOnPost?.length > 0 ? 'hasReact' : ''} onClick={() => toggleReact(reactList?.data[0])}>
                    {OwnReact?.length > 0 ? (
                        <span dangerouslySetInnerHTML={{ __html: OwnReact[0]?.reacts?.image }}></span>
                    ) : (
                        <>
                            <span>
                                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <g id="Layer">
                                        <path fill='none' stroke='currentColor' fillRule="evenodd" d="m11.7 9.5h-5.8q-0.2 0-0.3 0.1-0.1 0-0.3 0.1-0.1 0-0.2 0.1-0.1 0.1-0.2 0.2-0.3 0.3-0.4 0.7 0 0.4 0.1 0.8 0.2 0.4 0.6 0.7 0.4 0.2 0.8 0.2h0.3q-0.3 0-0.5 0-0.3 0.1-0.4 0.3-0.2 0.2-0.3 0.4-0.1 0.3-0.1 0.5 0 0.2 0.1 0.5 0.1 0.2 0.2 0.4 0.2 0.1 0.4 0.2 0.2 0.1 0.5 0.2-0.2 0.1-0.3 0.2-0.1 0.2-0.2 0.3 0 0.2-0.1 0.4 0 0.2 0.1 0.3 0 0.2 0.1 0.4 0.1 0.1 0.2 0.3 0.2 0.1 0.3 0.2 0.2 0 0.4 0.1-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.2 0 0.1 0 0.2 0 0.1 0.1 0.3 0 0.2 0.1 0.4 0.2 0.2 0.3 0.3 0.2 0.1 0.4 0.2 0.3 0.1 0.5 0.1h3.6q0.2 0 0.3-0.1 0.2 0 0.4 0 0.2 0 0.3 0 0.2-0.1 0.4-0.1l2.2-0.7h3c1.6 0 2-7.3 0-7.3h-0.8c-0.2 0-0.2-0.3-0.6-0.7-0.6-0.6-1.3-1.4-1.7-1.9q-0.4-0.3-0.8-0.7-0.4-0.4-0.7-0.9-0.3-0.4-0.6-0.9-0.3-0.4-0.6-0.9c-0.3-0.8-0.4-1.2-1.2-1.2q-0.2 0-0.4 0.2-0.2 0.1-0.3 0.2-0.2 0.2-0.2 0.4-0.1 0.3-0.1 0.5 0.1 0.6 0.2 1.2 0.1 0.5 0.2 0.9 0.2 0.4 0.4 0.9 0.2 0.4 0.4 0.8 0.2 0.4 0.5 0.8" />
                                    </g>
                                </svg>
                            </span>
                        </>
                    )}
                    <span>{reactListOnPost?.length || 0}</span>
                </button>
                <div className="react-box shadow-lg">
                    <ul>
                        {reactList?.data?.map((elem, i) => {
                            return (
                                <li data-title={elem.title} key={i}>
                                    <button onClick={() => addReact(elem)} dangerouslySetInnerHTML={{ __html: elem.image }}></button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            {reactListOnPost?.length > 0 ? (
                <div className='postReactList'>
                    <button type="button"><IoIosArrowDown /></button>
                    <div className='postReactListOverlay shadow-lg'>
                        {reactListOnPost?.map((elem, i) => {
                            return (
                                <div className='reactListItem' key={i}>
                                    <div className='userImg'>
                                        <Image src={elem?.user?.profile_picture !== undefined ? elem?.user?.profile_picture[0]?.image_url : defaultUserDp} />
                                    </div>
                                    <h5 className='fs_12 fw-bold'><Link to={`/profile/${elem?.user?._id}/timeline`}>{elem?.user?.fullName}</Link></h5>
                                    <span className='reactSvgIcon' dangerouslySetInnerHTML={{ __html: elem?.reacts?.image }}></span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : ('')}
        </>
    )
}

export default Reacts;