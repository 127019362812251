import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminMarketPlaceApi = createApi({
    reducerPath: "adminMarketPlace",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminMarketPlace"],
    endpoints: (builder) => ({
        getMarketPlaceList: builder.query({
            query: (data) => ({
                url: "api/admin/marketplace/products/list",
                method: "GET",
                params: {
                    page: data.page,
                    per_page: 100,
                    search_keyword: data.searchVal,
                    category_id: data.category,
                    // product_type:"my_products"
                },
            }),
            providesTags: ["AdminMarketPlace"],
        }),
        deleteMarketPlace: builder.mutation({
            query: (id) => {
                return {
                    url: ` api/admin/marketplace/products/delete`,
                    method: "DELETE",
                    body: {
                        product_id: id
                    }
                };
            },
            invalidatesTags: ["AdminMarketPlace"],
        }),
        updateMarketPlaceStatus: builder.mutation({
            query: (id) => {
                return {
                    url: `api/admin/marketplace/products/status`,
                    method: "PUT",
                    body: {
                        product_id: id
                    }
                };
            },
            invalidatesTags: ["AdminMarketPlace"],
        }),

    })
});

export const { useGetMarketPlaceListQuery,useDeleteMarketPlaceMutation,useUpdateMarketPlaceStatusMutation } = adminMarketPlaceApi;