import React from 'react';
import { DashboardMenuLink, FilterWrapper, MediaCard } from '../../../../components/components';
import { Link, useParams } from 'react-router-dom';
import './images.css'
import EventProfileLayout from '../../EventProfileLayout';
import { useGetAlbumEventOthersQuery, useGetAlbumEventQuery, useGetSingleEventQuery } from '../../../../store/api/eventApi';
import { useSelector } from 'react-redux';

const Images = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });
    // get id from param
    const params = useParams();
    const EVENTID = params.eventId;
    // get single Event Data
    const singleEvent = useGetSingleEventQuery(EVENTID);
    let eventWholeData = singleEvent?.data?.data;

    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: eventWholeData?.user?._id,
        meta_type: "Event",
        meta_data: EVENTID
    }
    const AlbumDataOthers = useGetAlbumEventOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetAlbumEventQuery(EVENTID)
    const Images = eventWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;



    const CoverImagesData = Images?.filter(function (el) {
        return el.name === "cover_photo";
    })[0]?.gallery;




    const AllImagesData = Images?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    return (
        <EventProfileLayout data={eventWholeData}>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/event/${EVENTID}/media`} title="Images" noCount />
                            <DashboardMenuLink url={`/event/${EVENTID}/media/video`} title="Videos" noCount />
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className='row gx-4'>
                    <div className='col-md-2'>
                        <Link to={`/event/${EVENTID}/media/cover_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>Cover Picture</h5>
                                    <p>{CoverImagesData?.length > 0 ? CoverImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to={`/event/${EVENTID}/media/all_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>All Picture</h5>
                                    <p>{AllImagesData?.length > 0 ? AllImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </EventProfileLayout>
    )
}

export default Images