import React from 'react'
import ProfileLayout from '../ProfileLayout'
import { DashboardAbout } from '../../../components/components'

const About = () => {
  return (
    <ProfileLayout>
      <DashboardAbout type={"profile"}/>
    </ProfileLayout>
  )
}

export default About