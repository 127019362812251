import React from "react";
import { Link } from "react-router-dom";
import style from "./ProfileComplitation.module.css";
import WidgetWrapper from "../WidgetWrapper";
import PercentComplete from "../ProfileWidget/PercentComplete";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/slice/themeSlice";


const ProfileComplitation = () => {
   const { theme } = useSelector(selectTheme)

   const { userSliceData } = useSelector((store) => {
      return store.userSlice
   })

   let genericDetailsPercentage = userSliceData.userProfilePercent?.perModulePercentage[0];
   let profilePhotoPercentage = userSliceData.userProfilePercent?.perModulePercentage[1];
   let communicationDetailsPercentage = userSliceData.userProfilePercent?.perModulePercentage[2];
   let arearOfInterestPercentage = userSliceData.userProfilePercent?.perModulePercentage[3];
   let shareToContactPercentage = userSliceData.userProfilePercent?.perModulePercentage[4];

   return (
      <WidgetWrapper title="Complete Your Profile">
         <div className={theme === 'light-theme' ? `${style.pit_reg_complete} lightTheme` : `${style.pit_reg_complete} darkTheme`}>
            <div style={{ marginTop: '50px' }}>
               <PercentComplete />
            </div>
            <div className={style.reg_comp_meta}>
               <p>
                  filling your profile details will help you to meet the right
                  people
               </p>
               <ul>
                  <li>
                     <i className="fa fa-envelope-o bg-red"></i>
                     <Link to={"/profile/generic-details"} className={(theme === 'light-theme') ? `${style.user_link} ${style.light_theme}` : `${style.user_link} ${style.dark_theme}`}>
                        Generic Details
                     </Link>
                     <span className="point">
                        {
                           genericDetailsPercentage?.percentage === genericDetailsPercentage?.totalEachPercentage
                              ?
                              (
                                 <svg fill="#3cb371" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                       c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                       c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                       c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                       C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                       c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                              :
                              (
                                 <svg fill="#ccc" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                       c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                       c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                       c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                       C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                       c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                        }
                     </span>
                  </li>
                  <li>
                     <i className="fa fa-envelope-o bg-blue"></i>
                     <Link to={"/profile/upload-profile"} className={(theme === 'light-theme') ? `${style.user_link} ${style.light_theme}` : `${style.user_link} ${style.dark_theme}`}>
                        Profile Photo
                     </Link>
                     <span className="point">
                        {
                           profilePhotoPercentage.percentage === profilePhotoPercentage.totalEachPercentage
                              ?
                              (
                                 <svg fill="#3cb371" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                       c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                       c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                       c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                       C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                       c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                              :
                              (
                                 <svg fill="#ccc" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                       c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                       c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                       c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                       C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                       c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                        }
                     </span>
                  </li>
                  <li>
                     <i className="fa fa-phone bg-purple"></i>
                     <Link to={"/profile/communication-details"} className={(theme === 'light-theme') ? `${style.user_link} ${style.light_theme}` : `${style.user_link} ${style.dark_theme}`}>
                        Communication Details
                     </Link>
                     <span className="point">
                        {
                           communicationDetailsPercentage.percentage === communicationDetailsPercentage.totalEachPercentage
                              ?
                              (
                                 <svg fill="#3cb371" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                    c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                    c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                    c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                    C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                    c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                              :
                              (
                                 <svg fill="#ccc" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                    c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                    c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                    c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                    C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                    c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                        }
                     </span>
                  </li>
                  <li>
                     <i className="fa fa-map-marker bg-green"></i>
                     <Link to={"/profile/area-of-interest"} className={(theme === 'light-theme') ? `${style.user_link} ${style.light_theme}` : `${style.user_link} ${style.dark_theme}`}>
                        Area of Interests
                     </Link>
                     <span className="point">
                        {
                           arearOfInterestPercentage.percentage === arearOfInterestPercentage.totalEachPercentage
                              ?
                              (
                                 <svg fill="#3cb371" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                    c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                    c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                    c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                    C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                    c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                              :
                              (
                                 <svg fill="#ccc" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                    c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                    c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                    c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                    C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                    c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                        }
                     </span>
                  </li>
                  <li>
                     <i className="fa fa-map-marker bg-green"></i>
                     <Link to={"/profile/update-password"} className={(theme === 'light-theme') ? `${style.user_link} ${style.light_theme}` : `${style.user_link} ${style.dark_theme}`}>
                        Update Password
                     </Link>
                     <span className="point">
                        {
                           shareToContactPercentage.percentage === shareToContactPercentage.totalEachPercentage
                              ?
                              (
                                 <svg fill="#3cb371" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                    c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                    c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                    c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                    C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                    c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                              :
                              (
                                 <svg fill="#ccc" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    width="800px" height="800px" viewBox="0 0 352.62 352.62">
                                    <g>
                                       <path d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664
                                    c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648
                                    c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704
                                    c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145
                                    C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611
                                    c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                                    </g>
                                 </svg>
                              )
                        }
                     </span>
                  </li>
               </ul>
            </div>
         </div>
      </WidgetWrapper>
   );
};

export default ProfileComplitation;
