import React from "react";
import WidgetWrapper from "../WidgetWrapper";
import styles from "./LocationWidget.module.css";

const LocationWidget = ({ data }) => {
   return (
      <WidgetWrapper title={"Location Info"}>
         <div className={styles.gmap_canvas}>
            <iframe width="600" height="300" id="gmap_canvas" src={`https://maps.google.com/maps?q=${data}&t=&z=13&ie=UTF8&iwloc=&output=embed`} title="k" className={styles.iframe}></iframe>
            <h3 className={styles.title}>
               {data}
            </h3>
         </div>
      </WidgetWrapper>
   );
};

export default LocationWidget;
