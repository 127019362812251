import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const categoryApi = createApi({
    reducerPath: "categoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["Category"],
    endpoints: (builder) => ({
        // POST REACT LIST
        getCategoryList: builder.query({
            query: () => `api/category/list`,
            providesTags: ["Category"],
        })
    })
})


export const { useGetCategoryListQuery } = categoryApi;