import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const reactsMasterApi = createApi({
  reducerPath: "reactsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const { userSlice } = getState();
      let token = userSlice.userAuthToken;
      return headers.set("x-access-token", token);
    },
  }),
  tagTypes: ["ReactMaster"],
  endpoints: (builder) => ({
    // POST REACT LIST
    getReacts: builder.query({
      query: (page) => `api/all-reactions`,
      providesTags: ["ReactMaster"],
    })
  })
})


export const {useGetReactsQuery} = reactsMasterApi;