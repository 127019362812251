import React, { useRef, useState } from 'react';
import './chatItem.css';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EpochTimeToDateTime from '../../../../../methods/EpochTimeToDateTime';
import { useDeleteSingleMessagesForEveryoneMutation, useDeleteSingleMessagesForMeMutation } from '../../../../../store/api/chatApi';
import { useSelector } from 'react-redux';
import { IoCheckmarkOutline, IoCheckmarkDoneOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import Video from '../../../../../components/video/video';
import { MdDelete } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Slider from "react-slick";
import ReactPlayer from "react-player";
import LazyImage from '../../../../../components/image/Image';
import { FaArrowAltCircleDown, FaRegArrowAltCircleDown } from "react-icons/fa";
import { useIsVisible } from '../../../../../methods/isVisibleMessage';
import { socket } from '../../../../../methods/Socket';

const ChatItem = ({ out, data }) => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    })
    const [imageModal, setImageModal] = useState(false);

    // All about delete message for me and everyone
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deleteSingleForMe] = useDeleteSingleMessagesForMeMutation()
    const deleteForMe = () => {
        let messageId = [data._id ? data._id : data?.message_id]
        socket.emit("deleteMessageForMe", { message_id: data?.message_id, room_id: data.room_id });
        deleteSingleForMe(messageId).then(() => {
            console.log("deleted")
            setDeleteModal(false)
        })
    }
    const [deleteSingleForEveryone] = useDeleteSingleMessagesForEveryoneMutation()
    const deleteForEveryone = () => {
        let messageId = [data._id ? data._id : data?.message_id]
        socket.emit("deleteMessages", {
            selectedMessageIds: [data._id ? data._id : data?.message_id],
            room_id: data.room_id,
        });
        deleteSingleForEveryone(messageId).then(() => {
            console.log('deleted')
            setDeleteModal(false)
        })
    }

    // message read-unread condition
    const ref = useRef(null)
    const isVisible = useIsVisible(ref)
    if (data.recipients[0].status !== "read") {
        if (userSliceData._id === data?.recipients[0]?.user_id?._id) {
            if (isVisible) {
                socket.emit("messageRead", {
                    room_id: data?.room_id,
                    user_id: data?.recipients[0]?.user_id?._id,
                });
            }
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <button className='sliderArrowNext'><MdOutlineKeyboardArrowRight style={{ fontSize: '20px', color: "black" }} /></button>,
        prevArrow: <button className='sliderArrowPrev'><MdOutlineKeyboardArrowLeft style={{ fontSize: '20px', color: "black" }} /></button>
    };

    return (
        <>
            <div className={!out ? 'message' : `message message-out`}>
                <div className="message-inner">
                    <div className="message-body">
                        <div className="message-content" ref={ref}>
                            <div className="message-text">
                                {data?.images.length === 1 && (
                                    <div className="img-bunch style_with_1" >
                                        <div className="row g-1">
                                            <div className="col-md-12">
                                                <div className="preview_post_img preview_post_img_1">
                                                    {data.images[0].mime_type.slice(0, 5) === "image" && (
                                                        <div onClick={() => setImageModal(true)} >
                                                            <img src={data.images[0].image_url} alt="..." />
                                                        </div>
                                                    )}
                                                    {data?.images[0]?.mime_type?.slice(0, 5) === "video" && (
                                                        <div className="videoPost" onClick={() => setImageModal(true)} >
                                                            <Video id={data?._id} url={data?.images[0]?.image_url} />
                                                        </div>
                                                    )}
                                                    {data?.images[0]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {data.images.length === 2 && (
                                    <div className="img-bunch style_with_2" >
                                        <div className="row g-1">
                                            <div className="col-6">
                                                <div className="preview_post_img preview_chat_img">
                                                    {data.images[0].mime_type.slice(0, 5) === "image" && (
                                                        <div to={``} onClick={() => setImageModal(true)}>
                                                            <img src={data.images[0]?.image_url} alt="..." />
                                                        </div>
                                                    )}
                                                    {data.images[0].mime_type.slice(0, 5) === "video" && (
                                                        <div className="videoPost" onClick={() => setImageModal(true)}>
                                                            <Video id={data._id} url={data.images[0].image_url} />
                                                        </div>
                                                    )}
                                                    {data?.images[0]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="preview_post_img preview_chat_img">
                                                    {data.images[1].mime_type.slice(0, 5) ===
                                                        "image" && (
                                                            <div onClick={() => setImageModal(true)}>
                                                                <img src={data.images[1]?.image_url} alt="..." />
                                                            </div>
                                                        )}
                                                    {data.images[1].mime_type.slice(0, 5) === "video" && (
                                                        <div className="videoPost" onClick={() => setImageModal(true)}>
                                                            <Video id={data._id} url={data.images[0].image_url} />
                                                        </div>
                                                    )}
                                                    {data?.images[1]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[1].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {data.images.length === 3 && (
                                    <div className="img-bunch style_with_3" >
                                        <div className="row g-1">
                                            <div className="col-6">
                                                <div className="preview_post_img preview_chat_img_1">
                                                    {data.images[0].mime_type.slice(0, 5) === "image" && (
                                                        <div onClick={() => setImageModal(true)}>
                                                            <img src={data.images[0]?.image_url} alt="..." />
                                                        </div>
                                                    )}
                                                    {data.images[0].mime_type.slice(0, 5) === "video" && (
                                                        <div className="videoPost" onClick={() => setImageModal(true)}>
                                                            <Video id={data._id} url={data.images[0].image_url} />
                                                        </div>
                                                    )}
                                                    {data?.images[0]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="preview_post_img preview_chat_img_2">
                                                    {data.images[1].mime_type.slice(0, 5) ===
                                                        "image" && (
                                                            <div onClick={() => setImageModal(true)}>
                                                                <img src={data.images[1]?.image_url} alt="..." />
                                                            </div>
                                                        )}
                                                    {data.images[1].mime_type.slice(0, 5) === "video" && (
                                                        <div className="videoPost" onClick={() => setImageModal(true)}>
                                                            <Video id={data._id} url={data.images[0].image_url} />
                                                        </div>
                                                    )}
                                                    {data?.images[1]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[1].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="preview_post_img preview_chat_img_3">
                                                    {data.images[2].mime_type.slice(0, 5) === "image" && (
                                                        <div onClick={() => setImageModal(true)}>
                                                            <img src={data.images[2]?.image_url} alt="..." />
                                                        </div>
                                                    )}
                                                    {
                                                        data.images[2].mime_type.slice(0, 5) === "video" && (
                                                            <div className="videoPost" onClick={() => setImageModal(true)}>
                                                                <Video id={data._id} url={data.images[0].image_url} />
                                                            </div>
                                                        )
                                                    }
                                                    {data?.images[2]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[2].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {data.images.length === 4 && (
                                    <div className="img-bunch style_with_4" >
                                        <div className="row g-1">
                                            <div className="col-md-6">
                                                <div className="preview_post_img preview_chat_img">
                                                    {data.images[0].mime_type.slice(0, 5) ===
                                                        "image" && (
                                                            <div onClick={() => setImageModal(true)}>
                                                                <img src={data.images[0]?.image_url} alt="..." />
                                                            </div>
                                                        )}
                                                    {data.images[0].mime_type.slice(0, 5) === "video" && (
                                                        <div className="videoPost" onClick={() => setImageModal(true)}>
                                                            <Video id={data._id} url={data.images[0].image_url} />
                                                        </div>
                                                    )}
                                                    {data?.images[0]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="preview_post_img preview_chat_img">
                                                    {data.images[1].mime_type.slice(0, 5) ===
                                                        "image" && (
                                                            <div onClick={() => setImageModal(true)}>
                                                                <img src={data.images[1]?.image_url} alt="..." />
                                                            </div>
                                                        )}
                                                    {data.images[1].mime_type.slice(0, 5) === "video" && (
                                                        <div className="videoPost" onClick={() => setImageModal(true)}>
                                                            <Video id={data._id} url={data.images[0].image_url} />
                                                        </div>
                                                    )}
                                                    {data?.images[1]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[1].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="preview_post_img preview_chat_img">
                                                    {data.images[2].mime_type.slice(0, 5) ===
                                                        "image" && (
                                                            <div onClick={() => setImageModal(true)}>
                                                                <img src={data.images[2]?.image_url} alt="..." />
                                                            </div>
                                                        )}
                                                    {data.images[2].mime_type.slice(0, 5) ===
                                                        "video" && (
                                                            <div className="videoPost" onClick={() => setImageModal(true)}>
                                                                <Video id={data._id} url={data.images[0].image_url} />
                                                            </div>
                                                        )}
                                                    {data?.images[2]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[2].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="preview_post_img preview_chat_img">
                                                    {data.images[3].mime_type.slice(0, 5) ===
                                                        "image" && (
                                                            <div onClick={() => setImageModal(true)}>
                                                                <img src={data.images[3]?.image_url} alt="..." />
                                                            </div>
                                                        )}
                                                    {data.images[3].mime_type.slice(0, 5) ===
                                                        "video" && (
                                                            <div className="videoPost" onClick={() => setImageModal(true)}>
                                                                <Video id={data._id} url={data.images[0].image_url} />
                                                            </div>
                                                        )}
                                                    {data?.images[3]?.mime_type?.slice(0, 5) === "appli" && (
                                                        <div className='file-view'>
                                                            <>file{(data.images[3].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {data?.message && <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: data?.message }}></p>}
                            </div>
                            {/* <!-- Dropdown --> */}
                            <div className="message-action">
                                {userSliceData._id === data?.sender && <Dropdown>
                                    <Dropdown.Toggle variant="light" id="dropdown-basic" className='ChatHeaderToggle'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                        {/* {<MdDelete onClick={() => setDeleteModal(true)} fontSize={20} />} */}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* <Dropdown.Item href="#/action-1">
                                            Edit
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                        </Dropdown.Item> */}
                                        <Dropdown.Item href="#/action-2">
                                            Reply
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-corner-up-left"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                                        </Dropdown.Item>
                                        {userSliceData._id === data?.sender && <Dropdown.Item className='text-danger' onClick={() => setDeleteModal(true)}>
                                            Delete
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                        </Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>}
                            </div>
                        </div>
                    </div>
                    <div className="message-footer">
                        <span className="extra-small text-muted fs_12">{EpochTimeToDateTime(data.createdAt).time}</span>
                        {data.sender === userSliceData._id &&
                            <span>
                                <>
                                    {
                                        data?.recipients[0]?.status === "read"
                                            ?
                                            <span><IoCheckmarkDoneOutline color='red' /></span>
                                            :
                                            <span><IoCheckmarkOutline /></span>
                                    }
                                </>
                            </span>
                        }
                    </div>
                </div>
            </div>

            <Modal centered size="md" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <div className='text-right'><RxCross2 style={{ cursor: 'pointer' }} onClick={() => setDeleteModal(false)}></RxCross2></div>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to delete the message?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={deleteForEveryone} className="mx-2">
                                Delete for everyone
                            </Button>
                            <Button variant="danger" onClick={deleteForMe} className="mx-2">
                                Delete for me
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal centered size="l" show={imageModal} onHide={() => setImageModal(false)}>
                <Modal.Body>
                    <Slider {...settings}>
                        {data?.images.map((elem, index) => {
                            return (
                                <div className='post_details_slideItem rounded-3' key={index}>
                                    {/* IMAGE VIEW */}
                                    {elem.mime_type.slice(0, 5) === "image" && (
                                        <>
                                            <LazyImage src={elem.image_url} className={'w-100 rounded-3'} />
                                            <Link to={elem.image_url}>
                                                <div className='download-btn'>Download <FaArrowAltCircleDown /></div>
                                            </Link>
                                        </>
                                    )}
                                    {/* VIDEO VIEW */}
                                    {elem.mime_type.slice(0, 5) === "video" && (
                                        <div className="videoPost">
                                            <ReactPlayer
                                                playing={false}
                                                width={"100%"}
                                                controls={true}
                                                url={elem.image_url}
                                            />
                                        </div>
                                    )}
                                    {elem.mime_type.slice(0, 5) === "appli" &&
                                        <div className='file-view'>
                                            <>file{(elem.size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </Slider>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChatItem;