async function convertImageLinkToFile(url) {
    const response = await fetch(url, {
        'Access-Control-Allow-Origin': '*'
    });
    const blob = await response.blob();

    // Create a File object from the blob
    const file = new File([blob], `image.jpg`, { type: "image/jpeg" });

    return file;
}

export default convertImageLinkToFile;