import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminMembershipApi = createApi({
    reducerPath: "adminMembershipApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminMembership", "AdminMembershipFeatureList"],
    endpoints: (builder) => ({
        getMembershipFeatureList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/membership/features",
                    method: "GET"
                }
            },
            providesTags: ["AdminMembershipFeatureList"],
        }),
        getMembershipList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/membership/list",
                    method: "GET"
                }
            },
            providesTags: ["AdminMembership"],
        }),
        getMembership: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/membership/details",
                    method: "GET",
                    params: {
                        membership_id: data,
                    },
                }
            },
            providesTags: ["AdminMembership"],
        }),
        createMembership: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/membership/create`,
                    method: "POST",
                    body: {
                        status: 1,
                        selected: false,
                        plan_name: data.plan_name,
                        price: Number(data.price),
                        mebership_type: data.mebership_type,
                        features: data.features,
                    }
                };
            },
            invalidatesTags: ['AdminMembership']
        }),
        updateMembership: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/membership/update`,
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: ["AdminMembership"],
        }),
        changeStatusMembership: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/membership/change-status`,
                    method: "PUT",
                    body: {
                        "membership_id": data._id,
                        "status": data.status
                    }
                };
            },
            invalidatesTags: ["AdminMembership"],
        }),
        deleteMembership: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/membership/delete`,
                    method: "DELETE",
                    body: {
                        "membership_id": data._id,
                    }
                };
            },
            invalidatesTags: ["AdminMembership"],
        })
    }),
});

export const {
    useGetMembershipFeatureListQuery,
    useGetMembershipListQuery,
    useGetMembershipQuery,
    useCreateMembershipMutation,
    useUpdateMembershipMutation,
    useChangeStatusMembershipMutation,
    useDeleteMembershipMutation
} = adminMembershipApi;