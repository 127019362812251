import React, { useReducer, useState } from "react";
import "./register.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Modal, InputGroup } from "react-bootstrap";
import { useCreateUserMutation } from "../../store/api/authApi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { DatePicker } from "antd";
import { useGetCountryQuery, useGetStateQuery } from "../../store/api/countryApi";
import { ErrorAlert, SuccessAlert } from "../components";
import GoBackWrapper from "../GoBackWrapper/GoBackWrapper";

const Register = () => {
  const [validated, setValidated] = useState(false);
  const [createUser, response] = useCreateUserMutation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [dateValid, setDateValid] = useState(null);
  const country = useGetCountryQuery();

  const initialState = {
    first_name: "",
    last_name: "",
    country: {},
    state: {},
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    dob: "",
    gender: "",
    terms_condition: false,
  };

  const userReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_USER":
        return { ...state, [payload.name]: payload.data };
      case "SET_COUNTRY":
        return { ...state, [payload.name]: JSON.parse(payload.data) };
      case "SET_TERMS":
        return { ...state, [payload.name]: payload.data };
      case "SET_DATE":
        return { ...state, dob: payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(userReducer, initialState);

  const handleChange = (e) => {
    dispatch({
      type: "SET_USER",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };

  const handleCountry = (e) => {
    dispatch({
      type: "SET_COUNTRY",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };

  const handleChangeTerms = (e) => {
    dispatch({
      type: "SET_TERMS",
      payload: {
        name: "terms_condition",
        data: e.target.checked,
      },
    });
  };

  const handleDate = (v, date) => {
    const selectedDateTimestamp = new Date(date + " " + "00:00:01").getTime();
    const sixteenYearagoTimestamp = 16 * 365 * 24 * 60 * 60 * 1000;
    const currentTimeTimestamp = Date.now();

    if ((currentTimeTimestamp - selectedDateTimestamp) > sixteenYearagoTimestamp) {
      setDateValid(true);
    } else {
      setDateValid(false);
    }
    dispatch({ type: "SET_DATE", payload: new Date(date + " " + "00:00:01").getTime() });
  };

  //register user post method
  const registerUser = async (e) => {
    e.preventDefault();

    if (!state.dob) { setDateValid(false) }
    const form = e.currentTarget;
    if (form.checkValidity() === true && dateValid === true) {
      await createUser(state).then((res) => {
        if (res?.data?.error === 0) {
          setTimeout(() => {
            navigate("/login");
          }, [3000]);
          setShow(true)
        }
        if (res?.error?.data?.error === 1) {
          console.log(res)
          setShow(true)
          setTimeout(() => {
            setShow(false)
          }, [2000]);
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    setValidated(true);
  };
  const stateName = useGetStateQuery(state.country.iso);

  return (
    <>
      <GoBackWrapper>
        <div className="we-login-register">
          <div className="form-title">
            <i className="fa fa-key" />
            Register
            <span>Register now and meet the awesome Friends around the world.</span>
          </div>
          <Form validated={validated} noValidate method="post" onSubmit={registerUser}>
            <Row>
              {/* first name */}
              <Form.Group as={Col} className="mb-2 pb-1 col-6">
                <Form.Label>First Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="first_name"
                  value={state.first_name}
                  onChange={handleChange}
                  required
                  pattern="^[A-Za-z0-9]{2,12}$"
                />
                <Form.Control.Feedback type="invalid">
                  2-12 charecter and not to include special charecter
                </Form.Control.Feedback>
              </Form.Group>
              {/* last name */}
              <Form.Group as={Col} className="mb-2 pb-1 col-6">
                <Form.Label>Last name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="last_name"
                  value={state.last_name}
                  onChange={handleChange}
                  required
                  pattern="^[A-Za-z0-9]{2,12}$"
                />
                <Form.Control.Feedback type="invalid">
                  2-12 charecter and not to include special charecter
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              {/* country */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>Country*</Form.Label>
                <Form.Select name="country" onChange={handleCountry} required>
                  <option value="">Select Country</option>
                  {country.data?.data.map((el, i) => (
                    <option key={i} value={JSON.stringify(el)}>
                      {el.country}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select your country
                </Form.Control.Feedback>
              </Form.Group>
              {/* state */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>State*</Form.Label>
                <Form.Select name="state" onChange={handleCountry} required>
                  <option value="">Select State</option>
                  {stateName?.data?.data.map((el, i) => (
                    <option key={i} value={JSON.stringify(el)}>
                      {el.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select your country
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              {/* email */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>
                  Email*
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email id"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  required
                  pattern={
                    isNaN(Number(state.email))
                      ? "^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$"
                      : "^[1-9]{1}[0-9]{5,90}$"
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email or phone no...
                </Form.Control.Feedback>
              </Form.Group>
              {/* phone */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>
                  Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  name="phone"
                  value={state.phone}
                  onChange={handleChange}
                  required
                  pattern={
                    isNaN(Number(state.phone))
                      ? "^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$"
                      : "^[1-9]{1}[0-9]{5,90}$"
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email or phone no...
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              {/* password */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>Password*</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    required
                    pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_]).{6,}$"
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  >
                    {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    Min 6 characters with 1 caps, 1 small and 1 special character
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {/* Re-enter password */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>Re-enter Password*</Form.Label>
                <Form.Control
                  type="password"
                  placeholder=" Re-enter Password"
                  required
                  pattern={state.password}
                  name="confirm_password"
                  value={state.confirm_password}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Check the password and re-enter
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              {/* date of birth */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>Date of birth*</Form.Label>
                <DatePicker
                  onChange={handleDate}
                  style={{
                    width: "100%",
                    height: "39px",
                    borderColor:
                      dateValid === null ? "" : dateValid === true ? "var(--bs-form-valid-border-color)" : "var(--red)",
                  }}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display:
                      dateValid === null
                        ? "none"
                        : dateValid === false
                          ? "block"
                          : "none",
                  }}
                >
                  Min age should be 16 years
                </Form.Control.Feedback>
              </Form.Group>
              {/* gender */}
              <Form.Group as={Col} className="mb-2 pb-1">
                <Form.Label>Gender</Form.Label>
                <Form.Select name="gender" value={state.gender} onChange={handleChange} required pattern="^[a-z]$">
                  <option value={""}>Choose...</option>
                  <option value={1}>Male</option>
                  <option value={2}>Female</option>
                  <option value={3}>Others</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select your Gender
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Form.Group key={`default-checkbox`} className="mb-2">
              <Form.Check
                required
                label={
                  <span>
                    I agree to the{" "}
                    <Link to={"/terms"} className="we-account login-link">
                      Terms and condition
                    </Link>{" "}
                    policy
                  </span>
                }
                feedback="You must agree before submitting."
                feedbackType="invalid"
                defaultChecked={state.terms_condition}
                onChange={handleChangeTerms}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="register-btn">
              Register
            </Button>
          </Form>
          <span>
            Already have an account?{" "}
            <Link to={"/login"} className="we-account login-link">
              Login Now
            </Link>
          </span>

          {/* Modal for message */}
          <Modal size="sm" show={show} onHide={() => setShow(false)} backdrop="static" keyboard={false} centered>
            <Modal.Body>
              {response.isSuccess === true && (
                <SuccessAlert
                  title={response?.isSuccess === true ? "User Register Successfull!!" : ""}
                />
              )}
              {response.isSuccess === false && (
                < ErrorAlert
                  title={
                    response?.isSuccess === false ? response.error?.data?.message : "Somthing Went Wrong!!"
                  }
                />
              )}
            </Modal.Body>
          </Modal>
        </div>
      </GoBackWrapper>
    </>
  );
};

export default Register;


