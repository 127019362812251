import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const commentApi = createApi({
    reducerPath: "commentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["comment"],
    endpoints: (builder) => ({
        // GET ALL COMMENT AGAINST POST ID
        getComments: builder.query({
            query: (postId) => ({
                url: "api/posts/comment/lists",
                method: "GET",
                params: {
                    post_id: postId,
                },
            }),
            providesTags: ["comment"],
        }),
        // ADD COMMENT
        addComment: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/comment/create`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["comment"]
        }),
        // REPLY COMMENT
        replyComment: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/comment/create`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["comment"]
        }),
        // EDIT COMMENT
        editComment: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/comment/update`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["comment"]
        }),
        // DELETE COMMENT
        deleteComment: builder.mutation({
            query: ({ comment_id }) => {
                return {
                    url: `api/posts/comment/delete`,
                    method: "DELETE",
                    body: {
                        "comment_id": comment_id
                    }
                };
            },
            invalidatesTags: ["comment"],
        }),
    }),
});

export const { useGetCommentsQuery, useAddCommentMutation, useEditCommentMutation, useDeleteCommentMutation, useReplyCommentMutation } = commentApi;