import React, { useEffect, useRef, useState } from 'react'
import LoaderImg from '../../assets/images/preLoad_img.webp'

const LazyImage = ({src, alt, height, width, className, loaderIcon}) => {  
  const [imgInView, setImgInView] = useState(false);
  const imageRef = useRef(null);
  const loader = new Image();

  const checkImage = () => {
    if (loader.complete) {
      lazyLoad();
    }
  };

  const lazyLoad = () => {
    if (elementInViewPort()) {
      setImgInView(true)
    }
  };
  
  // CHECKING IMAGE IS IN VIEWPORT OR NOT || RETURN TRUE IF IMAGE IN VIEWPORT 
  const elementInViewPort = () => {
    // getBoundingClientRect => returns the size of the given element and the position of it in relation to the view port
    const clientRect = imageRef?.current?.getBoundingClientRect();
    return (
      clientRect?.top <= (window.innerHeight || document?.documentElement?.clientHeight)
    );
  };

  useEffect(()=>{
    loader.src = LoaderImg;
    loader.addEventListener("load", checkImage);
    
    window.onscroll = window.addEventListener("scroll", lazyLoad);
    return () => {
        // remove the lazyLoad method
        window.removeEventListener("scroll", lazyLoad);
    };
  }, [])

  return <img ref={imageRef} src={imgInView ? src : LoaderImg} alt={alt} height={height} width={width} className={className} />; 
}

export default LazyImage