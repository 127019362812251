import React from "react";
import { Link } from "react-router-dom";
import WidgetWrapper from "../WidgetWrapper";
import style from "./ProfileWidget.module.css";
import { useSelector } from "react-redux";
import PercentComplete from "./PercentComplete";
import { defaultUserCover } from "../../components";
import { useGetFollowingListQuery, useGetUserDetailsAgainstIdQuery, useGetfollowerListQuery } from "../../../store/api/userApi";

const ProfileWidget = () => {
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });
  // get follower following list
  const followerList = useGetfollowerListQuery();
  const followingList = useGetFollowingListQuery();

  // get my details against my id
  const myProfileDetails = useGetUserDetailsAgainstIdQuery(userSliceData._id)

  let dataFromLS = JSON.parse(localStorage.getItem("userData"));

  return (
    <>
      <WidgetWrapper>
        <div className={style.profile_widget}>
          <div className={style.profile_top}>
            <div className={style.cover_img}>
              {userSliceData?.cover_picture !== null ? <img src={userSliceData?.cover_picture[0]?.hasOwnProperty("image_url") ? userSliceData?.cover_picture[0]?.image_url : defaultUserCover} height={100} width={100} alt="avatar" /> : (
                <img src={defaultUserCover} height={100} width={100} alt="avatar" />
              )}
            </div>
            <PercentComplete />
            <div className={style.profile_right}>
              <div className={style.page_meta}>
                <Link to={`/profile/${userSliceData._id}/about`} className="underline">
                  <span>{userSliceData.fname} {userSliceData.mname} {userSliceData.lname}</span>
                </Link>
                <span className={style.desig}>
                  {userSliceData.bio}
                </span>
                {/* <span>
                  {userSliceData.gender === "1" ? "Male" : (
                    userSliceData.gender === "2" ? "Female" : "Others"
                  )} | {" "}{userSliceData.countries.country}
                </span>
                <span className={style.desig}>
                  {userSliceData.email ? (userSliceData.email) : (userSliceData.phone)}
                </span> */}
              </div>
            </div>
          </div>
          <div className={style.profile_follow}>
            <ul className={style.profile_nav}>
              <li className={style.profile_nav_item}>
                <div className={style.profile_link}>
                  <span className="heading">Profile Views</span>
                  <div>
                    <span className={style.total_count}>
                      {myProfileDetails?.data?.data?.profile_view_count}
                    </span>
                  </div>
                </div>
              </li>
              <li className={style.profile_nav_item}>
                <Link to={`/profile/${userSliceData._id}/follower`} className={style.profile_link}>
                  <span className="heading">Followers</span>
                  <div>
                    <span className={style.total_count}>
                      {followerList?.data?.total_followers || 0}
                    </span>
                  </div>
                </Link>
              </li>
              <li className="profile_nav_item">
                <Link to={`/profile/${userSliceData._id}/following`} className={style.profile_link}>
                  <span className="heading">Following</span>
                  <div>
                    <span className={style.total_count}>
                      {followingList?.data?.total_following || 0}
                    </span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className={style.profile_bottom}>
            <div className={style.subscrib_div}>
              <span>Subscription</span>
              <div>
                <span className={style.sub_btn}>{dataFromLS?.membership?.plan_name || "Free"}</span>
                <Link to={`/plans`} className={style.heading}>Upgrade</Link>
              </div>
            </div>
          </div>
        </div>
      </WidgetWrapper>
    </>
  );
};

export default ProfileWidget;