const EpochTimeToDate = (time) => {
    const date = new Date(time);

    // Get the year, month, date, etc. separately.
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript.
    const day = date.getDate();

    // Format the string according to our need.
    const formattedDate = `${year}-${month}-${day}`;

    const dateComponents = formattedDate.split("-")
    const month2 = dateComponents[1].padStart(2, "0");
    const day2 = dateComponents[2].padStart(2, "0");
    const convertedDateString = `${dateComponents[0]}-${month2}-${day2}`;
    return convertedDateString;
}

export default EpochTimeToDate;
