import React from "react";
import {
   DashboardCoverPhoto,
   DashboardMenu,
   DashboardUserInfo,
   DashnoardPostFollower,
   PlanWidget,
} from "../../components/components";
import "./Dashboard.css"
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import DashboardAction from "../../components/dashboard/dashboardAction/DashboardAction";
import { useGetUserDetailsAgainstIdQuery } from "../../store/api/userApi";
import { isMobile } from "../../methods/checkMobile";

const ProfileLayout = ({ children, handleDisplay }) => {
   const { pathname } = useLocation();
   const { userId } = useParams();
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   const menuArr = [
      { title: "About", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/about` },
      { title: "Timeline", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/timeline` },
      { title: "Connection", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/connections` },
      { title: "Pages", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/pages` },
      { title: "Groups", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/groups` },
      { title: "Events", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/events` },
      { title: "Photo & Video", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media` },
   ];
   const menuOthers = [
      { title: "Timeline", url: `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/timeline` },
   ];

   const userDetails = useGetUserDetailsAgainstIdQuery(userId);

   if (userDetails.isSuccess) {
      if (userDetails.data.error === 1) {
         return (
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">
                  {userDetails.data.message}
               </h4>
            </div>
         )
      }
   }

   // Storing Dashboard Cover Photo Widget 
   const DashboardCoverProfileWidget = () => (
      <div className="user-profile">
         <figure>
            {handleDisplay !== "none" && <DashboardCoverPhoto />}
            {/* <DashboardSettings /> */}
            {isMobile() ? '' : <PlanWidget />}
         </figure>
         <div className="profile-section">
            <div className="row">
               {handleDisplay !== "none" && <div className="col-lg-2 col-md-3">
                  {<DashboardUserInfo />}
                  {isMobile() ? <div className="text-center"><PlanWidget /></div> : ''}
               </div>}
               {handleDisplay === "none" && <div className="col-lg-2 col-md-3 fw-bold text-center">
                  <div>{userDetails?.data?.data?.fullName}</div>
                  <div>{userDetails?.data?.data?.countries.country}</div>
               </div>}
               <div className="col-lg-10 col-md-9">
                  <div className="d-md-flex align-items-center h-100">
                     {isMobile() ? '' : <DashboardMenu links={userDetails?.data?.data?._id === userSliceData._id ? menuArr : (userDetails?.data?.data?.is_friend === false && userDetails?.data?.data?.is_fullprofile_view === false) ? menuOthers : menuArr} />}
                     {userId === userSliceData._id && <DashnoardPostFollower />}
                     {!(userId === userSliceData._id) && <DashboardAction className="ms-auto mt-0" />}
                  </div>
               </div>
            </div>
         </div>
      </div>
   )

   // if mobile true hide and 
   return (
      <>
         <div className="gap2 gray-bg full_height">
            <div className="container">
               {/* If Mobile and Page route is '/timeline' hiding this section*/}
               {(isMobile() && (pathname === `/profile/${userId === userSliceData._id ? userSliceData._id : userId}/timeline`)) && <DashboardCoverProfileWidget />}

               {/* If Mobile hide this section */}
               {isMobile() === false && <DashboardCoverProfileWidget />}

               {children}
            </div>
         </div>
      </>
   );
};

export default ProfileLayout;
