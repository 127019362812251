import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const { userSlice } = getState();
      let token = userSlice.userAuthToken;
      return headers.set("x-access-token", token);
    }
  }),
  tagTypes: ["auth", "healthCheckup"],
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (userData) => {
        return {
          url: "api/signup",
          method: "POST",
          body: userData,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags: ["auth"],
    }),
    loginUser: builder.mutation({
      query: (user) => {
        return {
          url: `api/signin`,
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags: ["auth"]
    }),
    signoutUser: builder.mutation({
      query: (token) => {
        return {
          url: `api/signout`,
          method: "POST",
          body: {
            'token': token
          }
        };
      },
      invalidatesTags: ["auth"],
    }),
    forgotPassword: builder.mutation({
      query: (data) => {
        return {
          url: `api/forget-password`,
          method: "POST",
          body: {
            emailorphone: data
          },
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags: ["auth"],
    }),
    SetPassword: builder.mutation({
      query: (data) => {
        return {
          url: `api/reset-password`,
          method: "POST",
          body: {
            "new_password": data.new_password,
            "token": data.token
          }
        };
      },
      invalidatesTags: ["auth"],
    }),

    UpdatePassword: builder.mutation({
      query: (data) => {
        console.log(data)
        return {
          url: `api/update-password`,
          method: "PUT",
          body: {
            "current_password": data.current_password,
            "new_password": data.new_password
          }
        };
      },
      invalidatesTags: ["auth"],
    }),
    OtpValidation: builder.mutation({
      query: (data) => {
        return {
          url: `api/validate-otp`,
          method: "POST",
          body: {
            "emailorphone": data.emailorphone,
            "otp": data.otp
          }
        };
      },
      invalidatesTags: ["auth"],
    }),
    OtpVerification: builder.mutation({
      query: (data) => {
        return {
          url: `api/verify-otp`,
          method: "POST",
          body: {
            "emailorphone": data.emailorphone,
          }
        };
      },
      invalidatesTags: ["auth"],
    }),
    healthCheckup: builder.mutation({
      query: (token) => {
        return {
          url: `api/healthchecker`,
          method: "POST",
          body: {
            'token': token
          }
        };
      },
      invalidatesTags: ["healthCheckup"],
    }),

  }),
});

export const { useOtpValidationMutation, useOtpVerificationMutation, useCreateUserMutation, useUpdatePasswordMutation, useLoginUserMutation, useSignoutUserMutation, useForgotPasswordMutation, useSetPasswordMutation, useHealthCheckupMutation } = authApi;
