import React, { useEffect, useReducer, useState } from 'react'
import ReactQuill from 'react-quill'
import './ChatBottom.css'
import { useSelector } from 'react-redux';
import Compressor from "compressorjs";
import AWS from "aws-sdk";
import generateRandomImageName from '../../../../methods/generateRandomImageName';
import { LiaTimesSolid } from 'react-icons/lia';
import ReactPlayer from 'react-player';
import toast from 'react-hot-toast';
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { socket } from '../../../../methods/Socket';

const initialState = {
  preview: false,
  images: [],
};

// Reducer function to manage the post state
const chatReducer = (state, action) => {
  switch (action.type) {
    case "SET_PREVIEW":
      return { ...state, preview: action.payload };
    case "SET_IMAGES":
      return { ...state, images: action.payload };
    case "RESET_REDUCER":
      return { preview: false, text: "", images: [], category: null };
    default:
      return state;
  }
};

const ChatBottom = ({  userData }) => {
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);
  const [typingData, setTypingData] = useState("")
  const [typingUserId, setTypingUserId] = useState("")
  const [post, dispatch] = useReducer(chatReducer, initialState);

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  })

  const { roomData } = useSelector((store) => {
    return store.chat;
  });

  let selectedFIles;

  let compressedFiles = []

  // ACCESING IMAGE INPUTS
  function handleImageFileChange(event) {
    if (image.length < 5) {
      setImage([...event.target.files]);
      selectedFIles = [];
      const targetFiles = event.target.files;
      const targetFilesObject = [...targetFiles];

      targetFilesObject.map((file) => {
        selectedFIles.push({
          name: file.name,
          image_url: URL.createObjectURL(file),
          type: file.type,
          size: file.size
        })
        if (file.type.slice(0, 5) === "image") {
          new Compressor(file, {
            quality: 0.2, // Default compression quality
            success(result) {
              const file = new File([result], result.name, { type: result.type });
              compressedFiles.push({
                name: file.name,
                image_url: file,
                type: file.type,
                size: file.size
              })
              dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
            },
            error(err) {
              console.error('Image compression error:', err);
            },
          })
        } else {
          compressedFiles.push({
            name: file.name,
            image_url: file,
            type: file.type,
            size: file.size
          })
        }
        return dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
      });
    }
    event.target.value = ''
  }

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (image.length > 4) {
      toast.error("Can't select more than 4!!")
    } else {
      // handle file to show
      // S3 bucket implement start
      const S3_BUCKET = "ubuntu-social-media-2";
      const REGION = "ap-south-1";
      AWS.config.update({
        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
        secretAccessKey: "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",
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      let imgArr = [];
      for (let i = 0; i < post.images.length; i++) {
        let randomName = generateRandomImageName(post.images[i].name)
        let fileSize = post.images[i].size;
        let fileType = post.images[i].type;
        let fileName = "post_" + randomName.split(" ").join("_").split("-").join("_");
        const params = {
          Bucket: S3_BUCKET,
          Key: fileName,
          Body: post.images[i].image_url,
        };

        var upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
          // File uploading progress
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );
        }).promise();

        imgArr.push({
          image_url: "https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + fileName,
          size: fileSize,
          mime_type: fileType,
        });
      }
      await upload;
      // S3 bucket implement end

      if (userSliceData.fullName) {
        // console.log( "sender:", userSliceData?._id, "receiver:", userData?._id )
        socket.emit('newChat', {
          sender: userSliceData?._id,
          receiver: userData?._id,
          message: message,
          room_id: roomData ? roomData : userData?.common_room_id,
          createdAt: Date.now(),
          images: imgArr,
        })
      }
    }
    setMessage('');
    removeImage()
  };


  const typingCheck = () => {
    socket.emit("typing", {
      text: `typing...`,
      room_id: roomData ? roomData : userData?.common_room_id,
      typingUserId: userSliceData._id
    });
  }

  useEffect(() => {
    socket.on("typingResponse", (data) => {
      setTypingData(data.text);
      setTypingUserId(data.typingUserId)
    });
    setTimeout(() => {
      setTypingData("");
    }, 2000)
  }, [typingData])

  // REMOVEING IMAGES
  const removeImage = (index) => {
    compressedFiles = []
    selectedFIles = []
    dispatch({ type: "SET_IMAGES", payload: [] });
    setImage([]);
  };

  useEffect(() => {
    if (image.length > 4) {
      toast.error("Can't select more than 4!!!")
    }
  }, [image.length > 4])

  if (userData?.fullName) {
    return (
      <div className='chatBottom p-3 px-4'>
        <>{typingUserId !== userSliceData._id && typingData}</>
        {post.images && (
          <>
            {/* FOR SINGLE IMAGE */
              (post.images.length === 1 && image.length > 0) && (
                <div className="img-bunch mt-2 style_with_1">
                  <div className="row g-1">
                    <div className="col-md-4">
                      <div className="preview_chat_img">
                        {image[0].type.slice(0, 5) === "image" && (
                          <img src={URL.createObjectURL(image[0])} alt="..." />
                        )}
                        {image[0].type.slice(0, 5) === "video" && (
                          <ReactPlayer
                            // playing={true}
                            width={"100%"}
                            controls={true}
                            url={URL.createObjectURL(image[0])}
                          />
                        )}
                        {image[0].type.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {image.length > 0 && <button className="chat-remove-btn" onClick={() => removeImage(0)}>
                    <LiaTimesSolid />
                  </button>}
                </div>
              )}
            {/* FOR TWO IMAGE */
              (post.images.length === 2 && image.length > 0) && (
                <div className="img-bunch mt-2 style_with_2">
                  <div className="row g-1">
                    <div className="col-md-2">
                      <div className="preview_chat_img">
                        {image[0]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[0])} alt="..." />
                          )}
                        {image[0]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[0])}
                            />
                          )}
                        {image[0]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="preview_chat_img">
                        {image[1]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[1])} alt="..." />
                          )}
                        {image[1]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[1])}
                            />
                          )}
                        {image[1]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[1].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <button className="remove-btn" onClick={() => removeImage(0)} >
                    <LiaTimesSolid />
                  </button>
                </div>
              )}
            {/* FOR THREE IMAGE */
              (post.images.length === 3 && image.length > 0) && (
                <div className="img-bunch mt-2 style_with_3">
                  <div className="row g-1">
                    <div className="col-md-2">
                      <div className="preview_post_img preview_chat_img_1">
                        {image[0]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[0])} alt="..." />
                          )}
                        {image[0]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[0])}
                            />
                          )}
                        {image[0]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                      <div className="preview_post_img preview_chat_img_2">
                        {image[1]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[1])} alt="..." />
                          )}
                        {image[1]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[1])}
                            />
                          )}
                        {image[1]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[1].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="preview_post_img preview_chat_img_3">
                        {image[2]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[2])} alt="..." />
                          )}
                        {image[2]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[2])}
                            />
                          )}
                        {image[2]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[2].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <button className="remove-btn" onClick={() => removeImage(0)} >
                    <LiaTimesSolid />
                  </button>
                </div>
              )}
            {/* FOR FOUR IMAGE */
              (post.images.length === 4 && image.length > 0) && (
                <div className="img-bunch mt-2 style_with_4">
                  <div className="row g-1">
                    <div className="col-md-2">
                      <div className="preview_post_img preview_chat_img">
                        {image[0]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[0])} alt="..." />
                          )}
                        {image[0]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[0])}
                            />
                          )}
                        {image[0]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[0].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                      <div className="preview_post_img preview_chat_img">
                        {image[1]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[1])} alt="..." />
                          )}
                        {image[1]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[1])}
                            />
                          )}
                        {image[1]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[1].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="preview_post_img preview_chat_img">
                        {image[2]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[2])} alt="..." />
                          )}
                        {image[2]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[2])}
                            />
                          )}
                        {image[2]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[2].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                      <div className="preview_post_img preview_chat_img">
                        {image[3]?.type?.slice(0, 5) ===
                          "image" && (
                            <img src={URL.createObjectURL(image[3])} alt="..." />
                          )}
                        {image[3]?.type?.slice(0, 5) ===
                          "video" && (
                            <ReactPlayer
                              // playing={true}
                              width={"100%"}
                              controls={true}
                              url={URL.createObjectURL(image[3])}
                            />
                          )}
                        {image[3]?.type?.slice(0, 5) === "appli" && (
                          <div className='file-view'>
                            <>file{(image[3].size / 1048576).toString().slice(0, 4)} MB <FaRegArrowAltCircleDown /></>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <button className="remove-btn" onClick={() => removeImage(0)} >
                    <LiaTimesSolid />
                  </button>
                </div>
              )}
          </>
        )}
        <div className="chat-form rounded-pill">
          <div className="row align-items-center gx-2">
            <div className="col-auto">
              <label className='chatAttachmentButton'>
                <input
                  type="file"
                  id="upload_post_image"
                  onChange={handleImageFileChange}
                  multiple
                  accept="image/*, video/*, .pdf, .docx"
                  style={{ display: 'none' }}

                />
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
              </label>
            </div>
            <div className="col">
              <div className='writeMessage'>
                <ReactQuill
                  onKeyDown={typingCheck}
                  value={message}
                  onChange={(value) => setMessage(value)}
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike'],
                      ['blockquote']
                    ],
                  }}
                  formats={[]}
                  placeholder="send a message !!!"
                />
              </div>
            </div>
            <div className="col-auto">
              <button className="rounded-circle chatBtnSent btn btn-primary" onClick={handleSendMessage}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default ChatBottom