import './editPost.css'
import TextArea from "antd/es/input/TextArea";
import { useReducer, useState } from "react";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { BsImage } from "react-icons/bs";
import AWS from "aws-sdk";
import generateRandomImageName from "../../../methods/generateRandomImageName"
import Compressor from "compressorjs";
import { useEditPostCommunityMutation, useEditPostEventMutation, useEditPostGroupMutation, useEditPostMutation, useEditPostPageMutation } from "../../../store/api/postApi";
import ReactPlayer from "react-player";
import { LiaTimesSolid } from "react-icons/lia";
import { useParams } from 'react-router-dom';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

// Reducer function to manage the edit post state in modal
const postReducer = (state, action) => {
    switch (action.type) {
        case "SET_PREVIEW":
            return { ...state, preview: action.payload };
        case "SET_TEXT":
            return { ...state, text: action.payload };
        case "SET_IMAGES":
            return { ...state, images: action.payload };
        case "RESET_REDUCER":
            return {
                preview: false,
                text: "",
                images: [],
            };
        default:
            return state;
    }
};

//this is main edit post component
const EditPost = ({ data, postId, index, showEditModal, type }) => {
    console.log(type)
    // edit post state
    const editPostState = {
        preview: true,
        text: data.post_text || "",
        images: data.images || [],
    };

    const [post, dispatch] = useReducer(postReducer, editPostState);
    const [image, setImage] = useState([]);
    const [editPost] = useEditPostMutation();
    const [editPostCommunity] = useEditPostCommunityMutation();

    const [editPostPage] = useEditPostPageMutation()
    const [editPostGroup] = useEditPostGroupMutation()
    const [editPostEvent] = useEditPostEventMutation()

    const { pageId, groupId, eventId } = useParams()

    // to manage text in edit modal
    const handleChange = (event) => {
        dispatch({ type: "SET_TEXT", payload: event });
        // dispatch({ type: "SET_TEXT", payload: event.target.value });
    };

    let selectedFIles;
    let compressedFiles = []
    // to manage images from input
    function handleImageFileChange(event) {
        setImage([...event.target.files]);
        selectedFIles = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles];
        targetFilesObject.map((file) => {
            selectedFIles.push({
                name: file.name,
                image_url: URL.createObjectURL(file),
                type: file.type,
                size: file.size
            })
            if (file.type.slice(0, 5) === "image") {
                new Compressor(file, {
                    quality: 0.2, // Default compression quality
                    success(result) {
                        const file = new File([result], result.name, { type: result.type });
                        compressedFiles.push({
                            name: file.name,
                            image_url: file,
                            type: file.type,
                            size: file.size
                        })
                        dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
                    },
                    error(err) {
                        console.error('Image compression error:', err);
                    },
                })
            } else {
                compressedFiles.push({
                    name: file.name,
                    image_url: file,
                    type: file.type,
                    size: file.size
                })
            }
            return dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
        });
        event.target.value = ''
    }

    //main update post function
    const handleUpdatePost = async () => {
        if (image.length > 0) {
            // S3 bucket implement start
            const S3_BUCKET = "ubuntu-social-media-2";
            const REGION = "ap-south-1";
            AWS.config.update({
                accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                secretAccessKey: "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",
            });

            const s3 = new AWS.S3({
                params: { Bucket: S3_BUCKET },
                region: REGION,
            });

            let imgArr = [];
            for (let i = 0; i < post.images.length; i++) {
                let randomName = generateRandomImageName(post.images[i].name)
                let fileSize = post.images[i].size;
                let fileType = post.images[i].type;
                let fileName = "post_" + randomName.split(" ").join("_").split("-").join("_");
                const params = {
                    Bucket: S3_BUCKET,
                    Key: fileName,
                    Body: post.images[i].image_url,
                };

                var upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
                    // File uploading progress
                    console.log(
                        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                    );
                }).promise();

                imgArr.push({
                    image_url: "https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + fileName,
                    size: fileSize,
                    mime_type: fileType,
                });
            }
            await upload;
            // S3 bucket implement end
            let obj = {
                pageId: pageId, groupId: groupId, eventId: eventId,
                post_text: post.text,
                post_id: postId,
                post_type: "string",
                scope: "public",
                images: imgArr,
                index
            }
            if (type === "community") {
                editPostCommunity(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else if (type === "page") {
                editPostPage(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else if (type === "group") {
                editPostGroup(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else if (type === "event") {
                editPostEvent(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else {
                editPost(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
        } else {
            let obj = {
                pageId: pageId, groupId: groupId, eventId: eventId,
                post_text: post.text,
                post_id: postId,
                post_type: "string",
                scope: "public",
                images: post.images || [],
                index
            }
            if (type === "community") {
                editPostCommunity(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else if (type === "page") {
                editPostPage(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else if (type === "group") {
                editPostGroup(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else if (type === "event") {
                editPostEvent(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
            else {
                editPost(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                    } else {
                        toast.error(res?.data?.message)
                    }
                })
            }
        }
        showEditModal.setShowEditModal(false)
        compressedFiles = []
        selectedFIles = []
        dispatch({ type: "RESET_REDUCER" });
        setImage([]);
    }

    // removing images from selected images
    const removeImage = () => {
        compressedFiles = []
        selectedFIles = []
        dispatch({ type: "SET_IMAGES", payload: [] });
        setImage([]);
    };

    return (
        <>
            <h5 className='mb-3'>Edit your post</h5>
            <ReactQuill
                value={post.text}
                onChange={handleChange}
                modules={{
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, 7] }]
                    ],
                }}
                formats={["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent"]}
                placeholder="Share some what are you thinking?"
            />
            {true ? (
                <>
                    {
                        /* FOR SINGLE IMAGE */
                        post?.images.length === 1 || image.length === 1 ? (
                            <div className="img-bunch mt-2 style_with_1">
                                <div className="row g-1">
                                    <div className="col-md-12">
                                        <div className="preview_post_img preview_post_img_1">
                                            {(image[0]?.type?.slice(0, 5) === "image" || post?.images[0]?.mime_type?.slice(0, 5) === "image" || post?.images[0]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 1 ? URL.createObjectURL(image[0]) : post?.images[0]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 1 && image[0]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[0]) : post?.images[0].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <button className="remove-btn" onClick={() => removeImage(0)}>
                                    <LiaTimesSolid />
                                </button>
                            </div>
                        ) : ("")
                    }
                    {
                        /* FOR TWO IMAGE */
                        post?.images.length === 2 || image.length === 2 ? (
                            <div className="img-bunch mt-2 style_with_2">
                                <div className="row g-1">
                                    <div className="col-md-6">
                                        <div className="preview_post_img preview_post_img_1">
                                            {(image[0]?.type?.slice(0, 5) === "image" || post?.images[0]?.mime_type?.slice(0, 5) === "image" || post?.images[0]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 2 ? URL.createObjectURL(image[0]) : post?.images[0]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 2 && image[0]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[0]) : post?.images[0].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="preview_post_img preview_post_img_1">
                                            {(image[1]?.type?.slice(0, 5) === "image" || post?.images[1]?.mime_type?.slice(0, 5) === "image" || post?.images[1]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 2 ? URL.createObjectURL(image[1]) : post?.images[1]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 2 && image[1]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[1]) : post?.images[1].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <button className="remove-btn" onClick={() => removeImage(0)}>
                                    <LiaTimesSolid />
                                </button>
                            </div>
                        ) : ("")
                    }
                    {
                        /* FOR THREE IMAGE */
                        post?.images.length === 3 || image.length === 3 ? (
                            <div className="img-bunch mt-2 style_with_3">
                                <div className="row g-1">
                                    <div className="col-md-6">
                                        <div className="preview_post_img preview_post_img_1">
                                            {(image[0]?.type?.slice(0, 5) === "image" || post?.images[0]?.mime_type?.slice(0, 5) === "image" || post?.images[0]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 3 ? URL.createObjectURL(image[0]) : post?.images[0]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 3 && image[0]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[0]) : post?.images[0].image_url}
                                                />
                                            }
                                        </div>
                                        <div className="preview_post_img preview_post_img_2">
                                            {(image[1]?.type?.slice(0, 5) === "image" || post?.images[1]?.mime_type?.slice(0, 5) === "image" || post?.images[1]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 3 ? URL.createObjectURL(image[1]) : post?.images[1]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 3 && image[1]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[1]) : post?.images[1].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="preview_post_img preview_post_img_3">
                                            {(image[2]?.type?.slice(0, 5) === "image" || post?.images[2]?.mime_type?.slice(0, 5) === "image" || post?.images[2]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 3 ? URL.createObjectURL(image[2]) : post?.images[2]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 3 && image[2]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[2]) : post?.images[2].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <button className="remove-btn" onClick={() => removeImage(0)}>
                                    <LiaTimesSolid />
                                </button>
                            </div>
                        ) : ("")
                    }
                    {
                        /* FOR FOUR IMAGE */
                        post?.images.length === 4 || image.length === 4 ? (
                            <div className="img-bunch mt-2 style_with_4">
                                <div className="row g-1">
                                    <div className="col-md-3">
                                        <div className="preview_post_img preview_post_img_1">
                                            {(image[0]?.type?.slice(0, 5) === "image" || post?.images[0]?.mime_type?.slice(0, 5) === "image" || post?.images[0]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 4 ? URL.createObjectURL(image[0]) : post?.images[0]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 4 && image[0]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[0]) : post?.images[0].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="preview_post_img preview_post_img_2">
                                            {(image[1]?.type?.slice(0, 5) === "image" || post?.images[1]?.mime_type?.slice(0, 5) === "image" || post?.images[1]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 4 ? URL.createObjectURL(image[1]) : post?.images[1]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 4 && image[1]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[1]) : post?.images[1].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="preview_post_img preview_post_img_3">
                                            {(image[2]?.type?.slice(0, 5) === "image" || post?.images[2]?.mime_type?.slice(0, 5) === "image" || post?.images[2]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 4 ? URL.createObjectURL(image[2]) : post?.images[2]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 4 && image[2]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[2]) : post?.images[2].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="preview_post_img preview_post_img_3">
                                            {(image[3]?.type?.slice(0, 5) === "image" || post?.images[3]?.mime_type?.slice(0, 5) === "image" || post?.images[3]?.type?.slice(0, 5) === "image") ?
                                                <img src={image.length === 4 ? URL.createObjectURL(image[3]) : post?.images[3]?.image_url} alt="..." /> :
                                                <ReactPlayer
                                                    width={"100%"}
                                                    controls={true}
                                                    url={image.length === 4 && image[3]?.type?.slice(0, 5) === "video" ? URL.createObjectURL(image[3]) : post?.images[3].image_url}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <button className="remove-btn" onClick={() => removeImage(0)}>
                                    <LiaTimesSolid />
                                </button>
                            </div>
                        ) : ("")
                    }
                    {
                        /* FOR FIVE IMAGES */
                        // post.images.length === 5 ? (
                        //   <div className="img-bunch mt-2 style_with_five">
                        //     <div className="row g-1">
                        //       <div className="col-md-6">
                        //         <div className="preview_post_img preview_post_img_1">
                        //           <img src={URL.createObjectURL(image[0])} alt="..." />
                        //         </div>
                        //         <div className="preview_post_img preview_post_img_2">
                        //           <img src={URL.createObjectURL(image[1])} alt="..." />
                        //         </div>
                        //       </div>
                        //       <div className="col-md-6">
                        //         <div className="preview_post_img preview_post_img_3">
                        //           <img src={URL.createObjectURL(image[2])} alt="..." />
                        //         </div>
                        //         <div className="preview_post_img preview_post_img_4">
                        //           <img src={URL.createObjectURL(image[3])} alt="..." />
                        //         </div>
                        //         <div className="preview_post_img preview_post_img_5">
                        //           <img src={URL.createObjectURL(image[4])} alt="..." />
                        //         </div>
                        //       </div>
                        //     </div>
                        //     <button
                        //       className="remove-btn"
                        //       onClick={() => removeImage(0)}
                        //     >
                        //       <LiaTimesSolid/>
                        //     </button>
                        //   </div>
                        // ) : ("")
                    }
                </>
            ) : ("")}
            <div className='attachments'>
                <ul className="editPostOptions">
                    <li
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title=""
                        data-original-title="Upload Photos"
                    >
                        <label htmlFor="edit_post_image" className="fileContainer">
                            <BsImage />
                            <input
                                type="file"
                                id="edit_post_image"
                                onChange={handleImageFileChange}
                                multiple
                                accept="image/*, video/*"
                            />
                        </label>
                    </li>
                </ul>
            </div>

            <div className='text-center mt-3'>
                <Button className='mx-1' variant="secondary" onClick={() => showEditModal.setShowEditModal(false)}>Cancel</Button>
                <Button className='mx-1' variant="primary" onClick={handleUpdatePost}>Update</Button>
            </div>
        </>
    )

}

export default EditPost;
