import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    theme: JSON.parse(localStorage.getItem("theme")) || "light-theme",
};
const ThemeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
            document.body.classList = state.theme;
            localStorage.setItem("theme", JSON.stringify(state.theme));
        },
    },
});

// set Theme
export const { setTheme } = ThemeSlice.actions;
// Select Theme
export const selectTheme = (state) => state.theme;
export default ThemeSlice.reducer;
