import React, { useEffect, useState } from 'react'
import { AdminMenu } from '../../../components/components'
import { useSelector } from 'react-redux';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';
import { useGetAdminSystemUserQuery } from '../../../store/api/Admin/userPanelApi';
import { Input, Pagination } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import AdminUserTableRow from '../../../components/adminComponent/table/tableRow/AdminUserTableRow';

const SystemUsers = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    // stste -> page no
    const [page, setPage] = useState(searchParams.get("page") || 1);
    // state -> search
    const [search, setSearch] = useState(searchParams.get("search") || "");

    // get user info from local storage
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    // Api call to get system users
    const systemUserList = useGetAdminSystemUserQuery({
        page: page,
        per_page: 10,
        searchKeyword: search
    })

    // page no change in pagination
    const handlePageChange = (page) => {
        setPage(page)
    }

    // for verify permission
    const [permissionArr, setPermissionArr] = useState("")
    const userPermissionList = useUserPermissionListQuery()
    useEffect(() => {
        if (userPermissionList?.isSuccess === true) {
            let user_permission_arr = userPermissionList?.data?.permissions;
            setPermissionArr(user_permission_arr)
        }
    }, [userPermissionList]);

    // set data in param
    useEffect(() => {
        let params = { page };
        if (search) {
            params.search = search;
        }
        setSearchParams(params);
    }, [page, search, setSearchParams]);

    return (
        <>
            <div className='gap2 gray-bg full_height'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <AdminMenu open />
                        </div>
                        <div className='col-md-9'>
                            <div className='bg-white rounded-2 px-1 pb-3'>
                                <div className='d-md-flex justify-content-between align-items-center p-2'>
                                    <h5 className='mb-0'>System Users</h5>
                                    {/* {(userInfo.user_type === 3 && permissionArr.includes("reaction_create")) && <Link to="/admin/reactions/create" className="createBtn ms-auto me-2">
                                    <FaPlus /> Create
                                </Link>} */}
                                    {userInfo.user_type === 1 && <Link to="/admin/roles-permission/system-users/create" className="createBtn ms-auto me-2">
                                        <FaPlus /> Create
                                    </Link>}
                                    <div>
                                        <Input placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} style={{ width: 200 }} />
                                    </div>
                                </div>
                                <div className='table_head'>
                                    <div className='row'>
                                        <div className='col-md-2'><div className='table_head_info text-center'>Full Name</div></div>
                                        <div className='col-md-3'><div className='table_head_info text-center'>Contact</div></div>
                                        <div className='col-md-2'><div className='table_head_info text-center'>Membership</div></div>
                                        <div className='col-md-2'><div className='table_head_info text-center'>Role</div></div>
                                        {userInfo.user_type === 1 && <div className='col-md-3'><div className='table_head_info text-center'>Action</div></div>}
                                    </div>
                                </div>
                                {
                                    systemUserList.isError && (
                                        <div className='col-12'>
                                            <div className='noData'>
                                                <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    systemUserList.isLoading && (
                                        <div className='col-12'>
                                            <div className='noData'>
                                                <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    systemUserList.isSuccess && (
                                        systemUserList?.data?.data?.length > 0 ? (
                                            systemUserList?.data?.data?.map((elem, i) => {
                                                return (
                                                    <AdminUserTableRow data={elem} key={elem._id} />
                                                )
                                            })
                                        ) : (
                                            <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!!</h5>
                                        )
                                    )
                                }
                                {
                                    systemUserList?.data?.data?.length > 0 && (
                                        <div className='mt-3 text-center'>
                                            <Pagination
                                                defaultCurrent={1}
                                                current={Number(page)}
                                                size="small"
                                                total={systemUserList.data?.totalUsers}
                                                defaultPageSize={10}
                                                onChange={handlePageChange}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SystemUsers
