const EpochTimeToDateTime = (epochTime) => {
    // Convert epoch time to milliseconds
    const date = new Date(epochTime);

    // Get components of the date and time
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours() % 12 || 12).slice(-2); // Convert to 12-hour format
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    // Format the result
    const formattedDateTime = {date: `${year}-${month}-${day}`,time: `${hours}:${minutes} ${ampm}`};

    return formattedDateTime;




    // const date = new Date(epochTime);

    // const year = date.getFullYear();
    // const month = date.getMonth() + 1;
    // const day = date.getDate();

    // const formattedDate = `${year}-${month}-${day}`;

    // const dateComponents = formattedDate.split("-")
    // const month2 = dateComponents[1].padStart(2, "0");
    // const day2 = dateComponents[2].padStart(2, "0");
    // const convertedDateString = `${dateComponents[0]}-${month2}-${day2}`;

    // const dateTime = { date: convertedDateString, time: `${date.getHours()}:${date.getMinutes()}` };

    // return dateTime;
}

export default EpochTimeToDateTime
