import React, { useState } from 'react'
import { defaultUserDp } from '../components'
import { Button, Card, Modal } from 'react-bootstrap'
import { Dropdown, Button as AntButton } from 'antd'
import { BsThreeDots } from 'react-icons/bs'
import { useDeleteProductMutation, useDeleteSavedProductMutation, useSaveProductMutation } from '../../store/api/marketApi'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './productCard.css'

const ProductCard = ({ data, type }) => {
    const navigate = useNavigate()
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    // Delete product section
    const [deleteProdModal, setDeleteProdModal] = useState(false)
    const [deleteProduct] = useDeleteProductMutation()
    const deleteProductModal = () => {
        deleteProduct(data?._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message);
            } else {
                toast.error("Something went wrong in this");
            }
        })
        setDeleteProdModal(false);
    }

    // save product
    const [saveProduct] = useSaveProductMutation()
    const handleSaveProduct = () => {
        saveProduct(data?._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message);
            } else {
                toast.error("Something went wrong in this");
            }
        })
    }

    // delete saved product
    const [deleteSavedProdModal, setDeleteSavedProdModal] = useState(false);
    const [deleteSavedProduct] = useDeleteSavedProductMutation()
    const handleDeleteSavedProduct = () => {
        deleteSavedProduct(data?._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message);
            } else {
                toast.error("Something went wrong in this");
            }
        })
        setDeleteSavedProdModal(false);
    }

    const DropdownLinksOwnProduct = [
        {
            label: <div onClick={() => navigate(`/marketplace/${data?._id}/edit`)}>Edit </div>,
            key: "0"
        },
        {
            label: <div onClick={() => setDeleteProdModal(true)}>Delete</div>,
            key: "1"
        }
    ]

    const DropdownLinksOthersProduct = [
        {
            label: <div onClick={handleSaveProduct}>Save</div>,
            key: "0"
        }
    ]

    const DropdownLinksSavedProduct = [
        {
            label: <div onClick={() => setDeleteSavedProdModal(true)}>Delete</div>,
            key: "0"
        }
    ]

    if (type === "saved") {
        return (
            <>
                <Card className='productCard h-100' style={{ cursor: 'pointer' }} onClick={() => navigate(`/marketplace/${data?._id}`)}>
                    <Card.Img variant="top" src={data?.cover_image[0]?.image_url || defaultUserDp} height={200} />
                    <Card.Body className='d-flex flex-column'>
                        <Dropdown menu={{ items: DropdownLinksSavedProduct }} placement="bottomRight" trigger={["click"]} >
                            <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
                        </Dropdown>
                        <div>
                            <span className='proCat mb-1 badge badge-success'>{data?.category?.category_name}</span>
                        </div>
                        <Card.Title className='fw-bold proTitle'>
                            <Link to={`/marketplace/${data?._id}`}>{data?.product_name}</Link>
                        </Card.Title>
                        <Card.Text className='proDesc mb-2'>{data?.short_desc}</Card.Text>
                        <Card.Text className='fw-bold proPrice mt-auto'>{data?.selling_price_with_currency}</Card.Text>
                    </Card.Body>
                </Card >
                {/* CONFIRM DELETE SAVED PROD MODAL */}
                <Modal Modal centered size="sm" show={deleteSavedProdModal} onHide={() => setDeleteSavedProdModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to delete this product?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeleteSavedProdModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={handleDeleteSavedProduct} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal >
            </>
        )
    } else {
        return (
            <>
                <Card className='productCard h-100' style={{ cursor: 'pointer' }} onClick={() => navigate(`/marketplace/${data?._id}`)}>
                    <Card.Img variant="top" src={data?.cover_image[0]?.image_url || defaultUserDp} height={200} />
                    <Card.Body className='d-flex flex-column'>
                        {userSliceData._id === data?.user?._id && type === undefined ? <Dropdown menu={{ items: DropdownLinksOwnProduct }} placement="bottomRight" trigger={["click"]} >
                            <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
                        </Dropdown> : <Dropdown menu={{ items: DropdownLinksOthersProduct }} placement="bottomRight" trigger={["click"]} >
                            <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
                        </Dropdown>}
                        <div>
                            <span className='proCat mb-1 badge badge-success'>{data?.category?.category_name}</span>
                        </div>
                        <Card.Title className='fw-bold proTitle'>
                            <Link to={`/marketplace/${data._id}`}>{data?.product_name}</Link>
                        </Card.Title>
                        <Card.Text className='proDesc mb-2'>{data?.short_desc}</Card.Text>
                        <Card.Text className='fw-bold proPrice mt-auto'>{data?.selling_price_with_currency}</Card.Text>
                    </Card.Body>
                </Card >
                {/* CONFIRM DELETE REQ MODAL */}
                <Modal Modal centered size="sm" show={deleteProdModal} onHide={() => setDeleteProdModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to delete this request?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeleteProdModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={deleteProductModal} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal >
            </>
        )
    }
}

export default ProductCard
