import React from "react";
import style from "./ProfileWidget.module.css";
import './circleLoader.css'
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/slice/themeSlice";
import { Link } from "react-router-dom";
import { defaultUserDp } from "../../components";
import { Progress } from 'antd';

const PercentComplete = ({className}) => {
   const getTheme = useSelector(selectTheme);
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });
   const Percentage = userSliceData?.userProfilePercent?.totalPercentage

   return (
      <div className={className ? `${style.completation} ${className}` : `${style.completation}`}>
         <img
            src={userSliceData?.profile_picture?.length ? userSliceData?.profile_picture[0]?.image_url : defaultUserDp}
            alt=""
         />
         <div className={style.progresdiv+' progresdiv'}>
            <Progress               
               type="circle"
               percent={Percentage}
               className={"progres " + style.prog}
               size={74}
            />
            <Link className="point">
               {userSliceData?.userProfilePercent?.totalPercentage}%
            </Link>
         </div>
      </div>
   );
};

export default PercentComplete;
