import React from 'react'
import style from './WidgetWrapper.module.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../store/slice/themeSlice'

const WidgetWrapper = ({children, className, title, linkName,linkTo }) => {
  const {theme} = useSelector(selectTheme)

  return (
    <div className={theme === 'light-theme' ? `${style.widget} ${style.lightTheme} ${className || ''}` : `${style.widget} ${style.darkTheme} ${className || ''}`}>
        {title ? <h4 className={style.widget_title}> {title} {linkName ? <Link className="see-all" to={linkTo} title="">{linkName}</Link> : ''}</h4> : ''}        
        {children}
    </div>
  )
}

export default WidgetWrapper