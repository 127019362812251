import React, { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { defaultUserDp } from "../../components";
import { LiaTimesSolid } from "react-icons/lia";
import { IoMdCheckmark } from "react-icons/io";
import { useDeleteCoverMutation, useGetUserDetailsAgainstIdQuery, useUpdateUserMutation } from "../../../store/api/userApi";
import { useUpdateGroupMutation } from "../../../store/api/groupApi";
import { useUpdatePageMutation } from "../../../store/api/pageApi";
import AWS from "aws-sdk";
import toast from "react-hot-toast";
import { addUserSlice } from "../../../store/slice/userSlice";
import './UserInfo.css'
import { useParams } from "react-router-dom";
import { Crop } from "../crop/Crop";
import { Button, Modal } from "react-bootstrap";
import convertImageLinkToFile from "../../../methods/ConvertImageToFile";
import generateRandomImageName from "../../../methods/generateRandomImageName";
import { useCreatePostEventMutation, useCreatePostGroupMutation, useCreatePostMutation, useCreatePostPageMutation } from "../../../store/api/postApi";
import { Dropdown, Button as AntButton } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { socket } from "../../../methods/Socket";

const DashboardUserInfo = ({ data, type }) => {

  // get user id from url
  const { userId } = useParams();
  const [createPost] = useCreatePostMutation();
  // get user details against id from param
  const userDetails = useGetUserDetailsAgainstIdQuery(userId);
  const userDetailsById = userDetails?.data?.data;
  const [cover, setCover] = useState([]);
  const [coverFile, setCoverFile] = useState([]);
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });
  const dispatch = useDispatch()



  // update the user cover
  const [updateUser] = useUpdateUserMutation()
  // update the group cover
  const [updateGroup] = useUpdateGroupMutation()
  // update the page cover
  const [updatePage] = useUpdatePageMutation()
  // for cropping
  const [cropModal, setCropModal] = useState(false);
  const [cropedImage, setCropedImage] = useState("")
  const [base64Image, setBase64Image] = useState("")
  const [createPostPage] = useCreatePostPageMutation();
  const [createPostGroup] = useCreatePostGroupMutation();
  const [createPostEvent] = useCreatePostEventMutation()

  let [load, setLoad] = useState(0)
  const userID = useSelector((store) => {
    return store.userSlice.userSliceData._id;
  });

  const [image, setImage] = useState([]);
  let coverArr = []

  var compressedFiles = []
  let selectedFIles;
  // for change cropped image blob to base64
  useEffect(() => {
    cropedImage !== "" && (
      fetch(cropedImage).then(response => response.blob()).then(blob => {
        // Convert Blob to base64
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result;
          // Set the base64 data as an image URL
          setBase64Image(base64Data);
        };
        reader.readAsDataURL(blob);
      }).catch(error => {
        console.error('Error fetching or converting Blob:', error);
      })
    )
  }, [cropedImage, setBase64Image])
  // cropped base64 image to file convert and set to cover file
  useEffect(() => {
    if (base64Image !== "") {
      convertImageLinkToFile(base64Image).then((res) => {
        setCoverFile(res)
      })
    }
  }, [base64Image])

  const handleProfilePhoto = (e) => {
    // setCoverFile(e.target.files[0]);
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });

    setCover([...selectedFIles]);
    setCropModal(true)
  };

  const removeImage = () => {
    setCover([])
    setCoverFile([])
    setBase64Image("")
  }

  const handleSave = async () => {
    const S3_BUCKET_COVER = "ubuntu-social-media-2";
    const REGION2 = "ap-south-1";
    AWS.config.update({
      accessKeyId: "AKIA4NNCEDROYLRYR2OX",
      secretAccessKey: "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",
    });
    const s3forCoverPhoto = new AWS.S3({
      params: { Bucket: S3_BUCKET_COVER },
      region: REGION2,
    });

    let coverName = generateRandomImageName(coverFile.name);
    let coverSize = coverFile.size;
    let coverType = coverFile.type;

    const coverParams = {
      Bucket: S3_BUCKET_COVER,
      Key: coverName,
      Body: coverFile,
    };


    var uploadCovertoS3 = s3forCoverPhoto.putObject(coverParams).on("httpUploadProgress", (evt) => {
      // File uploading progress
      console.log(
        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
      );
      setLoad(parseInt((evt.loaded * 100) / evt.total))
    }).promise();

    await uploadCovertoS3.then((data, err) => {
    });

    let coverArr = []
    coverArr.push({
      image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
      size: coverSize,
      mime_type: coverType,
    });

    // update group cover api function call
    if (type === "group") {
      let groupObj = {
        group_id: data._id,
        scope: "private",
        title: data.title,
        description: data.description,
        rules: data.rules,
        display_image: coverArr,
        cover_image: data.cover_image
      }
      let obj = {
        posted_by: userID,
        post_text: `In ${data.title} group Profile photo update successful`,
        post_type: "string",
        images: coverArr,
        scope: "public"
      };
      updateGroup(groupObj).then((res) => {
        if (res?.data?.error === 0) {
          toast.success(res?.data?.message)
        }
      })
      createPostGroup({
        ...obj, meta_data: data._id,
        meta_type: "Group"
      })
    }

    // update page cover api function call
    else if (type === "page") {
      let obj = {
        posted_by: userID,
        post_text: `In ${data.title} page Profile photo update successful`,
        post_type: "string",
        images: coverArr,
        scope: "public"
      };
      let pageObj = {
        page_id: data._id,
        scope: "private",
        title: data.title,
        description: data.description,
        display_image: coverArr,
        cover_image: data.cover_image
      }
      updatePage(pageObj).then((res) => {
        if (res?.data?.error === 0) {
          toast.success(res?.data?.message)
        }
      })

      createPostPage({
        ...obj, meta_data: data._id,
        meta_type: "Page"
      })
      toast.success("Profile Picture Update Successfull!!!")

    }

    // update profile cover api function call
    else {
      let obj2 = {
        cover_picture: "",
        profile_picture: coverArr,
        user_id: userSliceData._id,
      }


      updateUser(obj2).then((res) => {
        let newData = res?.data?.data;
        dispatch(addUserSlice(newData));
      })

      let objCover = {
        category_id: "65951b501f6ac01f962e889d",
        posted_by: userID,
        post_text: "Profile photo uploaded successfull",
        post_type: "string",
        images: coverArr,
        scope: "public",
      };

      createPost(objCover).then((res) => {
        socket.emit("sendNotificationUserDetailsUpdate", {
          sender_id: userSliceData._id,
          meta_data: res?.data?.data?._id,
          meta_type: "Post",
          notification_for: "user_profile_photo",
          update_meta_type_for: "User",
        });
        coverArr = [];
        compressedFiles = []
        selectedFIles = []
        dispatch({ type: "RESET_REDUCER" });
        setImage([])
      })

      toast.success("Cover Picture Update Successfull!!!")
    }

    //for cover picture end
    setCover([])
    setBase64Image("")
  }

  const [deleteProfileCover] = useDeleteCoverMutation()
  const [deleteCoverModal, setDeleteCoverModal] = useState(false)
  const handleDeleteCover = () => {
    deleteProfileCover({ type: "profile" }).then((res) => {
      console.log(res)
      let newData = res?.data?.data;
      dispatch(addUserSlice(newData));
      toast.success("Profile cover removed successfully")
    })
  }

  const DropdownLinks = [
    {
      label: <>
        <input type="file" accept="image/*" onChange={handleProfilePhoto} id="profileImg" style={{ display: "none" }} />
        <label htmlFor="profileImg">
          Change Profile Picture
        </label>
      </>,
      key: "0"
    },
    {
      label: <div onClick={() => setDeleteCoverModal(true)}>Delete Profile Picture</div>,
      key: "1"
    }
  ]

  // for group
  if (type === "group") {
    return (
      <>
        <div className="profile-author">
          <div className="profile-author-thumb">
            <img
              alt={data?.title}
              src={base64Image.length > 0 ? base64Image : (data?.display_image?.length > 0) ? (data?.display_image[0]?.image_url) : (defaultUserDp)}
            />
            {data?.user?._id === userSliceData._id && <div className="edit-dp">
              <label className="fileContainer">
                <FaCamera />
                <input type="file" onChange={handleProfilePhoto} />
              </label>
            </div>}
            {base64Image.length > 0 && (
              <>
                <div className="dpUploadAction">
                  <button className="cancel" onClick={removeImage}><LiaTimesSolid /></button>
                  <button className="save" onClick={handleSave}><IoMdCheckmark /></button>
                </div>
              </>
            )}
          </div>
          <div className="author-content">
            <h5 className="author-name">
              {data?.title}
            </h5>
          </div>
        </div>
        <Modal centered size="md" show={cropModal} onHide={() => setCropModal(false)}>
          <Modal.Body>
            <Crop image={cover[0]} imagePass={setCropedImage} manageCropModal={setCropModal} type={"profilePhoto"} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
  // for page
  else if (type === "page") {
    return (
      <>
        <div className="profile-author">
          <div className="profile-author-thumb" >

            {/* {load==100? 
    <img
              alt="author"
              src={base64Image.length > 0 ? base64Image : (data?.display_image?.length > 0) ? (data?.display_image[0]?.image_url) : (defaultUserDp)}
            /> 
            :"loading.."} */}
            <img
              alt="author"
              src={base64Image.length > 0 ? base64Image : (data?.display_image?.length > 0) ? (data?.display_image[0]?.image_url) : (defaultUserDp)}
            />
            {data?.user?._id === userSliceData._id && <div className="edit-dp">
              <label className="fileContainer">
                <FaCamera />
                <input type="file" onChange={handleProfilePhoto} />
              </label>
            </div>}
            {
              base64Image.length > 0 && (
                <>
                  <div className="dpUploadAction">
                    <button className="cancel" onClick={removeImage}><LiaTimesSolid /></button>
                    <button className="save" onClick={handleSave}><IoMdCheckmark /></button>
                  </div>
                </>
              )
            }
          </div>
          <div className="author-content">
            <h5 className="author-name">
              {data?.title}
            </h5>
            {/* <div className="country">{data?.description}</div> */}
          </div>
        </div>
        <Modal centered size="md" show={cropModal} onHide={() => setCropModal(false)}>
          <Modal.Body>
            <Crop image={cover[0]} imagePass={setCropedImage} manageCropModal={setCropModal} type={"profilePhoto"} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
  // for user-profile
  else {
    return (
      <>
        <div className="profile-author">
          <div className="profile-author-thumb">
            {
              userId === userSliceData._id ? (
                <img
                  src={base64Image.length > 0 ? base64Image : userSliceData?.profile_picture[0]?.image_url ? (userSliceData.profile_picture[0]?.image_url) : (defaultUserDp)}
                  alt="" style={{ height: "450px" }}
                />
              ) : (
                <img alt="author" src={userDetailsById?.profile_picture[0]?.image_url} />
              )
            }
            {
              userId === userSliceData._id && <div className="edit-dp">
                <Dropdown menu={{ items: DropdownLinks }} placement="bottomRight">
                  <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
                </Dropdown>
              </div>
            }
            {base64Image.length > 0 && (
              <>
                <div className="dpUploadAction">
                  <button className="cancel" onClick={removeImage}><LiaTimesSolid /></button>
                  <button className="save" onClick={handleSave}><IoMdCheckmark /></button>
                </div>
              </>
            )}
          </div>
          <div className="author-content">
            <h5 className="author-name">
              {userId === userSliceData._id ? userSliceData.fullName : userDetailsById?.fullName}
            </h5>
            <div className="country">
              {userId === userSliceData._id ? userSliceData.countries.country : userDetailsById?.countries.country}
            </div>
          </div>
        </div>
        {/* to crop the cover photo */}
        <Modal centered size="md" show={cropModal} onHide={() => setCropModal(false)}>
          <Modal.Body>
            <Crop image={cover[0]} imagePass={setCropedImage} manageCropModal={setCropModal} type={"profilePhoto"} />
          </Modal.Body>
        </Modal>
        {/* delete cover modal */}
        <Modal centered size="sm" show={deleteCoverModal} onHide={() => setDeleteCoverModal(false)}>
          <Modal.Body>
            <div className="text-center">
              <h5 className="mb-3">Are you sure, you want to Delete the profile photo?</h5>
              <div className="text-center">
                <Button variant="secondary" onClick={() => setDeleteCoverModal(false)} className="mx-2">
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteCover} className="mx-2">
                  Delete
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default DashboardUserInfo;
