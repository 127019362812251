import React from "react";
import {
   DashboardCoverPhoto,
   DashboardMenu,
   DashboardSettings,
   DashboardUserInfo
} from "../../components/components";
import DashboardAction from "../../components/dashboard/dashboardAction/DashboardAction";

const PageProfileLayout = ({ children, data }) => {
   const menuArr = [
      { title: "Timeline", url: `/page/${data?._id}/timeline` },
      { title: "About", url: `/page/${data?._id}/about` },
      { title: "Followers", url: `/page/${data?._id}/followers` },
      { title: "Photo & Video", url: `/page/${data?._id}/media` },
   ];

   return (
      <>
         <div className="gap2 gray-bg full_height">
            <div className="container">
               <div className="user-profile">
                  <figure>
                     <DashboardCoverPhoto data={data} type={"page"} />
                     {/* <DashboardSettings /> */}
                  </figure>
                  <div className="profile-section">
                     <div className="row">
                        <div className="col-lg-2 col-md-3">
                           <DashboardUserInfo data={data} type="page"/>
                        </div>
                        <div className="col-lg-10 col-md-9">
                           <div className="d-md-flex align-items-center">
                              <DashboardMenu links={menuArr} />
                              <DashboardAction data={data} type={'page'} className="ms-auto mt-0" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {children}
            </div>
         </div>
      </>
   );
};

export default PageProfileLayout;
