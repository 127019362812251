import React from "react";
import { Route, Routes } from "react-router";
import PrivateRoute from "./PrivateRoute";
import Homepage from "../pages/home/home";
import RegisterPage from "../pages/register/RegisterPage";
import LoginPage from "../pages/login/LoginPage";
import EmploymentPage from "../pages/employment/EmploymentPage";
import FaqPage from "../pages/Faq/Faq";
import Plans from "../pages/plans/Plans";
import Activity from "../pages/Activity/Activity";
import GeneralPage from "../pages/setting/general/GeneralPage";
// import NotificationPage from "../pages/setting/notification/NotificationPage";
import MessagePage from "../pages/setting/message/MessagePage";
import PrivacyPage from "../pages/setting/privacy/PrivacyPage";
import HelpSupport from "../pages/HelpSupport/HelpSupport";
import CreateGroup from "../pages/profile/groups/createGroup/CreateGroup";
import CreatePage from "../pages/profile/pages/createPage/CreatePage";
import TermsAndConditions from "../pages/termsAndConditions/TermsAndConditions";
import {
   Timeline as ProfileTimeline,
   About as ProfileAbout,
   Connections as ProfileConnections,
   NewConnections,
   SuggestedCon,
   Pages as ProfilePages,
   SuggestedPages as ProfileSuggestedPages,
   Groups as ProfileGroups,
   SuggestedGroups,
   Events as ProfileEvents,
   Images as ProfileImages,
   Videos as ProfileVideos,
   GenericDetails,
   UploadProfile,
   CommunicationDetails,
   AreaOfInterest,
   ShareToContact
} from "../pages/profile/AllPages";
import {
   Timeline as GroupTimeline,
   About as GroupAbout,
   Members as GroupMembers,
   Suggation as GroupSuggation,
   Events as GroupEvents,
   Videos as GroupVideos,
   Pending as GroupPending,
   Images as GroupImage,
   AllPicture as GroupAllPicture,
   CoverPicture as GroupCoverPicture,
   ProfilePicture as GroupProfilePicture
} from "../pages/group/GroupAllPages";
import {
   Followers as PageFollowers,
   Suggested as PageSuggestedFollowers,
   About as PageAbout,
   Timeline as PageTimeline,
   Videos as PageVideos,
   Pending as PagePending,
   Images as PageImage,
   AllPicture as PageAllPicture,
   CoverPicture as PageCoverPicture,
   ProfilePicture as PageProfilePicture
} from "../pages/page/PageAllPages";
import {
   Discussion as EventDiscussion,
   About as EventAbout,
   Guests as EventGuest,
   Suggation as EventSuggation,
   Create as CreateEvent,
   Pending as EventPending,
   Images as EventImages,
   AllPicture as EventAllPicture,
   CoverPicture as EventCoverPicture,
   Videos as EventVideo
} from "../pages/event/EventAllPages";

// AUTHENTICATION PAGES
import { ForgetPassword } from "../pages/auth/allAuthPages";
import Follower from "../pages/profile/followerFollowing/Follower";
import Following from "../pages/profile/followerFollowing/Following";
import SuggestedEvents from "../pages/profile/events/suggested/SuggestedEvents";
import PendingGroups from "../pages/profile/groups/pending/PendingGroups";
import PendingEvents from "../pages/profile/events/pending/PendingEvents";
import EditGroup from "../pages/profile/groups/edit/EditGroup";
import EditPage from "../pages/profile/pages/Edit/EditPage";
import ProfilePictures from "../pages/profile/media/Images/profilePicture";
import CoverPictures from "../pages/profile/media/Images/coverPicture";
import AllPictures from "../pages/profile/media/Images/allPicture";
import PendingPages from "../pages/profile/pages/pending/PendingPages";
import SavedPost from "../pages/SavedPost/SavedPost";
import EditEvent from "../pages/profile/events/edit/editEvent";
import JoinedGroups from "../pages/profile/groups/joined/JoinedGroups";
import JoinedPages from "../pages/profile/pages/joined/JoinedPages";
import JoinedEvents from "../pages/profile/events/joined/JoinedEvents";
import EmploymentCreate from "../pages/employment/EmploymentCreate";
import EmploymentEdit from "../pages/employment/EmploymentEdit";
import Community from "../pages/Community/Community";
import BlockedConnection from "../pages/BlockedConnection/BlockedConnection";
import EmploymentSaved from "../pages/employment/EmploymentSaved";
import EmploymentApply from "../pages/employment/EmploymentApply";
import EmploymentApplied from "../pages/employment/EmploymentApplied";
import EmploymentAppliedList from "../pages/employment/EmploymentAppliedList";
import NotFound from "../pages/notFound";
import PostDetails from "../pages/postDetails/postDetails";
import MarketPlace from "../pages/market/MarketPlace";
import EditProduct from "../pages/market/EditProduct";
import CreateProduct from "../pages/market/CreateProduct";
import ProductDetails from "../pages/market/ProductDetails";
import {
   Category,
   CategoryCreate,
   Edit as EditAdminCategory,
   Groups as AdminGroups,
   Users as AdminUsers,
   Pages as AdminPages,
   Events as AdminEvents,
   AdminPosts,
   Membership as AdminMembership,
   CreateMembership as AdminCreateMembership,
   EditMembership as AdminEditMembership,
   Reactions as AdminReactions,
   AddReactions as AdminAddReactions,
   EditReactions as AdminEditReactions,
   Reports as AdminReports,
   AddReports as AdminAddReports,
   EditReports as AdminEditReports,
   EmploymentList as AdminEmploymentLists,
   Quotes as AdminQuotes,
   Community as AdminCommunity,
   Permission as AdminPermission,
   EditPermission as AdminEditPermission,
   AddPermission as AdminAddPermission,
   Role as AdminRole,
   AddRole as AdminAddRole,
   EditRole as AdminEditRole,
} from "../pages/admin/adminPages";
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage";
import MyProducts from "../pages/market/MyProducts";
import EmplymentsMyJobs from "../pages/employment/EmplymentsMyJobs";
import AdminMarketplaceLists from "../pages/admin/marketplace/AdminMarketplaceLists";
import ChatPage from "../pages/chat/ChatPage";
import SetPasswordPage from "../pages/SetPasswordPage/SetPasswordPage";
import UpdatePasswordPage from "../pages/UpdatePasswordPage/UpdatePasswordPage";
import SavedProduct from "../pages/market/SavedProduct";
import Wallet from "../pages/wallet/Wallet";
import WalletHistory from "../pages/wallet/History/WalletHistory";
import Payment from "../pages/wallet/Payment/Payment";
import SearchPage from "../pages/Search/SearchPage";
import MyOrders from "../pages/market/MyOrders";
import MySellOrders from "../pages/market/MySellOrders";
import CreateBroadcast from "../pages/Broadcast/CreateBroadcast";
import AdminNotification from "../pages/admin/notification/AdminNotification";
import PostNotification from "../pages/admin/notification/PostNotification";
import GroupNotification from "../pages/admin/notification/GroupNotification";
import PageNotification from "../pages/admin/notification/PageNotification";
import AdminNotificationCreate from "../pages/admin/notification/CreateNotification";
import AdminNotificationEdit from "../pages/admin/notification/EditNotification";
import ChatNotification from "../pages/admin/notification/ChatNotification";
import UserNotification from "../pages/admin/notification/UserNotification";
import CommunityNotification from "../pages/admin/notification/CommunityNotification";
import PostCommentNotification from "../pages/admin/notification/PostCommentNotification";
import EventNotification from "../pages/admin/notification/EventNotification";
import NotificationPage from "../pages/Notification/NotificationPage";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import SystemUsers from "../pages/admin/systemUsers/SystemUsers";
import SystemUserEdit from "../pages/admin/systemUsers/SystemUserEdit";
import SystemUserAdd from "../pages/admin/systemUsers/SystemUserAdd";
import ReportedPost from "../pages/admin/post/ReportedPost";
import AdminUserLogs from "../pages/admin/logs/AdminUserLogs";

const AllRoutes = () => {
   return (
      <Routes>
         <Route path='*' element={<NotFound />} />
         <Route path="/login" element={<LoginPage />} />
         <Route path="/register" element={<RegisterPage />} />
         <Route path="/forgot" element={<ForgetPassword />} />
         <Route path="/reset-password" element={<ResetPasswordPage />} />
         <Route path="/set-password" element={<SetPasswordPage />} />
         <Route path="/update-password" element={<UpdatePasswordPage />} />
         <Route path="/" element={<PrivateRoute><Homepage /></PrivateRoute>} />
         <Route path="/help" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
         <Route path="/faq" element={<PrivateRoute><FaqPage /></PrivateRoute>} />
         <Route path="/plans" element={<PrivateRoute><Plans /></PrivateRoute>} />
         <Route path="/activity" element={<PrivateRoute><Activity /></PrivateRoute>} />
         <Route path="/terms" element={<TermsAndConditions />} />
         <Route path="/savedPost" element={<PrivateRoute><SavedPost /></PrivateRoute>} />
         <Route path="/community" element={<PrivateRoute><Community /></PrivateRoute>} />
         <Route path="/post/:id" element={<PostDetails />} />
         <Route path="/chat" element={<PrivateRoute><ChatPage /></PrivateRoute>} />
         <Route path="/search" element={<PrivateRoute><SearchPage /></PrivateRoute>} />

         {/* broadcast */}
         {/* <Route path="/broadcast">
            <Route path="create" element={<PrivateRoute><CreateBroadcast /></PrivateRoute>} />
         </Route> */}

         {/* wallet */}
         <Route path="/wallet">
            <Route path="" element={<PrivateRoute><Wallet /></PrivateRoute>} />
            <Route path="history" element={<PrivateRoute><WalletHistory /></PrivateRoute>} />
            <Route path="payment" element={<PrivateRoute><Payment /></PrivateRoute>} />
         </Route>

         {/* marketplace */}
         <Route path="/marketplace">
            <Route path="" element={<MarketPlace />} />
            <Route path="create" element={<CreateProduct />} />
            <Route path="myproduct" element={<MyProducts />} />
            <Route path="myorders" element={<MyOrders />} />
            <Route path="myorders/sell" element={<MySellOrders />} />
            <Route path=":productId/edit" element={<EditProduct />} />
            <Route path=":productId" element={<ProductDetails />} />
            <Route path="saved" element={<SavedProduct />} />
         </Route>

         {/* event */}
         <Route path="/event">
            <Route path=":eventId/edit" element={<PrivateRoute><EditEvent /></PrivateRoute>} />
            <Route path=":eventId/discussion" element={<PrivateRoute><EventDiscussion /></PrivateRoute>} />
            <Route path=":eventId/about" element={<PrivateRoute><EventAbout /></PrivateRoute>} />
            <Route path=":eventId/guests" element={<PrivateRoute><EventGuest /></PrivateRoute>} />
            <Route path=":eventId/guests/suggation" element={<PrivateRoute><EventSuggation /></PrivateRoute>} />
            <Route path=":eventId/guests/pending" element={<PrivateRoute><EventPending /></PrivateRoute>} />
            <Route path=":eventId/media" element={<PrivateRoute><EventImages /></PrivateRoute>} />
            <Route path=":eventId/media/cover_picture" element={<PrivateRoute><EventCoverPicture /></PrivateRoute>} />
            <Route path=":eventId/media/all_picture" element={<PrivateRoute><EventAllPicture /></PrivateRoute>} />
            <Route path=":eventId/media/video" element={<PrivateRoute><EventVideo /></PrivateRoute>} />
         </Route>

         {/* profile */}
         <Route path="/profile">
            <Route path=":userId/timeline" element={<PrivateRoute><ProfileTimeline /></PrivateRoute>} />
            <Route path=":userId/about" element={<PrivateRoute><ProfileAbout /></PrivateRoute>} />
            {/* connections */}
            <Route path=":userId/connections" element={<PrivateRoute><ProfileConnections /></PrivateRoute>} />
            <Route path=":userId/connections/new" element={<PrivateRoute><NewConnections /></PrivateRoute>} />
            <Route path=":userId/connections/suggested" element={<PrivateRoute><SuggestedCon /></PrivateRoute>} />
            <Route path="blockedConnection" element={<PrivateRoute><BlockedConnection /></PrivateRoute>} />
            {/* pages */}
            <Route path="pages/create" element={<PrivateRoute><CreatePage /></PrivateRoute>} />
            <Route path=":userId/pages" element={<PrivateRoute><ProfilePages /></PrivateRoute>} />
            <Route path=":userId/pages/joined" element={<PrivateRoute><JoinedPages /></PrivateRoute>} />
            <Route path=":userId/pages/pending" element={<PrivateRoute><PendingPages /></PrivateRoute>} />
            <Route path=":userId/pages/suggested" element={<PrivateRoute><ProfileSuggestedPages /></PrivateRoute>} />
            {/* groups */}
            <Route path="groups/create" element={<PrivateRoute><CreateGroup /></PrivateRoute>} />
            <Route path=":userId/groups" element={<PrivateRoute><ProfileGroups /></PrivateRoute>} />
            <Route path=":userId/groups/joined" element={<PrivateRoute><JoinedGroups /></PrivateRoute>} />
            <Route path=":userId/groups/pending" element={<PrivateRoute><PendingGroups /></PrivateRoute>} />
            <Route path=":userId/groups/suggested" element={<PrivateRoute><SuggestedGroups /></PrivateRoute>} />
            {/* events */}
            <Route path="event/create" element={<PrivateRoute><CreateEvent /></PrivateRoute>} />
            <Route path=":userId/events" element={<PrivateRoute><ProfileEvents /></PrivateRoute>} />
            <Route path=":userId/events/joined" element={<PrivateRoute><JoinedEvents /></PrivateRoute>} />
            <Route path=":userId/events/pending" element={<PrivateRoute><PendingEvents /></PrivateRoute>} />
            <Route path=":userId/events/suggested" element={<PrivateRoute><SuggestedEvents /></PrivateRoute>} />
            {/* medias */}
            <Route path=":userId/media" element={<PrivateRoute><ProfileImages /></PrivateRoute>} />
            <Route path=":userId/media/profile_picture" element={<PrivateRoute><ProfilePictures /></PrivateRoute>} />
            <Route path=":userId/media/cover_picture" element={<PrivateRoute><CoverPictures /></PrivateRoute>} />
            <Route path=":userId/media/all_picture" element={<PrivateRoute><AllPictures /></PrivateRoute>} />
            <Route path=":userId/media/video" element={<PrivateRoute><ProfileVideos /></PrivateRoute>} />
            {/* follower-following */}
            <Route path=":userId/follower" element={<PrivateRoute><Follower /></PrivateRoute>} />
            <Route path=":userId/following" element={<PrivateRoute><Following /></PrivateRoute>} />
            {/* profile complitation */}
            <Route path="generic-details" element={<PrivateRoute><GenericDetails /></PrivateRoute>} />
            <Route path="upload-profile" element={<PrivateRoute><UploadProfile /></PrivateRoute>} />
            <Route path="communication-details" element={<PrivateRoute><CommunicationDetails /></PrivateRoute>} />
            <Route path="area-of-interest" element={<PrivateRoute><AreaOfInterest /></PrivateRoute>} />
            <Route path="update-password" element={<PrivateRoute><ShareToContact /></PrivateRoute>} />
         </Route>

         {/* group */}
         <Route path="/group">
            <Route path=":groupId" element={<PrivateRoute><GroupTimeline /></PrivateRoute>} />
            <Route path=":groupId/edit" element={<PrivateRoute><EditGroup /></PrivateRoute>} />
            <Route path=":groupId/timeline" element={<PrivateRoute><GroupTimeline /></PrivateRoute>} />
            <Route path=":groupId/about" element={<PrivateRoute><GroupAbout /></PrivateRoute>} />
            <Route path=":groupId/members" element={<PrivateRoute><GroupMembers /></PrivateRoute>} />
            <Route path=":groupId/members/suggation" element={<PrivateRoute><GroupSuggation /></PrivateRoute>} />
            <Route path=":groupId/members/pending" element={<PrivateRoute><GroupPending /></PrivateRoute>} />
            <Route path=":groupId/events" element={<PrivateRoute><GroupEvents /></PrivateRoute>} />
            <Route path=":groupId/media" element={<PrivateRoute><GroupImage /></PrivateRoute>} />
            <Route path=":groupId/media/profile_picture" element={<PrivateRoute><GroupProfilePicture /></PrivateRoute>} />
            <Route path=":groupId/media/cover_picture" element={<PrivateRoute><GroupCoverPicture /></PrivateRoute>} />
            <Route path=":groupId/media/all_picture" element={<PrivateRoute><GroupAllPicture /></PrivateRoute>} />
            <Route path=":groupId/media/video" element={<PrivateRoute><GroupVideos /></PrivateRoute>} />
         </Route>

         {/* page */}
         <Route path="/page">
            <Route path=":pageId" element={<PrivateRoute><PageTimeline /></PrivateRoute>} />
            <Route path=":pageId/edit" element={<PrivateRoute><EditPage /></PrivateRoute>} />
            <Route path=":pageId/timeline" element={<PrivateRoute><PageTimeline /></PrivateRoute>} />
            <Route path=":pageId/about" element={<PrivateRoute><PageAbout /></PrivateRoute>} />
            <Route path=":pageId/followers" element={<PrivateRoute><PageFollowers /></PrivateRoute>} />
            <Route path=":pageId/followers/suggation" element={<PrivateRoute><PageSuggestedFollowers /></PrivateRoute>} />
            <Route path=":pageId/followers/pending" element={<PrivateRoute><PagePending /></PrivateRoute>} />
            <Route path=":pageId/media" element={<PrivateRoute><PageImage /></PrivateRoute>} />
            <Route path=":pageId/media/profile_picture" element={<PrivateRoute><PageProfilePicture /></PrivateRoute>} />
            <Route path=":pageId/media/cover_picture" element={<PrivateRoute><PageCoverPicture /></PrivateRoute>} />
            <Route path=":pageId/media/all_picture" element={<PrivateRoute><PageAllPicture /></PrivateRoute>} />
            <Route path=":pageId/media/video" element={<PrivateRoute><PageVideos /></PrivateRoute>} />
         </Route>

         {/* employment */}
         <Route path="/employment">
            <Route path="" element={<PrivateRoute><EmploymentPage /></PrivateRoute>} />
            <Route path="/employment/saved" element={<PrivateRoute><EmploymentSaved /></PrivateRoute>} />
            <Route path="/employment/create" element={<PrivateRoute><EmploymentCreate /></PrivateRoute>} />
            <Route path="/employment/applied" element={<PrivateRoute><EmploymentApplied /></PrivateRoute>} />
            <Route path="/employment/:employmentId/edit" element={<PrivateRoute><EmploymentEdit /></PrivateRoute>} />
            <Route path="/employment/:employmentId/apply" element={<PrivateRoute><EmploymentApply /></PrivateRoute>} />
            <Route path="/employment/:employmentId/applied-list" element={<PrivateRoute><EmploymentAppliedList /></PrivateRoute>} />
            <Route path="/employment/my-jobs" element={<PrivateRoute><EmplymentsMyJobs /></PrivateRoute>} />
         </Route>

         {/* setting routes */}
         {/* <Route path="/setting">
            <Route path="" element={<PrivateRoute><GeneralPage /></PrivateRoute>} />
            <Route path="edit" element={<PrivateRoute><EditPage /></PrivateRoute>} />
            <Route path="notification" element={<PrivateRoute><NotificationPage /></PrivateRoute>} />
            <Route path="message" element={<PrivateRoute><MessagePage /></PrivateRoute>} />
            <Route path="privacy" element={<PrivateRoute><PrivacyPage /></PrivateRoute>} />
         </Route> */}

         <Route path="/notifications" element={<PrivateRoute><NotificationPage /></PrivateRoute>} />

         {/* admin */}
         <Route path="/admin">
            <Route path="" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="users" element={<PrivateRoute><AdminUsers /></PrivateRoute>} />
            <Route path="users/:userId/logs" element={<PrivateRoute><AdminUserLogs /></PrivateRoute>} />
            <Route path="groups" element={<PrivateRoute><AdminGroups /></PrivateRoute>} />
            <Route path="pages" element={<PrivateRoute><AdminPages /></PrivateRoute>} />
            <Route path="events" element={<PrivateRoute><AdminEvents /></PrivateRoute>} />
            <Route path="posts" element={<PrivateRoute><AdminPosts /></PrivateRoute>} />
            <Route path="employments" element={<PrivateRoute><AdminEmploymentLists /></PrivateRoute>} />
            <Route path="marketplace" element={<PrivateRoute><AdminMarketplaceLists /></PrivateRoute>} />

            <Route path="category" element={<PrivateRoute><Category /></PrivateRoute>} />
            <Route path="category/create" element={<PrivateRoute><CategoryCreate /></PrivateRoute>} />
            <Route path="category/edit/:id" element={<PrivateRoute><EditAdminCategory /></PrivateRoute>} />

            <Route path="plans" element={<PrivateRoute><AdminMembership /></PrivateRoute>} />
            <Route path="plans/create" element={<PrivateRoute><AdminCreateMembership /></PrivateRoute>} />
            <Route path="plans/edit/:id" element={<PrivateRoute><AdminEditMembership /></PrivateRoute>} />

            <Route path="reactions" element={<PrivateRoute><AdminReactions /></PrivateRoute>} />
            <Route path="reactions/create" element={<PrivateRoute><AdminAddReactions /></PrivateRoute>} />
            <Route path="reactions/edit/:reactionId" element={<PrivateRoute><AdminEditReactions /></PrivateRoute>} />

            <Route path="report" element={<PrivateRoute><AdminReports /></PrivateRoute>} />
            <Route path="report/create" element={<PrivateRoute><AdminAddReports /></PrivateRoute>} />
            <Route path="report/edit/:reportId" element={<PrivateRoute><AdminEditReports /></PrivateRoute>} />
            <Route path="report/:reportId/post" element={<PrivateRoute><ReportedPost /></PrivateRoute>} />

            <Route path="roles-permission/permission" element={<PrivateRoute><AdminPermission /></PrivateRoute>} />
            <Route path="roles-permission/permission/create" element={<PrivateRoute><AdminAddPermission /></PrivateRoute>} />
            <Route path="roles-permission/permission/edit/:permissionId" element={<PrivateRoute><AdminEditPermission /></PrivateRoute>} />

            <Route path="roles-permission/role" element={<PrivateRoute><AdminRole /></PrivateRoute>} />
            <Route path="roles-permission/role/create" element={<PrivateRoute><AdminAddRole /></PrivateRoute>} />
            <Route path="roles-permission/role/edit/:roleId" element={<PrivateRoute><AdminEditRole /></PrivateRoute>} />

            <Route path="roles-permission/system-users" element={<PrivateRoute><SystemUsers /></PrivateRoute>} />
            <Route path="roles-permission/system-users/create" element={<PrivateRoute><SystemUserAdd /></PrivateRoute>} />
            <Route path="roles-permission/system-users/edit/:userId" element={<PrivateRoute><SystemUserEdit /></PrivateRoute>} />

            <Route path="quotes" element={<PrivateRoute><AdminQuotes /></PrivateRoute>} />
            <Route path="community" element={<PrivateRoute><AdminCommunity /></PrivateRoute>} />

            {/* admin notification */}
            <Route path="notification" element={<PrivateRoute><AdminNotification /></PrivateRoute>} />
            <Route path="notification/post" element={<PrivateRoute><PostNotification /></PrivateRoute>} />
            <Route path="notification/page" element={<PrivateRoute><PageNotification /></PrivateRoute>} />
            <Route path="notification/group" element={<PrivateRoute><GroupNotification /></PrivateRoute>} />
            <Route path="notification/event" element={<PrivateRoute><EventNotification /></PrivateRoute>} />
            <Route path="notification/post-comment" element={<PrivateRoute><PostCommentNotification /></PrivateRoute>} />
            <Route path="notification/community" element={<PrivateRoute><CommunityNotification /></PrivateRoute>} />
            <Route path="notification/user" element={<PrivateRoute><UserNotification /></PrivateRoute>} />
            <Route path="notification/chat" element={<PrivateRoute><ChatNotification /></PrivateRoute>} />
            <Route path="notification/create" element={<PrivateRoute><AdminNotificationCreate /></PrivateRoute>} />
            <Route path="notification/edit/:id" element={<PrivateRoute><AdminNotificationEdit /></PrivateRoute>} />
         </Route>
      </Routes>
   );
};

export default AllRoutes;
