import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const savePostApi = createApi({
    reducerPath: "savePostApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["SavePost"],
    endpoints: (builder) => ({
        // GET SAVE POST LIST
        getSavePostList: builder.query({
            query: () => {
                return {
                    url: `api/posts/savepost`,
                    method: "GET",
                    params: {
                        page: 1,
                        perPage: 10
                    }
                }
            },
            providesTags: ["SavePost"],
        }),
        // SAVE A POST
        saveAPost: builder.mutation({
            query: (postId) => {
                return {
                    url: `api/posts/savepost`,
                    method: "POST",
                    body: {
                        "post_id": postId
                    }
                }
            },
            invalidatesTags: ["SavePost"],
        }),
        // DELETE A SAVED POST
        deleteSavedPost: builder.mutation({
            query: (postId) => {
                return {
                    url: `api/posts/savepost`,
                    method: "DELETE",
                    body: {
                        "post_id": postId
                    }
                };
            },
            invalidatesTags: ["SavePost"],
        }),
    })
})


export const { useGetSavePostListQuery, useSaveAPostMutation, useDeleteSavedPostMutation } = savePostApi;