import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./activity.css";
import { useSelector } from "react-redux";
import { useGetRecentActivityQuery } from "../../store/api/activityApi";
import EpochTimeToDateTime from "../../methods/EpochTimeToDateTime";
import { DotLoading } from "../../components/components";
const Activity = () => {
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });
   const [currPage, setCurrPage] = useState(1);
   const [lastPage, setLastPage] = useState(1);
   const widgets = useGetRecentActivityQuery({ id: userSliceData._id, page: currPage })
   let widgetData = widgets.data?.data;

   const handleInfiniteScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if ((scrollTop + clientHeight) === scrollHeight) {
         // This will be triggered after hitting the last element.
         // API call here while implementing pagination.
         if (lastPage !== currPage) {
            setCurrPage((prev) => {
               return prev + 1
            })
         }
      }
   }
   useEffect(() => {
      if (widgets.status === "fulfilled") {
         setLastPage(widgets?.data.last_page)
         window.addEventListener('scroll', handleInfiniteScroll);
         return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
         };
      }
   }, [setLastPage, handleInfiniteScroll])

   if (widgets.isLoading) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Loading...</h4>
            </div>
         </>
      );
   }

   if (widgets.isError) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Someting Went Wrong!!</h4>
            </div>
         </>
      );
   }

   return (
      <div className="gap2 gray-bg full_height">
         <div className="container">
            <div className="row merged20 justify-content-center">
               {/* <!-- user profile banner  --> */}
               <div className="col-lg-6 col-md-10">
                  <div className="activity-page-top">
                     <Link to="/profile">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="24"
                           height="24"
                           viewBox="0 0 24 24"
                           fill="none"
                           stroke="currentColor"
                           strokeWidth="2"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           className="feather feather-arrow-left"
                        >
                           <line x1="19" y1="12" x2="5" y2="12"></line>
                           <polyline points="12 19 5 12 12 5"></polyline>
                        </svg>
                     </Link>
                     <div className="activity-page-title">
                        <h5>Your recent activities</h5>
                        <p>
                           See your recent activitirs in new way
                        </p>
                     </div>
                  </div>

                  {widgets?.data?.data?.length > 0 ? (
                     <>
                        <ul className="activity-ul">
                           {widgetData?.map((el, i) => (
                              <li key={i} className="activity-box">
                                 <span className="date">on {EpochTimeToDateTime(el.createdAt).date} at {EpochTimeToDateTime(el.createdAt).time}</span>
                                 <div className="activity-content">
                                    <h6>
                                       <span>{el.logs.title} </span>
                                    </h6>
                                 </div>
                              </li>
                           ))}
                        </ul>
                        <div className="d-flex justify-content-center">
                           {lastPage !== currPage ? <DotLoading /> : 'No more activities avialable!!!'}
                        </div>
                     </>
                  ) : (
                     <h6 className='text-center py-5 mb-0 mt-5'>No Activity Found !!</h6>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Activity;
