import React, { useState, useEffect } from 'react';
import './menu.css';
import { BsFileEarmarkMedical, BsFillCaretDownFill } from "react-icons/bs";
import MenuItem from './MenuItem';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AdminMenu = ({ className, open }) => {
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    const navigate = useNavigate()
    const [toggleMenu, setToggleMenu] = useState(open !== undefined ? true : false)
    const permissionList = useUserPermissionListQuery();
    const menuItems = [
        {
            title: 'Roles & Permission',
            icon: <BsFileEarmarkMedical />,
            children: [
                {
                    title: 'Permission',
                    link: '/admin/roles-permission/permission',
                    permission: "permission_list"
                },
                {
                    title: 'Role',
                    link: '/admin/roles-permission/role',
                    permission: "role_list"
                },
                {
                    title: 'System Users',
                    link: '/admin/roles-permission/system-users',
                    permission: ""
                }
            ],
            permissions: ["permission_list", "permission_edit", "permission_delete", "permission_create", "role_list", "role_create", "role_edit", "role_delete"]
        },
        {
            title: 'Users',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/users',
            permissions: ["user_list", "user_block"]
        },
        {
            title: 'Reactions',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/reactions',
            permissions: ["reaction_list", "reaction_edit", "reaction_active", "reaction_delete", "reaction_create"]
        },
        {
            title: 'Posts',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/posts',
            permissions: ["post_list", "post_delete"]
        },
        {
            title: 'Plans',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/plans',
            permissions: ["plan_list", "plan_create", "plan_edit", "plan_active", "plan_delete"]
        },
        {
            title: 'Category',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/category',
            permissions: ["category_list", "category_create", "category_edit", "category_active"]
        },
        {
            title: 'Report',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/report',
            permissions: ["report_list", "report_create", "report_edit", "report_active", "report_delete"]
        },
        {
            title: 'Groups',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/groups',
            permissions: ["group_list", "group_active"]
        },
        {
            title: 'Pages',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/pages',
            permissions: ["pages_list", "pages_active"]
        },
        {
            title: 'Events',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/events',
            permissions: ["event_list", "event_active"]
        },
        {
            title: 'Employment',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/employments',
            permissions: ["employment_list", "employment_active", "employment_delete"]
        },
        {
            title: 'Marketplace',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/marketplace',
            permissions: ["marketplace_list", "marketplace_active"]
        },
        {
            title: 'Quotes',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/quotes',
            permissions: ["quotes_list", "quotes_approve"]
        },
        {
            title: 'Community',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/community',
            permissions: ["community_list", "community_delete"]
        },
        {
            title: 'Notification',
            icon: <BsFileEarmarkMedical />,
            link: '/admin/notification',
            children: [
                {
                    title: 'Post',
                    link: '/admin/notification/post',
                    permission: ""
                },
                {
                    title: 'Page',
                    link: '/admin/notification/page',
                    permission: ""
                },
                {
                    title: 'Group',
                    link: '/admin/notification/group',
                    permission: ""
                },
                {
                    title: 'Event',
                    link: '/admin/notification/event',
                    permission: ""
                },
                {
                    title: 'Post Comment',
                    link: '/admin/notification/post-comment',
                    permission: ""
                },
                {
                    title: 'Community',
                    link: '/admin/notification/community',
                    permission: ""
                },
                {
                    title: 'User',
                    link: '/admin/notification/user',
                    permission: ""
                },
                {
                    title: 'Chat',
                    link: '/admin/notification/chat',
                    permission: ""
                },
            ],
        },
    ]

    const user_permission_arr = permissionList?.data?.permissions;
    if (permissionList.isSuccess === true) {
        var filteredData = menuItems.filter(item => {
            return item?.permissions?.some(permission => user_permission_arr?.includes(permission));
        }).map(item => {
            if (item.children) {
                item.children = item?.children?.filter(child => user_permission_arr?.includes(child.permission));
            }
            return item;
        });
    }

    return (
        <div className={className ? `admin_menu mb-3 ${className}` : `admin_menu mb-3`}>
            <div className={toggleMenu ? 'toggle_button active' : 'toggle_button'} onClick={() => { setToggleMenu(!toggleMenu); navigate("/admin") }}>
                Admin Menu <BsFillCaretDownFill />
            </div>
            {toggleMenu && (
                <ul>
                    {userInfo.user_type === 3 ? filteredData?.map((menu, i) => {
                        return (
                            <li key={i}>
                                <MenuItem data={menu} />
                            </li>
                        )
                    }) : menuItems?.map((menu, i) => {
                        return (
                            <li key={i}>
                                <MenuItem data={menu} />
                            </li>
                        )
                    })}
                </ul>
            )}
        </div>
    )
}

export default AdminMenu