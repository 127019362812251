import React from "react";
import { Link } from "react-router-dom";
import "./supportCard.css";

const SupportCard = () => {
   return (
      <div className="comon-help helpCommonHelp">
         <span>
            <i className="fa fa-question"></i>
         </span>
         <h4>Using</h4>
         <p>Learn the basics to help you make the most of valuable</p>
         <Link className="main-btn2" href="#" title="" data-ripple="">
            Read More
         </Link>
      </div>
   );
};

export default SupportCard;
