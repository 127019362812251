import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppliedUserListQuery } from '../../store/api/jobApi'
import { Button } from 'react-bootstrap'

const EmploymentAppliedList = () => {
    const { employmentId } = useParams()
    const AppliedUser = useAppliedUserListQuery(employmentId);
    const AppliedUserList = AppliedUser?.data?.appliedUsers;
    
    return (
        <div className="gap2 gray-bg">
            <div className="container">
                <div className="row">
                    <table className='adminTable col-md-10'>
                        <thead>
                            <tr>
                                <th><div className='table_head_info'>First Name</div></th>
                                <th><div className='table_head_info'>Last Name</div></th>
                                <th><div className='table_head_info'>Email</div></th>
                                <th><div className='table_head_info'>Current CTC</div></th>
                                <th><div className='table_head_info'>Expected CTC</div></th>
                                <th><div className='table_head_info'>Account Link</div></th>
                                <th><div className='table_head_info'>Resume</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                AppliedUserList?.length > 0 ? (
                                    AppliedUserList?.map((el, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className='table_info'>{el.first_name}</div>
                                                </td>
                                                <td>
                                                    <div className='table_info'>{el.last_name}</div>
                                                </td>
                                                <td>
                                                    <div className='table_info'>{el.email}</div>
                                                </td>
                                                <td>
                                                    <div className='table_info'>{el.current_ctc}</div>
                                                </td>
                                                <td>
                                                    <div className='table_info'>{el.expected_ctc}</div>
                                                </td>
                                                <td>
                                                    <Link to={`/profile/${el?.user?._id}/timeline`}>{el.user.fullName}</Link>
                                                </td>
                                                <td>
                                                    <div className='table_info'>
                                                        <Button href={el.cv_file[0].image_url}>Download</Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className='noData'>
                                        <td colSpan={8}>
                                            <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default EmploymentAppliedList
