import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  //get token value from state
  const token = useSelector((store) => {
    return store.userSlice.userAuthToken;
  });
  
  const authenticate = token.length > 0 ? true : false;

  if (!authenticate) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
