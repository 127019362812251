import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminReportsApi = createApi({
    reducerPath: "adminReport",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminReport"],
    endpoints: (builder) => ({
        getReportList: builder.query({
            query: () => {
                return {
                    url: "api/admin/reportReason/list",
                    method: "GET",
                }
            },
            providesTags: ["AdminReport"],
        }),
        createReport: builder.mutation({
            query: (data) => {
                return {
                    url: "api/admin/reportReason/create",
                    method: "POST",
                    body: {
                        reason_text: data.title
                    },
                }
            },
            invalidatesTags: ["AdminReport"],
        }),
        updateReport: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/reportReason/update`,
                    method: "PUT",
                    body: {
                        report_reason_id: data.id,
                        reason_text: data.title
                    }
                };
            },
            invalidatesTags: ["AdminReport"],
        }),
        changeReportStatus: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/reportReason/change-status`,
                    method: "PUT",
                    body: {
                        report_reason_id: data.id,
                        status: data.status
                    }
                };
            },
            invalidatesTags: ['AdminReport']
        }),
        deleteReport: builder.mutation({
            query: (id) => {
                return {
                    url: `api/admin/reportReason/delete`,
                    method: "DELETE",
                    body: {
                        report_reason_id: id
                    }
                };
            },
            invalidatesTags: ["AdminReport"],
        }),
        reportDetails: builder.query({
            query: (id) => {
                return {
                    url: "api/admin/reportReason/details",
                    method: "GET",
                    params: {
                        report_reason_id: id
                    },
                }
            },
            providesTags: ["AdminReport"],
        }),
        getReportListAgainstId: builder.query({
            query: (id) => {
                return {
                    url: "api/admin/report/meta/lists",
                    method: "GET",
                    params: {
                        meta_data: id
                    }
                }
            },
            providesTags: ["AdminReport"],
        }),
    }),
});

export const { useCreateReportMutation, useGetReportListQuery, useDeleteReportMutation, useChangeReportStatusMutation, useUpdateReportMutation, useReportDetailsQuery, useGetReportListAgainstIdQuery } = adminReportsApi;