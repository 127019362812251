import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";


const GoBackWrapper = ({ children }) => {
  const { userAuthToken } = useSelector((store) => {
    return store.userSlice;
  });
  const navigate = useNavigate();

  useEffect(() => {
    //if user login then redirect to previous page
    if (userAuthToken) {
      let prevRoute = () => {
        navigate(-1)
      }
      prevRoute()
    }
  }, [])
  return (
    <div>
      {children}
    </div>
  )
}

export default GoBackWrapper