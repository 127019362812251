import React from "react";
import GroupProfileLayout from "../EventProfileLayout";
import { DashboardAbout } from "../../../components/components";
import { useParams } from "react-router-dom";
import { useGetSingleEventQuery } from "../../../store/api/eventApi";

const About = () => {
   // get id from param
   const params = useParams();
   const id = params.eventId;
   // get single Event Data
   const singleEvent = useGetSingleEventQuery(id);
   let eventWholeData = singleEvent?.data?.data;
   return (
      <GroupProfileLayout data={eventWholeData}>
         <DashboardAbout data={eventWholeData} type={"Event"}/>
      </GroupProfileLayout>
   );
};

export default About;
