import React, { useEffect, useState } from 'react';
import './ChatRightTop.css';
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { socket } from '../../../../methods/Socket';

const RightTopChat = ({ userData, data }) => {
    // get room from global storage
    const { roomData } = useSelector((store) => {
        return store.chat;
    });
    // filter user who are online
    let filteredUserData = data?.filter(item => item?.chatRoom?._id === roomData)[0]

    // to identify which users are online
    const [onlineUsers, setOnlineUsers] = useState([])
    useEffect(() => {
        socket.on("allUserOnline", (data) => {
            setOnlineUsers(data);
        });
    }, []);

    if (userData.fullName) {
        return (
            <div className="chat-header shadow-sm py-4 py-lg-2 px-4">
                <div className="row align-items-center">
                    <div className="col-2 d-xl-none">
                        <Link className="icon icon-lg text-muted" to="#" data-toggle-chat="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                        </Link>
                    </div>

                    <div className="col-8 col-xl-12">
                        <div className="row align-items-center text-center text-xl-start">
                            <div className="col-12">
                                <div className="row align-items-center gx-3 justify-content-between">
                                    <div className="col-auto">
                                        <div className="avatar d-none d-xl-inline-block">
                                            <img className="avatar-img" src={userData?.profile_picture[0]?.image_url} alt="..." />
                                        </div>
                                    </div>

                                    <div className="col col_mid overflow-hidden">
                                        <h3 className="font-weight-bold fs-6 lh-sm mb-0 text-truncate">{userData?.fullName}</h3>
                                        <p className="text-truncate mb-0 text-muted">{onlineUsers.includes(filteredUserData?.opponents[0]?._id) ? "Online" : "Offline"}</p>
                                    </div>

                                    <div className="col-auto">
                                        <div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic" className='ChatHeaderToggle'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                                    {/* <IoIosArrowDropdown fontSize={"20px"} /> */}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item >
                                                        Delete conversassion
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Mobile: more --> */}
                    <div className="col-2 d-xl-none text-end">
                        <div className="dropdown">
                            <Link className="text-muted" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="icon icon-lg">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                </div>
                            </Link>

                            <ul className="dropdown-menu">
                                <li>
                                    <Link className="dropdown-item" to="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-add-members" aria-controls="offcanvas-add-members">Add members</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-more-group" aria-controls="offcanvas-more-group">More</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- Mobile: more --> */}
                </div>
            </div>
        )
    }

}

export default RightTopChat