import React, { useEffect, useState } from 'react'
import ProfileLayout from '../../ProfileLayout';
import { DashboardMenuLink, EventCard, FilterWrapper } from '../../../../components/components';
import { useAllCountEventListsQuery } from '../../../../store/api/eventApi';
import { useGetPendingInvitationListQuery } from '../../../../store/api/eventApi';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';

const PendingEvents = () => {
    const { userId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [sort, setSort] = useState(searchParams.get("sort") || "see_all")
    let countData = useAllCountEventListsQuery()

    const paginationPageChange = (page) => {
        setPageNo(page);
    }

    const infoObj = {
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }

    useEffect(() => {
        let params = { pageNo };
        if (search) {
            params.search = search;
        }
        if (sort) {
            params.sort = sort;
        }

        setSearchParams(params);
    }, [pageNo, search, sort]);

    // pending events
    const pendingInvitation = useGetPendingInvitationListQuery(infoObj)
    const pendingInvitationList = pendingInvitation?.data?.data;


    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    return (
        <ProfileLayout handleDisplay="none">
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/events`} title="Events" count={countData.isSuccess ? countData?.data?.data?.total_events : 0} />
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/events/joined`} title="Joined Events" count={countData.isSuccess ? countData?.data?.data?.total_joined_events : 0} />
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/events/pending`} title="Pending Events" count={countData.isSuccess ? countData?.data?.data?.total_pending_events : 0} />
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/events/suggested`} title="Suggested Events" count={countData.isSuccess ? countData?.data?.data?.total_suggested_events : 0} />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="gx-2 justify-content-md-end row">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <form method="post">
                                    <input
                                        type="text"
                                        placeholder="Search.."
                                        className="form-control"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={searchParams.get("search") || search}
                                    />
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="select-options">
                                    <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                                        <option>See All</option>
                                        <option>A to Z</option>
                                        <option>Newest</option>
                                        <option>Oldest</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className='row gx-4'>
                    {
                        pendingInvitation.isLoading && (
                            <div className="col-12">
                                <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                            </div>
                        )
                    }
                    {
                        pendingInvitation.isError && (
                            <div className="col-12">
                                <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong !!</h5>
                            </div>
                        )
                    }
                    {pendingInvitationList?.map((elem, index) => {
                        return <div className='col-lg-3 col-md-4 col-6' key={index}>
                            <EventCard data={elem} type={"pending"} />
                        </div>
                    })}
                    {pendingInvitationList?.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">No Pending Event !!!</h5>
                    </div>}
                </div>
                {/* pagination component */}
                <div>
                    {userId === userSliceData._id && (
                        pendingInvitation?.data?.total_count > 8 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={pendingInvitation?.data?.total_count} onChange={paginationPageChange} defaultPageSize={8} />
                    )}
                </div>
            </div>
        </ProfileLayout>
    )
}

export default PendingEvents;