import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const albumApi = createApi({
    reducerPath: "albumApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["Album"],
    endpoints: (builder) => ({
        getAlbum: builder.query({
            query: (id) => {
                return {
                    url: "api/users/profile-details",
                    method: "GET",
                    params: {
                        page: 1,
                        per_page: 100,
                        module: "Album",
                        meta_type: "User",
                        user_id: id
                    }
                };
            },
            providesTags: ["Album"],
        }),

        // get album data for page
        getMyPageAlbumData: builder.query({
            query: (PAGEID) => {
                return {
                    url: "api/album",
                    method: "GET",
                    params: {
                        page: 1,
                        per_page: 100,
                        meta_type: "Page",
                        meta_data: PAGEID
                    }
                };
            },
            providesTags: ["Album"],
        }),

        // get album data for group
        getMyGroupAlbumData: builder.query({
            query: (GROUPID) => {
                return {
                    url: "api/album",
                    method: "GET",
                    params: {
                        page: 1,
                        per_page: 100,
                        meta_type: "Group",
                        meta_data: GROUPID
                    }
                };
            },
            providesTags: ["Album"],
        }),

        // get album data for group
        getMyEventAlbumData: builder.query({
            query: (EVENTID) => {
                return {
                    url: "api/album",
                    method: "GET",
                    params: {
                        page: 1,
                        per_page: 100,
                        meta_type: "Event",
                        meta_data: EVENTID
                    }
                };
            },
            providesTags: ["Album"],
        }),
    })
})


export const {
    useGetAlbumQuery,
    useGetMyPageAlbumDataQuery,
    useGetMyGroupAlbumDataQuery,
    useGetMyEventAlbumDataQuery
} = albumApi;