import React from 'react';
import { Link } from 'react-router-dom';
import "./index.css"
const NotFound = () => {
    return (
      <div className="notFoundPage gray-bg">
        <div className="gap2 full_height d-flex align-items-center">
          <div className='container '>
            <h1>404 <br/> Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <Link to={"/"} className='btn btn-primary'>Back to Home</Link>
          </div>
        </div>
      </div>
    );
};

export default NotFound;