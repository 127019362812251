import React from 'react'
import { Button } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { DashboardMenuLink, FilterWrapper, MediaCard } from '../../../../../components/components'
import { Dropdown } from 'antd';
import { useGetAlbumQuery } from '../../../../../store/api/userApi'
import { useSelector } from 'react-redux'
import ProfileLayout from '../../../../profile/ProfileLayout';
import { useGetAlbumPageOthersQuery, useGetSinglePageQuery } from '../../../../../store/api/pageApi';
import { useParams } from 'react-router-dom';
import PageProfileLayout from '../../../PageProfileLayout';
import { useGetMyPageAlbumDataQuery } from '../../../../../store/api/albumApi';

const AllPictures = () => {
    // get id from param
    const { pageId } = useParams();

    // get single Page Data
    const singlePage = useGetSinglePageQuery(pageId);
    let pageWholeData = singlePage?.data?.data;

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: pageWholeData?.user?._id,
        meta_type: "Page",
        meta_data: pageId
    }
    const AlbumDataOthers = useGetAlbumPageOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetMyPageAlbumDataQuery(pageId)
    const AllImages = pageWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

    const AllImagesData = AllImages?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    if (AlbumData.isLoading) {
        return (
            <>
                <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                    <h4 className="mb-0">Loading...</h4>
                </div>
            </>
        );
    }

    if (AlbumData.isError) {
        return (
            <>
                <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                    <h4 className="mb-0">Someting Went Wrong!!</h4>
                </div>
            </>
        );
    }

    return (
        <PageProfileLayout data={pageWholeData}>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/page/${pageId}/media`} title="Images" noCount/>
                            <DashboardMenuLink url={`/page/${pageId}/media/video`} title="Videos" noCount/>
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <h3 className="fs_18 mb-3">All Picture</h3>
                <div className='row gx-4'>
                    {AllImagesData?.map((elem, index) => {
                        return (
                            <div className="col-lg-3 col-md-3 col-6" key={index}>
                                <MediaCard imageData={elem} type={"allImage"} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </PageProfileLayout>
    )
}

export default AllPictures
