import React, { useReducer } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import toast from 'react-hot-toast';
import AWS from "aws-sdk";
import { useSelector } from 'react-redux';
import { useCreateEventMutation } from '../../store/api/eventApi';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetCategoryListQuery } from '../../store/api/categoryMaster';
import generateRandomImageName from '../../methods/generateRandomImageName';
import style from "./createBroadcast.module.css";
import { DatePicker } from "antd";
import { defaultUserDp } from '../../components/components';
import { LiaTimesSolid } from 'react-icons/lia';
const { RangePicker } = DatePicker;

const CreateBroadcast = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const [timing, setTiming] = useState(null)
    const [validation, setValidation] = useState(false);
    const [banner, setBanner] = useState("")
    const [createEvent] = useCreateEventMutation()
    const inputRefBanner = useRef(null);
    const navigate = useNavigate()
    const categoryList = useGetCategoryListQuery()

    const initialState = {
        title: "",
        description: "",
        venue: "",
        timing: [],
        banner: [],
        scope: "",
        category: ""
    };

    const formReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_EVENT":
                return { ...state, [payload.name]: payload.data };
            case "SET_DURATION":
                return { ...state, timing: payload };
            case "SET_BANNER":
                return { ...state, [payload.name]: payload.data };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(formReducer, initialState);

    const handleChange = (e) => {
        dispatch({
            type: "SET_EVENT",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };

    const handleDuration = (v, DateTime) => {
        if (DateTime.length === 2) {
            setTiming(false)
        }
        dispatch({ type: "SET_DURATION", payload: DateTime });
    };

    // to set banner in state
    const handleBanner = (e) => {
        setBanner(e.target.files[0])
        const selectedFIles = [];
        const targetFiles = e.target.files;
        const targetFilesObject = [...targetFiles];

        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file));
        });

        if (selectedFIles.length > 0) {
            dispatch({
                type: "SET_BANNER",
                payload: {
                    name: e.target.name,
                    data: [...selectedFIles],
                },
            });
        }
    };

    const removeCoverImage = () => {
        setBanner("")
        dispatch({
            type: "SET_BANNER",
            payload: {
                name: "banner",
                data: [],
            },
        });
        inputRefBanner.current.value = null;
    }

    // POST THE EVENT DATA
    const submitForm = async (e) => {
        e.preventDefault();
        if (state.timing.length === 0) {
            setTiming(true)
        } else {
            setTiming(false)
            // do your all functions
            if (navigator.onLine) {
                if (banner.name) {
                    // for cover picture start
                    const bannerImage_S3_BUCKET = "ubuntu-social-media-2";
                    const bannerImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const bannerImage_s3 = new AWS.S3({
                        params: { Bucket: bannerImage_S3_BUCKET },
                        region: bannerImage_REGION,
                    });
                    let randomNameBannerImage = generateRandomImageName(banner.name);
                    let bannerName = "page_" + randomNameBannerImage.split(" ").join("_").split("-").join("_");
                    let bannerSize = banner.size;
                    let bannerType = banner.type;

                    const bannerImage_s3params = {
                        Bucket: bannerImage_S3_BUCKET,
                        Key: bannerName,
                        Body: banner,
                    };

                    var only_bannerImage_upload = bannerImage_s3.putObject(bannerImage_s3params).on("httpUploadProgress", (evt) => {
                        // File uploading progress
                        console.log(
                            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        );
                    }).promise();

                    await only_bannerImage_upload.then((data, err) => { });

                    let bannerImageArr = []

                    bannerImageArr.push({
                        image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + bannerName),
                        size: bannerSize,
                        mime_type: bannerType,
                    });

                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        venue: state.venue,
                        banner_image: bannerImageArr,
                        timing: state.timing,
                        category_id: state.category
                    }

                    createEvent(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/profile/${userSliceData._id}/events`)
                        } else {
                            toast.error("Something went wrong !!!")
                        }
                    })
                } else {
                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        venue: state.venue,
                        banner_image: [],
                        timing: state.timing,
                        category_id: state.category
                    }
                    createEvent(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/profile/${userSliceData._id}/events`)
                        } else {
                            toast.error("Something went wrong !!!")
                        }
                    })
                }
            }
        }
        setValidation(true);
    };

    return (
        <div className="gap2 gray-bg full_height">
            <div className="container">
                <div className="row">
                    <div className={"col-md-2 col-lg-2"}></div>
                    <div className={"col-md-7 col-lg-8"}>
                        <Card className={`${style.central_meta}`}>
                            <Card.Body className={`${style.card_body}`}>
                                <Card.Title className={`${style.card_title} mb-3 fs-4`}>
                                    Create a New Broadcast
                                </Card.Title>
                                <Form
                                    noValidate
                                    validated={validation}
                                    method="post"
                                    onSubmit={submitForm}
                                >
                                    <Row>
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-12 mb-3`}>
                                            <Form.Label>Broadcast title*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your event name"
                                                value={state.title}
                                                onChange={handleChange}
                                                name="title"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide event name</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Broadcast duration*</Form.Label>
                                            <RangePicker
                                                showTime
                                                onChange={handleDuration}
                                                style={{ width: '100%', height: '39px', borderColor: timing === null ? "var(--bs-border-color)" : (timing === true ? "var(--red)" : "var(--bs-form-valid-border-color)") }}
                                                required
                                            />
                                            <Form.Control.Feedback className={style.invalidMessage} style={{ display: timing ? "block" : "none" }}>Please provide event date and time</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formFile" className={`col-md-6 mb-3`}>
                                            <Form.Label>Upload banner*</Form.Label>
                                            <Form.Control type="file" onChange={handleBanner} name="banner" ref={inputRefBanner}/>
                                            <Form.Control.Feedback type="invalid">Please select event photo</Form.Control.Feedback>
                                            {banner.name && (
                                                <div className={`editEventUploadImgCard`}>
                                                    <img src={banner?.name?.length > 0 ? state?.banner[0] : state?.banner[0]?.image_url ? state?.banner[0]?.image_url : defaultUserDp} className="img-thumbnail" alt='...' />
                                                    <button type="button" onClick={removeCoverImage}>
                                                        <LiaTimesSolid />
                                                    </button>
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                            <Form.Label>Description*</Form.Label>
                                            <Form.Control
                                                placeholder="Enter a small description"
                                                required
                                                type="text"
                                                value={state.description}
                                                onChange={handleChange}
                                                name="description"
                                                as="textarea"
                                                style={{ height: '70px' }}
                                            />
                                            <Form.Control.Feedback type="invalid">Please add description</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Col className='d-flex align-items-center justify-content-center'>
                                        <Button className="btn btn-primary" type="submit">Create Broadcast</Button>
                                    </Col>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateBroadcast
