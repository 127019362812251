import { Dropdown } from 'antd'
import React from 'react'
import { Button } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { DashboardMenuLink, FilterWrapper, MediaCard } from '../../../../../components/components'
import { useGetAlbumQuery } from '../../../../../store/api/userApi'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { useGetSinglePageQuery } from '../../../../../store/api/pageApi'
import { useGetMyGroupAlbumDataQuery, useGetMyPageAlbumDataQuery } from '../../../../../store/api/albumApi'
import { useGetAlbumGroupOthersQuery, useGetAlbumGroupQuery, useGetSingleGroupQuery } from '../../../../../store/api/groupApi'
import GroupProfileLayout from '../../../GroupProfileLayout'

const CoverPictures = () => {
  // get id from param
  const { groupId } = useParams();

  // get single Event Data
  const singleGroup = useGetSingleGroupQuery(groupId);
  let groupWholeData = singleGroup?.data?.data;

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  const AlbumParamsOthers = {
    page: 1,
    per_page: 100,
    module: "Album",
    user_id: groupWholeData?.user?._id,
    meta_type: "Group",
    meta_data: groupId
  }
  const AlbumDataOthers = useGetAlbumGroupOthersQuery(AlbumParamsOthers)

  const AlbumData = useGetAlbumGroupQuery(groupId)
  const CoverImages = groupWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

  const CoverImagesData = CoverImages?.filter(function (el) {
    return el.name === "cover_photo";
  })[0]?.gallery;

  if (AlbumData.isLoading) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Loading...</h4>
        </div>
      </>
    );
  }

  if (AlbumData.isError) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Someting Went Wrong!!</h4>
        </div>
      </>
    );
  }

  return (
    <GroupProfileLayout data={groupWholeData}>
      <FilterWrapper>
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="DashboardMenuLinks">
              <DashboardMenuLink url={`/group/${groupId}/media`} title="Images" noCount/>
              <DashboardMenuLink url={`/group/${groupId}/media/video`} title="Videos" noCount/>
            </div>
          </div>
        </div>
      </FilterWrapper>
      <div className='central-meta'>
        <h3 className="fs_18 mb-3">Cover Picture</h3>
        <div className='row gx-4'>
          {CoverImagesData?.map((elem, index) => {
            return (
              <div className="col-lg-3 col-md-3 col-6" key={index}>
                <MediaCard imageData={elem} type={"coverImage"} />
              </div>
            );
          })}
        </div>
      </div>
    </GroupProfileLayout>
  )
}

export default CoverPictures;
