import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const modulePostApi = createApi({
    reducerPath: "modulePostApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["ModulePosts"],
    endpoints: (builder) => ({
        // CREATE A NEW POST
        createModulePost: builder.mutation({
            query: (newPost) => {
                return {
                    url: `api/posts/create`,
                    method: "POST",
                    body: newPost,
                };
            },
            invalidatesTags: ["ModulePosts"],
        }),

        getsingleUserTimelineModule: builder.query({
            query: (data) => {
                return {
                    url: `api/posts/allUserTimeline?page=${data.page}&per_page=10&meta_data=${data.meta_data}&meta_type=${data.meta_type}`,
                    method: "GET",
                }
            },
            providesTags: ["ModulePosts"],
        }),

        editPostModule: builder.mutation({
            query: (obj) => {
                return {
                    url: `api/posts/update`,
                    method: "PUT",
                    body: obj,
                };
            },
            invalidatesTags: ["ModulePosts"],
        }),

        deletePostModule: builder.mutation({
            query: ({ postId }) => {
                return {
                    url: `api/posts/delete`,
                    method: "DELETE",
                    body: {
                        "post_id": postId
                    }
                };
            },
            invalidatesTags: ["ModulePosts"],
        }),
    }),
})

export const {
    useCreateModulePostMutation,
    useGetsingleUserTimelineModuleQuery,
    useEditPostModuleMutation,
    useDeletePostModuleMutation
} = modulePostApi;
