import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useChangeReactionStatusMutation, useDeleteReactionMutation } from '../../../store/api/Admin/adminreactions'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import './reactionCard.css'
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi'
import { useSelector } from 'react-redux'

const ReactionCard = ({ data }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [statusModal, setStatusModal] = useState(false)
    const [changeReactionStatus] = useChangeReactionStatusMutation()
    const navigate = useNavigate()

    const [deleteReaction] = useDeleteReactionMutation()

    const handleStatus = () => {
        changeReactionStatus({
            id: data._id,
            status: data.status === 0 ? 1 : 0
        }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
            setStatusModal(false)
        })
    }
    const handleDelete = () => {
        setDeleteModal(false)
        deleteReaction(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
        })
        setStatusModal(false)
    }

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <>
            <div className='bg-white shadow text-center py-3 px-2 rounded adminReactionCard'>
                <span className="d-block" dangerouslySetInnerHTML={{ __html: data?.image }}></span>
                <h6 className='my-3 text-uppercase fw-bold'>{data.title}</h6>
                <div className="table_actions p-0">
                    {(userInfo.user_type === 3 && user_permission_arr.includes("reaction_edit")) && <button className='bg-gray-subtle text-gray' onClick={() => navigate(`/admin/reactions/edit/${data._id}`)}>Edit</button>}
                    {userInfo.user_type === 1 && <button className='bg-gray-subtle text-gray' onClick={() => navigate(`/admin/reactions/edit/${data._id}`)}>Edit</button>}
                    {(userInfo.user_type === 3 && user_permission_arr.includes("reaction_active")) && <button onClick={() => setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                        {data.status === 1 ? 'Active' : 'Deactive'}
                    </button>}
                    {userInfo.user_type === 1 && <button onClick={() => setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                        {data.status === 1 ? 'Active' : 'Deactive'}
                    </button>}
                    {(userInfo.user_type === 3 && user_permission_arr.includes("reaction_delete")) && <button className='bg-danger-subtle text-danger' onClick={() => setDeleteModal(!deleteModal)}>Delete</button>}
                    {userInfo.user_type === 1 && <button className='bg-danger-subtle text-danger' onClick={() => setDeleteModal(!deleteModal)}>Delete</button>}
                </div>
            </div>
            {/* BLOCK MODAL */}
            <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to {data.status !== 1 ? 'active' : 'deactive'} this react?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleStatus} className="mx-2">
                                {data.status !== 1 ? 'Active' : 'Deactive'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete MODAL */}
            <Modal centered size="sm" show={deleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to delete this react?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleDelete} className="mx-2">
                                Delete
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReactionCard