import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const stateApi = createApi({
  reducerPath: "stateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
  }),
  tagTypes: ["State"],

  endpoints: (builder) => ({
    getState: builder.query({
      query: (country) => ({
        url: "api/country/states",
        method: "GET",
        params: {
          iso: country,
        },
      }),
      providesTags: ["State"],
    }),
  }),
});

export const { useGetStateQuery } = stateApi;
