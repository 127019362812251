export function convertEpochToCustomFormat(epochTime) {
    const date = new Date(epochTime); // Convert to milliseconds

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const formattedDate = `${day}th ${month}, ${year}`;

    return formattedDate;
}