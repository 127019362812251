import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetUserDetailsAgainstIdQuery } from "../../../store/api/userApi";
import EpochTimeToDateTime from "../../../methods/EpochTimeToDateTime";
import style from './About.module.css'
const DashboardAbout = ({ data, type }) => {

   const { userId } = useParams();
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   const userDetails = useGetUserDetailsAgainstIdQuery(userId)

   console.log("dashData", data)


   const userDetailsById = userDetails?.data?.data;
   if (type === "Group" || type === "Event" || type === "Page") {
      return (
         <div className="border p-4 rounded-3 mt-4">
            <h5 className="fw-bold mb-3"> Info   </h5>
            <div className="row">
               <div className="col-md-6">
                  <div className="row gy-2">
                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Title</span>
                           <p className="mb-0 fs_16 text-dark">{data?.title}</p>
                        </div>
                     </div>
                     {
                        data?.rules && <div className="col-6">
                           <div className="aboutItem">
                              <span className="fw-bold">Rules</span>
                              <p className="mb-0 fs_16 text-dark">{data?.rules}</p>
                           </div>
                        </div>
                     }
                     {
                        userDetailsById?.phone && (
                           <div className="col-6">
                              <div className="aboutItem">
                                 <span className="fw-bold">Phone No.</span>
                                 <p className="mb-0 fs_16 text-dark">{userDetailsById?.phone}</p>
                              </div>
                           </div>
                        )
                     }
                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Auther</span>
                           <p className="mb-0 fs_16 text-dark">{data?.user.fullName}</p>
                        </div>
                     </div>


                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Category</span>
                           <p className="mb-0 fs_16 text-dark">{data?.category.category_name}</p>
                        </div>
                     </div>
                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Description</span>
                           <p className="mb-0 fs_16 text-dark">{data?.description}</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="row gy-2">
                     {
                        userDetailsById?.bio && <div className="col-12">
                           <div className="aboutItem">
                              <span className="fw-bold">Bio</span>
                              <p className="mb-0 fs_16 text-dark">{userDetailsById?.bio}</p>
                           </div>
                        </div>
                     }
                  </div>
               </div>
            </div>
         </div>
      )
   } else if (type === "profile") {
      return (
         <div className="border p-4 rounded-3 mt-4">
            <h5 className="fw-bold mb-3">Profile Info   </h5>
            <div className="row">
               <div className="col-md-6">
                  <div className="row gy-2">
                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Full Name</span>
                           <p className="mb-0 fs_16 text-dark">{userDetailsById?.fullName}</p>
                        </div>
                     </div>
                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Email</span>
                           <p className="mb-0 fs_16 text-dark">{userDetailsById?.email}</p>
                        </div>
                     </div>
                     {
                        userDetailsById?.phone && (
                           <div className="col-6">
                              <div className="aboutItem">
                                 <span className="fw-bold">Phone No.</span>
                                 <p className="mb-0 fs_16 text-dark">{userDetailsById?.phone}</p>
                              </div>
                           </div>
                        )
                     }
                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Date of Birth</span>
                           <p className="mb-0 fs_16 text-dark">{EpochTimeToDateTime(userDetailsById?.dob).date}</p>
                        </div>
                     </div>
                     <div className="col-6">
                        <div className="aboutItem">
                           <span className="fw-bold">Country</span>
                           <p className="mb-0 fs_16 text-dark">{userDetailsById?.countries.country}</p>
                        </div>
                     </div>
                     <div className="col-6">
                        <span className="fw-bold">Gender</span>
                        <p className="mb-0 fs_16 text-dark">{userDetailsById?.gender === 1 ? 'Male' : 'Female'}</p>
                     </div>
                     <div className="col-6">
                        {userDetailsById?.marital_status &&
                        <div className="aboutItem">
                           <span className="fw-bold">Marital Status</span>
                           <p className="mb-0 fs_16 text-dark">{userDetailsById?.marital_status}</p>
                        </div>
                        }
                     </div>
                     <div className="col-6">
                        {userDetailsById?.residential_address &&
                        <div className="aboutItem">
                           <span className="fw-bold">Address</span>
                           <p className="mb-0 fs_16 text-dark">{userDetailsById?.residential_address}</p>
                        </div>
                        }
                     </div>
                  </div>
               </div>
               <div className="col-md-6">
                  <div className="row gy-2">
                     {
                        userDetailsById?.bio && <div className="col-12">
                           <div className="aboutItem">
                              <span className="fw-bold">Bio</span>
                              <p className="mb-0 fs_16 text-dark">{userDetailsById?.bio}</p>
                           </div>
                        </div>
                     }

                     <div className="col-12">
                        <span className="fw-bold">Interests</span>
                        <div className={`${style.interestItems}`}>
                           {userDetailsById?.area_of_interests.map((elem, i) => {
                              if (elem.category) {
                                 return <span>{elem?.category.category_name}</span>
                              }
                           })}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   } else {
      <div className="row">
         <div className="col-12">
            <div className="mt-4">
               <div className="row">
                  {/* Left side description */}
                  <div className="col-lg-6">
                     <div className="gen-metabox">
                        <span>
                           Title
                        </span>
                        <p>
                           {data?.title}
                        </p>
                     </div>
                     <div className="gen-metabox">
                        <span>
                           Description
                        </span>
                        <p>
                           {data?.description}
                        </p>
                     </div>
                     {data?.hasOwnProperty("rules") && <div className="gen-metabox">
                        <span>
                           Rules
                        </span>
                        <p></p>
                     </div>}
                  </div>
                  {/* Right side description */}
                  <div className="col-lg-6">
                     <div className="gen-metabox">
                        <span className="mb-2">
                           Author Details
                        </span>
                        <img className="mb-3" src={data?.user?.profile_picture[0]?.image_url} alt="..." style={{ height: '80px', width: '80px', borderRadius: '50%' }} />
                        <p>
             
                           <br />
                      
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   }




};

export default DashboardAbout;
