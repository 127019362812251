import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const voteApi = createApi({
    reducerPath: "voteApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["vote"],
    endpoints: (builder) => ({

        // GET ALL VOTE
        getVote: builder.query({
            query: (id) => ({
                url: "api/posts/vote/lists",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 10000000,
                    post_id: id
                },
            }),
            providesTags: ["vote"],
        }),

        // VOTE UP ADD
        voteUpHandle: builder.mutation({
            query: (id) => {
                return {
                    url: `api/posts/vote/create-update`,
                    method: "PUT",
                    body: {
                        post_id: id,
                        flag: "up"
                    },
                };
            },
            invalidatesTags: ["vote"]
        }),

        // VOTE DOWN ADD
        voteDownHandle: builder.mutation({
            query: (id) => {
                return {
                    url: `api/posts/vote/create-update`,
                    method: "PUT",
                    body: {
                        post_id: id,
                        flag: "down"
                    },
                };
            },
            invalidatesTags: ["vote"]
        }),

        // DELETE VOTE UP
        deleteVoteUp: builder.mutation({
            query: (id) => {
                return {
                    url: `api/posts/vote/delete`,
                    method: "DELETE",
                    body: {
                        post_id: id,
                        flag: "up"
                    },
                };
            },
            invalidatesTags: ["vote"]
        }),

        // DELETE VOTE DOWN
        deleteVoteDown: builder.mutation({
            query: (id) => {
                return {
                    url: `api/posts/vote/delete`,
                    method: "DELETE",
                    body: {
                        post_id: id,
                        flag: "down"
                    },
                };
            },
            invalidatesTags: ["vote"]
        })
    })
})

export const { useVoteDownHandleMutation, useVoteUpHandleMutation, useGetVoteQuery, useDeleteVoteUpMutation, useDeleteVoteDownMutation } = voteApi