import React from "react";
import "./registerPage.css";
import Register from "../../components/register/register";
import bgImage from "../../assets/images/social-media-3846597_1280.webp";
import logo from "../../assets/images/logo.svg";

const RegisterPage = () => {
  return (
    <>
      <section
        className="login-back min-vh-100"
        style={{ background: `url(${bgImage})` }}
      >
        <div className="signin register min-vh-100">  
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6">
                <Register />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterPage;
