import React, { useEffect, useState } from "react";
import "../post/post.css";
import { Link, useParams } from "react-router-dom";
import Reacts from "../utils/reacts/Reacts";
import { Image, defaultUserDp } from "../components";
import EpochTimeToDateTime from "../../methods/EpochTimeToDateTime";
import { useDeletePostCommunityMutation, useDeletePostMutation, useCreateReportMutation, useDeletePostPageMutation, useDeletePostGroupMutation, useDeletePostEventMutation } from "../../store/api/postApi";
import { useDeleteAdminPostMutation } from "../../store/api/Admin/PostPanelApi";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Button, Col, Form, Modal } from "react-bootstrap";
// import Comments from "./comment/comments";
import Comments from "../post/comment/comments";
import EditPost from "../post/edit/editPost";
import { Dropdown, Button as AntButton } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { useSaveAPostMutation } from "../../store/api/savePostApi";
import { useGetReportReasonListQuery } from "../../store/api/reportApi";
import { useGetReactsQuery } from "../../store/api/reactApi";
import { RiErrorWarningLine } from "react-icons/ri";
import { WhatsappShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import Vote from "../utils/vote/Vote";
import { useGetVoteQuery } from "../../store/api/voteApi";
import Report from "../adminComponent/Reports/Report";
import { useGetReportListAgainstIdQuery } from "../../store/api/Admin/adminReportApi";
import { useGetCommentsQuery } from "../../store/api/commentApi";
import Video from "../video/video";
import { useUserPermissionListQuery } from "../../store/api/Admin/adminRolePermissionApi";
import { useDeleteAdminCommunityPostMutation } from "../../store/api/Admin/adminCommunityPanelApi";

const SearchPost = ({ data, index, caption, type }) => {
    const getComment = useGetCommentsQuery(data._id)
    const [shareDrop, setShareDrop] = useState(false)
    const shareUrl = `https://ubuntu-social-media-frontend.notebrains.com/post/${data?._id + "_" + index}`;
    const [idInState, setIdInState] = useState("");
    const [showComment, setShowComment] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    })
    const { pageId, groupId, eventId } = useParams()

    // DELETE POST
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deletePost] = useDeletePostMutation();
    const [deletePostCommunity] = useDeletePostCommunityMutation();
    const [deleteModalAdmin, setDeleteModalAdmin] = useState(false)
    const [deleteAdminPost] = useDeleteAdminPostMutation()
    const [deleteAdminCommunityPost] = useDeleteAdminCommunityPostMutation()
    const [deletePostPage] = useDeletePostPageMutation()
    const [deletePostGroup] = useDeletePostGroupMutation()
    const [deletePostEvent] = useDeletePostEventMutation()

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    const deleteThisPost = (indexId) => {
        if (type === "community") {
            deletePostCommunity(indexId).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                } else {
                    toast.error("Something went wrong!!!")
                }
            })
        }
        else if (type === "page") {
            deletePostPage(indexId).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                } else {
                    toast.error("Something went wrong!!!")
                }
            })
        }
        else if (type === "group") {
            deletePostGroup(indexId).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                } else {
                    toast.error("Something went wrong!!!")
                }
            })
        }
        else if (type === "event") {
            deletePostEvent(indexId).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                } else {
                    toast.error("Something went wrong!!!")
                }
            })
        }
        else {
            console.log(indexId)
            deletePost(indexId).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                } else {
                    toast.error(res?.data?.message)
                }
            })
        }
    }

    const triggerDelete = () => {
        deleteThisPost({ index: index, postId: data._id, pageId: pageId, groupId: groupId, eventId: eventId })
        setDeleteModal(false)
    }

    // EDIT POST
    const [showEditModal, setShowEditModal] = useState(false);
    const editThisPost = (id) => {
        setShowEditModal(true)
        setIdInState(id)
    }

    // SAVE POST
    const [savePost] = useSaveAPostMutation()
    const savePostFunc = () => {
        savePost(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
        })
    }

    let profile = JSON.parse(localStorage.getItem("userData"))

    // TO REPORT ON A POST
    const reportReasonList = useGetReportReasonListQuery()
    const [reportApost] = useCreateReportMutation()
    const [showReportModal, setShowReportModal] = useState(false);
    const [otherReason, setOtherReason] = useState("")
    const [reason, setReason] = useState("")
    let getReason = reason.length > 0 ? JSON.parse(reason) : "";

    const reportApostModal = () => {
        let reportObj = {
            index: index,
            meta_data: data._id,
            meta_type: "Post",
            other_reason: otherReason,
            report_reason_id: getReason._id
        }
        reportApost(reportObj).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
        })
        setShowReportModal(false);
        setOtherReason("");
        setReason("");
    }

    const postDropdownList = userSliceData?._id === data?.posted_by?._id ? [
        // {
        //     label: <div onClick={() => editThisPost(data._id)}>Edit Post</div>,
        //     key: "0"
        // },
        {
            label: <div onClick={savePostFunc}>Save Post</div>,
            key: "1"
        },
        // {
        //     label: <div onClick={() => setDeleteModal(true)}>Delete Post</div>,
        //     key: "3"
        // }
    ] : data.is_reported === false ? [
        {
            label: <div onClick={savePostFunc}>Save Post</div>,
            key: "1"
        },
        {
            label: <div onClick={() => setShowReportModal(true)}>Report Post</div>,
            key: "2"
        }
    ] : [
        {
            label: <div onClick={savePostFunc}>Save Post</div>,
            key: "1"
        },
        {
            label: <div onClick={() => toast.error("Already Reported!!")}>Reported</div>,
            key: "2"
        }
    ];

    const getReacts = useGetReactsQuery({
        post_id: data._id,
        page: 1,
        per_page: 10000000,
    })
    const reactListOnPost = getReacts?.data?.data;
    const getVote = useGetVoteQuery(data._id)
    const voteListOnPost = getVote?.data?.data;
    const reportReasonAgainstId = useGetReportListAgainstIdQuery(data._id)

    useEffect(() => {
        setShowComment(false)
        setShowReport(false)
    }, [])

    const totalChildrenLength = getComment?.data?.data.reduce((total, group) => {
        const childrenArrays = getComment?.data?.data.map(item => item.children);
        const childrenLength = childrenArrays.reduce((sum, children) => sum + children?.length, 0);
        return 0 + childrenLength;
    }, 0);
    console.log(data)
    return (
        <>
            <div className="postItem">
                <div className="post_top">
                    <div className="post_auth">
                        <figure>
                            <Image
                                src={data?.posted_by?._id === profile._id ? profile?.profile_picture[0]?.image_url : data?.hasOwnProperty("profile_picture") ? data.posted_by?.profile_picture[0]?.image_url : defaultUserDp}
                                alt="..."
                            />
                        </figure>
                        <div className="auth_info">
                            <div className="auth_nm">
                                <Link to={`/profile/${data.posted_by?._id}/timeline`}>
                                    {data.posted_by?.fullName}
                                </Link>
                            </div>
                            <span>
                                {data.post_module && <span className="mr-1">Post from <span>{data.post_module}</span> ·</span>}
                                {data.meta_type && <span className="mr-1">Post from <span>{data?.meta_type}</span> ·</span>}{EpochTimeToDateTime(data.createdAt).date} at {EpochTimeToDateTime(data.createdAt).time}
                            </span>
                        </div>
                    </div>
                    <div className="post_top_right">
                        {(data?.category == null || data?.category == null) ? ""
                            :
                            <div className="post_category">{data?.category?.category_name || data?.category?.name}</div>
                        }
                        <Dropdown menu={{ items: postDropdownList }} placement="bottomRight" trigger={["click"]}>
                            <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
                        </Dropdown>
                    </div>
                </div>
                <div className="post_meta">
                    {data?.post_text !== '' && (
                        <div className={"post_content active"} dangerouslySetInnerHTML={{ __html: data.post_text }} />
                    )}
                    {/* FOR SINGLE IMAGE  */}
                    {data?.images?.length === 1 && (
                        <div className="img-bunch style_with_1">
                            <div className="row g-1">
                                <div className="col-md-12">
                                    <div className="preview_post_img preview_post_img_1">
                                        {data.images[0].mime_type.slice(0, 5) === "image" && (
                                            <>
                                                <Link to={`/post/${data?._id + "_" + index}`}>
                                                    <Image src={data.images[0].image_url} alt="..." />
                                                </Link>
                                            </>
                                        )}
                                        {data.images[0].mime_type.slice(0, 5) === "video" && (
                                            <div className="videoPost">
                                                <Video id={data._id} url={data.images[0].image_url} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* FOR TWO IMAGES  */}
                    {data?.images?.length === 2 && (
                        <div className="img-bunch style_with_2">
                            <div className="row g-1">
                                <div className="col-6">
                                    <div className="preview_post_img preview_post_img_1">
                                        {data.images[0].mime_type.slice(0, 5) === "image" && (
                                            <Link to={`/post/${data?._id + "_" + index}`}>
                                                <Image src={data.images[0]?.image_url} alt="..." />
                                            </Link>
                                        )}
                                        {data.images[0].mime_type.slice(0, 5) === "video" && (
                                            <div className="videoPost">
                                                <Video id={data._id} url={data.images[0].image_url} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="preview_post_img preview_post_img_1">
                                        {data.images[1].mime_type.slice(0, 5) ===
                                            "image" && (
                                                <Link to={`/post/${data?._id + "_" + index}`}>
                                                    <Image src={data.images[1]?.image_url} alt="..." />
                                                </Link>
                                            )}
                                        {data.images[1].mime_type.slice(0, 5) === "video" && (
                                            <div className="videoPost">
                                                <Video id={data._id} url={data.images[0].image_url} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/*  FOR THREE IMAGE  */}
                    {data?.images?.length === 3 && (
                        <div className="img-bunch style_with_3">
                            <div className="row g-1">
                                <div className="col-6">
                                    <div className="preview_post_img preview_post_img_1">
                                        {data.images[0].mime_type.slice(0, 5) === "image" && (
                                            <Link to={`/post/${data?._id + "_" + index}`}>
                                                <Image src={data.images[0]?.image_url} alt="..." />
                                            </Link>
                                        )}
                                        {data.images[0].mime_type.slice(0, 5) === "video" && (
                                            <div className="videoPost">
                                                <Video id={data._id} url={data.images[0].image_url} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="preview_post_img preview_post_img_2">
                                        {data.images[1].mime_type.slice(0, 5) ===
                                            "image" && (
                                                <Link to={`/post/${data?._id + "_" + index}`}>
                                                    <Image src={data.images[1]?.image_url} alt="..." />
                                                </Link>
                                            )}
                                        {data.images[1].mime_type.slice(0, 5) === "video" && (
                                            <div className="videoPost">
                                                <Video id={data._id} url={data.images[0].image_url} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="preview_post_img preview_post_img_3">
                                        {data.images[2].mime_type.slice(0, 5) === "image" && (
                                            <Link to={`/post/${data?._id + "_" + index}`}>
                                                <Image src={data.images[2]?.image_url} alt="..." />
                                            </Link>
                                        )}
                                        {
                                            data.images[2].mime_type.slice(0, 5) === "video" && (
                                                <div className="videoPost">
                                                    <Video id={data._id} url={data.images[0].image_url} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* FOR FOUR IMAGE */}
                    {data?.images?.length === 4 && (
                        <div className="img-bunch style_with_4">
                            <div className="row g-1">
                                <div className="col-md-3">
                                    <div className="preview_post_img preview_post_img_1">
                                        {data.images[0].mime_type.slice(0, 5) ===
                                            "image" && (
                                                <Link to={`/post/${data?._id + "_" + index}`}>
                                                    <Image src={data.images[0]?.image_url} alt="..." />
                                                </Link>
                                            )}
                                        {data.images[0].mime_type.slice(0, 5) === "video" && (
                                            <div className="videoPost">
                                                <Video id={data._id} url={data.images[0].image_url} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="preview_post_img preview_post_img_2">
                                        {data.images[1].mime_type.slice(0, 5) ===
                                            "image" && (
                                                <Link to={`/post/${data?._id + "_" + index}`}>
                                                    <Image src={data.images[1]?.image_url} alt="..." />
                                                </Link>
                                            )}
                                        {data.images[1].mime_type.slice(0, 5) === "video" && (
                                            <div className="videoPost">
                                                <Video id={data._id} url={data.images[0].image_url} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="preview_post_img preview_post_img_3">
                                        {data.images[2].mime_type.slice(0, 5) ===
                                            "image" && (
                                                <Link to={`/post/${data?._id + "_" + index}`}>
                                                    <Image src={data.images[2]?.image_url} alt="..." />
                                                </Link>
                                            )}
                                        {data.images[2].mime_type.slice(0, 5) ===
                                            "video" && (
                                                <div className="videoPost">
                                                    <Video id={data._id} url={data.images[0].image_url} />
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="preview_post_img preview_post_img_3">
                                        {data.images[3].mime_type.slice(0, 5) ===
                                            "image" && (
                                                <Link to={`/post/${data?._id + "_" + index}`}>
                                                    <Image src={data.images[3]?.image_url} alt="..." />
                                                </Link>
                                            )}
                                        {data.images[3].mime_type.slice(0, 5) ===
                                            "video" && (
                                                <div className="videoPost">
                                                    <Video id={data._id} url={data.images[0].image_url} />
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* FOR FIVE IMAGES  */}
                    {data?.images?.length > 5 ? (
                        <div className="img-bunch style_with_five">
                            <div className="row g-1">
                                <div className="col-6">
                                    <div className="preview_post_img preview_post_img_1">
                                        <Link to={`/post/${data?._id + "_" + index}`}>
                                            <Image src={data.images[1]?.image_url} alt="..." />
                                        </Link>
                                    </div>
                                    <div className="preview_post_img preview_post_img_2">
                                        <Link to={`/post/${data?._id + "_" + index}`}>
                                            <Image src={data.images[2]?.image_url} alt="..." />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="preview_post_img preview_post_img_3">
                                        <Link to={`/post/${data?._id + "_" + index}`}>
                                            <Image src={data.images[3]?.image_url} alt="..." />
                                        </Link>
                                    </div>
                                    <div className="preview_post_img preview_post_img_4">
                                        <Link to={`/post/${data?._id + "_" + index}`}>
                                            <Image src={data.images[4]?.image_url} alt="..." />
                                        </Link>
                                    </div>
                                    <div className="preview_post_img preview_post_img_5">
                                        <Link to={`/post/${data?._id + "_" + index}`}>
                                            <Image src={data.images[5]?.image_url} alt="..." />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ("")}
                </div>
                <div className="post_actions">
                    <div className="post_action_group">
                        <div className="reactionPost">
                            <Reacts id={data._id} reactListOnPost={reactListOnPost} />
                        </div>
                        <Vote id={data._id} voteListOnPost={voteListOnPost} />
                    </div>
                    <div className="action comment" onClick={() => setShowComment(!showComment)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-message-square"
                        >
                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                        </svg>
                        <span>
                            {getComment?.data?.data?.length + totalChildrenLength}{" "} <span className="action_title d-none d-xl-inline">comments</span>
                        </span>
                    </div>
                    <div className={shareDrop === false ? 'action share' : 'action share active'}>
                        <button onClick={() => setShareDrop(!shareDrop)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="me-0"
                            >
                                <circle cx="18" cy="5" r="3"></circle>
                                <circle cx="6" cy="12" r="3"></circle>
                                <circle cx="18" cy="19" r="3"></circle>
                                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                            </svg>
                        </button>
                        <div className="shareOptions">
                            <FacebookShareButton
                                url={shareUrl}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <WhatsappShareButton
                                url={shareUrl}
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <LinkedinShareButton
                                url={shareUrl}
                                className="Demo__some-network__share-button"
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <TwitterShareButton
                                url={shareUrl}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                    </div>
                </div>
                {showComment ? <Comments data={data} postIndex={index} postId={data._id} /> : ''}
            </div>

            {/* EDIT POST MODAL */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} backdrop="static" keyboard={false}>
                <Modal.Body>
                    <EditPost data={data} postId={idInState} index={index} showEditModal={{ showEditModal, setShowEditModal }} type={type} />
                </Modal.Body>
            </Modal>

            {/* CONFIRM DELETE MODAL */}
            <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to delete the post?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => triggerDelete()} className="mx-2">
                                Delete
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* CONFIRM REPORT MODAL */}
            <Modal centered size="sm" show={showReportModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Report on Post</h5>
                        <Form noValidate className="we-form" method="post">
                            <Form.Group as={Col} className="mb-2 pb-1">
                                <Form.Label>Reason</Form.Label>
                                <Form.Select onChange={(e) => setReason(e.target.value)} required>
                                    <option value="">Select reason</option>
                                    {reportReasonList?.data?.data.map((el, i) => {
                                        return <option key={i} value={JSON.stringify(el)} >
                                            {el.reason_text}
                                        </option>
                                    })}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select your country
                                </Form.Control.Feedback>
                            </Form.Group>
                            {getReason.reason_text === "Others" && <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                <Form.Label>Description*</Form.Label>
                                <Form.Control
                                    placeholder="Enter a small description"
                                    required
                                    type="text"
                                    value={otherReason}
                                    onChange={(e) => setOtherReason(e.target.value)}
                                    as="textarea"
                                    style={{ height: '70px' }}
                                />
                                <Form.Control.Feedback type="invalid">Please add description</Form.Control.Feedback>
                            </Form.Group>}

                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setShowReportModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={reportApostModal} className="mx-2">
                                    Report
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SearchPost;
