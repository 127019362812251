import React from "react";
import {LiaTimesSolid} from 'react-icons/lia'
import './alert.css'

const ErrorAlert = ({ title, para }) => {
  return (
    <div className="alert_box failed">
      <div className="alertIcon error">
        <LiaTimesSolid/>
      </div>
      {(title !== "" && title !== undefined) && <h4>{title}</h4>}
      {(para !== "" && para !== undefined)  && <p>{para}</p>}
    </div>
  );
};

export default ErrorAlert;
