import React, { useState } from "react";
import bgImg from "../../../assets/images/social-media-3846597_1280.webp";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForgotPasswordMutation } from "../../../store/api/authApi";
import GoBackWrapper from "../../../components/GoBackWrapper/GoBackWrapper";

const ForgetPassword = () => {
  const [emailorphone, setEmailorphone] = useState("");
  const [forgotPassword, response] = useForgotPasswordMutation();
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState("")
  let navigate=useNavigate()

  const submitForm = (e) => {
    e.preventDefault()
    forgotPassword(emailorphone).then((res) => {
      setMessage(res?.data?.message)
    }).catch((err) => {
        console.log(err);
    });
  };

  return (
    <div className="www-layout">
      <GoBackWrapper>
      <div
        className="login-back"
        style={{ backgroundImage: `url(${bgImg})` }}
        // style="background: url('images/social-media-3846597_1280.webp');"
      >
        <div className="signin login">
          {/* <!-- <div className="bg-image" ></div> --> */}
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-4">
                <div className="we-login-register mb-5 mt-3">
                  <div className="form-title">
                    <i className="fa fa-key"></i>
                    forgot password
                    <span>
                      {/* sign in now and meet the awesome Friends around the world. */}
                      Oops! It happens to the best of us. If you've forgotten your password, don't worry. Just enter your email address below. 
                    </span>
                  </div>
                  <Form
                    noValidate
                    validated={validated}
                    className="we-form"
                    method="post"
                    onSubmit={submitForm}
                  >
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>
                          Email <span className="text-lowercase">or</span> Phone
                          {/* <small className="text-lowercase">
                                          (enter country code)
                                       </small> */}
                          *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter email or phone"
                          name="username"
                          value={emailorphone}
                          onChange={(e) => setEmailorphone(e.target.value)}
                          required
                          pattern={
                            isNaN(Number(emailorphone))
                              ? "^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$"
                              : "^[+][1-9]{1}[0-9]{1,2}[1-9]{1}[0-9]{9}$"
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your Email or Phone Number.
                        </Form.Control.Feedback>
                        {
                          message !== "" ? <span>{message}</span> : " "
                        }
                      </Form.Group>
                    </Row>
                    <Button
                      variant="primary"
                      type="submit"
                      className="register-btn"
                    >
                      Send Link
                    </Button>
                  </Form>
                  <span>
                    Don't have an account?{" "}
                    <Link
                      className="we-account login-link"
                      to="/register"
                      title=""
                    >
                      register now
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </GoBackWrapper>
    </div>
  );
};

export default ForgetPassword;
