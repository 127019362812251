import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminReactions = createApi({
    reducerPath: "adminReactions",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminReactions"],
    endpoints: (builder) => ({
        getReactionList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/reacts/list",
                    method: "GET",
                    params: {
                        page: data.page,
                        per_page: data.per_page,
                        search_keyword: data.search_keyword
                    },
                }
            },
            providesTags: ["AdminReactions"],
        }),
        createReactions: builder.mutation({
            query: (data) => {
                return {
                    url: "api/admin/reacts/create",
                    method: "POST",
                    body: {
                        title: data.title,
                        image: data.base64String,
                        status: 1
                    },
                }
            },
            invalidatesTags: ["AdminReactions"],
        }),
        updateReactions: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/reacts/update`,
                    method: "PUT",
                    body: {
                        react_id: data.reactionId,
                        title: data.title,
                        image: data.base64String,
                        status: 1
                    }
                };
            },
            invalidatesTags: ["AdminReactions"],
        }),
        changeReactionStatus: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/reacts/change-status`,
                    method: "PUT",
                    body: {
                        react_id: data.id,
                        status: data.status
                    }
                };
            },
            invalidatesTags: ['AdminReactions']
        }),
        deleteReaction: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/reacts/delete`,
                    method: "DELETE",
                    body: {
                        react_id: data
                    }
                };
            },
            invalidatesTags: ["AdminReactions"],
        }),
        reactionDetails: builder.query({
            query: (id) => {
                return {
                    url: "api/admin/reacts/details",
                    method: "GET",
                    params: {
                        react_id: id
                    },
                }
            },
            providesTags: ["AdminReactions"],
        }),
    }),
});

export const { useGetReactionListQuery, useCreateReactionsMutation, useUpdateReactionsMutation, useChangeReactionStatusMutation, useDeleteReactionMutation, useReactionDetailsQuery } = adminReactions;