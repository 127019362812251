import React from "react";
import GroupProfileLayout from "../GroupProfileLayout";
import { DashboardAbout } from "../../../components/components";
import { useParams } from "react-router-dom";
import { useGetSingleGroupQuery } from "../../../store/api/groupApi";

const About = () => {
   // get id from param
   const params = useParams();
   const id = params.groupId;

   // get single Event Data
   const singleGroup = useGetSingleGroupQuery(id);
   let groupWholeData = singleGroup?.data?.data;
   return (
      <GroupProfileLayout data={groupWholeData}>
         <DashboardAbout data={groupWholeData} type={"Group"}/>
      </GroupProfileLayout>
   );
};

export default About;
