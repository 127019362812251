import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const { userSlice } = getState();
      let token = userSlice.userAuthToken;
      return headers.set("x-access-token", token);
    },
  }),
  endpoints: (builder) => ({
    // ALL GENERAL POST APIS
    // SINGLE USER POST
    getsingleUserTimeline: builder.query({
      query: (page) => {
        return {
          url: `api/posts/singleUserTimeline?page=${page === undefined ? 1 : page}&per_page=3`,
          method: "GET",
        };
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, queryArg) => {
        if (queryArg.arg !== undefined) {
          currentCache.data.push(...newItems.data);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      // providesTags: ["Posts"],
    }),
    // ALL USER POST
    getAllUserTimeline: builder.query({
      query: (page) => {
        return {
          url: `api/posts/allUserTimeline?page=${page === undefined ? 1 : page}&per_page=3`,
          method: "GET",
        };
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, queryArg) => {
        const cacheJsonData = JSON.stringify(currentCache);
        const cacheData = (JSON.parse(cacheJsonData));
        const newItemId = newItems.data[0]?._id;
        const cacheIds = cacheData?.data.map(item => item._id);

        if (cacheIds.includes(newItemId) === false) {
          currentCache.data.push(...newItems.data);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    // CREATE A NEW POST
    createPost: builder.mutation({
      query: (newPost) => {
        return {
          url: `api/posts/create`,
          method: "POST",
          body: newPost,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const { userSlice } = getState();
          const newDataWithbindUserInfo = {
            ...data.data,
            posted_by: { ...userSlice.userSliceData },
            post_reactions: [],
            post_comments: []
          }
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getsingleUserTimeline', undefined, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
        } catch (error) {

        }
      }
    }),
    // EDIT POST
    editPost: builder.mutation({
      query: (obj) => {
        return {
          url: `api/posts/update`,
          method: "PUT",
          body: obj,
        };
      },
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const { userSlice } = getState();
          let updatedData = updatedPost.data;
          let index = arg.index;
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
          dispatch(
            postApi.util.updateQueryData('getsingleUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
        } catch (error) {
          console.log(error)
        }
      },
    }),
    // DELETE POST
    deletePost: builder.mutation({
      query: ({ postId }) => {
        return {
          url: `api/posts/delete`,
          method: "DELETE",
          body: {
            post_id: postId
          }
        };
      },
      async onQueryStarted({ arg, index, postId }, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getsingleUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // GET ALL POST
    getPostDetails: builder.query({
      query: (post_id) => {
        return {
          url: `api/posts/details`,
          method: "GET",
          params: {
            post_id: post_id
          }
        }
      },
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      // merge: (currentCache, newItems, queryArg) => {
      //   if (queryArg.arg !== undefined) {
      //     currentCache.data.push(...newItems.data);
      //   }
      // },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    // PAGE ALL APIS
    // CREATE POST
    createPostPage: builder.mutation({
      query: (data) => {
        return {
          url: "api/posts/create",
          method: "POST",
          body: data,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const { userSlice } = getState();
          const newDataWithbindUserInfo = {
            ...data.data,
            posted_by: { ...userSlice.userSliceData },
            post_reactions: [],
            post_comments: []
          }
          dispatch(
            postApi.util.updateQueryData(`getPageTimeline`, arg.meta_data, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
        } catch (error) {
          console.log(error);
        }
      }
    }),
    // GET ALL POST
    getPageTimeline: builder.query({
      query: (data) => {
        return {
          url: `api/posts/allUserTimeline?page=${data.page === undefined ? 1 : data.page}&per_page=5&meta_data=${data.meta_data}&meta_type=${data.meta_type}`,
          method: "GET",
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs.meta_data) {
          return endpointName + queryArgs.meta_data;
        } else {
          return endpointName + queryArgs;
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, queryArg) => {
        const cacheJsonData = JSON.stringify(currentCache);
        const cacheData = (JSON.parse(cacheJsonData));
        const cacheDataId = cacheData?.data[cacheData.data.length - 1]?._id;
        const newItemId = newItems.data[newItems.data.length - 1]?._id;
        if (queryArg.arg.page !== 1 && cacheDataId !== newItemId) {
          currentCache.data.push(...newItems.data);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      // providesTags: ["PagePosts"]
    }),
    // DELETE POST
    deletePostPage: builder.mutation({
      query: ({ postId }) => {
        return {
          url: `api/posts/delete`,
          method: "DELETE",
          body: {
            post_id: postId
          }
        };
      },
      async onQueryStarted({ arg, index, postId, pageId }, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(
            postApi.util.updateQueryData(`getPageTimeline`, pageId, (draft) => {
              draft.data.splice(index, 1)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // EDIT POST
    editPostPage: builder.mutation({
      query: (obj) => {
        return {
          url: `api/posts/update`,
          method: "PUT",
          body: obj,
        };
      },
      async onQueryStarted({ index, pageId }, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const { userSlice } = getState();
          let updatedData = updatedPost.data;
          dispatch(
            postApi.util.updateQueryData('getPageTimeline', pageId, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
        } catch (error) {
          console.log(error)
        }
      },
    }),

    // GROUP ALL APIS
    // CREATE POST
    createPostGroup: builder.mutation({
      query: (data) => {
        return {
          url: "api/posts/create",
          method: "POST",
          body: data,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const { userSlice } = getState();
          const newDataWithbindUserInfo = {
            ...data.data,
            posted_by: { ...userSlice.userSliceData },
            post_reactions: [],
            post_comments: []
          }
          dispatch(
            postApi.util.updateQueryData(`getGroupTimeline`, arg.meta_data, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // GET ALL POST
    getGroupTimeline: builder.query({
      query: (data) => {
        return {
          url: `api/posts/allUserTimeline?page=${data.page === undefined ? 1 : data.page}&per_page=5&meta_data=${data.meta_data}&meta_type=${data.meta_type}`,
          method: "GET",
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs.meta_data) {
          return endpointName + queryArgs.meta_data
        } else {
          return endpointName + queryArgs
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, queryArg) => {
        const cacheJsonData = JSON.stringify(currentCache);
        const cacheData = (JSON.parse(cacheJsonData));
        const cacheDataId = cacheData?.data[cacheData.data.length - 1]?._id;
        const newItemId = newItems.data[newItems.data.length - 1]?._id;
        if (queryArg.arg.page !== 1 && cacheDataId !== newItemId) {
          currentCache.data.push(...newItems.data);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      // providesTags: ["PagePosts"]
    }),
    // DELETE POST
    deletePostGroup: builder.mutation({
      query: ({ postId }) => {
        return {
          url: `api/posts/delete`,
          method: "DELETE",
          body: {
            post_id: postId
          }
        };
      },
      async onQueryStarted({ arg, index, postId, groupId }, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(
            postApi.util.updateQueryData(`getGroupTimeline`, groupId, (draft) => {
              draft.data.splice(index, 1)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // EDIT POST
    editPostGroup: builder.mutation({
      query: (obj) => {
        return {
          url: `api/posts/update`,
          method: "PUT",
          body: obj,
        };
      },
      async onQueryStarted({ index, groupId }, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const { userSlice } = getState();
          let updatedData = updatedPost.data;
          dispatch(
            postApi.util.updateQueryData('getGroupTimeline', groupId, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
        } catch (error) {
          console.log(error)
        }
      },
    }),

    // EVENT ALL APIS
    // CREATE POST
    createPostEvent: builder.mutation({
      query: (data) => {
        return {
          url: "api/posts/create",
          method: "POST",
          body: data,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const { userSlice } = getState();
          const newDataWithbindUserInfo = {
            ...data.data,
            posted_by: { ...userSlice.userSliceData },
            post_reactions: [],
            post_comments: []
          }
          dispatch(
            postApi.util.updateQueryData(`getEventTimeline`, arg.meta_data, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // GET ALL POST
    getEventTimeline: builder.query({
      query: (data) => {
        return {
          url: `api/posts/allUserTimeline?page=${data.page === undefined ? 1 : data.page}&per_page=5&meta_data=${data.meta_data}&meta_type=${data.meta_type}`,
          method: "GET",
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs.meta_data) {
          return endpointName + queryArgs.meta_data
        } else {
          return endpointName + queryArgs
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, queryArg) => {
        const cacheJsonData = JSON.stringify(currentCache);
        const cacheData = (JSON.parse(cacheJsonData));
        const cacheDataId = cacheData?.data[cacheData.data.length - 1]?._id;
        const newItemId = newItems.data[newItems.data.length - 1]?._id;
        if (queryArg.arg.page !== 1 && cacheDataId !== newItemId) {
          currentCache.data.push(...newItems.data);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      // providesTags: ["PagePosts"]
    }),
    // DELETE POST
    deletePostEvent: builder.mutation({
      query: ({ postId }) => {
        return {
          url: `api/posts/delete`,
          method: "DELETE",
          body: {
            post_id: postId
          }
        };
      },
      async onQueryStarted({ arg, index, postId, eventId }, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(
            postApi.util.updateQueryData(`getEventTimeline`, eventId, (draft) => {
              draft.data.splice(index, 1)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // EDIT POST
    editPostEvent: builder.mutation({
      query: (obj) => {
        return {
          url: `api/posts/update`,
          method: "PUT",
          body: obj,
        };
      },
      async onQueryStarted({ index, eventId }, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const { userSlice } = getState();
          let updatedData = updatedPost.data;
          dispatch(
            postApi.util.updateQueryData('getEventTimeline', eventId, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
        } catch (error) {
          console.log(error)
        }
      },
    }),

    // COMMUNITY ALL APIS
    // CREATE POST
    createPostCommunity: builder.mutation({
      query: (data) => {
        return {
          url: "api/posts/create",
          method: "POST",
          body: data,
        };
      },
      // invalidatesTags: ["Community"],
      async onQueryStarted(arg, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const { userSlice } = getState();
          const newDataWithbindUserInfo = {
            ...data.data,
            posted_by: { ...userSlice.userSliceData },
            post_reactions: [],
            post_comments: []
          }

          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllCommunityPostList', undefined, (draft) => {
              draft.data?.unshift(newDataWithbindUserInfo)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // GET ALL POST LIST
    getAllCommunityPostList: builder.query({
      query: () => ({
        url: "api/posts/allUserTimeline",
        method: "GET",
        params: {
          page: 1,
          per_page: 100,
          post_module: "community"
        },
      }),
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, queryArg) => {
        if (queryArg.arg !== undefined) {
          currentCache.data.push(...newItems.data);
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    // DELETE POST
    deletePostCommunity: builder.mutation({
      query: ({ postId }) => {
        return {
          url: `api/posts/delete`,
          method: "DELETE",
          body: {
            "post_id": postId
          }
        };
      },
      async onQueryStarted({ arg, index, postId }, { dispatch, queryFulfilled, getState }) {
        try {
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1)
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllCommunityPostList', undefined, (draft) => {
              draft.data.splice(index, 1)
            })
          )
        } catch (error) {
          console.log(error)
        }
      }
    }),
    // EDIT POST
    editPostCommunity: builder.mutation({
      query: (obj) => {
        return {
          url: `api/posts/update`,
          method: "PUT",
          body: obj,
        };
      },
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const { userSlice } = getState();
          let updatedData = updatedPost.data;
          let index = arg.index;
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
          dispatch(
            postApi.util.updateQueryData('getAllCommunityPostList', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], ...updatedData, posted_by: { ...userSlice.userSliceData } })
            })
          )
        } catch (error) {
          console.log(error)
        }
      },
    }),

    // create report on a post
    createReport: builder.mutation({
      query: (data) => {
        return {
          url: `api/report`,
          method: "POST",
          body: data
        }
      },
      async onQueryStarted(arg, { queryFulfilled, getState, dispatch }) {
        try {
          let index = arg.index;
          dispatch(
            postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
              draft.data.splice(index, 1, { ...draft.data[index], is_reported: true })
            })
          )
          dispatch(
            postApi.util.updateQueryData('getPostDetails', undefined, (draft) => {
              draft.data = ({ ...draft.data, is_reported: true });
            })
          )
        } catch (error) {

        }
      }
    })
  }),
})

export const {
  useGetPostDetailsQuery,
  useCreatePostMutation,
  useGetAllUserTimelineQuery,
  useGetsingleUserTimelineQuery,
  useDeletePostMutation,
  useEditPostMutation,
  useGetAllCommunityPostListQuery,
  useCreatePostCommunityMutation,
  useDeletePostCommunityMutation,
  useEditPostCommunityMutation,
  useCreatePostPageMutation,
  useGetPageTimelineQuery,
  useCreatePostGroupMutation,
  useGetGroupTimelineQuery,
  useCreatePostEventMutation,
  useGetEventTimelineQuery,
  useCreateReportMutation,
  useDeletePostPageMutation,
  useDeletePostGroupMutation,
  useDeletePostEventMutation,
  useEditPostPageMutation,
  useEditPostGroupMutation,
  useEditPostEventMutation
} = postApi;
