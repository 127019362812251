import React, { Suspense, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAllProductListQuery, useBuyProductMutation, useDeleteProductMutation, useGetSingleProductQuery } from '../../store/api/marketApi';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Slider from "react-slick";
import LazyImage from '../../components/image/Image';
import "./marketPlace.css";
import { Image } from '../../components/components';
import EpochTimeToDateTime from '../../methods/EpochTimeToDateTime';
import { Breadcrumb } from "antd";
import ProductCard from '../../components/market/ProductCard';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { useBuyProductWalletMutation, useWalletHistoryQuery } from '../../store/api/walletApi';
import { GoStarFill } from "react-icons/go";
import { PiChatsCircleFill } from "react-icons/pi";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from 'react-bootstrap/Dropdown';



const ProductDetails = () => {
    const { productId } = useParams();
    const productDetails = useGetSingleProductQuery(productId);
    const productsList = useAllProductListQuery({ page: 1 })
    const similerProductList = productsList?.data?.data?.filter((elem) => (elem.category.category_name === productDetails?.data?.data?.category?.category_name && elem._id !== productDetails?.data?.data?._id))

    const navigate = useNavigate()

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    // IN NEW REQUEST PAGE DELETE REQUEST
    const [deleteProdModal, setDeleteProdModal] = useState(false)
    const [deleteProduct] = useDeleteProductMutation()
    const deleteProductModal = () => {
        deleteProduct(productId).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error("Something went wrong in this")
            }
        })
        setDeleteProdModal(false)
        navigate(-1)
    }
    // get wallet ballence
    const historyWallet = useWalletHistoryQuery()
    const totalBallence = historyWallet?.data?.data?.totalBalance;

    // Buy product Api callings
    const [buyModal, setBuyModal] = useState(false);
    const [buyProdWallet] = useBuyProductWalletMutation()
    const [buyProduct] = useBuyProductMutation()
    const buyProductWallet = () => {
        if (totalBallence >= productDetails?.data?.data?.selling_price) {
            const data = {
                amount: productDetails?.data?.data?.selling_price,
                description: "Buy a product",
                meta_type: "Marketplace",
                meta_data: productDetails?.data?.data?._id,
                transaction_type: "3",
                receiver_id: productDetails?.data?.data?.user?._id
            }
            buyProdWallet(data).then((res) => {
                let buyProductObj = {
                    sender_transaction_id: res?.data?.sender_transaction_id,
                    product_id: productDetails?.data?.data?._id,
                    seller_id: productDetails?.data?.data?.user?._id,
                    receiver_transaction_id: res?.data?.receiver_transaction_id
                }
                toast.success(res?.data?.message)
                if (res?.data?.error === 0) {
                    buyProduct(buyProductObj).then((res) => {
                        toast.success(res?.data?.message)
                    })
                }
                setBuyModal(false)
            })
        } else {
            toast.error("Insufficient Ballence!!")
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <button><MdOutlineKeyboardArrowRight /></button>,
        prevArrow: <button><MdOutlineKeyboardArrowLeft /></button>
    };
    const similerSliderOptions = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <button><MdOutlineKeyboardArrowRight /></button>,
        prevArrow: <button><MdOutlineKeyboardArrowLeft /></button>
    };

    if (productDetails.isLoading) {
        return (
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                <h4 className="mb-0">Loading...</h4>
            </div>
        )
    }
    if (productDetails.isError) {
        return (
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                <h4 className="mb-0">Someting Went Wrong !!</h4>
            </div>
        )
    }

    return (
        <div className="gap2 gray-bg full_height">
            <div className='container'>
                <Breadcrumb>
                    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><Link to="/marketplace">Marketplace</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>{productId}</Breadcrumb.Item>
                </Breadcrumb>
                <div className={'row justify-content-center'} >
                    <div className='col-md-5'>
                        <div className='post_details_images rounded-3'>
                            {
                                productDetails?.data?.data?.images.length > 0 ? (
                                    <Slider {...settings}>
                                        {productDetails?.data?.data?.images.map((elem, index) => {
                                            return (
                                                <div className='post_details_slideItem rounded-3' key={index}>
                                                    {/* IMAGE VIEW */}
                                                    {elem.mime_type.slice(0, 5) === "image" && (
                                                        <LazyImage src={elem.image_url} className={'w-100 h-auto rounded-3'} />
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                ) : (
                                    <img src={productDetails?.data?.data.cover_image[0].image_url} alt='...' className='w-100' />
                                )
                            }
                            {/* <div className='row thum-image g-2'>
                                <div className='col-auto thum-col'>
                                    <img src='https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/post_6fmal3_1718005048602.jpg' className='active' />
                                </div>
                                <div className='col-auto thum-col'>
                                    <img src='https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/post_6fmal3_1718005048602.jpg' />
                                </div>
                                <div className='col-auto thum-col'>
                                    <img src='https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/post_6fmal3_1718005048602.jpg' />
                                </div>
                                <div className='col-auto thum-col'>
                                    <img src='https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/post_6fmal3_1718005048602.jpg' />
                                </div>
                                <div className='col-auto thum-col'>
                                    <img src='https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/post_6fmal3_1718005048602.jpg' />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-md-7 details-container'>
                        <h1 className='heading'>{productDetails?.data?.data?.product_name}</h1>
                        <p className='fs_15 mb-2 productDesc'>Feel free to customize the placeholders with specific details about each product.</p>
                        <p className='auth_price mb-3' style={{ color: 'var(--bs-heading-color)' }}>{productDetails?.data?.data?.selling_price_with_currency}</p>
                        <div className="post_auth style2">
                            <div className="auth_left">
                                <figure>
                                    <Image src={productDetails?.data?.data?.user?.profile_picture[0]?.image_url} alt="..." />
                                </figure>
                                <div className="auth_info">
                                    <div className="auth_nm">
                                        <Link to={`/profile/${productDetails?.data?.data?.user?._id}/timeline`} className='fs_16'>
                                            {productDetails?.data?.data?.user?.fullName}
                                        </Link>
                                    </div>
                                    <p className='mb-0 mt-1 fs_14'>
                                        Posted on {" "}{EpochTimeToDateTime(productDetails?.data?.data?.createdAt).date}{" "} at {EpochTimeToDateTime(productDetails?.data?.data?.createdAt).time}
                                    </p>
                                </div>
                            </div>
                            {/* <div className="auth_right">
                                <Link to={`/chat`} className='auth_icon fs_16'>
                                    <PiChatsCircleFill />
                                </Link>
                                <Link to={`/`} className='auth_icon fs_16'>
                                    <GoStarFill />
                                </Link>
                                <Dropdown>
                                    <Dropdown.Toggle size="sm" id="dropdown-basic" className='auth_icon'>
                                        <BsThreeDots />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Save Post</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Report Post</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> */}
                        </div>
                        {console.log(productDetails?.data?.data)}
                        <div className='auth_detail mt-4'>
                            <h6>Product Details:</h6>
                            <p dangerouslySetInnerHTML={{ __html: productDetails?.data?.data?.description }}></p>
                        </div>
                        <div className='auth_cat mt-4'>
                            <strong>Category :</strong>
                            <span className="proCat fw-medium badge badge-success">{productDetails?.data?.data?.category?.category_name}</span>
                        </div>
                        {userSliceData._id === productDetails?.data?.data?.user._id ?
                            (<div className='d-flex gap-2 mt-3'>
                                <button className='btn btn-secondary btn-lg py-2 px-3 fs_16 ' onClick={() => navigate(`/marketplace/${productId}/edit`)}>
                                    Edit
                                </button>
                                <button className='btn btn-primary btn-lg py-2 px-3 fs_16 ' onClick={() => setDeleteProdModal(true)}>
                                    Delete
                                </button>
                            </div>) :
                            (<div className='d-flex gap-2 mt-4'>
                                {productDetails?.data?.data?.can_buy === 1 &&
                                    (<button className='btn btn-primary btn-lg py-2 px-3 fs_16' onClick={() => setBuyModal(true)}>
                                        Buy Now
                                    </button>)
                                }
                                <button className='btn btn-enquiry btn-lg py-2 px-3 fs_16' onClick={() => navigate("/chat")}>
                                    Enquiry Now
                                </button>
                            </div>)
                        }
                    </div>
                    <div className='col-12'>
                        <div className='similar_box'>
                            <h5 className='fw-bold mb-3'>Similar Products</h5>
                            {
                                productsList?.isLoading && (
                                    <div className="col-12">
                                        <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                    </div>
                                )
                            }
                            {
                                productsList?.isError && (
                                    <div className="col-12">
                                        <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong !!</h5>
                                    </div>
                                )
                            }
                            {similerProductList?.length === 0 && (
                                <div className="col-12">
                                    <h5 className="text-center mb-0 py-5 fs_18">No Similar Product Found !!</h5>
                                </div>
                            )}
                            {(productsList.isLoading === false && productsList.isError === false) && (
                                similerProductList.length > 4 ? (
                                    <Slider {...similerSliderOptions}>
                                        {
                                            similerProductList?.map((elem, i) => {
                                                return (<ProductCard data={elem} key={i} />)
                                            })
                                        }
                                    </Slider>
                                ) : (
                                    <div className='row'>
                                        {
                                            similerProductList?.map((elem, i) => {
                                                return (<div className='col-md-3' key={i}><ProductCard data={elem} /></div>)
                                            })
                                        }
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* CONFIRM DELETE REQ MODAL */}
            <Modal centered size="sm" show={deleteProdModal} onHide={() => setDeleteProdModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to delete this product?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setDeleteProdModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={deleteProductModal} className="mx-2">
                                Delete
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* confirm buy product modal */}
            <Modal centered size="sm" show={buyModal} onHide={() => setBuyModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to buy? Your wallet ballence will be R{Number(totalBallence) - Number(productDetails?.data?.data?.selling_price)}</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setBuyModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={buyProductWallet} className="mx-2">
                                Yes
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProductDetails
