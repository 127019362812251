import React, { useState } from 'react'
import "./UserCard.css";
import { defaultUserCover, defaultUserDp } from '../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAcceptJoinRequestMutation, useInviteFollowerMutation, useRemovePageMemberMutation } from '../../store/api/pageApi';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { socket } from '../../methods/Socket';

const PageUserCard = ({ type, data, pageDetails }) => {

   const navigate = useNavigate()
   // get details who loggedin
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })



   const param = useParams()
   const PAGEID = param.pageId;

   // to accept the pending member who have requested to your page
   const [acceptMember] = useAcceptJoinRequestMutation()
   const acceptMemberFunc = () => {
      let obj = {
         page_id: PAGEID,
         members: {
            user_id: data?.user?._id,
            status: "active",
            role: "member"
         }
      }
      acceptMember(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong!!!")
         }
      })
   }

   // to remove a member from your page/page request
   const [removeFollowerModal, setRemoveFollowerModal] = useState(false)
   const [removePageFollower] = useRemovePageMemberMutation()
   const removePageMemberFunc = () => {
      setRemoveFollowerModal(true)
   }
   const removePageMemberModal = () => {
      let obj = {
         page_id: PAGEID,
         members: {
            user_id: data?.user?._id,
            is_remove: true
         }
      }
      removePageFollower(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setRemoveFollowerModal(false)
   }

   // to invite a suggested follower
   const [inviteFollower] = useInviteFollowerMutation()
   const inviteFollowerFunc = () => {
      inviteFollower({ userId: data._id, pageId: PAGEID }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationPage", {
               sender_id: userSliceData._id,
               receiver_id: data._id,
               meta_data: PAGEID,
               meta_type: "Page",
               notification_for: "page_invite",
            });
         } else {
            toast.error("Something went wrong")
         }
      })
   }

   // card for who already follower
   if (type === "PageFollower") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.user?.hasOwnProperty("cover_picture") ? data?.user?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.user?.hasOwnProperty("profile_picture") ? data?.user?.profile_picture[0]?.image_url : defaultUserDp} alt="" />
                  </figure>
                  <Link to={`/profile/${data?.user?._id}/timeline`} title="">
                     {data?.user?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        {data.role !== "admin" ? <button className="btn btn-light w-100" onClick={removePageMemberFunc}>
                           Remove
                        </button> : <button className="btn btn-light w-100 bg-red" onClick={() => navigate(`/profile/${data?.user?._id}/timeline`)}>
                           Admin
                        </button>}
                     </li>
                  </ul>
               </div>
            </div>
            {/* CONFIRM REMOVE USER MODAL */}
            <Modal centered size="sm" show={removeFollowerModal} onHide={() => setRemoveFollowerModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to remove this Follower?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setRemoveFollowerModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={removePageMemberModal} className="mx-2">
                           Remove
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }
   // card for who age pending members
   else if (type === "PagePendingMember") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.user?.hasOwnProperty("cover_picture") ? data?.user?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.user?.hasOwnProperty("profile_picture") ? data?.user?.profile_picture[0]?.image_url : defaultUserDp} alt="" />
                  </figure>
                  <Link to={`/profile/${data?.user?._id}/timeline`} title="">
                     {data?.user?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist" onClick={removePageMemberFunc}>
                        <Link href="#" title="Delete">
                           <i className="fa fa-trash"></i>
                        </Link>
                     </li>
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={acceptMemberFunc}>
                           Accept
                        </button>
                     </li>
                  </ul>
               </div>
            </div>
            {/* CONFIRM REMOVE USER MODAL */}
            <Modal centered size="sm" show={removeFollowerModal} onHide={() => setRemoveFollowerModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to Remove this member?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setRemoveFollowerModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={removePageMemberModal} className="mx-2">
                           Remove
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }
   // card for suggested follower
   else if (type === "PageSuggestedFollower") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.hasOwnProperty("profile_picture") ? data?.profile_picture[0]?.image_url : defaultUserDp} alt="" />
                  </figure>
                  <Link to={`/profile/${data._id}/timeline`} title="">
                     {data?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     {/* <li className="add-tofrndlist">
                        <Link href="#" title="Delete">
                           <i className="fa fa-trash"></i>
                        </Link>
                     </li> */}
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={inviteFollowerFunc}>
                           Invite
                        </button>
                     </li>
                  </ul>
                  {/* <Dropdown
                       menu={{ items: suggestedDropdownLinks }}
                       placement="bottomRight"
                       trigger={["click"]}
                    >
                       <AntButton
                          type="link"
                          size="small"
                          shape="circle"
                          icon={<BsThreeDots />}
                       />
                    </Dropdown> */}
               </div>
            </div>

            {/* CONFIRM BLOCK MODAL */}
            {/* <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
                 <Modal.Body>
                    <div className="text-center">
                       <h5 className="mb-3">Are you sure, you want to block this contact?</h5>
                       <div className="text-center">
                          <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                             Cancel
                          </Button>
                          <Button variant="danger" onClick={blockUserModal} className="mx-2">
                             Block user
                          </Button>
                       </div>
                    </div>
                 </Modal.Body>
              </Modal> */}

            {/* CONFIRM REPORT MODAL */}
            {/* <Modal centered size="sm" show={reportModal} onHide={() => setBlockModal(false)}>
                 <Modal.Body>
                    <div className="text-center">
                       <h5 className="mb-3">Are you sure, you want to report this contact?</h5>
                       <div className="text-center">
                          <Button variant="secondary" onClick={() => setReportModal(false)} className="mx-2">
                             Cancel
                          </Button>
                          <Button variant="danger" onClick={reportUserModal} className="mx-2">
                             Report user
                          </Button>
                       </div>
                    </div>
                 </Modal.Body>
              </Modal> */}
         </>
      );
   }
}

export default PageUserCard;
