import React, { useEffect, useState } from "react";
import ProfileLayout from "../ProfileLayout";
import {
  Advertisement,
  SideBar,
  ProfileComplitation,
  CreatePost,
  DotLoading,
  defaultUserDp,
} from "../../../components/components";
import { useGetsingleUserTimelineQuery } from "../../../store/api/postApi";
import Post1 from "../../../components/post/post1";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetPostByIdQuery } from "../../../store/api/otherUserApi";

const Timeline = () => {
  const { userId } = useParams();

  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [lastPage, setLastPage] = useState(1); // storing current page number

  const singleUserTimeline = useGetsingleUserTimelineQuery(currPage);
  let postData = singleUserTimeline.data?.data;

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  const handleInfiniteScroll = () => {
    const { scrollTop, offsetHeight } = document.documentElement;

    if (window.innerHeight + scrollTop !== offsetHeight) {
      // This will be triggered after hitting the last element.
      // API call here while implementing pagination.
      if (currPage < lastPage) {
        setCurrPage(currPage + 1)
      }
    }
  }

  useEffect(() => {
    if (singleUserTimeline.status === "fulfilled") {
      setLastPage(singleUserTimeline.data.last_page)
      window.addEventListener('scroll', handleInfiniteScroll);
      return () => {
        window.removeEventListener('scroll', handleInfiniteScroll);
      };
    }
  }, [handleInfiniteScroll])

  // other user post section
  const userParam = {
    id: userId
  }

  const otherUserPost = useGetPostByIdQuery(userParam)

  if (singleUserTimeline.isLoading) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Loading...</h4>
        </div>
      </>
    );
  }

  if (singleUserTimeline.isError) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Someting Went Wrong!!</h4>
        </div>
      </>
    );
  }

  return (
    <>
      <ProfileLayout handleDisplay="none">
        <div className="pt-4">
          <div className="row">
            <div className="col-md-3">
              <SideBar className={"left"}>
                {userId === userSliceData?._id && <ProfileComplitation />}
                <Advertisement />
              </SideBar>
            </div>
            <div className="col-md-9">
              {userId === userSliceData?._id && <CreatePost />}
              {userId === userSliceData?._id ? <div className="feedSec">
                {postData?.map((el, i) => {
                  let modifyObject;
                  if (el.posted_by._id === userSliceData._id) {
                    modifyObject = {
                      ...el,
                      posted_by: {
                        fullName: userSliceData?.fullName,
                        profile_picture: userSliceData.profile_picture !== undefined ? (
                          [...userSliceData?.profile_picture]
                        ) : (
                          [
                            {
                              image_url: defaultUserDp,
                              mime_type: "image/png",
                              size: 6788755
                            }
                          ]
                        ),
                        _id: userSliceData?._id
                      }
                    }
                  } else {
                    modifyObject = el;
                  }
                  return <Post1 data={modifyObject} index={i} key={i} caption={el.text} />
                })}
                <div className="d-flex justify-content-center">
                  {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                </div>
              </div>
                :
                <div className="feedSec">
                  <div className="d-flex justify-content-center">
                    {otherUserPost?.data?.data?.length === 0 ?
                      "No post found !!" :
                      <div className="col-md-12">
                        {otherUserPost?.data?.data?.map((el, i) => {
                          return <Post1 data={el} index={i} key={i} caption={el.text} />
                        })}
                        <div className="d-flex justify-content-center">
                          {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                        </div>
                      </div>
                    }
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </ProfileLayout>
    </>
  );
};

export default Timeline;
