import React, { useState } from 'react'
import './UserItem.css';
import { Button, Modal } from 'react-bootstrap';
import { useTransferBallenceMutation, useWalletHistoryQuery } from '../../../../../store/api/walletApi';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const UserItem = ({ data, modalController }) => {
  const [transferMoneyModal, setTransferMoneyModal] = useState(false)
  const [amount, setAmount] = useState("")
  const [transferBalence] = useTransferBallenceMutation();
  const historyWallet = useWalletHistoryQuery()
  const walletHistory = historyWallet?.data?.data;
  const user = useSelector((store) => {
    return store.userSlice;
  })

  const handleTransferMoney = () => {
    let details = {
      amount: amount,
      description: "Transfer Balence",
      meta_type: "User",
      meta_data: user.userSliceData._id,
      receiver_id: data._id
    }
    if ((amount > 0) && (amount <= walletHistory.totalBalance)) {
      console.log("Money Transfered!!")
      transferBalence(details).then((res) => {
        toast.success(res?.data?.message)
        setTransferMoneyModal(false);
        modalController(false)
      })
    } else if (+(amount) === 0) {
      toast.error("Amount is too low!!")
    } else if (amount > walletHistory.totalBalance) {
      toast.error("Insufficient Balence!!")
    }
  }

  const clickOnUser = () => {
    modalController(true);
    setTransferMoneyModal(true);
  }

  return (
    <>
      <div className='chatUserItem' onClick={clickOnUser}>
        <div className='chatUserItem_img'>
          <img src={data?.profile_picture[0]?.image_url} alt='...' />
        </div>
        <div className='chatUserItem_info'>
          <div className='chatUserItem_name'>{data?.fullName}</div>
          <div className='chatUserItem_lastMsg'>Send Money!!</div>
        </div>
      </div >
      {/* add money modal */}
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter col-md-12" centered show={transferMoneyModal} onHide={() => setTransferMoneyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Send money to <span className='chatUserItem_img'>
              <img src={data?.profile_picture[0]?.image_url} width={"30px"} alt='...' />
            </span> {data.fullName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Please enter amount</h6>
          <input type="text" className='form-control' onChange={(e) => setAmount(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <div className='add-money-btn-container'>
            <Button className='col-md-2 add-money-btn' onClick={handleTransferMoney}>Transfer</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default UserItem;