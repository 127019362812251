import React, { useReducer } from 'react'
import CommentItem from './commentItem/comment'
import toast from 'react-hot-toast';
import { useAddCommentMutation } from '../../../store/api/commentApi';
import { Button } from 'react-bootstrap';
import { AiOutlineSend } from "react-icons/ai";
import './comments.css';
import { useGetCommentsQuery } from '../../../store/api/commentApi';
import { useSelector } from 'react-redux';
import { useGetConnectionByIdQuery } from '../../../store/api/userApi';
import { MentionsInput, Mention } from 'react-mentions';
import { extractUserIds } from '../../../methods/extractUserId';
import { socket } from '../../../methods/Socket';

const commentState = {
    preview: false,
    text: "",
    images: [],
    videos: [],
};

const CommentReducer = (state, action) => {
    switch (action.type) {
        case "SET_PREVIEW":
            return { ...state, preview: action.payload };
        case "SET_TEXT":
            return { ...state, text: action.payload };
        case "SET_IMAGES":
            return { ...state, images: action.payload };
        case "RESET_REDUCER":
            return {
                preview: false,
                text: "",
                images: [],
                videos: [],
            };
        default:
            return state;
    }
};

const Comments = ({ data, postIndex, postId, className, posted_by_id }) => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    // GET COMMENT AGAINST POST ID
    const getComment = useGetCommentsQuery(postId)
    const getCommentData = getComment?.data?.data;
    const [comment, commentDispatch] = useReducer(CommentReducer, commentState);
    const [createComment] = useAddCommentMutation();

    // HANDLEING COMMENT INPUT
    const handleComment = (event) => {
        commentDispatch({ type: "SET_TEXT", payload: event.target.value });
    };

    // ADDING NEW COMMENT
    const submitComment = () => {
        if (comment.text !== '') {
            const arg = {
                postIndex,
                post_id: data._id,
                comment_text: comment.text,
                comment_images: comment.images,
                mention_user_ids: extractUserIds(comment.text),
            }
            createComment(arg).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success('Comment Added !!')
                    commentDispatch({ type: "RESET_REDUCER" });
                    socket.emit("sendNotificationPostOtherDetailsUpdate", {
                        sender_id: userSliceData._id,
                        meta_data: res?.data?.data?.post,
                        meta_type: "Post",
                        notification_for: "post_comment",
                        update_meta_type_for: "Post",
                        posted_by: posted_by_id
                    });

                } else {
                    toast.error('Somthing went wrong !!')
                }
            })
        } else {
            toast.error('Please add text to comment');
        }
    }

    const otherUserParam = {
        id: userSliceData._id
    }
    const MyConnection = useGetConnectionByIdQuery(otherUserParam);
    const formattedUser = MyConnection?.data?.connections?.map(({ _id, fullName }) => ({ id: _id, display: fullName }));
    const displayTransform = (id, display) => {
        return `@${display}`;
    }

    if (!userSliceData?._id) {
        return (
            <div className={className !== undefined ? `post_comment ${className}` : 'post_comment'}>
                <h5 className="commentTitle">Comments</h5>
                {getCommentData?.length > 0 ? (
                    <div className={`post_comment_list`}>
                        {getCommentData?.map((elem, index) => {
                            return <CommentItem postIndex={postIndex} data={elem} key={index} />
                        })}
                    </div>
                ) : (
                    <div className={`post_comment_list mb-3 mt-2`}>
                        <p className="mb-0">No Comment</p>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={className !== undefined ? `post_comment ${className}` : 'post_comment'}>
            <div className="write_comment mb-3">
                <MentionsInput placeholder='Write Comment here...' value={comment.text} onChange={handleComment} className='mentionList' style={{ height: '40px' }} >
                    <Mention trigger="@" data={formattedUser} displayTransform={displayTransform} />
                </MentionsInput>
                <Button onClick={submitComment}><AiOutlineSend /></Button>
            </div>

            <h5 className="commentTitle">Comments</h5>
            {getCommentData?.length > 0 ? (
                <div className={`post_comment_list`}>
                    {getCommentData?.map((elem, index) => {
                        return <CommentItem postIndex={postIndex} data={elem} key={index} />
                    })}
                </div>
            ) : (
                <div className={`post_comment_list mb-3 mt-2`}>
                    <p className="mb-0">No Comment</p>
                </div>
            )}
        </div>
    )
}

export default Comments;