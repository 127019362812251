import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminCategoryApi = createApi({
    reducerPath: "adminCategoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminCategory"],
    endpoints: (builder) => ({
        getCategoryList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/category/list",
                    method: "GET",
                    params: {
                        page: data.page,
                        per_page: data.per_page
                    },
                }
            },
            providesTags: ["AdminCategory"],
        }),
        getCategory: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/category/details",
                    method: "GET",
                    params: {
                        category_id: data,
                    },
                }
            },
            providesTags: ["AdminCategory"],
        }),
        createCategory: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/category/create`,
                    method: "POST",
                    body: {
                        "category_name": data.category_name,
                        "search_tag": data.search_tag,
                        "meta_keyword": data.meta_keyword,
                        "meta_desc": data.meta_desc,
                        "alias_url": data.alias_url,
                        "status": 1
                    }
                };
            },
            invalidatesTags: ['adminCategory']
        }),
        updateCategory: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/category/update`,
                    method: "PUT",
                    body: {
                        'category_id' : data.category_id,
                        "category_name": data.category_name,
                        "search_tag": data.search_tag,
                        "meta_keyword": data.meta_keyword,
                        "meta_desc": data.meta_desc,
                        "alias_url": data.alias_url,
                        "status": 1
                    }
                };
            },
            invalidatesTags: ["AdminCategory"],
        }),
        changeStatus: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/category/change-status`,
                    method: "PUT",
                    body: {
                        "category_id": data.category_id,
                        "status": data.status
                    }
                };
            },
            invalidatesTags: ["AdminCategory"],
        }),
    }),
});

export const { 
    useGetCategoryListQuery,
    useGetCategoryQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useChangeStatusMutation
} = adminCategoryApi;