import React, { useEffect, useState } from "react";
import CreatePost from "../../components/widgets/createPost/CreatePost";
import SideBar from "../../components/sideBar/SideBar";
import ProfileWidget from "../../components/widgets/ProfileWidget/ProfileWidget";
import CreateYourPageWidget from "../../components/widgets/createYourPageWidget/CreateYourPageWidget";
import Quote from "../../components/widgets/quoteWidget/Quote";
import BirthdayWidget from "../../components/widgets/birthdayWidget/BirthdayWidget";
import LiveWidget from "../../components/widgets/liveWidget/LiveWidget";
import TopTrending from "../../components/widgets/topTrending/TopTrending";
import GroupWidget from "../../components/widgets/groupWidget/groupWidget";
import { DotLoading, Post } from "../../components/components";
// import { useGetAllUserTimelineQuery } from "../../store/api/postApi";
import { AdminMenu } from "../../components/components";
import { useSelector } from "react-redux";
import { useGetAllCommunityPostListQuery } from "../../store/api/postApi";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

const Community = () => {
  // state to store current page
  const [currPage, setCurrPage] = useState(1);
  // state to store last page
  const [lastPage, setLastPage] = useState(1);
  // All community post api call
  const allCommunityPostList = useGetAllCommunityPostListQuery()
  let postData = allCommunityPostList?.data?.data;
  // get user details from local storage
  const { userSliceData: userInfo } = useSelector((store) => {
    return store.userSlice;
  });
  // handle infinite scroll function
  const handleInfiniteScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if ((scrollTop + clientHeight) === scrollHeight) {
      if (currPage < lastPage) {
        setCurrPage(currPage + 1)
      }
    }
  }
  // to handle the infinite scroll
  useEffect(() => {
    if (allCommunityPostList.status === "fulfilled") {
      setLastPage(allCommunityPostList.data.last_page)
      window.addEventListener('scroll', handleInfiniteScroll);
      return () => {
        window.removeEventListener('scroll', handleInfiniteScroll);
      };
    }
  }, [handleInfiniteScroll])

  // THIS WILL SHOW WHEN LODING THE PAGE
  if (allCommunityPostList.isLoading) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Loading...</h4>
        </div>
      </>
    );
  }

  // IF GET ERROR FROM API WILL SHOW THIS
  if (allCommunityPostList.isError) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Someting Went Wrong!!</h4>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="gap2 gray-bg full_height">
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Community</Breadcrumb.Item>
          </Breadcrumb>
          <div className="row">
            <aside className="col-md-3">
              <SideBar className="left">
                {userInfo.user_type === 1 && <AdminMenu />}
                <ProfileWidget />
                <Quote />
                <BirthdayWidget />
                <CreateYourPageWidget />
              </SideBar>
            </aside>
            <main className="col-md-6">
              {/* create post widget just passing type */}
              <CreatePost type={"community"} />
              <div className="feedSec">
                {postData?.map((el, i) => (
                  <Post data={el} index={i} key={i} caption={el.text} type={"community"} />
                ))}
                <div className="d-flex justify-content-center">
                  {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                </div>
              </div>
            </main>
            <aside className="col-md-3">
              <SideBar className="right">
                <LiveWidget />
                <TopTrending />
                <GroupWidget />
              </SideBar>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default Community;
