import React from "react";
import "./commonHelp.css";
import HelpCard from "../../../components/commonHelpCard/HelpCard";

const CommonHelp = () => {
   const commonHelpArr = new Array(4).fill(0);
   return (
      <div className="gap gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">
                  <div className="help-sec">
                     <div className="pitnik-title helpPitnikTitle">
                        <h1>Common Help</h1>
                        <p>
                           Get Instant answer for the most common questions and
                           learn how to use like a pro.
                        </p>
                     </div>
                     <div className="row">
                        {commonHelpArr.map((el,i) => (
                           <div className="col-lg-3 col-sm-6" key={i}>
                              <HelpCard />
                           </div>
                        ))}

                        {/* <div className="col-lg-3 col-sm-6">
                           <div className="help-list">
                              <h3>General Settings</h3>
                              <ul>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>How to
                                       Create your account
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>post
                                       video on my account?
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>Inviting
                                       friends to join winku
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>How do I
                                       search for my friends?
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>Create
                                       and manage groups
                                    </Link>
                                 </li>
                              </ul>
                              <Link to="#" title="" data-ripple="">
                                 more help
                              </Link>
                           </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-sm-6">
                           <div className="help-list">
                              <h3>Report Guidelines</h3>
                              <ul>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>How to
                                       Create your account
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>post
                                       video on my account?
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>Inviting
                                       friends to join winku
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>How do I
                                       search for my friends?
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>Create
                                       and manage groups
                                    </Link>
                                 </li>
                              </ul>
                              <Link to="#" title="" data-ripple="">
                                 more help
                              </Link>
                           </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-sm-6">
                           <div className="help-list">
                              <h3>Technical Issues</h3>
                              <ul>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>How to
                                       Create your account
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>post
                                       video on my account?
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>Inviting
                                       friends to join winku
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>How do I
                                       search for my friends?
                                    </Link>
                                 </li>
                                 <li>
                                    <Link
                                       to="support-and-help-detail.html"
                                       title=""
                                    >
                                       <i className="ti-direction"></i>Create
                                       and manage groups
                                    </Link>
                                 </li>
                              </ul>
                              <Link to="#" title="" data-ripple="">
                                 more help
                              </Link>
                           </div>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CommonHelp;
