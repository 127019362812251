import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import { Button } from 'react-bootstrap'
import './cropper.css'
import toast from 'react-hot-toast'

export const Crop = ({ image, imagePass, manageCropModal, type }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState('')
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels)
            imagePass(croppedImage)
            manageCropModal(false)
        } catch (e) {
            imagePass(image)
            manageCropModal(false)
            toast.error("You didn't crop anything")
        }
    }, [croppedAreaPixels])

    const onClose = useCallback(() => {
        setCroppedAreaPixels(null)
        manageCropModal(false)
    }, [])

    return (
        <div className='text-center'>
            <h5 className='fw-bold mb-3'>Crop Photo</h5>
            <div className='cropContainer mx-auto'>
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={type === "coverPhoto" ? 12 / 4 : 2 / 2}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                <Button onClick={onClose} color="secondary"> Close </Button>
                <Button onClick={showCroppedImage} color="primary"> Crop </Button>
            </div>
        </div>
    )
}

