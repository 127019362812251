import React, { useState } from 'react'
import WidgetWrapper from '../WidgetWrapper'
import style from './InfoWidget.module.css'
import { useSelector } from 'react-redux'
import { selectTheme } from '../../../store/slice/themeSlice'
import { Link, useParams } from 'react-router-dom'
import { useGetGroupTimelineQuery } from '../../../store/api/postApi'
import { useGetSingleGroupQuery } from '../../../store/api/groupApi'

const InfoWidget = ({ admin, title, description, totalMember, postlength }) => {
    const { theme } = useSelector(selectTheme)
    const [currPage, setCurrPage] = useState(1); // storing current page number
    // get id from param
    const params = useParams();
    const GROUPID = params.groupId;

    // get single Event Data
    const singleGroup = useGetSingleGroupQuery(GROUPID);

    const paramObj = {
        page: currPage,
        meta_data: GROUPID,
        meta_type: "Group"
    }
    const AllPostsInGroup = useGetGroupTimelineQuery(paramObj)

    if (title === "Page Info") {
        var postlengthData = postlength
    } else if (title === "Group Info") {
        var postlengthData = postlength

    }
    return (
        <WidgetWrapper title={title}>
            <ul className={style.groupInfoWidget}>
                {
                    admin ? (
                        <li>
                            <div className={style.title}>
                                <i className="fa fa-user" style={theme === 'light-theme' ? { backgroundColor: 'var(--bs-gray-200)', color: 'var(--bs-gray-800)' } : { backgroundColor: 'var(--bs-gray-500)', color: 'var(--bs-gray-200)' }}></i><span>Admin</span>
                            </div>
                            <Link to="/">{admin}</Link>
                        </li>
                    ) : ''
                }
                {
                    description ? (
                        <li>
                            <div>
                                <div className={style.title}>
                                    <i className="fa fa-address-card" style={theme === 'light-theme' ? { backgroundColor: 'var(--bs-gray-200)', color: 'var(--bs-gray-800)' } : { backgroundColor: 'var(--bs-gray-500)', color: 'var(--bs-gray-200)' }}></i><span>Description</span>
                                </div>
                                <p className="mb-0 mt-1">{description.substr(0, 150) + "..."}</p>
                            </div>
                        </li>
                    ) : ''
                }
                {
                    totalMember ? (
                        <li>
                            <div className={style.title}>
                                <i className="fa fa-users" style={theme === 'light-theme' ? { backgroundColor: 'var(--bs-gray-200)', color: 'var(--bs-gray-800)' } : { backgroundColor: 'var(--bs-gray-500)', color: 'var(--bs-gray-200)' }}></i><span>Members</span>
                            </div>
                            {totalMember}
                        </li>
                    ) : ''
                }
                <li>
                    <div className={style.title}>
                        <i className="fa fa-thumbs-up" style={theme === 'light-theme' ? { backgroundColor: 'var(--bs-gray-200)', color: 'var(--bs-gray-800)' } : { backgroundColor: 'var(--bs-gray-500)', color: 'var(--bs-gray-200)' }}></i><span>Total Posts</span>
                    </div>
                    {/* {AllPostsInGroup?.data?.data?.length || 0} */}
                    {postlengthData || 0}

                </li>
            </ul>
        </WidgetWrapper>
    )
}

export default InfoWidget