import React, { useRef, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import style from "./Employment.module.css";
import { useNavigate } from 'react-router-dom';
import { useReducer } from 'react';
import toast from 'react-hot-toast';
import { useGetCategoryListQuery } from '../../store/api/categoryMaster';
import { useMyPagesListForJobQuery } from '../../store/api/pageApi';
import { useCreateJobMutation } from '../../store/api/jobApi';
import { useMyGroupListForJobQuery } from '../../store/api/groupApi';
import Select from 'react-select'
import ReactQuill from 'react-quill';

const EmploymentCreate = () => {
    const [grList, setGrList] = useState(true)
    const [pgList, setPgList] = useState(true)
    const categoryList = useGetCategoryListQuery()
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const MyPageList = useMyPagesListForJobQuery()
    const MyGroupList = useMyGroupListForJobQuery()
    const [createJob] = useCreateJobMutation()

    //initial state for create page form
    const initialState = {
        title: "",
        category: "",
        location: "",
        email: "",
        description: "",
        salary: "",
        job_post_url: "",
        pages: [],
        groups: []
    };

    //reducer for create page form
    const createJobReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_JOB":
                return { ...state, [payload.name]: payload.data };
            case "SET_PAGE":
                return { ...state, pages: payload };
            case "SET_GROUP":
                return { ...state, groups: payload };
            case "SET_LOGNDESC":
                return { ...state, description: payload.data };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(createJobReducer, initialState);
    const handleChange = (e) => {
        dispatch({
            type: "SET_JOB",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };

    // change page value
    const pageListDropData = MyPageList?.data?.data.map((elem, i) => {
        return {
            value: elem._id,
            label: elem.title
        }
    })

    const handleChangePage = (data) => {
        dispatch({
            type: "SET_PAGE",
            payload: data.map((elem, i) => elem.value),
        });
    };
    const groupListDropData = MyGroupList?.data?.data.map((elem, i) => {
        return {
            value: elem._id,
            label: elem.title
        }
    })
    const handleChangeGroup = (data) => {
        dispatch({
            type: "SET_GROUP",
            payload: data.map((elem, i) => elem.value),
        });
    };
    const handleLongDesc = (data) => {
        dispatch({
            type: "SET_LOGNDESC",
            payload: {
                data: data,
            },
        });
    };

    // create page form submit
    const submitForm = async (e) => {
        e.preventDefault();
        if (e.currentTarget.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            // when device online it will work
            if (navigator.onLine) {
                let obj = {
                    title: state.title,
                    category_id: state.category,
                    description: state.description,
                    job_location: state.location,
                    salary: state.salary,
                    email: state.email,
                    pages: state.pages,
                    groups: state.groups,
                    job_post_url: ""
                }

                createJob(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                        // navigate("/employment")
                        navigate(-1)
                    } else {
                        toast.error("Something went wrong!!!")
                    }
                })
            }

            // when device not online it will work
            else {
                toast.error("Please turn on internet!!!");
            }
        }
    };

    return (
        <div className="gap2 gray-bg full_height">
            <div className="container">
                <div className="row">
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8"}>
                        <Card className={`${style.central_meta}`}>
                            <Card.Body className={`${style.card_body}`}>
                                <Card.Title className={`${style.card_title} mb-3 fs-4`} >
                                    Create A New Job
                                </Card.Title>

                                <Form noValidate validated={validated} method="post" onSubmit={submitForm}>
                                    <Row>
                                        {/* job title */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job title*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your job title"
                                                value={state.title}
                                                onChange={handleChange}
                                                name="title"
                                            />
                                            {/* <Form.Control.Feedback type="invalid">Please provide job title</Form.Control.Feedback> */}
                                        </Form.Group>

                                        {/* job category */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job category*</Form.Label>
                                            <Form.Select required aria-label="Default select example" value={state.category} name="category" onChange={handleChange}>
                                                <option value={""}>Select category</option>
                                                {categoryList?.data?.data.map((el) => (
                                                    <option key={el._id} value={el._id}>{el.category_name}</option>
                                                ))}
                                            </Form.Select>
                                            {/* <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback> */}
                                        </Form.Group>

                                        {/* job description */}
                                        <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                            <Form.Label>Job description*</Form.Label>
                                            <ReactQuill
                                                // style={{ border: "1px solid red" }}
                                                className={style.jobQuill}
                                                value={state.description}
                                                onChange={handleLongDesc}
                                                modules={{
                                                    toolbar: [
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        ['blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        [{ 'header': [1, 2, 3, 4, 5, 6, 7] }],
                                                    ],
                                                }}
                                                formats={[
                                                    "header",
                                                    "font",
                                                    "size",
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strike",
                                                    "blockquote",
                                                    "list",
                                                    "bullet",
                                                    "indent",
                                                ]}
                                                placeholder="Give a description"
                                            />
                                            <Form.Control.Feedback type="invalid">Please add a description</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* job location */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job Location*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your job location"
                                                value={state.location}
                                                onChange={handleChange}
                                                name="location"
                                            />
                                            {/* <Form.Control.Feedback type="invalid">Please provide job title</Form.Control.Feedback> */}
                                        </Form.Group>

                                        {/* contact mail */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Contact email*</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter your contact email"
                                                value={state.email}
                                                onChange={handleChange}
                                                name="email" />
                                            {/* <Form.Control.Feedback type="invalid">Please provide contact email</Form.Control.Feedback> */}
                                        </Form.Group>

                                        {/* page select */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Select page to post this job</Form.Label>
                                            <Select options={pageListDropData} isMulti onChange={handleChangePage} />
                                            <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* group select */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Select group to post this job</Form.Label>
                                            <Select options={groupListDropData} isMulti onChange={handleChangeGroup} />
                                            <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* job salary */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job salary*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter job salary"
                                                value={state.salary}
                                                onChange={handleChange}
                                                name="salary"
                                            />
                                            {/* <Form.Control.Feedback type="invalid">Please provide job title</Form.Control.Feedback> */}
                                        </Form.Group>

                                    </Row>
                                    <div className="mt-2 text-center">
                                        <Button className="btn btn-primary" type="submit">Create Job</Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmploymentCreate
