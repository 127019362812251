import React from "react";
import ProfileLayout from "../ProfileLayout";
import { UserCard } from "../../../components/components";
import { useGetfollowerListQuery } from "../../../store/api/userApi";

const Follower = () => {
    // get follower following list
    const followerFollowingList = useGetfollowerListQuery()

    const followerList = followerFollowingList?.data?.followers;

    return (
        <ProfileLayout>
            <div className="central-meta">
                <h3 className="fs_18 mb-3">Followers</h3>
                <div className="row gx-4">
                {
                  followerFollowingList.isLoading && (
                     <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                     </div>
                  )
               }
               
               {
                  followerFollowingList.isError && (
                     <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                     </div>
                  )
               }
                    
                    {followerList?.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">No Follower Found !!!</h5>
                    </div>}
                    {followerList?.map((elem, index) => {
                        return (
                            <div className="col-lg-2 col-md-4 col-6" key={index}>
                                <UserCard data={elem} fullData={elem} type={"followerFollowing"} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </ProfileLayout>
    );
};

export default Follower;
