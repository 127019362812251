import { Dropdown } from 'antd'
import React from 'react'
import { Button } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { DashboardMenuLink, FilterWrapper, MediaCard } from '../../../../../components/components'
import { useGetAlbumQuery, useGetMyEventAlbumDataQuery } from '../../../../../store/api/albumApi'
import EventProfileLayout from '../../../EventProfileLayout'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useGetAlbumEventOthersQuery, useGetAlbumEventQuery, useGetSingleEventQuery } from '../../../../../store/api/eventApi'
import { CiImageOn } from 'react-icons/ci'
import { IoVideocamOutline } from 'react-icons/io5'

const items = [
  {
    label: <div onClick={() => { }}>Show Friends Public</div>,
    key: "0",
  },
  {
    label: <div onClick={() => { }}>Show Friends Private</div>,
    key: "1",
  },
  {
    label: <div onClick={() => { }}>Mute Notifications</div>,
    key: "2",
  }
]

const CoverPictures = () => {
  // get id from param
  const params = useParams();
  const EVENTID = params.eventId;
  // get single Event Data
  const singleEvent = useGetSingleEventQuery(EVENTID);
  let eventWholeData = singleEvent?.data?.data;

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  const AlbumParamsOthers = {
    page: 1,
    per_page: 100,
    module: "Album",
    user_id: eventWholeData?.user?._id,
    meta_type: "Event",
    meta_data: EVENTID
  }
  const AlbumDataOthers = useGetAlbumEventOthersQuery(AlbumParamsOthers)

  const AlbumData = useGetAlbumEventQuery(EVENTID)

  const CoverImages = eventWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

  const CoverImagesData = CoverImages?.filter(function (el) {
    return el.name === "cover_photo";
  })[0]?.gallery;

  if (AlbumData.isLoading) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Loading...</h4>
        </div>
      </>
    );
  }

  if (AlbumData.isError) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Someting Went Wrong!!</h4>
        </div>
      </>
    );
  }

  return (
    <EventProfileLayout data={eventWholeData}>
      <FilterWrapper>
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="DashboardMenuLinks">
              <DashboardMenuLink url={`/event/${EVENTID}/media`} title="Images" noCount />
              <DashboardMenuLink url={`/event/${EVENTID}/media/video`} title="Videos" noCount />
            </div>
          </div>
          {/* <div className="col-lg-5">
            <div className="gx-2 justify-content-md-end row">
              <div className="col-lg-7 col-md-7 col-sm-7">
                <form method="post">
                  <input
                    type="text"
                    placeholder="Search.."
                    className="form-control"
                  />
                </form>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="select-options">
                  <select className="form-select">
                    <option>See All</option>
                    <option>A to Z</option>
                    <option>Newest</option>
                    <option>Oldest</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1">
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <Button className="filterWrapDropBtn">
                    <BsThreeDotsVertical />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div> */}
        </div>
      </FilterWrapper>
      <div className='central-meta'>
        <h3 className="fs_18 mb-3">Cover Picture</h3>
        <div className='row gx-4'>
          {CoverImagesData?.map((elem, index) => {
            return (
              <div className="col-lg-3 col-md-3 col-6" key={index}>
                <MediaCard imageData={elem} type={"coverImage"} />
              </div>
            );
          })}
        </div>
      </div>
    </EventProfileLayout>
  )
}

export default CoverPictures
