import React, { useState } from 'react'
import { useGetReactsQuery } from '../../../store/api/reactMaster';
import { useAddReactOnCommentMutation, useDeleteReactOnCommentMutation, useUpdateReactOnCommentMutation } from '../../../store/api/reactApi';
import { useSelector } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
import { Image } from 'react-bootstrap';
import { defaultUserDp } from '../../components';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

const ReactOnComment = ({ commentId, reactListOnComment }) => {
    const { data: reactList } = useGetReactsQuery();
    const [deleteReact] = useDeleteReactOnCommentMutation();
    const [addReact] = useAddReactOnCommentMutation();
    const [updateReact] = useUpdateReactOnCommentMutation();

    const USER = useSelector((store) => {
        return store.userSlice.userSliceData;
    });

    let OwnReact = reactListOnComment?.filter((elem) => {
        return elem.user._id === USER._id;
    })

    const [loading, setLoading] = useState(false)

    const addReactOnComment = (elem) => {
        let obj = {
            reacts: {
                _id: elem._id,
                title: elem.title,
                image: elem.image,
                status: 1
            },
            comment_id: commentId
        }
        setLoading(true)

        if (loading === false) {
            if (OwnReact.length > 0) {
                // UPDATE REACT
                updateReact(obj).then((res) => {
                    setLoading(false)
                })
            } else {
                // ADD REACT
                addReact(obj).then((res) => {
                    setLoading(false)
                })
            }
        }
    }

    const toggleReact = (elem) => {
        // REMOVE REACT IF HAS OWN REACT IN THE LIST ELSE ADD DEFAULT REACT
        setLoading(true)
        let obj = {
            reacts: {
                _id: elem._id,
                title: elem.title,
                image: elem.image,
                status: 1
            },
            comment_id: commentId
        }
        if (loading === false) {
            if (OwnReact.length > 0) {
                // REMOVEING REACT           
                deleteReact({ comment_id: commentId }).then((res) => {
                    setLoading(false)
                })
            } else {
                // ADDING DEFAULT REACT
                addReact(obj).then((res) => {
                    setLoading(false)
                })
            }
        }
    }

    // when user is unauthenticated
    if (!USER?._id) {
        return (
            <div className="reactionPost">
                <div className={reactListOnComment?.length > 0 ? 'post_react' : 'post_react me-0'}>
                    <button onClick={() => toast.error("Please login !!!")}>

                        <span>
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <g id="Layer">
                                    <path fill='none' stroke='currentColor' fillRule="evenodd" d="m11.7 9.5h-5.8q-0.2 0-0.3 0.1-0.1 0-0.3 0.1-0.1 0-0.2 0.1-0.1 0.1-0.2 0.2-0.3 0.3-0.4 0.7 0 0.4 0.1 0.8 0.2 0.4 0.6 0.7 0.4 0.2 0.8 0.2h0.3q-0.3 0-0.5 0-0.3 0.1-0.4 0.3-0.2 0.2-0.3 0.4-0.1 0.3-0.1 0.5 0 0.2 0.1 0.5 0.1 0.2 0.2 0.4 0.2 0.1 0.4 0.2 0.2 0.1 0.5 0.2-0.2 0.1-0.3 0.2-0.1 0.2-0.2 0.3 0 0.2-0.1 0.4 0 0.2 0.1 0.3 0 0.2 0.1 0.4 0.1 0.1 0.2 0.3 0.2 0.1 0.3 0.2 0.2 0 0.4 0.1-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.2 0 0.1 0 0.2 0 0.1 0.1 0.3 0 0.2 0.1 0.4 0.2 0.2 0.3 0.3 0.2 0.1 0.4 0.2 0.3 0.1 0.5 0.1h3.6q0.2 0 0.3-0.1 0.2 0 0.4 0 0.2 0 0.3 0 0.2-0.1 0.4-0.1l2.2-0.7h3c1.6 0 2-7.3 0-7.3h-0.8c-0.2 0-0.2-0.3-0.6-0.7-0.6-0.6-1.3-1.4-1.7-1.9q-0.4-0.3-0.8-0.7-0.4-0.4-0.7-0.9-0.3-0.4-0.6-0.9-0.3-0.4-0.6-0.9c-0.3-0.8-0.4-1.2-1.2-1.2q-0.2 0-0.4 0.2-0.2 0.1-0.3 0.2-0.2 0.2-0.2 0.4-0.1 0.3-0.1 0.5 0.1 0.6 0.2 1.2 0.1 0.5 0.2 0.9 0.2 0.4 0.4 0.9 0.2 0.4 0.4 0.8 0.2 0.4 0.5 0.8" />
                                </g>
                            </svg>
                        </span>
                        <span>{reactListOnComment?.length || 0}</span>
                    </button>
                    {/* <div className="react-box shadow-lg">
                        <ul>
                            {reactList?.data?.map((elem, i) => {
                                return (
                                    <li data-title={elem.title} key={i}>
                                        <button onClick={() => addReactOnComment(elem)} dangerouslySetInnerHTML={{ __html: elem.image }}></button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div> */}
                </div>
                {
                    reactListOnComment?.length > 0 ? (
                        <div className='postReactList'>
                            <button type="button"><IoIosArrowDown /></button>
                            <div className='postReactListOverlay shadow-lg'>
                                {reactListOnComment?.map((elem, i) => {
                                    return (
                                        <div className='reactListItem' key={i}>
                                            <div className='userImg'>
                                                <Image src={elem.user.profile_picture !== undefined ? elem.user.profile_picture[0].image_url : defaultUserDp} />
                                            </div>
                                            <h5 className='fs_12 fw-bold'><Link to={`/profile/${elem.user._id}/timeline`}>{elem.user.fullName}</Link></h5>
                                            <span className='reactSvgIcon' dangerouslySetInnerHTML={{ __html: elem.reacts.image }}></span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ) : ('')
                }
            </div>
        )
    }

    return (
        <div className="reactionPost">
            <div className={reactListOnComment?.length > 0 ? 'post_react' : 'post_react me-0'}>
                <button className={reactListOnComment?.length > 0 ? 'like hasReact' : 'like'} onClick={() => toggleReact(reactList?.data[0])}>
                    {OwnReact?.length > 0 ? (
                        <span dangerouslySetInnerHTML={{ __html: OwnReact[0]?.reacts?.image }}></span>
                    ) : (
                        <>
                            <span>
                                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <g id="Layer">
                                        <path fill='none' stroke='currentColor' fillRule="evenodd" d="m11.7 9.5h-5.8q-0.2 0-0.3 0.1-0.1 0-0.3 0.1-0.1 0-0.2 0.1-0.1 0.1-0.2 0.2-0.3 0.3-0.4 0.7 0 0.4 0.1 0.8 0.2 0.4 0.6 0.7 0.4 0.2 0.8 0.2h0.3q-0.3 0-0.5 0-0.3 0.1-0.4 0.3-0.2 0.2-0.3 0.4-0.1 0.3-0.1 0.5 0 0.2 0.1 0.5 0.1 0.2 0.2 0.4 0.2 0.1 0.4 0.2 0.2 0.1 0.5 0.2-0.2 0.1-0.3 0.2-0.1 0.2-0.2 0.3 0 0.2-0.1 0.4 0 0.2 0.1 0.3 0 0.2 0.1 0.4 0.1 0.1 0.2 0.3 0.2 0.1 0.3 0.2 0.2 0 0.4 0.1-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.2 0 0.1 0 0.2 0 0.1 0.1 0.3 0 0.2 0.1 0.4 0.2 0.2 0.3 0.3 0.2 0.1 0.4 0.2 0.3 0.1 0.5 0.1h3.6q0.2 0 0.3-0.1 0.2 0 0.4 0 0.2 0 0.3 0 0.2-0.1 0.4-0.1l2.2-0.7h3c1.6 0 2-7.3 0-7.3h-0.8c-0.2 0-0.2-0.3-0.6-0.7-0.6-0.6-1.3-1.4-1.7-1.9q-0.4-0.3-0.8-0.7-0.4-0.4-0.7-0.9-0.3-0.4-0.6-0.9-0.3-0.4-0.6-0.9c-0.3-0.8-0.4-1.2-1.2-1.2q-0.2 0-0.4 0.2-0.2 0.1-0.3 0.2-0.2 0.2-0.2 0.4-0.1 0.3-0.1 0.5 0.1 0.6 0.2 1.2 0.1 0.5 0.2 0.9 0.2 0.4 0.4 0.9 0.2 0.4 0.4 0.8 0.2 0.4 0.5 0.8" />
                                    </g>
                                </svg>
                            </span>
                        </>
                    )}
                    <span>{reactListOnComment?.length || 0}</span>
                </button>
                <div className="react-box shadow-lg">
                    <ul>
                        {reactList?.data?.map((elem, i) => {
                            return (
                                <li data-title={elem.title} key={i}>
                                    <button onClick={() => addReactOnComment(elem)} dangerouslySetInnerHTML={{ __html: elem.image }}></button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            {
                reactListOnComment?.length > 0 ? (
                    <div className='postReactList'>
                        <button type="button"><IoIosArrowDown /></button>
                        <div className='postReactListOverlay shadow-lg'>
                            {reactListOnComment?.map((elem, i) => {
                                return (
                                    <div className='reactListItem' key={i}>
                                        <div className='userImg'>
                                            <Image src={elem.user.profile_picture !== undefined ? elem.user.profile_picture[0].image_url : defaultUserDp} />
                                        </div>
                                        <h5 className='fs_12 fw-bold'><Link to={`/profile/${elem.user._id}/timeline`}>{elem.user.fullName}</Link></h5>
                                        <span className='reactSvgIcon' dangerouslySetInnerHTML={{ __html: elem.reacts.image }}></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : ('')
            }
        </div>
    )
}

export default ReactOnComment
