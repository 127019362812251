import React from "react";
import "./topContainer.css";

const TopContainer = () => {
   return (
      <div className="col-lg-10">
         <div className="sec-heading text-center planSecHeading">
            <h2>
               Our Price <span>Plans</span>
            </h2>
            <p>
               our price is free of cost for this package. you can not unlock
               all features. our price is free of cost for this package. you can
               not unlock all features. our price is free of cost for this
               package. you can not unlock all features.
            </p>
         </div>
      </div>
   );
};

export default TopContainer;
