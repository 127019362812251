import React from "react";
import { Link } from "react-router-dom";
import "./support.css";
import SupportCard from "../../../components/supportCard/SupportCard";

const SupportSection = () => {
   const supportArr = new Array(4).fill(0);
   return (
      <div className="gap gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">
                  <div className="primary-support helpPrimarySupport">
                     <div className="pitnik-title">
                        <h1>Ubuntu Support</h1>
                        <p>
                           Get Instant answer for the most common questions and
                           learn how to use like a pro.
                        </p>
                     </div>
                     <div className="row">
                        {supportArr.map((el, i) => (
                           <div className="col-lg-3 col-md-6 col-sm-6" key={i}>
                              <SupportCard />
                           </div>
                        ))}

                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
                           <div className="comon-help">
                              <span className="">
                                 <i className="fa fa-user"></i>
                              </span>
                              <h4>My Account</h4>
                              <p>Manage your profile and account setting</p>
                              <Link
                                 className="main-btn2"
                                 to="#"
                                 title=""
                                 data-ripple=""
                              >
                                 Read More
                              </Link>
                           </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
                           <div className="comon-help">
                              <span className="">
                                 <i className="fa fa-medkit"></i>
                              </span>
                              <h4>Fix a Problem</h4>
                              <p>
                                 Find solutions for the most common problems.
                              </p>
                              <Link
                                 className="main-btn2"
                                 to="#"
                                 title=""
                                 data-ripple=""
                              >
                                 Read More
                              </Link>
                           </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-md-6 col-sm-6">
                           <div className="comon-help">
                              <span className="">
                                 <i className="fa fa-first-order"></i>
                              </span>
                              <h4>Policy &amp; Reporting</h4>
                              <p>
                                 Understand the Pintik rules and report of
                                 violations
                              </p>
                              <Link
                                 className="main-btn2"
                                 to="#"
                                 title=""
                                 data-ripple=""
                              >
                                 Read More
                              </Link>
                           </div>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SupportSection;
