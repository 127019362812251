import React, { useEffect, useState } from 'react'
import RightTopChat from './RightTop/RightTopChat'
import ChatBottom from './ChatBottom/ChatBottom'
import ChatBody from './chatBody/ChatBody'
import { useSelector } from 'react-redux'
import { useGetChatUserListQuery } from '../../../store/api/chatApi'
import { socket } from '../../../methods/Socket';

const ChatRight = () => {
    const [searchChatList, setSearchChatList] = useState("");
    const paramObjChatList = {
        searchVal: searchChatList
    }
    const ChatUserList = useGetChatUserListQuery(paramObjChatList);
    const [messages, setMessages] = useState([]);
    const { userData } = useSelector((store) => {
        return store.chat;
    });

    useEffect(() => {
        socket.on('loadChat', (data) => {
            setMessages([...messages, data])
        });
        socket.on("loadMessages", (messages) => {
            setMessages(messages);
        });
        socket.on("messageDeletedForMe", (message) => {
            setMessages(message);
        });
        socket.on("messagesRemoved", (message) => {
            setMessages(message);
        });
        socket.on("conversationCleared", (message) => {
            setMessages(message);
        });
    }, [socket, messages]);

    if (userData.fullName) {
        return (
            <div className='chat'>
                <RightTopChat userData={userData} data={ChatUserList?.data?.data} />
                <ChatBody userData={userData} className="flex-1 h-auto chat_body"
                    messages={messages} />
                <ChatBottom userData={userData} />
            </div>
        )
    } else {
        return (
            <div className='d-flex flex-column h-100 position-relative justify-content-center align-items-center fs_18'>
                <div> Start a new conversation</div>
            </div>
        )
    }

}

export default ChatRight;
