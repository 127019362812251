import React from 'react'
import { Post } from '../../components/components'
import { useGetSavePostListQuery } from '../../store/api/savePostApi'
import SavedPostComp from '../../components/post/SavedPostComp'

const SavedPost = () => {
    // saved post api call
    const savedPost = useGetSavePostListQuery()
    const savedPostData = savedPost?.data?.data;

    return (
        <div className="gap2 gray-bg full_height">
            <div className="container">
                <div className="row">
                    <aside className="col-md-3"></aside>
                    <main className="col-md-6">
                        <p className="fs_22 fw-bold">Saved Posts</p>
                        <div className="feedSec">
                            {savedPostData?.map((el, i) => (
                                <Post data={el} index={i} key={i} caption={el.text} />
                            ))}
                            {savedPostData?.length === 0 && <>
                                <div className="col-12">
                                    <h5 className="text-center mb-0 py-5 fs_16">No Saved Post Found !!!</h5>
                                </div>
                            </>}
                            <div className="d-flex justify-content-center">
                                {/* {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'} */}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default SavedPost
