import React, { useState } from 'react'
import { AdminMenu } from '../../../components/components';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useCreateReportMutation } from '../../../store/api/Admin/adminReportApi';
import toast from 'react-hot-toast';

const AddReport = () => {
    const [title, setTitle] = useState("")
    const navigate = useNavigate()

    const [createReason] = useCreateReportMutation()


    const submitForm = () => {
        createReason({ title: title }).then((res) => {
            console.log(res)
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
                navigate("/admin/report")
            }
        })
    }


    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3 h-100'>
                            <h5>Create Report Reason</h5>
                            <Form noValidate method="post">
                                <Row>
                                    <Col className='col-md-6'>
                                        <Form.Group controlId="cat_nm">
                                            <Form.Label>Report Title</Form.Label>
                                            <Form.Control type="text" placeholder="Enter report name" value={title} onChange={(e) => setTitle(e.target.value)} name="category_name" />
                                            <Form.Control.Feedback type="invalid">Please provide report name</Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="mt-4 d-flex gap-2">
                                            <button className="btn btn-secondary" onClick={()=>navigate(-1)}>Back</button>
                                            <Button className="btn btn-primary" onClick={submitForm}>Create Report</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddReport;
