import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./api/authApi";
import ThemeSlice from "./slice/themeSlice";
import HeaderSlice from "./slice/headerSlice";
import weatherSlice from "./slice/weatherSlice";
import { weatherApi } from "./api/weatherApi";
import { postApi } from "./api/postApi";
import userSlice from "./slice/userSlice";
import { countryApi } from "./api/countryApi";
import { membershipApi } from "./api/membershipApi";
import { userApi } from "./api/userApi";
import { pokemonApi } from "./api/testInfiniteScroll";
import { stateApi } from "./api/stateApi";
import { activityApi } from "./api/activityApi";
import { reactsMasterApi } from "./api/reactMaster";
import { pageApi } from "./api/pageApi";
import { groupApi } from "./api/groupApi";
import { commentApi } from "./api/commentApi";
import { eventApi } from "./api/eventApi";
import { albumApi } from "./api/albumApi";
import { modulePostApi } from "./api/modulePostApi";
import { savePostApi } from "./api/savePostApi";
import { quoteApi } from "./api/quoteApi";
import { adminUserApi } from "./api/Admin/userPanelApi";
import { categoryApi } from "./api/categoryMaster";
import { jobApi } from "./api/jobApi";
import { communityApi } from "./api/communityApi";
import { reportApi } from "./api/reportApi";
import { otherUserApi } from "./api/otherUserApi";
import { reactApi } from "./api/reactApi";

import { adminCategoryApi } from "./api/Admin/categoryPanelApi";
import { adminGroupApi } from "./api/Admin/groupPanelApi";
import { adminPagesApi } from "./api/Admin/pagesPanelApi";
import { adminEventApi } from "./api/Admin/eventPanelApi";
import { adminPostApi } from "./api/Admin/PostPanelApi";
import { adminMembershipApi } from "./api/Admin/membershipPanelApi";
import { adminReactions } from "./api/Admin/adminreactions";
import { voteApi } from "./api/voteApi";
import { adminQuotes } from "./api/Admin/adminQuotes";
import { adminCommunityApi } from "./api/Admin/adminCommunityPanelApi";
import { adminReportsApi } from "./api/Admin/adminReportApi";
import { adminEmploymentApi } from "./api/Admin/adminEmploymentApi";
import { marketApi } from "./api/marketApi";
import { currencyApi } from "./api/currencyMaster";
import { adminRolePermissionApi } from "./api/Admin/adminRolePermissionApi";
import videoSlice from "./slice/videoSlice";
import { adminMarketPlaceApi } from "./api/Admin/adminMarkrtPlaceApi"
import chatSlice from "./slice/chatSlice";
import { chatApi } from "./api/chatApi";
import { walletApi } from "./api/walletApi";
import { searchApi } from "./api/searchApi";
import { adminNotificationApi } from "./api/Admin/AdminNotification";
import { birthdayApi } from "./api/birthdayApi";
import { notificationApi } from "./api/notificationApi";
import { adminDashboardApi } from "./api/Admin/adminDashboardApi";

const Store = configureStore({
  reducer: {
    [reactsMasterApi.reducerPath]: reactsMasterApi.reducer,
    [weatherApi.reducerPath]: weatherApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [stateApi.reducerPath]: stateApi.reducer,
    [membershipApi.reducerPath]: membershipApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [pokemonApi.reducerPath]: pokemonApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [pageApi.reducerPath]: pageApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [albumApi.reducerPath]: albumApi.reducer,
    [modulePostApi.reducerPath]: modulePostApi.reducer,
    [savePostApi.reducerPath]: savePostApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [quoteApi.reducerPath]: quoteApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [jobApi.reducerPath]: jobApi.reducer,
    [communityApi.reducerPath]: communityApi.reducer,
    [otherUserApi.reducerPath]: otherUserApi.reducer,
    [reactApi.reducerPath]: reactApi.reducer,
    [voteApi.reducerPath]: voteApi.reducer,
    [marketApi.reducerPath]: marketApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [birthdayApi.reducerPath]: birthdayApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,

    [adminUserApi.reducerPath]: adminUserApi.reducer,
    [adminCategoryApi.reducerPath]: adminCategoryApi.reducer,
    [adminGroupApi.reducerPath]: adminGroupApi.reducer,
    [adminPagesApi.reducerPath]: adminPagesApi.reducer,
    [adminEventApi.reducerPath]: adminEventApi.reducer,
    [adminPostApi.reducerPath]: adminPostApi.reducer,
    [adminMembershipApi.reducerPath]: adminMembershipApi.reducer,
    [adminReactions.reducerPath]: adminReactions.reducer,
    [adminReportsApi.reducerPath]: adminReportsApi.reducer,
    [adminEmploymentApi.reducerPath]: adminEmploymentApi.reducer,
    [adminQuotes.reducerPath]: adminQuotes.reducer,
    [adminCommunityApi.reducerPath]: adminCommunityApi.reducer,
    [adminRolePermissionApi.reducerPath]: adminRolePermissionApi.reducer,
    [adminMarketPlaceApi.reducerPath]: adminMarketPlaceApi.reducer,
    [adminNotificationApi.reducerPath]: adminNotificationApi.reducer,
    [adminDashboardApi.reducerPath]: adminDashboardApi.reducer,

    theme: ThemeSlice,
    weather: weatherSlice,
    header: HeaderSlice,
    userSlice: userSlice,
    videoSlice: videoSlice,
    chat: chatSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      reactsMasterApi.middleware,
      weatherApi.middleware,
      authApi.middleware,
      postApi.middleware,
      countryApi.middleware,
      stateApi.middleware,
      membershipApi.middleware,
      userApi.middleware,
      pokemonApi.middleware,
      activityApi.middleware,
      pageApi.middleware,
      groupApi.middleware,
      commentApi.middleware,
      eventApi.middleware,
      albumApi.middleware,
      modulePostApi.middleware,
      savePostApi.middleware,
      reportApi.middleware,
      quoteApi.middleware,
      categoryApi.middleware,
      jobApi.middleware,
      communityApi.middleware,
      otherUserApi.middleware,
      reactApi.middleware,
      voteApi.middleware,
      marketApi.middleware,
      currencyApi.middleware,
      chatApi.middleware,
      walletApi.middleware,
      searchApi.middleware,
      birthdayApi.middleware,
      notificationApi.middleware,

      adminDashboardApi.middleware,
      adminUserApi.middleware,
      adminCategoryApi.middleware,
      adminGroupApi.middleware,
      adminPagesApi.middleware,
      adminEventApi.middleware,
      adminPostApi.middleware,
      adminMembershipApi.middleware,
      adminReactions.middleware,
      adminReportsApi.middleware,
      adminEmploymentApi.middleware,
      adminQuotes.middleware,
      adminCommunityApi.middleware,
      adminRolePermissionApi.middleware,
      adminMarketPlaceApi.middleware,
      adminNotificationApi.middleware,
      chatApi.middleware
    ),
});

setupListeners(Store.dispatch);

export default Store;
