import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminRolePermissionApi = createApi({
   reducerPath: "adminRolePermission",
   baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
      prepareHeaders: (headers, { getState }) => {
         const { userSlice } = getState();
         let token = userSlice.userAuthToken;
         return headers.set("x-access-token", token);
      },
   }),
   tagTypes: ["AdminRolePermission"],
   endpoints: (builder) => ({
      getPermissionList: builder.query({
         query: () => {
            return {
               url: "api/admin/permission/list",
               method: "GET",
            };
         },
         providesTags: ["AdminRolePermission"],
      }),
      deletePermission: builder.mutation({
         query: (id) => {
            return {
               url: `api/admin/permission/delete`,
               method: "DELETE",
               body: {
                  permission_id: id,
               },
            };
         },
         invalidatesTags: ["AdminRolePermission"],
      }),
      // need this API but API pending from backend
      permissionDetails: builder.query({
         query: (id) => {
            return {
               url: "api/admin/permission/details",
               method: "GET",
               params: {
                  permission_id: id,
               },
            };
         },
         providesTags: ["AdminRolePermission"],
      }),
      updatePermission: builder.mutation({
         query: (data) => {
            return {
               url: `api/admin/permission/update`,
               method: "PUT",
               body: {
                  permission_id: data.id,
                  name: data.title,
               },
            };
         },
         invalidatesTags: ["AdminRolePermission"],
      }),
      createPermission: builder.mutation({
         query: (data) => {
            return {
               url: "api/admin/permission/create",
               method: "POST",
               body: {
                  name: data.title,
               },
            };
         },
         invalidatesTags: ["AdminRolePermission"],
      }),
      userPermissionList: builder.query({
         query: () => {
            return {
               url: "api/admin/permission/userpermission",
               method: "GET",
            };
         },
         providesTags: ["AdminRolePermission"],
      }),

      getRoleList: builder.query({
         query: () => {
            return {
               url: "api/admin/role/list",
               method: "GET",
            };
         },
         providesTags: ["AdminRolePermission"],
      }),
      deleteRole: builder.mutation({
         query: (id) => {
            return {
               url: `api/admin/role/delete`,
               method: "DELETE",
               params: {
                  role_id: id,
               },
            };
         },
         invalidatesTags: ["AdminRolePermission"],
      }),
      createRole: builder.mutation({
         query: (data) => {
            return {
               url: "api/admin/role/create",
               method: "POST",
               body: {
                  name: data.title,
                  permissions: data.permissions,
               },
            };
         },
         invalidatesTags: ["AdminRolePermission"],
      }),
      getRoleById: builder.query({
         query: (id) => {
            return {
               url: "api/admin/role/details",
               method: "GET",
               params: {
                  role_id: id,
               },
            };
         },
         providesTags: ["AdminRolePermission"],
      }),
      updateRole: builder.mutation({
         query: (data) => {
            console.log(data);
            return {
               url: `api/admin/role/update`,
               method: "PUT",
               body: {
                  role_id: data.id,
                  name: data.title,
                  permissions: data.permissions,
               },
            };
         },
         invalidatesTags: ["AdminRolePermission"],
      }),
   }),
});

export const {
   useGetPermissionListQuery,
   useDeletePermissionMutation,
   useUpdatePermissionMutation,
   useCreatePermissionMutation,
   useGetRoleListQuery,
   useDeleteRoleMutation,
   useCreateRoleMutation,
   useGetRoleByIdQuery,
   useUpdateRoleMutation,
   usePermissionDetailsQuery,
   useUserPermissionListQuery, 
} = adminRolePermissionApi;
