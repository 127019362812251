import React, { useEffect, useReducer, useState } from "react";
import AWS from "aws-sdk";
import "./createPost.css";
import { BsImage } from "react-icons/bs";
import { useCreatePostCommunityMutation, useCreatePostEventMutation, useCreatePostGroupMutation, useCreatePostMutation, useCreatePostPageMutation } from "../../../store/api/postApi";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import toast from "react-hot-toast";
import Compressor from "compressorjs";
import generateRandomImageName from "../../../methods/generateRandomImageName";
import { LiaTimesSolid } from "react-icons/lia";
import { Button, Modal } from "react-bootstrap";
import { useGetCategoryListQuery } from "../../../store/api/categoryMaster";
import ReactQuill from "react-quill";
import { GoPlusCircle } from "react-icons/go";
import { socket } from "../../../methods/Socket";
import { Mention, MentionsInput } from "react-mentions";
import { useGetConnectionByIdQuery } from "../../../store/api/userApi";
import Dropdown from 'react-bootstrap/Dropdown';


const initialState = {
  preview: false,
  text: "",
  images: [],
  category: null
};

// Reducer function to manage the post state
const postReducer = (state, action) => {
  switch (action.type) {
    case "SET_PREVIEW":
      return { ...state, preview: action.payload };
    case "SET_TEXT":
      return { ...state, text: action.payload };
    case "SET_CATEGORY":
      return { ...state, category: action.payload };
    case "SET_IMAGES":
      return { ...state, images: action.payload };
    case "RESET_REDUCER":
      return { preview: false, text: "", images: [], category: null };
    default:
      return state;
  }
};

const CreatePost = ({ ModuleType, ModuleID, type }) => {
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  })

  const [post, dispatch] = useReducer(postReducer, initialState);
  const [image, setImage] = useState([]);
  const [categoryModal, setCategoryModal] = useState(false);
  const [postModal, setPostModal] = useState(false);
  const [createPost] = useCreatePostMutation();
  const [createPostCommunity] = useCreatePostCommunityMutation();
  const [createPostPage] = useCreatePostPageMutation();
  const postCategoryList = useGetCategoryListQuery();
  const [createPostGroup] = useCreatePostGroupMutation();
  const [createPostEvent] = useCreatePostEventMutation();

  let selectedFIles;

  // HANDLING INPUTS
  const handleChange = (event) => {
    dispatch({ type: "SET_TEXT", payload: event.target.value });
  };

  let compressedFiles = []
  // ACCESING IMAGE INPUTS
  function handleImageFileChange(event) {
    let totalSize = 0;
    // Iterate through each file and sum up the sizes
    for (let i = 0; i < event.target.files.length; i++) {
      totalSize += event.target.files[i].size; // size is in bytes
    }
    // Convert from bytes to megabytes (MB)
    const totalSizeInMB = totalSize / (1024 * 1024);
    console.log(`Total size: ${totalSizeInMB.toFixed(2)} MB`);

    if (event.target.files.length < 6 && totalSizeInMB < 100.1) {
      console.log(event.target.files)
      setImage([...event.target.files]);

      selectedFIles = [];
      const targetFiles = event.target.files;
      const targetFilesObject = [...targetFiles];

      targetFilesObject.map((file) => {
        selectedFIles.push({
          name: file.name,
          image_url: URL.createObjectURL(file),
          type: file.type,
          size: file.size
        })
        if (file.type.slice(0, 5) === "image") {
          new Compressor(file, {
            quality: 0.2, // Default compression quality
            success(result) {
              const file = new File([result], result.name, { type: result.type });
              compressedFiles.push({
                name: file.name,
                image_url: file,
                type: file.type,
                size: file.size
              })
              dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
            },
            error(err) {
              console.error('Image compression error:', err);
            },
          })
        } else {
          compressedFiles.push({
            name: file.name,
            image_url: file,
            type: file.type,
            size: file.size
          })
        }
        return dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
      });
    } else {
      toast.error("You can select max of 5 images or File size more than 100 MB")
      setPostModal(false)
    }
    event.target.value = ''
  }
  const [percentage, setPercentage] = useState(0)
  const postData = async () => {
    // S3 bucket implement start
    const S3_BUCKET = "ubuntu-social-media-2";
    const REGION = "ap-south-1";
    AWS.config.update({
      accessKeyId: "AKIA4NNCEDROYLRYR2OX",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    let imgArr = [];
    for (let i = 0; i < post.images.length; i++) {
      let randomName = generateRandomImageName(post.images[i].name)
      let fileSize = post.images[i].size;
      let fileType = post.images[i].type;
      let fileName = "post_" + randomName.split(" ").join("_").split("-").join("_");
      const params = {
        Bucket: S3_BUCKET,
        Key: fileName,
        Body: post.images[i].image_url,
      };

      var upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
        setPercentage(parseInt((evt.loaded * 100) / evt.total))
      }).promise();

      imgArr.push({
        image_url: "https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + fileName,
        size: fileSize,
        mime_type: fileType,
      });
    }
    await upload;
    // S3 bucket implement end
    let obj = {
      category_id: post.category?._id,
      posted_by: userSliceData?._id,
      post_text: post.text,
      post_type: "string",
      images: imgArr,
      scope: "public",
      meta_data: ModuleID,
      meta_type: ModuleType
    };
    if (post.category !== null) {
      if (type === "community") {
        createPostCommunity({ ...obj, post_module: "community" }).then((res) => {
          imgArr = [];
          compressedFiles = []
          selectedFIles = []
          dispatch({ type: "RESET_REDUCER" });
          setImage([]);
          if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
          } else {
            toast.error('Somting Went Wrong!!')
          }
        })
      }

      else if (type === "page") {
        createPostPage({
          ...obj, meta_data: ModuleID,
          meta_type: ModuleType
        }).then((res) => {
          imgArr = [];
          compressedFiles = []
          selectedFIles = []
          dispatch({ type: "RESET_REDUCER" });
          setImage([]);
          if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationPagePost", {
              sender_id: userSliceData._id,
              meta_data: res?.data?.data._id,
              meta_type: "Post",
              notification_for: "page_post_create",
              update_meta_type_for: "Page",
              page_id: ModuleID
            });
          } else {
            toast.error('Somting Went Wrong!!')
          }
        })
      }

      else if (type === "group") {
        createPostGroup({
          ...obj, meta_data: ModuleID,
          meta_type: ModuleType
        }).then((res) => {
          imgArr = [];
          compressedFiles = []
          selectedFIles = []
          dispatch({ type: "RESET_REDUCER" });
          setImage([]);
          if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
          } else {
            toast.error('Somting Went Wrong!!')
          }
        })
      }

      else if (type === "event") {
        createPostEvent({
          ...obj, meta_data: ModuleID,
          meta_type: ModuleType
        }).then((res) => {
          imgArr = [];
          compressedFiles = []
          selectedFIles = []
          dispatch({ type: "RESET_REDUCER" });
          setImage([]);
          if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
          } else {
            toast.error('Somting Went Wrong!!')
          }
        })
      }

      else {
        createPost(obj).then((res) => {
          imgArr = [];
          compressedFiles = []
          selectedFIles = []
          dispatch({ type: "RESET_REDUCER" });
          setImage([]);
          if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationDataOthers", {
              sender_id: userSliceData._id,
              meta_data: res.data?.data?._id,
              meta_type: "Post",
              notification_for: "Create",
            });
          } else {
            toast.error('Somting Went Wrong!!')
          }
        })
      }
    }
    else {
      toast.error('Please select category')
    }
    setPostModal(false)
  };

  // REMOVEING IMAGES
  const removeImage = (index) => {
    compressedFiles = []
    selectedFIles = []
    dispatch({ type: "SET_IMAGES", payload: [] });
    setImage([]);
  };

  useEffect(() => {
    if (post.images.length === 0 && post.text.length === 0) {
      dispatch({ type: "SET_PREVIEW", payload: false });
    }
    else if (post.text.length === 11 && post.text === "<p><br></p>" && post.images.length === 0) {
      dispatch({ type: "SET_PREVIEW", payload: false });
    }
    else if (post.images.length > 0 && post.text.length >= 0) {
      dispatch({ type: "SET_PREVIEW", payload: true });
    }
    else {
      dispatch({ type: "SET_PREVIEW", payload: true });
    }
  }, [post.images, post.text, post.preview]);

  const handleCategory = () => {
    if (post.category) {
      setPostModal(true); setCategoryModal(false)
    }
  }
  // for mention part
  const otherUserParam = {
    id: userSliceData._id
  }
  const MyConnection = useGetConnectionByIdQuery(otherUserParam);
  const formattedUser = MyConnection?.data?.connections?.map(({ _id, fullName }) => ({ id: _id, display: fullName }));
  const displayTransform = (id, display) => {
    return `@${display}`;
  }

  return (
    <>
      <div className="central-meta cpost postbox">
        <div className='central-top d-flex align-items-center'>
          <span className="create-post">Create post</span>
          {
            post.category !== null ? (
              <span className="selectedCat">{post.category?.category_name}<button onClick={() => (dispatch({ type: "SET_CATEGORY", payload: null }))}><LiaTimesSolid /></button></span>
            ) : (
              <button className="selectCategoy" onClick={() => setPostModal(true)}>
                <GoPlusCircle />
                <span>Post</span>
              </button>
            )
          }
        </div>
      </div >

      {/* <Modal show={categoryModal} onHide={() => setCategoryModal(true)}>
        <Modal.Body>
          <h5 className="mb-4">Please select a category</h5>
          {postCategoryList?.data?.data?.length > 0 ? <>
            <div className="select_category">
              {postCategoryList?.data?.data?.map((elem, i) => {
                return (
                  <div className="category_item" key={i}>
                    <input type='radio' name="category" id={`category_${i}`} value={elem._id} onChange={() => (dispatch({ type: "SET_CATEGORY", payload: elem }))} />
                    <label htmlFor={`category_${i}`}>{elem.category_name}</label>
                  </div>
                )
              })}
            </div>
            <div className="text-center mt-4">
              <Button variant="secondary" className="mx-2" onClick={() => { dispatch({ type: "SET_CATEGORY", payload: null }); setCategoryModal(false) }}>
                Cancel
              </Button>
              <Button variant="primary" className="mx-2" onClick={handleCategory}>
                Save
              </Button>
            </div>
          </> : <div className="col-12">
            <h5 className="text-center mb-0 fs_18" >
              No Category Found !!!
            </h5>
            <div className="text-center mt-4">
              <Button variant="secondary" className="" onClick={() => { dispatch({ type: "SET_CATEGORY", payload: null }); setCategoryModal(false) }}>
                Cancel
              </Button>
            </div>
          </div>}
        </Modal.Body>
      </Modal> */}

      <Modal size="md" show={postModal} onHide={() => setPostModal(true)}>
        <Modal.Body>
          {percentage > 0 && percentage < 100 ? "Loading" : (
            <div>
              <div className="d-flex justify-content-space-between cat_dropdown">
                {post?.category?.category_name && <div className="cat_in">{post?.category?.category_name || ""}</div>}
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Select Category
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {postCategoryList?.data?.data?.map((elem, i) => {
                      return (
                        <div className="category_item" key={i}>
                          <input type='radio' name="category" id={`category_${i}`} value={elem._id} onChange={() => (dispatch({ type: "SET_CATEGORY", payload: elem }))} />
                          <label htmlFor={`category_${i}`}>{elem.category_name}</label>
                        </div>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <MentionsInput placeholder='Write something here...' value={post.text} onChange={handleChange} className='mentionList' style={{ height: '40px' }} >
                <Mention trigger="@" data={formattedUser} displayTransform={displayTransform} />
              </MentionsInput>
              {/* <ReactQuill
                value={post.text}
                onChange={handleChange}
                style={{ height: '120px' }}
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, 7] }],
                  ],
                }}
                formats={["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent",]}
                placeholder="Share some what are you thinking?"
              /> */}
              {post.preview ? (
                <>
                  {
                    /* FOR SINGLE IMAGE */
                    image.length === 1 ? (
                      <div className="img-bunch mt-2 style_with_1">
                        <div className="row g-1">
                          <div className="col-md-12">
                            <div className="preview_post_img preview_post_img_1">
                              {image[0].type.slice(0, 5) === "image" && (
                                <img src={URL.createObjectURL(image[0])} alt="..." />
                              )}
                              {image[0].type.slice(0, 5) === "video" && (
                                <ReactPlayer
                                  // playing={true}
                                  width={"100%"}
                                  controls={true}
                                  url={URL.createObjectURL(image[0])}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <button className="remove-btn" onClick={() => removeImage(0)}>
                          <LiaTimesSolid />
                        </button>
                      </div>
                    ) : ("")
                  }
                  {
                    /* FOR TWO IMAGE */
                    post.images.length === 2 ? (
                      <div className="img-bunch mt-2 style_with_2">
                        <div className="row g-1">
                          <div className="col-md-6">
                            <div className="preview_post_img preview_post_img_1">
                              {image[0].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[0])} alt="..." />
                                )}
                              {image[0].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[0])}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="preview_post_img preview_post_img_1">
                              {image[1].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[1])} alt="..." />
                                )}
                              {image[1].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[1])}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                        <button
                          className="remove-btn"
                          onClick={() => removeImage(0)}
                        >
                          <LiaTimesSolid />
                        </button>
                      </div>
                    ) : ("")
                  }
                  {
                    /* FOR THREE IMAGE */
                    post.images.length === 3 ? (
                      <div className="img-bunch mt-2 style_with_3">
                        <div className="row g-1">
                          <div className="col-md-6">
                            <div className="preview_post_img preview_post_img_1">
                              {image[0].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[0])} alt="..." />
                                )}
                              {image[0].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[0])}
                                  />
                                )}
                            </div>
                            <div className="preview_post_img preview_post_img_2">
                              {image[1].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[1])} alt="..." />
                                )}
                              {image[1].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[1])}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="preview_post_img preview_post_img_3">
                              {image[2].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[2])} alt="..." />
                                )}
                              {image[2].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[2])}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                        <button className="remove-btn" onClick={() => removeImage(0)} >
                          <LiaTimesSolid />
                        </button>
                      </div>
                    ) : ("")
                  }
                  {
                    /* FOR FOUR IMAGE */
                    post.images.length === 4 ? (
                      <div className="img-bunch mt-2 style_with_4">
                        <div className="row g-1">
                          <div className="col-md-3">
                            <div className="preview_post_img preview_post_img_1">
                              {image[0].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[0])} alt="..." />
                                )}
                              {image[0].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[0])}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="preview_post_img preview_post_img_2">
                              {image[1].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[1])} alt="..." />
                                )}
                              {image[1].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[1])}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="preview_post_img preview_post_img_3">
                              {image[2].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[2])} alt="..." />
                                )}
                              {image[2].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[2])}
                                  />
                                )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="preview_post_img preview_post_img_3">
                              {image[3].type.slice(0, 5) ===
                                "image" && (
                                  <img src={URL.createObjectURL(image[3])} alt="..." />
                                )}
                              {image[3].type.slice(0, 5) ===
                                "video" && (
                                  <ReactPlayer
                                    // playing={true}
                                    width={"100%"}
                                    controls={true}
                                    url={URL.createObjectURL(image[3])}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                        <button className="remove-btn" onClick={() => removeImage(0)} >
                          <LiaTimesSolid />
                        </button>
                      </div>
                    ) : ("")
                  }
                  {
                    /* FOR FIVE IMAGES */
                    post.images.length === 5 ? (
                      <div className="img-bunch mt-2 style_with_five">
                        <div className="row g-1">
                          <div className="col-md-6">
                            <div className="preview_post_img preview_post_img_1">
                              <img src={URL.createObjectURL(image[0])} alt="..." />
                            </div>
                            <div className="preview_post_img preview_post_img_2">
                              <img src={URL.createObjectURL(image[1])} alt="..." />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="preview_post_img preview_post_img_3">
                              <img src={URL.createObjectURL(image[2])} alt="..." />
                            </div>
                            <div className="preview_post_img preview_post_img_4">
                              <img src={URL.createObjectURL(image[3])} alt="..." />
                            </div>
                            <div className="preview_post_img preview_post_img_5">
                              <img src={URL.createObjectURL(image[4])} alt="..." />
                            </div>
                          </div>
                        </div>
                        <button
                          className="remove-btn"
                          onClick={() => removeImage(0)}
                        >
                          <LiaTimesSolid />
                        </button>
                      </div>
                    ) : ("")
                  }
                </>
              ) : ("")}
              <div className="attachments">
                <ul>
                  <li
                    data-toggle="tooltip"
                    data-placement="bottom"
                    // title=""
                    data-original-title="Upload Photos"
                  >
                    <label htmlFor="upload_post_image" className="fileContainer">
                      <BsImage />
                      <span>Images/Videos</span>
                      <input
                        type="file"
                        id="upload_post_image"
                        onChange={handleImageFileChange}
                        multiple
                        accept="image/*, video/*"
                      />
                    </label>
                  </li>
                  {post.preview ? (
                    <>
                      <li className="preview-btn">
                        <button
                          className="btn btn-primary rounded-pill"
                          type="submit"
                          onClick={postData}
                        >
                          Post
                        </button>
                        <button
                          className="btn btn-secondary rounded-pill"
                          type="submit"
                          onClick={() => { setPostModal(false); dispatch({ type: "RESET_REDUCER" }) }}
                        >
                          Cancel
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="preview-btn">
                        <button
                          className="btn btn-secondary rounded-pill"
                          type="submit"
                          onClick={() => { setPostModal(false); dispatch({ type: "RESET_REDUCER" }) }}
                        >
                          Cancel
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          )}

        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatePost;