import React, { useEffect, useState } from 'react'
import './settingSidePanel.css'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useDispatch, useSelector } from 'react-redux'
import { selectTheme, setTheme } from '../../store/slice/themeSlice'
import { selectOffCanvas, setOffCanvas } from '../../store/slice/headerSlice'

const SettingSidePanel = () => {
    const getOffCanvas = useSelector(selectOffCanvas)
    const getTheme = useSelector(selectTheme)
    const dispatch = useDispatch()

    // TOOGLE DARK/ LIGHT THEME
    const toggleTheme = () => {
        if(getTheme.theme === 'light-theme'){
            dispatch(setTheme('dark-theme'))
        } else {
            dispatch(setTheme('light-theme'))
        }
    }

    const videoAutoPlay = () => {

    }
    const soundEffect = () => {
        
    }
    const handleClose = () => {
        dispatch(setOffCanvas(false))
    };

    return (
        <Offcanvas show={getOffCanvas} onHide={handleClose} placement="end" className={'side_panel'}>
            <Offcanvas.Body>
                <h4 className={"panel_title"}>General Setting</h4>
                <div className="setting_row">
                    <span>use night mode</span>
                    <input type="checkbox" id="nightmode" onChange={toggleTheme} defaultChecked={getTheme.theme === 'dark-theme' ? true : false}/>                        
                    <label htmlFor="nightmode"></label>
                </div>

                <div className="setting_row">
                    <span>Video Autoplay</span>
                    <input type="checkbox" id="videoPlay" onChange={videoAutoPlay} defaultChecked={false}/>                        
                    <label htmlFor="videoPlay"></label>
                </div>

                <div className="setting_row">
                    <span>Sound Effect</span>
                    <input type="checkbox" id="videoPlay" onChange={soundEffect} defaultChecked={false}/>                        
                    <label htmlFor="videoPlay"></label>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SettingSidePanel