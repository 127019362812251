import React from 'react'
import UserItem from './UserItem/UserItem'
import './UserList.css'
import { socket } from '../../../../methods/Socket'

const UserList = ({ data, type, modalControl }) => {
    // console.log(data)
    return (
        <div className='userList'>
            {data?.map((el, i) => {
                return (
                    <UserItem
                        key={i} data={el} type={type} modalControl={modalControl}
                    />
                )
            })}
            {data?.length === 0 && <div className='text-center fs_16 mt-4'>No connection found!</div>}
        </div>
    )
}

export default UserList;