import React from 'react'
import './MediaCard.css'
import { Image } from '../components'

const MediaCard = ({ type, imageData,ele }) => {





    if (type === 'profileImage') {
        return (
            <div className='media_box image'>
                <Image src={imageData?.file[0]?.image_url} alt="..." className="aspect[1/1]" />
            </div>
        )
    }
    else if (type === 'coverImage') {
        return (
            <div className='media_box image'>
                <Image src={imageData?.file[0]?.image_url} alt="..." className="aspect[1/1]" />
            </div>
        )
    }
    else if (type === 'allImage') {
        return (
            <div className='media_box image'>
                <Image src={imageData?.file[0]?.image_url} alt="..." className="aspect[1/1]" />
            </div>
        )
    }
    else if(type ==="video"){
        return (


          
        
         <video width={320} height={240} controls >
        <source src={ele.file[0].image_url} type="video/mp4"/>
        </video>
  
            // </div>
        )

    }

    // else {
    //     return (
    //         <div className='media_box video'>
    //             <button type='button' className='playBtn shadow btn btn-primary'>
    //                 <i className='fa fa-play'></i>
    //             </button>
    //             <img src={src} alt="..." className="aspect[1/1]" />
    //         </div>
    //     )
    // }

}

export default MediaCard