import React, { useEffect, useState } from 'react'
import { AdminMenu } from '../../../components/components'
import MarketPlace from '../../market/MarketPlace'
import MarketplaceTableRow from '../../../components/adminComponent/table/tableRow/MarketplaceTableRow'
import { useSearchParams } from 'react-router-dom'
import { useGetMarketPlaceListQuery } from '../../../store/api/Admin/adminMarkrtPlaceApi'
import { Input } from 'antd'
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi'
import { useSelector } from 'react-redux'

const AdminMarketplaceLists = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [category, setCategory] = useState(searchParams.get("category") || "");
  const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1);

  const paramsMarket = {
    page: pageNo,
    searchVal: search,
    category: category,
  }
  const MarketPlaceList = useGetMarketPlaceListQuery(paramsMarket)

  console.log("mkk", MarketPlaceList)
  const onSearch = (e) => {
    setSearch(e.target.value)
  }
  {/* <Link to={`/marketplace/${data._id}`}></Link> */ }
  useEffect(() => {
    // let params = { page };
    // if (search) {
    //     params.search = search;
    // }
    // setSearchParams(params);
  }, [search, setSearchParams])

  const { userSliceData: userInfo } = useSelector((store) => {
    return store.userSlice;
  });
  const [permissionArr, setPermissionArr] = useState("")
  const userPermissionList = useUserPermissionListQuery()

  useEffect(() => {
    if (userPermissionList?.isSuccess === true) {
      let user_permission_arr = userPermissionList?.data?.permissions;
      setPermissionArr(user_permission_arr)
    }
  }, [userPermissionList]);

  return (
    <div className='gap2 gray-bg full_height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <AdminMenu open />
          </div>
          <div className='col-md-9'>
            <div className='bg-white rounded-2 px-1 pb-3'>
              {/* <div className="d-md-flex justify-content-between align-items-center p-2">
                <h5 className="mb-0">Product</h5>
              </div> */}
              <div className='d-md-flex justify-content-between align-items-center p-2'>
                <h5 className='mb-0'>Products</h5>
                <div className=''>
                  <Input placeholder="Search..." value={search} onChange={onSearch} style={{ width: 200 }} />
                  {/* <Input placeholder="Search..."  style={{ width: 200 }} /> */}

                </div>
              </div>
              <table className='adminTable'>
                <thead>
                  <tr>
                    <th><div className='table_head_info'>Title</div></th>
                    <th><div className='table_head_info'>Category</div></th>
                    <th><div className='table_head_info'>Price</div></th>
                    <th><div className='table_head_info'>Seller</div></th>
                    <th><div className='table_head_info'>Buyer</div></th>
                    {(userInfo.user_type === 3 && permissionArr.includes("marketplace_active")) && <th><div className='table_head_info text-center'>Actions</div></th>}
                    {userInfo.user_type === 1 && <th><div className='table_head_info text-center'>Actions</div></th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    MarketPlaceList.isError && (
                      <tr className='noData'>
                        <td colSpan={8}>
                          <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                        </td>
                      </tr>
                    )
                  }
                  {
                    MarketPlaceList.isLoading && (
                      <tr className='noData'>
                        <td colSpan={8}>
                          <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                        </td>
                      </tr>
                    )
                  }
                  {
                    MarketPlaceList.isSuccess && (
                      MarketPlaceList?.data?.data.length > 0 ? (
                        MarketPlaceList?.data?.data.map((el, i) => {
                          return (
                            <MarketplaceTableRow data={el} key={i} />
                          )
                        })
                      ) : (
                        <tr className='noData'>
                          <td colSpan={8}>
                            <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                          </td>
                        </tr>
                      )
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminMarketplaceLists