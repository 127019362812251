import React, { useEffect, useState } from 'react'
import { isMobile } from "../../methods/checkMobile";
import { AdminMenu, CreateYourPageWidget, GroupCard, GroupWidget, LiveWidget, PageCard, ProfileWidget, Quote, SideBar, TopTrending, UserCard } from '../../components/components';
import { useSelector } from 'react-redux';
import { useUserPermissionListQuery } from '../../store/api/Admin/adminRolePermissionApi';
import { useSearchSomethingQuery } from '../../store/api/searchApi';
import { useSearchParams } from 'react-router-dom';
import { Radio } from 'antd';
import SearchPost from '../../components/search/Post';

const SearchPage = () => {
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    const permissionList = useUserPermissionListQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState(searchParams.get("searchValue") || "");
    const [type, setType] = useState(searchParams.get("type") || "");
    let searchData = {
        search: searchValue,
        type: type
    }
    const searchSomething = useSearchSomethingQuery(searchData);
    console.log(searchSomething)
    useEffect(() => {
        let params = {};
        if (searchValue) {
            params.searchValue = searchValue;
        }
        if (type) {
            params.type = type;
        }
        setSearchParams(params);
    }, [searchValue, type]);

    const handleModeChange = (e) => {
        setType(e.target.value);
    };

    return (
        <>
            <div className="gap2 gray-bg full_height">
                <div className="container">
                    <div className="row">
                        {
                            isMobile() ? '' : (
                                <aside className="col-md-3">
                                    <SideBar className="left">
                                        {(userInfo.user_type === 1 || (userInfo.user_type === 3 && permissionList?.data?.permissions.length > 0)) && <AdminMenu />}
                                        <ProfileWidget />
                                        <Quote />
                                        {/* <BirthdayWidget /> */}
                                        <CreateYourPageWidget />
                                    </SideBar>
                                </aside>
                            )
                        }
                        <main className="col-md-6">
                            <h5 className="fw-bold col-lg-12 col-md-12 col-12">Search for people, group, page, post</h5>
                            <div className="col-lg-12 col-md-12 col-12">
                                <form method="post">
                                    <input type="text" placeholder="Search.." className="form-control" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                </form>
                                <Radio.Group
                                    onChange={handleModeChange}
                                    value={type}
                                    style={{
                                        marginBottom: 8,
                                        marginTop: 8,
                                    }}
                                >
                                    <Radio.Button value="users">People</Radio.Button>
                                    <Radio.Button value="posts">Posts</Radio.Button>
                                    <Radio.Button value="groups">Groups</Radio.Button>
                                    <Radio.Button value="pages">Pages</Radio.Button>
                                </Radio.Group>
                                <div className='row'>
                                    {
                                        type === "users" && (
                                            searchSomething?.data?.data?.map((el, index) => (
                                                <div className="col-4 mt-2" key={index}>
                                                    <UserCard type="search" data={el} />
                                                </div>
                                            ))
                                        )
                                    }
                                    {
                                        type === "posts" && (
                                            searchSomething?.data?.data?.map((el, index) => (
                                                <div className="col-12 mt-2" key={index}>
                                                    <SearchPost data={el} />
                                                </div>
                                            ))
                                        )
                                    }
                                    {
                                        type === "groups" && (
                                            searchSomething?.data?.data?.map((el, index) => (
                                                <div className="col-4 mt-2" key={index}>
                                                    <GroupCard type="search" data={el} />
                                                </div>
                                            ))
                                        )
                                    }
                                    {
                                        type === "pages" && (
                                            searchSomething?.data?.data?.map((el, index) => (
                                                <div className="col-4 mt-2" key={index}>
                                                    <PageCard type="search" data={el} />
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            </div>
                        </main>
                        {
                            isMobile() ? '' : (
                                <aside className="col-md-3">
                                    <SideBar className="right">
                                        <LiveWidget />
                                        <TopTrending />
                                        <GroupWidget />
                                    </SideBar>
                                </aside>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchPage
