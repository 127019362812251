import React, { useState } from 'react'
import { useGetUserConnectionQuery } from '../../../store/api/userApi'
import { Button, Modal } from 'react-bootstrap'
import { RxCross2 } from 'react-icons/rx'
import { IoIosSearch } from 'react-icons/io'
import UserList from './UserLists/UserList'
import { Link, useNavigate } from 'react-router-dom'
import style from "../wallet.module.css"

const SendMoneyToConnection = () => {
    const navigate = useNavigate()
    const [connectionModal, setConnectionModal] = useState(false)
    const [searchConnection, setSearchConnection] = useState("")
    const paramObjConnection = {
        pageNo: 1,
        searchVal: searchConnection
    }
    const connectionList = useGetUserConnectionQuery(paramObjConnection)
    return (
        <>
            <>
                <Link to={""}>
                    <Button className={style.walletButton} onClick={() => setConnectionModal(true)}>
                        Send Money To Connection
                    </Button>
                </Link>
                <Link to={"/wallet/history"}>
                    <Button className={style.walletButton}>
                        Wallet History
                    </Button>
                </Link>
            </>
            {/* connection Modal */}
            <Modal centered size="l" show={connectionModal} onHide={() => setConnectionModal(false)}>
                <Modal.Body>
                    <div>
                        <div className='text-right'><RxCross2 style={{ cursor: 'pointer' }} onClick={() => setConnectionModal(false)}>
                        </RxCross2>
                        </div>
                        <h4 className="fw-bold mb-2">Connections</h4>
                        <form className='searchUserForm mb-2'>
                            <IoIosSearch />
                            <input type="text" className="form-control" placeholder="Search users..." aria-label="Search users..." onChange={(e) => setSearchConnection(e.target.value)} value={searchConnection} />
                        </form>
                        <UserList data={connectionList?.data?.connections} modalController={setConnectionModal} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SendMoneyToConnection
