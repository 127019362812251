import React, { useEffect, useState } from 'react'
import { AdminMenu } from '../../../../components/components';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { usePermissionDetailsQuery, useUpdatePermissionMutation } from '../../../../store/api/Admin/adminRolePermissionApi';

const EditPermission = () => {
    const { permissionId } = useParams()
    const [title, setTitle] = useState("")
    const navigate = useNavigate()
    const [updatePermission] = useUpdatePermissionMutation()
    const PermissionData = usePermissionDetailsQuery(permissionId)

    const submitForm = () => {
        updatePermission({ title: title, id: permissionId }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
                navigate("/admin/roles-permission/permission")
            }
        })
    }
    useEffect(() => {
        if (PermissionData.status === "fulfilled") {
            setTitle(PermissionData?.data?.data?.name)
        }
    }, [PermissionData])

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3 h-100'>
                            <h5>Edit Permission Name</h5>
                            <Form noValidate method="post">
                                <Row>
                                    <Col className="col-md-6">
                                        <Form.Group controlId="cat_nm">
                                            <Form.Label>Permission Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter report name" value={title} onChange={(e) => setTitle(e.target.value)} name="category_name" />
                                            <Form.Control.Feedback type="invalid">Please provide permission name</Form.Control.Feedback>
                                        </Form.Group>
                                        <div className='mt-4'>
                                            <Button className="btn btn-primary" onClick={submitForm}>Edit Permission</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPermission;