import React, { useEffect, useState } from "react";
import { BsFillCameraFill } from "react-icons/bs";
import "./profileCoverPhoto.css";
import { useDispatch, useSelector } from "react-redux";
import AWS from "aws-sdk";
import { addUserSlice } from "../../../store/slice/userSlice";
import { useDeleteCoverMutation, useGetUserDetailsAgainstIdQuery, useUpdateUserMutation } from "../../../store/api/userApi";
import toast from "react-hot-toast";
import { LiaTimesSolid } from "react-icons/lia";
import { IoMdCheckmark } from "react-icons/io";
import { defaultUserCover } from "../../components";
import { useUpdateEventMutation } from "../../../store/api/eventApi";
import { useUpdateGroupMutation } from "../../../store/api/groupApi";
import { useUpdatePageMutation } from "../../../store/api/pageApi";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Crop } from "../crop/Crop";
import convertImageLinkToFile from "../../../methods/ConvertImageToFile";
import generateRandomImageName from "../../../methods/generateRandomImageName";
import { useCreatePostEventMutation, useCreatePostGroupMutation, useCreatePostMutation, useCreatePostPageMutation } from "../../../store/api/postApi";
import { Dropdown, Button as AntButton } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { socket } from "../../../methods/Socket";

const DashboardCoverPhoto = ({ type, data }) => {

  const [createPost] = useCreatePostMutation();
  const [createPostPage] = useCreatePostPageMutation();
  const [createPostGroup] = useCreatePostGroupMutation();
  const [createPostEvent] = useCreatePostEventMutation()

  // get user id from url
  const { userId } = useParams();
  // get user details against id from param
  const userDetails = useGetUserDetailsAgainstIdQuery(userId)
  const userDetailsById = userDetails?.data?.data;

  // to get user data in redux
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  // update the user cover
  const [updateUser] = useUpdateUserMutation()
  // update the event cover
  const [updateEvent] = useUpdateEventMutation()
  // update the group cover
  const [updateGroup] = useUpdateGroupMutation()
  // update the page cover
  const [updatePage] = useUpdatePageMutation()

  const dispatch = useDispatch()
  const [cover, setCover] = useState([]);
  const [coverFile, setCoverFile] = useState([]);
  // for cropping
  const [cropModal, setCropModal] = useState(false);
  const [cropedImage, setCropedImage] = useState("")
  const [base64Image, setBase64Image] = useState("")

  const userID = useSelector((store) => {
    return store.userSlice.userSliceData._id;
  });
  // for change cropped image blob to base64
  let compressedFiles = []
  let selectedFIles;

  useEffect(() => {
    cropedImage !== "" && (
      fetch(cropedImage).then(response => response.blob()).then(blob => {
        // Convert Blob to base64
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result;
          // Set the base64 data as an image URL
          setBase64Image(base64Data);
        };
        reader.readAsDataURL(blob);
      }).catch(error => {
        console.error('Error fetching or converting Blob:', error);
      })
    )
  }, [cropedImage, setBase64Image])
  // cropped base64 image to file convert and set to cover file
  useEffect(() => {
    if (base64Image !== "") {
      convertImageLinkToFile(base64Image).then((res) => {
        setCoverFile(res)
      })
    }
  }, [base64Image])

  const handleCoverPhoto = (e) => {
    // setCoverFile(e.target.files[0]);
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setCover([...selectedFIles]);
    setCropModal(true)
  };

  const removeImage = () => {
    setCover([])
    setCoverFile([])
    setBase64Image("")
  }

  const handleSave = async () => {
    const S3_BUCKET_COVER = "ubuntu-social-media-2";
    const REGION2 = "ap-south-1";
    AWS.config.update({
      accessKeyId: "AKIA4NNCEDROYLRYR2OX",
      secretAccessKey: "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",
    });
    const s3forCoverPhoto = new AWS.S3({
      params: { Bucket: S3_BUCKET_COVER },
      region: REGION2,
    });

    let coverName = generateRandomImageName(coverFile.name);
    let coverSize = coverFile.size;
    let coverType = coverFile.type;

    const coverParams = {
      Bucket: S3_BUCKET_COVER,
      Key: coverName,
      Body: coverFile,
    };

    var uploadCovertoS3 = s3forCoverPhoto.putObject(coverParams).on("httpUploadProgress", (evt) => {
      // File uploading progress
      console.log("Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%");
    }).promise();

    await uploadCovertoS3;

    let coverArr = []

    coverArr.push({
      image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
      size: coverSize,
      mime_type: coverType,
    });
    // update event cover api function call
    if (type === "event") {
      let eventObj = {
        title: data.title,
        description: data.description,
        venue: data.venue,
        scope: "private",
        timing: data.timing,
        banner_image: coverArr,
        event_id: data._id
      }
      let obj = {
        posted_by: userID,
        post_text: `In ${data.title} group cover photo update successful`,
        post_type: "string",
        images: coverArr,
        scope: "public"
      };
      updateEvent(eventObj).then((res) => {
        if (res?.data?.error === 0) {
          toast.success(res?.data?.message)
        }
      })
      createPostEvent({
        ...obj, meta_data: data._id,
        meta_type: "Event"
      })
    }
    // update group cover api function call
    else if (type === "group") {
      let groupObj = {
        group_id: data._id,
        scope: "private",
        title: data.title,
        description: data.description,
        rules: data.rules,
        display_image: data.display_image,
        cover_image: coverArr
      }
      let obj = {
        posted_by: userID,
        post_text: `In ${data.title} group cover photo update successful`,
        post_type: "string",
        images: coverArr,
        scope: "public"
      };
      updateGroup(groupObj).then((res) => {
        if (res?.data?.error === 0) {
          toast.success(res?.data?.message)
        }
      })
      createPostGroup({
        ...obj, meta_data: data._id,
        meta_type: "Group"
      })
    }
    // update page cover api function call
    else if (type === "page") {
      let pageObj = {
        page_id: data._id,
        scope: "private",
        title: data.title,
        description: data.description,
        display_image: data.display_image,
        cover_image: coverArr
      }
      updatePage(pageObj).then((res) => {
        if (res?.data?.error === 0) {
          toast.success(res?.data?.message)
        }
      })

      let obj = {
        posted_by: userID,
        post_text: `In ${data.title} page cover photo update successful`,
        post_type: "string",
        images: coverArr,
        scope: "public"
      };
      createPostPage({
        ...obj, meta_data: data._id,
        meta_type: "Page"
      })
      toast.success("Cover Picture Update Successfull!!!")
    }
    // update profile cover api function call
    else {
      let obj2 = {
        cover_picture: coverArr,
        profile_picture: "",
        user_id: userSliceData._id,
      }
      updateUser(obj2).then((res) => {
        let newData = res?.data?.data;
        console.log("Update", newData)
        dispatch(addUserSlice(newData));
      })

      let objCover = {
        posted_by: userID,
        post_text: "Cover photo uploaded successfull",
        post_type: "string",
        images: coverArr,
        scope: "public",
      };
      createPost(objCover).then((res) => {
        socket.emit("sendNotificationUserDetailsUpdate", {
          sender_id: userSliceData._id,
          meta_data: res?.data?.data?._id,
          meta_type: "Post",
          notification_for: "user_cover_photo",
          update_meta_type_for: "User",
        });

        socket.emit("loggedinUserNotificationCount", {});

        coverArr = [];
        compressedFiles = []
        selectedFIles = []
        dispatch({ type: "RESET_REDUCER" });
      })
      toast.success("Cover Picture Update Successfull!!!")
    }

    //for cover picture end
    setCover([])
    setBase64Image("")
  }

  // for delete profile cover
  const [deleteProfileCover] = useDeleteCoverMutation()
  const [deleteCoverModal, setDeleteCoverModal] = useState(false)
  const handleDeleteCover = () => {
    deleteProfileCover({ type: "cover" }).then((res) => {
      console.log(res)
      let newData = res?.data?.data;
      dispatch(addUserSlice(newData));
      toast.success("Profile cover removed successfully")
    })
  }
  const DropdownLinks = [
    {
      label: <>
        <input type="file" accept="image/*" onChange={handleCoverPhoto} id="coverImg" style={{ display: "none" }} />
        <label htmlFor="coverImg">
          Change Profile Cover
        </label>
      </>,
      key: "0"
    },
    {
      label: <div onClick={() => setDeleteCoverModal(true)}>Delete Profile Cover</div>,
      key: "1"
    }
  ]

  // Event cover JSX
  if (type === "event") {
    return (
      <>
        {data?.user?._id === userSliceData._id && <div className="edit-pp">
          <label className="fileContainer">
            <BsFillCameraFill />
            <input type="file" accept="image/*" onChange={handleCoverPhoto} />
          </label>
        </div>}
        <img
          src={base64Image.length > 0 ? base64Image : (data?.banner_image?.length > 0) ? (data?.banner_image[0]?.image_url) : (defaultUserCover)}
          alt=""
          style={{ height: "450px" }}
        />
        {base64Image.length > 0 && (
          <>
            <div className="coverUploadAction">
              <button className="cancel" onClick={removeImage}><LiaTimesSolid /> Cancel</button>
              <button className="save" onClick={handleSave}><IoMdCheckmark /> Save</button>
            </div>
          </>
        )}
        <Modal centered size="md" show={cropModal} onHide={() => setCropModal(false)}>
          <Modal.Body>
            <Crop image={cover[0]} imagePass={setCropedImage} manageCropModal={setCropModal} type={"coverPhoto"} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
  // Group cover JSX
  else if (type === "group") {
    return (
      <>
        {data?.user?._id === userSliceData._id && <div className="edit-pp">
          <label className="fileContainer">
            <BsFillCameraFill />
            <input type="file" accept="image/*" onChange={handleCoverPhoto} />
          </label>
        </div>}
        <img
          src={base64Image.length > 0 ? base64Image : (data?.cover_image?.length > 0) ? (data?.cover_image[0]?.image_url) : (defaultUserCover)}
          alt="" style={{ height: "450px" }}
        />
        {base64Image.length > 0 && (
          <>
            <div className="coverUploadAction">
              <button className="cancel" onClick={removeImage}><LiaTimesSolid /> Cancel</button>
              <button className="save" onClick={handleSave}><IoMdCheckmark /> Save</button>
            </div>
          </>
        )}
        <Modal centered size="md" show={cropModal} onHide={() => setCropModal(false)}>
          <Modal.Body>
            <Crop image={cover[0]} imagePass={setCropedImage} manageCropModal={setCropModal} type={"coverPhoto"} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
  // Page cover JSX
  else if (type === "page") {
    return (
      <>
        {data?.user?._id === userSliceData._id && <div className="edit-pp">
          <label className="fileContainer">
            <BsFillCameraFill />
            <input type="file" accept="image/*" onChange={handleCoverPhoto} />
          </label>
        </div>}
        <img
          src={base64Image.length > 0 ? base64Image : (data?.cover_image?.length > 0) ? (data?.cover_image[0]?.image_url) : (defaultUserCover)}
          alt="" style={{ height: "450px" }}
        />
        {base64Image.length > 0 && (
          <>
            <div className="coverUploadAction">
              <button className="cancel" onClick={removeImage}><LiaTimesSolid /> Cancel</button>
              <button className="save" onClick={handleSave}><IoMdCheckmark /> Save</button>
            </div>
          </>
        )}
        <Modal centered size="md" show={cropModal} onHide={() => setCropModal(false)}>
          <Modal.Body>
            <Crop image={cover[0]} imagePass={setCropedImage} manageCropModal={setCropModal} type={"coverPhoto"} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
  // Profile cover JSX
  else {
    return (
      <>
        {userId === userSliceData._id && <div className="edit-pp">
          <Dropdown menu={{ items: DropdownLinks }} placement="bottomRight">
            <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
          </Dropdown>
        </div>}
        {userId === userSliceData._id ? <img
          src={base64Image.length > 0 ? base64Image : userSliceData?.cover_picture[0]?.image_url ? (userSliceData?.cover_picture[0]?.image_url) : (defaultUserCover)}
          alt="" style={{ height: "450px" }}
        /> : <img src={userDetailsById?.cover_picture[0]?.image_url ? userDetailsById?.cover_picture[0]?.image_url : (defaultUserCover)} alt="" style={{ height: "450px" }} />
        }

        {base64Image.length > 0 && (
          <>
            <div className="coverUploadAction">
              <button className="cancel" onClick={removeImage}><LiaTimesSolid /> Cancel</button>
              <button className="save" onClick={handleSave}><IoMdCheckmark /> Save</button>
            </div>
          </>
        )}

        <Modal centered size="md" show={cropModal} onHide={() => setCropModal(false)}>
          <Modal.Body>
            <Crop image={cover[0]} imagePass={setCropedImage} manageCropModal={setCropModal} type={"coverPhoto"} />
          </Modal.Body>
        </Modal>

        {/* delete cover modal */}
        <Modal centered size="sm" show={deleteCoverModal} onHide={() => setDeleteCoverModal(false)}>
          <Modal.Body>
            <div className="text-center">
              <h5 className="mb-3">Are you sure, you want to Delete the cover photo?</h5>
              <div className="text-center">
                <Button variant="secondary" onClick={() => setDeleteCoverModal(false)} className="mx-2">
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteCover} className="mx-2">
                  Delete
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default DashboardCoverPhoto;
