import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiOutlineStar, AiOutlineLike, AiOutlineUsergroupAdd, AiOutlineDislike } from "react-icons/ai";
import { LuClipboardEdit } from "react-icons/lu";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { useJoinRequestMutation as useJoinRequestMutationPage, useRemovePageMemberMutation } from "../../../store/api/pageApi";
import toast from "react-hot-toast";
import { useJoinRequestMutation as useJoinRequestMutationGroup, useRemoveGroupMemberMutation } from "../../../store/api/groupApi";
import { MdGroupRemove } from "react-icons/md";
import { useLeaveEventMutation, useToJoinEventMutation } from "../../../store/api/eventApi";
import { useAcceptNewRequestMutation, useCancelRequestMutation, useDeleteConnectionMutation, useGetUserDetailsAgainstIdQuery, useRemoveConnectionMutation, useSendRequestMutation } from "../../../store/api/userApi";

const DashboardAction = ({ type, className, data }) => {
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   //ACCEPT FRIEND REQUEST 
   const [acceptRequest] = useAcceptNewRequestMutation()
   const acceptFriendRequest = () => {
      let obj = {
         requester: userDetailsById?._id,
         recipient: userSliceData._id
      }
      acceptRequest(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
         } else {
            toast.error("Something went wrong");
         }
      })
   }

   // join page
   const [joinRequest] = useJoinRequestMutationPage()
   const joinPageReqFunc = () => {
      let obj = {
         page_id: data._id,
         members: {
            user_id: userSliceData?._id,
         }
      }

      joinRequest(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }
   // Leave from page
   const [cancelReqPageModal, setCancelReqPageModal] = useState(false)
   const [leavePageModal, setLeavePageModal] = useState(false)
   const [leaveFromPage] = useRemovePageMemberMutation()
   const leaveFromPageModal = () => {
      let obj = {
         page_id: data._id,
         members: {
            user_id: userSliceData._id,
            is_remove: true
         }
      }
      leaveFromPage(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
      setLeavePageModal(false)
   }

   // join group
   const [joinRequestGroup] = useJoinRequestMutationGroup()
   const joinGroupReqFunc = () => {
      let obj = {
         group_id: data._id,
         members: {
            user_id: userSliceData?._id,
         }
      }
      joinRequestGroup(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // Leave from group
   const [cancelReqGrpModal, setCancelReqGrpModal] = useState(false)
   const [leaveGroupModal, setLeaveGroupModal] = useState(false)
   const [leaveFromGroup] = useRemoveGroupMemberMutation()
   const leaveFromGroupFunc = () => {
      setLeaveGroupModal(true)
   }
   const leaveFromGroupModal = () => {
      let obj = {
         group_id: data._id,
         members: {
            user_id: userSliceData._id,
            is_remove: true
         }
      }
      leaveFromGroup(obj).then((res) => { })
      setLeaveGroupModal(false)
   }
   // join event
   const [joinEvent] = useToJoinEventMutation()
   const joinEventHandler = () => {
      joinEvent({ event_id: data._id, user_id: userSliceData._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }
   // leave event from own profile
   const [joinReqModal, setJoinReqModal] = useState(false)
   const [leaveEventModal, setLeaveEventModal] = useState(false)
   const [leaveEvent] = useLeaveEventMutation()
   const leaveEventHandler = () => {
      leaveEvent({ event_id: data._id, user_id: userSliceData._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }
   // get user id from url
   const { userId } = useParams();
   // get user details against id from param
   const userDetails = useGetUserDetailsAgainstIdQuery(userId)
   const userDetailsById = userDetails?.data?.data;

   //SEND CONNECTION REQUEST
   const [requestSend] = useSendRequestMutation()
   const sendRequest = () => {
      let obj = {
         recipient: userId
      }
      requestSend(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
         } else {
            toast.error("Something went wrong");
         }
      })
   }

   // Cancel connection request
   const [cancelReqModal, setCancelReqModal] = useState(false)
   const [cancelConnectionReq] = useCancelRequestMutation()
   const cancelRequest = () => {
      cancelConnectionReq(userId).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setCancelReqModal(false)
   }

   //IN CONNECTION PAGE REMOVE FROM CONNECTION
   const [removeConnectionModal, setRemoveConnectionModal] = useState(false)
   const [removeConnection] = useRemoveConnectionMutation()
   const removeMyConnectionModal = () => {
      let obj = {
         targetUserId: userId
      }
      removeConnection(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setRemoveConnectionModal(false)
   }

   const [decReqModal, setDecReqModal] = useState(false)
   const [deleteConnection] = useDeleteConnectionMutation()
   const declineRequestModal = () => {
      deleteConnection({
         requester: userDetailsById?._id
      }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong in this")
         }
      })
      setDecReqModal(false)
   }

   if (type === "event") {
      return (
         <>
            {data?.user?._id === userSliceData?._id ? (
               <Link to={`/event/${data?._id}/edit`} className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                  <span>
                     <LuClipboardEdit style={{ height: '1.5em', width: '1.5em' }} />{" "}
                     Update Event
                  </span>
               </Link>
            ) : (
               <>
                  {data?.is_join === "pending" ?
                     <>
                        <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                           <div onClick={() => setJoinReqModal(true)}>
                              <AiOutlineStar style={{ height: '1.5em', width: '1.5em' }} />
                              &nbsp;
                              Cancel Request
                           </div>
                        </Link>

                        {/* CONFIRM cancel join req */}
                        <Modal centered size="sm" show={joinReqModal} onHide={() => setJoinReqModal(false)}>
                           <Modal.Body>
                              <div className="text-center">
                                 <h5 className="mb-3">Are you sure, you want to cancel the join request?</h5>
                                 <div className="text-center">
                                    <Button variant="secondary" onClick={() => setJoinReqModal(false)} className="mx-2">
                                       Cancel
                                    </Button>
                                    <Button variant="danger" onClick={leaveEventHandler} className="mx-2">
                                       Cancel Request
                                    </Button>
                                 </div>
                              </div>
                           </Modal.Body>
                        </Modal>
                     </>
                     :
                     data?.is_join === "not_exists" ?
                        <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                           <div onClick={joinEventHandler}>
                              <AiOutlineUsergroupAdd style={{ height: '1.5em', width: '1.5em' }} />
                              &nbsp;
                              Join Event
                           </div>
                        </Link>
                        :
                        <>
                           <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                              <div onClick={() => setLeaveEventModal(true)}>
                                 <AiOutlineUsergroupAdd style={{ height: '1.5em', width: '1.5em' }} />
                                 &nbsp;
                                 Leave Event
                              </div>
                           </Link>
                           {/* CONFIRM leave event */}
                           <Modal centered size="sm" show={leaveEventModal} onHide={() => setLeaveEventModal(false)}>
                              <Modal.Body>
                                 <div className="text-center">
                                    <h5 className="mb-3">Are you sure, you want to leave the event?</h5>
                                    <div className="text-center">
                                       <Button variant="secondary" onClick={() => setLeaveEventModal(false)} className="mx-2">
                                          Cancel
                                       </Button>
                                       <Button variant="danger" onClick={leaveEventHandler} className="mx-2">
                                          Leave Event
                                       </Button>
                                    </div>
                                 </div>
                              </Modal.Body>
                           </Modal>
                        </>}
               </>
            )}
         </>
      );
   }

   if (type === "group") {
      return (
         <>
            {data?.user?._id === userSliceData?._id ? (
               <Link to={`/group/${data?._id}/edit`} className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                  <div>
                     <LuClipboardEdit style={{ height: '1.5em', width: '1.5em' }} />{" "}
                     Update Group
                  </div>
               </Link>
            ) : (<>
               {data?.is_join === "pending" ?
                  <>
                     <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                        <div onClick={() => setCancelReqGrpModal(true)}>
                           <AiOutlineStar style={{ height: '1.5em', width: '1.5em' }} />
                           &nbsp;
                           Cancel Request
                        </div>
                     </Link>
                     {/* CONFIRM cancel join req */}
                     <Modal centered size="sm" show={cancelReqGrpModal} onHide={() => setCancelReqGrpModal(false)}>
                        <Modal.Body>
                           <div className="text-center">
                              <h5 className="mb-3">Are you sure, you want to cancel the join request?</h5>
                              <div className="text-center">
                                 <Button variant="secondary" onClick={() => setCancelReqGrpModal(false)} className="mx-2">
                                    Cancel
                                 </Button>
                                 <Button variant="danger" onClick={leaveFromGroupModal} className="mx-2">
                                    Cancel Request
                                 </Button>
                              </div>
                           </div>
                        </Modal.Body>
                     </Modal>
                  </>
                  :
                  data?.is_join === "not_exists" ? <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                     <div onClick={joinGroupReqFunc}>
                        <AiOutlineUsergroupAdd style={{ height: '1.5em', width: '1.5em' }} />
                        &nbsp;
                        Join Group
                     </div>
                  </Link>
                     :
                     <>
                        <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                           <div onClick={() => setLeaveGroupModal(true)}>
                              <AiOutlineUsergroupAdd style={{ height: '1.5em', width: '1.5em' }} />
                              &nbsp;
                              Leave Group
                           </div>
                        </Link>
                        {/* CONFIRM leave from group */}
                        <Modal centered size="sm" show={leaveGroupModal} onHide={() => setLeaveGroupModal(false)}>
                           <Modal.Body>
                              <div className="text-center">
                                 <h5 className="mb-3">Are you sure, you want to leave from the group?</h5>
                                 <div className="text-center">
                                    <Button variant="secondary" onClick={() => setLeaveGroupModal(false)} className="mx-2">
                                       Cancel
                                    </Button>
                                    <Button variant="danger" onClick={leaveFromGroupModal} className="mx-2">
                                       Cancel Request
                                    </Button>
                                 </div>
                              </div>
                           </Modal.Body>
                        </Modal>
                     </>}
            </>
            )}
         </>
      );
   }

   if (type === "page") {
      return (
         <>
            {data?.user?._id === userSliceData?._id ? (
               <Link to={`/page/${data?._id}/edit`} className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                  <div>
                     <LuClipboardEdit style={{ height: '1.5em', width: '1.5em' }} />{" "}
                     Update Page
                  </div>
               </Link>
            ) : (<>
               {data?.is_join === "pending" ?
                  <>
                     <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                        <div onClick={() => setCancelReqPageModal(true)}>
                           <AiOutlineStar style={{ height: '1.5em', width: '1.5em' }} />
                           &nbsp;
                           Cancel Request
                        </div>
                     </Link>
                     {/* CONFIRM leave from group */}
                     <Modal centered size="sm" show={cancelReqPageModal} onHide={() => setCancelReqPageModal(false)}>
                        <Modal.Body>
                           <div className="text-center">
                              <h5 className="mb-3">Are you sure, you want to cancel request?</h5>
                              <div className="text-center">
                                 <Button variant="secondary" onClick={() => setCancelReqPageModal(false)} className="mx-2">
                                    Cancel
                                 </Button>
                                 <Button variant="danger" onClick={leaveFromPageModal} className="mx-2">
                                    Cancel Request
                                 </Button>
                              </div>
                           </div>
                        </Modal.Body>
                     </Modal>
                  </>
                  :
                  data?.is_join === "not_exists" ?
                     <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                        <div onClick={joinPageReqFunc}>
                           <AiOutlineUsergroupAdd style={{ height: '1.5em', width: '1.5em' }} />
                           &nbsp;
                           Join Page
                        </div>
                     </Link> :
                     <>
                        <Link to={``} title="" className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                           <div onClick={() => setLeavePageModal(true)}>
                              <AiOutlineUsergroupAdd style={{ height: '1.5em', width: '1.5em' }} />
                              &nbsp;
                              Leave Page
                           </div>
                        </Link>
                        {/* CONFIRM leave from group */}
                        <Modal centered size="sm" show={leavePageModal} onHide={() => setLeavePageModal(false)}>
                           <Modal.Body>
                              <div className="text-center">
                                 <h5 className="mb-3">Are you sure, you want to leave the page?</h5>
                                 <div className="text-center">
                                    <Button variant="secondary" onClick={() => setLeavePageModal(false)} className="mx-2">
                                       Cancel
                                    </Button>
                                    <Button variant="danger" onClick={leaveFromPageModal} className="mx-2">
                                       Leave Page
                                    </Button>
                                 </div>
                              </div>
                           </Modal.Body>
                        </Modal>
                     </>}
            </>
            )}
         </>
      );
   }

   else {
      return (
         <>
            {
               userDetailsById?.is_friend === true && userDetailsById?.is_pending === false && <div className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"} onClick={() => setRemoveConnectionModal(true)}>
                  Remove Connection
               </div>}

            {userDetailsById?.is_friend === false && userDetailsById?.is_pending === true &&
               <>
                  {userDetailsById?.is_requested === false ?
                     <div onClick={() => setCancelReqModal(true)} className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                        Cancel Request
                     </div>
                     :
                     <>
                        <div onClick={acceptFriendRequest} className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                           Accept
                        </div>
                        <div onClick={() => setDecReqModal(true)} className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"}>
                           Decline
                        </div>
                     </>
                  }
               </>
            }

            {
               userDetailsById?.is_friend === false && userDetailsById?.is_pending === false && <div className={className !== undefined ? `${className} btn btn-primary` : "btn btn-primary"} onClick={sendRequest}>
                  Send Request
               </div>
            }

            {/* remove connection */}
            <Modal centered size="sm" show={removeConnectionModal} onHide={() => setRemoveConnectionModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to remove connection?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setRemoveConnectionModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={removeMyConnectionModal} className="mx-2">
                           Remove
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
            {/* cancel sent request */}
            <Modal centered size="sm" show={cancelReqModal} onHide={() => setCancelReqModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to cancel request?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setCancelReqModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={cancelRequest} className="mx-2">
                           Cancel Req
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
            {/* decline request */}
            <Modal centered size="sm" show={decReqModal} onHide={() => setDecReqModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to decline request?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDecReqModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={declineRequestModal} className="mx-2">
                           Decline Request
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }
};

export default DashboardAction;
