import React, { useEffect, useReducer, useState } from 'react'
import { AdminMenu } from '../../../components/components'
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import { useCreateMembershipMutation, useGetMembershipFeatureListQuery } from '../../../store/api/Admin/membershipPanelApi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AdminContentWrapper from '../../../components/adminComponent/adminContentWrapper/AdminContentWrapper';

const CreateMembership = () => {
    const navigate = useNavigate();
    const [createMembership] = useCreateMembershipMutation()
    const [validated, setValidated] = useState(false);
    const membershipFeatureList = useGetMembershipFeatureListQuery()
    console.log(membershipFeatureList)


    const keyNameArr = ['can_add_connection', 'can_follow', 'can_unfollow', 'is_fullprofile_view', 'groups_can_added', 'can_post_in_community', 'can_create_page', 'can_create_events', 'member_joint_event', 'abc']
    const initialState = {
        plan_name: "",
        price: "",
        mebership_type: "",
        features: [],
    };
    const createCategoryReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return { ...state, [payload.name]: payload.data };
            case "SET_VALUE":
                return { ...state, [payload.name]: payload.data };
            case "SET_FEATURES":
                return { ...state, features: payload };
            case "CLEAR_STATE":
                return initialState
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(createCategoryReducer, initialState);
    const handleChange = (e) => {
        dispatch({
            type: "SET_STATE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };
    const handleNumber = (e) => {
        dispatch({
            type: "SET_VALUE",
            payload: {
                name: e.target.name,
                data: Number(e.target.value),
            },
        });
    };
    const handleFeature = (input, elem, i) => {
        const dataArr = [...state.features]
        dataArr[i][input.target.name] = input.target.value
        dispatch({
            type: "SET_FEATURES",
            payload: dataArr
        });

    }
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            createMembership(state).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                    dispatch({
                        type: "CLEAR_STATE"
                    });
                    navigate("/admin/plans");
                }
            })
        }
        setValidated(true);
    };

    useEffect(() => {
        if (membershipFeatureList.isLoading === false && membershipFeatureList.isError === false) {
            dispatch({
                type: "SET_FEATURES",
                payload: membershipFeatureList?.data?.data.map((elem, i) => ({ key_name: elem.key, title: elem.value })),
            });
        }
    }, [membershipFeatureList.status])

    console.log(state)

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <AdminContentWrapper>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <h5 className='mb-2'>Add Membership</h5>
                                <Row>
                                    <Form.Group as={Col} controlId="plan_name" className={`col-md-4 mb-3`}>
                                        <Form.Label>Plan Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Plan name"
                                            name="plan_name"
                                            required
                                            value={state.plan_name}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please provide plan name</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="price" className={`col-md-4 mb-3`}>
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter plan price"
                                            name="price"
                                            required
                                            value={state.price}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">Please provide plan price</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="mebership_type" className={`col-md-4 mb-3`}>
                                        <Form.Label>Membership type</Form.Label>
                                        <Form.Select aria-label="Mebership type select" name="mebership_type" onChange={handleChange}>
                                            <option value="">Select</option>
                                            <option value="Monthly">Monthly</option>
                                            <option value="Yearly">Yearly</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">Please select membership type</Form.Control.Feedback>
                                    </Form.Group>

                                    <div className='col-12'>
                                        <h6>Features</h6>
                                        {
                                            state?.features?.length > 0 && state.features.map((elem, i) => {
                                                return (
                                                    <div className='feature_item p-3 mb-2 rounded-2' key={i} style={{ background: 'var(--lightOverOverBg)' }}>
                                                        <h6 className='fs_14 fw-bold text-capitalize'>{elem.key_name.split('_').join(' ')}</h6>
                                                        <Row className="gx-2" key={i}>
                                                            <FloatingLabel as={Col} controlId={`${elem.key_name}_accesbeility`} label="Select accesebility">
                                                                <Form.Select name="accesebility" aria-label="Select accesebility" required onChange={(e) => handleFeature(e, elem, i)}>
                                                                    <option value="">Select accesebility</option>
                                                                    <option value={true}>Yes</option>
                                                                    <option value={false}>No</option>
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">Please select accesebility</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                            <FloatingLabel as={Col} controlId={`${elem.key_name}_plan_name`} className={`col-md-4`} label="Title">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder=" "
                                                                    name="title"
                                                                    required
                                                                    onChange={(e) => handleFeature(e, elem, i)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">Please provide plan name</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                            <FloatingLabel as={Col} controlId={`${elem.key_name}_value`} className={`col-md-4`} label="Value">
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder=" "
                                                                    name="value"
                                                                    required
                                                                    onChange={(e) => handleFeature(e, elem, i)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">Please provide value</Form.Control.Feedback>
                                                            </FloatingLabel>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Row>
                                <div className='text-center d-flex gap-2'>
                                    <button className='btn btn-secondary' type="button" onClick={() => navigate(-1)}>Back</button>
                                    <Button type="submit">Submit</Button>
                                </div>
                            </Form>
                        </AdminContentWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateMembership