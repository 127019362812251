import React, { useEffect, useState } from "react";
import ProfileLayout from "../ProfileLayout";
import {
   DashboardMenuLink,
   FilterWrapper,
   PageCard,
} from "../../../components/components";
import { CreateWidget } from "../../../components/dashboard/Dashboard";
import {
   useMyPagesQuery,
   useAllCountPageListsQuery,
   useAllOtherPageCountListsQuery,
} from "../../../store/api/pageApi";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
   useGetCreatedPageByIdQuery,
} from "../../../store/api/pageApi";
import { Pagination } from "antd";

const Pages = () => {
   const countData = useAllCountPageListsQuery();

   const { userId } = useParams();
   const otherCountData = useAllOtherPageCountListsQuery(userId);
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   const [searchParams, setSearchParams] = useSearchParams();
   const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1);
   const [search, setSearch] = useState(searchParams.get("search") || "");
   const [sort, setSort] = useState(searchParams.get("sort") || "see_all");

   const paginationPageChange = (page) => {
      setPageNo(page);
   }

   const infoObj = {
      pageNo: pageNo,
      searchVal: search,
      sortVal: sort,
   };

   const myAllPages = useMyPagesQuery(infoObj);
   const myAllPagesData = myAllPages?.data?.data;

   useEffect(() => {
      let params = { pageNo };
      if (search) {
         params.search = search;
      }
      if (sort) {
         params.sort = sort;
      }
      setSearchParams(params);
   }, [pageNo, search, sort]);

   // other user
   const otherUserParam = {
      id: userId,
      pageNo: pageNo,
      searchVal: search,
      sortVal: sort,
   };
   const otherUserPage = useGetCreatedPageByIdQuery(otherUserParam);

   return (
      <ProfileLayout handleDisplay="none">
         <FilterWrapper>
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="DashboardMenuLinks">
                     <DashboardMenuLink
                        url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId
                           }/pages`}
                        title="Pages"
                        count={
                           userId === userSliceData._id
                              ? countData.isSuccess
                                 ? countData.data.data.total_pages
                                 : 0
                              : otherCountData.isSuccess
                                 ? otherCountData.data.data.total_pages
                                 : 0
                        }
                     />
                     <DashboardMenuLink
                        url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/pages/joined`}
                        title="Joined Pages"
                        count={
                           userId === userSliceData._id
                              ? countData.isSuccess
                                 ? countData.data.data.total_joined_pages
                                 : 0
                              : otherCountData?.isSuccess
                                 ? otherCountData.data.data.total_joined_pages
                                 : 0
                        }
                     />
                     {userId === userSliceData._id && (
                        <DashboardMenuLink
                           url={`/profile/${userSliceData._id}/pages/pending`}
                           title="Pending Requests"
                           count={
                              countData.isSuccess
                                 ? countData.data.data.total_pending_pages
                                 : 0
                           }
                        />
                     )}
                     {userId === userSliceData._id && (
                        <DashboardMenuLink
                           url={`/profile/${userSliceData._id}/pages/suggested`}
                           title="Suggested Pages"
                           count={
                              countData.isSuccess
                                 ? countData.data.data.total_suggested_pages
                                 : 0
                           }
                        />
                     )}
                  </div>
               </div>
               <div className="col-lg-5">
                  <div className="gx-2 justify-content-md-end row">
                     <div className="col-lg-7 col-md-7 col-sm-7">
                        <form method="post">
                           <input
                              type="text"
                              placeholder="Search.."
                              className="form-control"
                              onChange={(e) => setSearch(e.target.value)}
                              value={searchParams.get("search") || search}
                           />
                        </form>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="select-options">
                           <select
                              className="form-select"
                              onChange={(e) => setSort(e.target.value)}
                              value={searchParams.get("sort") || sort}
                           >
                              <option value={"see_all"}>See All</option>
                              <option value={"a_to_z"}>A to Z</option>
                              <option value={"z_to_a"}>Z to A</option>
                              <option value={"newest"}>Newest</option>
                              <option value={"oldest"}>Oldest</option>
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </FilterWrapper>
         <div className="row g-md-3 g-2 ">
            {
               userId === userSliceData._id && (
                  <div className="col-lg-2 col-md-3 col-6">
                     <CreateWidget title={"Create Page"} url="/profile/pages/create" />
                  </div>
               )
            }

            {myAllPages.isLoading && otherUserPage.isLoading && (
               <div className="col-9">
                  <h5 className="text-center mb-0 py-6 fs_18" style={{ paddingTop: "5rem", paddingBottom: "3rem" }}>
                     Loading...
                  </h5>
               </div>
            )}
            {myAllPages.isError && otherUserPage.isError && (
               <div className="col-9">
                  <h5 className="text-center mb-0 py-6 fs_18" style={{ paddingTop: "5rem", paddingBottom: "3rem" }}>
                     Someting Went Wrong !!
                  </h5>
               </div>
            )}
            {userId === userSliceData._id
               ? myAllPagesData?.length === 0 && (
                  <div className="col-9">
                     <h5 className="text-center mb-0 py-6 fs_18" style={{ paddingTop: "5rem", paddingBottom: "3rem" }}>
                        No Created Page !!
                     </h5>
                  </div>
               )
               : otherUserPage?.data?.data?.length === 0 && (
                  <div className="col-12">
                     <h5
                        className="text-center mb-0 py-6 fs_18"
                        style={{ paddingTop: "5rem", paddingBottom: "3rem" }}
                     >
                        No Created Page !!
                     </h5>
                  </div>
               )}
            {userId === userSliceData._id
               ? myAllPagesData?.map((elem, index) => {
                  return (
                     <div className="col-lg-2 col-md-3 col-6" key={index}>
                        <PageCard data={elem} pageId={elem._id} type="myPage" />
                     </div>
                  );
               })
               : otherUserPage?.data?.data?.map((elem, index) => {
                  return (
                     <div className="col-lg-2 col-md-3 col-6" key={index}>
                        <PageCard data={elem} pageId={elem._id} type="others" />
                     </div>
                  );
               })}
            {/* pagination component */}
            <div>
               {userId === userSliceData._id ? (
                  myAllPages?.data?.total_count > 11 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={myAllPages?.data?.total_count} onChange={paginationPageChange} defaultPageSize={11} />
               ) : (
                  otherUserPage?.data?.total_count > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={otherUserPage?.data?.total_count} onChange={paginationPageChange} defaultPageSize={12} />
               )}
            </div>
         </div>
      </ProfileLayout>
   );
};

export default Pages;