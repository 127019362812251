import React from 'react'
import { NavLink } from 'react-router-dom'
import './Link.css'

const MenuLink = ({ url, title, count, className, noCount,end }) => {
  if (url !== undefined && noCount === undefined) {
    return (
      <NavLink to={url} className={className !== undefined ? `${className} menu_link ${noCount !== undefined && 'noCount'}` : `menu_link ${noCount !== undefined && 'noCount'}`} end>
        {title}
        {noCount === undefined && (
          <span>{count}</span>
        )}
      </NavLink>
    )
  }
  if (url !== undefined && noCount !== undefined && end !== undefined) {
    return (
      <NavLink to={url} className={className !== undefined ? `${className} menu_link ${noCount !== undefined && 'noCount'}` : `menu_link ${noCount !== undefined && 'noCount'}`} end>
        {title}
        {noCount === undefined && (
          <span>{count}</span>
        )}
      </NavLink>
    )
  }
  else if (url !== undefined && noCount !== undefined) {
    return (
      <NavLink to={url} className={className !== undefined ? `${className} menu_link ${noCount !== undefined && 'noCount'}` : `menu_link ${noCount !== undefined && 'noCount'}`}>
        {title}
        {noCount === undefined && (
          <span>{count}</span>
        )}
      </NavLink>
    )
  }

  else {
    return (
      <span className={className !== undefined ? `${className} menu_link` : `menu_link`}>
        {title} <span>{count}</span>
      </span>
    )
  }

}

export default MenuLink