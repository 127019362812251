import React, { useEffect, useState } from 'react';
import { AdminMenu } from '../../../components/components';
import { useGetAdminUserQuery } from '../../../store/api/Admin/userPanelApi';
import EpochTimeToDateTime from '../../../methods/EpochTimeToDateTime';
import './users.css';
import '../adminTable.css';
import UserTableRow from '../../../components/adminComponent/table/tableRow/UserTableRow';
import { Input, Pagination } from 'antd';
import { useSearchParams } from "react-router-dom";
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useGetMembershipListQuery } from '../../../store/api/Admin/membershipPanelApi';

const Users = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    // state to handle page
    const [page, setPageNo] = useState(searchParams.get("page") || 1);
    // state to sorting
    const [sort_by, setSort_by] = useState(searchParams.get("sort_by") || "");
    // state to search
    const [search, setSearch] = useState(searchParams.get("search") || "");
    // state to filtering
    const [membership, setMembership] = useState(searchParams.get("membership") || "");
    const [gender, setGender] = useState(searchParams.get("gender") || "");
    // get membership list from api
    const membershipList = useGetMembershipListQuery();
    // get frontenduser list from api
    const AdminUserList = useGetAdminUserQuery({
        page: page,
        per_page: 10,
        sort_by: sort_by,
        searchKeyword: search,
        membership: membership,
        gender: gender
    })
    //to handle the page no
    const handlePageChange = (page) => {
        setPageNo(page)
    }
    // to update the url with pagination, sorting, filtering
    useEffect(() => {
        let params = {};
        if (page > 1) {
            params.page = page;
        }
        if (sort_by) {
            params.sort_by = sort_by;
        }
        if (search) {
            params.search = search;
        }
        if (membership) {
            params.membership = membership;
        }
        if (gender) {
            params.gender = gender;
        }
        setSearchParams(params);
    }, [page, search, sort_by, membership, gender, setSearchParams])
    // get user info from localStorage
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    // get details about permission
    const [permissionArr, setPermissionArr] = useState("")
    const userPermissionList = useUserPermissionListQuery()
    useEffect(() => {
        if (userPermissionList?.isSuccess === true) {
            let user_permission_arr = userPermissionList?.data?.permissions;
            setPermissionArr(user_permission_arr)
        }
    }, [userPermissionList]);

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center p-2'>
                                <h5 className='mb-0'>Users</h5>
                                <div className='d-flex justify-content-between align-items-center gap-2'>
                                    <Form.Select onChange={(e) => setSort_by(e.target.value)} className='filter-select' aria-label="Default select example">
                                        <option value="">Sort By</option>
                                        <option value="min_post">Min Post</option>
                                        <option value="max_post">Max Post</option>
                                        <option value="closest_birthday">Closest Birthday</option>
                                        <option value="most_login">Most Loggin</option>
                                    </Form.Select>
                                    <Form.Select className='filter-select' aria-label="Default select example" onChange={(e) => setGender(e.target.value)}>
                                        <option value="">Filter By Gender</option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Others</option>
                                    </Form.Select>
                                    <Form.Select onChange={(e) => setMembership(e.target.value)} value={membership} className='filter-select' aria-label="Default select example">
                                        <option value="">Filter By Membership</option>
                                        {membershipList.data?.data.map((el, i) => (
                                            <option key={i} value={el._id}>
                                                {el.plan_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Input placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} style={{ width: 200 }} />

                                </div>
                            </div>
                            <div className='table_head has_toggle'>
                                <div className='row'>
                                    <div className='col-md-4'><div className='table_head_info'>Full Name</div></div>
                                    {/* <div className='col-md-2'><div className='table_head_info text-center'>Reg on</div></div> */}
                                    <div className='col-md-2'><div className='table_head_info'>Membership</div></div>
                                    <div className='col-md-2'><div className='table_head_info'>Total Post</div></div>
                                    <div className='col-md-2'><div className='table_head_info'>Last Loggedin</div></div>
                                    {(userInfo.user_type === 3 && permissionArr.includes("user_block")) && <div className='col-md-2'><div className='table_head_info'>Action</div></div>}
                                    {userInfo.user_type === 1 && <div className='col-md-2'><div className='table_head_info'>Action</div></div>}
                                </div>
                            </div>
                            {
                                AdminUserList.isError && (
                                    <div className='col-12'>
                                        <div className='noData'>
                                            <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                AdminUserList.isLoading && (
                                    <div className='col-12'>
                                        <div className='noData'>
                                            <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                AdminUserList.isSuccess && (
                                    AdminUserList?.data?.data?.length > 0 ? (
                                        AdminUserList?.data?.data?.map((elem, i) => {
                                            return (
                                                <UserTableRow key={i} data={elem} />
                                            )
                                        })
                                    ) : (
                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!!</h5>
                                    )
                                )
                            }
                            {
                                AdminUserList?.data?.data?.length > 0 && (
                                    <div className='mt-3 text-center'>
                                        <Pagination
                                            defaultCurrent={1}
                                            current={Number(page)}
                                            size="small"
                                            total={AdminUserList.data?.totalUsers}
                                            defaultPageSize={10}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users