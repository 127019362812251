import React from "react";
import searchImg from "../../../assets/images/resources/baner-forum.png";
import { FaSearch } from "react-icons/fa";
import "./search.css";

const SearchContainer = () => {
   return (
      <div className="page-header faqPageHeader">
         <div className="header-inner faqHeaderInner">
            <h2>Faq's</h2>
            <form method="post">
               <input type="text" placeholder="Ask Question" />
               <button type="submit">
                  <FaSearch />
               </button>
            </form>
            <p>
               Welcome to Ubuntu Social Network. Here you’ll find all questions
               about the Ubuntu usages in the whole template and ready made
               elemets as you want.
            </p>
         </div>
         <figure>
            <img src={searchImg} alt="" />
         </figure>
      </div>
   );
};

export default SearchContainer;
