import React, { useEffect, useState } from 'react'
import ProfileLayout from '../../ProfileLayout';
import { DashboardMenuLink, FilterWrapper, GroupCard } from '../../../../components/components';
import { Dropdown, Pagination } from 'antd';
import { Button } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useJoinedGroupsQuery, useMyGroupsQuery, useSuggestedGroupsQuery, useGetPendingInvitationListQuery, useAllCountGroupListsQuery } from '../../../../store/api/groupApi';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SuggestedGroups = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const { userId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [sort, setSort] = useState(searchParams.get("sort") || "see_all")
    let countData = useAllCountGroupListsQuery()

    const paginationPageChange = (page) => {
        setPageNo(page);
    }

    const infoObj = {
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }

    const suggestedGroups = useSuggestedGroupsQuery(infoObj);
    const suggestedGroupsData = suggestedGroups?.data?.data;

    useEffect(() => {
        let params = { pageNo };
        if (search) {
            params.search = search;
        }
        if (sort) {
            params.sort = sort;
        }

        setSearchParams(params);
    }, [pageNo, search, sort]);

    return (
        <ProfileLayout handleDisplay="none">
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/groups`} title="Groups" count={countData.isSuccess ? countData.data.data.total_groups : 0} />
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/groups/joined`} title="Joined Groups" count={countData.isSuccess ? countData.data.data.total_joined_groups : 0} />
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/groups/pending`} title="Pending Groups" count={countData.isSuccess ? countData.data.data.total_pending_groups : 0} />
                            <DashboardMenuLink url={`/profile/${userSliceData._id}/groups/suggested`} title="Suggested Groups" count={countData.isSuccess ? countData.data.data.total_suggested_groups : 0} />
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="gx-2 justify-content-md-end row">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <form method="post">
                                    <input
                                        type="text"
                                        placeholder="Search.."
                                        className="form-control"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={searchParams.get("search") || search}
                                    />
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="select-options">
                                    <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                                        <option value={"see_all"}>See All</option>
                                        <option value={"a_to_z"}>A to Z</option>
                                        <option value={"z_to_a"}>Z to A</option>
                                        <option value={"newest"}>Newest</option>
                                        <option value={"oldest"}>Oldest</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className='row gx-4'>
                    {
                        suggestedGroups.isLoading && (
                            <div className="col-12">
                                <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                            </div>
                        )
                    }
                    {
                        suggestedGroups.isError && (
                            <div className="col-12">
                                <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong !!</h5>
                            </div>
                        )
                    }

                    {suggestedGroupsData?.map((elem, index) => {
                        return <div className='col-lg-2 col-md-3 col-6' key={index}>
                            <GroupCard data={elem} groupId={index} type={"suggested"} />
                        </div>
                    })}
                    {suggestedGroupsData?.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">No Suggested Group !!!</h5>
                    </div>}
                </div>

                {/* pagination component */}
                <div>
                    {userId === userSliceData._id && (
                        suggestedGroups?.data?.total_count > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={pageNo} total={suggestedGroups?.data?.total_count} onChange={paginationPageChange} defaultPageSize={12} />
                    )}
                </div>
            </div>
        </ProfileLayout>
    )
}

export default SuggestedGroups