import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const groupApi = createApi({
    reducerPath: "groupApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        // set access token to headers
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["group"],
    endpoints: (builder) => ({
        // ALL TYPE OF GROUP LISTING
        // GET MY ALL CREAETD GROUPS
        myGroups: builder.query({
            query: (data) => ({
                url: "api/groups/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 11,
                    role: "admin",
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["group"],
        }),
        // GET ALL JOINED GROUPS
        joinedGroups: builder.query({
            query: (data) => ({
                url: "api/groups/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 12,
                    role: "member",
                    status: 'active',
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                },
            }),
            providesTags: ["group"],
        }),
        //pending invitation list from pages
        getPendingInvitationList: builder.query({
            query: (data) => ({
                url: "api/groups/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 12,
                    role: "member",
                    join_type: "invited",
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["group"],
        }),
        // GET ALL SUGGESTED GROUPS
        suggestedGroups: builder.query({
            query: (data) => ({
                url: "api/groups/suggested",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 12,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["group"],
        }),

        // DETAILS GROUP MEMBER LISTING
        // GROUP ACTIVE MEMBER
        getGroupActiveMembers: builder.query({
            query: (data) => ({
                url: "api/groups/details",
                method: "GET",
                params: {
                    group_id: data.group_id,
                    member_page: data.pageNo,
                    member_per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                    status: 'active',
                    role: 'member',
                },
            }),
            providesTags: ["group"],
        }),
        // GROUP PENDING MEMBER
        getGroupPendingMembers: builder.query({
            query: (data) => ({
                url: "api/groups/details",
                method: "GET",
                params: {
                    group_id: data.group_id,
                    member_page: data.pageNo,
                    member_per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                    status: 'pending',
                    role: 'member',
                    join_type:"invited"
                },
            }),
            providesTags: ["group"],
        }),
        // SUGGESTED GROUP MEMBER
        suggestedGroupMember: builder.query({
            query: (data) => ({
                url: "api/groups/member/suggested",
                method: "GET",
                params: {
                    group_id: data.group_id,
                    page: data.pageNo,
                    per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                },
            }),
            providesTags: ["group"],
        }),

        // CREATE NEW GROUP
        createGroup: builder.mutation({
            query: (groupData) => {
                return {
                    url: "api/groups/create",
                    method: "POST",
                    body: groupData,
                };
            },
            invalidatesTags: ["group"],
        }),
        myGroupListForJob: builder.query({
            query: () => ({
                url: "api/groups/list",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 100,
                    role: "admin",
                    sort_by: "see_all"
                },
            }),
            providesTags: ["group"],
        }),
        // DELETE MY GROUP
        deleteGroup: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/groups/delete`,
                    method: "DELETE",
                    body: {
                        "group_id": ID
                    }
                };
            },
            invalidatesTags: ["group"],
        }),
        // GET SINGLE GROUP DATA
        getSingleGroup: builder.query({
            query: (id) => ({
                url: "api/groups/details",
                method: "GET",
                params: {
                    group_id: id
                },
            }),
            providesTags: ["group"],
        }),

        // UPDATE GROUP DETAILS
        updateGroup: builder.mutation({
            query: (data) => ({
                url: "api/groups/update",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["group"],
        }),
        // Request to join group
        joinRequest: builder.mutation({
            query: (data) => ({
                url: "api/groups/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["group"],
        }),
        // accept pending member request
        acceptJoinRequest: builder.mutation({
            query: (data) => ({
                url: "api/groups/update",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["group"],
        }),
        // remove group member
        removeGroupMember: builder.mutation({
            query: (data) => ({
                url: "api/groups/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["group"],
        }),
        // invite as member
        inviteFollower: builder.mutation({
            query: (data) => ({
                url: "api/member/invite",
                method: "PUT",
                body: {
                    meta_type: "Group",
                    meta_data: data.groupId,
                    members: {
                        user_id: data.userId,
                        role: "member"
                    }
                }
            }),
            invalidatesTags: ["group"],
        }),
        // accept pending request to join a page
        acceptPendingRequest: builder.mutation({
            query: (data) => ({
                url: "api/member/accept-invitation",
                method: "PUT",
                body: {
                    meta_type: "Group",
                    meta_data: data.groupId,
                    members: {
                        user_id: data.userId,
                        role: "member"
                    }
                }
            }),
            invalidatesTags: ["group"],
        }),
        // decline pending request to join a page
        declinePendingRequest: builder.mutation({
            query: (data) => ({
                url: "api/member/remove-invitation",
                method: "DELETE",
                body: {
                    meta_type: "Group",
                    meta_data: data.groupId,
                    members: {
                        user_id: data.userId,
                        role:"member"
                    }
                }
            }),
            invalidatesTags: ["group"],
        }),

        // other user group apis
        // GET CREATED GROUP
        getCreatedGroupById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {
                    module: "Group",
                    user_id: data.id,
                    role: "admin",
                    group_id: data.group_id,
                    page: data.pageNo,
                    per_page: 12,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                }
            }),
            providesTags: ["group"],
        }),
        // GET JOINED GROUP
        getJoinedGroupById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {    
                    module: "Group",
                    user_id: data.id,
                    role: "member",
                    group_id: data.group_id,
                    page: data.pageNo,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal,
                    per_page: 12,
                }
            }),
            providesTags: ["group"],
        }),
        // remove group member or leave from group
        removeGroupMemberOthers: builder.mutation({
            query: (data) => ({
                url: "api/groups/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["group"],
        }),
        // Request to join group
        joinRequestOthers: builder.mutation({
            query: (data) => ({
                url: "api/groups/member/add-or-remove",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["group"],
        }),

        // Album APIs
        getAlbumGroup: builder.query({
            query: (id) => {
                return {
                    url: "api/album",
                    method: "GET",
                    params: {
                        page: 1,
                        meta_type: "Group",
                        meta_data: id
                    }
                };
            },
            providesTags: ["group"],
        }),

        getAlbumGroupOthers: builder.query({
            query: (data) => {
                return {
                    url: "api/users/profile-details",
                    method: "GET",
                    params: data
                };
            },
            providesTags: ["group"],
        }),

        allCountGroupLists: builder.query({
            query: () => {
              return {
                url: `api/all/count`,
                method: "GET",
                params: {
                  module: "Group"
                },
              }
            },
            providesTags: ["group"],
          }) ,

          allOtherGroupCountLists: builder.query({
            query: (id) => {
              return {
                url: `api/all/count`,
                method: "GET",
                params: {
                  module: "Group",
                  user_id:id
                },
              }
            },
            providesTags: ["group"],


          })
    }),
});

export const { 
    useAllOtherGroupCountListsQuery,
     useGetAlbumGroupOthersQuery,
      useGetAlbumGroupQuery,
       useCreateGroupMutation, 
        useMyGroupsQuery, 
         useJoinedGroupsQuery,
          useDeleteGroupMutation,
           useSuggestedGroupsQuery,
            useGetSingleGroupQuery,
             useUpdateGroupMutation,
              useJoinRequestMutation,
               useGetGroupActiveMembersQuery,
                useGetGroupPendingMembersQuery,
                 useAcceptJoinRequestMutation,
                  useRemoveGroupMemberMutation,
                   useSuggestedGroupMemberQuery,
                    useInviteFollowerMutation,
                     useGetPendingInvitationListQuery,
                      useAcceptPendingRequestMutation,
                       useDeclinePendingRequestMutation,
                        useMyGroupListForJobQuery,
                         useGetCreatedGroupByIdQuery,
                          useGetJoinedGroupByIdQuery,
                           useRemoveGroupMemberOthersMutation,
                            useJoinRequestOthersMutation,
                             useAllCountGroupListsQuery 
                            } = groupApi;
