import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from "react-player";
import ReactSlider from "react-slider";
import {FaPause, FaPlay, FaVolumeMute, FaVolumeUp } from "react-icons/fa"
import './video.css'
import { useDispatch } from 'react-redux';
import { setActiveVideo } from '../../store/slice/videoSlice';

const Video = ({url, id}) => {
    const dispatch = useDispatch()
    const [videoInView, setVideoInView] = useState(false);
    const [videoState, setVideoState] = useState({
        loading: true,
        buffering: false,
        playing: false,
        played: 0,
        seeking: false,
        speeker: true,
    });
    const videoPlayerRef = useRef(null);
    const handlePlayPause = () => {
        setVideoState({ ...videoState, playing: !videoState.playing });
        // dispatch(setActiveVideo(id || ""))
    };
    const handleSliderChange = (value) => {
        setVideoState({ ...videoState, playing: false, played: value / 100 })
    };
    const handleSliderSeek = (value) => {
        setVideoState({ ...videoState, playing: false, played: value / 100 })     
    };
    const handleSliderSeekEnd = (value) => {
        setVideoState({ ...videoState, playing: true, played: value / 100 })
        videoPlayerRef.current.seekTo(value / 100)
    };
    const toggleMute = () => {
        setVideoState({ ...videoState, speeker: !videoState.speeker})
    }
    const lazyLoad = () => {
        if (elementInViewPort()) {
            console.log('Video in viewport and playing')
            handlePlayPause()
        } else {
            if(videoState.playing === true){
                console.log('Video in viewport and paused')
                handlePlayPause()
            }         
        }
    };
      
    // CHECKING IMAGE IS IN VIEWPORT OR NOT || RETURN TRUE IF IMAGE IN VIEWPORT 
    const elementInViewPort = () => {
        const clientRect = videoPlayerRef?.current?.wrapper?.children[0]?.getBoundingClientRect();
        if(clientRect){
            return clientRect.top >= 0 && clientRect.left >= 0 && clientRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && clientRect.right <= (window.innerWidth || document.documentElement.clientWidth)
        } else {
            return false
        }
    };

    useEffect(()=>{
        window.onscroll = window.addEventListener("scroll", lazyLoad);
        return () => {
            // remove the lazyLoad method
            window.removeEventListener("scroll", lazyLoad);
        };
    }, [])

    return (
        <div className='ubuntuPlayer'>
            {videoState.buffering && <div className='loading'><div className="lds-ripple"><div></div><div></div></div></div>}
            <div onClick={handlePlayPause}>
                <ReactPlayer
                    id={id}
                    className="player"
                    url={url}
                    width="100%"
                    height="100%"
                    playing={videoState.playing}
                    progressInterval={0.5}
                    progressFrequency={1000}
                    onProgress={(progress) => {
                        if (!videoState.seeking) {
                            setVideoState({ ...videoState, played: progress.played });
                        }
                    }}
                    ref={videoPlayerRef}
                    onReady={()=>()=>{setVideoState({ ...videoState, loading: false})}}
                    muted={videoState.speeker}
                    onBuffer={()=>{setVideoState({ ...videoState, buffering: true })}}
                    onBufferEnd={()=>{setVideoState({ ...videoState, buffering: false })}}
                    onEnded={()=>{setVideoState({ ...videoState, playing: false })}}
                />
            </div>
            {
                !videoState.buffering && (
                    <div className='controls'>
                        <button className="control_btn playPause" onClick={handlePlayPause}>
                            {videoState.playing ? <FaPause /> : <FaPlay/>}
                        </button>
                        <ReactSlider
                            className="customSlider"
                            trackClassName="customSlider-track"
                            thumbClassName="customSlider-thumb"
                            defaultValue={0}
                            value={Math.round(parseFloat(videoState.played) * 100)}
                            onChange={handleSliderChange}
                            onBeforeChange={handleSliderSeek}
                            onAfterChange={handleSliderSeekEnd}
                        />
                        <button className="control_btn MuteUnmute" onClick={toggleMute}>
                            {videoState.speeker ? <FaVolumeMute/> : <FaVolumeUp/>}
                        </button>
                    </div>
                )
            }
        </div>
    )
}

export default Video