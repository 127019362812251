import React, { useState } from 'react'
import './tableRow.css'
import { Button, Modal } from 'react-bootstrap'
import { useChangeStatusMembershipMutation, useDeleteMembershipMutation } from '../../../../store/api/Admin/membershipPanelApi'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import FeaturedList from '../../FeaturedList/FeaturedList'
import { useSelector } from 'react-redux'
import { useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'

const MembershipTableRow = ({ data }) => {
    const [statusModal, setStatusModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [updateStatus] = useChangeStatusMembershipMutation()
    const [deleteMembership] = useDeleteMembershipMutation()

    const handleStatus = () => {
        updateStatus({
            status: data.status === 1 ? 0 : 1,
            _id: data._id
        }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
            setStatusModal(false)
        })
    }

    const handleDelete = () => {
        deleteMembership({ _id: data._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
            setDeleteModal(false)
        })
    }

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <tr>
            <td>
                <div className='table_info'>{data.plan_name}</div>
            </td>
            <td>
                <div className='table_info'>{data.mebership_type}</div>
            </td>
            <td>
                <div className='table_info'>{data.price}</div>
            </td>
            <td>
                <div className='table_info'>
                    <FeaturedList data={data.features} />
                </div>
            </td>
            <td className=''>
                <div className='table_actions text-center'>
                    {(userInfo.user_type === 3 && user_permission_arr.includes("plan_edit")) && <Link to={`edit/${data._id}`}>Edit</Link>}
                    {userInfo.user_type === 1 && <Link to={`edit/${data._id}`}>Edit</Link>}

                    {(userInfo.user_type === 3 && user_permission_arr.includes("plan_active")) && <button onClick={() => setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                        {data.status === 1 ? 'Active' : 'Deactive'}
                    </button>}
                    {userInfo.user_type === 1 && <button onClick={() => setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                        {data.status === 1 ? 'Active' : 'Deactive'}
                    </button>}

                    {(userInfo.user_type === 3 && user_permission_arr.includes("plan_delete")) && <button onClick={() => setDeleteModal(!statusModal)} className='bg-danger-subtle text-danger'>
                        Delete
                    </button>}
                    {userInfo.user_type === 1 && <button onClick={() => setDeleteModal(!statusModal)} className='bg-danger-subtle text-danger'>
                        Delete
                    </button>}
                </div>
                {/* BLOCK MODAL */}
                <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to {data.status !== 1 ? 'Active' : 'Deactive'} this plan?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={handleStatus} className="mx-2">
                                    {data.status !== 1 ? 'Active' : 'Deactive'}
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Delete MODAL */}
                <Modal centered size="sm" show={deleteModal} onHide={() => setDeleteModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to delete this plan?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={handleDelete} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </td>
        </tr>
    )
}

export default MembershipTableRow