import React, { useState } from 'react';
import { AdminMenu } from '../../../components/components';
import { Input, Pagination } from 'antd';
import { useGetAllNotificationQuery } from '../../../store/api/Admin/AdminNotification';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import NotificationRow from '../../../components/adminComponent/notification/NotificationRow';
import { FaPlus } from 'react-icons/fa';

const EventNotification = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPageNo] = useState(searchParams.get("page") || 1)
    const allNotificationList = useGetAllNotificationQuery("Event");
    const handlePageChange = (page) => {
        setPageNo(page)
    }

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center p-2'>
                                <h5 className='mb-0'>Notification</h5>
                                <Link to="/admin/notification/create" className="createBtn ms-auto me-2">
                                    <FaPlus /> Create
                                </Link>
                                {/* <div className=''>
                                    <Input placeholder="Search..." value={"search"} onChange={"onSearch"} style={{ width: 200 }} />
                                </div> */}
                            </div>
                            <table className='adminTable'>
                                <thead>
                                    <tr>
                                        <th><div className='table_head_info'>Title</div></th>
                                        <th><div className='table_head_info'>Description</div></th>
                                        <th><div className='table_head_info'>Type</div></th>
                                        <th><div className='table_head_info'>Notification for</div></th>
                                        <th><div className='table_head_info'>Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        allNotificationList.isError && (
                                            <tr className='noData'>
                                                <td colSpan={6}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        allNotificationList.isLoading && (
                                            <tr className='noData'>
                                                <td colSpan={6}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        allNotificationList.isSuccess && (
                                            allNotificationList?.data?.data?.notifications.length > 0 ? (
                                                allNotificationList?.data?.data?.notifications.map((elem, i) => {
                                                    return (
                                                        <NotificationRow data={elem} key={i} />
                                                    )
                                                })
                                            ) : (
                                                <tr className='noData'>
                                                    <td colSpan={6}>
                                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                            {
                                allNotificationList?.data?.data.length > 0 && (
                                    <div className='mt-3 text-center'>
                                        <Pagination
                                            defaultCurrent={1}
                                            current={Number(page)}
                                            size="small"
                                            total={allNotificationList?.data?.total_count}
                                            defaultPageSize={10}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventNotification
