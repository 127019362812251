import React from "react";
import WidgetWrapper from "../WidgetWrapper";
import styles from "./shortcut.module.css";
import { AiOutlineBars, AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { BsCalendarEvent } from "react-icons/bs";
import { HiMiniUserGroup } from "react-icons/hi2";
import { FiHelpCircle } from "react-icons/fi";
import { GoGraph } from "react-icons/go";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Shortcut = () => {
   const userData = useSelector((store) => {
      return store.userSlice.userSliceData;
   });
   return (
      <WidgetWrapper title={"Shortcuts"}>
         <ul className={styles.optionContainer}>
            <li>
               <AiOutlineHome />
               <Link to="/" title="">
                  Home
               </Link>
            </li>
            <li>
               <AiOutlineUser />
               <Link to={`/profile/${userData._id}/connections`} title="">
                  Connections
               </Link>
            </li>
            <li>
               <HiUserGroup />
               <Link to={`/profile/${userData._id}/groups`} title="">
                  Groups
               </Link>
            </li>
            <li>
               <BsCalendarEvent />
               <Link to={`/profile/${userData._id}/events`} title="">
                  Events
               </Link>
            </li>
            <li>
               <HiMiniUserGroup />
               <Link to="/community" title="">
                  Community
               </Link>
            </li>
            <li>
               <AiOutlineBars />
               <Link to="/activity" title="">
                  Activities
               </Link>
            </li>
            <li>
               <GoGraph />
               <Link to="" title="">
                  Account Stats
               </Link>
            </li>
            <li>
               <FiHelpCircle />
               <Link to="/help" title="">
                  Help
               </Link>
            </li>
         </ul>
      </WidgetWrapper>
   );
};

export default Shortcut;
