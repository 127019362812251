import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDeleteMarketPlaceMutation, useUpdateMarketPlaceStatusMutation } from '../../../../store/api/Admin/adminMarkrtPlaceApi'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'

const MarketplaceTableRow = ({ data }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [marketPlaceDelete] = useDeleteMarketPlaceMutation()
    const [statusModal, setStatusModal] = useState(false)
    const [statusChange] = useUpdateMarketPlaceStatusMutation()

    const deleteEmployment = () => {
        marketPlaceDelete(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
        })
    }


    const handleStatusChange = () => {
        statusChange(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
            setStatusModal(false)
        })
    }

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <>
            <tr>
                <td className='d-flex align-items-center'>
                    <div className='table_info d-flex align-items-center'>
                        {data?.images[0]?.image_url && <img className='market-img-small' src={data?.images[0]?.image_url} alt="Not found" />}
                        <Link target="_blank" to={`/marketplace/${data._id}`}>
                            <div className='table_info'>{data.product_name}</div>
                        </Link>
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        <div className='table_info'>{data?.category?.category_name}</div>
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        <div className='table_info'>{data?.selling_price_with_currency}</div>
                    </div>
                </td>
                <td className=''>
                    <div className='table_info d-flex align-items-center'>
                        {data?.user?.profile_picture[0]?.image_url && <img className='market-img-small' src={data?.user?.profile_picture[0]?.image_url} alt="Not found" />}
                        <Link target="_blank" to={`/profile/${data?.user?._id}/timeline`}>
                            <div className='table_info'>{data?.user?.fullName}</div>
                        </Link>
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        <div className='table_info'>None</div>
                    </div>
                </td>
                <td>
                    <div className='table_actions text-center'>
                        {(userInfo.user_type === 3 && user_permission_arr.includes("marketplace_active")) && <button onClick={() => setStatusModal(true)} className={data.is_active === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.is_active === 1 ? "Active" : "Deactive"}
                        </button>}
                        {userInfo.user_type === 1 && <button onClick={() => setStatusModal(true)} className={data.is_active === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.is_active === 1 ? "Active" : "Deactive"}
                        </button>}
                    </div>
                    {/* Delete MODAL */}
                    <Modal centered size="sm" show={deleteModal} onHide={() => setDeleteModal(false)}>
                        <Modal.Body>
                            <div className="text-center">
                                <h5 className="mb-3">Are you sure, you want to Delete this plan?</h5>
                                <div className="text-center">
                                    <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={deleteEmployment} className="mx-2">
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* Status MODAL */}
                    <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                        <Modal.Body>
                            <div className="text-center">
                                <h5 className="mb-3">Are you sure, you want to {data.status !== 1 ? 'Active' : 'Deactive'} this plan?</h5>
                                <div className="text-center">
                                    <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={handleStatusChange} className="mx-2">
                                        {data.is_active != 1 ? 'Active' : 'Deactive'}
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </td>
            </tr>
        </>
    )
}

export default MarketplaceTableRow