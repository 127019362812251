import React from "react";
import styles from "./CalenderInfo.module.css";
import { BsCalendar3 } from "react-icons/bs";
import { Link } from "react-router-dom";

const CalenderInfo = ({ data }) => {
   let startDuration = data?.timing[0];
   let StartDate = startDuration?.split(" ")[0];
   let startDay = StartDate?.slice(-2)

   return (
      <div className={styles.profileAuthor}>
         <div className={styles.profileAuthorThumb}>
            <div className={styles.dateContainer} alt="author">
               <div className={styles.blank}>
                  Event Date
                  <BsCalendar3 />
               </div>
               <div className={styles.date}>{startDay}</div>
            </div>
         </div>

         <div className="author-content">
            <Link className="h4 author-name" to="">
               {data?.title}
            </Link>
            <div className="h5 country" style={{ color: "var(--bs-primary)" }}>
               {data?.timing[0]}
            </div>
         </div>
      </div>
   );
};

export default CalenderInfo;
