import React, { useState } from 'react'
import { AdminMenu } from '../../../components/components';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { useGetMembershipListQuery } from '../../../store/api/Admin/membershipPanelApi';
import MembershipTableRow from '../../../components/adminComponent/table/tableRow/MembershipTableRow';
import { useSelector } from 'react-redux';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';

const Membership = () => {
    const membershipList = useGetMembershipListQuery()

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center p-2'>
                                <h5 className='mb-0'>Membership</h5>
                                {(userInfo.user_type === 3 && user_permission_arr.includes("plan_create")) && <Link to="/admin/plans/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                                {userInfo.user_type === 1 && <Link to="/admin/plans/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                            </div>
                            <table className='adminTable'>
                                <thead>
                                    <tr>
                                        <th><div className='table_head_info'>Name</div></th>
                                        <th><div className='table_head_info'>Type</div></th>
                                        <th><div className='table_head_info'>Price</div></th>
                                        <th><div className='table_head_info'>Features</div></th>
                                        {(userInfo.user_type === 3 && user_permission_arr.includes("plan_edit"||"plan_active"||"plan_delete")) && <th><div className='table_head_info text-center'>Action</div></th>}
                                        {userInfo.user_type === 1 && <th><div className='table_head_info text-center'>Action</div></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        membershipList.isError && (
                                            <tr className='noData'>
                                                <td colSpan={5}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        membershipList.isLoading && (
                                            <tr className='noData'>
                                                <td colSpan={5}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        membershipList.isSuccess && (
                                            membershipList.data?.data.length > 0 ? (
                                                membershipList.data?.data?.map((elem, i) => {
                                                    return (
                                                        <MembershipTableRow key={i} data={elem} />
                                                    )
                                                })
                                            ) : (
                                                <tr className='noData'>
                                                    <td colSpan={5}>
                                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Membership