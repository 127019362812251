import React from 'react'
import { BirthdayWidget, CreateYourPageWidget, Quote, SendMoneyToConnection } from '../../../components/components';
import { useTransactionHistoryQuery } from '../../../store/api/walletApi';

const WalletHistory = () => {
    const transactionHistory = useTransactionHistoryQuery()
    return (
        <div className="gap2 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SendMoneyToConnection />
                        <Quote />
                        <BirthdayWidget />
                        <CreateYourPageWidget />
                    </div>
                    <div className="col-md-9">
                        <div className='d-md-flex justify-content-between align-items-center mb-2'>
                            <h5 className='mb-0 fw-bold'>Wallet History</h5>
                        </div>
                        {
                            transactionHistory?.data?.transactions.length > 0 ? (
                                transactionHistory?.data?.transactions.map((el, i) => (
                                    <div style={{ border: "1px solid var(--border-color)", padding: '10px', borderRadius: '5px', marginTop: '10px' }} key={i}>
                                        <h6 className="mb-1 fw-bold">{el.description}</h6>
                                        {el.receiver_id._id === el.sender_id._id ? (
                                            <div>R{el.amount} has been added to your wallet</div>
                                        ) : (
                                            <div>You send R{el.amount} to {el.receiver_id.fullName}</div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <h6 className='text-center py-5 mb-0 mt-5'>No Data Found !!</h6>
                            )
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default WalletHistory
