import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useUpdatePasswordMutation } from "../../../store/api/authApi";
import { ErrorAlert, SuccessAlert } from "../../../components/components";

const UpdatePassword = () => {
  const [validated, setValidated] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  let initialState =
  {
    current_password: '',
    new_password: '',

  }
  const [state, setState] = useState(initialState);
  let [update_Password, response] = useUpdatePasswordMutation()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const submitLogin = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() == true) {
      // Proceed with form submission
      await update_Password(state).then((res) => {
        if (res?.data?.error === 0) {
          setTimeout(() => {
            navigate("/login");
          }, [3000]);
          handleShow();
        }
        if (res?.error?.data?.error === 1) {
          handleShow();
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    setValidated(true);

  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });

  };

  return (
    <>
      {/* <GoBackWrapper> */}
      <div className="we-login-register-resett">
        <div className="form-title-set" style={{ fontSize: "20px", fontWeight: "500", textTransform: "uppercase", color: "var(--bs-text-blue)" }}>
          Update Password
        </div>
        <Form
          noValidate
          validated={validated}
          className="we-form"
          method="post"
          onSubmit={submitLogin}
        >
          <div>
            <Form.Group className="mb-2 pb-1">
              <Form.Label>Current Password*</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  placeholder="current_password"
                  name="current_password"
                  value={state.current_password}
                  onChange={handleChange}
                  required
                />
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                >
                  {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </InputGroup.Text>

              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2 pb-1">
              <Form.Label>New Password*</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passwordVisible ? "text" : "password"}
                  placeholder="new_password"
                  name="new_password"
                  value={state.new_password}
                  onChange={handleChange}
                  required
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$"
                />
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                >
                  {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  6 to 20 characters which contain at least one numeric digit, one
                  uppercase and one lowercase letter
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>
          <Button variant="primary" type="submit" className="register-btn">
            Update Password
          </Button>
        </Form>

      </div>
      {/* Modal for message */}
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          {response.isSuccess === true && (
            <SuccessAlert
              title={response?.isSuccess === true ? "Password Updated !!" : ""}
            />
          )}
          {response.isSuccess === false && (
            <ErrorAlert
              title={
                response?.isSuccess === false ? response.error?.data.Message : "Somthing Went Wrong!!"
              }
            />
          )}
        </Modal.Body>
      </Modal>
      {/* </GoBackWrapper> */}
    </>
  );
};

export default UpdatePassword;
