import React from "react";
import SearchContainer from "./searchContainer/SearchContainer";
import MainBody from "./mainBody/MainBody";
import Shortcut from "../../components/widgets/shortcutWidget/Shortcut";

const Faq = () => {
   return (
      <div className="full_height">
         <SearchContainer />

         <div className="gap gray-bg">
            <div className="container">
               <div className="row" id="page-contents">
                  <div className="col-lg-3">
                     <aside className="sidebar">
                        <Shortcut />
                     </aside>
                  </div>
                  <MainBody />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Faq;
