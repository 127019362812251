import React from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./PhotoWidget.module.css";
import WidgetWrapper from "../WidgetWrapper";
import { useSelector } from "react-redux";
import { useGetAlbumPageOthersQuery, useGetSinglePageQuery } from "../../../store/api/pageApi";
import { useGetMyPageAlbumDataQuery } from "../../../store/api/albumApi";
import { useGetAlbumGroupOthersQuery, useGetAlbumGroupQuery, useGetSingleGroupQuery } from "../../../store/api/groupApi";
const PhotoWidget = ({ link, type }) => {

   const { pageId } = useParams();

   // get single Page Data
   const singlePage = useGetSinglePageQuery(pageId);
   let pageWholeData = singlePage?.data?.data;

   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   //for photo

   const AlbumParamsOthers = {
      page: 1,
      per_page: 100,
      module: "Album",
      user_id: pageWholeData?.user?._id,
      meta_type: "Page",
      meta_data: pageId
   }
   const AlbumDataOthers = useGetAlbumPageOthersQuery(AlbumParamsOthers)

   const AlbumData = useGetMyPageAlbumDataQuery(pageId)

   //group
   const { groupId } = useParams();

   // get single Event Data
   const singleGroup = useGetSingleGroupQuery(groupId);
   let groupWholeData = singleGroup?.data?.data;

   const AlbumParamsOtherss = {
      page: 1,
      per_page: 100,
      module: "Album",
      user_id: groupWholeData?.user?._id,
      meta_type: "Group",
      meta_data: groupId
   }
   const AlbumDataOtherss = useGetAlbumGroupOthersQuery(AlbumParamsOtherss)

   const AlbumDataa = useGetAlbumGroupQuery(groupId)

   if (type === "page") {
      var AllImages = pageWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;
   } else if (type === "group") {
      var AllImages = groupWholeData?.user?._id === userSliceData._id ? AlbumDataa?.data?.data : AlbumDataOtherss?.data?.data;
   }

   const AllImagesData = AllImages?.filter(function (el) {
      return el.name === "uploads";
   })[0]?.gallery;


   return (
      <WidgetWrapper title={"Photos"} linkName={"See All"} linkTo={link}>
         {AllImagesData?.length === 0 ? <div className="text-center mb-3" >No Videos Found !! </div>
            :
            <ul className={styles.recentPhotos}>
               {
                  AllImagesData?.map((elem) => {
                     return (
                        <li>
                           <Link
                              data-strip-group-options="loop: false"
                              data-strip-group="mygroup"
                              title
                              to=""
                              className=""
                           >
                              <img alt="" src={elem?.file[0]?.image_url} />
                           </Link>
                        </li>
                     )
                  })
               }
            </ul>
         }

      </WidgetWrapper>
   );
};

export default PhotoWidget;
