import React, { useReducer, useState } from 'react'
import { AdminMenu } from '../../../components/components';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetCountryQuery } from '../../../store/api/countryApi';
import { DatePicker } from 'antd';
import { useGetRoleListQuery } from '../../../store/api/Admin/adminRolePermissionApi';
import { useGetMembershipListQuery } from '../../../store/api/Admin/membershipPanelApi';
import { useCreateSystemUserMutation } from '../../../store/api/Admin/userPanelApi';
import toast from 'react-hot-toast';

const initialState = {
    first_name: "",
    last_name: "",
    emailorphone: "",
    password: "",
    confirm_password: "",
    gender: "",
    dob: 0,
    terms_condition: true,
    roles: "",
    membership_id: "",
    country: {},
    area_of_interests: null
}

const SystemUserAdd = () => {
    const navigate = useNavigate();
    // state -> to verify date validation
    const [dateValid, setDateValid] = useState(null);
    // state -> form validation
    const [validated, setValidated] = useState(false);
    // get country list from api
    const country = useGetCountryQuery();
    // get role list from api
    const roleList = useGetRoleListQuery();
    // get membership list from api
    const membershipList = useGetMembershipListQuery();
    // create system user api
    const [createSystemUser] = useCreateSystemUserMutation()

    // reducer for form
    const userReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_USER":
                return { ...state, [payload.name]: payload.data };
            case "SET_COUNTRY":
                return { ...state, [payload.name]: JSON.parse(payload.data) };
            case "SET_DATE":
                return { ...state, dob: payload };
            default:
                return state;
        }
    };
    const [state, userDispatch] = useReducer(userReducer, initialState);

    // to change date 
    const handleDate = (v, date) => {
        const selectedDateTimestamp = new Date(date + " " + "00:00:01").getTime();
        const sixteenYearagoTimestamp = 16 * 365 * 24 * 60 * 60 * 1000;
        const currentTimeTimestamp = Date.now();

        if ((currentTimeTimestamp - selectedDateTimestamp) > sixteenYearagoTimestamp) {
            setDateValid(true);
        } else {
            setDateValid(false);
        }
        userDispatch({ type: "SET_DATE", payload: new Date(date + " " + "00:00:01").getTime() });
    };
    // to change common inputs
    const handleChange = (e) => {
        userDispatch({
            type: "SET_USER",
            payload: {
                name: e.target.name,
                data: e.target.value
            }
        })
    }
    // to change country
    const handleCountry = (e) => {
        userDispatch({
            type: "SET_COUNTRY",
            payload: {
                name: e.target.name,
                data: e.target.value
            }
        })
    }

    // form submit
    const handleSubmit = (event) => {
        // if there is no date
        if (!state.dob) { setDateValid(false) }
        
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false && dateValid === false) {
            event.stopPropagation();
        } else if (form.checkValidity() === true && dateValid === true) {
            createSystemUser(state).then((res) => {
                console.log(res)
                if (res.data.error === 0) {
                    navigate("/admin/roles-permission/system-users")
                    toast.success(res?.data?.message)
                }
            })
        }
        setValidated(true);
    };

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3'>
                            <h5 className='mb-2'>Create System User</h5>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="cat_nm" className={`col-md-6`}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter first name"
                                            value={state.first_name}
                                            onChange={handleChange}
                                            name="first_name"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="meta_keyword" className={`col-md-6`}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter last name"
                                            value={state.last_name}
                                            onChange={handleChange}
                                            name="last_name"
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="cat_nm" className={`col-md-6`}>
                                        <Form.Label>Country</Form.Label>
                                        <Form.Select onChange={handleCountry} required name="country">
                                            <option value={""}>Select Country</option>
                                            {country.data?.data.map((el, i) => (
                                                <option key={i} value={JSON.stringify(el)}>
                                                    {el.country}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="meta_keyword" className={`col-md-6`}>
                                        <Form.Label>Email or phone (with country code)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Email or Phone"
                                            value={state.emailorphone}
                                            onChange={handleChange}
                                            name="emailorphone"
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="">
                                        <Form.Label>Date of birth</Form.Label>
                                        <DatePicker
                                            onChange={handleDate}
                                            style={{
                                                width: "100%",
                                                height: "39px",
                                                borderColor:
                                                    dateValid === null ? "" : dateValid === true ? "var(--bs-form-valid-border-color)" : "var(--red)",
                                            }}
                                            required
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            style={{
                                                display:
                                                    dateValid === null
                                                        ? "none"
                                                        : dateValid === false
                                                            ? "block"
                                                            : "none",
                                            }}
                                        >
                                            Min age should be 16 years
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="cat_nm" className={`col-md-6`}>
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Select required aria-label="Default select example" value={state.gender} onChange={handleChange} name="gender">
                                            <option value={""}>Select Gender</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Others</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="cat_nm" className={`col-md-6`}>
                                        <Form.Label>Role</Form.Label>
                                        <Form.Select required aria-label="Default select example" name="roles" onChange={handleChange}>
                                            <option value="">Select Role</option>
                                            {roleList.data?.data.map((el, i) => (
                                                <option key={i} value={el._id}>
                                                    {el.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="cat_nm" className={`col-md-6`}>
                                        <Form.Label>Membership</Form.Label>
                                        <Form.Select onChange={handleChange} required aria-label="Default select example" name="membership_id">
                                            <option value="">Select Membership</option>
                                            {membershipList.data?.data.map((el, i) => (
                                                <option key={i} value={el._id}>
                                                    {el.plan_name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="cat_nm" className={`col-md-6`} onChange={handleChange}>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter first name"
                                            value={state.password}
                                            onChange={handleChange}
                                            name="password"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="meta_keyword" className={`col-md-6`} onChange={handleChange}>
                                        <Form.Label>Re-enter Password</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Meta Keyword"
                                            value={state.confirm_password}
                                            onChange={handleChange}
                                            name="confirm_password"
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <div className="mt-2 text-center d-flex align-items-center justify-content-center gap-2">
                                    <button type="button" className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                                    <Button type="submit" className="btn btn-primary">Create User</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SystemUserAdd;
