import { useParams, useSearchParams } from "react-router-dom";
import {
   DashboardMenuLink,
   FilterWrapper,
} from "../../../../components/components";
import GroupProfileLayout from "../../EventProfileLayout";
import { useGetSingleEventQuery, useGuestQuery, usePendingGuestQuery, useSuggestedGuestListQuery } from "../../../../store/api/eventApi";
import EventUserCard from "../../../../components/userCard/EventUserCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Suggation = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1);
   const [search, setSearch] = useState(searchParams.get("search") || "");
   const [sort, setSort] = useState(searchParams.get("sort") || "see_all");
   // get user details
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })
   // get id from param
   const params = useParams();
   const event_id = params.eventId;

   // get single Event Data
   const singleEvent = useGetSingleEventQuery(event_id);
   let eventWholeData = singleEvent?.data?.data;

   const paramSuggationGuest = {
      event_id: event_id,
      pageNo: pageNo,
      searchVal: search,
      sortVal: sort
   }

   const suggestedGuest = useSuggestedGuestListQuery(paramSuggationGuest)
   const suggestedGuestList = suggestedGuest?.data?.data;

   const guestAPI = useGuestQuery({ event_id: event_id, pageNo: pageNo, })

   const pendingList = usePendingGuestQuery({ event_id: event_id, pageNo: pageNo, })

   useEffect(() => {
      let params = { pageNo };
      if (search) {
         params.search = search;
      }
      if (sort) {
         params.sort = sort;
      }
      setSearchParams(params);
   }, [pageNo, search, sort]);

   return (
      <GroupProfileLayout data={eventWholeData}>
         <FilterWrapper>
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="DashboardMenuLinks">
                     <DashboardMenuLink
                        url={`/event/${event_id}/guests`}
                        title="Guests"
                        count={guestAPI?.data?.total_count || 0}
                     />
                     {
                        eventWholeData?.user?._id === userSliceData?._id && <DashboardMenuLink
                           url={`/event/${event_id}/guests/pending`}
                           title="Pending Guests"
                           count={pendingList?.data?.total_count || 0}
                        />
                     }
                     {
                        eventWholeData?.user?._id === userSliceData?._id && <DashboardMenuLink
                           url={`/event/${event_id}/guests/suggation`}
                           title="Suggested Guests"
                           count={suggestedGuest?.data?.total_count || 0}
                        />
                     }
                  </div>
               </div>
               <div className="col-lg-5">
                  <div className="row merged20">
                     <div className="col-lg-7 col-md-7 col-sm-7">
                        <form method="post">
                           <input
                              type="text"
                              placeholder="Search Pages"
                              className="form-control"
                              onChange={(e) => setSearch(e.target.value)}
                              value={searchParams.get("search") || search}
                           />
                        </form>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="select-options">
                           <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                              <option value={"see_all"}>See All</option>
                              <option value={"a_to_z"}>A to Z</option>
                              <option value={"z_to_a"}>Z to A</option>
                              <option value={"newest"}>Newest</option>
                              <option value={"oldest"}>Oldest</option>
                           </select>
                        </div>
                     </div>
                     {/* <div className="col-lg-1 col-md-1 col-sm-1">
                        <div className="option-list">
                           <i className="fa fa-ellipsis-v" />
                           <ul>
                              <li>Show Friends Public</li>
                              <li>Show Friends Private</li>
                              <li>Mute Notifications</li>
                           </ul>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
         </FilterWrapper>
         <div className="central-meta">
            <div className="row gx-4">
               {suggestedGuestList?.map((elem, index) => {
                  return (
                     <div className="col-lg-2 col-md-3 col-6" key={index}>
                        <EventUserCard type={"EventSuggestedGuest"} data={elem} eventID={event_id} />
                     </div>
                  );
               })}
               {suggestedGuestList?.length === 0 && <div className="col-12">
                  <h5 className="text-center mb-0 py-5 fs_18">No Suggested Guest Found !!!</h5>
               </div>}
            </div>
         </div>
      </GroupProfileLayout>
   );
};

export default Suggation;
