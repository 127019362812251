import React, { useEffect, useRef, useState } from 'react'
import { BirthdayWidget, CreateYourPageWidget, Quote } from '../../components/components';
import { useTransactionHistoryQuery } from '../../store/api/walletApi';
import { SendMoneyToConnection } from '../../components/components';
import { IoWalletOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useRechargeWalletMutation, useWalletHistoryQuery } from '../../store/api/walletApi';
import toast from 'react-hot-toast';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Wallet = () => {
    const navigate = useNavigate()
    const user = useSelector((store) => {
        return store.userSlice;
    })

    const [addMoneyModal, setAddMoneyModal] = useState(false);
    const [amount, setAmount] = useState("")

    const [rechargeWallet] = useRechargeWalletMutation()
    const historyWallet = useWalletHistoryQuery()
    const walletHistory = historyWallet?.data?.data;
    console.log(walletHistory)
    const handleAddMoney = () => {
        const data = {
            amount: amount,
            description: "Wallet Recharege",
            meta_type: "User",
            meta_data: user.userSliceData._id,
            transaction_type: "1"
        }
        if (amount > 99) {
            rechargeWallet(data).then((res) => {
                toast.success(res?.data?.message)
                setAddMoneyModal(false)
            })
        } else {
            toast.error("Add min R100!!")
        }
    }
    // to focus on add modal input
    const inputRef = useRef(null);
    useEffect(() => {
        inputRef?.current?.focus();
    }, [addMoneyModal]);

    return (
        <div className="gap2 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <SendMoneyToConnection />
                        <Quote />
                        <BirthdayWidget />
                        <CreateYourPageWidget />
                    </div>
                    <div className="col-md-9">
                        <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                            <h1 className="display-6">My Wallet</h1>
                            <p className="lead mt-3">
                                Introducing our sleek and secure wallet, designed for effortless transactions and seamless integration within your social media experience.
                            </p>
                            <div className='container d-flex justify-content-center'>
                                <div className="card-deck mb-3 text-center m-0 col-md-4">
                                    <div className="card mb-4 shadow-sm">
                                        <div className="card-body">
                                            <IoWalletOutline fontSize={100} />
                                            <ul className="list-unstyled fs_18 mt-3 mb-4">
                                                <li>Balance: R{walletHistory?.totalBalance}</li>
                                            </ul>
                                            <button type="button" className="btn btn-lg btn-block btn-primary" onClick={() => setAddMoneyModal(true)}>Add Money</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        {/* add money modal */}
                        <Modal size="md" aria-labelledby="contained-modal-title-vcenter col-md-12" centered show={addMoneyModal} onHide={() => setAddMoneyModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Add money
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h6>Amount</h6>
                                <input ref={inputRef} type="text" className='form-control' onChange={(e) => setAmount(e.target.value)} />
                                <div className='validate-feedback'>*min add R100</div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='col-md-2 add-money-btn' onClick={handleAddMoney}>Add</Button>
                                <button className='col-md-2 btn btn-secondary' onClick={() => setAddMoneyModal(false)}>cancel</button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Wallet
