import React, { useEffect, useState } from "react";
import GroupProfileLayout from "../GroupProfileLayout";
import { Advertisement, Post, SideBar, CreatePost, InfoWidget, DotLoading } from "../../../components/components";
import { useParams } from "react-router-dom";
import PhotoWidget from "../../../components/widgets/PhotoWidget/PhotoWidget";
import { useGetSingleGroupQuery } from "../../../store/api/groupApi";
import { useGetGroupTimelineQuery } from "../../../store/api/postApi";
import { useSelector } from "react-redux";

const Timeline = () => {
   const [currPage, setCurrPage] = useState(1); // storing current page number
   const [lastPage, setLastPage] = useState(1); // storing last page number

   // get details who loggedin
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   // get id from param
   const params = useParams();
   const GROUPID = params.groupId;

   // get single group Data
   const singleGroup = useGetSingleGroupQuery(GROUPID);

   let groupWholeData = singleGroup?.data?.data;

   const paramObj = {
      page: currPage,
      meta_data: GROUPID,
      meta_type: "Group"
   }
   const AllPostsInGroup = useGetGroupTimelineQuery(paramObj)
   // console.log("how",AllPostsInGroup)
   const AllPostDataInGroup = AllPostsInGroup?.data?.data;

   const handleInfiniteScroll = () => {
      const { scrollTop, offsetHeight } = document.documentElement;
      if (window.innerHeight + scrollTop !== offsetHeight) {
         // This will be triggered after hitting the last element.
         // API call here while implementing pagination.
         if (currPage < lastPage) {
            setCurrPage(currPage + 1)
         }
      }
   }

   useEffect(() => {
      if (AllPostsInGroup.status === "fulfilled") {
         setLastPage(AllPostsInGroup.data.last_page)
         window.addEventListener('scroll', handleInfiniteScroll);
         return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
         };
      }
   }, [handleInfiniteScroll])

   const hasDesiredId = groupWholeData?.members?.some((item) => item.user._id === userSliceData._id);

   // THIS WILL SHOW WHEN LODING THE PAGE
   if (AllPostsInGroup.isLoading) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Loading...</h4>
            </div>
         </>
      );
   }

   // IF GET ERROR FROM API WILL SHOW THIS
   if (AllPostsInGroup.isError) {
      return (
         <>
            <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
               <h4 className="mb-0">Someting Went Wrong!!</h4>
            </div>
         </>
      );
   }

   return (
      <>
         <GroupProfileLayout data={groupWholeData}>
            <div className="row">
               <div className="col-md-3">
                  <SideBar className={"left"}>
                     <InfoWidget
                        postlength={AllPostDataInGroup.length}
                        admin={groupWholeData?.user?.fullName}
                        title={"Group Info"}
                        description={groupWholeData?.description}
                        totalMember={groupWholeData?.members?.length}
                     />
                     <PhotoWidget type={"group"} link={`/group/${GROUPID}/media/all_picture`} />
                     <Advertisement />
                  </SideBar>
               </div>
               <div className="col-md-9">
                  {hasDesiredId && <CreatePost ModuleType={"Group"} ModuleID={GROUPID} type={"group"} />}
                  <div className="feedSec">
                     {AllPostDataInGroup?.map((el, i) => (
                        <Post data={el} index={i} key={i} caption={el.text} type={"group"} />
                     ))}
                     <div className="d-flex justify-content-center">
                        {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                     </div>
                  </div>
               </div>
            </div>
         </GroupProfileLayout>
      </>
   );
};

export default Timeline;
