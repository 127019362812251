import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BirthdayWidget, CreateYourPageWidget, Quote } from '../../components/components'
import { useSavedProductListQuery } from '../../store/api/marketApi'
import ProductCard from '../../components/market/ProductCard'

const SavedProduct = () => {
    const savedProducts = useSavedProductListQuery()
    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-3">
                        <Button className="createProductBtn">
                            <Link to={"/marketplace/create"}>Create New Product</Link>
                        </Button>
                        <Button className="createProductBtn">
                            <Link to={"/marketplace/myproduct"}>My Products</Link>
                        </Button>
                        <Quote />
                        <BirthdayWidget />
                        <CreateYourPageWidget />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center mb-2'>
                                <h5 className='mb-0 fw-bold'>Saved Products</h5>
                                {/* <div className="select-options" style={{display:"flex",gap:"5px"}}>
                                    <select className="form-select" onChange={(e) => setCategory(e.target.value)} value={searchParams.get("category") || category}>
                                        <option value="">Select Category</option>
                                        {categoryList?.data?.data.map((el) => (
                                            <option key={el._id} value={el._id}>{el.category_name}</option>
                                        ))}
                                    </select>
                                    <div className=''>
                                    <Input placeholder="Search..." style={{ width: 200 }} onChange={(e) => setSearch(e.target.value)} value={searchParams.get("search") || search} />
                                </div>
                                </div> */}
                            </div>
                            <Row xs={1} md={3} className="g-4">
                                {savedProducts?.data?.data?.length > 0 ? (savedProducts?.data?.data?.map((el, i) => (
                                    <Col style={{ marginBottom: "10px" }} key={i}>
                                        <ProductCard data={el} type={"saved"} />
                                    </Col>
                                ))) : (
                                    <div className='col-md-12'>
                                        <h6 className="text-center py-5 mb-0 mt-5">
                                            No Saved Product Found !!
                                        </h6>
                                    </div>
                                )}
                            </Row>
                            <table className='adminTable'>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SavedProduct
