import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const { userSlice } = getState();
      let token = userSlice.userAuthToken;
      return headers.set("x-access-token", token);
    },
  }),
  tagTypes: ["activity"],

  endpoints: (builder) => ({
    getRecentActivity: builder.query({
      query: (obj) => {
        return {
          url: "api/activitylist",
          method: "GET",
          params: {
            params: JSON.stringify({
              user_id: obj.id,
              page: obj.page === undefined ? 1 : obj.page,
              per_page: 8,
            }),
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, queryArg) => {
        if (queryArg.arg !== undefined) {
          currentCache.data.push(...newItems.data);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["activity"],
    }),
  }),
});

export const { useGetRecentActivityQuery } = activityApi;