import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useUpdatePasswordMutation } from '../../../store/api/authApi';
import toast from 'react-hot-toast';

const ShareToContact = () => {
  const [validated, setValidated] = useState(false);

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [changePassword] = useUpdatePasswordMutation()

  const submitForm = (event) => {
    event.preventDefault()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (newPassword === confirmPassword) {

        changePassword({ current_password: oldPassword, new_password: newPassword }).then((res) => {
          console.log(res)
          if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
          } else {
            toast.error("Check all fields")
          }
        })
      } else {
        toast.error("Put the confirm password")
      }
    }
    setValidated(true);
  };


  return (
    <div className="gap2 gray-bg full_height">
      <Container className="py-4">
        <Row className="justify-content-center">
          <Col lg="6">
            <Card>
              <Card.Body className="p-4">
                <h1 className="fs-4 fw-fw-bold mb-2 text-centre">Password Update</h1>
                <Form noValidate validated={validated} method="post" onSubmit={submitForm}>
                  <Row>
                    <Col xxl="12" xl="12" lg="12" md="12">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="fname"
                          className="password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          required
                        // pattern=""
                        />
                        <label htmlFor="fname">Old Password</label>
                        {/* <Form.Control.Feedback type="invalid">

                        </Form.Control.Feedback> */}
                      </Form.Floating>
                    </Col>
                    <Col xxl="12" xl="12" lg="12" md="12">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          name="mname"
                          id="mname"
                          type="text"
                          placeholder=""
                          className="password prevent-validation"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_]).{6,}$"
                        />
                        <label htmlFor="mname">New Password</label>
                        <Form.Control.Feedback type="invalid">
                          Min 6 characters with 1 caps, 1 small and 1 special character
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col xxl="12" xl="12" lg="12" md="12">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          name="mname"
                          id="mname"
                          type="text"
                          placeholder=""
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          className="prevent-validation"
                          pattern={newPassword}
                        />
                        <label htmlFor="mname">Confirm Password</label>
                        <Form.Control.Feedback type="invalid">
                          Check the password and re-enter
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center">
                      <Button variant="primary" type="submit">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ShareToContact