import React from 'react'
import { DashboardMenuLink, FilterWrapper } from '../../../../components/components';
import { Link, useParams } from 'react-router-dom';
import './images.css';
import { useSelector } from 'react-redux';
import { useGetAlbumPageOthersQuery, useGetAlbumPageQuery, useGetSinglePageQuery } from '../../../../store/api/pageApi';
import PageProfileLayout from '../../PageProfileLayout';

const Images = () => {
    // get id from param
    const { pageId } = useParams();

    // get single Page Data
    const singlePage = useGetSinglePageQuery(pageId);
    let pageWholeData = singlePage?.data?.data;

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: pageWholeData?.user?._id,
        meta_type: "Page",
        meta_data: pageId
    }
    const AlbumDataOthers = useGetAlbumPageOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetAlbumPageQuery(pageId)
    const Images = pageWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

    const CoverImagesData = Images?.filter(function (el) {
        return el.name === "cover_photo";
    })[0]?.gallery;

    const AllImagesData = Images?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    const ProfileImagesData = Images?.filter(function (el) {
        return el.name === "profile_picture";
    })[0]?.gallery;

    return (
        <PageProfileLayout data={pageWholeData}>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/page/${pageId}/media`} title="Images" noCount/>
                            <DashboardMenuLink url={`/page/${pageId}/media/video`} title="Videos" noCount/>
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className='row gx-4'>
                    <div className='col-md-2'>
                        <Link to={`/page/${pageId}/media/profile_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>Profile Picture</h5>
                                    <p>{ProfileImagesData?.length > 0 ? ProfileImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to={`/page/${pageId}/media/cover_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>Cover Picture</h5>
                                    <p>{CoverImagesData?.length > 0 ? CoverImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to={`/page/${pageId}/media/all_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>All Picture</h5>
                                    <p>{AllImagesData?.length > 0 ? AllImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </PageProfileLayout>
    )
}

export default Images