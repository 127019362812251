import React, { useState } from 'react'
import CreateJobButton from '../../components/employment/CreateJobButton';
import { BirthdayWidget, CreateYourPageWidget, Quote } from '../../components/components';
import { useUserJobListQuery } from '../../store/api/jobApi';
import JoblistItem from '../../components/employment/JoblistingItem';
import { Breadcrumb, Input } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';

const EmplymentsMyJobs = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("search") || "");
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1);
    const navigate = useNavigate()

    const paramsMarket = {
        page: pageNo,
        searchVal: search,
    }
    const jobList = useUserJobListQuery(paramsMarket);

    const jobListData = jobList?.data?.data;

    return (
        <div className="gap2 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className='market_details'>
                            <h4 className='mb-0 fw-bold'>Employment</h4>
                            <div className='search mt-2'>
                                <Input placeholder="Search..." style={{ width: 200 }} onChange={(e) => setSearch(e.target.value)} value={searchParams.get("search") || search} />
                            </div>
                        </div>
                        <ul className='market_option'>
                            <li onClick={() => navigate("/employment/my-jobs")}>
                                <div className='icon'><HiOutlineBuildingOffice2 /></div>My Jobs</li>
                            {/* <li onClick={() => navigate("/marketplace/myorders")}>
                                    <div className='icon'><BiShoppingBag /></div>Buying</li>
                                <li onClick={() => navigate("/marketplace/myproduct")}>
                                    <div className='icon'><LuTags /></div>Selling</li> */}
                        </ul>
                        <CreateYourPageWidget />
                    </div>
                    <div className="col-md-9">
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/employment">Employment</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/employment/my-jobs">My-jobs</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='d-md-flex justify-content-between align-items-center mb-2'>
                            <h5 className='mb-0 fw-bold'>My Jobs</h5>
                            <div className="select-options" style={{ display: "flex", gap: "5px" }}>
                                <div className=''>
                                    <Input placeholder="Search..." style={{ width: 200 }} onChange={(e) => setSearch(e.target.value)} value={searchParams.get("search") || search} />
                                </div>
                            </div>
                        </div>
                        {jobListData?.length > 0 ? (
                            jobListData?.map((el) => {
                                return (
                                    <JoblistItem data={el} key={el._id} />
                                )
                            })
                        ) : (
                            <h6 className='text-center py-5 mb-0 mt-5'>No Job Found !!</h6>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmplymentsMyJobs