import React, { useEffect, useState } from "react";
import CreatePost from "../../components/widgets/createPost/CreatePost";
import SideBar from "../../components/sideBar/SideBar";
import ProfileWidget from "../../components/widgets/ProfileWidget/ProfileWidget";
import CreateYourPageWidget from "../../components/widgets/createYourPageWidget/CreateYourPageWidget";
import Quote from "../../components/widgets/quoteWidget/Quote";
import LiveWidget from "../../components/widgets/liveWidget/LiveWidget";
import GroupWidget from "../../components/widgets/groupWidget/groupWidget";
import { BirthdayWidget, DotLoading, Post } from "../../components/components";
import { useGetAllUserTimelineQuery } from "../../store/api/postApi";
import { AdminMenu } from "../../components/components";
import { useSelector } from "react-redux";
import { isMobile } from "../../methods/checkMobile";
import { useUserPermissionListQuery } from "../../store/api/Admin/adminRolePermissionApi";
import EventWidget from "../../components/widgets/eventWidget/EventWidget";
import PageWidget from "../../components/widgets/pageWidget/PageWidget";
import CreateYourGroupWidget from "../../components/widgets/createYourGroupWidget/CreateYourGroupWidget";
import CreateYourEventWidget from "../../components/widgets/createYourEventWidget/CreateYourEventWidget";
import socketIO from 'socket.io-client';

const Homepage = () => {
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [lastPage, setLastPage] = useState(1); // storing last page number
  const allUserTimeline = useGetAllUserTimelineQuery(currPage)
  const permissionList = useUserPermissionListQuery()
  let postData = allUserTimeline?.data?.data;

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  const handleInfiniteScroll = () => {
    const { scrollTop, offsetHeight } = document.documentElement;
    if (window.innerHeight + scrollTop !== offsetHeight) {
      // This will be triggered after hitting the last element.
      // API call here while implementing pagination.
      if (currPage < lastPage) {
        setCurrPage(currPage + 1)
      }
    }
  }

  useEffect(() => {
    if (allUserTimeline.status === "fulfilled") {
      setLastPage(allUserTimeline.data.last_page)
      window.addEventListener('scroll', handleInfiniteScroll);
      return () => {
        window.removeEventListener('scroll', handleInfiniteScroll);
      };
    }
  }, [handleInfiniteScroll])

  useEffect(() => {
    const socket = socketIO.connect('https://ubuntu-social-media-api.notebrains.com/', {
      auth: {
        user_id: userSliceData?._id,
      }
    });
    socket.emit("myRoom", userSliceData?.user_room_id);
  }, [])

  // THIS WILL SHOW WHEN LODING THE PAGE
  if (allUserTimeline.isLoading) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Loading...</h4>
        </div>
      </>
    );
  }

  // IF GET ERROR FROM API WILL SHOW THIS
  if (allUserTimeline.isError) {
    return (
      <>
        <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
          <h4 className="mb-0">Someting Went Wrong !!</h4>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="gap2 gray-bg full_height">
        <div className="container">
          <div className="row">
            {
              isMobile() ? '' : (
                <aside className="col-md-3">
                  <SideBar className="left">
                    {(userSliceData.user_type === 1 || (userSliceData.user_type === 3 && permissionList?.data?.permissions.length > 0)) && <AdminMenu />}
                    <ProfileWidget />
                    <Quote />
                    <BirthdayWidget />
                    <CreateYourPageWidget />
                    <PageWidget />
                  </SideBar>
                </aside>
              )
            }
            <main className="col-md-6">
              <CreatePost />
              <div className="feedSec">
                {postData?.map((el, i) => (
                  <Post data={el} index={i} key={i} caption={el.text} />
                ))}
                <div className="d-flex justify-content-center">
                  {lastPage !== 0 ? (lastPage !== currPage && lastPage !== null ? <DotLoading /> : 'No more post!!') : 'No post found !!'}
                </div>
              </div>
            </main>
            {
              isMobile() ? '' : (
                <aside className="col-md-3">
                  <SideBar className="right">
                    <LiveWidget />
                    <CreateYourGroupWidget />
                    <GroupWidget />
                    <CreateYourEventWidget />
                    <EventWidget />
                  </SideBar>
                </aside>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
