import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSetPasswordMutation } from "../../../store/api/authApi";
import GoBackWrapper from "../../../components/GoBackWrapper/GoBackWrapper";
import { ErrorAlert, SuccessAlert } from "../../../components/components";



const SetPassword = () => {
  const [validated, setValidated] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();


  let [token, setToken] = useState("")


  let initialState =
  {
    new_password: '',
    confirm_password: '',
    token: `${token}`
  }
  const [state, setState] = useState(initialState);

  let [set_Password, response] = useSetPasswordMutation()




  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const submitLogin = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() == true) {
      // Proceed with form submission
      await set_Password(state).then((res) => {

        if (res?.data?.error === 0) {
          setTimeout(() => {
            navigate("/login");
          }, [3000]);
          handleShow();
        }
        if (res?.error?.data?.error === 1) {
          handleShow();
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    setValidated(true);

  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value, token: `${token}` });

  };


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    console.log('Token:', token);
    setToken(token)
  }, [token]);


  return (
    <>
      <GoBackWrapper>
        <div className="we-login-register-resett">
          <div className="form-title-set" style={{ fontSize: "20px", fontWeight: "500", textTransform: "uppercase", color: "var(--bs-text-blue)" }}>
            set Password
          </div>
          <Form
            noValidate
            validated={validated}
            className="we-form"
            method="post"
            onSubmit={submitLogin}
          >
            <div>
              <Form.Group className="mb-2 pb-1">
                <Form.Label>Password*</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordVisible ? "text" : "password"}
                    placeholder="new_password"
                    name="new_password"
                    value={state.new_password}
                    onChange={handleChange}
                    required
                    pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$"
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  >
                    {passwordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    6 to 20 characters which contain at least one numeric digit, one
                    uppercase and one lowercase letter
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-2 pb-1">
                <Form.Label>Re-enter Password*</Form.Label>
                <Form.Control
                  type="password"
                  placeholder=" Re-enter Password"
                  required
                  pattern={state.new_password}
                  name="confirm_password"
                  value={state.confirm_password}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Check the password and re-enter
                </Form.Control.Feedback>
              </Form.Group>

            </div>
            <Button variant="primary" type="submit" className="register-btn">
              Set Password
            </Button>
          </Form>

        </div>
        {/* Modal for message */}
        <Modal
          size="sm"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            {response.isSuccess === true && (
              <SuccessAlert
                title={response?.isSuccess === true ? "Password Updated !!" : ""}
              />
            )}
            {response.isSuccess === false && (
              <ErrorAlert
                title={
                  response?.isSuccess === false ? response.error?.data.Message : "Somthing Went Wrong!!"
                }
              />
            )}
          </Modal.Body>
        </Modal>
      </GoBackWrapper>
    </>
  );
};

export default SetPassword;
