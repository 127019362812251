import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const membershipApi = createApi({
  reducerPath: "membershipApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const { userSlice } = getState();
      let token = userSlice.userAuthToken;
      return headers.set("x-access-token", token);
    },
  }),
  tagTypes: ["membership"],

  endpoints: (builder) => ({
    // get membership list
    getMembershipList: builder.query({
      query: () => ({
        url: "api/membership-list",
        method: "GET",
      }),
      providesTags: ["membership"],
    }),
    // buy a membership
    buyMembership: builder.mutation({
      query: (data) => {
        return {
          url: "api/membership-buy",
          method: "POST",
          body: {
            membership_id: data
          },
        };
      },
      invalidatesTags: ["membership"],
    }),
  }),
});

export const { useGetMembershipListQuery, useBuyMembershipMutation } = membershipApi;
