import React from "react";
// import "./loginPage.css";
import "../login/loginPage.css"
import bgImage from "../../assets/images/social-media-3846597_1280.webp";
import logo from "../../assets/images/logo.svg";
import Login from "../../components/login/login.jsx";
import { isMobile } from "../../methods/checkMobile.js";

import UpdatePassword from "../auth/UpdatePassword/UpdatePassword.jsx";

const UpdatePasswordPage = () => {
  return (
    <>
      <section
        className="login-back min-vh-100"
        style={{ background: `url(${bgImage})` }}
      >
        <div className="signin login min-vh-100" >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4" style={{backgroundColor:"white",  padding: "40px 50px",  boxShadow:" 0 0 10px 0px rgb(0 0 0 / 25%)",borderRadius: "24px"}}>
            <UpdatePassword/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdatePasswordPage;
