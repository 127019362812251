import React, { useState } from 'react'
import { AdminMenu } from '../../../../components/components';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCreatePermissionMutation } from '../../../../store/api/Admin/adminRolePermissionApi';

const AddPermission = () => {
    const [title, setTitle] = useState("")
    const navigate = useNavigate()

    const [createPermission] = useCreatePermissionMutation()

    const submitForm = () => {
        createPermission({ title: title }).then((res) => {
            console.log(res)
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
                navigate("/admin/roles-permission/permission")
            }
        })
    }


    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3 h-100'>
                            <h5>Create Permission Name</h5>
                            <Form noValidate method="post">
                                <Row>
                                    <Col className='col-md-6'>
                                        <Form.Group controlId="cat_nm">
                                            <Form.Label>Permission Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter report name" value={title} onChange={(e) => setTitle(e.target.value)} name="category_name" />
                                            <Form.Control.Feedback type="invalid">Please provide report name</Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="mt-4 d-flex gap-2">
                                            <button type='button' className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                                            <button type='button' className="btn btn-primary" onClick={submitForm}>Create Permission Name</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPermission;
