import React, { useState } from 'react'
import { BirthdayWidget, CreateYourPageWidget, Quote } from '../../components/components'
import { Breadcrumb, Col, Input, Radio } from 'antd';
import { Button } from 'react-bootstrap'
import { Row } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserProductListQuery } from '../../store/api/marketApi';
import ProductCard from '../../components/market/ProductCard';
import { useGetCategoryListQuery } from '../../store/api/categoryMaster';
import { BsShopWindow } from "react-icons/bs";
import { BiShoppingBag } from "react-icons/bi";
import { LuTags } from "react-icons/lu";
import { HiLocationMarker } from "react-icons/hi";
import Dropdown from 'react-bootstrap/Dropdown';
import WidgetWrapper from '../../components/widgets/WidgetWrapper';
import { BiSolidShoppingBags } from "react-icons/bi";

const MyProducts = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const [search, setSearch] = useState(searchParams.get("search") || "");
    const [category, setCategory] = useState(searchParams.get("category") || "");
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1);
    const categoryList = useGetCategoryListQuery();

    const paramsMarket = {
        page: pageNo,
        searchVal: search,
        category: category,
    }
    const products = useUserProductListQuery(paramsMarket);


    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                <div className="col-md-3">
                        <div className='market_details'>
                            <h4 className='mb-0 fw-bold'>Marketplace</h4>
                            <div className='search mt-2'>
                                <Input placeholder="Search..." style={{ width: 200 }} onChange={(e) => setSearch(e.target.value)} value={searchParams.get("search") || search} />
                            </div>
                        </div>
                        <ul className='market_option'>
                            <li>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <div className='icon'><BsShopWindow /></div>Category:
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setCategory("")}>Show All</Dropdown.Item>
                                        {categoryList?.data?.data.map((el) => (
                                            <Dropdown.Item key={el._id} value={el._id} onClick={() => setCategory(el._id)}>{el.category_name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="active" onClick={() => navigate("/marketplace/myproduct")}>
                                <div className='icon'><BiSolidShoppingBags /></div>My Products</li>
                            <li onClick={() => navigate("/marketplace/myorders")}>
                                <div className='icon'><BiShoppingBag /></div>Buying</li>
                            <li onClick={() => navigate("/marketplace/myorders/sell")}>
                                <div className='icon'><LuTags /></div>Selling</li>
                        </ul>
                        <WidgetWrapper>
                            <div className='market_location'>
                                <div className='icon'><HiLocationMarker /></div>
                                <div>
                                    <h6>Location</h6>
                                    <Link to={`#`} className="address">
                                        East Grand Bahama, Bahamas
                                    </Link>
                                </div>
                            </div>
                        </WidgetWrapper>
                        <CreateYourPageWidget />
                    </div>
                    <div className='col-md-9'>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/marketplace">Marketplace</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/marketplace/myproduct">MyProduct</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className='d-md-flex justify-content-between align-items-center mb-2'>
                                <h5 className='mb-0 fw-bold'>My Products</h5>
                                {/* <div className="select-options" style={{ display: "flex", gap: "5px" }}>
                                    <Radio.Group style={{ marginBottom: 8, display: "flex" }}>
                                        <Radio.Button onClick={() => navigate("/marketplace/create")} value="top">CreateNewProduct</Radio.Button>
                                        <Radio.Button onClick={() => navigate("/marketplace/myproduct")} value="left" className='ant-radio-button-wrapper-checked'>MyProducts</Radio.Button>
                                        <Radio.Button onClick={() => navigate("/marketplace/myorders")} value="right">Orders</Radio.Button>
                                    </Radio.Group>
                                    <select className="form-select" onChange={(e) => setCategory(e.target.value)} value={searchParams.get("category") || category}>
                                        <option value="">Select Category</option>
                                        {categoryList?.data?.data.map((el) => (
                                            <option key={el._id} value={el._id}>{el.category_name}</option>
                                        ))}
                                    </select>
                                    <div className=''>
                                        <Input placeholder="Search..." style={{ width: 200 }} onChange={(e) => setSearch(e.target.value)} value={searchParams.get("search") || search} />
                                    </div>
                                </div> */}

                            </div>
                            <Row xs={1} md={3} className="g-4">
                                {/* 
                                {orderedList?.data?.data?.length > 0 ? (
                                    orderedList?.data?.data?.map((el) => {
                                        return (
                                            <OrderList data={el} key={el._id} type="sell" />
                                        )
                                    })
                                ) : (
                                    <h6 className='text-center py-5 mb-0 mt-5'>No Orders Found !!</h6>
                                )}
                                */}
                                {products?.data?.data?.length > 0 ? (
                                    products?.data?.data?.map((el, i) => (
                                        <Col style={{ marginBottom: "10px" }} key={i}>
                                            <ProductCard data={el} />
                                        </Col>
                                    ))
                                ) : (
                                    <h6 className=' col-md-12 text-center py-5 mb-0 mt-5'>No Product Found !!</h6>
                                )}
                            </Row>
                            <table className='adminTable'>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProducts