import React, { useEffect, useRef, useState } from 'react'
import { AdminMenu } from '../../../components/components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useCreateReactionsMutation } from '../../../store/api/Admin/adminreactions'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { LiaTimesSolid } from 'react-icons/lia'
import './reaction.css'

const AddReactions = () => {
    const [title, setTitle] = useState("")
    const navigate = useNavigate()
    const inputRefIcon = useRef(null);
    const [reactionCreate] = useCreateReactionsMutation()
    const [svgString, setSvgString] = useState("");
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const svgResult = e.target.result;
                setSvgString(svgResult);
            };
            reader.readAsText(file);
        }
    };
    const submitForm = () => {
        reactionCreate({ title: title, base64String: svgString }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
                navigate("/admin/reactions")
            }
        })
    }
    const removeIcon = () => {
        setSvgString("")
        inputRefIcon.current.value = null;
    }

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3 h-100'>
                            <h5>Create Reaction</h5>
                            <Form noValidate method="post">
                                <Row>
                                    <Col className={`col-md-6 mb-3`}>
                                        <Form.Group controlId="cat_nm">
                                            <Form.Label>Reaction Title</Form.Label>
                                            <Form.Control type="text" placeholder="Enter category name" value={title} onChange={(e) => setTitle(e.target.value)} name="category_name" />
                                            <Form.Control.Feedback type="invalid">Please provide category name</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formFile" className='mt-3'>
                                            <Form.Label>Upload reaction image (only svg file)*</Form.Label>
                                            <Form.Control type="file" onChange={handleFileChange} name="banner" accept=".svg" ref={inputRefIcon} />
                                            <Form.Control.Feedback type="invalid">Please select reaction photo</Form.Control.Feedback>
                                            <div className='mt-4'>
                                                {(svgString.length > 0) && (
                                                    <span className='uploadedReactBox'>
                                                        <span className="react_icon" dangerouslySetInnerHTML={{ __html: svgString }}></span>
                                                        <button type="button" onClick={removeIcon}>
                                                            <LiaTimesSolid />
                                                        </button>
                                                    </span>
                                                )}
                                            </div>
                                        </Form.Group>
                                        <div className='mt-4 d-flex gap-2'>
                                            <button type="button" className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                                            <Button type="button" className="btn btn-primary" onClick={submitForm}>Create Reaction</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddReactions