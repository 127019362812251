import React, { Fragment } from 'react'
import { BirthdayWidget, CreateYourPageWidget, Quote } from '../../components/components'
import { useAllNotificationListQuery } from '../../store/api/notificationApi'
import { useNavigate } from 'react-router-dom'
import "./NotificationPage.css";

const NotificationPage = () => {
    const navigate = useNavigate()
    const notificationList = useAllNotificationListQuery()

    const clickNotification = (data) => {
        console.log(data)
        if (data?.meta_type === "Post") {
            navigate(`/post/${data?.meta_data?._id}`)
        }
        else if (data?.meta_type === "User") {
            navigate(`/profile/${data?.meta_data?._id}/timeline`)
        }
    }

    return (
        <div className="gap2 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <Quote />
                        <BirthdayWidget />
                        <CreateYourPageWidget />
                    </div>
                    <div className="col-md-9">
                        <div className='d-md-flex justify-content-between align-items-center mb-2'>
                            <h5 className='mb-0 fw-bold'>Notifications</h5>
                        </div>
                        {
                            notificationList.isLoading ? (
                                <div className='text-center py-5 mb-0 mt-5'>Loading...</div>
                            ) : (
                                notificationList?.data?.data?.notifications?.length > 0 ? (
                                    notificationList?.data?.data?.notifications.map((el, i) => (
                                        <Fragment key={i}>
                                            <div className='notification_mainDiv' key={i} onClick={() => { clickNotification(el) }}>
                                                <img src={el?.sender?.profile_picture[0].image_url} className='notification_avatar' alt="" />
                                                <div>{el.title}</div>
                                            </div>
                                        </Fragment>
                                    ))
                                ) : (
                                    <h6 className='text-center py-5 mb-0 mt-5'>No Notification Found !!</h6>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationPage
