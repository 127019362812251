import React from 'react';
import { useDeleteVoteDownMutation, useDeleteVoteUpMutation, useVoteDownHandleMutation, useVoteUpHandleMutation } from '../../../store/api/voteApi';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { socket } from '../../../methods/Socket';

const Vote = ({ id, voteListOnPost, posted_by_id }) => {
    // USER DATA
    const USER = useSelector((store) => {
        return store.userSlice.userSliceData;
    });

    // VOTE UP SECTION
    const [voteUp] = useVoteUpHandleMutation()
    const [deleteVoteUp] = useDeleteVoteUpMutation()
    let filteredArrayUp = voteListOnPost?.filter(item => item.flag === "up");
    let OwnVoteUp = voteListOnPost?.filter((elem) => {
        return elem.user._id === USER._id && elem.flag === "up";
    })
    const handleVoteUp = () => {
        if (OwnVoteUp?.length > 0) {
            deleteVoteUp(id).then((res) => { })
        } else {
            voteUp(id).then((res) => {
                socket.emit("sendNotificationPostOtherDetailsUpdate", {
                    sender_id: USER._id,
                    meta_data: res?.data?.data?.post,
                    meta_type: "Post",
                    notification_for: "post_vote",
                    update_meta_type_for: "Post",
                    posted_by: posted_by_id
                });

            })
        }
    }

    // VOTE DOWN SECTION
    const [voteDown] = useVoteDownHandleMutation()
    const [deleteVoteDown] = useDeleteVoteDownMutation()
    let filteredArrayDown = voteListOnPost?.filter(item => item.flag === "down");
    let OwnVoteDown = voteListOnPost?.filter((elem) => {
        return elem.user._id === USER._id && elem.flag === "down";
    })
    const handleVoteDown = () => {
        if (OwnVoteDown?.length > 0) {
            deleteVoteDown(id).then((res) => { })
        } else {
            voteDown(id).then((res) => { })
        }
    }

    if (!USER?._id) {
        return (
            <>
                {/* #495057 */}
                <button type="button" className="vote up" data-target="comment_1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Up Vote" onClick={() => toast.error("Please login !!!")}>
                    {OwnVoteUp?.length > 0 ? <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4 3 15h6v5h6v-5h6z" className="icon_svg-stroke icon_svg-fill" strokeWidth="1.3" stroke="currentColor" fill="#495057" strokeLinejoin="round" ></path>
                    </svg> :
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4 3 15h6v5h6v-5h6z" className="icon_svg-stroke icon_svg-fill" strokeWidth="1.3" stroke="currentColor" fill="none" strokeLinejoin="round" ></path>
                        </svg>}
                    <span id="upvote4">{filteredArrayUp?.length || 0}</span>
                </button>
                <button type="button" className="vote down" data-target="comment_1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Down Vote" onClick={() => toast.error("Please login !!!")}>
                    {OwnVoteDown?.length > 0 ? <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                        <path d="m12 20 9-11h-6V4H9v5H3z" className="icon_svg-stroke icon_svg-fill" stroke="currentColor" fill="#495057" strokeWidth="1.3" strokeLinejoin="round"></path>
                    </svg> :
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                            <path d="m12 20 9-11h-6V4H9v5H3z" className="icon_svg-stroke icon_svg-fill" stroke="currentColor" fill="none" strokeWidth="1.3" strokeLinejoin="round"></path>
                        </svg>}
                    <span id="downvote4">{filteredArrayDown?.length || 0}</span>
                </button>
            </>
        )
    }

    return (
        <>
            {/* #495057 */}
            <button type="button" className="vote up" data-target="comment_1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Up Vote" onClick={handleVoteUp}>
                {OwnVoteUp?.length > 0 ? <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4 3 15h6v5h6v-5h6z" className="icon_svg-stroke icon_svg-fill" strokeWidth="1.3" stroke="currentColor" fill="#495057" strokeLinejoin="round" ></path>
                </svg> :
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4 3 15h6v5h6v-5h6z" className="icon_svg-stroke icon_svg-fill" strokeWidth="1.3" stroke="currentColor" fill="none" strokeLinejoin="round" ></path>
                    </svg>}
                <span id="upvote4">{filteredArrayUp?.length || 0}</span>
            </button>
            <button type="button" className="vote down" data-target="comment_1" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Down Vote" onClick={handleVoteDown}>
                {OwnVoteDown?.length > 0 ? <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                    <path d="m12 20 9-11h-6V4H9v5H3z" className="icon_svg-stroke icon_svg-fill" stroke="currentColor" fill="#495057" strokeWidth="1.3" strokeLinejoin="round"></path>
                </svg> :
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                        <path d="m12 20 9-11h-6V4H9v5H3z" className="icon_svg-stroke icon_svg-fill" stroke="currentColor" fill="none" strokeWidth="1.3" strokeLinejoin="round"></path>
                    </svg>}
                <span id="downvote4">{filteredArrayDown?.length || 0}</span>
            </button>
        </>
    )
}

export default Vote
