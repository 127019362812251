import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const notificationApi = createApi({
    reducerPath: "notificationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["notification"],
    endpoints: (builder) => ({
        allNotificationList: builder.query({
            query: () => ({
                url: "api/notification/list",
                method: "GET",
            }),
            providesTags: ["notification"],
        }),

        // createProduct: builder.mutation({
        //     query: (data) => {
        //         return {
        //             url: "api/marketplace/products/create",
        //             method: "POST",
        //             body: data,
        //         };
        //     },
        //     invalidatesTags: ["notification"],
        // }),

        // updateProduct: builder.mutation({
        //     query: (data) => ({
        //         url: "api/marketplace/products/update",
        //         method: "PUT",
        //         body: data
        //     }),
        //     invalidatesTags: ["notification"],
        // }),

        // deleteProduct: builder.mutation({
        //     query: (ID) => {
        //         return {
        //             url: `api/marketplace/products/delete`,
        //             method: "DELETE",
        //             body: {
        //                 product_id: ID
        //             }
        //         };
        //     },
        //     invalidatesTags: ["notification"],
        // }),
    })
})

export const { useAllNotificationListQuery } = notificationApi;