import React from "react";
import JoblistItem from "../../components/employment/JoblistingItem";
import { useSavedJobListsQuery } from "../../store/api/jobApi";
import CreateJobButton from "../../components/employment/CreateJobButton";
import { BirthdayWidget, CreateYourPageWidget, Quote } from "../../components/components";

const EmploymentSaved = () => {
    const jobList = useSavedJobListsQuery();
    const jobListData = jobList?.data?.data;

    return (
        <div className="gap2 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <CreateJobButton />
                        <Quote />
                        <BirthdayWidget />
                        <CreateYourPageWidget />
                    </div>
                    <div className="col-md-9">
                        <div className='d-md-flex justify-content-between align-items-center mb-2'>
                            <h5 className='mb-0 fw-bold'>Saved Jobs</h5>
                            <div className="select-options" style={{ display: "flex", gap: "5px" }}>
                                {/* <div className=''>
                                    <Input placeholder="Search..." style={{ width: 200 }} onChange={(e) => setSearch(e.target.value)} value={searchParams.get("search") || search} />
                                </div> */}
                            </div>
                        </div>
                        {jobListData?.length > 0 ? (
                            jobListData?.map((el) => {
                                return (
                                    <JoblistItem data={el} key={el._id} type={"save"} />
                                )
                            })
                        ) : (
                            <h6 className='text-center py-5 mb-0 mt-5'>No Saved Jobs Found !!</h6>
                        )}
                    </div>
                </div>
            </div>
        </div>
        // <div className="gap2 gray-bg">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-md-2"></div>
        //             <div className="col-md-8">
        //                 {jobListData?.length > 0 ? (
        //                     jobListData?.map((el) => {
        //                         return <JoblistItem data={el} key={el._id} type={"save"} />;
        //                     })
        //                 ) : (
        //                     <h6 className="text-center py-5 mb-0 mt-5">
        //                         No Saved Job Found !!
        //                     </h6>
        //                 )}
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default EmploymentSaved;
