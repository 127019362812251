import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./UserCard.css";
import { BsThreeDots } from "react-icons/bs";
import { Dropdown, Button as AntButton } from "antd";
import { useSelector } from "react-redux";
import { useAcceptNewRequestMutation, useBlockConnectionMutation, useCancelRequestMutation, useDeleteConnectionMutation, useFollowUserMutation, useRemoveConnectionMutation, useSendRequestMutation, useUnblockConnectionMutation, useUnfollowUserMutation } from "../../store/api/userApi";
import { defaultUserCover, defaultUserDp } from "../components";
import { Form, Modal, Button, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { useGetReportReasonListQuery } from "../../store/api/reportApi";
import { useCreateReportMutation } from "../../store/api/postApi";
import { socket } from "../../methods/Socket";

const UserCard = ({ type, data, fullData }) => {
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   const navigate = useNavigate()
   // get all details from redux store
   const userData = useSelector((store) => {
      return store.userSlice.userSliceData;
   });

   // follow a user
   const [followUser] = useFollowUserMutation()

   const followUserBtn = () => {
      let obj = {
         targetUserId: data._id
      }
      followUser(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
         } else {
            toast.error("Something went wrong");
         }
      })
   }

   // unfollow a user
   const [unfollowUser] = useUnfollowUserMutation()
   const unfollowUserBtn = () => {
      let obj = {
         targetUserId: data._id
      }
      unfollowUser(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
         } else {
            toast.error("Something went wrong");
         }
      })
   }

   //ACCEPT FRIEND REQUEST 
   const [acceptRequest] = useAcceptNewRequestMutation()
   const acceptFriendRequest = () => {
      let obj = {
         requester: data?._id,
         recipient: userData._id
      }
      acceptRequest(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
            socket.emit("sendNotification", {
               sender_id: userSliceData._id,
               receiver_id: data._id,
               meta_data: userSliceData._id, // sender id
               meta_type: "User",
               notification_for: "accept_request",
               room_id: data.user_room_id,
            });

         } else {
            toast.error("Something went wrong");
         }
      })
   }

   //SEND CONNECTION REQUEST
   const [requestSend] = useSendRequestMutation()
   const sendRequest = () => {
      let obj = {
         recipient: data._id
      }
      requestSend(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
            socket.emit("sendNotification", {
               sender_id: userSliceData._id,
               receiver_id: data._id,
               meta_data: userSliceData._id, // sender id
               meta_type: "User",
               notification_for: "send_request",
               room_id: data.user_room_id,
            });
         } else {
            toast.error("Something went wrong");
         }
      })
   }

   // BLOCK A USER
   const [blockConnection] = useBlockConnectionMutation()
   const [blockModal, setBlockModal] = useState(false)
   const blockUserModal = () => {
      let obj = {
         "targetUserId": data._id
      }
      blockConnection(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setBlockModal(false)
   }

   // TO REPORT A USER
   const reportReasonList = useGetReportReasonListQuery()
   const [reportApost] = useCreateReportMutation()
   const [showReportModal, setShowReportModal] = useState(false);
   const [otherReason, setOtherReason] = useState("")
   const [reason, setReason] = useState("")
   let getReason = reason.length > 0 ? JSON.parse(reason) : "";
   const createReportModal = () => {
      let reportObj = {
         meta_data: data._id,
         meta_type: "User",
         other_reason: otherReason,
         report_reason_id: getReason._id
      }
      reportApost(reportObj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         }
      })
      setShowReportModal(false);
      setOtherReason("");
      setReason("");
   }

   // IN NEW REQUEST PAGE DELETE REQUEST
   const [deleteReqModal, setDeleteReqModal] = useState(false)
   const [deleteConnection] = useDeleteConnectionMutation()
   const deleteRequestModal = () => {
      deleteConnection({
         requester: data?._id
      }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong in this")
         }
      })
   }

   //IN CONNECTION PAGE REMOVE FROM CONNECTION
   const [removeConnectionModal, setRemoveConnectionModal] = useState(false)
   const [removeConnection] = useRemoveConnectionMutation()
   const removeMyConnectionModal = () => {
      let obj = {
         targetUserId: data._id
      }
      removeConnection(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setRemoveConnectionModal(false)
   }

   // UNBLOCK USER
   const [unblockConnection] = useUnblockConnectionMutation()
   const [unblockModal, setUnblockModal] = useState(false)
   const unblockUserModal = () => {
      unblockConnection(data?.requester?._id).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setUnblockModal(false)
   }

   // Cancel connection request
   const [cancelConnectionReq] = useCancelRequestMutation()
   const cancelRequest = () => {
      cancelConnectionReq(data._id).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
   }

   const NewDropdownLinks = [
      {
         label: <div onClick={() => setDeleteReqModal(true)}>Delete Request</div>,
         key: "0"
      },
      {
         label: <div onClick={() => setBlockModal(true)}>Block</div>,
         key: "1"
      },
      {
         label: <div onClick={() => setShowReportModal(true)}>Report</div>,
         key: "2"
      }
   ]

   const suggestedDropdownLinks = [
      {
         label: <div onClick={() => setBlockModal(true)}>Block</div>,
         key: "0"
      },
      {
         label: <div onClick={() => setShowReportModal(true)}>Report</div>,
         key: "1"
      }
   ]

   const connectionDropdownLinks = [
      {
         label: <div onClick={() => setRemoveConnectionModal(true)}>Remove</div>,
         key: "0"
      },
      {
         label: <div onClick={() => setBlockModal(true)}>Block</div>,
         key: "1"
      },
      {
         label: <div onClick={() => setShowReportModal(true)}>Report</div>,
         key: "2"
      }
   ]

   const FollowerFollowingDropdownLinks = [
      {
         label: <div onClick={() => setBlockModal(true)}>Block</div>,
         key: "1"
      },
      {
         label: <div onClick={() => setShowReportModal(true)}>Report</div>,
         key: "2"
      }
   ]

   if (type === "new") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }}
               ></div>
               <div className="user_content">
                  <figure>
                     <img
                        src={data?.hasOwnProperty("profile_picture") ? `${data?.profile_picture[0]?.image_url}` : defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/profile/${data._id}/timeline`} title="">
                     {data?.fullName}
                  </Link>
                  <span>{fullData?.commonFriends} mutual friends</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist">
                        <Link href="#" title="Message">
                           <i className="fa fa-trash" onClick={() => setDeleteReqModal(true)}></i>
                        </Link>
                     </li>
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={acceptFriendRequest}>Accept</button>
                     </li>
                  </ul>

                  <Dropdown
                     menu={{ items: NewDropdownLinks }}
                     placement="bottomRight"
                     trigger={["click"]}
                  >
                     <AntButton
                        type="link"
                        size="small"
                        shape="circle"
                        icon={<BsThreeDots />}
                     />
                  </Dropdown>
               </div>
            </div>

            {/* CONFIRM BLOCK MODAL */}
            <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to block this contact?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={blockUserModal} className="mx-2">
                           Block user
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>

            {/* CONFIRM REPORT MODAL */}
            <Modal centered size="sm" show={showReportModal}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Report the connection</h5>
                     <Form noValidate className="we-form" method="post">
                        <Form.Group as={Col} className="mb-2 pb-1">
                           <Form.Label>Reason</Form.Label>
                           <Form.Select onChange={(e) => setReason(e.target.value)} required>
                              <option value="">Select reason</option>
                              {reportReasonList?.data?.data.map((el, i) => {
                                 return <option key={i} value={JSON.stringify(el)} >
                                    {el.reason_text}
                                 </option>
                              })}
                           </Form.Select>
                        </Form.Group>
                        {getReason.reason_text === "Others" && <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                           <Form.Label>Description*</Form.Label>
                           <Form.Control
                              placeholder="Enter a small description"
                              required
                              type="text"
                              value={otherReason}
                              onChange={(e) => setOtherReason(e.target.value)}
                              as="textarea"
                              style={{ height: '70px' }}
                           />
                        </Form.Group>}
                        <div className="text-center">
                           <Button variant="secondary" onClick={() => setShowReportModal(false)} className="mx-2">
                              Cancel
                           </Button>
                           <Button variant="danger" onClick={createReportModal} className="mx-2">
                              Report
                           </Button>
                        </div>
                     </Form>
                  </div>
               </Modal.Body>
            </Modal >

            {/* CONFIRM DELETE REQ MODAL */}
            <Modal centered size="sm" show={deleteReqModal} onHide={() => setDeleteReqModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to delete this request?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeleteReqModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteRequestModal} className="mx-2">
                           Delete Request
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "suggested") {
      return (
         <>
            <div className={"userCard"}>
               <div
                  className="userCardcoverPhoto"
                  style={{ backgroundImage: `url(${data.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }}
               >
               </div>
               <div className="user_content">
                  <figure>
                     <img
                        src={data.hasOwnProperty("profile_picture") ? data?.profile_picture[0]?.image_url : defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/profile/${data?._id}/timeline`} title="">
                     {data?.fullName}
                  </Link>
                  <span className="d-block">{data?.common_friend} mutual friends</span>
                  <ul className="add-remove-frnd following">
                     {/* <li className="add-tofrndlist">
                        <Link href="#" title="Message">
                           <i className="fa fa-trash"></i>
                        </Link>
                     </li> */}
                     <li className="following">
                        {data?.is_pending ? <button className="btn btn-light w-100" onClick={cancelRequest}>
                           Cancel Request
                        </button>
                           :
                           <button className="btn btn-light w-100" onClick={sendRequest}>
                              Send Request
                           </button>}
                     </li>
                  </ul>

                  <Dropdown
                     menu={{ items: suggestedDropdownLinks }}
                     placement="bottomRight"
                     trigger={["click"]}
                  >
                     <AntButton
                        type="link"
                        size="small"
                        shape="circle"
                        icon={<BsThreeDots />}
                     />
                  </Dropdown>
               </div>
            </div>

            {/* CONFIRM BLOCK MODAL */}
            <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to block this contact?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={blockUserModal} className="mx-2">
                           Block user
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>

            {/* CONFIRM REPORT MODAL */}
            <Modal centered size="sm" show={showReportModal}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Report the connection</h5>
                     <Form noValidate className="we-form" method="post">
                        <Form.Group as={Col} className="mb-2 pb-1">
                           <Form.Label>Reason</Form.Label>
                           <Form.Select onChange={(e) => setReason(e.target.value)} required>
                              <option value="">Select reason</option>
                              {reportReasonList?.data?.data.map((el, i) => {
                                 return <option key={i} value={JSON.stringify(el)} >
                                    {el.reason_text}
                                 </option>
                              })}
                           </Form.Select>
                        </Form.Group>
                        {getReason.reason_text === "Others" && <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                           <Form.Label>Description*</Form.Label>
                           <Form.Control
                              placeholder="Enter a small description"
                              required
                              type="text"
                              value={otherReason}
                              onChange={(e) => setOtherReason(e.target.value)}
                              as="textarea"
                              style={{ height: '70px' }}
                           />
                        </Form.Group>}
                        <div className="text-center">
                           <Button variant="secondary" onClick={() => setShowReportModal(false)} className="mx-2">
                              Cancel
                           </Button>
                           <Button variant="danger" onClick={createReportModal} className="mx-2">
                              Report
                           </Button>
                        </div>
                     </Form>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "followerFollowing") {
      return (
         <div className={"userCard"}>
            <div
               className="userCardcoverPhoto"
               style={{ backgroundImage: `url(${data?.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }}
            ></div>
            <div className="user_content">
               <figure>
                  <img
                     src={data?.hasOwnProperty("profile_picture") ? `${data?.profile_picture[0]?.image_url}` : defaultUserDp}
                     alt=""
                  />
               </figure>
               <Link to={`/profile/${data?._id}/timeline`} title="">
                  {data?.fullName}
               </Link>
               <span>12 mutual friends</span>
               <ul className="add-remove-frnd following">
                  {/* <li className="add-tofrndlist">
                     <Link href="#" title="Message">
                        <i className="fa fa-envelope"></i>
                     </Link>
                  </li> */}
                  <li className="following">
                     {data?.is_following === true ? <button className="btn btn-light w-100" onClick={unfollowUserBtn}>
                        Following
                     </button> : <button className="btn btn-light w-100" onClick={followUserBtn}>
                        Follow
                     </button>}
                  </li>
               </ul>

               <Dropdown
                  menu={{ items: FollowerFollowingDropdownLinks }}
                  placement="bottomRight"
                  trigger={["click"]}
               >
                  <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
               </Dropdown>
            </div>
         </div>
      )
   }

   else if (type === "block") {
      return (
         <>
            <div className={"userCard"}>
               <div
                  className="userCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.hasOwnProperty("cover_picture") ? data?.requester?.cover_picture[0]?.image_url : defaultUserCover})` }}
               ></div>
               <div className="user_content">
                  <figure>
                     <img
                        src={data?.requester?.hasOwnProperty("profile_picture") ? `${data?.requester?.profile_picture[0]?.image_url}` : defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/profile/${data?.requester?._id}/timeline`} title="">
                     {data?.requester?.fullName}
                  </Link>
                  <span>12 mutual friends</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={() => setUnblockModal(true)}>
                           Unblock
                        </button>
                     </li>
                  </ul>
               </div>
            </div>

            {/* CONFIRM UNBLOCK MODAL */}
            <Modal centered size="sm" show={unblockModal} onHide={() => setUnblockModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to unblock this contact?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setUnblockModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={unblockUserModal} className="mx-2">
                           Unblock user
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      )
   }

   else if (type === "others") {
      return (
         <div className={"userCard"} >
            <div
               className="userCardcoverPhoto"
               style={{ backgroundImage: `url(${data.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }}
            >
            </div>
            <div className="user_content">
               <figure>
                  <img
                     src={data.hasOwnProperty("profile_picture") ? data?.profile_picture[0]?.image_url : defaultUserDp}
                     alt=""
                  />
               </figure>
               <Link to={`/profile/${data?._id}/timeline`} title="">
                  {data?.fullName}
               </Link>
               <span className="d-block">{data?.common_friend} mutual friends</span>
               <ul className="add-remove-frnd following">
                  <li className="following">
                     {data.is_friend === true && data.is_pending === false && <button className="btn btn-light w-100" onClick={removeMyConnectionModal}>
                        Remove Connection
                     </button>}

                     {data.is_friend === false && data.is_pending === true && <button className="btn btn-light w-100" onClick={cancelRequest}>
                        Cancel Request
                     </button>}

                     {data.is_friend === false && (!data.hasOwnProperty("is_pending") || data.is_pending === false) && <button className="btn btn-light w-100" onClick={sendRequest}>
                        Send Request
                     </button>}
                  </li>
               </ul>

               <Dropdown menu={{ items: suggestedDropdownLinks }} placement="bottomRight" trigger={["click"]} >
                  <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
               </Dropdown>
            </div>
         </div>
      )
   }

   else if (type === "search") {
      return (
         <div className={"userCard"} >
            <div
               className="userCardcoverPhoto"
               style={{ backgroundImage: `url(${data.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }}
            >
            </div>
            <div className="user_content">
               <figure>
                  <img
                     src={data.hasOwnProperty("profile_picture") ? data?.profile_picture[0]?.image_url : defaultUserDp}
                     alt=""
                  />
               </figure>
               <Link to={`/profile/${data?._id}/timeline`} title="">
                  {data?.fullName}
               </Link>
               <span>Explore this profile</span>
               <ul className="add-remove-frnd following">
                  <li className="following">
                     <button className="btn btn-light w-100" onClick={() => navigate(`/profile/${data?._id}/timeline`)}>
                        View Profile
                     </button>
                  </li>
               </ul>
            </div>
         </div>
      )
   }

   else {
      return (
         <>
            <div className={"userCard"}>
               <div
                  className="userCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }}
               ></div>
               <div className="user_content">
                  <figure>
                     <img
                        src={data?.hasOwnProperty("profile_picture") ? `${data?.profile_picture[0]?.image_url}` : defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/profile/${data?._id}/timeline`} title="">
                     {data?.fullName}
                  </Link>
                  <span>{data?.common_friend} mutual friends</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist">
                        <Link href="#" title="Message">
                           <i className="fa fa-envelope"></i>
                        </Link>
                     </li>
                     <li className="following">
                        {data?.is_following === true ? <button className="btn btn-light w-100" onClick={unfollowUserBtn}>
                           Following
                        </button> : <button className="btn btn-light w-100" onClick={followUserBtn}>
                           Follow
                        </button>}
                     </li>
                  </ul>

                  <Dropdown menu={{ items: connectionDropdownLinks }} placement="bottomRight" trigger={["click"]} >
                     <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
                  </Dropdown>
               </div>
            </div>

            {/* CONFIRM BLOCK MODAL */}
            <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to block this contact?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={blockUserModal} className="mx-2">
                           Block user
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>

            {/* CONFIRM REPORT MODAL */}
            <Modal centered size="sm" show={showReportModal}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Report the connection</h5>
                     <Form noValidate className="we-form" method="post">
                        <Form.Group as={Col} className="mb-2 pb-1">
                           <Form.Label>Reason</Form.Label>
                           <Form.Select onChange={(e) => setReason(e.target.value)} required>
                              <option value="">Select reason</option>
                              {reportReasonList?.data?.data.map((el, i) => {
                                 return <option key={i} value={JSON.stringify(el)} >
                                    {el.reason_text}
                                 </option>
                              })}
                           </Form.Select>
                        </Form.Group>
                        {getReason.reason_text === "Others" && <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                           <Form.Label>Description*</Form.Label>
                           <Form.Control
                              placeholder="Enter a small description"
                              required
                              type="text"
                              value={otherReason}
                              onChange={(e) => setOtherReason(e.target.value)}
                              as="textarea"
                              style={{ height: '70px' }}
                           />
                        </Form.Group>}
                        <div className="text-center">
                           <Button variant="secondary" onClick={() => setShowReportModal(false)} className="mx-2">
                              Cancel
                           </Button>
                           <Button variant="danger" onClick={createReportModal} className="mx-2">
                              Report
                           </Button>
                        </div>
                     </Form>
                  </div>
               </Modal.Body>
            </Modal >

            {/* CONFIRM DELETE REQ MODAL */}
            <Modal centered size="sm" show={removeConnectionModal} onHide={() => setRemoveConnectionModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to remove this connection?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setRemoveConnectionModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={removeMyConnectionModal} className="mx-2">
                           Remove
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }
};

export default UserCard;
