import React, { useState } from 'react'
import "./UserCard.css";
import { defaultUserCover, defaultUserDp } from '../components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAcceptJoinRequestMutation, useInviteFollowerMutation, useRemoveGroupMemberMutation } from '../../store/api/groupApi';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { socket } from '../../methods/Socket';

const GroupUserCard = ({ type, data, groupDetails }) => {
   // get details who loggedin
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   const navigate = useNavigate()

   // to get group id from url
   const param = useParams()
   const GROUPID = param.groupId;

   // to accept the pending member who have requested to your group
   const [acceptMember] = useAcceptJoinRequestMutation()
   const acceptMemberFunc = () => {
      let obj = {
         group_id: GROUPID,
         members: {
            user_id: data?.user?._id,
            status: "active",
            role: "member"
         }
      }
      acceptMember(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
            socket.emit("sendNotificationGroup", {
               sender_id: userSliceData._id,
               receiver_id: data._id,
               meta_data: GROUPID,
               meta_type: "Group",
               notification_for: "group_accept_request",
            });
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // to remove a member from your group
   const [removeMemberModal, setRemoveMemberModal] = useState(false)
   const [removeGroupMember] = useRemoveGroupMemberMutation()
   const removeGroupMemberFunc = () => {
      setRemoveMemberModal(true)
   }
   const removeGroupMemberModal = () => {
      let obj = {
         group_id: GROUPID,
         members: {
            user_id: data?.user?._id,
            is_remove: true
         }
      }
      removeGroupMember(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // to invite a suggested member
   const [inviteMember] = useInviteFollowerMutation()
   const inviteMemberFunc = () => {
      inviteMember({ userId: data._id, groupId: GROUPID }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationGroup", {
               sender_id: userSliceData._id,
               receiver_id: data._id,
               meta_data: GROUPID,
               meta_type: "Group",
               notification_for: "group_invite",
            });
         } else {
            toast.error("Something went wrong")
         }
      })
   }

   // to show group members
   if (type === "GroupMember") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.user?.hasOwnProperty("cover_picture") ? data?.user?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data.user.hasOwnProperty("profile_picture") ? data?.user?.profile_picture[0]?.image_url : defaultUserDp} alt="" />
                  </figure>
                  <Link to={`/profile/${data?.user?._id}/timeline`} title="">
                     {data?.user?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        {data.role !== "admin" ? <button className="btn btn-light w-100" onClick={removeGroupMemberFunc}>
                           Remove
                        </button> : <button className="btn btn-light w-100" onClick={() => navigate(`/profile/${data?.user?._id}/timeline`)}>
                           Admin
                        </button>}
                     </li>
                  </ul>

                  {/* <Dropdown
                       menu={{ items: suggestedDropdownLinks }}
                       placement="bottomRight"
                       trigger={["click"]}
                    >
                       <AntButton
                          type="link"
                          size="small"
                          shape="circle"
                          icon={<BsThreeDots />}
                       />
                    </Dropdown> */}
               </div>
            </div>
            {/* CONFIRM REMOVE USER MODAL */}
            <Modal centered size="sm" show={removeMemberModal} onHide={() => setRemoveMemberModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to Remove this member?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setRemoveMemberModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={removeGroupMemberModal} className="mx-2">
                           Remove
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   // to show pending member
   else if (type === "GroupPendingMember") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.user?.hasOwnProperty("cover_picture") ? data?.user?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.user?.hasOwnProperty("profile_picture") ? data?.user?.profile_picture[0]?.image_url : defaultUserDp} alt="" />
                  </figure>
                  <Link to={`/profile/${data?.user?._id}/timeline`} title="">
                     {data?.user?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist" onClick={removeGroupMemberFunc}>
                        <Link href="#" title="Delete">
                           <i className="fa fa-trash"></i>
                        </Link>
                     </li>
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={acceptMemberFunc}>
                           Accept
                        </button>
                     </li>
                  </ul>

                  {/* <Dropdown
                       menu={{ items: suggestedDropdownLinks }}
                       placement="bottomRight"
                       trigger={["click"]}
                    >
                       <AntButton
                          type="link"
                          size="small"
                          shape="circle"
                          icon={<BsThreeDots />}
                       />
                    </Dropdown> */}
               </div>
            </div>
            {/* CONFIRM REMOVE PENDING MEMBER MODAL */}
            <Modal centered size="sm" show={removeMemberModal} onHide={() => setRemoveMemberModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to Remove this member?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setRemoveMemberModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={removeGroupMemberModal} className="mx-2">
                           Remove
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   // to show suggested members
   else if (type === "GroupSuggestedMember") {
      return (
         <>
            <div className={"userCard"}>
               <div className="userCardcoverPhoto" style={{ backgroundImage: `url(${data?.hasOwnProperty("cover_picture") ? data?.cover_picture[0]?.image_url : defaultUserCover})` }} />
               <div className="user_content">
                  <figure>
                     <img src={data?.hasOwnProperty("profile_picture") ? data?.profile_picture[0]?.image_url : defaultUserDp} alt="" />
                  </figure>
                  <Link to={`/profile/${data?.user?._id}/timeline`} title="">
                     {data?.fullName}
                  </Link>
                  <span className="mt-2 d-inline-block">{data?.user?.countries?.country}</span>
                  <ul className="add-remove-frnd following">
                     {/* <li className="add-tofrndlist">
                                <Link href="#" title="Delete">
                                    <i className="fa fa-trash"></i>
                                </Link>
                            </li> */}
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={inviteMemberFunc}>
                           Invite
                        </button>
                     </li>
                  </ul>

                  {/* <Dropdown
                       menu={{ items: suggestedDropdownLinks }}
                       placement="bottomRight"
                       trigger={["click"]}
                    >
                       <AntButton
                          type="link"
                          size="small"
                          shape="circle"
                          icon={<BsThreeDots />}
                       />
                    </Dropdown> */}
               </div>
            </div>

            {/* CONFIRM BLOCK MODAL */}
            {/* <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
                 <Modal.Body>
                    <div className="text-center">
                       <h5 className="mb-3">Are you sure, you want to block this contact?</h5>
                       <div className="text-center">
                          <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                             Cancel
                          </Button>
                          <Button variant="danger" onClick={blockUserModal} className="mx-2">
                             Block user
                          </Button>
                       </div>
                    </div>
                 </Modal.Body>
              </Modal> */}

            {/* CONFIRM REPORT MODAL */}
            {/* <Modal centered size="sm" show={reportModal} onHide={() => setBlockModal(false)}>
                 <Modal.Body>
                    <div className="text-center">
                       <h5 className="mb-3">Are you sure, you want to report this contact?</h5>
                       <div className="text-center">
                          <Button variant="secondary" onClick={() => setReportModal(false)} className="mx-2">
                             Cancel
                          </Button>
                          <Button variant="danger" onClick={reportUserModal} className="mx-2">
                             Report user
                          </Button>
                       </div>
                    </div>
                 </Modal.Body>
              </Modal> */}
         </>
      );
   }

}

export default GroupUserCard;
