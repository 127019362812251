import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { CheckButton } from "../../../components/components";
import { useUpdateUserMutation } from "../../../store/api/userApi";
import { useDispatch, useSelector } from "react-redux";
import { addUserSlice } from "../../../store/slice/userSlice";
import toast from "react-hot-toast";
import { useGetCategoryListQuery } from "../../../store/api/categoryMaster";
import { useNavigate } from "react-router-dom";

const AreaOfInterest = () => {
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const categoryList = useGetCategoryListQuery()
  const [updateUser] = useUpdateUserMutation()

  const [interestArr, setInterestArr] = useState(userSliceData.area_of_interests.map((obj) => obj.category) || []);

  const handleChangeInterest = (e, elem) => {
    if (e.target.checked) {
      setInterestArr([...interestArr, elem]);
    } else {
      setInterestArr(interestArr.filter((item) => item !== elem));
    }
  };



  const submitForm = (e) => {
    e.preventDefault();
    console.log("newdata", interestArr)

    if (interestArr.length > 0) {
      if (navigator.onLine) {
        let arr = { user_id: userSliceData?._id, area_of_interests: interestArr }
        updateUser(arr).then((res) => {
          let newData = res?.data?.data;
          console.log("newdata", interestArr)


          dispatch(addUserSlice(newData));

          toast.success(res?.data?.message)
        })
      } else {
        toast.error('Please turn on internet !!')
      }

      navigate(`/profile/${userSliceData?._id}/timeline`)
    }
  };

  return (
    <div className="gap2 gray-bg full_height">
      <Container className="py-4">
        <Row className="justify-content-center">
          <Col lg="6">
            <Card>
              <Card.Body className="p-4">
                <h1 className="fs-4 fw-fw-bold mb-2">
                  Area of interests
                </h1>
                <p>
                  Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard dummy
                  text ever since the 1500s.
                </p>
                <div className="card-body px-0">
                  {categoryList?.data?.data?.length > 0 ? <form method="post" className="c-form" onSubmit={submitForm}>
                    {categoryList?.data?.data?.map((el, i) => {
                      return (
                        <CheckButton key={i} id={i} name={el.category_name} handleChange={(e) => handleChangeInterest(e, el._id)}
                          checked={interestArr.includes(el._id)}
                          title={el.title} />
                      )
                    })}
                    <Col className="d-flex align-items-center justify-content-center">
                      <Button  variant="primary" type="submit">
                        Save
                      </Button>
                    </Col>
                  </form>
                    : <div className="col-12">
                      <h5 className="text-center mb-0 fs_18">
                        No Category Found !!!
                      </h5>
                    </div>}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AreaOfInterest;
