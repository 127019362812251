import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const reactApi = createApi({
    reducerPath: "reactApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
           
            const { userSlice } = getState();
        
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["react", "commentReact"],
    endpoints: (builder) => ({
        // GET ALL REACT
        getReacts: builder.query({
            query: (data) => ({
                url: "api/posts/reaction/lists",
                method: "GET",
                params: data,
            }),
            providesTags: ["react"],
        }),
        // ADD REACT
        addReactOnPost: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/reaction/create`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["react"]
        }),
        // UPDATE REACT
        updateReact: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/reaction/update`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["react"]
        }),
        // DELETE REACT
        deleteReact: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/reaction/delete`,
                    method: "DELETE",
                    body: data,
                };
            },
            invalidatesTags: ["react"]
        }),

        // All react apis on comment
        getReactsOnComment: builder.query({
            query: (data) => ({
                url: `api/posts/comment/reaction/lists`,
                method: "GET",
                params: data,
            }),
            providesTags: ["commentReact"],
        }),
        addReactOnComment: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/comment/reaction/create`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["commentReact"]
        }),
        updateReactOnComment: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/comment/reaction/update`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["commentReact"]
        }),
        deleteReactOnComment: builder.mutation({
            query: (data) => {
                return {
                    url: `api/posts/comment/reaction/delete`,
                    method: "DELETE",
                    body: data,
                };
            },
            invalidatesTags: ["commentReact"]
        }),
    })
})

export const { useGetReactsQuery, useAddReactOnPostMutation, useUpdateReactMutation, useDeleteReactMutation, useGetReactsOnCommentQuery,useAddReactOnCommentMutation,useUpdateReactOnCommentMutation,useDeleteReactOnCommentMutation } = reactApi