import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminGroupApi = createApi({
    reducerPath: "adminGroupApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminGroup"],
    endpoints: (builder) => ({
        getGroupList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/groups/list",
                    method: "GET",
                    params: {
                        page: data.page,
                        per_page: data.per_page,
                        search_keyword: data.search_keyword
                    }
                }
            },
            providesTags: ["AdminGroup"],
        }),
        changeStatus: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/groups/change-status`,
                    method: "PUT",
                    body: {
                        "group_id": data._id,
                        "status": data.status
                    }
                };
            },
            invalidatesTags: ["AdminGroup"],
        }),
    }),
});

export const { 
    useGetGroupListQuery,
    useChangeStatusMutation
} = adminGroupApi;