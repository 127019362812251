import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const PageGroupList = ({ data, type }) => {
    const [statusModal, setStatusModal] = useState(false)
    return (
        <div>
            {type === "page" && <Link onClick={() => setStatusModal(!statusModal)} className='fw-bold'>Page ({data.length})</Link>}
            {type === "group" && <Link onClick={() => setStatusModal(!statusModal)} className='fw-bold'>Group ({data.length})</Link>}
            <Modal centered size="lg" show={statusModal} onHide={() => setStatusModal(false)}>
                <Modal.Body>
                    {
                        type === "page" ? <h5 className="mb-3">Shared Page List</h5> : <h5 className="mb-3">Shared Group List</h5>
                    }
                    
                    <table className='table table-borderless table-striped'>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Created By</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Members</th>
                                <th>Scope</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((elem, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            {
                                                type === "page" ? (
                                                    <Link target='_blank' to={ `/page/${elem?._id}/timeline` }>
                                                        {elem?.title}
                                                    </Link>
                                                ) : (
                                                    <Link target='_blank' to={ `/group/${elem?._id}/timeline` }>
                                                        {elem?.title}
                                                    </Link>
                                                )
                                            }
                                        </td>
                                        <td>
                                            <Link target='_blank' to={ `/profile/${elem?.user?._id}/timeline` }>
                                                {elem?.user?.fullName}
                                            </Link>
                                        </td>
                                        <td>{elem.description}</td>
                                        <td>{elem.description}</td>
                                        <td>{elem.members.length}</td>
                                        <td>{elem.scope}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="text-center">
                        <Button variant="danger" onClick={() => setStatusModal(false)} className="mx-2">
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PageGroupList
