import React, { useEffect, useState } from 'react'
import { useGetReactionListQuery } from '../../../store/api/Admin/adminreactions'
import { Link, useSearchParams } from 'react-router-dom';
import { AdminMenu } from '../../../components/components';
import Input from 'antd/es/input/Input';
import { Pagination } from 'antd';
import ReactionCard from '../../../components/adminComponent/reactionCard/ReactionCard';
import { FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';

const Reactions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "")
  const [page, setPageNo] = useState(searchParams.get("page") || 1)

  const perPage = 15;
  const reactionsList = useGetReactionListQuery({
    page: page,
    per_page: perPage,
    search_keyword: search
  })

  const onSearch = (e) => {
    setSearch(e.target.value)
  }
  const handlePageChange = (page) => {
    setPageNo(page)
  }

  const { userSliceData: userInfo } = useSelector((store) => {
    return store.userSlice;
  });

  const permissionList = useUserPermissionListQuery()
  if (permissionList.isSuccess === true) {
    var user_permission_arr = permissionList?.data?.permissions;
  }

  useEffect(() => {
    let params = { page };
    if (search) {
      params.search = search;
    }
    setSearchParams(params);
  }, [page, search, setSearchParams])


  return (
    <div className='gap2 gray-bg full_height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <AdminMenu open />
          </div>
          <div className='col-md-9'>
            <div className='bg-white rounded-2 p-3 h-100'>
              <div className='d-md-flex justify-content-between align-items-center mb-3'>
                <h5 className='mb-0'>Reactions</h5>
                {(userInfo.user_type === 3 && user_permission_arr.includes("reaction_create")) && <Link to="/admin/reactions/create" className="createBtn ms-auto me-2">
                  <FaPlus /> Create
                </Link>}
                {userInfo.user_type === 1 && <Link to="/admin/reactions/create" className="createBtn ms-auto me-2">
                  <FaPlus /> Create
                </Link>}
                <div className=''>
                  <Input placeholder="Search..." value={search} onChange={onSearch} style={{ width: 200 }} />
                </div>
              </div>
              <div className='row g-3'>
                {
                  reactionsList.isError && (
                    <div className='col-12'>
                      <div className='noData'>
                        <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                      </div>
                    </div>
                  )
                }
                {
                  reactionsList.isLoading && (
                    <div className='col-12'>
                      <div className='noData'>
                        <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                      </div>
                    </div>
                  )
                }
                {
                  reactionsList.isSuccess && (
                    reactionsList.data?.data.length > 0 ? (
                      reactionsList.data?.data?.map((elem, i) => {
                        return (
                          <div className='col-md-3' key={i}>
                            <ReactionCard data={elem} />
                          </div>
                        )
                      })
                    ) : (
                      <div className='col-12'>
                        <div className='noData'>
                          <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                        </div>
                      </div>
                    )
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reactions;