import React, { useReducer, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import { LiaTimesSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import AWS from "aws-sdk";
import { useUpdateUserMutation } from "../../../store/api/userApi";
import { addUserSlice } from "../../../store/slice/userSlice";
import toast from "react-hot-toast";
import './uploadProfile.css'
import { useNavigate } from "react-router-dom";
import { useCreatePostMutation } from "../../../store/api/postApi";
import { socket } from "../../../methods/Socket";

const UploadProfile = () => {
  const [createPost] = useCreatePostMutation();
  const dispatch1 = useDispatch()
  const [profile, setProfile] = useState("")
  const [cover, setCover] = useState("")
  const [updateUser] = useUpdateUserMutation()
  const [errorShow, setErrorShow] = useState(null);
  const inputRefProfile = useRef(null);
  const inputRefCover = useRef(null);
  const navigate = useNavigate();
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  const initialState = {
    user_id: userSliceData._id,
    coverPhoto: userSliceData.hasOwnProperty("cover_picture") ? [userSliceData.cover_picture[0].image_url] : [],
    profilePhoto: userSliceData.hasOwnProperty("profile_picture") ? [userSliceData.profile_picture[0].image_url] : []
  };

  const formReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_PROFILE":
        return { ...state, [payload.name]: payload.data };
      case "SET_COVER":
        return { ...state, [payload.name]: payload.data };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formReducer, initialState);


  //when user click save button user will go to previous page
  let redirectHandle = () => {
    navigate(-1)
  }
  const handleProfilePhoto = (e) => {
    setErrorShow(false);
    setProfile(e.target.files[0])
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];

    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });

    if (selectedFIles.length > 0) {
      dispatch({
        type: "SET_PROFILE",
        payload: {
          name: e.target.name,
          data: [...selectedFIles],
        },
      });
    }
  };

  const handleCoverPhoto = (e) => {
    setErrorShow(false);
    setCover(e.target.files[0])
    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];

    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });

    if (selectedFIles.length > 0) {
      dispatch({
        type: "SET_COVER",
        payload: {
          name: e.target.name,
          data: [...selectedFIles],
        },
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (navigator.onLine) {
      if (profile.name && cover.name) {
        // for profile picture start
        const S3_BUCKET = "ubuntu-social-media-2";
        const REGION = "ap-south-1";
        AWS.config.update({
          accessKeyId: "AKIA4NNCEDROYLRYR2OX",
          secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });

        let fileName = Date.now() + Math.random() + profile.name && profile.name.split(" ").join("");
        let fileSize = profile.size;
        let fileType = profile.type;
        const params = {
          Bucket: S3_BUCKET,
          Key: fileName,
          Body: profile,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            // File uploading progress
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          }).promise();

        await upload.then((data, err) => { });

        let profileArr = []

        let obj = {
          profile_picture: profileArr,
          user_id: userSliceData._id,
        }
        updateUser(obj).then((res) => {
          let newData = res?.data?.data;
          dispatch1(addUserSlice(newData));
          let objCover = {
            category_id: "65951b501f6ac01f962e889d",
            posted_by: userSliceData._id,
            post_text: "Profile photo uploaded successfull",
            post_type: "string",
            images: profileArr,
            scope: "public",
          };

          createPost(objCover).then((res) => {
            socket.emit("sendNotificationUserDetailsUpdate", {
              sender_id: userSliceData._id,
              meta_data: res?.data?.data?._id,
              meta_type: "Post",
              notification_for: "user_profile_photo",
              update_meta_type_for: "User",
            });
            coverArr = [];
            dispatch({ type: "RESET_REDUCER" });
          })
        })
        profileArr.push({
          image_url:
            ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
              fileName),
          size: fileSize,
          mime_type: fileType,
        });
        // for profile picture end

        // for cover picture start
        const S3_BUCKET2 = "ubuntu-social-media-2";
        const REGION2 = "ap-south-1";
        AWS.config.update({
          accessKeyId: "AKIA4NNCEDROYLRYR2OX",
          secretAccessKey: "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",
        });
        const s32 = new AWS.S3({
          params: { Bucket: S3_BUCKET2 },
          region: REGION2,
        });

        let coverName = Date.now() + Math.random() + cover.name && cover.name.split(" ").join("");
        let coverSize = cover.size;
        let coverType = cover.type;

        const params2 = {
          Bucket: S3_BUCKET2,
          Key: coverName,
          Body: cover,
        };

        var upload2 = s32
          .putObject(params2)
          .on("httpUploadProgress", (evt) => {
            // File uploading progress
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        await upload2.then((data, err) => { });

        let coverArr = []

        let obj2 = {
          cover_picture: coverArr,
          user_id: userSliceData._id,
        }
        updateUser(obj2).then((res) => {
          let newData = res?.data?.data
          dispatch1(addUserSlice(newData));
          let objCover = {
            posted_by: userSliceData._id,
            post_text: "Cover photo uploaded successfull",
            post_type: "string",
            images: coverArr,
            scope: "public",
          };
          createPost(objCover).then((res) => {
            socket.emit("sendNotificationUserDetailsUpdate", {
              sender_id: userSliceData._id,
              meta_data: res?.data?.data?._id,
              meta_type: "Post",
              notification_for: "user_cover_photo",
              update_meta_type_for: "User",
            });

            coverArr = [];
            dispatch({ type: "RESET_REDUCER" });
          })
        })
        coverArr.push({
          image_url:
            ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
              coverName),
          size: coverSize,
          mime_type: coverType,
        });
        //for cover picture end
      }
      else if (cover.name) {
        // for cover picture start
        const S3_BUCKET2 = "ubuntu-social-media-2";
        const REGION2 = "ap-south-1";
        AWS.config.update({
          accessKeyId: "AKIA4NNCEDROYLRYR2OX",
          secretAccessKey: "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",
        });
        const s32 = new AWS.S3({
          params: { Bucket: S3_BUCKET2 },
          region: REGION2,
        });

        let coverName = Date.now() + Math.random() + cover.name && cover.name.split(" ").join("");
        let coverSize = cover.size;
        let coverType = cover.type;

        const params2 = {
          Bucket: S3_BUCKET2,
          Key: coverName,
          Body: cover,
        };

        var upload3 = s32.putObject(params2).on("httpUploadProgress", (evt) => {
          // File uploading progress
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );
        })
          .promise();

        await upload3.then((data, err) => { });

        let coverArr = []

        let obj2 = {
          cover_picture: coverArr,
          user_id: userSliceData._id,
        }
        updateUser(obj2).then((res) => {
          let newData = res?.data?.data
          dispatch1(addUserSlice(newData));
          let objCover = {
            posted_by: userSliceData._id,
            post_text: "Cover photo uploaded successfull",
            post_type: "string",
            images: coverArr,
            scope: "public",
          };
          createPost(objCover).then((res) => {
            socket.emit("sendNotificationUserDetailsUpdate", {
              sender_id: userSliceData._id,
              meta_data: res?.data?.data?._id,
              meta_type: "Post",
              notification_for: "user_cover_photo",
              update_meta_type_for: "User",
            });

            coverArr = [];
            dispatch({ type: "RESET_REDUCER" });
          })
        })
        coverArr.push({
          image_url:
            ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
              coverName),
          size: coverSize,
          mime_type: coverType,
        });
        //for cover picture end
        toast.success("Cover Picture Upload Successfull!!!")
      }
      else if (profile.name) {
        // for profile picture start
        const S3_BUCKET = "ubuntu-social-media-2";
        const REGION = "ap-south-1";
        AWS.config.update({
          accessKeyId: "AKIA4NNCEDROYLRYR2OX",
          secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });

        let fileName = Date.now() + Math.random() + profile.name && profile.name.split(" ").join("");
        let fileSize = profile.size;
        let fileType = profile.type;
        const params = {
          Bucket: S3_BUCKET,
          Key: fileName,
          Body: profile,
        };

        var upload4 = s3.putObject(params).on("httpUploadProgress", (evt) => {
          // File uploading progress
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );
        }).promise();

        await upload4.then((data, err) => { });

        let profileArr = []

        let obj = {
          profile_picture: profileArr,
          user_id: userSliceData._id,
        }
        updateUser(obj).then((res) => {
          let newData = res?.data?.data;
          dispatch1(addUserSlice(newData));
          let objCover = {
            category_id: "65951b501f6ac01f962e889d",
            posted_by: userSliceData._id,
            post_text: "Profile photo uploaded successfull",
            post_type: "string",
            images: profileArr,
            scope: "public",
          };

          createPost(objCover).then((res) => {
            socket.emit("sendNotificationUserDetailsUpdate", {
              sender_id: userSliceData._id,
              meta_data: res?.data?.data?._id,
              meta_type: "Post",
              notification_for: "user_profile_photo",
              update_meta_type_for: "User",
            });
            dispatch({ type: "RESET_REDUCER" });
          })
        })
        profileArr.push({
          image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + fileName),
          size: fileSize,
          mime_type: fileType,
        });
        // for profile picture end
        toast.success("Profile Picture Upload Successfull!!!")
      }
      else {
        toast.error("Please select any image!!!");
      }
    } else {
      toast.error("Please turn on internet!!!");
    }
  };

  const removePhoto = (data, e) => {
    if (data.type === "profilePhoto") {
      dispatch({
        type: "SET_PROFILE",
        payload: {
          name: "profilePhoto",
          data: [],
        },
      });
      inputRefProfile.current.value = null;
      setProfile("")
    } else if (data.type === "coverPhoto") {
      dispatch({
        type: "SET_COVER",
        payload: {
          name: "coverPhoto",
          data: [],
        },
      });
      inputRefCover.current.value = null;
      setCover("")
    }
  };

  return (
    <div className="gap2 gray-bg full_height">
      <Container className="py-4">
        <Row className="justify-content-center">
          <Col lg="6">
            <Card>
              <Card.Body className="p-4">
                <h1 className="fs-4 fw-fw-bold mb-2">Upload Profile Photos</h1>
                <p>
                  Here you can update your profile photo and your cover photo.
                </p>
                <p
                  style={{
                    display: errorShow ? "block" : "none",
                    color: "var(--red)",
                  }}
                >
                  Please select any one image
                </p>
                <Form onSubmit={submitForm}>
                  <Row>
                    <Col sm={12}>
                      <Form.Group
                        controlId="formFile"
                        className="uploadProfileFormGroup"
                      >
                        <Form.Label>Upload profile photo</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          onChange={handleProfilePhoto}
                          name="profilePhoto"
                          style={{
                            borderColor:
                              errorShow === null
                                ? "var(--bs-border-color)"
                                : errorShow === true
                                  ? "var(--red)"
                                  : "var(--bs-form-valid-border-color)",
                          }}
                          ref={inputRefProfile}
                        />
                      </Form.Group>
                      {state.profilePhoto.length > 0 && (
                        <>
                          {/* <CropperImage src={state.profilePhoto[0]} aspectRatio={10 / 10}/> */}
                          <div className="UploadImgCard">
                            <Image src={state.profilePhoto[0]} thumbnail />
                            <button
                              name="profilePhoto"
                              onClick={(e) =>
                                removePhoto({ type: "profilePhoto" }, e)
                              }
                            >
                              <LiaTimesSolid />
                            </button>
                          </div>
                        </>
                      )}
                    </Col>
                    <Col sm={12}>
                      <Form.Group
                        controlId="formFile"
                        className="uploadProfileFormGroup"
                      >
                        <Form.Label>Upload cover photo</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          onChange={handleCoverPhoto}
                          name="coverPhoto"
                          style={{
                            borderColor:
                              errorShow === null
                                ? "var(--bs-border-color)"
                                : errorShow === true
                                  ? "var(--red)"
                                  : "var(--bs-form-valid-border-color)",
                          }}
                          ref={inputRefCover}
                        />
                      </Form.Group>
                      {state.coverPhoto.length > 0 && (
                        <>
                          {/* <CropperImage src={state.coverPhoto[0]} aspectRatio={16 / 9}/> */}
                          <div className="UploadImgCard">
                            <Image src={state.coverPhoto[0]} thumbnail />
                            <button
                              name="coverPhoto"
                              onClick={(e) =>
                                removePhoto({ type: "coverPhoto" }, e)
                              }
                            >
                              <LiaTimesSolid />
                            </button>
                          </div>
                        </>
                      )}
                    </Col>

                    <Col className="d-flex align-items-center justify-content-center" sm={12}>
                      <Button
                        variant="primary"
                        type="submit"
                        onClick={redirectHandle}
                      // onClick={handleUpload}
                      >
                        Upload
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UploadProfile;