import React, { useEffect, useState } from 'react'
import { AdminMenu } from '../../../components/components';
import EmploymentRow from '../../../components/adminComponent/table/tableRow/EmploymentRow';
import { useGetEmploymentListQuery } from '../../../store/api/Admin/adminEmploymentApi';
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi';
import { useSelector } from 'react-redux';

const EmploymentLists = () => {
  const EmploymentList = useGetEmploymentListQuery()
  const { userSliceData: userInfo } = useSelector((store) => {
    return store.userSlice;
  });
  const [permissionArr, setPermissionArr] = useState("")
  const userPermissionList = useUserPermissionListQuery()

  useEffect(() => {
    if (userPermissionList?.isSuccess === true) {
      let user_permission_arr = userPermissionList?.data?.permissions;
      setPermissionArr(user_permission_arr)
    }
  }, [userPermissionList]);
  return (
    <div className='gap2 gray-bg full_height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <AdminMenu open />
          </div>
          <div className='col-md-9'>
            <div className='bg-white rounded-2 px-1 pb-3'>
              <div className="d-md-flex justify-content-between align-items-center p-2">
                <h5 className="mb-0">Employment</h5>
              </div>
              <table className='adminTable'>
                <thead>
                  <tr>
                    <th><div className='table_head_info'>Title</div></th>
                    <th><div className='table_head_info'>Salary</div></th>
                    <th><div className='table_head_info'>Shared in groups</div></th>
                    <th><div className='table_head_info'>Shared in pages</div></th>
                    <th><div className='table_head_info'>Applicants</div></th>
                    {(userInfo.user_type === 3 && permissionArr.includes("employment_active" || "employment_delete")) && <th><div className='table_head_info text-center'>Actions</div></th>}
                    {userInfo.user_type === 1 && <th><div className='table_head_info text-center'>Actions</div></th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    EmploymentList.isError && (
                      <tr className='noData'>
                        <td colSpan={8}>
                          <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                        </td>
                      </tr>
                    )
                  }
                  {
                    EmploymentList.isLoading && (
                      <tr className='noData'>
                        <td colSpan={8}>
                          <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                        </td>
                      </tr>
                    )
                  }
                  {
                    EmploymentList.isSuccess && (
                      EmploymentList?.data?.data.length > 0 ? (
                        EmploymentList?.data?.data.map((el, i) => {
                          return (
                            <EmploymentRow data={el} key={i} />
                          )
                        })
                      ) : (
                        <tr className='noData'>
                          <td colSpan={8}>
                            <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                          </td>
                        </tr>
                      )
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmploymentLists;
