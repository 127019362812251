import React, { useEffect, useState } from 'react';
import { AdminMenu, DotLoading, Post } from '../../../components/components';
import { useGetAdminPostListQuery } from '../../../store/api/Admin/PostPanelApi';

const AdminPosts = () => {
    const [currPage, setCurrPage] = useState(1); // storing current page number
    const [lastPage, setLastPage] = useState(1); // storing last page number
    const postList = useGetAdminPostListQuery({
        page: currPage,
        per_page: 5,
    })

    const handleInfiniteScroll = () => {
        const { scrollTop, offsetHeight } = document.documentElement;
        if (window.innerHeight + scrollTop !== offsetHeight) {
            // This will be triggered after hitting the last element.
            // API call here while implementing pagination.
            if (currPage < lastPage) {
                setCurrPage(currPage + 1)
            }
        }
    }

    useEffect(() => {
        if (postList.status === "fulfilled") {
            setLastPage(postList.data.last_page)
            window.addEventListener('scroll', handleInfiniteScroll);
            return () => {
                window.removeEventListener('scroll', handleInfiniteScroll);
            };
        }
    }, [handleInfiniteScroll])

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className=''>
                            <div className='d-md-flex justify-content-between align-items-center mb-3'>
                                <h5 className='mb-0'>Posts</h5>
                            </div>
                            {postList.isError && <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>}
                            {postList.isLoading && <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>}
                            {postList.isSuccess && (
                                postList?.data?.data.length > 0 ? (
                                    <>
                                        {postList?.data?.data?.map((el, i) => (
                                            <Post data={el} index={i} key={i} caption={el.post_text} type={"admin"} />
                                        ))}
                                        <div className="d-flex justify-content-center">
                                            {lastPage !== 0 ? (
                                                lastPage !== currPage && lastPage !== null ?
                                                    <DotLoading />
                                                    : 'No more post!!'
                                            ) : 'No post found !!'}
                                        </div>
                                    </>
                                ) : (
                                    <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminPosts