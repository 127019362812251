import React, { useReducer, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import style from "./employmentapply.module.css";
import { useNavigate, useParams } from 'react-router-dom';
import { useApplyJobMutation } from '../../store/api/jobApi';
import toast from 'react-hot-toast';
import AWS from "aws-sdk";
import generateRandomImageName from '../../methods/generateRandomImageName';

const EmploymentApply = () => {
    const { employmentId } = useParams()
    const [validated, setValidated] = useState(false);
    const [resume, setResume] = useState("")
    const inputRefResume = useRef(null);
    const [applyJob] = useApplyJobMutation()
    const navigate = useNavigate()

    //initial state for create group form
    const initialState = {
        first_name: "",
        last_name: "",
        contact_number: "",
        email: "",
        current_ctc: "",
        expected_ctc: "",
        cv_file: ""
    };
    const ApplyJobReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_INPUT":
                return { ...state, [payload.name]: payload.data };
            case "SET_CV":
                return { ...state, [payload.name]: payload.data };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(ApplyJobReducer, initialState);

    //to change title, description, rules
    const handleChange = (e) => {
        dispatch({
            type: "SET_INPUT",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };
    const handleResume = (e) => {
        setResume(e.target.files[0])
    }
    
    const submitForm = async (e) => {
        e.preventDefault();

        const displayImage_S3_BUCKET = "ubuntu-social-media-2";
        const displayImage_REGION = "ap-south-1";
        AWS.config.update({
            accessKeyId: "AKIA4NNCEDROYLRYR2OX",
            secretAccessKey: "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",
        });
        const displayImage_s3 = new AWS.S3({
            params: { Bucket: displayImage_S3_BUCKET },
            region: displayImage_REGION,
        });
        let randomNameDisplayImage = generateRandomImageName(resume.name);
        let fileName = "resume_" + randomNameDisplayImage.split(" ").join("_").split("-").join("_");
        let fileSize = resume.size;
        let fileType = resume.type;
        const displayImage_params = {
            Bucket: displayImage_S3_BUCKET,
            Key: fileName,
            Body: resume,
        };

        var uploadDisplayImage = displayImage_s3
            .putObject(displayImage_params)
            .on("httpUploadProgress", (evt) => {
                // File uploading progress
                console.log(
                    "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            })
            .promise();

        await uploadDisplayImage.then((data, err) => {});

        let resumeArr = []

        resumeArr.push({
            image_url:
                ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
                    fileName),
            size: fileSize,
            mime_type: fileType,
        });
        
        const obj = {
            employment_id: employmentId,
            first_name: state.first_name,
            last_name: state.last_name,
            contact_number: state.contact_number,
            email: state.email,
            current_ctc: state.current_ctc,
            expected_ctc: state.expected_ctc,
            cv_file: resumeArr
        }
        
        applyJob(obj).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message);
                navigate("/employment")
            } else {
                toast.error("Something went wrong");
            }
        })
    }

    return (
        <div className="gap2 gray-bg full_height">
            <div className="container">
                <div className="row">
                    <div className={"col-md-12 col-lg-12"}>
                        <Card className={`${style.central_meta}`}>
                            <Card.Body className={`${style.card_body}`}>
                                <Card.Title className={`${style.card_title} mb-3 fs-4`} >
                                    Apply For The Job
                                </Card.Title>
                                <Form noValidate validated={validated} method="post" onSubmit={submitForm} >
                                    <Row>
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>First Name*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your first name"
                                                value={state.first_name}
                                                onChange={handleChange}
                                                name="first_name"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide group name</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Last Name*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your last name"
                                                value={state.last_name}
                                                onChange={handleChange}
                                                name="last_name"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide group name</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Contact Number*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your contact number"
                                                value={state.contact_number}
                                                onChange={handleChange}
                                                name="contact_number"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide group name</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Your Email ID*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your email id"
                                                value={state.email}
                                                onChange={handleChange}
                                                name="email"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide group name</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Current CTC*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your current ctc"
                                                value={state.current_ctc}
                                                onChange={handleChange}
                                                name="current_ctc"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide group name</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Expectrd CTC*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your expected ctc"
                                                value={state.expected_ctc}
                                                onChange={handleChange}
                                                name="expected_ctc"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide group name</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formFile" className="col-md-6 mb-3">
                                            <Form.Label>Upload Your CV *</Form.Label>
                                            <div className="position-relative">
                                                <Form.Control type="file"
                                                    onChange={handleResume}
                                                    name="display_image"
                                                    className="prevent-validation" />
                                            </div>
                                        </Form.Group>
                                    </Row>
                                    <div style={{ width: '14%', margin: 'auto' }} className="mt-2">
                                        <Button className="btn btn-primary" type="submit">Apply</Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EmploymentApply
