import React from 'react'
import { DashboardMenuLink, FilterWrapper } from '../../../../components/components';
import { Link, useParams } from 'react-router-dom';
import { useGetAlbumQuery } from '../../../../store/api/userApi';
import './images.css';
import { useSelector } from 'react-redux';
import { useGetSinglePageQuery } from '../../../../store/api/pageApi';
import { useGetMyGroupAlbumDataQuery, useGetMyPageAlbumDataQuery } from '../../../../store/api/albumApi';
import GroupProfileLayout from '../../GroupProfileLayout';
import { useGetAlbumGroupOthersQuery, useGetAlbumGroupQuery, useGetSingleGroupQuery } from '../../../../store/api/groupApi';

const Images = () => {
    // get id from param
    const { groupId } = useParams();

    // get single Event Data
    const singleGroup = useGetSingleGroupQuery(groupId);
    let groupWholeData = singleGroup?.data?.data;

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: groupWholeData?.user?._id,
        meta_type: "Group",
        meta_data: groupId
    }
    const AlbumDataOthers = useGetAlbumGroupOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetAlbumGroupQuery(groupId)
    const Images = groupWholeData?.user?._id === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

    const CoverImagesData = Images?.filter(function (el) {
        return el.name === "cover_photo";
    })[0]?.gallery;

    const AllImagesData = Images?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    const ProfileImagesData = Images?.filter(function (el) {
        return el.name === "profile_picture";
    })[0]?.gallery;

    return (
        <GroupProfileLayout data={groupWholeData}>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/group/${groupId}/media`} title="Images" noCount />
                            <DashboardMenuLink url={`/group/${groupId}/media/video`} title="Videos" noCount />
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className='row gx-4'>
                    <div className='col-md-2'>
                        <Link to={`/group/${groupId}/media/profile_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>Profile Picture</h5>
                                    <p>{ProfileImagesData?.length > 0 ? ProfileImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to={`/group/${groupId}/media/cover_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>Cover Picture</h5>
                                    <p>{CoverImagesData?.length > 0 ? CoverImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to={`/group/${groupId}/media/all_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>All Picture</h5>
                                    <p>{AllImagesData?.length > 0 ? AllImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </GroupProfileLayout>
    )
}

export default Images