import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ApplicantList from '../../ApplicantList/ApplicantList'
import PageGroupList from '../../PageGroupList/PageGroupList'
import { useDeleteEmploymentMutation, useUpdateEmploymentStatusMutation } from '../../../../store/api/Admin/adminEmploymentApi'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'

const EmploymentRow = ({ data }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [employmentDelete] = useDeleteEmploymentMutation()
    const deleteEmployment = () => {
        employmentDelete(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
        })
    }

    const [statusModal, setStatusModal] = useState(false)
    const [statusChange] = useUpdateEmploymentStatusMutation()
    const handleStatusChange = () => {
        statusChange(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
            setStatusModal(false)

        })
    }

    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <>
            <tr>
                <td>
                    <div className='table_info'>{data.title}</div>
                </td>
                <td>
                    <div className='table_info'>{data.SalaryWithCurrency}</div>
                </td>
                <td>
                    <div className='table_info'>
                        {
                            data.groups.length > 0 ?
                                <PageGroupList type={"group"} data={data.groups} />
                                : 'No groups'
                        }

                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        {
                            data.pages.length > 0 ?
                                <PageGroupList type={"page"} data={data.pages} />
                                : 'No pages'
                        }
                    </div>
                </td>
                <td>
                    <div className='table_info'>
                        {
                            data.job_apply.length > 0 ?
                                <ApplicantList data={data.job_apply} />
                                : 'No Applicant'
                        }

                    </div>
                </td>
                <td>
                    <div className='table_actions text-center'>
                        {(userInfo.user_type === 3 && user_permission_arr.includes("employment_active")) && <button onClick={() => setStatusModal(true)} className={data.is_active === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.is_active === 1 ? "Active" : "Deactive"}
                        </button>}
                        {userInfo.user_type === 1 && <button onClick={() => setStatusModal(true)} className={data.is_active === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.is_active === 1 ? "Active" : "Deactive"}
                        </button>}

                        {(userInfo.user_type === 3 && user_permission_arr.includes("employment_delete")) && <button onClick={() => setDeleteModal(true)} className={'bg-danger-subtle text-danger'}>
                            Delete
                        </button>}
                        {userInfo.user_type === 1 && <button onClick={() => setDeleteModal(true)} className={'bg-danger-subtle text-danger'}>
                            Delete
                        </button>}
                    </div>
                    {/* Delete MODAL */}
                    <Modal centered size="sm" show={deleteModal} onHide={() => setDeleteModal(false)}>
                        <Modal.Body>
                            <div className="text-center">
                                <h5 className="mb-3">Are you sure, you want to Delete this plan?</h5>
                                <div className="text-center">
                                    <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={deleteEmployment} className="mx-2">
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* Status MODAL */}
                    <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                        <Modal.Body>
                            <div className="text-center">
                                <h5 className="mb-3">Are you sure, you want to {data.status === 1 ? 'Active' : 'Deactive'} this plan?</h5>
                                <div className="text-center">
                                    <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={handleStatusChange} className="mx-2">
                                        {data.is_active !== 1 ? 'Active' : 'Deactive'}
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </td>
            </tr>
        </>
    )
}

export default EmploymentRow
