import React, { useState } from 'react'
import { useDeletePostMutation, useGetPostDetailsQuery } from '../../store/api/postApi'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from "react-slick";
import "./slick.min.css";
import "./slick-theme.min.css";
import './postDetails.css'
import LazyImage from '../../components/image/Image';
import Comments from '../../components/post/comment/comments';
import { Dropdown, Button as AntButton } from 'antd';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useSaveAPostMutation } from '../../store/api/savePostApi';
import toast from 'react-hot-toast';
import { BsThreeDots } from 'react-icons/bs';
import EpochTimeToDateTime from '../../methods/EpochTimeToDateTime';
import { useGetReportReasonListQuery } from '../../store/api/reportApi';
import { Image, defaultUserDp } from '../../components/components';
import EditPost from '../../components/post/edit/editPost';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import ReactPlayer from "react-player";
import Reacts from '../../components/utils/reacts/Reacts';
import { useGetReactsQuery } from '../../store/api/reactApi';
import { useCreateReportMutation } from '../../store/api/postApi'
import Vote from '../../components/utils/vote/Vote';
import { useGetVoteQuery } from '../../store/api/voteApi';
import { useGetCommentsQuery } from '../../store/api/commentApi';
import { WhatsappShareButton, LinkedinShareButton, TwitterShareButton, FacebookShareButton, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";

const PostDetails = () => {
  const [shareDrop, setShareDrop] = useState(false)
  const navigate = useNavigate()
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [idInState, setIdInState] = useState("");
  const params = useParams();
  const id_index = params.id.split("_");
  const id = id_index[0]
  const index = id_index[1]
  const shareUrl = window.location.href;

  const getReacts = useGetReactsQuery({
    post_id: id,
    page: 1,
    per_page: 1000000000,
  })
  const reactListOnPost = getReacts?.data?.data;

  const getComment = useGetCommentsQuery(id)
  const totalChildrenLength = getComment?.data?.data.reduce((total, group) => {
    const childrenArrays = getComment?.data?.data.map(item => item.children);
    const childrenLength = childrenArrays.reduce((sum, children) => sum + children?.length, 0);
    return 0 + childrenLength;
  }, 0);

  const getVote = useGetVoteQuery(id)
  const voteListOnPost = getVote?.data?.data;

  const [savePost] = useSaveAPostMutation()
  const detailsData = useGetPostDetailsQuery(id)
  const [deletePost] = useDeletePostMutation();
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  })

  const savePostFunc = () => {
    savePost(detailsData?.data?.data._id).then((res) => {
      if (res?.data?.error === 0) {
        toast.success(res?.data?.message)
      }
    })
  }
  const triggerDelete = () => {
    deleteThisPost({ postId: detailsData?.data?.data?._id })
    setDeleteModal(false)
  }
  const deleteThisPost = (id) => {
    deletePost(id).then((res) => {
      if (res?.data?.error === 0) {
        toast.success(res?.data?.message)
        navigate('/')
      } else {
        toast.error(res?.data?.message)
      }
    })
  }
  const editThisPost = (id) => {
    setShowEditModal(true)
    setIdInState(id)
  }

  // TO REPORT ON A POST
  const reportReasonList = useGetReportReasonListQuery()
  const [reportApost] = useCreateReportMutation()
  const [showReportModal, setShowReportModal] = useState(false);
  const [otherReason, setOtherReason] = useState("")
  const [reason, setReason] = useState("")
  let getReason = reason.length > 0 ? JSON.parse(reason) : "";
  const reportApostModal = () => {
    let reportObj = {
      index: index,
      meta_data: detailsData?.data?.data?._id,
      meta_type: "Post",
      other_reason: otherReason,
      report_reason_id: getReason._id
    }
    reportApost(reportObj).then((res) => {
      if (res?.data?.error === 0) {
        toast.success(res?.data?.message)
      }
    })
    setShowReportModal(false);
    setOtherReason("");
    setReason("");
  }

  const postDropdownList = userSliceData?._id === detailsData?.data?.data?.posted_by?._id ? [
    {
      label: <div onClick={() => editThisPost(detailsData?.data?.data?._id)}>Edit Post</div>,
      key: "0"
    },
    {
      label: <div onClick={savePostFunc}>Save Post</div>,
      key: "1"
    },
    {
      label: <div onClick={() => setDeleteModal(true)}>Delete Post</div>,
      key: "3"
    }
  ] : detailsData?.data?.data?.is_reported === false ? [
    {
      label: <div onClick={savePostFunc}>Save Post</div>,
      key: "1"
    },
    {
      label: <div onClick={() => setShowReportModal(true)}>Report Post</div>,
      key: "2"
    }
  ] : [
    {
      label: <div onClick={savePostFunc}>Save Post</div>,
      key: "1"
    },
    {
      label: <div onClick={() => toast.error("Already Reported!!")}>Reported</div>,
      key: "2"
    }
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <button><MdOutlineKeyboardArrowRight /></button>,
    prevArrow: <button><MdOutlineKeyboardArrowLeft /></button>
  };

  if (detailsData.isLoading && getReacts.isLoading) {
    return (
      <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
        <h4 className="mb-0">Loading...</h4>
      </div>
    )
  }

  if (!userSliceData?._id) {
    return (
      <div className="gap2 gray-bg full_height">
        <div className='container'>
          <div className={'row justify-content-center'}>
            <div className='col-md-8'>
              <div className='post_details bg-white rounded-3'>
                <div className='px-3 py-3 details_right_block_top'>
                  <div className="post_top">
                    <div className="post_auth">
                      <figure>
                        <Image
                          src={detailsData.data?.data?.posted_by?.profile_picture !== undefined ? detailsData.data?.data?.posted_by?.profile_picture[0]?.image_url : defaultUserDp}
                          alt="..."
                        />
                      </figure>
                      <div className="auth_info">
                        <div className="auth_nm">
                          <Link to={`/profile/${detailsData?.data?.data?.posted_by?._id}/timeline`}>
                            {detailsData?.data?.data?.posted_by?.fullName}
                          </Link>
                        </div>
                        <span>
                          {detailsData?.data?.data?.meta_type && <span className="mr-1">Post from <span>{detailsData?.data?.data?.meta_type}</span> ·</span>}{EpochTimeToDateTime(detailsData?.data?.data?.createdAt).date} at {EpochTimeToDateTime(detailsData?.data?.data?.createdAt).time}
                        </span>
                      </div>
                    </div>
                    <div className="post_top_right">
                      {(detailsData.data.data.category == null) ? ""
                        :
                        <div className="post_category">{detailsData?.data?.data?.category?.category_name || detailsData.data.data.category.name}</div>
                      }
                    </div>
                  </div>
                  {
                    detailsData.data?.data?.post_text !== '' ? (
                      <div className="post_meta">
                        <div className={"post_content active"} dangerouslySetInnerHTML={{ __html: detailsData.data?.data?.post_text }} />
                      </div>
                    ) : ('')
                  }
                </div>
                {detailsData.data?.data?.images.length > 0 && (
                  <div className='post_details_images rounded-3 mx-3 mb-2'>
                    <div>
                      <Slider {...settings}>
                        {
                          detailsData.data?.data?.images.map((elem, index) => {
                            return (
                              <div className='post_details_slideItem rounded-3' key={index}>
                                {/* IMAGE VIEW */}
                                {

                                  elem.mime_type.slice(0, 5) === "image" && (
                                    <LazyImage src={elem.image_url} className={'w-100 rounded-3'} />
                                  )
                                }
                                {/* VIDEO VIEW */}
                                {
                                  elem.mime_type.slice(0, 5) === "video" && (
                                    <div className="videoPost">
                                      <ReactPlayer
                                        playing={false}
                                        width={"100%"}
                                        controls={true}
                                        url={elem.images[0].image_url}
                                      />
                                    </div>
                                  )
                                }
                              </div>
                            )
                          })
                        }
                      </Slider>
                    </div>
                  </div>
                )}
                <div className="post_actions mx-3 mb-2">
                  <div className="post_action_group">
                    <div className="reactionPost">
                      <Reacts id={id} reactListOnPost={reactListOnPost} />
                    </div>
                    <Vote id={id} voteListOnPost={voteListOnPost} />
                  </div>
                  <div className="action comment">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square">
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                    <span>
                      {getComment?.data?.data?.length + totalChildrenLength}{" "} <span className="action_title d-none d-xl-inline">comments</span>
                    </span>
                  </div>
                  <div className={shareDrop === false ? 'action share' : 'action share active'}>
                    <button onClick={() => setShareDrop(!shareDrop)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="me-0"
                      >
                        <circle cx="18" cy="5" r="3"></circle>
                        <circle cx="6" cy="12" r="3"></circle>
                        <circle cx="18" cy="19" r="3"></circle>
                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                      </svg>
                    </button>
                    <div className="shareOptions">
                      <FacebookShareButton
                        url={shareUrl}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        url={shareUrl}
                        className="Demo__some-network__share-button"
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                      <LinkedinShareButton
                        url={shareUrl}
                        className="Demo__some-network__share-button"
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                      <TwitterShareButton
                        url={shareUrl}
                        className="Demo__some-network__share-button"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </div>
                  </div>
                </div>
                <div className={detailsData.data?.data?.images.length > 0 ? 'details_right_block' : 'details_right_block bg-white rounded-3 shadow'}>
                  <Comments className={'mx-0'} data={detailsData?.data?.data} postId={detailsData?.data?.data._id} />
                </div>
              </div>
            </div>
          </div>

          {/* EDIT POST MODAL */}
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)} backdrop="static" keyboard={false}>
            <Modal.Body>
              <EditPost data={detailsData?.data?.data} postId={idInState} showEditModal={{ showEditModal, setShowEditModal }} />
            </Modal.Body>
          </Modal>

          {/* CONFIRM DELETE MODAL */}
          <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
            <Modal.Body>
              <div className="text-center">
                <h5 className="mb-3">Are you sure, you want to delete the post?</h5>
                <div className="text-center">
                  <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={() => triggerDelete()} className="mx-2">
                    Delete
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* CONFIRM DELETE MODAL */}
          <Modal centered size="sm" show={showReportModal} onHide={() => setDeleteModal(false)}>
            <Modal.Body>
              <div className="text-center">
                <h5 className="mb-3">Report on Post</h5>
                <Form noValidate className="we-form" method="post">
                  <Form.Group as={Col} className="mb-2 pb-1">
                    <Form.Label>Reason</Form.Label>
                    <Form.Select onChange={(e) => setReason(e.target.value)} required>
                      <option value="">Select reason</option>
                      {reportReasonList?.data?.data.map((el) => {
                        return <option key={el._id} value={JSON.stringify(el)} >
                          {el.reason_text}
                        </option>
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select your country
                    </Form.Control.Feedback>
                  </Form.Group>
                  {getReason.reason_text === "Others" && <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                    <Form.Label>Description*</Form.Label>
                    <Form.Control
                      placeholder="Enter a small description"
                      required
                      type="text"
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      as="textarea"
                      style={{ height: '70px' }}
                    />
                    <Form.Control.Feedback type="invalid">Please add description</Form.Control.Feedback>
                  </Form.Group>}

                  <div className="text-center">
                    <Button variant="secondary" onClick={() => setShowReportModal(false)} className="mx-2">
                      Cancel
                    </Button>
                    <Button variant="danger" onClick={reportApostModal} className="mx-2">
                      Report
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    )
  }

  return (
    <div className="gap2 gray-bg full_height">
      <div className='container'>
        <div className={'row justify-content-center'}>
          <div className='col-md-8'>
            <div className='post_details bg-white rounded-3'>
              <div className='px-3 py-3 details_right_block_top'>
                <div className="post_top">
                  <div className="post_auth">
                    <figure>
                      <Image
                        src={detailsData.data?.data?.posted_by?.profile_picture !== undefined ? detailsData.data?.data?.posted_by?.profile_picture[0]?.image_url : defaultUserDp}
                        alt="..."
                      />
                    </figure>
                    <div className="auth_info">
                      <div className="auth_nm">
                        <Link to={`/profile/${detailsData?.data?.data?.posted_by?._id}/timeline`}>
                          {detailsData?.data?.data?.posted_by?.fullName}
                        </Link>
                      </div>
                      <span>
                        {detailsData?.data?.data?.meta_type && <span className="mr-1">Post from <span>{detailsData?.data?.data?.meta_type}</span> ·</span>}{EpochTimeToDateTime(detailsData?.data?.data?.createdAt).date} at {EpochTimeToDateTime(detailsData?.data?.data?.createdAt).time}
                      </span>
                    </div>
                  </div>
                  <div className="post_top_right">
                    {(detailsData?.data?.data?.category == null) ? ""
                      :
                      <div className="post_category">{detailsData?.data?.data?.category?.category_name || detailsData?.data?.data?.category?.name}</div>
                    }
                    {detailsData?.data?.data?.category !== null && (
                      <Dropdown
                        menu={{ items: postDropdownList }}
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <AntButton
                          type="link"
                          size="small"
                          shape="circle"
                          icon={<BsThreeDots />}
                        />
                      </Dropdown>
                    )}
                  </div>
                </div>
                {
                  detailsData.data?.data?.post_text !== '' ? (
                    <div className="post_meta">
                      <div className={"post_content active"} dangerouslySetInnerHTML={{ __html: detailsData.data?.data?.post_text }} />
                    </div>
                  ) : ('')
                }
              </div>
              {
                detailsData.data?.data?.images.length > 0 && (
                  <div className='post_details_images rounded-3 mx-3 mb-2'>
                    <div>
                      <Slider {...settings}>
                        {
                          detailsData.data?.data?.images.map((elem, index) => {
                            return (
                              <div className='post_details_slideItem rounded-3' key={index}>
                                {/* IMAGE VIEW */}
                                {

                                  elem.mime_type.slice(0, 5) === "image" && (
                                    <LazyImage src={elem.image_url} className={'w-100 rounded-3'} />
                                  )
                                }
                                {/* VIDEO VIEW */}
                                {
                                  elem.mime_type.slice(0, 5) === "video" && (
                                    <div className="videoPost">
                                      <ReactPlayer
                                        playing={false}
                                        width={"100%"}
                                        controls={true}
                                        url={elem.images[0].image_url}
                                      />
                                    </div>
                                  )
                                }
                              </div>
                            )
                          })
                        }
                      </Slider>
                    </div>
                  </div>
                )
              }
              <div className="post_actions mx-3 mb-2">
                <div className="post_action_group">
                  <div className="reactionPost">
                    <Reacts id={id} reactListOnPost={reactListOnPost} />
                  </div>
                  <Vote id={id} voteListOnPost={voteListOnPost} />
                </div>
                <div className="action comment">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-message-square"
                  >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </svg>
                  <span>
                    {getComment?.data?.data?.length + totalChildrenLength}{" "} <span className="action_title d-none d-xl-inline">comments</span>
                  </span>
                </div>
                <div className={shareDrop === false ? 'action share' : 'action share active'}>
                  <button onClick={() => setShareDrop(!shareDrop)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="me-0"
                    >
                      <circle cx="18" cy="5" r="3"></circle>
                      <circle cx="6" cy="12" r="3"></circle>
                      <circle cx="18" cy="19" r="3"></circle>
                      <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                      <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                    </svg>
                  </button>
                  <div className="shareOptions">
                    <FacebookShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <LinkedinShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <TwitterShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
              <div className={detailsData.data?.data?.images.length > 0 ? 'details_right_block' : 'details_right_block bg-white rounded-3 shadow'}>
                {
                  getComment.isLoading && (
                    <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                  )
                }
                {
                  getComment.isError && (
                    <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                  )
                }
                {
                  (getComment.isLoading === false && getComment.isError === false) && (
                    <Comments className={'mx-0'} data={detailsData?.data?.data} postId={detailsData?.data?.data._id} />
                  )
                }
              </div>
            </div>
          </div>
        </div>



        {/* EDIT POST MODAL */}
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)} backdrop="static" keyboard={false}>
          <Modal.Body>
            <EditPost data={detailsData?.data?.data} postId={idInState} showEditModal={{ showEditModal, setShowEditModal }} />
          </Modal.Body>
        </Modal>

        {/* CONFIRM DELETE MODAL */}
        <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
          <Modal.Body>
            <div className="text-center">
              <h5 className="mb-3">Are you sure, you want to delete the post?</h5>
              <div className="text-center">
                <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                  Cancel
                </Button>
                <Button variant="danger" onClick={() => triggerDelete()} className="mx-2">
                  Delete
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* CONFIRM DELETE MODAL */}
        <Modal centered size="sm" show={showReportModal} onHide={() => setDeleteModal(false)}>
          <Modal.Body>
            <div className="text-center">
              <h5 className="mb-3">Report on Post</h5>
              <Form noValidate className="we-form" method="post">
                <Form.Group as={Col} className="mb-2 pb-1">
                  <Form.Label>Reason</Form.Label>
                  <Form.Select onChange={(e) => setReason(e.target.value)} required>
                    <option value="">Select reason</option>
                    {reportReasonList?.data?.data.map((el) => {
                      return <option key={el._id} value={JSON.stringify(el)} >
                        {el.reason_text}
                      </option>
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select your country
                  </Form.Control.Feedback>
                </Form.Group>
                {getReason.reason_text === "Others" && <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                  <Form.Label>Description*</Form.Label>
                  <Form.Control
                    placeholder="Enter a small description"
                    required
                    type="text"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    as="textarea"
                    style={{ height: '70px' }}
                  />
                  <Form.Control.Feedback type="invalid">Please add description</Form.Control.Feedback>
                </Form.Group>}

                <div className="text-center">
                  <Button variant="secondary" onClick={() => setShowReportModal(false)} className="mx-2">
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={reportApostModal} className="mx-2">
                    Report
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default PostDetails