import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./MainBody.css";

const MainBody = () => {
   const accArr = new Array(5).fill(0);
   return (
      <div className="col-lg-9">
         <div className="faq-area faqArea">
            <h4>Ask help about Ubuntu</h4>
            <p>
               Anim pariatur cliche reprehenderit, enim eiusmod high life
               accusamus terry richardson ad squid. 3 wolf moon officia aute,
               non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt
               laborum eiusmod.{" "}
            </p>

            <Accordion defaultActiveKey={0} flush>
               {accArr.map((el, i) => (
                  <Accordion.Item eventKey={i} className="faqAccordionItem">
                     <Accordion.Header className="faqAccordionButton">
                        How to make your own social website?
                     </Accordion.Header>
                     <Accordion.Body>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod.{" "}
                        <Link to="#" title="">
                           Go for Create Page
                        </Link>
                        <ol>
                           <li>register yourself on friendzone</li>
                           <li>go to the setting panal</li>
                           <li>
                              click on{" "}
                              <Link to="#" title="">
                                 create page
                              </Link>
                           </li>
                        </ol>
                     </Accordion.Body>
                  </Accordion.Item>
               ))}
            </Accordion>
         </div>
      </div>
   );
};

export default MainBody;
