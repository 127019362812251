import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   userData: {},
   roomData: {},
   online: "",
   offline: "",
   messageReadStatus: ""
};
const chatSlice = createSlice({
   name: "chat",
   initialState,
   reducers: {
      setUserForChat: (state, action) => {
         state.userData = action.payload;
      },
      setChatRoomForChat: (state, action) => {
         state.roomData = action.payload;
      },
      setOnlineForChat: (state, action) => {
         state.online = action.payload;
      },
      setOfflineForChat: (state, action) => {
         state.offline = action.payload;
      },
      setMessageReadStatus: (state, action) => {
         state.messageReadStatus = action.payload;
      },
   },
});

// set Theme
export const {
   setUserForChat,
   setChatRoomForChat,
   setOnlineForChat,
   setOfflineForChat,
   setMessageReadStatus
} = chatSlice.actions;
// Select Theme
export const selectUserForChat = (state) => state.userData;
export const selectRoomForChat = (state) => state.roomData;
export const selectOnlineForChat = (state) => state.online;
export const selectOffnlineForChat = (state) => state.offline;
export const selectMessageReadStatus = (state) => state.messageReadStatus;

export default chatSlice.reducer;
