import React, { useState } from 'react'
import './TopTrending.css'
import image from '../../../assets/images/resources/recentlink-1.jpg'
import { Link } from 'react-router-dom'
const TopTrending = () => {
    const [tab, setTab] = useState('Trending')

    return (
        <div className="widget trending_widget">
            <h4 className="widget-title">Top Trending</h4>
            <ul className="nav nav-tabs trend">
                <li className="nav-item">
                    <button type='button' className={tab === 'Trending' ? 'btn btn-danger active' : 'btn btn-light'} onClick={() => setTab('Trending')}>Trending</button>
                </li>
                <li className="nav-item">
                    <button type='button' className={tab === 'Hot' ? 'btn btn-danger active' : 'btn btn-light'} onClick={() => setTab('Hot')}>Hot</button>
                </li>
            </ul>
            {/* Tab panes */}
            <div className="tab-content">
                <div className={tab === 'Trending' ? 'tab-pane active fade show' : 'tab-pane fade'}>
                    <ul className="recent-links">
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        moira's fade reaches much farther than you think.
                                    </Link>
                                </h6>
                                <span>2 weeks ago </span>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        moira's fade reaches much farther than you think.
                                    </Link>
                                </h6>
                                <span>2 weeks ago </span>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        moira's fade reaches much farther than you think.
                                    </Link>
                                </h6>
                                <span>2 weeks ago </span>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        moira's fade reaches much farther than you think.
                                    </Link>
                                </h6>
                                <span>2 weeks ago </span>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        moira's fade reaches much farther than you think.
                                    </Link>
                                </h6>
                                <span>2 weeks ago </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={tab === 'Hot' ? 'tab-pane active fade show' : 'tab-pane fade'}>
                    <ul className="recent-links">
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        daniel asks if we want him to do the voice of doomfist
                                    </Link>
                                </h6>
                                <span>3 months ago </span>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        the oneVoice overwatch scandals.
                                    </Link>
                                </h6>
                                <span>1 day before</span>
                            </div>
                        </li>
                        <li>
                            <figure>
                                <img alt="" src={image} />
                            </figure>
                            <div className="re-links-meta">
                                <h6>
                                    <Link to={'/'}>
                                        moira's fade reaches much farther than you think.
                                    </Link>
                                </h6>
                                <span>2 weeks ago </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TopTrending