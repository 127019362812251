import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const { userSlice } = getState();
      let token = userSlice.userAuthToken;
      return headers.set("x-access-token", token);
    },
  }),
  tagTypes: ["user", "userAlbum"],

  endpoints: (builder) => ({
    // Get user full details against id
    getUserDetailsAgainstId: builder.query({
      query: (id) => ({
        url: "api/users/profile-details",
        method: "GET",
        params: {
          user_id: id
        }
      }),
      providesTags: ["user"],
    }),
    // Connection lists
    // get all connections of the user
    getUserConnection: builder.query({
      query: (data) => ({
        url: "api/connections/user-connections",
        method: "GET",
        params: {
          search: data.searchVal,
          filter: data.sortVal,
          page: data.pageNo,
          per_page: 100
        }
      }),
      providesTags: ["user"],
    }),
    //get all suggested user
    getSuggestedUser: builder.query({
      query: (data) => ({
        url: "api/connections/suggested-connection",
        method: "GET",
        params: {
          search: data.searchVal,
          filter: data.sortVal,
          page: data.pageNo,
          per_page: 12
        }
      }),
      providesTags: ["user"],
    }),
    // get all the new request
    getNewRequest: builder.query({
      query: (data) => ({
        url: "api/connections/new-connection-request",
        method: "GET",
        params: {
          search: data.searchVal,
          filter: data.sortVal,
          page: data.pageNo,
          per_page: 100
        }
      }),
      providesTags: ["user"],
    }),
    // update the user
    updateUser: builder.mutation({
      query: (data) => ({
        url: "api/users/update-profile",
        method: "PUT",
        body: data
      }),
      invalidatesTags: ["user", "userAlbum"],
    }),
    // accept the request
    acceptNewRequest: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/accept-connection-request`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // send request to other user
    sendRequest: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/send-connection-request`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // Cancel request which I sent
    cancelRequest: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/cancel-connection-request`,
          method: "POST",
          body: {
            recipient: userId
          },
        };
      },
      invalidatesTags: ["user"],
    }),
    // delete friend request
    deleteConnection: builder.mutation({
      query: (data) => {
        return {
          url: `api/connections/delete-connection-request`,
          method: "DELETE",
          body: data
        };
      },
      invalidatesTags: ["user"],
    }),
    // remove from friend
    removeConnection: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/unfriend-connection`,
          method: "POST",
          body: userId
        };
      },
      invalidatesTags: ["user"],
    }),
    // block any connection or user
    blockConnection: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/block-connection`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // report any connection or user
    reportConnection: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/report-connection`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // get list who are my follower 
    getfollowerList: builder.query({
      query: () => ({
        url: "api/connections/follower-connection",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    // get list whom I am following
    getFollowingList: builder.query({
      query: () => ({
        url: "api/connections/following-connection",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    // follow-unfollow connection
    followUnfollowConnection: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/follow-unfollow-connection`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // follow-unfollow friend
    followUnfollowFriend: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/follow-unfollow-friend`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // follow a user
    followUser: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/follow-connection`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // unfollow a user
    unfollowUser: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/unfollow-connection`,
          method: "POST",
          body: userId,
        };
      },
      invalidatesTags: ["user"],
    }),
    // blocked connection list
    blockedUserList: builder.query({
      query: () => ({
        url: "api/connections/blocked-connections",
        method: "GET",
      }),
      providesTags: ["user"],
    }),
    // unblock connection
    unblockConnection: builder.mutation({
      query: (userId) => {
        return {
          url: `api/connections/unblock-connection`,
          method: "POST",
          body: {
            targetUserId: userId
          },
        };
      },
      invalidatesTags: ["user"],
    }),
    // get connection list by id
    getConnectionById: builder.query({
      query: (data) => {
        return {
          url: "api/users/profile-details",
          method: "GET",
          params: {
            // page: 1,
            per_page: 100,
            module: "Connections",
            user_id: data.id,
            search: data.searchVal,
            filter: data.sortVal,
            page: data.pageNo,

          }
        }
      },
      providesTags: ["user"],
    }),
    // Album APIs
    getAlbum: builder.query({
      query: (id) => {
        return {
          url: "api/album",
          method: "GET",
          params: {
            page: 1,
            meta_type: "User",
            meta_data: id
          }
        };
      },
      providesTags: ["userAlbum"],
    }),

    getAlbumOthers: builder.query({
      query: (data) => {
        return {
          url: "api/users/profile-details",
          method: "GET",
          params: data
        };
      },
      providesTags: ["userAlbum"],
    }),

    allCountLists: builder.query({
      query: () => {
        return {
          url: `api/all/count`,
          method: "GET",
          params: {
            module: "User"
          },
        }
      },
      providesTags: ["user"],
    }),

    allOtherCountLists: builder.query({
      query: (id) => {
        return {
          url: `api/all/count`,
          method: "GET",
          params: {
            module: "User",
            user_id: id
          },
        }
      },
      providesTags: ["user"],
    }),

    deleteCover: builder.mutation({
      query: (data) => {
        return {
          url: `api/users/delete-photo`,
          method: "POST",
          body: {
            type: data.type
          },
        };
      },
      invalidatesTags: ["user"],
    }),
  }),
});

export const { useAllOtherCountListsQuery, useGetConnectionByIdQuery, useUpdateUserMutation, useGetUserConnectionQuery, useGetNewRequestQuery, useAcceptNewRequestMutation, useDeleteConnectionMutation, useGetSuggestedUserQuery, useSendRequestMutation, useBlockConnectionMutation, useReportConnectionMutation, useFollowUnfollowConnectionMutation, useRemoveConnectionMutation, useFollowUnfollowFriendMutation, useGetUserDetailsAgainstIdQuery, useGetAlbumQuery, useBlockedUserListQuery, useUnblockConnectionMutation, useCancelRequestMutation, useGetFollowingListQuery, useGetfollowerListQuery, useFollowUserMutation, useUnfollowUserMutation, useGetAlbumOthersQuery, useAllCountListsQuery, useDeleteCoverMutation } = userApi;
