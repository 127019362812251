import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./tableRow.css"

const NotificationRow = ({ data }) => {
    const navigate = useNavigate()
    console.log(data)
    return (
        <>
            <tr>
                <td>
                    <div className='table_info'> {data.title} </div>
                </td>
                <td>
                    <div className='table_info'>{data?.description}</div>
                </td>
                <td>
                    <div className='table_info'>{data.meta_type}</div>
                </td>
                <td>
                    <div className='table_info'>{data.notification_for}</div>
                </td>
                <td>
                    <div className='table_actions text-center'>
                        <button className='bg-gray-subtle text-gray' onClick={() => navigate(`/admin/notification/edit/${data?._id}`)}>Edit</button>
                    </div>
                </td>
                <td>
                    {/* <div className='table_actions text-center'>
                        {(userInfo.user_type === 3 && user_permission_arr.includes("pages_active")) && <button onClick={() => setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.status === 1 ? 'Active' : 'Deactive'}
                        </button>}
                        {userInfo.user_type === 1 && <button onClick={() => setStatusModal(!statusModal)} className={data.status === 1 ? 'bg-success-subtle text-success' : 'bg-danger-subtle text-danger'}>
                            {data.status === 1 ? 'Active' : 'Deactive'}
                        </button>}
                    </div> */}
                    {/* BLOCK MODAL */}
                    {/* <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                        <Modal.Body>
                            <div className="text-center">
                                <h5 className="mb-3">Are you sure, you want to {data.status !== 1 ? 'Active' : 'Deactive'} this page?</h5>
                                <div className="text-center">
                                    <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={handleStatus} className="mx-2">
                                        {data.status !== 1 ? 'Active' : 'Deactive'}
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal> */}
                </td>
            </tr>
        </>
    )
}

export default NotificationRow
