import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const chatApi = createApi({
    reducerPath: "chatApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["Chat"],
    endpoints: (builder) => ({
        getChatUserList: builder.query({
            query: (data) => {
                return {
                    url: "api/chatroom/list",
                    method: "GET",
                    params: {
                        search_keyword: data.searchVal,
                        // filter: data.sortVal,
                        // page: data.pageNo,
                        per_page: 100
                    }
                };
            },
            providesTags: ["Chat"],
        }),

        getMessageList: builder.query({
            query: (data) => {
                return {
                    url: `api/chat/list?page=${data.page === undefined ? 1 : data.page}&per_page=10`,
                    method: "GET",
                    params: {
                        room_id: data.id
                    }
                };
            },
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, queryArg) => {
                if (queryArg.arg !== undefined) {
                    currentCache.data.push(...newItems.data);
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            // providesTags: ["Chat"],
        }),

        deleteAllMessages: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/chat/coversation/delete`,
                    method: "DELETE",
                    params: {
                        room_id: ID
                    }
                };
            },
            invalidatesTags: ["Chat"],
        }),
        deleteSingleMessagesForMe: builder.mutation({
            query: (ID) => {
                console.log(ID)
                return {
                    url: `api/chat/own-message/delete`,
                    method: "DELETE",
                    body: {
                        message_ids: ID
                    }
                };
            },
            invalidatesTags: ["Chat"],
        }),
        deleteSingleMessagesForEveryone: builder.mutation({
            query: (ID) => {
                console.log(ID)
                return {
                    url: `api/chat/message/delete`,
                    method: "DELETE",
                    body: {
                        message_ids: ID
                    }
                };
            },
            invalidatesTags: ["Chat"],
        }),
        sendMessage: builder.mutation({
            query: (data) => {
                return {
                    url: `api/chat/create`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Chat"]
        }),
    }),
});

export const { useGetChatUserListQuery, useDeleteAllMessagesMutation, useDeleteSingleMessagesForMeMutation, useDeleteSingleMessagesForEveryoneMutation, useSendMessageMutation, useGetMessageListQuery, } = chatApi;