import React from 'react'
import { useGetQuotesListQuery } from '../../../store/api/Admin/adminQuotes'
import { AdminMenu } from '../../../components/components'
import './adminQuotes.css'
import AdminQuotes from '../../../components/adminComponent/adminQuotes/AdminQuotes'
const Quotes = () => {
  const getQuotesList = useGetQuotesListQuery()
  return (
    <div className='gap2 gray-bg full_height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <AdminMenu open/>
          </div>
          <div className='col-md-9'>
            <div className="d-md-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Quotes</h5>
            </div>
            {getQuotesList.isError && <div className='noData'><h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5></div>}
            {getQuotesList.isLoading && <div className='noData'><h5 className="text-center mb-0 py-5 fs_18">Loading...</h5></div>}
            {
              getQuotesList.isSuccess && (
                <div className='columns'>
                  {
                    getQuotesList.data?.data?.length > 0 ? (getQuotesList?.data?.data.map((elem, i)=>{
                      return(
                        <AdminQuotes data={elem} key={i}/>
                      )
                    })) : (
                      <div className='noData'><h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5></div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
  
}

export default Quotes