import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const FeaturedList = ({ data }) => {
    const [statusModal, setStatusModal] = useState(false)
    return (
        <div>
            <Link onClick={() => setStatusModal(!statusModal)} className='fw-bold'>Features ({data.length})</Link>
            <Modal centered size="lg" show={statusModal} onHide={() => setStatusModal(false)}>
                <Modal.Body>
                    <div>
                        <h5 className="mb-3">Features List</h5>
                        <table className='table table-borderless table-striped'>
                            <thead>
                                <tr>
                                    <th className='fw-bold text-dark'>Title</th>
                                    <th className='fw-bold text-dark'>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((elem, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><strong>{elem.title}</strong></td>
                                            <td>{elem.value}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="text-center">
                            <Button variant="danger" onClick={() => setStatusModal(false)} className="mx-2">
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FeaturedList