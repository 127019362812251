import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminUserApi = createApi({
    reducerPath: "adminUserApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["adminUserApi"],
    endpoints: (builder) => ({
        // get frontend user list in admin
        getAdminUser: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/user-list",
                    method: "GET",
                    params: {
                        page: data.page,
                        per_page: data.per_page,
                        search_keyword: data.searchKeyword,
                        sort_by: data.sort_by,
                        filter_by_gender: data.gender,
                        filter_by_membership: data.membership
                    },
                }
            },
            providesTags: ["adminUserApi"],
        }),
        // to deactivate a user
        deactiveUser: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/admin/user-status`,
                    method: "PUT",
                    body: {
                        "userId": ID
                    }
                };
            },
            invalidatesTags: ["adminUserApi"],
        }),
        // assign a role for a user
        roleUser: builder.mutation({
            query: (arg) => {
                return {
                    url: `api/admin/user/role/assign`,
                    method: "PUT",
                    body: arg
                };
            },
            invalidatesTags: ["adminUserApi"],
        }),
        // get system user
        getAdminSystemUser: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/user/systemuser/list",
                    method: "GET",
                    params: {
                        page: data.page,
                        per_page: data.per_page,
                        search_keyword: data.searchKeyword,
                    },
                }
            },
            providesTags: ["adminUserApi"],
        }),
        // create system user 
        createSystemUser: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/user/systemuser/create`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["adminUserApi"],
        }),
        // edit system user
        editSystemUser: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/user/systemuser/update`,
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: ["adminUserApi"],
        }),
        // get single system user
        singleSystemUser: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/user/systemuser/details",
                    method: "GET",
                    params: {
                        user_id: data,
                    },
                }
            },
            providesTags: ["adminUserApi"],
        }),
        // to get single user's full log
        getSingleUserLogs: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/activity-logs/list",
                    method: "GET",
                    params: {
                        user_id: data.userId,
                        page: data.page,
                    },
                }
            },
            providesTags: ["adminUserApi"],
        }),
    }),
});

export const {
    useGetAdminUserQuery,
    useDeactiveUserMutation,
    useRoleUserMutation,
    useGetAdminSystemUserQuery,
    useCreateSystemUserMutation,
    useEditSystemUserMutation,
    useSingleSystemUserQuery,
    useGetSingleUserLogsQuery
} = adminUserApi;
