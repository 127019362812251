import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminNotificationApi = createApi({
    reducerPath: "adminNotificationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminNotification"],
    endpoints: (builder) => ({
        getAllNotification: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/notification/template/list",
                    method: "GET",
                    params: {
                        meta_type: data,
                        limit: 50
                    }
                }
            },
            providesTags: ["AdminNotification"],
        }),
        createNotification: builder.mutation({
            query: (data) => {
                return {
                    url: "api/admin/notification/template/create",
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["AdminNotification"],
        }),
        getNotificationById: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/notification/template/details",
                    method: "GET",
                    params: {
                        template_id: data
                    }
                }
            },
            providesTags: ["AdminNotification"],
        }),
        editNotification: builder.mutation({
            query: (data) => {
                return {
                    url: "api/admin/notification/template/update",
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: ["AdminNotification"],
        }),
    }),
});

export const { useGetAllNotificationQuery, useCreateNotificationMutation, useGetNotificationByIdQuery, useEditNotificationMutation } = adminNotificationApi;