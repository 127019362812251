import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Quote.css';
import WidgetWrapper from '../WidgetWrapper';
import { Button, Modal } from 'react-bootstrap';
import { Input } from 'antd';
import { useCreateQuoteMutation, useGetQuoteListQuery } from '../../../store/api/quoteApi';
import toast from 'react-hot-toast';

const Quote = () => {
    const getQuote = useGetQuoteListQuery()
    const getQuoteList = getQuote?.data?.data;

    // FOR POST A QUOTE
    const [showQuoteModal, setShowQuoteModal] = useState(false);
    const [quoteText, setQuoteText] = useState("")
    const [quoteAuthor, setQuoteAuthor] = useState("")
    const [createQuote] = useCreateQuoteMutation()
    const createQuoteModal = () => {
        if (quoteText.length > 0 && quoteAuthor.length > 0) {
            createQuote({ authorName: quoteAuthor, quoteText: quoteText }).then((res) => {
                if (res?.data?.error === 0) {
                    toast.success(res?.data?.message)
                } else {
                    toast.error("Something went wrong!")
                }
            })
        } else {
            toast.error("Please fill all boxes!!!")
        }
        setQuoteText("")
        setQuoteAuthor("")
        setShowQuoteModal(false)
    }

    const { TextArea } = Input;
    const onChangeText = (e) => {
        setQuoteText(e.target.value)
    };
    const onChangeAuthor = (e) => {
        setQuoteAuthor(e.target.value)
    }

    return (
        <>
            <WidgetWrapper>
                <div className="p-xl-4 p-3 quote">
                    {getQuoteList && <>
                        <blockquote>
                            <span className="quote_sign">”</span>
                            {getQuoteList?.quote}
                        </blockquote>
                        <cite>{getQuoteList?.author}</cite>
                        <div className="posted_by">
                            Posted by - <span>{getQuoteList?.created_by?.fullName}</span>
                        </div>
                    </>}
                    {!getQuoteList && <>
                        <div className="col-12">
                            <h5 className="text-center mb-0 py-3 fs_18">No Quote Found !!</h5>
                        </div>
                    </>

                    }
                    <div className="mt-3 px-0">
                        <Link to="" className="btn btn-primary rounded-pill w-100" onClick={() => setShowQuoteModal(true)}>
                            Post Your Quote
                        </Link>
                        <i className="font_sm mt-2 d-block" style={{ color: "var(--color10)" }}>
                            Earn 10 points today!
                        </i>
                    </div>
                </div>
            </WidgetWrapper>
            {/* WRITE QUOTE MODAL */}
            <Modal centered size="md" show={showQuoteModal} onHide={() => setShowQuoteModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Please provide your quote!!!</h5>
                        <Input placeholder="Author of the quote" allowClear onChange={onChangeAuthor} />
                        <TextArea showCount maxLength={200} onChange={onChangeText} placeholder="Enter your quote here"
                            style={{
                                height: 100,
                                resize: 'none',
                                marginTop: '10px'
                            }}
                        />
                        <div className="text-center mt-4">
                            <Button variant="secondary" onClick={() => setShowQuoteModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={createQuoteModal} className="mx-2">
                                Post
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Quote;