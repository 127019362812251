import React, { useEffect } from "react";
import ChatLeft from "./leftBar/ChatLeft";
import ChatRight from "./rightBar/ChatRight";
import { useDispatch, useSelector } from "react-redux";
import { setOfflineForChat, setOnlineForChat } from "../../store/slice/chatSlice";
import { socket } from "../../methods/Socket";

const ChatPage = () => {

   const dispatch = useDispatch();
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   useEffect(() => {
      socket.emit("myRoom", userSliceData.user_room_id);
      socket.on("userOnline", (data) => {
         dispatch(setOnlineForChat(data))
         dispatch(setOfflineForChat(""))
      });
      socket.on("userOffline", (data) => {
         dispatch(setOfflineForChat(data))
         dispatch(setOnlineForChat(""))
      });
   }, [socket, userSliceData.user_room_id])

   return (
      <div className="container-fluid px-0">
         <div className="row gx-0">
            <div className="col-md-3">
               <ChatLeft />
            </div>
            <div className="col-md-9 vh-100">
               <ChatRight/>
            </div>
         </div>
      </div>
   );
};

export default ChatPage;
