import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminEmploymentApi = createApi({
    reducerPath: "adminEmployment",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminEmployment"],
    endpoints: (builder) => ({
        getEmploymentList: builder.query({
            query: () => {
                return {
                    url: "api/admin/employment",
                    method: "GET",
                }
            },
            providesTags: ["AdminEmployment"],
        }),
        deleteEmployment: builder.mutation({
            query: (id) => {
                return {
                    url: `api/admin/employment`,
                    method: "DELETE",
                    body: {
                        employmentId: id
                    }
                };
            },
            invalidatesTags: ["AdminEmployment"],
        }),
        updateEmploymentStatus: builder.mutation({
            query: (id) => {
                return {
                    url: `api/admin/employment/status`,
                    method: "PUT",
                    body: {
                        employment_id: id
                    }
                };
            },
            invalidatesTags: ["AdminEmployment"],
        }),
    }),
});

export const { useGetEmploymentListQuery, useDeleteEmploymentMutation, useUpdateEmploymentStatusMutation } = adminEmploymentApi;