import React, { useEffect, useState } from 'react';
import UserList from './userLists/UserList';
import './chatLeft.css';
import { useGetUserConnectionQuery } from '../../../store/api/userApi';
import { Modal } from 'react-bootstrap';
import { IoIosSearch } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { setChatRoomForChat, setUserForChat } from '../../../store/slice/chatSlice';
import { socket } from '../../../methods/Socket';

const ChatLeft = () => {
    const dispatch = useDispatch();

    // Calculate user list height once on initial render
    const userListHeight = window.innerHeight - document.querySelectorAll('.chatLeftTop')[0]?.offsetHeight;

    const [searchConnection, setSearchConnection] = useState('');
    const [searchChatList, setSearchChatList] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [connectionListModal, setConnectionListModal] = useState(false);
    const [chatList, setChatList] = useState([]);

    const user = useSelector((store) => store.userSlice);
    const { userSliceData } = useSelector((store) => store.userSlice);

    // Parameters for queries
    const paramObjConnection = {
        pageNo: pageNo,
        searchVal: searchConnection,
    };

    // Fetch connection list and chat user list using hooks
    const connectionList = useGetUserConnectionQuery(paramObjConnection);

    // Function to fetch user data
    const leftBarUserData = async () => {
        try {
            const params = { per_page: 100, search_keyword: searchChatList };
            const headers = { 'x-access-token': user.userAuthToken };
            const response = await axios.get('https://ubuntu-social-media-api.notebrains.com/api/chatroom/list', {
                params,
                headers,
            });
            setChatList(response?.data?.data);
            localStorage.setItem("usm_leftChat_list", JSON.stringify(response?.data?.data))
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // WebSocket event listeners
    socket.on('pushMessageDataForme', (data) => {
        let chat_left_list = JSON.parse(localStorage.getItem("usm_leftChat_list"))
        let newDataAfterFilter = chat_left_list.filter((el) => el.chatRoom._id !== data.chatRoom._id);
        localStorage.setItem("usm_leftChat_list", JSON.stringify([data, ...newDataAfterFilter]))
        setChatList([data, ...newDataAfterFilter]);
    });

    socket.on('pushMessageDataToFriend', (data) => {
        let chat_left_list = JSON.parse(localStorage.getItem("usm_leftChat_list"))
        let newDataAfterFilter = chat_left_list.filter((el) => el.chatRoom._id !== data.chatRoom._id);
        localStorage.setItem("usm_leftChat_list", JSON.stringify([data, ...newDataAfterFilter]))
        setChatList([data, ...newDataAfterFilter]);
    });

    const userSet = (data) => {
        dispatch(setUserForChat(data));
    };

    const alreadyChattedUserSet = (data) => {
        userSet(data.opponents[0]);
        dispatch(setChatRoomForChat(data?.chatRoom?._id));
        socket.emit('switchRoom', data?.chatRoom?._id);
        socket.emit('myRoom', userSliceData.user_room_id);

        // socket.emit("messageRead", {
        //     room_id: data.chatRoom._id,
        //     user_id: data.opponents[0]._id,
        // });

        let chat_left_list = JSON.parse(localStorage.getItem("usm_leftChat_list"));
        let updatedChatRooms = chat_left_list.map(el =>
            el.chatRoom._id === data.chatRoom._id
                ? { ...el, chatRoom: { ...el.chatRoom, totalUnreadMessages: 0 } }
                : el
        );

        localStorage.setItem("usm_leftChat_list", JSON.stringify(updatedChatRooms))
        setChatList(updatedChatRooms);
    };

    const [onlineUsers, setOnlineUsers] = useState([])
    // Only run once on mount
    useEffect(() => {
        leftBarUserData();
        socket.on("allUserOnline", (data) => {
            setOnlineUsers(data);
        });
    }, []); // Empty dependency array ensures this runs only on mount

    return (
        <>
            <aside className='bg-light shadow-sm chatLeftBar'>
                <div className='chatLeftTop'>
                    <div className='container p-4'>
                        <div className='mb-3 d-flex justify-content-between'>
                            <Link to='/'>
                                <img width={'120px'} src={logo} alt='' />
                            </Link>
                            <button className='add-connection-btn' onClick={() => setConnectionListModal(true)}>
                                +
                            </button>
                        </div>
                        <form className='searchUserForm'>
                            <IoIosSearch />
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Search users...'
                                aria-label='Search users...'
                                onChange={(e) => setSearchChatList(e.target.value)}
                                value={searchChatList}
                            />
                        </form>
                    </div>
                </div>
                <div className='chatLeftUserListWrapper' style={{ height: `${userListHeight}px` }}>
                    <div className='container p-4 pt-0'>
                        <div className='userList'>
                            {chatList?.map((el, i) => {
                                return (
                                    <div key={i} className='chatUserItem' onClick={() => alreadyChattedUserSet(el)}>
                                        <div className='chatUserItem_img'>
                                            <img src={el?.opponents[0]?.profile_picture[0]?.image_url} alt='...' />
                                            {/* {(el?.opponents[0]?._id === online || el?.opponents[0]?.is_online === '1') && (
                                                <span className={`chatUserItem_status active`}></span>
                                            )} */}
                                            {onlineUsers.includes(el?.opponents[0]?._id) ? (
                                                <span className={`chatUserItem_status active`}></span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className='chatUserItem_info'>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className='chatUserItem_name'>{el?.opponents[0]?.fullName}</div>
                                                {/* unread message */}
                                                {el?.chatRoom?.totalUnreadMessages > 0 && (
                                                    <div className='chatUserItem_name'>
                                                        {el?.chatRoom?.totalUnreadMessages}
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className='chatUserItem_lastMsg'
                                                dangerouslySetInnerHTML={{ __html: el?.chatRoom?.lastMessage }}
                                            ></div>
                                        </div>
                                    </div>
                                );
                            })}
                            {chatList?.length === 0 && <div className='text-center fs_16 mt-4'>No connection found!</div>}
                        </div>
                    </div>
                </div>
            </aside>

            <Modal centered size='l' show={connectionListModal} onHide={() => setConnectionListModal(false)}>
                <Modal.Body>
                    <div>
                        <div className='text-right'>
                            <RxCross2 style={{ cursor: 'pointer' }} onClick={() => setConnectionListModal(false)}></RxCross2>
                        </div>
                        <h4 className='fw-bold mb-2'>Connections</h4>
                        <form className='searchUserForm mb-2'>
                            <IoIosSearch />
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Search users...'
                                aria-label='Search users...'
                                onChange={(e) => setSearchConnection(e.target.value)}
                                value={searchConnection}
                            />
                        </form>
                        <UserList
                            data={connectionList?.data?.connections}
                            type={'connection'}
                            modalControl={setConnectionListModal}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChatLeft;
