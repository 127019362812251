import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const marketApi = createApi({
    reducerPath: "marketApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["market"],
    endpoints: (builder) => ({
        allProductList: builder.query({
            query: (data) => ({
                url: "api/marketplace/products/list",
                method: "GET",
                params: {
                    page: data.page,
                    per_page: 100,
                    search_keyword: data.searchVal,
                    category_id: data.category
                },
            }),
            providesTags: ["market"],
        }),

        createProduct: builder.mutation({
            query: (data) => {
                return {
                    url: "api/marketplace/products/create",
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["market"],
        }),

        updateProduct: builder.mutation({
            query: (data) => ({
                url: "api/marketplace/products/update",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["market"],
        }),

        deleteProduct: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/marketplace/products/delete`,
                    method: "DELETE",
                    body: {
                        product_id: ID
                    }
                };
            },
            invalidatesTags: ["market"],
        }),

        getSingleProduct: builder.query({
            query: (id) => ({
                url: "api/marketplace/products/details",
                method: "GET",
                params: {
                    product_id: id
                },
            }),
            providesTags: ["market"],
        }),

        UserProductList: builder.query({
            query: (data) => ({
                url: "api/marketplace/products/list",
                method: "GET",
                params: {
                    page: data.page,
                    per_page: 100,
                    search_keyword: data.searchVal,
                    category_id: data.category,
                    product_type: "my_products"
                },
            }),
            providesTags: ["market"],
        }),

        saveProduct: builder.mutation({
            query: (ID) => {
                return {
                    url: "api/marketplace/products/save",
                    method: "POST",
                    body: {
                        product_id: ID
                    }
                };
            },
            invalidatesTags: ["market"],
        }),

        savedProductList: builder.query({
            query: () => ({
                url: "api/marketplace/products/save/list",
                method: "GET",
            }),
            providesTags: ["market"],
        }),

        deleteSavedProduct: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/marketplace/products/save/remove`,
                    method: "DELETE",
                    body: {
                        product_id: ID
                    }
                };
            },
            invalidatesTags: ["market"],
        }),
        // to buy a product
        buyProduct: builder.mutation({
            query: (data) => {
                return {
                    url: "api/marketplace/order/create",
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["market"],
        }),
        // my ordered product list
        orderedList: builder.query({
            query: (id) => ({
                url: "api/marketplace/order/list",
                method: "GET",
            }),
            providesTags: ["market"],
        }),
        // my ordered product list
        sellOrderList: builder.query({
            query: (id) => ({
                url: "api/marketplace/seller/order/list",
                method: "GET",
            }),
            providesTags: ["market"],
        }),
        updateOrderConfirmation: builder.mutation({
            query: (data) => {
                return {
                    url: "api/marketplace/order/update-order-confirmation",
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: ["market"],
        }),
    })
})

export const { useUserProductListQuery, useAllProductListQuery, useCreateProductMutation, useDeleteProductMutation, useGetSingleProductQuery, useUpdateProductMutation, useSaveProductMutation, useSavedProductListQuery, useDeleteSavedProductMutation, useBuyProductMutation, useOrderedListQuery, useSellOrderListQuery, useUpdateOrderConfirmationMutation } = marketApi;