import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import style from './employment.module.css'
const CreateJobButton = () => {
  return (
    <>
      <Link to={"/employment/create"}>
        <Button className={style.createJobBtn}>
          Create New Job
        </Button>
      </Link>
      <Link to={"/employment/my-jobs"}>
        <Button className={style.createJobBtn}>
          My Jobs
        </Button>
      </Link>
    </>
  )
}

export default CreateJobButton