import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminDashboardApi = createApi({
    reducerPath: "adminDashboard",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["adminDashboard"],
    endpoints: (builder) => ({
        getDashboardList: builder.query({
            query: () => {
                return {
                    url: "api/admin/dashboard/data",
                    method: "GET",
                }
            },
            providesTags: ["adminDashboard"],
        }),
    }),
});

export const { useGetDashboardListQuery } = adminDashboardApi;