import React, { useEffect, useState } from "react";
import GroupProfileLayout from "../EventProfileLayout";
import { DashboardMenuLink, FilterWrapper } from "../../../components/components";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetSingleEventQuery, useGuestQuery, usePendingGuestQuery, useSuggestedGuestListQuery } from "../../../store/api/eventApi";
import EventUserCard from "../../../components/userCard/EventUserCard";
import { useSelector } from "react-redux";

const Guests = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
   const [search, setSearch] = useState(searchParams.get("search") || "")
   const [sort, setSort] = useState(searchParams.get("sort") || "see_all")
   // get user details
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })
   // get id from param
   const params = useParams();
   const event_id = params.eventId;

   // get single Event Data
   const singleEvent = useGetSingleEventQuery(event_id);
   let eventWholeData = singleEvent?.data?.data;

   const paramActiveGuest = {
      event_id: event_id,
      pageNo: pageNo,
      searchVal: search,
      sortVal: sort
   }

   const guestAPI = useGuestQuery(paramActiveGuest)
   const eventGuest = guestAPI?.data?.data?.members;

   const pendingList = usePendingGuestQuery({ event_id: event_id, pageNo: pageNo, })

   const suggestedGuest = useSuggestedGuestListQuery({ event_id: event_id, pageNo: pageNo, })

   useEffect(() => {
      let params = { pageNo };
      if (search) {
         params.search = search;
      }
      if (sort) {
         params.sort = sort;
      }
      setSearchParams(params);
   }, [pageNo, search, sort]);
   
   return (
      <GroupProfileLayout data={eventWholeData}>
         <FilterWrapper>
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="DashboardMenuLinks">
                     <DashboardMenuLink
                        url={`/event/${event_id}/guests`}
                        title="Guests"
                        count={guestAPI?.data?.total_count || 0}
                     />
                     {
                        eventWholeData?.user?._id === userSliceData?._id && <DashboardMenuLink
                           url={`/event/${event_id}/guests/pending`}
                           title="Pending Guests"
                           count={pendingList?.data?.total_count || 0}
                        />
                     }
                     {
                        eventWholeData?.user?._id === userSliceData?._id && <DashboardMenuLink
                           url={`/event/${event_id}/guests/suggation`}
                           title="Suggested Guests"
                           count={suggestedGuest?.data?.total_count || 0}
                        />
                     }
                  </div>
               </div>
               <div className="col-lg-5">
                  <div className="row merged20">
                     <div className="col-lg-7 col-md-7 col-sm-7">
                        <form method="post">
                           <input
                              type="text"
                              placeholder="Search Pages"
                              className="form-control"
                              onChange={(e) => setSearch(e.target.value)}
                              value={searchParams.get("search") || search}
                           />
                        </form>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="select-options">
                           <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                              <option value={"see_all"}>See All</option>
                              <option value={"a_to_z"}>A to Z</option>
                              <option value={"z_to_a"}>Z to A</option>
                              <option value={"newest"}>Newest</option>
                              <option value={"oldest"}>Oldest</option>
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </FilterWrapper>
         <div className="central-meta">
            <div className="row gx-4">
               {
                  eventGuest?.length > 0 ? (
                     <>
                        {
                           eventGuest?.map((elem, index) => {
                              return (
                                 <div className="col-lg-2 col-md-3 col-6" key={index}>
                                    <EventUserCard eventID={event_id} data={elem} type={"EventGuest"} />
                                 </div>
                              );
                           })
                        }
                     </>
                  ) : (
                     <>
                        <div className="col-12">
                           <h5 className="text-center mb-0 py-5 fs_18">No Guest Found !!</h5>
                        </div>
                     </>
                  )
               }
            </div>
         </div>
      </GroupProfileLayout>
   );
};

export default Guests;
