import React, { useEffect, useState } from "react";
import ProfileLayout from "../ProfileLayout";
import { DashboardMenuLink, FilterWrapper, UserCard } from "../../../components/components";
import { useAllCountListsQuery, useAllOtherCountListsQuery, useGetUserConnectionQuery } from "../../../store/api/userApi";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetConnectionByIdQuery } from "../../../store/api/userApi";
import { Pagination } from "antd";

const Connections = () => {
   const { userId } = useParams();
   const [searchParams, setSearchParams] = useSearchParams();
   const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
   const [search, setSearch] = useState(searchParams.get("search") || "")
   const [sort, setSort] = useState(searchParams.get("sort") || "all")

   const paginationPageChange = (page) => {
      setPageNo(page);
   }

   const paramObj = {
      pageNo: pageNo,
      searchVal: search,
      sortVal: sort
   }
   //get connection data list
   const connectionData = useGetUserConnectionQuery(paramObj)
   const connectionUserData = connectionData?.data?.connections;
   const countData = useAllCountListsQuery()
   const otherCountData = useAllOtherCountListsQuery(userId)

   useEffect(() => {
      let params = { pageNo };
      if (search) {
         params.search = search;
      }
      if (sort) {
         params.sort = sort;
      }

      setSearchParams(params);
   }, [pageNo, search, sort]);

   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   // other user
   const otherUserParam = {
      id: userId,
      pageNo: pageNo,
      searchVal: search,
      sortVal: sort
   }
   const otherUserConnection = useGetConnectionByIdQuery(otherUserParam)

   return (
      <ProfileLayout handleDisplay="none">
         <FilterWrapper>
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="DashboardMenuLinks">
                     <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/connections`} title="Connections"
                        count={
                           userId === userSliceData._id ? (
                              countData?.isSuccess ? (
                                 countData?.data?.data?.total_friends
                              ) : 0
                           ) : otherCountData?.isSuccess ? (
                              otherCountData?.data?.data?.total_friends
                           ) : 0
                        } className={'test'} />
                     {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/connections/new`} title="New Request" count={countData.isSuccess ? countData.data.data.total_pending_requests : 0} />}
                     {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/connections/suggested`} title="Suggested Connections" count={countData.isSuccess ? countData.data.data.total_suggested_connection : 0} />}
                  </div>
               </div>
               <div className="col-lg-5">
                  <div className="gx-2 justify-content-md-end row">
                     <div className="col-lg-7 col-md-7 col-8">
                        <form method="post">
                           <input
                              type="text"
                              placeholder="Search.."
                              className="form-control"
                              onChange={(e) => setSearch(e.target.value)}
                              value={searchParams.get("search") || search}
                           />
                        </form>
                     </div>
                     <div className="col-lg-4 col-md-4 col-4">
                        <div className="select-options">
                           <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                              <option value={"all"}>See All</option>
                              <option value={"AtoZ"}>A to Z</option>
                              <option value={"ZtoA"}>Z to A</option>
                              <option value={"newest"}>Newest</option>
                              <option value={"oldest"}>Oldest</option>
                           </select>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </FilterWrapper>
         <div className="">
            <div className="row g-md-3 g-2">
               {
                  connectionData.isLoading && (
                     <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                     </div>
                  )
               }

               {
                  connectionData.isError && (
                     <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                     </div>
                  )
               }

               {userId === userSliceData._id ? connectionUserData?.map((elem, index) => {
                  return (
                     <div className="col-lg-2 col-md-4 col-6" key={index}>
                        <UserCard data={elem} />
                     </div>

                  );
               }) : otherUserConnection?.data?.connections?.map((elem, index) => {
                  return (
                     <div className="col-lg-2 col-md-4 col-6" key={index}>
                        <UserCard data={elem} type={"others"} />
                     </div>
                  );
               })}
               {userId === userSliceData._id ? connectionUserData?.length === 0 && <div className="col-12">
                  <h5 className="text-center mb-0 py-5 fs_18">No Connection Found !!!</h5>
               </div> : otherUserConnection?.data?.connections?.length === 0 && <div className="col-12">
                  <h5 className="text-center mb-0 py-5 fs_18">No Connection Found !!!</h5>
               </div>}
            </div>
            {/* pagination component */}
            {userId === userSliceData._id ? (
               connectionData?.data?.totalfriends > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={connectionData?.data?.totalfriends} onChange={paginationPageChange} defaultPageSize={12} />
            ) : (
               otherUserConnection?.data?.totalfriends > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={otherUserConnection?.data?.totalfriends} onChange={paginationPageChange} defaultPageSize={12} />
            )}
         </div>
      </ProfileLayout>
   );
};

export default Connections;


