import React from "react";
import { Link } from "react-router-dom";
import boxBg1 from "../../../assets/images/resources/help-contact.png";
import boxBg2 from "../../../assets/images/resources/help-chat.png";
import "./twoBox.css";

const TwoBox = () => {
   return (
      <div className="no-top gray-bg">
         <div className="container">
            <div className="row">
               <div className="col-lg-6 col-md-6">
                  <div className="help-box helpHelpBox">
                     <img src={boxBg1} alt="" />
                     <span>Need More Help</span>
                     <h6>Contact Us</h6>
                     <Link to="#" title="" className="main-btn2 help">
                        Get Help
                     </Link>
                  </div>
               </div>
               <div className="col-lg-6 col-md-6">
                  <div className="help-box helpHelpBox">
                     <img src={boxBg2} alt="" />
                     <span>Get Instant Answer</span>
                     <h6>Ask Data</h6>
                     <Link to="#" title="" className="main-btn chat">
                        Chat Now
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default TwoBox;
