import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./PageCard.css";
import { Button as AntButton, Dropdown } from "antd";
import { BsThreeDots } from "react-icons/bs";
import { useAcceptPendingRequestMutation, useDeclinePendingRequestMutation, useDeletePageMutation, useJoinRequestMutation, useRemovePageMemberMutation } from "../../store/api/pageApi";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { defaultUserCover, defaultUserDp } from "../components";
import { useSelector } from "react-redux";
import { useJoinRequestPageOthersMutation, useRemovePageMemberOthersMutation } from "../../store/api/pageApi";
import { socket } from "../../methods/Socket";

const PageCard = ({ type, data, pageId }) => {

   // to get all my data from redux store
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   // To delete my page
   const [deletePage] = useDeletePageMutation()
   const [showDeleteModal, setDeleteModal] = useState(false);
   const deletePageModal = () => {
      const pageId = data._id
      deletePage(pageId).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setDeleteModal(false)
   }

   // join page req 
   const [joinRequest] = useJoinRequestMutation()
   const joinPageReqFunc = () => {
      let obj = {
         page_id: data._id,
         members: {
            user_id: userSliceData?._id,
         }
      }
      joinRequest(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationPage", {
               sender_id: userSliceData._id,
               receiver_id: data.user._id,
               meta_data: data._id,
               meta_type: "Page",
               notification_for: "page_join",
            });
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // join page req for other profile
   const [joinRequestPageOthers] = useJoinRequestPageOthersMutation()
   const joinPageReqOthersFunc = () => {
      let obj = {
         page_id: data._id,
         members: {
            user_id: userSliceData?._id,
         }
      }
      joinRequestPageOthers(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // leave from a page
   const [leavePageModal, setLeavePageModal] = useState(false)
   const [leaveFromPage] = useRemovePageMemberMutation()
   const leaveFromGroupModal = () => {
      let obj = {
         page_id: pageId,
         members: {
            user_id: userSliceData._id,
            is_remove: true
         }
      }
      leaveFromPage(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
      setLeavePageModal(false)
   }

   // leave from page othersProfile
   const [leaveFromPageOthers] = useRemovePageMemberOthersMutation()
   const leaveFromPageOthersModal = () => {
      let obj = {
         page_id: pageId,
         members: {
            user_id: userSliceData._id,
            is_remove: true
         }
      }
      leaveFromPageOthers(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
      setLeavePageModal(false)
   }

   console.log(data)
   // accept the pending invitation
   const [acceptPendingRequest] = useAcceptPendingRequestMutation()
   const acceptPendingRequestFunc = () => {
      acceptPendingRequest({ pageId: data._id, userId: userSliceData._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationPage", {
               sender_id: userSliceData._id,
               receiver_id: data.user._id,
               meta_data: data._id,
               meta_type: "Page",
               notification_for: "page_accept_request",
            });
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // decline the pending invitation
   const [declineModal, setDeclineModal] = useState(false)
   const [declinePendingRequest] = useDeclinePendingRequestMutation()
   const declinePendingRequestFunc = () => {
      declinePendingRequest({ pageId: data._id, userId: userSliceData._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   const items = type === "myPage" ? [
      {
         label: <div onClick={() => { }}>Share Page</div>,
         key: "0",
      },
      {
         label: <div onClick={() => setDeleteModal(true)}>Delete Page</div>,
         key: "2",
      }
   ]
      :
      [
         {
            label: <div onClick={() => { }}>Share Page</div>,
            key: "0",
         },
         {
            label: <div onClick={() => { }}>Report</div>,
            key: "2",
         }
      ];

   if (type === 'myPage') {
      return (
         <>
            <div className={"pageCard"}>
               <div
                  className="pageCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
               ></div>
               <div className="page_content">
                  <figure>
                     <img
                        src={data?.display_image[0]?.image_url || defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/page/${pageId}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Likes" : "Like"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist">
                        <button onClick={() => setDeleteModal(true)} className='deleteBtn'>
                           <i className="fa fa-trash"></i>
                        </button>
                     </li>
                     <li className="following">
                        <Link to={`/page/${data?._id}/edit`} className="btn btn-light w-100">Edit Page</Link>
                     </li>
                  </ul>
               </div>
            </div>

            {/* CONFIRM DELETE MODAL FOR MY PAGE */}
            <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to delete the page?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={deletePageModal} className="mx-2">
                           Delete
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === 'pending') {
      return (
         <>
            <div className={"pageCard"}>
               <div className="pageCardcoverPhoto" style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}></div>
               <div className="page_content">
                  <figure>
                     <img
                        src={data?.display_image[0]?.image_url || defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/page/${data?._id}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Likes" : "Like"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist" >
                        <button onClick={() => setDeclineModal(true)} className='deleteBtn'>
                           <i className="fa fa-trash" ></i>
                        </button>
                     </li>
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={acceptPendingRequestFunc}>Accept</button>
                     </li>
                  </ul>
               </div>
               <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  trigger={["click"]}
               >
                  <AntButton
                     type="link"
                     size="small"
                     shape="circle"
                     icon={<BsThreeDots />}
                  />
               </Dropdown>
            </div>
            {/* CONFIRM DECLINE MODAL FOR INVITED PAGE */}
            <Modal centered size="sm" show={declineModal} onHide={() => setDeclineModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to decline the page request?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeclineModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={declinePendingRequestFunc} className="mx-2">
                           Delete
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === 'liked') {
      return (
         <>
            <div className={"pageCard"}>
               <div
                  className="pageCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
               ></div>
               <div className="page_content">
                  <figure>
                     <img
                        src={data?.display_image[0]?.image_url || defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/page/${pageId}/timeline`} title="">
                     {data.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Likes" : "Like"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={() => setLeavePageModal(true)}>Unlike</button>
                     </li>
                  </ul>
               </div>
            </div>
            {/* CONFIRM REMOVE USER MODAL */}
            <Modal centered size="sm" show={leavePageModal} onHide={() => setLeavePageModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to leave this page?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setLeavePageModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={leaveFromGroupModal} className="mx-2">
                           Leave
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "suggested") {
      return (
         <div className={"pageCard"}>
            <div
               className="pageCardcoverPhoto"
               style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
            ></div>
            <div className="page_content">
               <figure>
                  <img
                     src={data?.display_image[0]?.image_url || defaultUserDp}
                     alt=""
                  />
               </figure>
               <Link to={`/page/${data._id}/timeline`} title="">
                  {data.title}
               </Link>
               <span>{data?.members_count} {data?.members_count > 1 ? "Likes" : "Like"}</span>
               <ul className="add-remove-frnd following">
                  <li className="following">
                     <button className="btn btn-light w-100" onClick={joinPageReqFunc}>Like Page</button>
                  </li>
               </ul>
            </div>
            <Dropdown
               menu={{ items }}
               placement="bottomRight"
               trigger={["click"]}
            >
               <AntButton
                  type="link"
                  size="small"
                  shape="circle"
                  icon={<BsThreeDots />}
               />
            </Dropdown>
         </div>
      )
   }

   else if (type === 'others') {
      return (
         <>
            <div className={"pageCard"}>
               <div
                  className="pageCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
               ></div>
               <div className="page_content">
                  <figure>
                     <img
                        src={data?.display_image[0]?.image_url || defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/page/${pageId}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Likes" : "Like"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        {console.log(data)}
                        {data.is_join === "active" ? <Link to={``} className="btn btn-light w-100" onClick={leaveFromPageOthersModal}>Unlike</Link> : <Link to={``} className="btn btn-light w-100" onClick={joinPageReqOthersFunc}>Like Page</Link>}
                     </li>
                  </ul>
               </div>
            </div>

            {/* CONFIRM DELETE MODAL FOR MY PAGE */}
            <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to delete the page?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={deletePageModal} className="mx-2">
                           Delete
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }
   else if (type === 'search') {
      return (
         <>
            <div className={"pageCard"}>
               <div
                  className="pageCardcoverPhoto"
                  style={{ backgroundImage: `url(${data.hasOwnProperty("cover_image") ? data?.cover_image[0]?.image_url : defaultUserCover})` }}
               ></div>
               <div className="page_content">
                  <figure>
                     <img
                        src={data.hasOwnProperty("display_image") ? data?.display_image[0]?.image_url : defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/page/${data._id}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members?.length} {data?.members?.length > 1 ? "Likes" : "Like"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        <Link to={`/page/${data._id}/timeline`} className="btn btn-light w-100" onClick={leaveFromPageOthersModal}>View Page</Link>
                     </li>
                  </ul>
               </div>
            </div>
         </>
      );
   }
};

export default PageCard;
