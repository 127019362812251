import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./GroupCard.css";
import { useAcceptPendingRequestMutation, useDeclinePendingRequestMutation, useDeleteGroupMutation, useJoinRequestMutation, useRemoveGroupMemberMutation } from "../../store/api/groupApi";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { defaultUserCover, defaultUserDp } from "../components";
import { useSelector } from "react-redux";
import { useJoinRequestOthersMutation, useRemoveGroupMemberOthersMutation } from "../../store/api/groupApi";
import { socket } from "../../methods/Socket";

const GroupCard = ({ type, data, groupId }) => {
   // get own data from local storage
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });
   // TO DELETE MY GROUP
   const [deleteGroup] = useDeleteGroupMutation()
   const [showDeleteModal, setDeleteModal] = useState(false);
   const deleteGroupFunc = () => {
      setDeleteModal(true)
   }
   const deleteGroupModal = () => {
      const groupId = data._id
      deleteGroup(groupId).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setDeleteModal(false)
   }

   // join group from suggested
   const [joinRequest] = useJoinRequestMutation()
   const joinGroupReqFunc = () => {
      let obj = {
         group_id: data._id,
         members: {
            user_id: userSliceData?._id,
         }
      }
      joinRequest(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationGroup", {
               sender_id: userSliceData._id,
               receiver_id: data.user._id,
               meta_data: data._id,
               meta_type: "Group",
               notification_for: "group_join",
            });

         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // join group for others
   const [joinRequestOthers] = useJoinRequestOthersMutation()
   const joinGroupReqOthersFunc = () => {
      let obj = {
         group_id: data._id,
         members: {
            user_id: userSliceData?._id,
         }
      }
      joinRequestOthers(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // leave from a group
   const [leaveGroupModal, setLeaveGroupModal] = useState(false)
   const [leaveFromGroup] = useRemoveGroupMemberMutation()
   const leaveFromGroupFunc = () => {
      setLeaveGroupModal(true)
   }
   const leaveFromGroupModal = () => {
      let obj = {
         group_id: groupId,
         members: {
            user_id: userSliceData._id,
            is_remove: true
         }
      }
      leaveFromGroup(obj).then((res) => { })
      setLeaveGroupModal(false)
   }

   // leave from group other user
   const [leaveFromGroupOthers] = useRemoveGroupMemberOthersMutation()
   const leaveFromGroupOthersModal = () => {
      let obj = {
         group_id: groupId,
         members: {
            user_id: userSliceData._id,
            is_remove: true
         }
      }
      leaveFromGroupOthers(obj).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
      setLeaveGroupModal(false)
   }

   // accept the pending invitation
   const [acceptPendingRequest] = useAcceptPendingRequestMutation()
   const acceptPendingRequestFunc = () => {
      acceptPendingRequest({ groupId: data._id, userId: userSliceData._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
            socket.emit("sendNotificationGroup", {
               sender_id: userSliceData._id,
               receiver_id: data.user._id,
               meta_data: data._id,
               meta_type: "Group",
               notification_for: "group_accept_request",
            });
         } else {
            toast.error("Something went wrong")
         }
      })
   }

   // decline the pending invitation
   const [declineModal, setDeclineModal] = useState(false)
   const [declinePendingRequest] = useDeclinePendingRequestMutation()
   const declinePendingRequestFunc = () => {
      declinePendingRequest({ groupId: data._id, userId: userSliceData._id }).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Request sent failed")
         }
      })
   }

   // const suggestedOptions = [
   //    {
   //       label: <div onClick={() => { }}>Share Group</div>,
   //       key: "0",
   //    },
   //    {
   //       label: <div onClick={() => { }}>Remove Group</div>,
   //       key: "1",
   //    },
   //    {
   //       label: <div onClick={() => { }}>Report</div>,
   //       key: "2",
   //    }
   // ]

   // const pendingGroups = [
   //    {
   //       label: <div onClick={() => { }}>Share Group</div>,
   //       key: "0",
   //    },
   //    {
   //       label: <div onClick={() => { }}>Report</div>,
   //       key: "2",
   //    }
   // ]

   if (type === "suggested") {
      return (
         <div className={"groupCard"}>
            <div
               className="groupCardcoverPhoto"
               style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
            ></div>
            <div className="group_content">
               <figure>
                  <img
                     src={data?.display_image[0]?.image_url || defaultUserDp}
                     alt=""
                  />
               </figure>
               <Link to={`/group/${data?._id}/timeline`} title="">
                  {data.title}
               </Link>
               <span>{data?.members_count} {data?.members_count > 1 ? "Members" : "Member"}</span>
               <ul className="add-remove-frnd following">
                  <li className="following">
                     <button className="btn btn-light w-100" onClick={joinGroupReqFunc}>
                        Join Group
                     </button>
                  </li>
               </ul>
            </div>
         </div>
      );
   }

   else if (type === "myGroup") {
      return (
         <>
            <div className={"groupCard"}>
               <div
                  className="groupCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
               ></div>
               <div className="group_content">
                  <figure>
                     <img
                        src={data?.display_image[0]?.image_url || defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/group/${groupId}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Members" : "Member"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist">
                        <button onClick={deleteGroupFunc} className='deleteBtn'>
                           <i className="fa fa-trash"></i>
                        </button>
                     </li>
                     <li className="following">
                        <Link to={`/group/${data?._id}/edit`} className="btn btn-light w-100">Edit Group</Link>
                     </li>
                  </ul>
               </div>
            </div>
            {/* CONFIRM DELETE MODAL FOR MY PAGE */}
            <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to delete the page?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteGroupModal} className="mx-2">
                           Delete
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "joinedGroup") {
      return (
         <>
            <div className={"groupCard"}>
               <div
                  className="groupCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
               ></div>
               <div className="group_content">
                  <figure>
                     <img
                        src={data?.display_image[0]?.image_url || defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/group/${groupId}/timeline`} title="">
                     {data.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Members" : "Member"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={leaveFromGroupFunc}>Leave Group</button>
                     </li>
                  </ul>
               </div>
            </div>
            {/* CONFIRM REMOVE USER MODAL */}
            <Modal centered size="sm" show={leaveGroupModal} onHide={() => setLeaveGroupModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to Remove this member?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setLeaveGroupModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={leaveFromGroupModal} className="mx-2">
                           Remove
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "pending") {
      return (
         <>
            <div className={"groupCard"}>
               <div
                  className="groupCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
               ></div>
               <div className="group_content">
                  <figure>
                     <img src={data?.display_image[0]?.image_url || defaultUserDp} alt="" />
                  </figure>
                  <Link to={`/group/${data._id}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Members" : "Member"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="add-tofrndlist">
                        {/* <Link href="#" title="Delete"> */}
                        <button onClick={() => setDeclineModal(true)} className='deleteBtn'>
                           <i className="fa fa-trash" ></i>
                        </button>
                     </li>
                     <li className="following">
                        <button className="btn btn-light w-100" onClick={acceptPendingRequestFunc}>Accept</button>
                     </li>
                  </ul>
               </div>

               {/* <Dropdown
               menu={{ items: pendingGroups }}
               placement="bottomRight"
               trigger={["click"]}
            >
               <AntButton
                  type="link"
                  size="small"
                  shape="circle"
                  icon={<BsThreeDots />}
               />
            </Dropdown> */}
            </div>

            {/* CONFIRM DECLINE MODAL FOR INVITED GROUP */}
            <Modal centered size="sm" show={declineModal} onHide={() => setDeclineModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to decline the group request?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeclineModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={declinePendingRequestFunc} className="mx-2">
                           Delete
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "others") {
      return (
         <>
            <div className={"groupCard"}>
               <div
                  className="groupCardcoverPhoto"
                  style={{ backgroundImage: `url(${data?.cover_image[0]?.image_url || defaultUserCover})` }}
               ></div>
               <div className="group_content">
                  <figure>
                     <img
                        src={data?.display_image[0]?.image_url || defaultUserDp}
                        alt=""
                     />
                  </figure>
                  <Link to={`/group/${groupId}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members_count} {data?.members_count > 1 ? "Members" : "Member"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        {data.is_join ? <Link to={``} className="btn btn-light w-100" onClick={leaveFromGroupOthersModal}>Leave Group</Link> : <Link to={``} className="btn btn-light w-100" onClick={joinGroupReqOthersFunc}>Join Group</Link>}
                     </li>
                  </ul>
               </div>
            </div>
            {/* CONFIRM DELETE MODAL FOR MY PAGE */}
            <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
               <Modal.Body>
                  <div className="text-center">
                     <h5 className="mb-3">Are you sure, you want to delete the page?</h5>
                     <div className="text-center">
                        <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                           Cancel
                        </Button>
                        <Button variant="danger" onClick={deleteGroupModal} className="mx-2">
                           Delete
                        </Button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </>
      );
   }

   else if (type === "search") {
      return (
         <>
            <div className={"groupCard"}>
               <div
                  className="groupCardcoverPhoto"
                  style={{ backgroundImage: `url(${data.hasOwnProperty("cover_image") ? data?.cover_image[0]?.image_url : defaultUserCover})` }}
               ></div>
               <div className="group_content">
                  <figure>
                     <img
                        src={(data.hasOwnProperty("display_image") && data?.display_image[0]?.image_url) ? data?.display_image[0]?.image_url : defaultUserDp}
                        alt="dp"
                     />
                  </figure>
                  <Link to={`/group/${data._id}/timeline`} title="">
                     {data?.title}
                  </Link>
                  <span>{data?.members?.length} {data?.members?.length > 1 ? "Members" : "Member"}</span>
                  <ul className="add-remove-frnd following">
                     <li className="following">
                        <Link to={`/group/${data._id}/timeline`} className="btn btn-light w-100">View Group</Link>
                     </li>
                  </ul>
               </div>
            </div>
         </>
      );
   }
};

export default GroupCard;
