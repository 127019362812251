import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const jobApi = createApi({
    reducerPath: "jobApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["job"],
    endpoints: (builder) => ({
        // create a new job
        createJob: builder.mutation({
            query: (pageData) => {
                return {
                    url: "api/employment",
                    method: "POST",
                    body: pageData,
                };
            },
            invalidatesTags: ["job"],
        }),
        // see all job list
        allJobList: builder.query({
            query: (data) => ({
                url: "api/employment",
                method: "GET",
                params: {
                    page: data.page,
                    per_page: 100,
                    search_keyword: data.searchVal,


                },
            }),
            providesTags: ["job"],
        }),
        // delete job
        deleteJob: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/employment`,
                    method: "DELETE",
                    body: {
                        employmentId: ID
                    }
                };
            },
            invalidatesTags: ["job"],
        }),
        // get a single job data
        getSingleJob: builder.query({
            query: (id) => ({
                url: "api/employment/details",
                method: "GET",
                params: {
                    employmentId: id
                },
            }),
            providesTags: ["job"],
        }),
        // update a job
        updateJob: builder.mutation({
            query: (data) => ({
                url: "api/employment",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["job"],
        }),

        // save a job
        saveJob: builder.mutation({
            query: (ID) => {
                return {
                    url: "api/savejob",
                    method: "POST",
                    body: {
                        employment_id: ID
                    },
                };
            },
            invalidatesTags: ["job"],
        }),
        // saved job list
        savedJobLists: builder.query({
            query: () => ({
                url: "api/savejob",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 100
                },
            }),
            providesTags: ["job"],
        }),
        // delete saved job
        deleteSavedJob: builder.mutation({
            query: (ID) => {
                return {
                    url: `api/savejob`,
                    method: "DELETE",
                    body: {
                        employment_id: ID
                    }
                };
            },
            invalidatesTags: ["job"],
        }),
        // apply for the job
        applyJob: builder.mutation({
            query: (data) => {
                return {
                    url: "api/jobapplication",
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["job"],
        }),
        // applied job list
        appliedJobLists: builder.query({
            query: () => ({
                url: "api/jobapplication",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 100
                },
            }),
            providesTags: ["job"],
        }),
        // applied users list
        appliedUserList: builder.query({
            query: (ID) => ({
                url: "api/applied-users",
                method: "GET",
                params: {
                    employment_id:ID
                },
            }),
            providesTags: ["job"],
        }),
        //my jobs
        userJobList: builder.query({
            query: (data) => ({
                url: "api/employment/posted-employment",
                method: "GET",
                params: {
                    search_keyword: data.searchVal,
                    page: data.page,
                    
                },
            }),
            providesTags: ["job"],
        }),
    })
})

export const {useUserJobListQuery, useCreateJobMutation, useAllJobListQuery, useDeleteJobMutation, useGetSingleJobQuery, useUpdateJobMutation, useSaveJobMutation, useSavedJobListsQuery, useDeleteSavedJobMutation,useApplyJobMutation,useAppliedJobListsQuery,useAppliedUserListQuery } = jobApi;