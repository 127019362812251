import React from 'react'
import './SideBar.css'
const SideBar = ({children, className}) => {
  return (
    <div className={`sidebar static ${className}`}>
        {children}
    </div>
  )
}

export default SideBar