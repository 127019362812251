import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const reportApi = createApi({
    reducerPath: "reportApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["Report"],
    endpoints: (builder) => ({
        // GET SAVE POST LIST
        getReportReasonList: builder.query({
            query: () => {
                return {
                    url: `api/reportReason/list`,
                    method: "GET",
                }
            },
            providesTags: ["Report"],
        })
    })
})

export const { useGetReportReasonListQuery } = reportApi;