import { createSlice } from "@reduxjs/toolkit";
const api = {
    key: "9160b9da814a4115412d9082c569ed58",
    base: "https://api.openweathermap.org/data/2.5/"
}

const initialState = {
    temp : '00',
    weatherName: '',
    city : '',
    country: '',
    icon: ''
};

const WeatherSlice = createSlice({
    name: "weather",
    initialState,
    reducers: {
        setWeather: (state, action) => {
            state.temp = action.payload.temp
            state.weatherName = action.payload.weatherName
            state.city = action.payload.city
            state.country = action.payload.country
            state.icon = action.payload.icon
        },
    },
});

// set Weather action
export const { setWeather } = WeatherSlice.actions;

// Select Weather state

export const selectTemp = (state) => state.weather.temp;
export const selectWeatherName = (state) => state.weather.weatherName;
export const selectCity = (state) => state.weather.city;
export const selectCountry = (state) => state.weather.country;
export const selectIcon = (state) => state.weather.icon;

export default WeatherSlice.reducer;
