import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const birthdayApi = createApi({
    reducerPath: "birthdayApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        }
    }),
    tagTypes: ["birthday"],
    endpoints: (builder) => ({
        birthdayWish: builder.mutation({
            query: (data) => {
                return {
                    url: "api/posts/create",
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["birthday"],
        }),
        birthdayList: builder.query({
            query: () => ({
                url: "api/connections/firiends-birthday",
                method: "GET",
            }),
            providesTags: ["birthday"],
        }),
    }),
});

export const { useBirthdayListQuery,useBirthdayWishMutation } = birthdayApi;
