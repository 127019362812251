import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineDiamond } from "react-icons/md";
import "./planWidge.css"

const PlanWidget = () => {
   return (
      <Link to="/plans" className="planBtn">
         <span>
            <MdOutlineDiamond className='diamond'/>
         </span>
         FREE TIER
      </Link>
   );
};

export default PlanWidget;
