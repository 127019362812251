import React from "react";
import GroupProfileLayout from "../PageProfileLayout";
import { DashboardAbout } from "../../../components/components";
import { useParams } from "react-router-dom";
import { useGetSinglePageQuery } from "../../../store/api/pageApi";

const About = () => {
   // get id from param
   const params = useParams();
   const id = params.pageId;

   // get single Page Data
   const singlePage = useGetSinglePageQuery(id);
   let pageWholeData = singlePage?.data?.data;
   return (
      <GroupProfileLayout data={pageWholeData}>
         <DashboardAbout data={pageWholeData} type={"Page"} />
      </GroupProfileLayout>
   );
};

export default About;
