import React from 'react'
import { AdminMenu } from '../../../components/components';
import { Link } from 'react-router-dom';
import "./dashboard.css";
import { useGetDashboardListQuery } from '../../../store/api/Admin/adminDashboardApi';

const Dashboard = () => {
    const dashboardData = useGetDashboardListQuery();
    console.log(dashboardData?.data?.data);
    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3 h-100'>
                            <div className='d-md-flex justify-content-between align-items-center mb-3'>
                                <h5 className='mb-0'>Dashboard</h5>
                            </div>
                            <div className='row g-3'>
                                {/* user Record */}
                                <div className='col-lg-4 col-12'>
                                    <div className="scheduleBox">
                                        <div className="scheduleBoxTop">
                                            <div>User Records</div>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={'/admin/users'} className='scheduleBoxTitle'>
                                                Total Users
                                            </Link>
                                            <Link to={'/admin/users'} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.totalUsers || 0}
                                            </Link>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={'/admin/roles-permission/system-users'} className='scheduleBoxTitle'>
                                                Total System Users
                                            </Link>
                                            <Link to={'/admin/roles-permission/system-users'} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.totalSystemUsers || 0}
                                            </Link>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={''} className='scheduleBoxTitle'>
                                                Today's Registration
                                            </Link>
                                            <Link to={''} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.todaysRegisteredUsers || 0}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Post Details */}
                                <div className='col-lg-4 col-12'>
                                    <div className="scheduleBox">
                                        <div className="scheduleBoxTop">
                                            <div>Post Details</div>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={'/admin/posts'} className='scheduleBoxTitle'>
                                                Total Posts
                                            </Link>
                                            <Link to={'/admin/posts'} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.totalPosts||0}
                                            </Link>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={''} className='scheduleBoxTitle'>
                                                Today's Posts
                                            </Link>
                                            <Link to={''} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.todaysPosts||0}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Group Details */}
                                <div className='col-lg-4 col-12'>
                                    <div className="scheduleBox">
                                        <div className="scheduleBoxTop">
                                            <div>Group Details</div>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={'/admin/groups'} className='scheduleBoxTitle'>
                                                Total Groups
                                            </Link>
                                            <Link to={'/admin/groups'} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.totalGroups || 0}
                                            </Link>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={''} className='scheduleBoxTitle'>
                                                Today's Created Groups
                                            </Link>
                                            <Link to={''} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.todaysGroup || 0}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Event Details */}
                                <div className='col-lg-4 col-12'>
                                    <div className="scheduleBox">
                                        <div className="scheduleBoxTop">
                                            <div>Event Details</div>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={'/admin/events'} className='scheduleBoxTitle'>
                                                Total Events
                                            </Link>
                                            <Link to={'/admin/events'} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.totalEvents || 0}
                                            </Link>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={''} className='scheduleBoxTitle'>
                                                Today's Created Events
                                            </Link>
                                            <Link to={''} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.todaysEvents || 0}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Page Details */}
                                <div className='col-lg-4 col-12'>
                                    <div className="scheduleBox">
                                        <div className="scheduleBoxTop">
                                            <div>Page Details</div>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={'/admin/pages'} className='scheduleBoxTitle'>
                                                Total Pages
                                            </Link>
                                            <Link to={'/admin/pages'} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.totalPages || 0}
                                            </Link>
                                        </div>
                                        <div className="scheduleLabel total">
                                            <Link to={''} className='scheduleBoxTitle'>
                                                Today's Created Pages
                                            </Link>
                                            <Link to={''} className='scheduleBoxTitleCount'>
                                                {dashboardData?.data?.data?.todaysPages || 0}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;