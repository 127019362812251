import React from 'react'
import './createWidget.css'
import { Link } from 'react-router-dom'
import {PiPlusCircleBold} from "react-icons/pi"
const CreateWidget = ({url, title, className}) => {
  return (
    <Link to={url}>
        <div className={className ? className+' createWidget' : "createWidget"}>
            <div className="createWidgetWrap">
                <PiPlusCircleBold className='createPlus'/>
                <div className="titleMeta">
                    <h5>{title}</h5>
                    <span>its only take a few minutes!</span>
                </div>
            </div>
        </div>
    </Link>
  )
}

export default CreateWidget