import React, { useState } from 'react'
import { useApproveQuotesMutation } from '../../../store/api/Admin/adminQuotes'
import { Button, Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useUserPermissionListQuery } from '../../../store/api/Admin/adminRolePermissionApi'

const AdminQuotes = ({ data }) => {
    // state for approval modal
    const [approveModal, setApproveModal] = useState(false)
    // api call for approve a quote
    const [approveQuotes] = useApproveQuotesMutation()
    // function to manage approve quote
    const approveQuote = () => {
        approveQuotes(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
            setApproveModal(false)
        })
    }
    // get user's own data from local storage
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    // get permission list
    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }

    return (
        <>
            <div className={`quote bg-white shadow p-3 rounded adminReactionCard d-inline-block w-100 position-relative mb-3`}>
                <blockquote>
                    <span className="quote_sign">”</span>
                    {data?.quote}
                </blockquote>
                <cite>{data?.author}</cite>
                <div className="posted_by">
                    Posted by - <Link to={`/profile/${data?.created_by?._id}/timeline`}>{data?.created_by?.fullName}</Link>
                </div>
                {
                    data.approved === false && (
                        <div className='text-center mt-3'>
                            {(userInfo.user_type === 3 && user_permission_arr.includes("quotes_approve")) && <button className="btn bg-success-subtle text-success mx-2" onClick={() => setApproveModal(true)}>
                                Approve
                            </button>}
                            {userInfo.user_type === 1 && <button className="btn bg-success-subtle text-success mx-2" onClick={() => setApproveModal(true)}>
                                Approve
                            </button>}
                        </div>
                    )
                }
            </div>
            {/* APPROVE MODAL */}
            <Modal centered size="sm" show={approveModal} onHide={() => setApproveModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to approve this quote?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setApproveModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={approveQuote} className="mx-2">
                                Approve
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default AdminQuotes