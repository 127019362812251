import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import AWS from "aws-sdk";
import dayjs from 'dayjs';
import generateRandomImageName from '../../../../methods/generateRandomImageName';
import { useGetSingleEventQuery, useUpdateEventMutation } from '../../../../store/api/eventApi';
import { DatePicker } from "antd";
import style from "./CreateEvent.module.css"
import toast from 'react-hot-toast';
import { LiaTimesSolid } from 'react-icons/lia';
import { useNavigate, useParams } from 'react-router-dom';
import { ImClock } from 'react-icons/im';
import { GrLocation } from 'react-icons/gr';
import { defaultUserCover } from '../../../../components/components';
import { useGetCategoryListQuery } from '../../../../store/api/categoryMaster';
const { RangePicker } = DatePicker;

const formReducer = (state, { type, payload }) => {
    switch (type) {
        case "SET_EVENT_STATE":
            return { ...payload };
        case "SET_EVENT":
            return { ...state, [payload.name]: payload.data };
        case "SET_DURATION":
            return { ...state, timing: payload };
        case "SET_BANNER":
            return { ...state, banner: payload };
        default:
            return state;
    }
};

//this is main edit post component
const EditEvent = () => {
    const [editEvent] = useUpdateEventMutation()
    const [timing, setTiming] = useState(null)
    const [banner, setBanner] = useState([])
    const inputRefCover = useRef(null);

    const params = useParams();
    const EventID = params.eventId;
    // get single Event Data
    const EventAPI = useGetSingleEventQuery(EventID);

    const categoryList = useGetCategoryListQuery()

    const initialState = {
        title: "",
        description: "",
        venue: "",
        timing: [],
        banner: [],
        scope: "",
        category: ""
    };

    const navigate = useNavigate()

    useEffect(() => {
        if (EventAPI.status === "fulfilled") {
            dispatch({
                type: 'SET_EVENT_STATE',
                payload: {
                    event_id: EventAPI?.data?.data?._id,
                    title: EventAPI?.data?.data?.title,
                    description: EventAPI?.data?.data?.description,
                    venue: EventAPI?.data?.data?.venue,
                    timing: EventAPI?.data?.data?.timing,
                    banner: EventAPI?.data?.data?.banner_image,
                    scope: EventAPI?.data?.data?.scope,
                    category: EventAPI?.data?.data?.category?._id
                }
            })
        }
    }, [EventAPI])

    const [state, dispatch] = useReducer(formReducer, initialState);

    const handleChange = (e) => {
        dispatch({
            type: "SET_EVENT",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };

    const handleDuration = (v, DateTime) => {
        if (DateTime.length === 2) {
            setTiming(false)
        }
        dispatch({ type: "SET_DURATION", payload: DateTime });
    };

    // to set banner in state
    const handleBanner = (e) => {
        setBanner(e.target.files[0])
        const selectedFIles = [];
        const targetFiles = e.target.files;
        const targetFilesObject = [...targetFiles];

        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file));
        });

        if (selectedFIles.length > 0) {
            dispatch({
                type: "SET_BANNER",
                payload: [...selectedFIles],
            });
        }
    };

    // removing images from selected images
    const removeImage = () => {
        dispatch({ type: "SET_BANNER", payload: [] });
        setBanner([]);
        inputRefCover.current.value = null;
    };

    // POST THE EVENT DATA
    const submitForm = async (e) => {
        e.preventDefault();
        if (state.timing.length === 0) {
            setTiming(true)
        } else {
            setTiming(false)
            // do your all functions
            if (navigator.onLine) {
                if (banner.name) {
                    // for cover picture start
                    const bannerImage_S3_BUCKET = "ubuntu-social-media-2";
                    const bannerImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const bannerImage_s3 = new AWS.S3({
                        params: { Bucket: bannerImage_S3_BUCKET },
                        region: bannerImage_REGION,
                    });
                    let randomNameBannerImage = generateRandomImageName(banner.name);
                    let bannerName = "page_" + randomNameBannerImage.split(" ").join("_").split("-").join("_");
                    let bannerSize = banner.size;
                    let bannerType = banner.type;

                    const bannerImage_s3params = {
                        Bucket: bannerImage_S3_BUCKET,
                        Key: bannerName,
                        Body: banner,
                    };

                    var only_bannerImage_upload = bannerImage_s3.putObject(bannerImage_s3params).on("httpUploadProgress", (evt) => {
                        // File uploading progress
                        console.log(
                            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        );
                    }).promise();

                    await only_bannerImage_upload.then((data, err) => {});

                    let bannerImageArr = []

                    bannerImageArr.push({
                        image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + bannerName),
                        size: bannerSize,
                        mime_type: bannerType,
                    });

                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        venue: state.venue,
                        banner_image: bannerImageArr,
                        timing: state.timing,
                        event_id: state.event_id,
                        category_id: state.category
                    }

                    editEvent(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/event/${EventID}/discussion`)
                        } else {
                            toast.error("Please filled all required data")
                        }
                    })
                } else {
                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        venue: state.venue,
                        banner_image: state.banner || [],
                        timing: state.timing,
                        event_id: state.event_id,
                        category_id: state.category
                    }
                    editEvent(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/event/${EventID}/discussion`)
                        } else {
                            toast.error("Please filled all required data")
                        }
                    })
                }
            }
        }
    };

    return (
        <>
            <div className="gap2 gray-bg full_height">
                <div className="container">
                    <div className="row">
                        <div className={"col-md-7 col-lg-8"}>
                            <Card className={`${style.central_meta}`}>
                                <Card.Body className={`${style.card_body}`}>
                                    <Card.Title className={`${style.card_title} mb-3 fs-4`}>
                                        Edit Your Event
                                    </Card.Title>
                                    <Form noValidate method="post">
                                        <Row>
                                            <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                                <Form.Label>Event name*</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter your event name"
                                                    value={state.title}
                                                    onChange={handleChange}
                                                    name="title"
                                                />
                                                <Form.Control.Feedback type="invalid">Please provide event name</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                                <Form.Label>Group scope*</Form.Label>
                                                <Form.Select required aria-label="Default select example" value={state?.scope} name="scope" onChange={handleChange}>
                                                    <option value="public">Public</option>
                                                    <option value="private">Private</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom01" className={`col-md-12 mb-3`}>
                                                <Form.Label>Event category*</Form.Label>
                                                <Form.Select required aria-label="Default select example" value={state.category} name="category" onChange={handleChange}>
                                                    <option>Select category</option>
                                                    {categoryList?.data?.data.map((el) => (
                                                        <option key={el._id} value={el._id}>{el.category_name}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                                <Form.Label>Event location*</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter your event location"
                                                    value={state.venue}
                                                    onChange={handleChange}
                                                    name="venue"
                                                />
                                                <Form.Control.Feedback type="invalid">Please add location</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                                <Form.Label>Description*</Form.Label>
                                                <Form.Control
                                                    placeholder="Enter a small description"
                                                    required
                                                    type="text"
                                                    value={state.description}
                                                    onChange={handleChange}
                                                    name="description"
                                                    as="textarea"
                                                    style={{ height: '70px' }}
                                                />
                                                <Form.Control.Feedback type="invalid">Please add description</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                                <Form.Label>Event duration*</Form.Label>
                                                <RangePicker
                                                    showTime
                                                    defaultValue={[dayjs(state?.timing[0]), dayjs(state?.timing[1])]}
                                                    onChange={handleDuration}
                                                    style={{ width: '100%', height: '39px', borderColor: timing === null ? "var(--bs-border-color)" : (timing === true ? "var(--red)" : "var(--bs-form-valid-border-color)") }}
                                                    required
                                                />
                                                <Form.Control.Feedback className={style.invalidMessage} style={{ display: timing ? "block" : "none" }}>Please provide event date and time</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formFile" className={`col-md-6 mb-3`}>
                                                <Form.Label>Upload banner*</Form.Label>
                                                <Form.Control type="file" ref={inputRefCover} onChange={handleBanner} name="banner" />
                                                <Form.Control.Feedback type="invalid">Please select event photo</Form.Control.Feedback>

                                                {(state.banner.length > 0 || banner.length > 0) && (
                                                    <div className={style.editEventUploadImgCard}>
                                                        <img src={banner?.name?.length > 0 ? state?.banner[0] : state?.banner[0]?.image_url} className="img-thumbnail" alt='...' />
                                                        <button type="button" onClick={removeImage}>
                                                            <LiaTimesSolid />
                                                        </button>
                                                    </div>
                                                )}
                                            </Form.Group>

                                        </Row>
                                    </Form>
                                    <div className='text-center mt-3'>
                                        <Button className='mx-1' variant="secondary" onClick={() => { }}>Back</Button>
                                        <Button className='mx-1' variant="primary" onClick={submitForm}>Update</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="eventCard">
                                <figure>
                                    <img
                                        asd={
                                            state.banner.length > 0 ? state.banner[0].image_url : "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?size=626&ext=jpg&uid=R75396338&ga=GA1.2.315737418.1692334609&semt=ais"
                                        }
                                        src={banner?.name?.length > 0 ? state?.banner[0] : (state?.banner[0]?.image_url ? state?.banner[0]?.image_url : defaultUserCover)}
                                        alt="..."
                                        height={"300"}
                                    />
                                </figure>
                                <div className="eventCardContent">
                                    <span className="upload_time">
                                        <ImClock />{" "}
                                        {state.timing.length > 0
                                            ? state.timing[0] + " to " + state.timing[1]
                                            : "Event Duration"}
                                    </span>
                                    <h6>
                                        {state.title.length > 0
                                            ? state.title
                                            : "Event Title"}
                                    </h6>
                                    {
                                        state.description !== '' ?
                                            <>
                                                <p>
                                                    {state.description}
                                                </p>
                                            </>
                                            : "Description"
                                    }

                                    <div className="location">
                                        <GrLocation /> {state.venue.length > 0 ? state.venue : "Event Location"}
                                    </div>
                                    <button className="btn btn-light w-100">Join Event</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditEvent;