import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const currencyApi = createApi({
    reducerPath: "currencyApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["currency"],
    endpoints: (builder) => ({
        // POST REACT LIST
        getCurrencyList: builder.query({
            query: () => `api/currency`,
            providesTags: ["currency"],
        })
    })
})


export const { useGetCurrencyListQuery } = currencyApi;