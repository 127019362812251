import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSingleProductQuery, useUpdateProductMutation } from '../../store/api/marketApi';
import { useGetCategoryListQuery } from '../../store/api/categoryMaster';
import { useGetCurrencyListQuery } from '../../store/api/currencyMaster';
import Compressor from 'compressorjs';
import AWS from "aws-sdk";
import generateRandomImageName from '../../methods/generateRandomImageName';
import toast from 'react-hot-toast';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { LiaTimesSolid } from 'react-icons/lia';
import ReactQuill from 'react-quill';

const EditProduct = () => {
    const { productId } = useParams();
    const productDetails = useGetSingleProductQuery(productId);
    const categoryList = useGetCategoryListQuery()
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const [cover, setCover] = useState([])
    const [image, setImage] = useState([]);
    const inputRefCover = useRef(null);
    const inputRefImage = useRef(null);
    const [updateProduct] = useUpdateProductMutation();

    //initial state for create page form
    const initialState = {
        product_name: "",
        description: "",
        stock_quantity: "",
        currency_id: "",
        category_id: "",
        selling_price: "",
        cover_image: [],
        images: [],
        short_desc: "",
        can_buy: 0
    };

    //reducer for create page form
    const createProductReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_PRODUCT_STATE":
                return { ...payload };
            case "SET_PRODUCT":
                return { ...state, [payload.name]: payload.data };
            case "SET_LOGNDESC":
                return { ...state, description: payload.data };
            case "SET_COVER":
                return { ...state, [payload.name]: payload.data };
            case "SET_IMAGES":
                return { ...state, images: payload };
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(createProductReducer, initialState);

    const handleChange = (e) => {
        dispatch({
            type: "SET_PRODUCT",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };

    const handleLongDesc = (data) => {
        dispatch({
            type: "SET_LOGNDESC",
            payload: {
                data: data,
            },
        });
    };

    const handleCover = (e) => {
        setCover(e.target.files[0])
        const selectedFIles = [];
        const targetFiles = e.target.files;
        const targetFilesObject = [...targetFiles];

        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file));
        });

        if (selectedFIles.length > 0) {
            dispatch({
                type: "SET_COVER",
                payload: {
                    name: e.target.name,
                    data: [...selectedFIles],
                },
            });
        }
    };

    const removeCoverImage = () => {
        setCover("")
        dispatch({
            type: "SET_COVER",
            payload: {
                name: "cover_image",
                data: [],
            },
        });
        inputRefCover.current.value = null;
    }

    const removeImage = (index) => {
        compressedFiles = []
        selectedFIles = []
        dispatch({ type: "SET_IMAGES", payload: [] });
        setImage([]);
        inputRefImage.current.value = null;
    };

    let selectedFIles;
    let compressedFiles = []

    function handleImageFileChange(event) {
        setImage([...event.target.files]);

        selectedFIles = [];
        const targetFiles = event.target.files;
        const targetFilesObject = [...targetFiles];

        targetFilesObject.map((file) => {
            selectedFIles.push({
                name: file.name,
                image_url: URL.createObjectURL(file),
                type: file.type,
                size: file.size
            })
            if (file.type.slice(0, 5) === "image") {
                new Compressor(file, {
                    quality: 0.2, // Default compression quality
                    success(result) {
                        const file = new File([result], result.name, { type: result.type });
                        compressedFiles.push({
                            name: file.name,
                            image_url: file,
                            type: file.type,
                            size: file.size
                        })
                        dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
                    },
                    error(err) {
                        console.error('Image compression error:', err);
                    },
                })
            } else {
                compressedFiles.push({
                    name: file.name,
                    image_url: file,
                    type: file.type,
                    size: file.size
                })
            }
            return dispatch({ type: "SET_IMAGES", payload: [...compressedFiles] });
        });

    }

    // list product form submit
    const submitForm = async (e) => {
        e.preventDefault();
        if (e.currentTarget.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            // when device online it will work
            if (navigator.onLine) {
                if (cover.name && image.length > 0) {
                    // S3 bucket implement start
                    const S3_BUCKET = "ubuntu-social-media-2";
                    const REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });

                    const s3 = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        region: REGION,
                    });

                    let imgArr = [];
                    for (let i = 0; i < state.images.length; i++) {
                        let randomName = generateRandomImageName(state.images[i].name)
                        let fileSize = state.images[i].size;
                        let fileType = state.images[i].type;
                        let fileName = "post_" + randomName.split(" ").join("_").split("-").join("_");
                        const params = {
                            Bucket: S3_BUCKET,
                            Key: fileName,
                            Body: state.images[i].image_url,
                        };

                        var upload = s3.putObject(params).on("httpUploadProgress", (evt) => {
                            // File uploading progress
                            console.log(
                                "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                            );
                        }).promise();

                        imgArr.push({
                            image_url: "https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + fileName,
                            size: fileSize,
                            mime_type: fileType,
                        });
                    }
                    await upload;
                    // s3 end for image (not cover image)

                    const coverImage_S3_BUCKET = "ubuntu-social-media-2";
                    const coverImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const coverImage_s3 = new AWS.S3({
                        params: { Bucket: coverImage_S3_BUCKET },
                        region: coverImage_REGION,
                    });
                    let randomNameCoverImage = generateRandomImageName(cover.name);
                    let coverName = "page_" + randomNameCoverImage.split(" ").join("_").split("-").join("_");
                    let coverSize = cover.size;
                    let coverType = cover.type;

                    const coverImage_s3params = {
                        Bucket: coverImage_S3_BUCKET,
                        Key: coverName,
                        Body: cover,
                    };

                    var only_coverImage_upload = coverImage_s3.putObject(coverImage_s3params).on("httpUploadProgress", (evt) => {
                        // File uploading progress
                        console.log(
                            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        );
                    }).promise();

                    await only_coverImage_upload;

                    let coverImageArr = []

                    coverImageArr.push({
                        image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
                        size: coverSize,
                        mime_type: coverType,
                    });

                    let obj = {
                        product_id: state.product_id,
                        product_name: state.product_name,
                        description: state.description,
                        stock_quantity: state.stock_quantity,
                        category_id: state.category_id,
                        currency_id: state.currency_id,
                        selling_price: state.selling_price,
                        cover_image: coverImageArr,
                        images: imgArr
                    }

                    updateProduct(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate("/marketplace")
                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })
                }

                else if (image.length > 0) {
                    // S3 bucket implement start
                    const S3_BUCKET = "ubuntu-social-media-2";
                    const REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });

                    const s3 = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        region: REGION,
                    });

                    let imgArr = [];
                    for (let i = 0; i < state.images.length; i++) {
                        let randomName = generateRandomImageName(state.images[i].name)
                        let fileSize = state.images[i].size;
                        let fileType = state.images[i].type;
                        let fileName = "post_" + randomName.split(" ").join("_").split("-").join("_");
                        const params = {
                            Bucket: S3_BUCKET,
                            Key: fileName,
                            Body: state.images[i].image_url,
                        };

                        var upload2 = s3.putObject(params).on("httpUploadProgress", (evt) => {
                            // File uploading progress
                            console.log(
                                "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                            );
                        }).promise();

                        imgArr.push({
                            image_url: "https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + fileName,
                            size: fileSize,
                            mime_type: fileType,
                        });
                    }
                    await upload2;
                    // s3 end for image (not cover image)

                    let obj = {
                        product_id: state.product_id,
                        product_name: state.product_name,
                        description: state.description,
                        stock_quantity: state.stock_quantity,
                        category_id: state.category_id,
                        currency_id: state.currency_id,
                        selling_price: state.selling_price,
                        cover_image: state.cover_image || [],
                        images: imgArr
                    }

                    updateProduct(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate("/marketplace")
                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })
                }

                else if (cover.name) {
                    const coverImage_S3_BUCKET = "ubuntu-social-media-2";
                    const coverImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const coverImage_s3 = new AWS.S3({
                        params: { Bucket: coverImage_S3_BUCKET },
                        region: coverImage_REGION,
                    });
                    let randomNameCoverImage = generateRandomImageName(cover.name);
                    let coverName = "page_" + randomNameCoverImage.split(" ").join("_").split("-").join("_");
                    let coverSize = cover.size;
                    let coverType = cover.type;

                    const coverImage_s3params = {
                        Bucket: coverImage_S3_BUCKET,
                        Key: coverName,
                        Body: cover,
                    };

                    let only_coverImage_upload = coverImage_s3.putObject(coverImage_s3params).on("httpUploadProgress", (evt) => {
                        // File uploading progress
                        console.log(
                            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        );
                    }).promise();

                    await only_coverImage_upload.then((data, err) => { });

                    let coverImageArr = []

                    coverImageArr.push({
                        image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
                        size: coverSize,
                        mime_type: coverType,
                    });

                    let obj = {
                        product_id: state.product_id,
                        product_name: state.product_name,
                        description: state.description,
                        stock_quantity: state.stock_quantity,
                        category_id: state.category_id,
                        currency_id: state.currency_id,
                        selling_price: state.selling_price,
                        cover_image: coverImageArr,
                        images: state.images || []
                    }

                    updateProduct(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate("/marketplace")
                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })
                }

                else {
                    let obj = {
                        product_id: state.product_id,
                        product_name: state.product_name,
                        description: state.description,
                        stock_quantity: state.stock_quantity,
                        category_id: state.category_id,
                        currency_id: state.currency_id,
                        selling_price: state.selling_price,
                        cover_image: state.cover_image || [],
                        images: state.images || []
                    }

                    updateProduct(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            // navigate("/marketplace")
                            navigate(-1)

                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })
                }
            }
            // when device not online it will work
            else {
                toast.error("Please turn on internet!!!");
            }
        }
    };

    useEffect(() => {
        if (productDetails.status === "fulfilled") {
            dispatch({
                type: 'SET_PRODUCT_STATE',
                payload: {
                    product_id: productDetails?.data?.data?._id,
                    product_name: productDetails?.data?.data?.product_name,
                    description: productDetails?.data?.data?.description,
                    stock_quantity: productDetails?.data?.data?.stock_quantity,
                    currency_id: productDetails?.data?.data?.currency?._id,
                    category_id: productDetails?.data?.data?.category?._id,
                    selling_price: productDetails?.data?.data?.selling_price,
                    cover_image: productDetails?.data?.data?.cover_image,
                    images: productDetails?.data?.data?.images,
                    can_buy: productDetails?.data?.data?.can_buy.toString()
                }
            })
        }
    }, [productDetails])

    return (
        <div className="gap2 gray-bg full_height">
            <div className="container">
                <div className="row">
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8"}>
                        <Card className={`central_meta`}>
                            <Card.Body className={`card_body`}>
                                <Card.Title className={`card_title mb-3 fs-4`} >
                                    Edit The Listed Product
                                </Card.Title>
                                <Form noValidate validated={validated} method="post" onSubmit={submitForm}>
                                    <Row>
                                        {/* product title */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-12 mb-3`}>
                                            <Form.Label>Product title*</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter your product name" value={state.product_name} onChange={handleChange} name="product_name" />
                                            <Form.Control.Feedback type="invalid">Please provide Product title</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* product category */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Product category*</Form.Label>
                                            <Form.Select required aria-label="Default select example" value={state.category_id} name="category_id" onChange={handleChange}>
                                                <option>Select category</option>
                                                {categoryList?.data?.data.map((el) => (
                                                    <option key={el._id} value={el._id}>{el.category_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">Please provide product category</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* product price */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Product Price*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter product price"
                                                value={state.selling_price}
                                                onChange={handleChange}
                                                name="selling_price"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide job title</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* product description */}
                                        <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                            <Form.Label>Product description*</Form.Label>
                                            <ReactQuill
                                                value={state.description}
                                                onChange={handleLongDesc}
                                                modules={{
                                                    toolbar: [
                                                        ['bold', 'italic', 'underline', 'strike'],
                                                        ['blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        [{ 'header': [1, 2, 3, 4, 5, 6, 7] }],
                                                    ],
                                                }}
                                                formats={[
                                                    "header",
                                                    "font",
                                                    "size",
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strike",
                                                    "blockquote",
                                                    "list",
                                                    "bullet",
                                                    "indent",
                                                ]}
                                                placeholder="Share some what are you thinking?"
                                            />
                                            <Form.Control.Feedback type="invalid">Please add a description</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* product status */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-12 mb-3`}>
                                            <Form.Label>Product Status</Form.Label>
                                            <div key={`inline-${"radio"}`} className="mb-3">
                                                <Form.Check
                                                    inline
                                                    label="Can buy this"
                                                    name="can_buy"
                                                    type={"radio"}
                                                    id={`inline-${"radio"}-1`}
                                                    value={"1"}
                                                    checked={state.can_buy === "1"}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Only for enquiry"
                                                    name="can_buy"
                                                    type={"radio"}
                                                    id={`inline-${"radio"}-2`}
                                                    value={"0"}
                                                    checked={state.can_buy === "0"}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <Form.Control.Feedback type="invalid">Please provide Product title</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* upload cover */}
                                        <Form.Group as={Col} controlId="formFile" className="col-md-6 mb-3">
                                            <Form.Label>Upload Cover *</Form.Label>
                                            <div className="position-relative">
                                                <Form.Control type="file" onChange={handleCover} name="cover_image" ref={inputRefCover} className="prevent-validation" />
                                                {(cover.name || state?.cover_image.length > 0) && (
                                                    <div className={`editEventUploadImgCard`}>
                                                        <img src={cover?.name?.length > 0 ? state?.cover_image[0] : state?.cover_image[0]?.image_url} className="img-thumbnail" alt='...' />
                                                        <button type="button" onClick={removeCoverImage}>
                                                            <LiaTimesSolid />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </Form.Group>

                                        {/* upload photos */}
                                        <Form.Group as={Col} controlId="formFile" className="col-md-6 mb-3">
                                            <Form.Label>Upload Photos (max 4 pics)*</Form.Label>
                                            <div className="position-relative">
                                                <Form.Control type="file" onChange={handleImageFileChange} name="images" ref={inputRefImage} className="prevent-validation" multiple accept="image/*" />
                                                {(image.length > 0 || state.images.length > 0) && <Row className='gx-2'>
                                                    {image.length > 0 ? image.map((el, i) => (
                                                        <Col md="3" key={i}>
                                                            <div className={`editEventUploadImgCard`}>
                                                                <img src={URL.createObjectURL(image[i])} alt="..." className="img-thumbnail" />
                                                                <button type="button" onClick={() => removeImage(i)}>
                                                                    <LiaTimesSolid />
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    )) : state.images.map((el, i) => (
                                                        <Col md="3" key={i}>
                                                            <div className={`editEventUploadImgCard`}>
                                                                <img src={el.image_url} alt="..." className="img-thumbnail" />
                                                                <button type="button" onClick={() => removeImage(i)}>
                                                                    <LiaTimesSolid />
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>}
                                            </div>
                                        </Form.Group>
                                    </Row>
                                    <div className="mt-2 text-center">
                                        <Button className="btn btn-primary" type="submit">Edit Product</Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProduct
