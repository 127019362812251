import React from "react";
import {BsCheck2} from 'react-icons/bs'
import './alert.css'
const SuccessAlert = ({ title, para }) => {
  return (
    <div className="alert_box success">      
      <div className="alertIcon success">
      <BsCheck2/>
      </div>
      {(title !== "" && title !== undefined) && <h4>{title}</h4>}
      {(para !== "" && para !== undefined)  && <p>{para}</p>}
    </div>
  );
};

export default SuccessAlert;
