import React from "react";
import userCover from "../../../assets/images/resources/album4.jpg";
import { Link } from "react-router-dom";
import WidgetWrapper from "../WidgetWrapper";
import style from "./HostWidget.module.css";
import { defaultUserCover, defaultUserDp } from "../../components";

const HostWidget = ({ data }) => {
   return (
      <>
         <WidgetWrapper title={"Meet The Host"}>
            <div className={style.profile_widget}>
               <div className={style.cover_img}>
                  <img src={data?.cover_picture?.length > 0 ? data?.cover_picture[0]?.image_url : defaultUserCover} height={100} width={100} alt="cover_pic" />
               </div>
               <div className={style.completation}>
                  <img
                     src={data?.profile_picture?.length > 0 ? data?.profile_picture[0]?.image_url : defaultUserDp}
                     alt="profile_pic"
                  />
               </div>
               <div className={style.page_meta}>
                  <Link to="/profile" className="underline">
                     {data?.fullName}
                  </Link>
                  <span>{data?.gender === "1" ? "Male" : data?.gender === "2" ? "Female" : "Others"}{data?.gender && " |"} 24 yrs | Single | Kolkata</span>
                  <span className={style.desig}>
                     Lorem ipsum, dolor sit amet consectetur adipisicing elit
                  </span>
               </div>
               <div className={style.page_likes}>
                  <ul className="nav nav-tabs likes_btn">
                     <li className="nav-item">
                        <button
                           type="button"
                           className={"btn btn-primary rounded-pill w-100"}
                        >
                           Following
                        </button>
                     </li>
                     <li className="nav-item">
                        <button
                           type="button"
                           className={"btn btn-light rounded-pill w-100"}
                        >
                           Message
                        </button>
                     </li>
                  </ul>
               </div>
            </div>
         </WidgetWrapper>
      </>
   );
};

export default HostWidget;
