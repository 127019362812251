import React, { useEffect, useReducer, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { AdminMenu } from '../../../components/components'
import { useEditNotificationMutation, useGetNotificationByIdQuery } from '../../../store/api/Admin/AdminNotification'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

const AdminNotificationEdit = () => {
    const [editNotifition] = useEditNotificationMutation()
    const navigate = useNavigate()
    const { id } = useParams()
    const getSingleNotification = useGetNotificationByIdQuery(id)

    const initialState = {
        meta_type: "",
        title: "",
        notification_for: "",
        description: ""
    }
    const notificationReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_VALUE":
                return { ...state, [payload.name]: payload.data };
            case "CLEAR_STATE":
                return initialState;
            case "SET_DEFAULT_VALUE":
                return { ...payload };
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(notificationReducer, initialState);
    const handleChange = (e) => {
        dispatch({
            type: "SET_VALUE",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };

    const [formValidate, setFormValidate] = useState(false)
    const handleSave = () => {
        setFormValidate(true)
        if (state.description && state.notification_for && state.meta_type && state.title) {
            editNotifition({ ...state, status: 1, id: id }).then((res) => {
                console.log(res)
                if (res?.data?.error === 0) {
                    navigate("/admin/notification")
                    toast.success(res?.data?.message)
                } else {
                    toast.error("Something went wrong!")
                }
            })
        } else {
            toast.error("Fill all data properly!")
        }
    }

    useEffect(() => {
        if (getSingleNotification.status === "fulfilled") {
            dispatch({
                type: "SET_DEFAULT_VALUE",
                payload: {
                    meta_type: getSingleNotification?.data?.data?.meta_type,
                    title: getSingleNotification?.data?.data?.title,
                    notification_for: getSingleNotification?.data?.data?.notification_for,
                    description: getSingleNotification?.data?.data?.description
                }
            })
        }
    }, [getSingleNotification.status])

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 p-3'>
                            <h5 className='mb-2'>Edit Notification</h5>
                            <Row>
                                <Form.Group as={Col} controlId="cat_nm" className={`col-md-12 mb-3`} >
                                    <Form.Label>Notification Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter notification title"
                                        value={state.title}
                                        onChange={handleChange}
                                        name="title"
                                        required
                                    />
                                    {(formValidate && !state.title) && <div style={{ color: "#dc3545", fontSize: "" }}>
                                        Please enter notification title
                                    </div>}
                                </Form.Group>
                                <Form.Group as={Col} controlId="meta_keyword" className={`col-md-6 mb-3`}>
                                    <Form.Label>Notification Type</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleChange} value={state.meta_type} required name="meta_type">
                                        <option>Select Type</option>
                                        <option value="Group">Group</option>
                                        <option value="Event">Event</option>
                                        <option value="Post">Post</option>
                                        <option value="PostComment">Post Comment</option>
                                        <option value="Community">Community</option>
                                        <option value="User">User</option>
                                        <option value="Chat">Chat</option>
                                        <option value="Page">Page</option>
                                        {/* ['Page', 'Group', 'Event', 'Post', 'PostComment', 'Community','User','Chat'] */}
                                    </Form.Select>
                                    {(formValidate && !state.meta_type) && <div style={{ color: "#dc3545", fontSize: "" }}>
                                        Please select notification type
                                    </div>}
                                </Form.Group>
                                <Form.Group as={Col} controlId="cat_nm" className={`col-md-6 mb-3`}>
                                    <Form.Label>Notification For</Form.Label>
                                    <Form.Select aria-label="Default select example" value={state.notification_for} name="notification_for" onChange={handleChange}>
                                        <option>Select notification for</option>
                                        <option value="Create">Create</option>
                                        <option value="user_profile_photo">User Profile Photo</option>
                                        <option value="user_cover_photo">User Cover Photo</option>
                                        <option value="post_react">Post React</option>
                                        <option value="post_vote">Post Vote</option>
                                        <option value="post_comment">Post comment</option>
                                        <option value="birthday_wish">Birthday wish</option>
                                        <option value="Edit">Edit</option>
                                        <option value="List">List</option>
                                        <option value="Delete">Delete</option>
                                        <option value="accept_request">Accept request</option>
                                        <option value="send_request">Send request</option>
                                        <option value="page_join">Page Join</option>
                                        {/* ['Create', 'Edit','List','Delete','Block'] */}
                                    </Form.Select>
                                    {(formValidate && !state.notification_for) && <div style={{ color: "#dc3545", fontSize: "" }}>
                                        Please select notification for
                                    </div>}
                                </Form.Group>
                                <Form.Group as={Col} controlId="meta_desc" className={`col-md-12 mb-3`}>
                                    <Form.Label>Notification Description</Form.Label>
                                    <Form.Control
                                        placeholder="Enter a meta description"
                                        type="text"
                                        value={state.description}
                                        onChange={handleChange}
                                        name="description"
                                        as="textarea"
                                        style={{ height: '100px' }}
                                        required
                                    />
                                    {(formValidate && !state.description) && <div style={{ color: "#dc3545", fontSize: "" }}>
                                        Please enter notification description
                                    </div>}
                                </Form.Group>
                                <div className="mt-2 text-center">
                                    <Button className="btn btn-primary" onClick={handleSave}>Edit Notification</Button>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminNotificationEdit
