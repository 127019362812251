import React from 'react'
import './checkButton.css'
const CheckButton = ({id,name,handleChange,checked,title}) => {
  return (
    <div className="checkboxBtn p-1">
        <input
        type="checkbox"
        id={id}
        className="graphic"
        name={name}
        onChange={handleChange}
        checked={checked}
        />
        <label htmlFor={id}>
        {title}
        </label>
    </div>
  )
}

export default CheckButton