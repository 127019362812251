import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    offCanvas : false,
};
const HeaderSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setOffCanvas: (state, action) => {      
            state.offCanvas = action.payload;
        },
    },
});

// set Offcanvas
export const { setOffCanvas } = HeaderSlice.actions;
// Select Offcanvas
export const selectOffCanvas = (state) => state.header.offCanvas;

export default HeaderSlice.reducer;
