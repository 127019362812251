import React, { useState } from 'react'
import { useChangeReportStatusMutation, useDeleteReportMutation } from '../../../../store/api/Admin/adminReportApi'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useDeletePermissionMutation, useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'
import { useSelector } from 'react-redux'

const PermissionRow = ({ data }) => {
    const navigate = useNavigate()
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    const [deletePermission] = useDeletePermissionMutation()
    const [deleteModal, setDeleteModal] = useState(false)
    const deleteReason = () => {
        deletePermission(data._id).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
                setDeleteModal(false)
            }
        })
    }

    const permissionList = useUserPermissionListQuery()
    if (permissionList.isSuccess === true) {
        var user_permission_arr = permissionList?.data?.permissions;
    }
    
    return (
        <>
            <tr>
                <td>
                    <div className='table_info'>{data.name}</div>
                </td>
                <td>
                    <div className='table_actions text-center'>
                        {/* checking for have access these buttons */}
                        {(userInfo.user_type === 3 && user_permission_arr.includes("permission_edit")) && <button className='bg-gray-subtle text-gray' onClick={() => navigate(`/admin/roles-permission/permission/edit/${data._id}`)}>Edit</button>}
                        {userInfo.user_type === 1 && <button className='bg-gray-subtle text-gray' onClick={() => navigate(`/admin/roles-permission/permission/edit/${data._id}`)}>Edit</button>}
                        {(userInfo.user_type === 3 && user_permission_arr.includes("permission_delete")) && <button className='bg-danger-subtle text-danger' onClick={() => setDeleteModal(true)}>Delete</button>}
                        {userInfo.user_type === 1 && <button className='bg-danger-subtle text-danger' onClick={() => setDeleteModal(true)}>Delete</button>}
                    </div>
                    {/* Delete MODAL */}
                    <Modal centered size="sm" show={deleteModal} onHide={() => setDeleteModal(false)}>
                        <Modal.Body>
                            <div className="text-center">
                                <h5 className="mb-3">Are you sure, you want to delete this react?</h5>
                                <div className="text-center">
                                    <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={deleteReason} className="mx-2">
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* BLOCK MODAL */}
                    {/* <Modal centered size="sm" show={statusModal} onHide={() => setStatusModal(false)}>
                        <Modal.Body>
                            <div className="text-center">
                                <h5 className="mb-3">Are you sure, you want to {data.status !== 1 ? 'active' : 'deactive'} this react?</h5>
                                <div className="text-center">
                                    <Button variant="secondary" onClick={() => setStatusModal(false)} className="mx-2">
                                        Cancel
                                    </Button>
                                    <Button variant="danger" onClick={changeStatus} className="mx-2">
                                        {data.status !== 1 ? 'Active' : 'Deactive'}
                                    </Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal> */}
                </td>
            </tr>
        </>
    )
}

export default PermissionRow
