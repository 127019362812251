import React, { useEffect, useReducer, useState } from 'react'
import { AdminMenu } from '../../../components/components'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useCreateMembershipMutation, useGetMembershipFeatureListQuery, useGetMembershipQuery, useUpdateMembershipMutation } from '../../../store/api/Admin/membershipPanelApi';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
// const keyNameArr = ['can_add_connection', 'can_follow', 'can_unfollow', 'is_fullprofile_view', 'groups_can_added', 'can_post_in_community', 'can_create_page', 'can_create_events', 'member_joint_event', 'post_video_wall', 'grace_period', 'hide_profile']
const keyNameArr = ['can_add_connection', 'can_follow', 'can_unfollow', 'is_fullprofile_view', 'groups_can_added', 'can_post_in_community', 'can_create_page', 'can_create_events', 'member_joint_event', 'post_video_wall', 'grace_period']
const initialState = {
  plan_name: "",
  price: "",
  mebership_type: "",
  features: keyNameArr.map((elem, i) => ({ key_name: elem, accesebility: '', title: '', value: '' })),
};

const EditMembership = () => {
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [editMembership] = useUpdateMembershipMutation()
  const [validated, setValidated] = useState(false);
  const getMembershipData = useGetMembershipQuery(id)
  const membershipFeatureList = useGetMembershipFeatureListQuery()

  const createCategoryReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_DEFAULT_STATE":
        return payload;
      case "SET_STATE":
        return { ...state, [payload.name]: payload.data };
      case "SET_VALUE":
        return { ...state, [payload.name]: payload.data };
      case "SET_FEATURES":
        return { ...state, features: payload };
      case "CLEAR_STATE":
        return initialState
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(createCategoryReducer, initialState);
  const handleChange = (e) => {
    dispatch({
      type: "SET_STATE",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };
  const handleNumber = (e) => {
    dispatch({
      type: "SET_VALUE",
      payload: {
        name: e.target.name,
        data: Number(e.target.value),
      },
    });
  };

  const handleFeature = (input, elem, i) => {
    let dataArr = JSON.parse(JSON.stringify(state.features));
    dataArr[i][input.target.name] = input.target.value
    dispatch({
      type: "SET_FEATURES",
      payload: dataArr
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      const data = {
        membership_id: id,
        mebership_type: state.mebership_type,
        plan_name: state.plan_name,
        price: state.price,
        selected: false,
        status: 1,
        features: state.features
      }
      editMembership(data).then((res) => {
        if (res?.data?.error === 0) {
          toast.success(res?.data?.message)
          dispatch({
            type: "CLEAR_STATE"
          });
          navigate("/admin/plans");
        }
      })
    }
    setValidated(true);
  };

  useEffect(() => {
    if (getMembershipData.status === 'fulfilled') {
      dispatch({
        type: "SET_DEFAULT_STATE",
        payload: {
          plan_name: getMembershipData?.data?.data?.plan_name,
          price: getMembershipData?.data?.data?.price,
          mebership_type: getMembershipData?.data?.data?.mebership_type,
          features: getMembershipData?.data?.data?.features,
        }
      });
    }
  }, [getMembershipData])

  return (
    <div className='gap2 gray-bg full_height'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'>
            <AdminMenu open />
          </div>
          <div className='col-md-9'>
            <div className='bg-white rounded-2 p-3'>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h5 className='mb-2'>Edit Membership</h5>
                <Row>
                  <Form.Group as={Col} controlId="plan_name" className={`col-md-4 mb-3`}>
                    <Form.Label>Plan Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Plan name"
                      name="plan_name"
                      required
                      value={state.plan_name}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">Please provide plan name</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="price" className={`col-md-4 mb-3`}>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter plan price"
                      name="price"
                      required
                      value={state.price}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">Please provide plan price</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="mebership_type" className={`col-md-4 mb-3`}>
                    <Form.Label>Membership type</Form.Label>
                    <Form.Select aria-label="Mebership type select" name="mebership_type" onChange={handleChange} value={state.mebership_type}>
                      <option value="">Select</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Yearly">Yearly</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Please select membership type</Form.Control.Feedback>
                  </Form.Group>
                  <div className='col-12'>
                    <h6>Features</h6>
                    {state.features.map((elem, i) => {
                      return (
                        <div className='feature_item p-3 mb-2 rounded-2' key={i} style={{ background: 'var(--lightOverOverBg)' }}>
                          <Row className="gx-2">
                            <Form.Group as={Col} controlId={`${elem.key_name}_accesebility`} className={`col-md-4`}>
                              <h6 className='fs_14 fw-bold text-capitalize'>{elem.key_name}</h6>
                              {/* <Form.Label className="mb-1">{elem.key_name}</Form.Label> */}
                              <Form.Select name="accesebility" aria-label="Select accesebility" required onChange={(e) => handleFeature(e, elem, i)} value={elem.accesebility}>
                                <option value="">Select accesebility</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">Please select accesebility</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId={`${elem.key_name}_plan_name`} className={`col-md-4`}>
                              <Form.Label className="mb-1">Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Plan name"
                                name="title"
                                required
                                value={elem.title}
                                onChange={(e) => handleFeature(e, elem, i)}
                              />
                              <Form.Control.Feedback type="invalid">Please provide plan name</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} controlId={`${elem.key_name}_value`} className={`col-md-4`}>
                              <Form.Label className="mb-1">Value</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Value"
                                name="value"
                                required
                                value={elem.value === null ? 0 : elem.value}
                                onChange={(e) => handleFeature(e, elem, i)}
                              />
                              <Form.Control.Feedback type="invalid">Please provide value</Form.Control.Feedback>
                            </Form.Group>
                          </Row>
                        </div>
                      )
                    }
                    )}
                  </div>
                </Row>
                <div className='text-center'>
                  <Button type="submit">Update</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditMembership;
