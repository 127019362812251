import React from "react";
import { Link } from "react-router-dom";
import SearchbarContainer from "./searchContainer/SearchbarContainer";
import SupportSection from "./supportSection/SupportSection";
import TwoBox from "./twoBox/TwoBox";
import CommonHelp from "./commonHelp/CommonHelp";

const HelpSupport = () => {
   return (
      <div className="full_height">
         <div className="theme-layout">
            {/* searchbar container */}
            <SearchbarContainer />
            {/* support section */}
            <SupportSection />
            {/* two more box */}
            <TwoBox />
            {/* common help */}
            <CommonHelp />
         </div>
      </div>
   );
};

export default HelpSupport;
