import React, { useEffect, useState } from 'react'
import './weather.css'
import axios from 'axios'
import { selectCity, selectCountry, selectIcon, selectTemp, selectWeatherName, setWeather } from '../../store/slice/weatherSlice'
import { useDispatch, useSelector } from 'react-redux'
import defaultWeatherIcon from '../../assets/images/default-weather.png'

const Weather = () => {
    const [currentLocation, setCurrentLocation] = useState(null)
    const temp = useSelector(selectTemp)
    const weatherName = useSelector(selectWeatherName)
    const city = useSelector(selectCity)
    const country = useSelector(selectCountry)
    const icon = useSelector(selectIcon)

    const dispatch = useDispatch()

    // Fetch Weather
    const fetchWeather = async () => {
        const getWeatherURL = `https://api.weatherapi.com/v1/forecast.json?key=${'b134e251af58430f964152538220407'}&q=${currentLocation.city || "South Africa"}&days=3&aqi=yes`
        const weatheData = await axios.get(getWeatherURL)
        dispatch(setWeather({
            temp: weatheData.data.current.temp_c,
            weatherName: weatheData.data.current.condition.text,
            country: weatheData.data.location.country,
            icon: weatheData.data.current.condition.icon,
            city: weatheData.data.location.name,
        }))
    }

    useEffect(() => {
        // FETCHING CURRENT LOCATION
        axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?localityLanguage=en`).then((res) => {
            setCurrentLocation({
                city: res?.data.city,
                country: res?.data.countryCode
            })
            return res?.data;
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        if (currentLocation !== null) {
            fetchWeather().then((res) => { }).catch((error) => {
                // console.log(error)
                // if (error.response.data.error.code === 1006) {
                    setCurrentLocation({
                        city: 'South Africa',
                        country: 27
                    })
                // }
            })
        }
    }, [currentLocation])

    return (
        <>
            <div className='whether'>
                <div className='right py-0'>
                    <img src={icon === '' ? defaultWeatherIcon : icon} alt={weatherName} height={40} width={40} />
                </div>
                <div className='left'>
                    <div className='d-flex'>
                        <div className='temp'>
                            {Math.round(temp)}<sup>o</sup> C
                        </div>
                        <div className='other ms-2' title={weatherName}>
                            {weatherName}
                        </div>
                    </div>
                    <div className='city fst-italic'>
                        <span className='edit_loc'>{city}, {country}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Weather