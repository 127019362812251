// program to generate random strings

// declare all characters
const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const generateRandomImageName = (imageName) => {
    // Split the imageName into base name and extension
    const parts = imageName.split('.');
    if (parts.length < 2) {
      // If there's no extension, return the original name
      return imageName;
    }
    
    const baseName = parts[0]; // Extract the base name
    const extension = parts[1]; // Extract the extension
    
    // Generate a new random base name
    const randomChars = Math.random().toString(36).substring(2, 8);
    const newBaseName = `${randomChars}-${Date.now()}`;
    
    // Combine the new base name and the original extension
    const newImageName = `${newBaseName}.${extension}`;
    
    return newImageName;
  }

export default generateRandomImageName;