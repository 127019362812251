import React from 'react'
import { Link } from 'react-router-dom'

const Report = ({ className, data }) => {
    return (
        <div className={className !== undefined ? `post_comment ${className}` : 'post_comment'}>
            <h5 className="commentTitle">Reports</h5>
            {data?.length > 0 ? (
                <div className={`mt-2`}>
                    <table className='adminTable'>
                        <thead>
                            <tr>
                                <th><div className='table_head_info'>Full Name</div></th>
                                <th><div className='table_head_info'>Report Reason</div></th>
                                <th><div className='table_head_info'>Report Description</div></th>
                            </tr>
                        </thead>
                        {data?.map((el, i) => {
                            return <tbody>
                                <tr key={i}>
                                    <td>
                                        <div className='table_info'>
                                            <Link to={`/profile/${el?.user?._id}/timeline`}>{el.user.fullName}</Link>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='table_info'>{el.report_reason_id.reason_text}</div>
                                    </td>
                                    <td>
                                        <div className='table_info'>{el.other_reason === "" ? "No Description" : el.other_reason}</div>
                                    </td>
                                </tr>
                            </tbody>
                        })}
                    </table>
                </div>
            ) : (
                <div className={`post_comment_list mb-3 mt-2`}>
                    <p className="mb-0">No Report</p>
                </div>
            )}
        </div>
    )
}

export default Report


