import React, { useEffect, useState } from 'react'
import { AdminMenu } from '../../../../components/components'
import { useGetRoleListQuery, useUserPermissionListQuery } from '../../../../store/api/Admin/adminRolePermissionApi'
import RoleRow from '../../../../components/adminComponent/table/tableRow/RoleRow'
import { useSelector } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Role = () => {
    const roleList = useGetRoleListQuery()
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });
    const [permissionArr, setPermissionArr] = useState("")
    const userPermissionList = useUserPermissionListQuery()

    useEffect(() => {
        if (userPermissionList?.isSuccess === true) {
            let user_permission_arr = userPermissionList?.data?.permissions;
            console.log(user_permission_arr)
            setPermissionArr(user_permission_arr)
        }
    }, [userPermissionList]);

    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminMenu open />
                    </div>
                    <div className='col-md-9'>
                        <div className='bg-white rounded-2 px-1 pb-3'>
                            <div className="d-md-flex justify-content-between align-items-center p-2">
                                <h5 className="mb-0">Role</h5>
                                {(userInfo.user_type === 3 && permissionArr.includes("role_create")) && <Link to="/admin/roles-permission/role/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                                {userInfo.user_type === 1 && <Link to="/admin/roles-permission/role/create" className="createBtn">
                                    <FaPlus /> Create
                                </Link>}
                            </div>
                            <table className='adminTable'>
                                <thead>
                                    <tr>
                                        <th><div className='table_head_info'>Role Name</div></th>
                                        <th><div className='table_head_info'>Permissions</div></th>
                                        {(userInfo.user_type === 3 && permissionArr.includes("role_edit" || "role_delete")) && <th><div className='table_head_info text-center'>Actions</div></th>}
                                        {userInfo.user_type === 1 && <th><div className='table_head_info text-center'>Actions</div></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        roleList.isError && (
                                            <tr className='noData'>
                                                <td colSpan={2}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        roleList.isLoading && (
                                            <tr className='noData'>
                                                <td colSpan={2}>
                                                    <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        roleList.isSuccess && (
                                            roleList?.data?.data.length > 0 ? (
                                                roleList?.data?.data.map((el, i) => {
                                                    return (
                                                        <RoleRow data={el} key={i} />
                                                    )
                                                })
                                            ) : (
                                                <tr className='noData'>
                                                    <td colSpan={2}>
                                                        <h5 className="text-center mb-0 py-5 fs_18">No Data Found !!</h5>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Role;
