import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDeactiveUserMutation } from '../../../../store/api/Admin/userPanelApi'
import { useSelector } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'

const AdminUserTableRow = ({ data }) => {
    const navigate = useNavigate()
    // state -> block modal
    const [blockModal, setBlockModal] = useState(false)
    // state -> delete user
    const [deleteModal, setDeleteModal] = useState(false)

    // to deactivate or block user
    const [deactiveUser] = useDeactiveUserMutation()
    const handleBlock = (id) => {
        console.log(id)
        deactiveUser(id).then((res) => {
            console.log(res)
            setBlockModal(false)
        })
    }

    // get user info from local storage
    const { userSliceData: userInfo } = useSelector((store) => {
        return store.userSlice;
    });

    return (
        <>
            <div className='row' key={data._id}>
                {/* full name */}
                <div className='col-2'>
                    <div className='table_info'>
                        <Link to={`/profile/${data._id}/timeline`} target='_blank'>{data?.fullName}</Link>
                    </div>
                </div>
                {/* reg on */}
                <div className='col-3'>
                    <div className='table_info'>
                        <div>{data?.email}</div>
                        <div>{ }</div>
                    </div>
                </div>
                {/* membership */}
                <div className='col-2'>
                    <div className='table_info'>
                        {data?.membership?.plan_name}
                    </div>
                </div>
                {/* role */}
                <div className='col-2'>
                    <div className='table_actions' >
                        {data?.roles?.name}
                    </div>
                </div>

                {/* Action */}
                <div className='col-3'>
                    <div className='table_actions text-center' >
                        <div>
                            {userInfo.user_type === 1 && <button onClick={() => setBlockModal(!blockModal)} className={data.is_active !== 1 ? 'bg-danger-subtle text-danger' : ''}>
                                {data.is_active === 1 ? 'Block' : 'Unblock'}
                            </button>}
                            <button onClick={() => navigate(`/admin/roles-permission/system-users/edit/${data?._id}`)} className={'bg-secondary-subtle text-secondary'}>
                                Edit
                            </button>
                            {userInfo.user_type === 1 && <button onClick={() => navigate(`/admin/users/${data._id}/logs`)} className={data.is_active !== 1 ? 'bg-secondary-subtle text-secondary' : ''}>
                                view logs
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ BLOCK MODAL /} */}
            <Modal centered size="sm" show={blockModal} onHide={() => setBlockModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to {data.is_active === 1 ? 'Block' : 'Unblock'} this user?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setBlockModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={() => handleBlock(data._id)} className="mx-2">
                                {data.is_active === 1 ? 'Block' : 'Unblock'}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminUserTableRow
