import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import style from "./Employment.module.css";
import { useNavigate, useParams } from 'react-router-dom';
import { useReducer } from 'react';
import toast from 'react-hot-toast';
import { useGetCategoryListQuery } from '../../store/api/categoryMaster';
import { useMyPagesListForJobQuery } from '../../store/api/pageApi';
import { useGetSingleJobQuery, useUpdateJobMutation } from '../../store/api/jobApi';
import { useMyGroupListForJobQuery } from '../../store/api/groupApi';
import { Checkbox } from 'antd';
import { CiSquareMinus, CiSquarePlus  } from "react-icons/ci";
import Select from 'react-select'

const EmploymentEdit = () => {
    const [grList, setGrList] = useState(true)
    const [pgList, setPgList] = useState(true)
    // get id from param
    const params = useParams();
    const EMPLOYMENTID = params.employmentId;

    // get single Event Data
    const singleJob = useGetSingleJobQuery(EMPLOYMENTID);
    let singleJobData = singleJob?.data;

    const categoryList = useGetCategoryListQuery()
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const MyPageList = useMyPagesListForJobQuery()
    const MyGroupList = useMyGroupListForJobQuery()
    const [updateJob] = useUpdateJobMutation()

    //initial state for create page form
    const initialState = {
        title: "",
        category: "",
        location: "",
        email: "",
        description: "",
        salary: "",
        job_post_url: "",
        pages: [],
        groups: []
    };

    //reducer for create page form
    const editJobReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return { ...payload };
            case "SET_JOB":
                return { ...state, [payload.name]: payload.data };
            case "SET_PAGE":
                return {...state, pages: payload};
            case "SET_GROUP":
                return {...state, groups: payload};
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(editJobReducer, initialState);
    
    useEffect(() => {
        if (singleJob.status === "fulfilled") {
            dispatch({
                type: "SET_STATE",
                payload: {
                    title: singleJobData?.data?.title,
                    category: singleJobData?.data?.category._id,
                    location: singleJobData?.data?.job_location,
                    email: singleJobData?.data?.email,
                    description: singleJobData?.data?.description,
                    salary: singleJobData?.data?.salary,
                    job_post_url: singleJobData?.data?.job_post_url,
                    pages: singleJobData?.data?.pages.map(item => ({value: item._id, label: item.title})),
                    groups: singleJobData?.data?.groups.map(item => ({value: item._id, label: item.title}))
                }
            })
        }
    }, [singleJob])

    const handleChange = (e) => {
        dispatch({
            type: "SET_JOB",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };

    const [selectedItems, setSelectedItems] = useState([]);
    const handleChangePage = (e, id) => {
        
    };

    const [selectedItems2, setSelectedItems2] = useState([]);
    const handleChangeGroup = (e, id) => {
        
    };
    const submitForm = async (e) => {
        e.preventDefault();
        if (e.currentTarget.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            // when device online it will work
            if (navigator.onLine) {
                let obj = {
                    title: state.title,
                    category_id: state.category,
                    description: state.description,
                    job_location: state.location,
                    salary: state.salary,
                    email: state.email,
                    pages: selectedItems,
                    groups: selectedItems2,
                    job_post_url: "",
                    employmentId: EMPLOYMENTID
                }
                
                updateJob(obj).then((res) => {
                    if (res?.data?.error === 0) {
                        toast.success(res?.data?.message)
                        navigate(-1)
                    } else {
                        toast.error("Something went wrong!!!")
                    }
                })
            }

            // when device not online it will work
            else {
                toast.error("Please turn on internet!!!");
            }
        }
    };

    return (
        <div className="gap2 gray-bg full_height">
            <div className="container">
                <div className="row">
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8"}>
                        <Card className={`${style.central_meta}`}>
                            <Card.Body className={`${style.card_body}`}>
                                <Card.Title className={`${style.card_title} mb-3 fs-4`} >
                                    Update Your Job
                                </Card.Title>
                                <Form noValidate validated={validated} method="post" onSubmit={submitForm}>
                                    <Row>
                                        {/* job title */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job title*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your page name"
                                                value={state.title}
                                                onChange={handleChange}
                                                name="title"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide job title</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* job category */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job category*</Form.Label>
                                            <Form.Select required aria-label="Default select example" value={state.category} name="category" onChange={handleChange}>
                                                <option>Select category</option>
                                                {categoryList?.data?.data.map((el) => (
                                                    <option key={el._id} value={el._id}>{el.category_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* job description */}
                                        <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                            <Form.Label>Job description*</Form.Label>
                                            <Form.Control
                                                placeholder="Enter full details about the job"
                                                required
                                                type="text"
                                                value={state.description}
                                                onChange={handleChange}
                                                name="description"
                                                as="textarea"
                                                style={{ height: '100px' }}
                                            />
                                            <Form.Control.Feedback type="invalid">Please add a description</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* job location */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job Location*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your page name"
                                                value={state.location}
                                                onChange={handleChange}
                                                name="location"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide job title</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* contact mail */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Contact email*</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter your page name"
                                                value={state.email}
                                                onChange={handleChange}
                                                name="email" />
                                            <Form.Control.Feedback type="invalid">Please provide contact email</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* page select */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Select page to post this job</Form.Label>
                                            <Select defaultValue={state.pages.map((elem,i)=>elem.value)} options={state.pages} isMulti onChange={handleChangePage} />
                                            <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* group select */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Select group to post this job</Form.Label>
                                            <Select defaultValue={state.groups.map((elem,i)=>elem.value)} options={state.groups} isMulti onChange={handleChangeGroup} />
                                            <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                        </Form.Group>

                                        {/* job salary */}
                                        <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                            <Form.Label>Job salary*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter your page name"
                                                value={state.salary}
                                                onChange={handleChange}
                                                name="salary"
                                            />
                                            <Form.Control.Feedback type="invalid">Please provide job title</Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>
                                    <div className="mt-2 text-center">
                                        <Button className="btn btn-primary" type="submit">Update Job</Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmploymentEdit
