import React, { useState } from "react";
import style from "./employment.module.css";
import { BsFillCheckCircleFill, BsThreeDots } from "react-icons/bs";
import { FaRegClock } from "react-icons/fa";
import { useDeleteJobMutation, useDeleteSavedJobMutation, useSaveJobMutation } from "../../store/api/jobApi";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Button as AntButton } from "antd";
import { convertEpochToCustomFormat } from "../../methods/EpochTimeToCustomDate";

const JoblistItem = ({ data, type }) => {
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   })

   const navigate = useNavigate()

   // To delete the job
   const [deleteJob] = useDeleteJobMutation()
   const [showDeleteModal, setDeleteModal] = useState(false);
   const deleteJobFunc = () => {
      setDeleteModal(false)
      deleteJob(data?._id).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message)
         } else {
            toast.error("Something went wrong")
         }
      })
   }

   // save a job
   const [saveJob] = useSaveJobMutation();
   const saveJobFunc = () => {
      saveJob(data?._id).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
         } else {
            toast.error("Something went wrong");
         }
      })
   }

   // Delete Saved job
   const [deleteSavedJob] = useDeleteSavedJobMutation()
   const [savedJobDeleteModal, setSavedJobDeleteModal] = useState()
   const deleteSavedJobFunc = () => {
      deleteSavedJob(data?._id).then((res) => {
         if (res?.data?.error === 0) {
            toast.success(res?.data?.message);
         } else {
            toast.error("Something went wrong");
         }
      })
   }

   const dropdownForMe = [
      {
         label: <div onClick={() => navigate(`/employment/${data._id}/applied-list`)}>View Applied list</div>,
         key: "0"
      },
      {
         label: <div onClick={() => navigate(`/employment/${data._id}/edit`)}>Edit Job</div>,
         key: "1"
      },
      {
         label: <div onClick={() => setDeleteModal(true)}>Delete Job</div>,
         key: "2"
      }
   ]

   const dropdownForOthers = [
      {
         label: type === "save" ? <div onClick={() => setSavedJobDeleteModal(true)}>Delete This Job</div> : <div onClick={saveJobFunc}>Save this job</div>,
         key: "0"
      }
   ]

   return (
      <>
         <div style={{ border: "1px solid var(--border-color)" }} className={`${style.job_item}`}>
            <Dropdown
               menu={{ items: userSliceData?._id === data?.created_by?._id ? dropdownForMe : dropdownForOthers }}
               placement="bottomRight"
               trigger={["click"]}
               className={`${style.jobDropdown}`}
            >
               <AntButton type="link" size="small" shape="circle" icon={<BsThreeDots />} />
            </Dropdown>
            <h6 className="mb-1 fw-bold">
               <Link className="text-dark">{data?.title}</Link>
            </h6>
            <div className={`${style.job_description}`}>
               <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
               <p className="mb-0">
                  Location : {data?.job_location} <br />
                  <span>
                     Salary: <span className={`${style.job_salary}`}>{data?.salary}{" "}</span>
                  </span>
               </p>
            </div>
            <div className={`${style.job_bottom}`}>
               <div className={`${style.job_bottom_info}`}>
                  <span>
                     Posted by <Link className="ms-1"><BsFillCheckCircleFill className="mr-1" />{data?.created_by?.fname}</Link>
                  </span>
                  <span className="ms-4">
                     <FaRegClock /> {convertEpochToCustomFormat(data?.createdAt)}
                  </span>
               </div>
               {userSliceData._id !== data?.created_by?._id && <>
                  {data?.hasApplied ? (
                     <Link className={`${style.apply_btn}`} data-toggle="modal" data-target="#jobApplyModal" onClick={() => toast.error("Already applied for the job")}> Applied </Link>
                  ) : (
                     <Link to={`/employment/${data?._id}/apply`} className={`${style.apply_btn}`} data-toggle="modal" data-target="#jobApplyModal"> Apply Now </Link>
                  )}
               </>}
            </div>
         </div>
         <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
            <Modal.Body>
               <div className="text-center">
                  <h5 className="mb-3">Are you sure, you want to delete the job?</h5>
                  <div className="text-center">
                     <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                        Cancel
                     </Button>
                     <Button variant="danger" onClick={deleteJobFunc} className="mx-2">
                        Delete
                     </Button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         {/* saved job delete modal */}
         <Modal centered size="sm" show={savedJobDeleteModal} onHide={() => setSavedJobDeleteModal(false)}>
            <Modal.Body>
               <div className="text-center">
                  <h5 className="mb-3">Are you sure, you want to delete the saved job?</h5>
                  <div className="text-center">
                     <Button variant="secondary" onClick={() => setSavedJobDeleteModal(false)} className="mx-2">
                        Cancel
                     </Button>
                     <Button variant="danger" onClick={deleteSavedJobFunc} className="mx-2">
                        Delete
                     </Button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
};

export default JoblistItem;
