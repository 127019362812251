import React, { useState } from 'react'
import './eventCard.css';
import { Link } from 'react-router-dom';
import { ImClock } from 'react-icons/im'
import { GrLocation } from 'react-icons/gr'
import { defaultUserCover } from '../components';
import { useSelector } from 'react-redux';
import { useToJoinEventMutation, useDeleteEventMutation, useLeaveEventMutation, useAcceptPendingRequestMutation, useDeclinePendingRequestMutation } from '../../store/api/eventApi';
import { Button, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLeaveEventOthersMutation, useToJoinEventOthersMutation } from '../../store/api/eventApi';

const EventCard = ({ data, type }) => {
console.log()
    const userData = useSelector((store) => {
        return store.userSlice.userSliceData;
    });

    // join event from own profile
    const [joinEvent] = useToJoinEventMutation()
    const joinEventHandler = () => {
        joinEvent({ event_id: data._id, user_id: userData._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error("Request sent failed")
            }
        })
    }

    // join event for others profile visit
    const [joinEventOthers] = useToJoinEventOthersMutation()
    const joinEventHandlerOthers = () => {
        joinEventOthers({ event_id: data._id, user_id: userData._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error("Request sent failed")
            }
        })
    }

    // leave event from own profile
    const [leaveEvent] = useLeaveEventMutation()
    const leaveEventHandler = () => {
        leaveEvent({ event_id: data._id, user_id: userData._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error("Request sent failed")
            }
        })
        setDeleteModal(false)
    }

    // leave event for others profile visit
    const [leaveEventOthers] = useLeaveEventOthersMutation()
    const leaveEventHandlerOthers = () => {
        leaveEventOthers({ event_id: data._id, user_id: userData._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error("Request sent failed")
            }
        })
    }

    // DELETE CONFIRMATION BEFORE DELETE
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deleteEvent] = useDeleteEventMutation()
    const triggerDelete = () => {
        deleteEvent({ event_id: data._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message);
            } else {
                toast.error("Request sent failed");
            }
        })
        setDeleteModal(false);
    }

    // accept the pending invitation
    const [acceptPendingRequest] = useAcceptPendingRequestMutation()
    const acceptPendingRequestFunc = () => {
        acceptPendingRequest({ eventId: data._id, userId: userData._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error("Request sent failed")
            }
        })
    }

    // decline the pending invitation
    const [declineModal, setDeclineModal] = useState(false)
    const [declinePendingRequest] = useDeclinePendingRequestMutation()
    const declinePendingRequestFunc = () => {
        declinePendingRequest({ eventId: data._id, userId: userData._id }).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error("Request sent failed")
            }
        })
    }

    if (type === "myEvent") {
        return (
            <>
                <div className="eventCard">
                    <figure>
                        <img src={data?.banner_image?.length > 0 ? data?.banner_image[0]?.image_url : defaultUserCover} alt="..." />
                        <em>{data?.members_count > 1 ? `${data?.members_count} Participated` : '0 Participated'}</em>
                    </figure>
                    <div className='eventCardContent'>
                        <h6>
                            <Link to={`/event/${data?._id}/discussion`}>{data?.title}</Link>
                        </h6>
                        <span className="upload_time">
                            <ImClock />{data?.timing[0]} to {data?.timing[1]}
                        </span>
                        <div className='location'><GrLocation /> {data?.venue}</div>
                        <ul className="add-remove-frnd following mt-0">
                            <li className="add-tofrndlist">
                                <button onClick={() => setDeleteModal(true)} className='deleteBtn'>
                                    <i className="fa fa-trash"></i>
                                </button>
                            </li>
                            <li className="following">
                                <Link to={`/event/${data?._id}/edit`} className="btn btn-light w-100">Edit Event</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* CONFIRM DELETE MODAL */}
                <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to delete the event?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={() => triggerDelete()} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    else if (type === "suggested") {
        return (
            <div className="eventCard">
                <figure>
                    <img src={data?.banner_image?.length > 0 ? data?.banner_image[0]?.image_url : defaultUserCover} alt="..." />
                    <em>{data?.members_count > 1 ? `${data?.members_count} Participated` : '0 Participated'}</em>
                    {/* <span className="event_date">10 Nov</span> */}
                </figure>
                <div className='eventCardContent'>
                    <h6>
                        <Link to={`/event/${data?._id}/discussion`}>{data?.title}</Link>
                    </h6>
                    <span className="upload_time">
                        <ImClock />{data?.timing[0]} to {data?.timing[1]}
                    </span>
                    <div className='location'><GrLocation /> {data?.venue}</div>
                    <button className="btn btn-light w-100" onClick={joinEventHandler}>Join Event</button>
                </div>
            </div>
        )
    }
    else if (type === "joined") {
        return (
            <>
                <div className="eventCard">
                    <figure>
                        <img src={data?.banner_image?.length > 0 ? data?.banner_image[0]?.image_url : defaultUserCover} alt="..." />
                        <em>{data?.members_count > 1 ? `${data?.members_count} Participated` : '0 Participated'}</em>
                        {/* <span className="event_date">10 Nov</span> */}
                    </figure>
                    <div className='eventCardContent'>
                        <h6>
                            <Link to={`/event/${data?._id}/discussion`}>{data?.title}</Link>
                        </h6>
                        <span className="upload_time">
                            <ImClock />{data?.timing[0]} to {data?.timing[1]}
                        </span>
                        <div className='location'><GrLocation /> {data?.venue}</div>
                        <button className="btn btn-light w-100" onClick={() => setDeleteModal(true)}>Leave Event</button>
                    </div>
                </div>
                {/*CONFIRM DELETE MODAL*/}
                <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to leave the event?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={leaveEventHandler} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    else if (type === "pending") {
        return (
            <>
                <div className="eventCard">
                    <figure>
                        <img src={data?.banner_image?.length > 0 ? data?.banner_image[0]?.image_url : defaultUserCover} alt="..." />
                        <em>{data?.members_count > 1 ? `${data?.members_count} Participated` : '0 Participated'}</em>
                        {/* <span className="event_date">10 Nov</span> */}
                    </figure>
                    <div className='eventCardContent'>
                        <h6>
                            <Link to={`/event/${data?._id}/discussion`}>{data?.title}</Link>
                        </h6>
                        <span className="upload_time">
                            <ImClock />{data?.timing[0]} to {data?.timing[1]}
                        </span>
                        <div className='location'><GrLocation /> Event venue</div>
                        <ul className="add-remove-frnd following mt-0">
                            <li className="add-tofrndlist">
                                <button className='deleteBtn' onClick={() => setDeclineModal(true)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            </li>
                            <li className="following">
                                <button className="btn btn-light w-100" onClick={acceptPendingRequestFunc}>Accept</button>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* CONFIRM DECLINE MODAL FOR INVITED EVENT */}
                <Modal centered size="sm" show={declineModal} onHide={() => setDeclineModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to decline the request?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeclineModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={declinePendingRequestFunc} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    else if (type === "others") {
        return (
            <>
                <div className="eventCard">
                    <figure>
                        <img src={data?.banner_image?.length > 0 ? data?.banner_image[0]?.image_url : defaultUserCover} alt="..." />
                        <em>{data?.members_count > 1 ? `${data?.members_count} Participated` : '0 Participated'}</em>
                    </figure>
                    <div className='eventCardContent'>
                        <h6>
                            <Link to={`/event/${data?._id}/discussion`}>{data?.title}</Link>
                        </h6>
                        <span className="upload_time">
                            <ImClock />{data?.timing[0]} to {data?.timing[1]}
                        </span>
                        <div className='location'><GrLocation /> {data?.venue}</div>
                        <ul className="add-remove-frnd following">
                            <li className="following">
                                {data.is_join === "not_exists" ? <Link to={``} className="btn btn-light w-100" onClick={joinEventHandlerOthers}>Join Event</Link> : <Link to={``} className="btn btn-light w-100" onClick={leaveEventHandlerOthers}>Cancel Join request</Link>}
                            </li>
                        </ul>
                    </div>
                </div>
                {/* CONFIRM DELETE MODAL */}
                <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to delete the event?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={() => triggerDelete()} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default EventCard;