import React, { useEffect, useState } from "react";
import ProfileLayout from "../../ProfileLayout";
import {
    DashboardMenuLink,
    FilterWrapper,
    PageCard,
} from "../../../../components/components";
import { useJoinedPagesQuery, useMyPagesQuery, useSuggestedPagesQuery, useGetPendingInvitationListQuery, useAllCountPageListsQuery, useAllOtherPageCountListsQuery } from "../../../../store/api/pageApi";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetCreatedPageByIdQuery, useGetJoinedPageByIdQuery } from "../../../../store/api/pageApi";
import { Pagination } from "antd";

const JoinedPages = () => {
    const { userId } = useParams()
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [sort, setSort] = useState(searchParams.get("sort") || "see_all")
    const countData = useAllCountPageListsQuery()
    const otherCountData = useAllOtherPageCountListsQuery(userId)

    const paginationPageChange = (page) => {
        setPageNo(page);
    }

    const infoObj = {
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }
    const myJoinedPages = useJoinedPagesQuery(infoObj);
    const myJoinedPagesData = myJoinedPages?.data?.data;

    useEffect(() => {
        let params = { pageNo };
        if (search) {
            params.search = search;
        }
        if (sort) {
            params.sort = sort;
        }
        setSearchParams(params);
    }, [pageNo, search, sort]);

    // other user
    const otherUserParam = {
        id: userId,
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }
    const otherUserJoinedPage = useGetJoinedPageByIdQuery(otherUserParam)

    return (
        <ProfileLayout handleDisplay="none">
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/pages`} title="Pages" count={userId === userSliceData._id ? countData.isSuccess ? countData.data.data.total_pages : 0 : otherCountData.isSuccess ? otherCountData.data.data.total_pages : 0} />
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/pages/joined`} title="Joined Pages" count={userId === userSliceData._id ? countData.isSuccess ? countData.data.data.total_joined_pages : 0 : otherCountData?.isSuccess ? otherCountData.data.data.total_joined_pages : 0} />
                            {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/pages/pending`} title="Pending Requests" count={countData.isSuccess ? countData.data.data.total_pending_pages : 0} />}
                            {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/pages/suggested`} title="Suggested Pages" count={countData.isSuccess ? countData.data.data.total_suggested_pages : 0} />}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="gx-2 justify-content-md-end row">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <form method="post">
                                    <input
                                        type="text"
                                        placeholder="Search.."
                                        className="form-control"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={searchParams.get("search") || search}
                                    />
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="select-options">
                                    <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                                        <option value={"see_all"}>See All</option>
                                        <option value={"a_to_z"}>A to Z</option>
                                        <option value={"z_to_a"}>Z to A</option>
                                        <option value={"newest"}>Newest</option>
                                        <option value={"oldest"}>Oldest</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="col-lg-1 col-md-1 col-sm-1">
                                <Dropdown
                                    menu={{ items }}
                                    placement="bottomRight"
                                    trigger={["click"]}
                                >
                                    <Button className="filterWrapDropBtn">
                                        <BsThreeDotsVertical />
                                    </Button>
                                </Dropdown>
                            </div> */}
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className="row g-md-3 g-2">
                {
                    (userId === userSliceData._id ? myJoinedPages.isLoading : otherUserJoinedPage.isLoading) && (
                        <div className="col-12">
                            <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                        </div>
                    )
                }
                {
                    (userId === userSliceData._id ? myJoinedPages.isError : otherUserJoinedPage.isError) && (
                        <div className="col-12">
                            <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong !!</h5>
                        </div>
                    )
                }
                {
                    userId === userSliceData._id ? myJoinedPagesData?.length === 0 && (
                        <div className="col-12">
                            <h5 className="text-center mb-0 py-5 fs_18">No Joined Page !!</h5>
                        </div>
                    ) : otherUserJoinedPage?.data?.data.length === 0 && (
                        <div className="col-12">
                            <h5 className="text-center mb-0 py-5 fs_18">No Joined Page !!</h5>
                        </div>
                    )
                }

                {
                    userId === userSliceData._id ? myJoinedPagesData?.map((elem, index) => {
                        return (
                            <div className="col-lg-2 col-md-3 col-6" key={index}>
                                <PageCard data={elem} pageId={elem._id} type="liked" />
                            </div>
                        );
                    }) : otherUserJoinedPage?.data?.data.map((elem, index) => {
                        return (
                            <div className="col-lg-2 col-md-3 col-6" key={index}>
                                <PageCard data={elem} pageId={elem._id} type="others" />
                            </div>
                        );
                    })
                }
            </div>
            {/* pagination component */}
            {userId === userSliceData._id ? (
                myJoinedPages?.data?.total_count > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={myJoinedPages?.data?.total_count} onChange={paginationPageChange} defaultPageSize={12} />
            ) : (
                otherUserJoinedPage?.data?.total_count > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={1} total={otherUserJoinedPage?.data?.total_count} onChange={paginationPageChange} defaultPageSize={12} />
            )}
        </ProfileLayout>
    );
};

export default JoinedPages;
