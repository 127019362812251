import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const otherUserApi = createApi({
    reducerPath: "otherUserApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["otherUser"],
    endpoints: (builder) => ({
        // GET POST BY ID
        getPostById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 100,
                    module: "Post",
                    user_id: data.id
                }
            }),
            providesTags: ["otherUser"],
        }),
    })
})


export const { useGetPostByIdQuery } = otherUserApi;