import React from 'react'
import './FilterWrapper.css'

const FilterWrapper = ({children}) => {
  return (
    <div className='filterDashboard mb-4'>
       <div className='wrapperBlock'>
            {children}
       </div> 
    </div>
  )
}

export default FilterWrapper