import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const communityApi = createApi({
    reducerPath: "communityApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["Community"],
    endpoints: (builder) => ({
        // POST REACT LIST
        getAllCommunityPostList: builder.query({
            query: () => ({
                url: "api/posts/allUserTimeline",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 100,
                    post_module: "community"
                },
            }),
            providesTags: ["Community"],
        }),
        createPostCommunity: builder.mutation({
            query: (data) => {
                return {
                    url: "api/posts/create",
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Community"],
        }),
    })
})


export const { useGetAllCommunityPostListQuery, useCreatePostCommunityMutation } = communityApi;