import React from "react";
import { Link } from "react-router-dom";
import AdvertiseImage from "../../../assets/images/resources/ad-widget.gif";
import style from './advertiseWidget.module.css'
const Advertisement = () => {
   return (
      <div className={style.advertisment_box}>
         <h4>advertisment</h4>
         <figure>
            <Link href="#" title="Advertisment">
               <img src={AdvertiseImage} alt="" />
            </Link>
         </figure>
      </div>
   );
};

export default Advertisement;
