import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adminQuotes = createApi({
    reducerPath: "adminQuotes",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["adminQuotes"],
    endpoints: (builder) => ({
        getQuotesList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/admin-side-quotes",
                    method: "GET"
                }
            },
            providesTags: ["adminQuotes"],
        }),
        createQuotes: builder.mutation({
            query: (data) => {
                return {
                    url: "api/admin/admin-side-quotes",
                    method: "POST",
                    body: {
                        quote: data.quote,
                        author: data.author,
                    },
                }
            },
            invalidatesTags: ["adminQuotes"],
        }),
        approveQuotes: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/admin-side-quotes`,
                    method: "PUT",
                    body: {
                        quoteId: data,
                    }
                };
            },
            invalidatesTags: ["adminQuotes"],
        }),
    }),
});

export const { useGetQuotesListQuery, useCreateQuotesMutation, useApproveQuotesMutation } = adminQuotes;