import React from "react";
import {
   DashboardMenuLink,
   EventCard,
   FilterWrapper,
} from "../../../components/components";
import GroupProfileLayout from "../GroupProfileLayout";
import { Dropdown } from "antd";
import { Button } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useGetSingleGroupQuery } from "../../../store/api/groupApi";

const Events = () => {
   // get id from param
   const params = useParams();
   const id = params.groupId;

   // get single Event Data
   const singleGroup = useGetSingleGroupQuery(id);
   let groupWholeData = singleGroup?.data?.data;
   const eventArray = Array.from({ length: 19 });
   const showFriendPublic = (id) => {}
   const showFriendPrivate = (id) => {}
   const muteNotification = (id) => {}
   const items = [
      {
         label: <div onClick={() => showFriendPublic(1)}>Show Friends Public</div>,
         key: "0",
      },
      {
         label: <div onClick={() => showFriendPrivate(1)}>Show Friends Private</div>,
         key: "1",
      },
      {
         label: <div onClick={() => muteNotification(1)}>Mute Notifications</div>,
         key: "2",
      }
   ]
   return (
      <GroupProfileLayout data={groupWholeData}>
         <FilterWrapper>
            <div className="row align-items-center">
               <div className="col-lg-7">
                  <div className="DashboardMenuLinks">
                     <DashboardMenuLink title="Events" count="10" />
                  </div>
               </div>
               <div className="col-lg-5">
                  <div className="gx-2 justify-content-md-end row">
                     <div className="col-lg-7 col-md-7 col-sm-7">
                        <form method="post">
                           <input
                              type="text"
                              placeholder="Search.."
                              className="form-control"
                           />
                        </form>
                     </div>
                     <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="select-options">
                           <select className="form-select">
                              <option>See All</option>
                              <option>A to Z</option>
                              <option>Newest</option>
                              <option>Oldest</option>
                           </select>
                        </div>
                     </div>
                     <div className="col-lg-1 col-md-1 col-sm-1">
                        <Dropdown
                           menu={{ items }}
                           placement="bottomRight"
                           trigger={["click"]}
                        >
                           <Button className="filterWrapDropBtn">
                              <BsThreeDotsVertical />
                           </Button>
                        </Dropdown>
                     </div>
                  </div>
               </div>
            </div>
         </FilterWrapper>
         <div className="central-meta">
            <div className="row gx-4">
               {eventArray.map((elem, index) => {
                  return (
                     <div className="col-lg-3 col-md-3 col-6" key={index}>
                        <EventCard />
                     </div>
                  );
               })}
            </div>
         </div>
      </GroupProfileLayout>
   );
};

export default Events;
