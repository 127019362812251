import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const countryApi = createApi({
  reducerPath: "countryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
  }),
  tagTypes: ["Country"],

  endpoints: (builder) => ({
    getCountry: builder.query({
      query: () => ({
        url: "api/countrylist",
        method: "GET",
      }),
      providesTags: ["Country"],
    }),
    getState: builder.query({
      query: (data) => ({
        url: "api/country/states",
        method: "GET",
        params: {
          iso: data
        }
      }),
      providesTags: ["Country"],
    }),
  }),
});

export const { useGetCountryQuery,useGetStateQuery } = countryApi;
