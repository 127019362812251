import './genericDetails.css'
import React, { useReducer, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  FloatingLabel,
  Button,
  Card,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserMutation } from "../../../store/api/userApi";
import { useGetCountryQuery } from "../../../store/api/countryApi";
import { addUserSlice } from "../../../store/slice/userSlice";
import { DatePicker } from "antd";
import toast from 'react-hot-toast';
import { useGetStateQuery } from '../../../store/api/stateApi';
import dayjs from 'dayjs';
import EpochTimeToDate from '../../../methods/EpochTimeToDate';
import TextArea from 'antd/es/input/TextArea';
import { Link, useNavigate } from 'react-router-dom';

const GenericDetails = () => {
  const dispatch1 = useDispatch()
  const [validated, setValidated] = useState(false);
  const [updateUser] = useUpdateUserMutation()
  const [dateValid, setDateValid] = useState(null);
  const navigate = useNavigate();
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });

  const country = useGetCountryQuery();
  const initialState = {
    user_id: userSliceData._id,
    fname: userSliceData.fname || "",
    mname: userSliceData.mname || "",
    lname: userSliceData.lname || "",
    email: userSliceData.email || "",
    gender: userSliceData.gender || "",
    dob: userSliceData.dob || null,
    marital_status: userSliceData.marital_status || "",
    state: userSliceData.state || {},
    countries: userSliceData.countries || {},
    bio: userSliceData.bio || ""
  };


  const userReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_DETAILS":
        return { ...state, [payload.name]: payload.data };
      case "SET_COUNTRY":
        return { ...state, countries: JSON.parse(payload.data) };
      case "SET_STATE":
        return { ...state, state: JSON.parse(payload.data) };
      case "SET_DATE":
        return { ...state, dob: payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  const handleChange = (e) => {
    dispatch({
      type: "SET_DETAILS",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };

  // state.user_id/timeline
  console.log("st", state.user_id)
  const countryState = useGetStateQuery(state.countries.iso)
  let statesAgainstCountry = countryState.data?.data;

  const handleCountry = (e) => {
    dispatch({
      type: "SET_COUNTRY",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });

    dispatch({
      type: "SET_STATE",
      payload: {
        name: state,
        data: JSON.stringify({ blank: 'blank' }),
      },
    });
  };

  const handleState = (e) => {
    dispatch({
      type: "SET_STATE",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };

  const handleDate = (v, date) => {
    const selectedDateTimestamp = new Date(date + " " + "00:00:01").getTime()
    const sixteenYearagoTimestamp = 16 * 365 * 24 * 60 * 60 * 1000;
    const currentTimeTimestamp = Date.now()

    if ((currentTimeTimestamp - selectedDateTimestamp) > sixteenYearagoTimestamp) {
      setDateValid(true)
    } else {
      setDateValid(false)
    }
    dispatch({ type: "SET_DATE", payload: new Date(date + " " + "00:00:01").getTime() });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if (navigator.onLine) {
        updateUser(state).then((res) => {
          let newData = res?.data?.data
          dispatch1(addUserSlice(newData));
          navigate(-1)
          toast.success('Successfully saved your changes!!!')
        }).catch(() => {
          toast.error('Sorry!!!something went wrong!!!')
        })
      } else {
        toast.error('Please turn on internet!!!')
      }
    }
  };

  return (
    <div className="gap2 gray-bg full_height">
      <Container className="py-4">
        <Row className="justify-content-center">
          <Col lg="6">
            <Card>
              <Card.Body className="p-4">
                <h1 className="fs-4 fw-fw-bold mb-2 text-centre">Generic Details</h1>
                <Form noValidate validated={validated} method="post" onSubmit={submitForm}>
                  <Row>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="fname"
                          value={state.fname}
                          onChange={handleChange}
                          required
                          pattern="^[A-Za-z]{2,12}$"
                        />
                        <label htmlFor="fname">First Name</label>
                        <Form.Control.Feedback type="invalid">
                          2-12 charecter and not to include special charecter
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          name="mname"
                          id="mname"
                          type="text"
                          placeholder=""
                          value={state.mname}
                          onChange={handleChange}
                          className="prevent-validation"
                          pattern="^[A-Za-z]{2,12}$"
                        />
                        <label htmlFor="mname">Middle Name</label>
                        <Form.Control.Feedback type="invalid">
                          2-12 charecter and not to include special charecter
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          name="lname"
                          id="lname"
                          type="text"
                          placeholder=" "
                          value={state.lname}
                          onChange={handleChange}
                          required
                          pattern="^[A-Za-z]{2,12}$"
                        />
                        <label htmlFor="lname">Last Name</label>
                        <Form.Control.Feedback type="invalid">
                          2-12 charecter and not to include special charecter
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          id="email"
                          type="email"
                          name="email"
                          placeholder=" "
                          value={state.email}
                          onChange={handleChange}
                          required
                          pattern={
                            "^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$"
                          }
                        />
                        <label htmlFor="email">Email address</label>
                        <Form.Control.Feedback type="invalid">
                          {state.email === ""
                            ? "This field is required"
                            : "Enter a valid email address"}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col xxl="12" xl="12" lg="12" md="12">
                      <Form.Floating className="mb-3 bioTextarea">
                        <TextArea
                          name="bio"
                          id="bio"
                          value={state.bio}
                          showCount
                          maxLength={100}
                          onChange={handleChange}
                          style={{ height: 60, resize: 'none' }}
                        />
                        <Form.Label>Add a short bio</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          2-12 charecter and not to include special charecter
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <FloatingLabel
                        controlId="gender"
                        label="Select Gender"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Select Gender"
                          name="gender"
                          required
                          onChange={handleChange}
                          value={state.gender}
                        >
                          <option value="">Select Gender</option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Others</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Form.Group as={Col} className="mb-2 pb-1">
                      <div className="cutom_ant_datepicker">
                        <Form.Label>Date of birth</Form.Label>
                        <DatePicker
                          defaultValue={dayjs(EpochTimeToDate(userSliceData.dob))}
                          onChange={handleDate}
                          style={{
                            width: "100%",
                            height: "39px",
                            borderColor:
                              dateValid === null ? "" : dateValid === true ? "var(--bs-form-valid-border-color)" : "var(--red)",
                          }}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{
                            display:
                              dateValid === null
                                ? "none"
                                : dateValid === false
                                  ? "block"
                                  : "none",
                          }}
                        >
                          Min age should be 16 years
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <FloatingLabel
                        controlId="marital_status"
                        label="Select Marital Status"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Select marital status"
                          name="marital_status"
                          required
                          onChange={handleChange}
                          value={state.marital_status}
                        >
                          <option value="">Select Marital Status</option>
                          <option value="single">Single</option>
                          <option value="married">Married</option>
                          <option value="divorced">Divorced</option>
                          <option value="cohabitiong">Cohabiting</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <FloatingLabel
                        controlId="state"
                        label="State / Terriroty"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Select State / Terriroty"
                          name="state"
                          // required
                          onChange={handleState}
                          value={JSON.stringify(state.state)}
                        >
                          <option value={JSON.stringify({ blank: 'blank' })}>Select State</option>
                          {statesAgainstCountry?.map((elem, i) => {
                            return (
                              <option key={i} value={JSON.stringify(elem)} defaultChecked={true}>
                                {elem.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <FloatingLabel
                        controlId="countries"
                        label="Select Country"
                        className="mb-3"
                      >
                        <Form.Select
                          aria-label="Select Country"
                          name="countries"
                          onChange={handleCountry}
                          value={JSON.stringify(state.countries)}
                        >
                          {country.data?.data.map((elem, i) => {
                            return (
                              <option key={i} value={JSON.stringify(elem)}>{elem.country}</option>
                            )
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center">
                      <Button variant="primary" type="submit">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GenericDetails;
