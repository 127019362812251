import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useGetSingleGroupQuery, useUpdateGroupMutation } from '../../../../store/api/groupApi';
import AWS from "aws-sdk";
import generateRandomImageName from '../../../../methods/generateRandomImageName';
import toast from 'react-hot-toast';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import style from "./CreateGroup.module.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { defaultUserCover, defaultUserDp } from '../../../../components/components';
import { LiaTimesSolid } from 'react-icons/lia';
import { useGetCategoryListQuery } from '../../../../store/api/categoryMaster';
import { useSelector } from 'react-redux';

const EditGroup = () => {

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const [profile, setProfile] = useState("")
    const [cover, setCover] = useState("")
    const [editGroup] = useUpdateGroupMutation()
    const inputRefProfile = useRef(null);
    const inputRefCover = useRef(null);
    const navigate = useNavigate()
    const categoryList = useGetCategoryListQuery()

    // get id from param
    const params = useParams();
    const id = params.groupId;

    // get single Event Data
    const singleGroup = useGetSingleGroupQuery(id);
    let singleGroupData = singleGroup?.data;

    //initial state for create group form
    const initialState = {
        title: "",
        description: "",
        rules: "",
        cover_image: [],
        display_image: [],
        scope: "",
        category: ""
    };

    //reducer for create group form
    const createGroupReducer = (state, { type, payload }) => {
        switch (type) {
            case "SET_STATE":
                return { ...payload };
            case "SET_GROUP":
                return { ...state, [payload.name]: payload.data };
            case "SET_COVER":
                return { ...state, [payload.name]: payload.data };
            case "SET_IMAGE":
                return { ...state, [payload.name]: payload.data };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(createGroupReducer, initialState);

    useEffect(() => {
        if (singleGroup.status === "fulfilled") {
            dispatch({
                type: "SET_STATE",
                payload: {
                    title: singleGroupData?.data?.title,
                    description: singleGroupData?.data?.description,
                    rules: singleGroupData?.data?.rules,
                    cover_image: singleGroupData?.data?.cover_image,
                    display_image: singleGroupData?.data?.display_image,
                    scope: singleGroupData?.data?.scope,
                    category: singleGroupData?.data?.category?._id
                }
            })
        }
    }, [singleGroup])

    //to change title, description, rules
    const handleChange = (e) => {
        dispatch({
            type: "SET_GROUP",
            payload: {
                name: e.target.name,
                data: e.target.value,
            },
        });
    };

    //to set cover_image in state
    const handleCover = (e) => {
        setCover(e.target.files[0])
        const selectedFIles = [];
        const targetFiles = e.target.files;
        const targetFilesObject = [...targetFiles];

        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file));
        });

        if (selectedFIles.length > 0) {
            dispatch({
                type: "SET_COVER",
                payload: {
                    name: e.target.name,
                    data: [...selectedFIles],
                },
            });
        }
    };

    //to set display_image in state
    const handleImage = (e) => {
        setProfile(e.target.files[0]);
        const selectedFIles = [];
        const targetFiles = e.target.files;
        const targetFilesObject = [...targetFiles];

        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file));
        });

        if (selectedFIles.length > 0) {
            dispatch({
                type: "SET_IMAGE",
                payload: {
                    name: e.target.name,
                    data: [...selectedFIles],
                },
            });
        }
    };

    // create group form submit
    const submitForm = async (e) => {
        e.preventDefault();
        if (e.currentTarget.checkValidity() === false) {
            e.stopPropagation();
            // setValidated(true);
        } else {
            // when device online it will work
            if (navigator.onLine) {

                // when cover image and display image both present
                if (profile.name && cover.name) {
                    // for profile picture start
                    const displayImage_S3_BUCKET = "ubuntu-social-media-2";
                    const displayImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const displayImage_s3 = new AWS.S3({
                        params: { Bucket: displayImage_S3_BUCKET },
                        region: displayImage_REGION,
                    });
                    let randomNameDisplayImage = generateRandomImageName(profile.name);
                    let fileName = "page_" + randomNameDisplayImage.split(" ").join("_").split("-").join("_");
                    let fileSize = profile.size;
                    let fileType = profile.type;
                    const displayImage_params = {
                        Bucket: displayImage_S3_BUCKET,
                        Key: fileName,
                        Body: profile,
                    };

                    var uploadDisplayImage = displayImage_s3
                        .putObject(displayImage_params)
                        .on("httpUploadProgress", (evt) => {
                            // File uploading progress
                            console.log(
                                "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                            );
                        })
                        .promise();

                    await uploadDisplayImage.then((data, err) => {});

                    let displayImageArr = []

                    displayImageArr.push({
                        image_url:
                            ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
                                fileName),
                        size: fileSize,
                        mime_type: fileType,
                    });
                    // for profile picture end

                    // for cover picture start
                    const coverImage_S3_BUCKET = "ubuntu-social-media-2";
                    const coverImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const coverImage_s3 = new AWS.S3({
                        params: { Bucket: coverImage_S3_BUCKET },
                        region: coverImage_REGION,
                    });
                    let randomNameCoverImage = generateRandomImageName(cover.name);
                    let coverName = "page_" + randomNameCoverImage.split(" ").join("_").split("-").join("_");
                    let coverSize = cover.size;
                    let coverType = cover.type;

                    const coverImage_s3params = {
                        Bucket: coverImage_S3_BUCKET,
                        Key: coverName,
                        Body: cover,
                    };

                    var coverImage_upload = coverImage_s3.putObject(coverImage_s3params).on("httpUploadProgress", (evt) => {
                        // File uploading progress
                        console.log(
                            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        );
                    }).promise();

                    await coverImage_upload.then((data, err) => {});

                    let coverImageArr = []

                    coverImageArr.push({
                        image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
                        size: coverSize,
                        mime_type: coverType,
                    });
                    //for cover picture end

                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        rules: state.rules,
                        display_image: displayImageArr,
                        cover_image: coverImageArr,
                        group_id: id,
                        category_id: state.category
                    }

                    editGroup(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/profile/${userSliceData._id}/groups`)
                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })
                }

                //when cover image only present
                else if (cover.name) {
                    // for cover picture start
                    const coverImage_S3_BUCKET = "ubuntu-social-media-2";
                    const coverImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const coverImage_s3 = new AWS.S3({
                        params: { Bucket: coverImage_S3_BUCKET },
                        region: coverImage_REGION,
                    });
                    let randomNameCoverImage = generateRandomImageName(cover.name);
                    let coverName = "page_" + randomNameCoverImage.split(" ").join("_").split("-").join("_");
                    let coverSize = cover.size;
                    let coverType = cover.type;

                    const coverImage_s3params = {
                        Bucket: coverImage_S3_BUCKET,
                        Key: coverName,
                        Body: cover,
                    };

                    var only_coverImage_upload = coverImage_s3.putObject(coverImage_s3params).on("httpUploadProgress", (evt) => {
                        // File uploading progress
                        console.log(
                            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                        );
                    }).promise();

                    await only_coverImage_upload.then((data, err) => {});

                    let coverImageArr = []

                    coverImageArr.push({
                        image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + coverName),
                        size: coverSize,
                        mime_type: coverType,
                    });

                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        rules: state.rules,
                        display_image: state.display_image || [],
                        cover_image: coverImageArr,
                        group_id: id,
                        category_id: state.category
                    }

                    editGroup(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/profile/${userSliceData._id}/groups`)
                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })

                    //for cover picture end
                    toast.success("Cover Picture Upload Successfull!!!")
                }

                // // when display image only present
                else if (profile.name) {
                    // for profile picture start
                    const displayImage_S3_BUCKET = "ubuntu-social-media-2";
                    const displayImage_REGION = "ap-south-1";
                    AWS.config.update({
                        accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                        secretAccessKey: "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",
                    });
                    const displayImage_s3 = new AWS.S3({
                        params: { Bucket: displayImage_S3_BUCKET },
                        region: displayImage_REGION,
                    });
                    let randomNameDisplayImage = generateRandomImageName(profile.name);
                    let fileName = "page_" + randomNameDisplayImage.split(" ").join("_").split("-").join("_");
                    let fileSize = profile.size;
                    let fileType = profile.type;
                    const displayImage_params = {
                        Bucket: displayImage_S3_BUCKET,
                        Key: fileName,
                        Body: profile,
                    };

                    var only_uploadDisplayImage = displayImage_s3
                        .putObject(displayImage_params)
                        .on("httpUploadProgress", (evt) => {
                            // File uploading progress
                            console.log(
                                "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                            );
                        })
                        .promise();

                    await only_uploadDisplayImage.then((data, err) => {});

                    let displayImageArr = []

                    displayImageArr.push({
                        image_url:
                            ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" +
                                fileName),
                        size: fileSize,
                        mime_type: fileType,
                    });

                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        rules: state.rules,
                        display_image: displayImageArr,
                        cover_image: state.display_image || [],
                        group_id: id,
                        category_id: state.category
                    }

                    editGroup(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/profile/${userSliceData._id}/groups`)
                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })
                    // for profile picture end
                }

                //when cover image and display image both not present 
                else {
                    let obj = {
                        scope: state.scope,
                        title: state.title,
                        description: state.description,
                        rules: state.rules,
                        display_image: state.display_image || [],
                        cover_image: state.cover_image || [],
                        group_id: id,
                        category_id: state.category
                    }

                    editGroup(obj).then((res) => {
                        if (res?.data?.error === 0) {
                            toast.success(res?.data?.message)
                            navigate(`/profile/${userSliceData._id}/groups`)
                        } else {
                            toast.error("Something went wrong!!!")
                        }
                    })
                }
                navigate(`/group/${id}/timeline`)
            }
            // when device not online it will work
            else {
                toast.error("Please turn on internet!!!");
            }
        }
    };

    //to remove display image
    const removeDisplayImage = () => {
        setProfile("")
        dispatch({
            type: "SET_IMAGE",
            payload: {
                name: "display_image",
                data: [],
            },
        });
        inputRefProfile.current.value = null;
    }

    // to remove cover image
    const removeCoverImage = () => {
        setCover("")
        dispatch({
            type: "SET_COVER",
            payload: {
                name: "cover_image",
                data: [],
            },
        });
        inputRefCover.current.value = null;
    }

    return (
        <>
            <div className="gap2 gray-bg full_height">
                <div className="container">
                    <div className="row">
                        <div className={"col-md-7 col-lg-8"}>
                            <Card className={`${style.central_meta}`}>
                                <Card.Body className={`${style.card_body}`}>
                                    <Card.Title className={`${style.card_title} mb-3 fs-4`}>
                                        Edit Your Group
                                    </Card.Title>
                                    <Form onSubmit={submitForm} >
                                        <Row>
                                            <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                                <Form.Label>Group name*</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Enter your group name"
                                                    value={state?.title}
                                                    onChange={handleChange}
                                                    name="title"
                                                />
                                                <Form.Control.Feedback type="invalid">Please provide group name</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                                <Form.Label>Group scope*</Form.Label>
                                                <Form.Select required aria-label="Default select example" value={state?.scope} name="scope" onChange={handleChange}>
                                                    <option value="public">Public</option>
                                                    <option value="private">Private</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom01" className={`col-md-12 mb-3`}>
                                                <Form.Label>Group category*</Form.Label>
                                                <Form.Select required aria-label="Default select example" value={state.category} name="category" onChange={handleChange}>
                                                    <option>Select category</option>
                                                    {categoryList?.data?.data.map((el) => (
                                                        <option key={el._id} value={el._id}>{el.category_name}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom02" className={`col-md-6 mb-3`}>
                                                <Form.Label>Description*</Form.Label>
                                                <Form.Control
                                                    placeholder="Enter a small description"
                                                    required
                                                    type="text"
                                                    value={state.description}
                                                    onChange={handleChange}
                                                    name="description"
                                                    as="textarea"
                                                    style={{ height: '80px' }}
                                                />
                                                <Form.Control.Feedback type="invalid">Please add a small description</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="validationCustom03" className={`col-md-6 mb-3`}>
                                                <Form.Label>Set Rules</Form.Label>
                                                <Form.Control
                                                    placeholder="Set rules and expectations from your members"
                                                    type="text"
                                                    value={state.rules}
                                                    onChange={handleChange}
                                                    name="rules"
                                                    as="textarea"
                                                    style={{ height: '80px' }}
                                                    className='prevent-validation'
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formFile" className="col-md-6 mb-3">
                                                <Form.Label>Upload group image *</Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control type="file" onChange={handleImage} name="display_image" ref={inputRefProfile} className="prevent-validation" />
                                                    {(state.display_image.length > 0 || profile.length > 0) && (
                                                        <div className={style.editEventUploadImgCard}>
                                                            <img src={profile?.name?.length > 0 ? state?.display_image[0] : state?.display_image[0]?.image_url ? state?.display_image[0]?.image_url : defaultUserDp} className="img-thumbnail" alt='...' />
                                                            <button type="button" onClick={removeDisplayImage}>
                                                                <LiaTimesSolid />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formFile" className="col-md-6 mb-3">
                                                <Form.Label>Upload Cover *</Form.Label>
                                                <div className="position-relative">
                                                    <Form.Control type="file" onChange={handleCover} name="cover_image" ref={inputRefCover} className="prevent-validation" />
                                                    {(state.cover_image.length > 0 || cover.length > 0) && (
                                                        <div className={style.editEventUploadImgCard}>
                                                            <img src={cover?.name?.length > 0 ? state?.cover_image[0] : state?.cover_image[0]?.image_url ? state?.cover_image[0]?.image_url : defaultUserDp} className="img-thumbnail" alt='...' />
                                                            <button type="button" onClick={removeCoverImage}>
                                                                <LiaTimesSolid />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <div style={{ width: '14%', margin: 'auto' }} className="mt-2">
                                            <Button className="btn btn-primary" type="submit">Update Group</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className={"groupCard"}>
                                <div
                                    className="groupCardcoverPhoto"
                                    style={{ backgroundImage: `url(${cover?.name?.length > 0 ? state?.cover_image[0] : state?.cover_image[0]?.image_url ? state?.cover_image[0]?.image_url : defaultUserCover})` }}
                                ></div>
                                <div className="group_content">
                                    <figure>
                                        <img src={profile?.name?.length > 0 ? state?.display_image[0] : state?.display_image[0]?.image_url ? state?.display_image[0]?.image_url : defaultUserDp} alt="" />
                                    </figure>
                                    <Link>
                                        {state.title}
                                    </Link>
                                    <span>12k Members</span>
                                    <ul className="add-remove-frnd following">
                                        <li className="following">
                                            <button className="btn btn-light w-100">
                                                Join Group
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditGroup
