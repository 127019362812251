import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { postApi } from "../postApi";

export const adminPostApi = createApi({
    reducerPath: "adminPostApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["AdminPost"],
    endpoints: (builder) => ({
        getAdminPostList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/posts/allUserTimeline",
                    method: "GET",
                    params: {
                        page: data.page,
                        per_page: data.per_page,
                        meta_data: data.meta_data,
                        meta_type: data.meta_type
                    }
                }
            },
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, queryArg) => {
                const cacheJsonData = JSON.stringify(currentCache);
                const cacheData = (JSON.parse(cacheJsonData));
                const newItemId = newItems.data[0]?._id;
                const cacheIds = cacheData?.data.map(item => item._id);

                if (cacheIds.includes(newItemId) === false) {
                    currentCache.data.push(...newItems.data);
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            // providesTags: ["AdminPost"],
        }),

        // get reported posts
        getAdminReportedPostList: builder.query({
            query: (data) => {
                return {
                    url: "api/admin/report/list/reasonwise",
                    method: "GET",
                    params: data
                }
            },
            // Only have one cache entry because the arg always maps to one string
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, queryArg) => {
                const cacheJsonData = JSON.stringify(currentCache);
                const cacheData = (JSON.parse(cacheJsonData));
                const newItemId = newItems.data[0]?._id;
                const cacheIds = cacheData?.data.map(item => item._id);

                if (cacheIds.includes(newItemId) === false) {
                    currentCache.data.push(...newItems.data);
                }
            },
            // Refetch when the page arg changes
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            // providesTags: ["AdminPost"],
        }),

        deleteAdminPost: builder.mutation({
            query: (data) => {
                return {
                    url: `api/admin/posts/delete`,
                    method: "DELETE",
                    body: {
                        post_id: data.id
                    }
                };
            },
            async onQueryStarted({ arg, index, postId }, { dispatch, queryFulfilled, getState }) {
                try {
                    dispatch(
                        adminPostApi.util.updateQueryData('getAdminPostList', undefined, (draft) => {
                            draft.data.splice(index, 1);
                        })
                    )
                    dispatch(
                        postApi.util.updateQueryData('getAllUserTimeline', undefined, (draft) => {
                            draft.data.splice(index, 1);
                        })
                    )
                    dispatch(
                        adminPostApi.util.updateQueryData('getAdminReportedPostList', undefined, (draft) => {
                            draft.data.splice(index, 1);
                        })
                    )
                } catch (error) {
                    console.log(error)
                }
            }
        }),


    }),
});

export const {
    useGetAdminPostListQuery, useDeleteAdminPostMutation, useGetAdminReportedPostListQuery
} = adminPostApi;