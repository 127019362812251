import React, { useReducer, useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateUserMutation } from '../../../store/api/userApi';
import { addUserSlice } from '../../../store/slice/userSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const CommunicationDetails = () => {
  const [updateUser] = useUpdateUserMutation()
  const dispatch1 = useDispatch()
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const initialState = {
    user_id: userSliceData._id,
    email: userSliceData.email || '',
    alternative_email: userSliceData.alternative_email || '',
    phone: userSliceData.phone || '',
    alternative_phone_number: userSliceData.alternative_phone_number || '',
    residential_address: userSliceData.residential_address || ""
  };

  const userReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_DETAILS":
        return { ...state, [payload.name]: payload.data };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(userReducer, initialState);
  const handleChange = (e) => {
    dispatch({
      type: "SET_DETAILS",
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    if (e.currentTarget.checkValidity() === true) {
      if (navigator.onLine) {
        updateUser(state).then((res) => {
          if (res?.data?.error === 0) {
            let newData = res?.data?.data
            dispatch1(addUserSlice(newData));
            toast.success("Update Successfull!!!");
            navigate(-1)
          }
        })
      } else {
        toast.error('Please turn on internet!!!')
      }
    }
  }

  return (
    <div className='gap2 gray-bg full_height'>
      <Container className='py-4'>
        <Row className="justify-content-center">
          <Col lg="6">
            <Card>
              <Card.Body className='p-4'>
                <h1 className='fs-4 fw-fw-bold mb-2'>Communication Details</h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                </p>
                <Form
                  noValidate
                  validated={validated}
                  method="post"
                  onSubmit={submitForm}
                >
                  <Row>
                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="email"
                          value={state.email}
                          onChange={handleChange}
                          required
                          pattern="^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$"
                        />
                        <label htmlFor="fName">Email address*</label>
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email address
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          id="email"
                          type="email"
                          name="alternative_email"
                          placeholder=""
                          className='prevent-validation'
                          value={state.alternative_email}
                          onChange={handleChange}
                          pattern="^[A-Za-z0-9._]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$"
                        />
                        <label htmlFor="email">Alternative Email address</label>
                        <Form.Control.Feedback type="invalid">
                          Enter enter a valid alternative email address
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          name="phone"
                          id="phone"
                          type="text"
                          placeholder=" "
                          value={state.phone}
                          onChange={handleChange}
                          // required
                          pattern="^[1-9]{1}[0-9]{5,90}$"
                        />
                        <label htmlFor="phone">Phone Number*</label>
                        <Form.Control.Feedback type="invalid">
                          Please provide valid phone no
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          name="alternative_phone_number"
                          id="alternative_phone_number"
                          type="text"
                          placeholder=""
                          value={state.alternative_phone_number}
                          onChange={handleChange}
                          className='prevent-validation'
                          pattern="^[1-9]{1}[0-9]{5,90}$"
                        />
                        <label htmlFor="alternative_phone_number">Alternative Phone Number</label>
                        <Form.Control.Feedback type="invalid">
                          Please provide valid alternative phone no
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    <Col xxl="12" xl="6" lg="6" md="6">
                      <Form.Floating className="mb-3">
                        <Form.Control
                          name="residential_address"
                          id="address"
                          type="text"
                          placeholder=""
                          value={state.residential_address}
                          onChange={handleChange}
                          // required
                          pattern="^[A-Za-z]{5,50}$"
                          as={"textarea"}
                          style={{ height: '80px' }}
                        />
                        <label htmlFor="address">Residential Address*</label>
                        <Form.Control.Feedback type="invalid">
                          5-50 charecter and not to include special charecter
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    <Col className='d-flex align-items-center justify-content-center'>
                      <Button variant="primary" type="submit">Save</Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CommunicationDetails