import React from 'react'
import { useAllCountListsQuery, useGetFollowingListQuery, useGetfollowerFollowingListQuery, useGetfollowerListQuery } from '../../../store/api/userApi'
import { Link } from 'react-router-dom';
import { useGetsingleUserTimelineQuery } from '../../../store/api/postApi';
import { useSelector } from 'react-redux';
// import { useAllCountListQuery } from '../../../store/api/countApi';
// 
const DashnoardPostFollower = ({ className }) => {
  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });
  // get follower following list
  const followerList = useGetfollowerListQuery();
  const followingList = useGetFollowingListQuery();
  const singleUserTimeline = useGetsingleUserTimelineQuery(1);
  const countData = useAllCountListsQuery()



  return (
    <ol className={className !== undefined ? "folw-detail ms-auto" + className : 'folw-detail ms-auto'}>

      <li>
        <span><Link to={`/profile/${userSliceData._id}/follower`}>Followers</Link></span>
        <ins>{countData.isSuccess ? countData.data.data.total_follower : 0}</ins>
      </li>
      <li>
        <span><Link to={`/profile/${userSliceData._id}/following`}>Following</Link></span>
        <ins>{countData.isSuccess ? countData.data.data.total_following : 0}</ins>
      </li>
    </ol>
  )
}

export default DashnoardPostFollower;