import React from "react";
import ProfileLayout from "../ProfileLayout";
import { UserCard } from "../../../components/components";
import { useGetFollowingListQuery } from "../../../store/api/userApi";

const Following = () => {
    // get follower following list
    const followerFollowingList = useGetFollowingListQuery()
    const followingList = followerFollowingList?.data?.following;

    return (
        <ProfileLayout>
            <div className="central-meta">
                <h3 className="fs_18 mb-3">Following</h3>
                <div className="row gx-4">
                {
                  followerFollowingList.isLoading && (
                     <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                     </div>
                  )
               }
               
               {
                  followerFollowingList.isError && (
                     <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
                     </div>
                  )
               }
                
                    {followingList?.map((elem, index) => {
                        return (
                            <div className="col-lg-2 col-md-4 col-6" key={index}>
                                <UserCard data={elem} type={"followerFollowing"} />
                            </div>
                        );
                    })}
                    {followingList?.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">No Following Connection Found !!!</h5>
                    </div>}
                </div>
            </div>
        </ProfileLayout>
    );
};

export default Following;
