import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import './linkItem.css'
const DrawerLinkItem = ({data}) => {
    const [subMenu, setSubMenu] = useState(false)
    return (
        <>
            <div className={`drawer_link_parent`}>
                <NavLink to={data.link}>
                    <span className='drawer_link_icon'>{data.icon}</span>
                    {data.title}
                </NavLink>                
                {data?.children && (
                    <button onClick={()=>setSubMenu(!subMenu)} className={subMenu ? `buttonActive` : ''}>
                        <MdOutlineKeyboardArrowDown />
                    </button>
                )}
            </div>
            {
                data.children && (
                    <ul className={subMenu ? `active submenu` : `submenu`}>
                        {
                            data.children.map((submenu, i) => {
                                return(
                                    <li key={i}>
                                        <NavLink to={submenu.link}>
                                            {submenu.title}
                                        </NavLink>
                                    </li>
                                )
                            })
                        }
                    </ul>
                )
            }
        </>
    )
}

export default DrawerLinkItem