import React from 'react'
import EpochTimeToDateTime from '../../../../methods/EpochTimeToDateTime'
import { useNavigate } from 'react-router-dom'

const AdminUserLogsTable = ({ data }) => {
    const navigate = useNavigate()
    console.log(data)
    let link;
    if (data.meta_type === "Post") {
        link = `/post/${data.meta_data}`;
    }else if(data.meta_type === "Group"){
        link = `/group/${data.meta_data}/timeline`; ///group/65d846254b6e684cf575d4ec/timeline
    }
    return (
        <div className='row'>
            {/* activity's title */}
            <div className='col-8'>
                <div className='table_info' onClick={() => navigate(link)} style={{cursor:"pointer"}}>
                    {data?.logs?.title}
                </div>
            </div>
            {/* browser info */}
            {/* <div className='col-7'>
                <div className='table_info'>
                    {data?.navigator?.browserinfo}
                    {data?.navigator?.ipaddress}
                </div>
            </div> */}
            {/* activity time */}
            <div className='col-4'>
                <div className='table_info'>
                    <div>{EpochTimeToDateTime(data.createdAt).date}</div>
                    <div>{EpochTimeToDateTime(data.createdAt).time}</div>
                </div>
            </div>
        </div>
    )
}

export default AdminUserLogsTable
