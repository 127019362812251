import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const weatherApi = createApi({
  reducerPath: 'weatherApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.openweathermap.org/data/2.5/weather' }),
  tagTypes: ['Weather'],
  endpoints: (builder) => ({
    getWeather: builder.query({
        query: (position,query,key) => `?lat=${position.lat}&lon=${position.lon}?q=${query}&appid=${key}&units=metric`,
        providesTags: ['Weather']
    })    
  }),
});

export const { useGetWeatherQuery } = weatherApi;