import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { BirthdayWidget, CreateYourPageWidget, Quote } from '../../components/components'
import { useOrderedListQuery, useSellOrderListQuery } from '../../store/api/marketApi'
import { Breadcrumb, Input, Radio } from 'antd'
import OrderList from '../../components/market/OrderList'
import { BiShoppingBag } from 'react-icons/bi'
import { LuTags } from 'react-icons/lu'

const MySellOrders = () => {
    const navigate = useNavigate()
    const orderedList = useSellOrderListQuery()
    return (
        <div className='gap2 gray-bg full_height'>
            <div className='container'>
                <div className='row'>
                    <div className="col-md-3">
                        <div className='market_details'>
                            <h4 className='mb-0 fw-bold'>Marketplace</h4>
                            <div className='search mt-2'>
                                <Input placeholder="Search..." style={{ width: 200 }} />
                            </div>
                        </div>
                        <ul className='market_option'>
                            <li onClick={() => navigate("/marketplace/myorders")}><div className='icon'><BiShoppingBag /></div>Buying</li>
                            <li onClick={() => navigate("/marketplace/myorders/sell")} className='active'><div className='icon'><LuTags /></div>Selling</li>
                        </ul>
                        <CreateYourPageWidget />
                    </div>
                    <div className='col-md-9'>
                        <Breadcrumb>
                            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/marketplace">Marketplace</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/marketplace/myorders/sell">MySellingOrders</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='d-md-flex justify-content-between align-items-center mb-2'>
                            <h5 className='mb-0 fw-bold'>Selling Orders</h5>
                        </div>
                        {orderedList?.data?.data?.length > 0 ? (
                            orderedList?.data?.data?.map((el) => {
                                return (
                                    <OrderList data={el} key={el._id} type="sell" />
                                )
                            })
                        ) : (
                            <h6 className='text-center py-5 mb-0 mt-5'>No Orders Found !!</h6>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MySellOrders
